const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENV,
  }
};

export { rollbarConfig }