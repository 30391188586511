import React from 'react';
import {
  PhoneNumberField,
  EmailField,
  TextField,
  SSNField,
  DateField,
  Select,
  CurrencyField,
  ZipCodeField,
  formatNumberToCurrencyString, removeNonDigitCharacters,
} from './../CustomizedMUIInputs';

export const getSSNCustomError = ({applicant, coapplicant, reapplyId, isApplicant, isConfirmation} = {}) => {
  const subject = isApplicant ? applicant : coapplicant;
  const applicantFullSSN = removeNonDigitCharacters(applicant?.unmaskedSSN + applicant?.ssn);
  const applicantFullSSNConfirmation = removeNonDigitCharacters(applicant?.unmaskedSSNConfirmation + applicant?.ssnConfirmation);
  const coapplicantFullSSNConfirmation = removeNonDigitCharacters(coapplicant?.unmaskedSSNConfirmation + coapplicant?.ssnConfirmation);
  const coapplicantFullSSN = removeNonDigitCharacters(coapplicant?.unmaskedSSN + coapplicant?.ssn);
  const fullSSNSubject = isApplicant ? applicantFullSSN : coapplicantFullSSN;
  const ssnFieldsMatch = fullSSNSubject === removeNonDigitCharacters(subject?.unmaskedSSNConfirmation + subject?.ssnConfirmation);

  if (isApplicant
    ? isConfirmation
      ? applicantFullSSNConfirmation.length < 1
      : applicantFullSSN.length < 1
    : isConfirmation
      ? coapplicantFullSSNConfirmation.length < 1
      : coapplicantFullSSN.length < 1) {
    return {
      errorMessage: '',
      validationFunction: (_value) => true,
    };
  } else if (!reapplyId && !ssnFieldsMatch) {
    return {
      errorMessage: 'SSN Fields do not match',
      validationFunction: (_value) => false,
    };
  } else if (coapplicant && applicantFullSSN === coapplicantFullSSN) {
    return {
      errorMessage: 'Applicant and Co-Applicant SSN cannot be the same',
      validationFunction: (_value) => false,
    };
  }
};

export const ApplicantSection = ({
  applicant,
  coapplicant,
  setApplicant,
  isLoading,
  showAllErrors,
  isValidIncomeAmount,
  minIncomeAmount,
  highlightEmail,
  reapplyId,
}) => {

  const highlightedEmailHelperText = highlightEmail
    ? `This email appears to be invalid. You can still submit the application if you'd like.`
    : ""

  return (
    <div className="form-applicants-information-section form-card">
      <h2>Applicant's Information</h2>
      <p className="form-applicants-information-warning">
        All Applicants must be <strong>US Citizens</strong>
      </p>
      <div className="form-applicants-information-section-grid">
        <TextField
          value={applicant.firstName}
          onChange={(newValue) => setApplicant(oldValue => ({...oldValue, firstName: newValue }))}
          label="First Name"
          id="applicant-first-name"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <TextField
          value={applicant.lastName}
          onChange={(newValue) => setApplicant(oldValue => ({...oldValue, lastName: newValue }))}
          label="Last Name"
          id="applicant-last-name"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <PhoneNumberField
          value={applicant.phoneNumber}
          onChange={(newValue) => setApplicant(oldValue => ({...oldValue, phoneNumber: newValue }))}
          label="Mobile Phone Number"
          id="applicant-phone-number"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <EmailField
          value={applicant.email}
          onChange={(newValue) => setApplicant(oldValue => ({...oldValue, email: newValue }))}
          label="Email"
          id="applicant-email"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          highlight={highlightEmail}
          helperText={highlightedEmailHelperText}
          required
        />
        <SSNField
          value={applicant.ssn}
          onChange={(newValue) => {
            setApplicant(oldValue => ({...oldValue, ssn: newValue.ssn, unmaskedSSN: newValue.unmaskedSSN }));
          }}
          label="SSN"
          id="applicant-ssn"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
          customError={getSSNCustomError({applicant, coapplicant, reapplyId, isApplicant: true, isConfirmation: false})}
        />
        <DateField
          value={applicant.birthday}
          onChange={(newValue) => {
            setApplicant(oldValue => ({...oldValue, birthday: newValue }));
          }}
          label="Date of Birth"
          id="applicant-birthday"
          keyboardButtonAriaLabel="Pick a Date of Birth"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          maximumDate={new Date()} // new Date() means today
          required
          customError={!reapplyId && {
            errorMessage: 'Date Fields do not match',
            validationFunction: (value) => {
              return value === applicant.birthdayConfirmation
            },
          }}
        />
        {!reapplyId && (<SSNField
          value={applicant.ssnConfirmation}
          onChange={(newValue) => {
            setApplicant(oldValue => ({
              ...oldValue,
              ssnConfirmation: newValue.ssn,
              unmaskedSSNConfirmation: newValue.unmaskedSSN
            }));
          }}
          label="SSN Confirmation"
          id="applicant-ssn-confirmation"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
          customError={getSSNCustomError({applicant, coapplicant, reapplyId, isApplicant: true, isConfirmation: true})}
        />)}
        {!reapplyId && (<DateField
          value={applicant.birthdayConfirmation}
          onChange={(newValue) => {
            setApplicant(oldValue => ({...oldValue, birthdayConfirmation: newValue}));
          }}
          label="Date of Birth Confirmation"
          id="applicant-birthday-confirmation"
          keyboardButtonAriaLabel="Pick a Date of Birth"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          maximumDate={new Date()} // new Date() means today
          required
          onPaste={(e) => e.preventDefault()}
          customError={{
            errorMessage: 'Date Fields do not match',
            validationFunction: (value) => {
              return value === applicant.birthday
            },
          }}
        />)}
        <p className="form-applicants-information-text-span">
          Add Applicant's employment and income information
        </p>
        <Select
          value={applicant.employmentType}
          label="Employment Type"
          onChange={(newValue) => {
            setApplicant(oldValue => ({...oldValue, employmentType: newValue }));
          }}
          options={[
            {
              label: 'Employed',
              value: 'employed',
              id: 'employed-option-aplicant',
            },
            {
              label: 'Self Employed',
              value: 'self_employed',
              id: 'self-employed-option-aplicant',
            },
            {
              label: 'Retired',
              value: 'retired',
              id: 'retired-option-aplicant',
            },
          ]}
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        {['employed', 'self_employed'].includes(applicant.employmentType) && (
          <TextField
            value={applicant.occupation}
            onChange={(newValue) => setApplicant(oldValue => ({...oldValue, occupation: newValue }))}
            label="Occupation"
            id="applicant-occupation"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        {['employed', 'self_employed'].includes(applicant.employmentType) && (
          <TextField
            value={applicant.employerName}
            onChange={(newValue) => setApplicant(oldValue => ({...oldValue, employerName: newValue }))}
            label="Employer Name"
            id="applicant-employer-name"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        {['employed', 'self_employed'].includes(applicant.employmentType) && (
          <ZipCodeField
            value={applicant.employerZipCode}
            onChange={(newValue) =>
              setApplicant(oldValue => ({...oldValue, employerZipCode: newValue }))
            }
            label="Employer's Zip Code"
            id="applicant-employer-zip-code"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        <CurrencyField
          value={applicant.annualIncome}
          onChange={(newValue) => setApplicant(oldValue => ({...oldValue, annualIncome: newValue }))}
          label="Applicant's Annual Income (USD)"
          id="applicant-annual-income"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          customError={
            isValidIncomeAmount && {
              validationFunction: (value) => isValidIncomeAmount(value),
              errorMessage: `Total income between applicant and coapplicant must be above ${formatNumberToCurrencyString(
                minIncomeAmount,
              )}`,
            }
          }
          required={minIncomeAmount === 0}
        />
        <CurrencyField
          value={applicant.otherHouseholdIncome}
          onChange={(newValue) =>
            setApplicant(oldValue => ({...oldValue, otherHouseholdIncome: newValue }))
          }
          label="Other Household Income (USD)"
          id="other-household-income"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
        />
      </div>
    </div>
  );
};
