import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from '../../../components/AlertContext';
import AddNote from './AddNote';
import NotesList from './NotesList';

const Notes = ({
  applicationNumber,
  setMsgStatusUpdated,
  msgStatusUpdated,
}) => {
  const [notesData, setNotesData] = useState(null);
  const [fetchedData, setFetchedData] = useState(false);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const [hasUnreadMessages, setHasUnreadMessages] = useState();
  const { user } = useUserContext();

  useEffect(() => {
    async function fetch() {
      const response = camelizeKeys(
        (
          await protectedGet(
            `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes`,
          )
        ).data,
      );
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
        ),
      );
      if (rawApplication) {
        setHasUnreadMessages(
          rawApplication?.data?.data?.attributes?.has_unread_messages,
        );
        setMsgStatusUpdated(!msgStatusUpdated);
      }
      // if (err) {
      //   notifyErrorWithRedirect(
      //     err,
      //     errorAlertMessage,
      //     history,
      //     '/applications',
      //   );
      //   return;
      // }
      setNotesData(response.data);
    }
    fetch();
  }, [fetchedData]);

  return (
    <div data-testid="notes-container">
      <AddNote
        applicationNumber={applicationNumber}
        fetchedData={fetchedData}
        setFetchedData={setFetchedData}
        setAlertMessage={setAlertMessage}
        setErrorAlertMessage={setErrorAlertMessage}
      />
      <NotesList
        notesData={notesData}
        applicationNumber={applicationNumber}
        fetchedData={fetchedData}
        setFetchedData={setFetchedData}
        setAlertMessage={setAlertMessage}
        setErrorAlertMessage={setErrorAlertMessage}
        user={user}
        hasUnreadMessages={hasUnreadMessages}
      />
    </div>
  );
};

export default Notes;
