import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles, Box } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: '0.5rem',
  },
  itemContainer: {
    marginTop: '3rem',
    marginBottom: '6rem',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '48rem',

    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    alignSelf: 'center',
    height: 94,
    width: 94,
    marginBottom: '1rem',
  },
}));

const PlaidInfoCard = ({ item, isCarousel }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  return (
    <Box className={classes.item}>
      <img src={item.image} className={classes.image} />
      <Box display="flex" alignItems="center" marginBottom="0.25rem">
        <Icon icon="info-filled" color="#9198A0" fontSize="1.25rem" />
        <Text
          textSize="sm"
          textFontWeight={600}
          textAlign="center"
          isCarousel
          className={classes.title}
        >
          {item.title}
        </Text>
      </Box>
      <Text
        textSize="sm"
        textcolor={palette.text.hint}
        style={{
          whiteSpace: 'pre-line',
          marginBottom: isCarousel ? '6rem' : '',
        }}
      >
        {item.description}
      </Text>
    </Box>
  );
};

export default PlaidInfoCard;
