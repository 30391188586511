import React, { useState } from 'react';
import { makeStyles, Paper, Box, Grid } from '@material-ui/core';

import Text from 'components/TextFields/Text';

const SettingsSectionFormGroup = ({
  title,
  description,
  children,
  className,
  spacing,
}) => {
  return (
    <Box className={className}>
      <Box mb="1rem">
        {title && (
          <Text
            textVariant="h2"
            textFontSize={16}
            textFontWeight={600}
            textLetterSpacing={0.6}
            textLineHeight="24px"
            textColor="#1E272E"
            text={title}
          />
        )}
        {description && (
          <Text
            textVariant="body1"
            textFontSize={13}
            textFontWeight={500}
            textLetterSpacing={0.6}
            textLineHeight="12px"
            textColor="#636A72"
            text={description}
          />
        )}
      </Box>
      <Grid spacing={spacing} container>
        {children}
      </Grid>
    </Box>
  );
};

export default SettingsSectionFormGroup;
