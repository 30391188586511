import {Box, makeStyles, withStyles} from "@material-ui/core";
import React from "react";

export const toCapitalCase = (str) => {
  if (!str) {
    return '';
  }

  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const processName = (name) => {
  return name && typeof name === 'string'
    ? name
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    : '';
};

const processFileSize = (size = 'Unknown') => {
  return size;
};

export const processFileType = (fileType) => {
  let parsedFileType = 'Unknown';
  if (fileType) {
    const stubs = fileType.split('/');
    parsedFileType = stubs.length
      ? stubs[stubs.length - 1].toUpperCase()
      : parsedFileType;
  }
  return `${parsedFileType}`;
};

export const getIconName = (fileType) => {
  switch (fileType) {
    case 'PDF':
      return 'documentPDF';
    case 'PNG':
      return 'imagePNG';
    case 'DOC':
    case 'DOCX':
    case 'ODF':
      return 'documentDOC';
    case 'JPG':
    case 'JPEG':
      return 'imageJPG';
    default:
      return 'documentUNKNOWN';
  }
};

export const useStyles = makeStyles(() => ({
  title: {
    color: '#636A72',
    lineHeight: '18px',
    letterSpacing: 0.5,
    paddingTop: 12,
    fontSize: '.875rem'
  },
  cardDarken: {
    border: 'solid 0.5px rgb(193,201,209)',
    borderRadius: 4,
    minHeight: 76,
    marginTop: 12,
    marginBottom: 0,
    padding: 12,
  },
  cardwithoutBorder: {
    borderRadius: 4,
    borderBottom: 'none',
    padding: 12,
    backgroundColor: 'white',
  },
  card: {
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    borderRadius: 4,
    minHeight: 76,
    marginTop: 11,
    marginBottom: 12,
    padding: 12,
    backgroundColor: 'white',
  },
  inactiveCard: {
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    borderRadius: 4,
    minHeight: 76,
    padding: 12,
    backgroundColor: 'white',
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadedDocument: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    wordBreak: "break-all",
    lineHeight: '18px',
    letterSpacing: 0.65,
  },
  divider: {
    backgroundColor: '#F3F4F4',
    marginTop: 12,
    marginBottom: 12,
  },
  documentType: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
  },
  fileDescriptionUploaded: {
    margin: '0 auto auto 1rem',
  },
  fileDescription: {
    width: '100%',
  },
  documentTitle: {
    marginBottom: '0.125rem',
  },
  cardButton: {
    borderColor: '#F79D3C',
    borderWidth: '1.5px',
    width: '100%',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
  dropdownInput: {
    display: 'flex',
    width: '100%;',
    height: '100%',
    position: 'absolute',
    opacity: 0,
  },
  cardFooter: {
    fontSize: 10,
    lineHeight: '12px',
    letterSpacing: 0.46,
    marginTop: 16,
    padding: 10,
  },
}));

export const StyledDocStatus = withStyles((theme) => ({
  root: {
    margin: 0,
    marginLeft: '0.125rem',
    borderRadius: '6px',
    opacity: 0.95,
    lineHeight: 1,
    padding: '0.125rem',
    fontSize: 13,
    color: ({status}) => `${theme.palette.appState[status]}`,
    border: ({status}) => `solid 1px ${theme.palette.appState[status]}`,
  },
}))(Box);

export const DocStatus = ({status}) => {
  return (
    <StyledDocStatus status={status}>
      {`${status.charAt(0).toUpperCase()}${status.slice(1)}`}
    </StyledDocStatus>
  );
};