import React from 'react';
import {Grid, Divider, Typography} from '@material-ui/core';

import {format} from 'date-fns-tz';
import { useStyles } from "pages/ApplicationDetail/DocumentsTab/stipulations";
import {NoticesFileList} from "pages/ApplicationDetail/DocumentsTab/NoticesFileList";

export const Notices = ({notices, showLoader, showFileType, handleUploadDocument}) => {
  const classes = useStyles();


  return (
    <>
      {notices.map((notice) => (
        <>
          {((notice?.file?.name) && (
            <div
              className={classes.logItem}
              key={notice?.id}
              data-testid="viewcard-container"
            >
              <div className={classes.logContentWrapper}>
                <div className={classes.innerContentWrapper}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        data-testid="logItem"
                        className={classes.logContent}
                        style={{
                          marginBottom: '0px !important',
                        }}
                      >
                        <bold
                          style={{
                            fontWeight: 'bold',
                            fontSize: '14px',
                            marginBottom: '0px !important',
                          }}
                        >
                          { notice?.file?.name.includes('risk_based_notice') ? "Risk Based Notice" : "Adverse Action Notice" }
                        </bold>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Typography
                    data-testid="logItem"
                    className={classes.logContent}
                  >
                          <span style={{color: 'gray'}}>
                            {format(
                              new Date(
                                notice?.created_at ||
                                notice?.createdAt,
                              ),
                              'MM/dd/yyyy',
                              {
                                timeZone: 'America/New_York',
                              },
                            )}
                          </span>
                  </Typography>
                </div>
              </div>
                <>
                  <Divider className={classes.divider}/>
                  <NoticesFileList
                    documents={[notice?.file]}
                    handleUploadDocument={handleUploadDocument}
                    adhocstyling
                    showLoader={showLoader}
                    notices
                    showFileType={showFileType}
                  />
                </>
            </div>
          ))}
        </>
      ))}
    </>
  );
};
