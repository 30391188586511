import { useContext } from 'react';
import { camelizeKeys, decamelizeKeys } from 'humps';

import UserContext from 'components/UserContext';

export const useUserContext = () => {
  const context = useContext(UserContext);
  const parsed = JSON.parse(context.user);
  const user = camelizeKeys(parsed);
  const setUser = data => {
    const decamelized = decamelizeKeys(data);
    context.setUser(JSON.stringify(decamelized));
  };
  return {
    ...context,
    user,
    get userType() {
      return user?.data?.type;
    },
    get dealerLevelRole() {
      return user?.data?.attributes?.hierarchyLevelRole;
    },
    get defaultStageFunding() {
      return user?.data?.attributes?.defaultStageFunding;
    },
    get dealerLevelId() {
      return user?.data?.attributes?.hierarchyLevelId;
    },
    get dealerLevelTitle() {
      return user?.data?.attributes?.hierarchyLevelTitle;
    },
    get webUiPreference (){
      return user?.data?.attributes?.webUiPreference?.columnFilters;
    },
    setUser,
    _user: parsed,
  };
};
