export function setToken(token) {
  sessionStorage.setItem('token', token);
}

export function getToken() {
  return sessionStorage.getItem('token');
}

export function removeToken() {
  sessionStorage.removeItem('token');
}

export function setUserData(userData) {
  sessionStorage.setItem('userData', userData);
}

export function getUserData() {
  return sessionStorage.getItem('userData');
}

export function removeUserData() {
  sessionStorage.removeItem('userData');
}

export function setRedirectPage(path) {
  sessionStorage.setItem('redirectPage', path);
}

export function getRedirectPage() {
  return sessionStorage.getItem('redirectPage');
}

export function removeRedirectPage() {
  sessionStorage.removeItem('redirectPage');
}