import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { OutlinedTextField } from 'components/InputFields/InputField';
import { SelectField } from 'components/InputFields/SelectField';
import { StateSelectField } from 'components/InputFields/StateSelectField';
import MenuItem from '@material-ui/core/MenuItem';

const getTexts = (isDealer = false, dealerRole = 'dealership') => {
  const HOTexts = {
    stepTitle: 'Where do you currently live?',
    stepSubtitle: 'Let us know more about you',
    title: '',
    subtitle: '',
    address: 'Type the Address here',
    owner: '"Are you an Owner?"',
  };
  const dealershipTexts = {
    stepTitle: 'Current Address',
    stepSubtitle: 'Let us know more about your client',
    title: '',
    subtitle: '',
    address: 'Type the Address here',
    owner: 'Is an Owner?',
  };
  if (isDealer) {
    switch (dealerRole) {
      case 'dealership':
      default:
        return dealershipTexts;
    }
  } else {
    return HOTexts;
  }
};

const validationSchemaFn = () => {
  return Yup.object({
    application: Yup.object({
      addressAttributes: Yup.object().shape({
        streetAddress: Yup.string().required('Field Required').trim(),
        addressLine2: Yup.string().trim(),
        zipCode: Yup.string().required('Field Required').trim(),
        city: Yup.string().required('Field Required').trim(),
        state: Yup.string().required('Field Required').trim(),
      }),
      owner: Yup.mixed().oneOf([true, false], 'Field Required'),
    }),
  });
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: '3rem',
    },
  },
  textField: {
    width: '100%',
  },
  notchedOutline: {
    borderColor: '#1E272E',
  },
}));

export const Step4AddressInfo = ({
  isActive,
  setChildCallbacks,
  isDealer,
  dealerRole,
}) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return TEXTS.stepTitle;
        },
        get stepSubtitle() {
          return TEXTS.stepSubtitle;
        },
        get validationSchema() {
          return validationSchemaFn();
        },
      });
    }
    return () => {};
  }, [isActive]);

  const classes = useStyles();

  const TEXTS = getTexts(isDealer, dealerRole);

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <OutlinedTextField
          label={TEXTS.address}
          name="application.addressAttributes.streetAddress"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OutlinedTextField
          label="Address Line 2"
          name="application.addressAttributes.addressLine2"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OutlinedTextField
          label="City"
          name="application.addressAttributes.city"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <StateSelectField
          label="State"
          name="application.addressAttributes.state"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <OutlinedTextField
          label="Zip Code"
          name="application.addressAttributes.zipCode"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField label={TEXTS.owner} name="application.owner">
          <MenuItem value>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};
