import React, { useState } from 'react';
import * as yup from 'yup';
import {
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';

import SettingsSectionAction from 'components/SettingsSection/SettingsSectionAction';
import { useFormikContext } from 'formik';
import BusinessAddress from '../BusinessAddress';
import BusinessInformation from '../BusinessInformation';

const BusinessInformationForm = ({ userData }) => {
  const { values } = useFormikContext();

  return (
    <>
      <BusinessInformation userData={userData} />
      <BusinessAddress userData={userData} />
      <SettingsSectionAction
        disabledOptions={{ businessPhone: values.businessPhone }}
      >
        SAVE CHANGES{' '}
      </SettingsSectionAction>
    </>
  );
};

export default BusinessInformationForm;
