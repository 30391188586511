import React, { useEffect } from 'react';
import { map } from 'ramda';

import MenuItem from '@material-ui/core/MenuItem';
import { useFormikContext } from 'formik';
import { pathSplit } from 'services/utils';
import { useAddressLocationsContext } from 'services/contexts/useAddressLocations';
import { SelectField } from './SelectField';

export const StateSelectField = ({
  name,
  disabled,
  setSelectedState,
  type,
  ...props
}) => {
  const { states } = useAddressLocationsContext();
  const { values } = useFormikContext();
  const value = pathSplit(values, name);
  useEffect(() => {
    if (setSelectedState) {
      if (value) {
        setSelectedState(value);
      }
    }
  }, [value]);

  return (
    <SelectField
      name={name}
      disabled={disabled || !states || states.length === 0}
      {...props}
    >
      {map(
        (state) => (
          <MenuItem key={state.code} value={state.code}>
            {type === 'codeType' ? state.code : state.name}
          </MenuItem>
        ),
        states || [],
      )}
    </SelectField>
  );
};
