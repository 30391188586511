import React from 'react';

import { makeStyles, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  informationItemWrapper: {
    backgroundColor: '#F9FAFC',
    marginBottom: 18,
    paddingBottom: 25,
    paddingLeft: 22,
    paddingTop: 18,
  },
  informationItemTitle: {
    color: '#1E272E',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.7,
    lineHeight: '18px',
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 22,
  },
  informationItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 22,
    width: '100%',
  },
  informationItemDescription: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  informationItemValue: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '12px',
  },
  // Project Total Amount
  debtInformation: {
    backgroundColor: '#C1C9D133',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 32,
  },
  totalAmount: {
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,

    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  totalAmountValue: {
    color: '#1C272F',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
  monthlyPayment: {
    color: '#1DD1A1',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  monthlyPaymentValue: {
    color: '#1DD1A1',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
}));

const LoanTab = ({ loanDetails }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.informationItemWrapper}>
        <Typography className={classes.informationItemTitle}>
          Base Project Amount
        </Typography>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Base Project Amount
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.baseAmount ? (
              <NumberFormat
                value={loanDetails.baseAmount}
                displayType="text"
                prefix="$"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
              />
            ) : (
              '--'
            )}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} className={classes.informationItemWrapper}>
        <Typography className={classes.informationItemTitle}>
          Credit Terms
        </Typography>
        {/* <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Activation Fee
          </Typography>
          <Typography className={classes.informationItemValue}>--</Typography>
        </div>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Warranty
          </Typography>
          <Typography className={classes.informationItemValue}>--</Typography>
        </div> */}
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Interest Rate
          </Typography>
          <Typography className={classes.informationItemValue}>--</Typography>
        </div>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Term in Months
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.term * 12} months
          </Typography>
        </div>
      </Grid>

      {/* {loanDetails.firstStage && (
        <Grid item xs={12} className={classes.informationItemWrapper}>
          <Typography className={classes.informationItemTitle}>
            Stage Funding
          </Typography>
          <div className={classes.informationItem}>
            <Typography className={classes.informationItemDescription}>
              First Stage
            </Typography>
            <Typography className={classes.informationItemValue}>
              {format(new Date(loanDetails.firstStage), 'MM/dd/yyyy') || '--'}
            </Typography>
          </div>
          <div className={classes.informationItem}>
            <Typography className={classes.informationItemDescription}>
              Second Stage
            </Typography>
            <Typography className={classes.informationItemValue}>
              {format(new Date(loanDetails.secondStage), 'MM/dd/yyyy') || '--'}
            </Typography>
          </div>
          <div className={classes.informationItem}>
            <Typography className={classes.informationItemDescription}>
              Final Dealer Payment
            </Typography>
            <Typography className={classes.informationItemValue}>
              {format(new Date(loanDetails.thirdStage), 'MM/dd/yyyy') || '--'}
            </Typography>
          </div>
        </Grid>
      )} */}

      <Grid item xs={12} className={classes.informationItemWrapper}>
        <Typography className={classes.informationItemTitle}>
          Project Type
        </Typography>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Project Type
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.projectType || '--'}
          </Typography>
        </div>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Project Description
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.unitDescription || '--'}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} lg={6} />
      <Grid item xs={12} lg={6} className={classes.debtInformation}>
        <Typography className={classes.totalAmount}>
          TOTAL PROJECT AMOUNT
          <span className={classes.totalAmountValue}>--</span>
        </Typography>
        <Typography className={classes.monthlyPayment}>
          MONTHLY PAYMENT
          <span className={classes.monthlyPaymentValue}>--</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoanTab;
