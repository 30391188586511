import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowForward';
import Grid from '@material-ui/core/Grid';

import Icon from 'components/Icon';
import AdditionalInfo from '../../../components/AdditionalInfo';
import InputField, {
  TrimField,
} from '../../../components/InputFields/InputField';
import Text from '../../../components/TextFields/Text';
import ContainedButton from '../../../components/Buttons/ContainedButton';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: -40,
    },
  },
}));

const FirstPage = ({ setCurrentPage, values, errors, setFieldValue }) => {
  const classes = useStyles();

  const handleNext = e => {
    e.preventDefault();
    setCurrentPage('secondPage');
  };

  return (
    <div className={classes.form}>
      <Text
        textVariant="body1"
        textColor="#636A72"
        textFontWeight={600}
        textFontSize={12}
        textLetterSpacing={0.6}
        textLineHeight="16px"
        text="PERSONAL INFORMATION"
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <InputField name="firstname" type="text" label="Firstname" />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputField name="lastname" type="text" label="Lastname" />
        </Grid>
      </Grid>
      <TrimField
        name="email"
        type="email"
        label="Email"
        setFieldValue={setFieldValue}
      />

      <ContainedButton
        onClick={handleNext}
        text={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            NEXT <Icon icon="arrow-forward" style={{ marginLeft: '0.25rem' }} />
          </span>
        }
        variant="contained"
        color="primary"
        optionalDisabling
        disabledOptions={values}
        errors={errors}
        style={{ width: '180px', height: 40, fontSize: '1rem' }}
      />

      <AdditionalInfo
        title="Why do I have to create an account?"
        text="By creating an account you can have easy access to your loan information, documents, payments, and any other important information"
      />
    </div>
  );
};

export default FirstPage;
