import React from 'react';
import { Route } from 'react-router-dom';

import InitialPage from '../templates/initialPages';

const InitialRoute = ({ path, component: Component, type, ...rest }) => {
  return (
    <Route
      {...rest}
      path={path}
      render={props => (
        <InitialPage component={Component} type={type} {...props} />
      )}
    />
  );
};

export default InitialRoute;
