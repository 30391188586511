import React, { useEffect, useState } from 'react';
import { map, sortBy, prop } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardContent, makeStyles } from '@material-ui/core';
import { PageTable } from 'components/PageTable';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  header: {
    background: 'rgba(57, 63, 71, 0.02)',
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.6px',
  },
  cardContent: {
    padding: '0 !important',
  },
}));

const headCells = [
  {
    id: 'loanNumber',
    label: 'Loan Number',
  },
  {
    id: 'dealer',
    label: 'Contractor',
  },
  {
    id: 'projectAmount',
    label: 'Loan Amount',
  },
  {
    id: 'docsReceivedDate',
    label: 'Docs Received Date',
  },
  {
    id: 'stageFunding',
    label: 'Stage Funding',
  },
  {
    id: 'status',
    label: 'status',
  },
];

function buildTableData(projects) {
  return map((project) => {
    const rowCells = [];
    rowCells.push({
      type: 'link',
      value: `/applications/${project.application.id}`,
      state: { applicationId: project.application.id },
      subValue: project.application.ppId,
    });

    rowCells.push(project?.dealer?.businessName);
    rowCells.push({
      value: project?.baseAmount,
      type: 'money',
    });
    rowCells.push({
      value: project.createdAt
        ? format(new Date(project.createdAt), 'MM/dd/yyyy')
        : 'N/A',
      subValue: project.createdAt
        ? format(new Date(project.createdAt), 'hh:mm aaa')
        : '',
    });
    rowCells.push({
      value: {
        payments: project.payments,
        currentPayment: project.currentPayment,
        maxPayments: project.maxPayments,
        status: project.status,
      },
      type: 'stage-fund',
    });
    rowCells.push({
      value: project.status,
      subValue: project.application.draftStatus,
      type: 'status',
    });
    return rowCells;
  }, projects);
}

export const ProjectCard = () => {
  const classes = useStyles();
  const history = useHistory();

  const [totalItems, setTotalItems] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    perPage: 10,
  });
  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const [err, projects] = await to(
        protectedGet('/v1/loans/projects', {
          params: decamelizeKeys({ ...params, page: params.page + 1 }),
        }),
      );
      setIsLoading(false);
      if (err) {
        console.error(err);
        return;
      }

      projects.data.data = map(
        (app) => ({
          ...app,
          attributes: {
            ...app.attributes,
            ...app.meta,
            maxPayments: app?.meta?.payments,
          },
        }),
        projects.data?.data,
      );

      let res = normalize(camelizeKeys(projects.data)).get([
        'id',
        'status',
        'dealer.id',
        'dealer.name',
        'dealer.lastName',
        'dealer.businessName',
        'createdAt',
        'baseAmount',
        'application.id',
        'application.ppId',
        // meta
        'payments.id',
        'payments.status',
        'currentPayment',
        'stageFundedDate',
        'maxPayments',
      ]);
      res = map((prj) => {
        return {
          ...prj,
          payments: sortBy(prop('id'), prj.payments),
        };
      }, res);

      setTotalItems(Number(projects.headers.total));
      setRows(buildTableData(res));
    }
    fetch();
  }, [params]);

  return (
    totalItems > 0 && (
      <Card>
        <CardHeader
          className={classes.header}
          titleTypographyProps={{ className: classes.cardTitle }}
          title="Your Loans"
        />
        <CardContent className={classes.cardContent}>
          <PageTable
            totalItems={totalItems}
            rows={rows}
            headCells={headCells}
            isLoading={isLoading}
            page={params.page}
            perPage={params.perPage}
            setParams={setParams}
            hidePagination
            hideCheckboxes
            noSort
            tableHorizontalPadding="1.5rem"
            onRowClick={onRowClick}
          />
        </CardContent>
      </Card>
    )
  );
};
