import React, {useEffect, useState} from 'react';
import Icon from 'components/Icon';
import PulseLoader from 'react-spinners/PulseLoader';
import {Box, Button, Divider, Grid, Typography, useTheme,} from '@material-ui/core';
import {useUserContext} from 'services/hooks/useUser';
import {ActionsMenu} from './ActionsMenu';
import {
  DocStatus,
  getIconName,
  processFileType,
  processName,
  useStyles
} from "./utils";

export const DocumentCard = ({
    document,
    handleUploadDocument,
    isAppCanceled,
    canEdit,
    adhocstyling,
    showLoader,
    showFileType,
    stipulations,
    hasSelectedDeal
  }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (
      showFileType &&
      showFileType === (document?.file_type || document?.fileType) &&
      showLoader &&
      document?.status !== 'verified'
    ) {
      setShowSpinner(true);
    } else {
      setShowSpinner(false);
    }
  }, [showFileType, showLoader]);

  if (document) {
    const updateDoc =
      document.status === 'verified' || document.status === 'signed'
        ? false
        : document.status === 'sent'
          ? true
          : canEdit;
    const fileType = document.contentType
      ? processFileType(document.contentType)
      : processFileType(document.content_type);
    const status = document.status ? document.status : 'uploaded'
    const fileName = document.fileType
      ? processName(document.fileType)
      : processName(document.file_type);
    const isDocUploaded = status.toLowerCase() !== 'needed';
    const isInstallContract = fileName === 'Install Contract';
    const disableUpload = isInstallContract && !hasSelectedDeal;

    return (
      <div className={adhocstyling ? classes.cardwithoutBorder : classes.card}>
        <div className={classes.cardContent}>
          {/* ICON */}
          {isDocUploaded && !showSpinner && (
            <Icon icon={getIconName(fileType)} />
          )}

          {/* TEXT */}
          <div
            className={
              isDocUploaded
                ? classes.fileDescriptionUploaded
                : classes.fileDescription
            }
          >
            {/* TITLE */}
            {isDocUploaded && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.documentTitle}
              >
                <Typography className={classes.uploadedDocument}>
                  {document.name || fileName} &nbsp;
                </Typography>
                <DocStatus status={status} />
              </Box>
            )}

            {/* DESCRIPTION || UPLOAD BUTTON */}
            {isDocUploaded ? (
              <>
                {showSpinner && (
                  <Box
                    p={2}
                  >
                    <PulseLoader
                      color={theme.palette.primary.light}
                      size="1rem"
                    />
                  </Box>
                )}
                {!showSpinner && (
                  <Typography className={classes.documentType}>
                    {`File Type: ${fileType}`}
                  </Typography>
                )}
              </>
            ) : (
              <>
                {showSpinner && (
                  <Box
                    p={2}
                  >
                    <PulseLoader
                      color={theme.palette.primary.light}
                      size="1rem"
                    />
                  </Box>
                )}
                {!showSpinner && (
                  <>
                    {!stipulations && (
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              data-testid="logItem"
                              className={classes.logContent}
                              style={{
                                marginBottom: '0px !important',
                              }}
                            >
                              <bold
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  marginBottom: '0px !important',
                                }}
                              >
                                {document.name || fileName} &nbsp;
                              </bold>
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider className={classes.divider} />
                      </>
                    )}

                    <span>
                      <Button
                        color="primary"
                        variant="outlined"
                        className= {classes.cardButton}
                        disabled={disableUpload}
                        type="button"
                        component="span"
                      >
                        <input
                          type="file"
                          onChange={(e) => handleUploadDocument(e, document)}
                          className={classes.dropdownInput}
                          disabled={disableUpload}
                        />
                        <span
                          style={{
                            marginRight: 8,
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          UPLOAD DOCUMENT
                        </span>

                        <Icon
                          icon="uploadIcon"
                          color={isAppCanceled ? 'rgba(0, 0, 0, 0.26)' : null}
                        />
                      </Button>
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          {/* OPTIONS MENU */}
          {isDocUploaded && !showSpinner && (
            <ActionsMenu
              handleUploadDocument={(e) => handleUploadDocument(e, document)}
              isAppCanceled={isAppCanceled}
              classes={classes}
              urlTarget={document.url ? document.url : null}
              canEdit={updateDoc}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};

export const DocumentCardNotes = (
  document,
  handleUploadDocument,
  isAppCanceled,
  showClearOption,
  clearFile,
) => {
  const classes = useStyles();
  if (document) {
    // const updateDoc =document.status === 'verified' || document.status === 'signed'?false:document.status==='sent'?true:canEdit;
    const fileType = document.type;
    const status = 'uploaded';
    const fileName = document.name;
    const isDocUploaded = status.toLowerCase() !== 'needed';
    return (
      <div className={classes.card}>
        <div className={classes.cardContent}>
          {/* ICON */}
          {isDocUploaded && (
            <Icon icon={getIconName(fileType.split('/')[1].toUpperCase())} />
          )}

          {/* TEXT */}
          <div className={classes.fileDescription}>
            {/* TITLE */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.documentTitle}
            >
              <Typography className={classes.uploadedDocument}>
                {fileName}
              </Typography>
              {/* <DocStatus status={status} /> */}
            </Box>

            {/* DESCRIPTION || UPLOAD BUTTON */}
          </div>

          {/* OPTIONS MENU */}
          {isDocUploaded && (
            <ActionsMenu
              handleUploadDocument={(e) => handleUploadDocument(e)}
              isAppCanceled={isAppCanceled}
              classes={classes}
              showClearOption
              clearFile={clearFile}
              urlTarget={document.url ? document.url : null}
              canEdit
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};

export const FileList = ({
  documents,
  handleUploadDocument,
  isAppCanceled,
  canEdit,
  newFlow,
  notesDoc,
  showClearOption,
  clearFile,
  adhocstyling,
  showLoader,
  stipulations,
  showFileType,
  loanProductCode,
  hasSelectedDeal
}) => {
  const classes = useStyles();
  const { user, userType } = useUserContext();
  const loanProductType = loanProductCode ? loanProductCode : '';
  if (documents && Array.isArray(documents)) {
    return documents.map((document, index) => (
      <>
        {loanProductType === 'healthcare' && document?.fileType === "install_contract" && userType === 'dealer'
          ?
          <>
            {document?.status !== 'verified' ?
              <div className={classes.cardDarken}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      data-testid="logItem"
                      className={classes.logContent}
                      style={{
                        marginBottom: '0px !important',
                        marginTop: "12px"
                      }}
                    >
                      <bold
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginBottom: '0px !important',
                          position: 'relative',
                          bottom: '14px',
                          right: '3px'
                        }}
                      >
                        Applicant Stipulations and Documents
                      </bold>
                    </Typography>
                    <Typography className={classes.title} style={{
                      fontSize: '14px', position: 'relative',
                      bottom: '24px', right: '3px'
                    }}>
                      There are documents and stipulations visible to the
                      applicant only. Please have them login into their
                      account to complete the process.
                    </Typography>
                  </Grid>
                </Grid>
              </div> : <></>}
          </>
          : <>
            {notesDoc ? (
              <span key={index}>
                {DocumentCardNotes(
                  document,
                  handleUploadDocument,
                  isAppCanceled,
                  showClearOption,
                  clearFile,
                )}
              </span>
            ) : newFlow ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <DocumentCard
                  document={document}
                  handleUploadDocument={handleUploadDocument}
                  isAppCanceled={isAppCanceled}
                  canEdit={canEdit}
                  notesDoc={notesDoc}
                  showLoader={showLoader}
                  showFileType={showFileType}
                  hasSelectedDeal={hasSelectedDeal}
                />
              </Grid>
            ) : (
              <>
                <DocumentCard
                  key={index}
                  document={document}
                  handleUploadDocument={handleUploadDocument}
                  isAppCanceled={isAppCanceled}
                  canEdit={canEdit}
                  adhocstyling={adhocstyling}
                  showLoader={showLoader}
                  showFileType={showFileType}
                  stipulations={stipulations}
                  hasSelectedDeal={hasSelectedDeal}
                />
              </>
            )}
          </>
        }
      </>
    ));
  }
  return null;
};
