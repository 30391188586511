import React from 'react';
import {Grid} from "@material-ui/core";
import {InactiveDocumentCard} from "./InactiveDocumentCard";

export const InactiveFileList = ({
    documents,
    isAppCanceled,
    handleUploadDocument,
}) => {
  return Array.isArray(documents)
    ? documents.map((document) => (
      <Grid>
        {InactiveDocumentCard(
          document,
          isAppCanceled,
          handleUploadDocument,
        )}
      </Grid>
    ))
    : null;
};