import React, { useState } from 'react';
import * as Yup from 'yup';
import to from 'await-to-js';
import axios from 'axios';
import normalize from 'json-api-normalize';
import { Formik, Form } from 'formik';
import { protectedPost } from 'services/http';
import { camelizeKeys } from 'humps';
import PulseLoader from 'react-spinners/PulseLoader';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Text from 'components/TextFields/Text';
import { DriverLicenseInput } from 'components/InputFields/DriverLicenseInput';
import { useAlertContext } from 'components/AlertContext';
import { buildFormData } from 'services/utils';

export const onVerifyDocument = async (
  data,
  { token, setPrequalification, setSelectedFlow, setSubmitting },
) => {
  const [err, r] = await to(
    protectedPost(`/v1/socure_proxies/email_auth_score`, {
      modules: [
        // 'fraud',
        // 'emailrisk',
        // 'phonerisk',
        // 'addressrisk',
        // 'devicerisk',
        // 'kyc',
        // 'watchliststandard',
        'documentverification',
        // 'decision', no permissions
        // 'social',
        // 'alertlist',
      ],
      documentUuid: data.uuid,
      country: 'us',
    }),
  );
  if (err) {
    console.error({ ...err });
    return false;
  }
  if (setSubmitting) {
    setSubmitting(false);
  }
  const documentData = camelizeKeys(JSON.parse(r.data.body))
    ?.documentVerification?.documentData;
  const address = documentData?.parsedAddress?.physicalAddress?.split(' ');
  setPrequalification((state) => ({
    ...state,
    driverLicenseUid: data.uuid,
    name: documentData.firstName,
    lastName: documentData.surName,
    birthday: documentData.dob,
    houseNumber: address?.[0],
    address: address.slice(1, address.lenght).join(' '),
    zipCode: documentData?.parsedAddress?.zip,
    state: documentData?.parsedAddress?.state,
    city: documentData?.parsedAddress?.city,
  }));
  setSelectedFlow('confirmPrequal');
};

export const UploadDocForm = ({
  token,
  setPrequalification,
  setSelectedFlow,
}) => {
  const theme = useTheme();
  const { setErrorAlertMessage } = useAlertContext();

  const onSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();

    buildFormData(formData, {
      documentType: 'license',
      documentFront: values.driverLicenseF,
      documentBack: values.driverLicenseB,
    });

    setSubmitting(true);
    const [err, r] = await to(
      axios.post(`https://upload.socure.com/api/3.0/documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `SocureApiKey ${process.env.REACT_APP_SOCURE_PUBLIC_KEY}`,
        },
      }),
    );
    if (err && err.response) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return false;
    }
    const resData = camelizeKeys(r.data);
    await onVerifyDocument(resData, {
      token,
      setPrequalification,
      setSelectedFlow,
      setSubmitting,
    });
  };

  return (
    <Formik
      initialValues={{
        driverLicenseF: null,
        driverLicenseB: null,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Text textSize="md" bold>
                  Upload your Driver&apos;s License
                </Text>
              </Grid>
              <Grid item xs={12} md={6}>
                <Text textSize="sm+" semibold>
                  Front Side
                </Text>
                <DriverLicenseInput name="driverLicenseF" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Text textSize="sm+" semibold>
                  Back Side (optional)
                </Text>
                <DriverLicenseInput name="driverLicenseB" />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginTop="3rem">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    <Text textSize="md" bold textColor="white">
                      SUBMIT
                    </Text>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {isSubmitting && (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <PulseLoader
                      color={theme.palette.primary.light}
                      size="1rem"
                    />
                    <Text textSize="sm+" semibold textColor="secondary">
                      Please wait while our systems scan your document, this
                      process may take up to 30 seconds
                    </Text>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const validationSchema = Yup.object({
  driverLicenseF: Yup.mixed().required('Field Required'),
  driverLicenseB: Yup.mixed(),
});
