import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Tabs,
  Tab,
  Paper,
  Button,
  Grid,
  Typography,
  Badge,
} from '@material-ui/core';
import to from 'await-to-js';
import { protectedGet } from 'services/http';
import Info from '@material-ui/icons/Info';
import { Documents } from 'pages/ApplicationDetail/DocumentsTab';
import { useUserContext } from 'services/hooks/useUser';
import Timeline from '../../TimelineTab/Timeline';
import Notes from '../../NotesTab/Notes';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0 0 12px rgba(116,125,140,0.1)',
    padding: 8,
  },
  container: {
    height: 590,
    marginTop: 30,
    overflowY: 'scroll',
    paddingLeft: 10,
    paddingRight: 17,
  },
  createLoanButton: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  editLoan: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  importantInfoComplement: {
    color: '#1E272E',
    display: 'flex',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.46,
    marginTop: '1rem',
  },
  badgeClass: {
    marginLeft: '10px',
    '& .MuiBadge-badge': {
      color: 'white',
    },
  },
}));

const Sidebar = ({
  applicationStatus,
  applicationNumber,
  createLoanEnvelope,
  handleLoanCreation,
  documents,
  values,
  hasLoan,
  updateLoan,
  handleStartProject,
  projectStatus,
  openStipulations,
  closedStipulations,
  sendedLoanDocs,
  processingLoanDocs,
  fetchedData,
  setFetchedData,
  draftStatus,
  loanId,
  signatureMethod,
  application,
  dealStructs,
  loan,
  changeOrderDetails,
  showLoader,
  setShowLoader,
}) => {
  const selectedDS = dealStructs.find((DS) => DS.id === values?.dealStructId);
  const { user } = useUserContext();
  const classes = useStyles();
  const [documentsTab, setDocumentsTab] = useState(1);
  const [unreadMsg, setUnreadMsg] = useState(0);
  const [msgStatusUpdated, setMsgStatusUpdated] = useState(false);
  const [unreadStips, setUnreadStips] = useState(0);
  const handleChange = (event, newValue) => {
    setDocumentsTab(newValue);
  };
  const isAppCanceled = draftStatus === 'canceled';
  const getProperSendLoanMessage = (application, values, hasLoan) => {
    return !application.hasDriverLicenses
      ? 'DL should be uploaded to generate the loan documents'
      : isAppCanceled
        ? 'Application is in cancelled status'
        : !values.dealStructId
          ? 'Deal structs sholud be selected'
          : !values.baseProjectAmount
            ? 'Base project amount is mandatory'
            : !values.projectType
              ? 'Please select poject type'
              : hasLoan
                ? 'Has loan already'
                : !values.validBaseProjectAmount
                  ? 'Kindly enter valid base project amount'
                  : selectedDS?.stipPay && application.meta.hasStipPay && !values?.stipPay
                    ? 'Stip pay is needed'
                    : application.status === 'needs_review'
                      ? 'Application is in needs to be reviwed status'
                      : '';
  };
  const getProperCreateLoanMessage = (application) => {
    return isAppCanceled
      ? 'Application is in cancelled status'
      : application.status === 'needs_review'
        ? 'Your application is being reviwed by powerpay team'
        : application.status === 'rejected'
          ? 'Application is in rejected status'
          : !user.data.meta.canManageResources
            ? 'User does not have access to create loan'
            : '';
  };
  useEffect(() => {
    let numOfUnreadStips = 0;

    if (application) {
      if (documents?.documents?.length > 0) {
        documents?.documents.map((doc) => {
          if (doc?.status === 'needed') {
            numOfUnreadStips += 1;
          }
          return '';
        });
      }
      if (openStipulations.length > 0) {
        openStipulations.map((stips) => {
          if (!stips?.file) {
            numOfUnreadStips += 1;
          }
          if (stips?.file?.status === 'needed') {
            numOfUnreadStips += 1;
          }
          return '';
        });
      }
      if (closedStipulations.length > 0) {
        closedStipulations.map((stips) => {
          if (stips?.file?.status === 'needed') {
            numOfUnreadStips += 1;
          }
          return '';
        });
      }
      setUnreadStips(numOfUnreadStips);
    }
  }, [application]);
  useEffect(async () => {
    let numOfUnreadMsg = 0;
    const [err, rawApplication] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes`,
      ),
    );
    if (rawApplication?.data?.data?.length > 0) {
      rawApplication?.data?.data.map((note) => {
        if (note.attributes.read_by === null && note.attributes.admin_note) {
          numOfUnreadMsg += 1;
        }
        return numOfUnreadMsg;
      });
      setUnreadMsg(numOfUnreadMsg);
    }
  }, [application, msgStatusUpdated]);

  const returnLabel = (label, gridSize) => {
    const counter = label === 'Stips & Docs' ? unreadStips : unreadMsg;
    return (
      <Grid container >
        <Grid item xs={gridSize} sm={gridSize} md={gridSize} lg={gridSize}>
          {label}
        </Grid>
        <Grid item>
          <Badge
            badgeContent={counter}
            className={classes.badgeClass}
            color="primary"
            onClick={handleChange}
          >
          </Badge>
        </Grid>
      </Grid>);
  };
  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <Tabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          value={documentsTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {' '}
          <Tab label={returnLabel('Stips & Docs',10)} value={1} />
          <Tab value={0} label={returnLabel('Messages',8)} />
          <Tab label="TIMELINE" value={2} />
        </Tabs>
        <div className={classes.container}>
          {documentsTab === 1 && (
            <Documents
              applicationNumber={applicationNumber}
              documents={documents}
              signatureMethod={signatureMethod}
              fetchedData={fetchedData}
              isAppCanceled={isAppCanceled}
              loanId={loanId}
              openStipulations={openStipulations}
              closedStipulations={closedStipulations}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              projectStatus={projectStatus}
              sendedLoanDocs={sendedLoanDocs}
              processingLoanDocs={processingLoanDocs}
              setFetchedData={setFetchedData}
              changeOrderDetails={changeOrderDetails}
            />
          )}
          {documentsTab === 2 && (
            <Timeline applicationNumber={applicationNumber} />
          )}
          {documentsTab === 0 && (
            <div>
              <Notes
                applicationNumber={applicationNumber}
                hasUnreadMessages={application.hasUnreadMessages}
                setMsgStatusUpdated={setMsgStatusUpdated}
                msgStatusUpdated={msgStatusUpdated}
              ></Notes>
            </div>
          )}
        </div>
      </Paper>
      {!hasLoan && createLoanEnvelope && (
        <>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.createLoanButton}
            disabled={
              !application.hasDriverLicenses ||
              isAppCanceled ||
              !values.dealStructId ||
              !values.baseProjectAmount ||
              !values.projectType ||
              hasLoan ||
              !values.validBaseProjectAmount ||
              (selectedDS?.stipPay &&
                application.meta.hasStipPay &&
                !values?.stipPay) ||
              application.status === 'needs_review'
            }
          >
            SEND LOAN DOCUMENTS
          </Button>
          {(!application.hasDriverLicenses ||
            isAppCanceled ||
            !values.dealStructId ||
            !values.baseProjectAmount ||
            !values.projectType ||
            hasLoan ||
            !values.validBaseProjectAmount ||
            (selectedDS?.stipPay &&
              application.meta.hasStipPay &&
              !values?.stipPay) ||
            application.status === 'needs_review') && (
              <Typography className={classes.importantInfoComplement}>
                <Info
                  style={{ marginRight: 7, color: '#9198A0', fontSize: 13 }}
                />
                {getProperSendLoanMessage(application, values, hasLoan)}
              </Typography>
            )}
        </>
      )}

      {/* {projectStatus !== 'in_progress' &&
        projectStatus !== 'completed' &&
        projectStatus !== 'finished' &&
        projectStatus !== 'change_requested' &&
        hasLoan && (
          <Button
            variant="contained"
            color="primary"
            className={classes.createLoanButton}
            disabled={
              isAppCanceled ||
              applicationStatus !== 'approved' ||
              projectStatus !== 'approved' ||
              !user.data.meta.canManageResources
            }
            onClick={handleStartProject}
          >
            START PROJECT
          </Button>
        )}
      {!['in_progress', 'finished', 'change_requested', 'completed'].includes(
        loan?.status,
      ) &&
        !['rejected'].includes(applicationStatus) &&
        hasLoan && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.editLoan}
            onClick={updateLoan}
            disabled={
              isAppCanceled ||
              projectStatus === 'in_progress' ||
              projectStatus === 'completed' ||
              projectStatus === 'change_requested' ||
              !user.data.meta.canManageResources
            }
          >
            EDIT LOAN ENVELOPE
          </Button>
        )} */}
    </>
  );
};

export default Sidebar;
