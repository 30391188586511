import React, { useState, useEffect, useContext } from 'react';
import to from 'await-to-js';
import { map } from 'ramda';
import { protectedGet } from 'services/http';

const AddressLocationsContext = React.createContext();
export const {
  Consumer: AddressLocationsConsumer,
  Provider,
} = AddressLocationsContext;

export function AddressLocationsProvider({ children }) {
  const [states, setStates] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      const [err, res] = await to(protectedGet(`/v1/addresses/states`));
      if (err) {
        return;
      }
      const st = map((key) => {
        return {
          code: key,
          name: res.data[key],
        };
      }, Object.keys(res.data));
      setStates(st);
    };
    if (states === null) {
      fetch();
    }
  }, [states]);

  return (
    <Provider
      value={{
        states,
      }}
    >
      {children}
    </Provider>
  );
}

export const useAddressLocationsContext = () => {
  const context = useContext(AddressLocationsContext);
  return context;
};

export default AddressLocationsContext;
