import React, { useState } from 'react';
import SettingsSection from 'components/SettingsSection';
import Text from 'components/TextFields/Text';
import to from 'await-to-js';
import {
    Box,
    makeStyles,
    FormControlLabel,
} from '@material-ui/core';
import { protectedPut } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { useAlertContext } from 'components/AlertContext';
import { getToken, setToken } from 'services/authentication';
import { decamelizeKeys } from 'humps';
import {
    StepSubtitle,
    StepTitle,
} from 'pages/NewApplicationDealer/DealerSection';
const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '40px',
    },
    checkbox: {
        verticalAlign: 'top',
        padding: 0,
        marginRight: '0.5rem',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuItem: {
        padding: '0.375rem 0',
        cursor: 'pointer',
    },
    logContentRead: {
        color: '#1E272E',
        fontSize: 14,
        letterSpacing: 0.55,
        lineHeight: '12px',
    },
    button: {
        marginTop: '1rem',
        minWidth: 130
    },

}));
const getText = (dealerCode, dealerLevelRole, openApiKeyDetails) => {
    const classes = useStyles();
    const { setAlertMessage } = useAlertContext();
    const { user, setUser, userStatus } = useUserContext();
    const [defaultStagedFunding, setDefaultStagedFunding] = useState(user.data.attributes.defaultStageFunding);
    const [showFundingPref, setShowFundingPref] = useState(user.data.attributes.showNetFundingAndCost);
    const onSubmit = () => {
        const update = async () => {
            const [err, userRes] = await to(
                protectedPut(`/v1/users/${user.data.attributes.id}`, {
                    user: {
                        dealer_network_attributes: {
                            id: user.data.attributes.hierarchyLevelId,
                            default_stage_funding: defaultStagedFunding,
                            show_net_funding_and_cost: showFundingPref,
                        }
                    },
                }),
            );
            if (err) {
                return;
            }
            setAlertMessage('Operation Completed Successfully');
            user.data.attributes.defaultStageFunding = defaultStagedFunding;
            user.data.attributes.showNetFundingAndCost = showFundingPref;
            setUser(user);

            let userData = JSON.parse(getToken());
            userData = {
                ...userData,
                data: {
                    ...userData.data,
                    attributes: {
                        ...userData.data.attributes,
                        defaultStageFunding: defaultStagedFunding,
                        showNetFundingAndCost: showFundingPref,
                    },
                },
            };
            setToken(JSON.stringify(decamelizeKeys(userData)));
        };
        update();
    };
    switch (dealerLevelRole) {
        case 'dealer_network_overseer':
            return (
                <>
                    <StepTitle>Merchant Preferences</StepTitle>
                    <StepSubtitle>
                        Change your default preferences and configuration.
                    </StepSubtitle>
                    <br></br>
                    <FormControlLabel
                        classes={{
                            label: classes.logContentRead,
                        }}
                        control={
                            <Switch
                                checked={defaultStagedFunding}
                                color="secondary"
                                onChange={(e) => {
                                    setDefaultStagedFunding(e.target.checked);
                                }}
                            />
                        }
                        label="Set default Advanced Funding"
                        labelPlacement="end"
                    />
                    <br></br>
                    <FormControlLabel
                        classes={{
                            label: classes.logContentRead,
                        }}
                        control={
                            <Switch
                                checked={showFundingPref}
                                color="secondary"
                                onChange={(e) => {
                                    setShowFundingPref(e.target.checked);

                                }}
                            />
                        }
                        label="Show Merchant Fee Detail"
                        labelPlacement="end"
                    />
                    <br></br>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => onSubmit()}
                    >
                        <Text textSize="lg" textColor="white" bold>
                            SAVE
                        </Text>
                    </Button>

                </>
            );

        default:
            return 0;
    }
};

const MerchantPreferences = () => {
    const classes = useStyles();
    const { user, dealerLevelRole } = useUserContext();
    return (
        <SettingsSection className={classes.dealerCodeSection}>
            <Text textSize="lg" textColor="primary">
                {getText(
                    user?.data?.attributes?.dealerCode,
                    dealerLevelRole,
                    user?.data?.attributes?.openApiKeyDetails,
                )}
            </Text>
        </SettingsSection>
    );
};
export default MerchantPreferences;