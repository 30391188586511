import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
  toolbar: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export const PageTableHeader = ({ children }) => {
  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar)}>
      <Box className={classes.wrapper}>{children}</Box>
    </Toolbar>
  );
};
