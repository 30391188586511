import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NotImplemented } from 'pages/NotImplemented';
import { useRedirectToDefaultUrl } from 'services/hooks/useRedirectToDefaultUrl';
import InitialRoute from 'components/InitialRoute';
import ProtectedRoute from 'components/ProtectedRoute';
import PublicRoute from 'components/PublicRoute';
import { useUserContext } from 'services/hooks/useUser';
import { protectedRoutes, publicRoutes } from './routes';

const Router = () => {
  useRedirectToDefaultUrl();
  const { user } = useUserContext();
  const routes = user ? protectedRoutes : publicRoutes;
  return (
    <>
      <Switch>
        {routes.map(({ component, ...route }) => {
          if (route.type === 'redirect') {
            return (
              <Route key={route.id} exact path={route.path}>
                <Redirect to={route.to} />
              </Route>
            );
          }
          if (route.type === 'protectedRoute') {
            return (
              <ProtectedRoute
                key={route.id}
                exact
                comp={component}
                {...route}
              />
            );
          }
          if (route.type === 'initialRoute') {
            return (
              <InitialRoute
                key={route.id}
                path={route.path}
                type={route.id}
                component={component}
              />
            );
          }
          if (route.type === 'publicRoute') {
            return (
              <PublicRoute
                key={route.id}
                exact={route.exact}
                path={route.path}
                component={component}
              />
            );
          }
          return (
            <Route
              key={route.id}
              exact
              path={route.path}
              component={component}
            />
          );
        })}
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <ProtectedRoute comp={NotImplemented} title="Error" key="error" />
      </Switch>
    </>
  );
};

export default Router;
