import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Text from '../TextFields/Text';
import LinkText from '../TextFields/LinkText';

const useStyles = makeStyles((theme) => ({
  container: {},
  contactUs: {
    fontSize: 14,
    lineHeight: '18px',
    marginTop: 7,
    marginBottom: 54,
    color: '#1E272E',
  },
  copyright: {
    display: 'block',
    color: '#636A72',
  },
}));

const Footer = () => {
  const classes = useStyles();
  const copyRightText = `GetPowerPay ${new Date().getFullYear()}-${new Date().getFullYear()+1}. Privacy Policy © All rights reserved.`;
  return (
    <div className={classes.container}>
      <Divider style={{ marginBottom: 12 }} />
      <LinkText
        variant="subtitle1"
        link="/"
        text="Questions?"
        textFontSize={16}
        textFontWeight={600}
        textLineHeight="16px"
        textLetterSpacing={0.6}
        textColor="#1E272E"
      />
      <div className={classes.contactUs}>
        Please feel free to{' '}
        <LinkText
          variant="subtitle1"
          link="https://getpowerpay.com/contact-us/"
          text="contact us "
          textFontSize={14}
          textFontWeight={500}
          textLineHeight="16px"
          textLetterSpacing={0.6}
          newWindow
        />
        or read our{' '}
        <LinkText
          variant="subtitle1"
          link="https://getpowerpay.com/homeowner-faqs/"
          text="FAQ'S"
          textFontSize={14}
          textFontWeight={500}
          textLineHeight="16px"
          textLetterSpacing={0.6}
          newWindow
        />
      </div>
      <div className={classes.copyright}>
        <Text
          text={copyRightText}
          textVariant="subtitle1"
          textFontSize={12}
          textFontWeight={400}
          textLetterSpacing={0.55}
          textLineHeight="12px"
        />
      </div>
    </div>
  );
};

export default Footer;
