import React, { useEffect, useState } from 'react';
import { useFormikContext, yupToFormErrors } from 'formik';
import to from 'await-to-js';
import * as yup from 'yup';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ToggleButtons from 'components/Buttons/ToggleButtons';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import { OutlinedTextField } from 'components/InputFields/InputField';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';
import { StateSelectField } from 'components/InputFields/StateSelectField';
import { SelectField } from 'components/InputFields/SelectField';
import {
  BusinessProductSelect,
  BusinessTypeSelect,
} from 'components/InputFields/BusinessTypeSelect';
import { protectedGet } from 'services/http';
import { Box, Link, useTheme } from '@material-ui/core';
import {
  StepSubtitle,
  StepTitle,
} from 'pages/NewApplicationDealer/DealerSection';
import AddressAutocomplete from 'pages/NewApplicationDealer/AddressAutocomplete';

const validationSchema = yup.object({
  streetAddress: yup.string().required('Field Required').trim(),
  // addressLine: yup.string().trim(),
  state: yup.string().required('Field Required').trim(),
  city: yup.string().required('Field Required').trim(),
  zipCode: yup.string().required('Field Required').trim(),
  businessName: yup.string().required('Field Required').trim(),
  dbaBusinessName: yup.string().required('Field Required').trim(),
  businessValue: yup.string().required('Field Required').trim(),
  annualFinanceVolume: yup.string().required('Field Required').trim(),
  websiteUrl: yup
    .string()
    .required('Field Required')
    .matches(
      /[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi,
      {
        excludeEmptyString: true,
        message: 'Invalid Url',
      },
    )
    .trim(),
  mobilePhone: yup
    .string()
    .required('Field Required')
    .matches(/^(?:\+1)?\(\d{3}\)\d{3}-\d{4}$/gm, {
      excludeEmptyString: true,
      message: 'Invalid Phone Number',
    })
    .trim()
    .nullable(),
  productType: yup.string().required('Field Required').trim(),
  businessType: yup.string().required('Field Required').trim(),
  firstname: yup.string().required('Field Required').trim(),
  lastname: yup.string().required('Field Required').trim(),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Field Required')
    .trim(),
  ownerStatus: yup.string().required('Field Required').trim(),
});

const useStyles = makeStyles((theme) => ({
  linkText: {
    alignItems: 'center',
    color: '#9198A0',
    display: 'flex',
    fontSize: '0.875rem',
    letterSpacing: 0.6,
    lineHeight: '18px',
    marginRight: 48,
    fontWeight: 600,
    [`&:hover`]: {
      cursor: 'pointer',
    },
  },
  buttonsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  actionButton: {
    height: 40,
    width: '24rem',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  terms: {
    color: '#636A72',
    fontSize: 12,
    letterSpacing: 0.12,
    lineHeight: '16px',
    textAlign: 'justify',
  },
  boldLink: {
    fontWeight: 600,
  },
  inputField: {
    marginTop: 12,
    marginBottom: 12,
    maxWidth: 345,
    minHeight: 61,
    width: '100%',
  },
  inputLabel: {
    marginLeft: 15,
  },
  button: {
    width: 175,
    height: 46,
    padding: '9px 0',
  },
}));

const SecondPage = ({
  setCurrentPage,
  ownerValue,
  setOwnerValue,
  showLegalBusinessName,
  gridSize,
  user,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const [productTypes, setProductTypes] = useState();
  const [formSubmit, setFormSubmit] = useState(false);
  const { values, setErrors, setFieldValue, handleSubmit } = useFormikContext();
  useEffect(async () => {
    if (!productTypes) {
      const [err, response] = await to(
        protectedGet(`${process.env.REACT_APP_BASE_URL}/v2/loan_products`),
      );
      if (response) {
        setProductTypes(response.data);
      }
    }
  }, []);
  const onHandleSubmit = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    const validate = async () => {
      const [validationErrors] = await to(
        validationSchema.validate(values, {
          abortEarly: false,
        }),
      );

      if (validationErrors) {
        setErrors(yupToFormErrors(validationErrors));
        return false;
      }
      handleSubmit(e);
      return true;
    };

    validate();
  };
  const handleNext = (e) => {
    e.preventDefault();
    const validate = async () => {
      const [validationErrors] = await to(
        validationSchema.validate(values, {
          abortEarly: false,
        }),
      );

      if (validationErrors) {
        setErrors(yupToFormErrors(validationErrors));
        return false;
      }
      setCurrentPage('secondPage');
      return true;
    };

    validate();
  };
  const autoFillAddress = (name, value) => {
    setFieldValue(name, value);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text
            textVariant="body1"
            textColor="#636A72"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            text="BUSINESS INFORMATION"
          />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField
            name="businessName"
            type="text"
            label="Legal Business Name"
          />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField
            name="dbaBusinessName"
            type="text"
            label="DBA Business Name"
          />
        </Grid>
        <Grid item xs={gridSize || 12}>
          {productTypes && (
            <BusinessProductSelect
              name="productType"
              label="Product Type"
              businessData={productTypes}
            />
          )}
        </Grid>

        {values.productType && (
          <Grid item xs={gridSize || 12}>
            <BusinessTypeSelect
              name="businessType"
              label="Business Type"
              businessData={productTypes}
            />
          </Grid>
        )}
        {!showLegalBusinessName && (
          <Grid item xs={gridSize || 12}>
            <OutlinedTextField
              name="referralName"
              type="text"
              label="Referral / Affiliate Name"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Text
            textVariant="body1"
            textColor="#636A72"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            text="BUSINESS ADDRESS"
            style={{
              marginTop: '10px',
            }}
          />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <AddressAutocomplete
            name="streetAddress"
            autoFillAddress={autoFillAddress}
            formSubmitAction={formSubmit}
          ></AddressAutocomplete>
          {/* <OutlinedTextField
          
            onBlur={(e)=>{autoFillCity(e,setFieldValue)}}
            type="text"
            label="Street Address"
          /> */}
        </Grid>
        {/* <Grid item xs={gridSize || 12}>
          <OutlinedTextField
            name="addressLine"
            type="text"
            label="Street Address 2"
          />
        </Grid> */}
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField name="city" type="text" label="City" />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <StateSelectField label="State" name="state" />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField
            name="zipCode"
            type="number"
            label="Zip Code"
            onChange={(e) =>
              setFieldValue('zipCode', e.target.value.replace(/\D/, ''))
            }
          />
        </Grid>

        <Grid item xs={gridSize || 12}>
          <OutlinedTextField
            name="websiteUrl"
            type="text"
            label="Website URL"
          />
        </Grid>

        <Grid item xs={gridSize || 12}>
          <PhoneNumberField
            name="mobilePhone"
            label="Mobile Phone"
            id="mobile-phoneNumber-input"
          />
        </Grid>

        <Grid item xs={gridSize || 12}>
          <SelectField name="businessValue" label="Annual Sales Volume">
            <MenuItem value={0}>Under 2 million</MenuItem>
            <MenuItem value={1}>From 2 to 5 million</MenuItem>
            <MenuItem value={2}>From 5 to 10 million</MenuItem>
            <MenuItem value={3}>Over 10 million</MenuItem>
          </SelectField>
        </Grid>

        <Grid item xs={gridSize || 12}>
          <SelectField name="annualFinanceVolume" label="Annual Finance Volume">
            <MenuItem value={0}>Under 2 million</MenuItem>
            <MenuItem value={1}>From 2 to 5 million</MenuItem>
            <MenuItem value={2}>From 5 to 10 million</MenuItem>
            <MenuItem value={3}>Over 10 million</MenuItem>
          </SelectField>
        </Grid>
        {/* 
        <Grid item xs={gridSize || 12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="button"
            onClick={handleNext}
          >
            <Text
              textSize="md"
              bold
              style={{ display: 'flex', alignItems: 'center' }}
              textColor="white"
            >
              NEXT{' '}
              <Icon icon="arrow-forward" style={{ marginLeft: '0.25rem' }} />
            </Text>
          </Button>
        </Grid> */}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text
            textVariant="body1"
            textColor="#636A72"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            style={{
              marginTop: '10px',
            }}
            textLineHeight="16px"
            text="PERSONAL INFORMATION"
          />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField name="firstname" type="text" label="First Name" />
        </Grid>
        <Grid item xs={gridSize || 12}>
          <OutlinedTextField name="lastname" type="text" label="Last Name" />
        </Grid>

        <Grid item xs={gridSize || 12}>
          <OutlinedTextField name="email" type="email" label="Email" />
        </Grid>

        <Grid item xs={12}>
          <Text
            textVariant="body1"
            textColor="#1E272E"
            textFontWeight={500}
            textFontSize={15}
            textLetterSpacing={0.56}
            textLineHeight="24px"
            text="Are you a Business Owner?"
          />
        </Grid>

        <Grid item xs={gridSize || 12}>
          <div className={classes.toggleWrapper}>
            <ToggleButtons
              values={['yes', 'no']}
              setFieldValue={setOwnerValue}
              hasValue={ownerValue}
            />
          </div>
        </Grid>

        {/* <Grid item xs={4} className={classes.linkWrapper}>
          <Typography
            className={classes.linkText}
            onClick={() => setCurrentPage('firstPage')}
          >
            <KeyboardArrowLeft style={{ marginRight: 5 }} />
            Back
          </Typography>
        </Grid> */}

        {!gridSize && (
          <Grid item xs={12}>
            <StepSubtitle>
              By clicking the button below and submitting this application, you
              are certifying that you have read and agree to the
              <Link
                href="https://getpowerpay.com/account_opening_disclosures/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' Disclosures'}
              </Link>
              ,{' '}
              <Link
                href="https://getpowerpay.com/service-provider-guidelines/"
                rel="noopener noreferrer"
                target="_blank"
              >
                . Service Provider Guidelines
              </Link>{' '}
              and{' '}
              <Link
                href="https://getpowerpay.com/program-agreement/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Program Agreement
              </Link>
              . Sole Proprietorships and Partnerships: We may verify credit
              history and secure credit reports of Applicant, principals,
              owners, or partners. You hereby approve any third party to provide
              reports to PowerPay®.
            </StepSubtitle>
          </Grid>
        )}
        {gridSize ? (
          <>
            <Grid item xs={12} className={classes.formContainer}>
              <Box
                marginTop="2rem"
                paddingY="1rem"
                paddingX="0.5rem"
                borderRadius="6px"
                border={`solid 1px ${palette.divider}`}
                display="flex"
                alignItems="center"
              >
                <Icon icon="check-email" width={80} height={80} />
                <Box marginLeft="0.5rem">
                  <Text textSize="md+" bold textColor={palette.text.primary}>
                    Invite this dealer to join Powerpay
                  </Text>
                  <Text
                    textSize="sm"
                    semibold
                    textColor={palette.text.secondary}
                  >
                    We’ll send an email to this dealer to create <br />
                    an account and manage their own deals
                  </Text>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" marginTop="3rem">
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                  type="button"
                  onClick={onHandleSubmit}
                >
                  <Text textSize="md" bold>
                    ADD NEW DEALER
                  </Text>
                </Button>
              </Box>
            </Grid>
          </>
        ) : (
          <Grid item xs={gridSize || 12}>
            <Button
              style={{
                width: '100%',
              }}
              variant="contained"
              color="primary"
              className={classes.button}
              type="button"
              onClick={onHandleSubmit}
            >
              <Text
                textSize="md"
                bold
                style={{ display: 'flex', alignItems: 'center' }}
                textColor="white"
              >
                CREATE ACCOUNT
              </Text>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SecondPage;
