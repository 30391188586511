import React, { useState, useEffect } from 'react';
import { makeStyles, Typography, Divider } from '@material-ui/core';
import { camelizeKeys } from 'humps';
import Info from '@material-ui/icons/Info';
import { protectedGet } from 'services/http';
import TimelineStageCard from './TimelineStageCard';

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: '0.75rem 0',
  },
  importantInfo: {
    backgroundColor: theme.palette.text.lightGray,
    borderRadius: 4,
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0.5,
    marginBottom: 14,
    padding: 10,
  },
  importantInfoComplement: {
    color: theme.palette.text.primary,
    display: 'block',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.46,
  },
  infoIcon: {
    marginRight: '0.5rem',
    color: '#9198A0',
    fontSize: '0.875rem',
  },
}));

const Timeline = ({ applicationNumber }) => {
  const [timelineData, setTimelineData] = useState(null);
  const classes = useStyles();
  useEffect(() => {
    async function fetch() {
      const response = camelizeKeys(
        (
          await protectedGet(
            `${process.env.REACT_APP_BASE_URL}/v1/application_logs/stages?application_id=${applicationNumber}`,
          )
        )?.data,
      );
      setTimelineData(response?.data);
    }
    fetch();
  }, []);
  return (
    <div className={classes.container} data-testid="timeline-container">
      {/* MAPPING LOGS UNDER CORRESPONDING APPLICATION STAGE */}
      <TimelineStageCard timelineData={timelineData} />
      {timelineData && <Divider className={classes.divider} />}
      <Typography className={classes.importantInfo}>
        <Info className={classes.infoIcon} />
        Keep Updated!
        <span className={classes.importantInfoComplement}>
          This is the start of your PowerPay Application process, here you will
          receive information regarding its status.
        </span>
      </Typography>
    </div>
  );
};

export default Timeline;
