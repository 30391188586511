export const TermsAndConditionsText = () => {
  return (
    <div>
      <h2>Disclaimer</h2>
      <p>
        By clicking button below, I, the applicant, agree to the following: I've
        read, understand, accept, and Consent to the terms of the{' '}
        <a
          href="https://getpowerpay.com/account_opening_disclosures/"
          target="_blank"
        >
          Account Opening Disclosures
        </a>
        , the{' '}
        <a href="https://getpowerpay.com/esign_consent/" target="_blank">
          Electronic Records and Communication
        </a>{' '}
        and the{' '}
        <a href="https://getpowerpay.com/privacy-policy/" target="_blank">
          Privacy Policy
        </a>
        , and agree to receive electronic disclosures with any loan obtained as
        a result of this application. If a joint application is filed, these
        Terms of Use apply to each of the applicants equally: I authorize
        PowerPay and/or any Lender in its Lender Network, its representatives,
        successors, and assigns to investigate my creditworthiness and obtain a
        credit report from me for any lawful purpose, including, but not limited
        to, any extension of credit, renewal, servicing and collections. Upon
        request, Lender will inform me of whether a credit report was obtained
        and if a report was obtained, the names and addresses of the credit
        reporting agencies that furnished the report. All credit offers expire
        30 days from the initial application submission. If the loan has been
        executed within the initial 30 day period but has not been fully funded
        within 180 calendar days from the application submission date Powerpay
        and/or the Lender reserves the right to rescind or change the credit
        offer. I authorize PowerPay to contact me at the phone number I provided
        via text, an automated telephone dialing system, or artificial or
        prerecorded voice messages, for any purpose. This is not required to
        apply. I can opt-out by contacting PowerPay at 1-800-397-4485.
      </p>
    </div>
  );
};
