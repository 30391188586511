import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PaymentCard from './PaymentCard';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },

  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
  },
  content: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  total: {
    borderRadius: 4,
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      maxWidth: 195,
    },
  },
}));

const PaymentTab = ({
  loanDetails,
  applicationNumber,
  setFetchedData,
  changeOrderDetails,
  appData,
  applicationDetail,
}) => {
  const classes = useStyles();
  const [showPayments,setShowPayments] = useState(true);
  const payments = [...loanDetails.payments].sort((prev, next) => {
    const p = Number(prev.id);
    const n = Number(next.id);

    if (p < n) {
      return -1;
    }

    if (p > n) {
      return 1;
    }

    return 0;
  });
  const [showTotalProjectAmount, setShowTotalProjectAmount] = useState(true)

  const anyPendingRefund = loanDetails?.refunds?.some(
    (element) =>
      element.status === 'pending',
  );

  const anyConfirmedRefund = loanDetails?.refunds?.some(
    (element) =>
      element.status === 'confirmed',
  );

  const disableRequestAndFinish = anyPendingRefund || anyConfirmedRefund;

  return (
    <div className={classes.container}>
      {payments.map((payment, index) => {
        if(!showPayments && index <payments.length -1 ){
          return '';
        }
        return (
          <PaymentCard
            key={payment.id}
            applicationNumber={applicationNumber}
            appData={appData}
            loanId={loanDetails.id}
            loanDetails={loanDetails}
            payment={payment}
            index={index}
            applicationDetail={applicationDetail}
            setShowPayments={setShowPayments}
            payments={payments}
            setFetchedData={setFetchedData}
            changeOrderDetails={changeOrderDetails}
            setShowTotalProjectAmount={setShowTotalProjectAmount}
            disableRequestAndFinish={disableRequestAndFinish}
          />
        );
      })}

      {showTotalProjectAmount &&
        <div className={classes.total}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>
              TOTAL PROJECT PAYMENT
            </Typography>
          </div>
          <div className={classes.contentWrapper}>
            <Typography className={classes.content}>
              <span className={classes.contentLabel}>Payment</span>
              <NumberFormat
                value={loanDetails.dealerNetFunding}
                displayType="text"
                prefix="$"
                thousandSeparator
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          </div>
        </div>
      }
    </div>
  );
};

export default PaymentTab;
