import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PhoneVerification from 'components/PhoneVerification';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: -40,
    },
  },
}));

const ThirdPage = ({ setCurrentPage, values, errors }) => {
  const classes = useStyles();

  const handleNext = () => {
    setCurrentPage('thirdPage');
  };

  return (
    <div className={classes.form}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <PhoneVerification
            isSkippable
            handleNext={handleNext}
            phone={values.phone}
            errors={errors}
            isHomeOwner
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ThirdPage;
