import React from 'react';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { useHistory } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { protectedPost } from 'services/http';
import to from 'await-to-js';

import { Button } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Icon from 'components/Icon';

import Text from 'components/TextFields/Text';

export const onNewApplicationClick = (ev, history, setErrorAlertMessage) => {
  ev.stopPropagation();
  ev.preventDefault();
  history.push('/applications/start')
  // const createNewApp = async () => {
  //   const [err, r] = await to(
  //     protectedPost(
  //       '/v1/applications',
  //       decamelizeKeys({
  //         application: {
  //           filler: 1,
  //         },
  //         dealer: {
  //           filler: 1,
  //         },
  //       }),
  //     ),
  //   );

  //   if (err && err.response) {
  //     setErrorAlertMessage(err.response.data.message || 'Error');
  //     return;
  //   }

  //   const application = normalize(camelizeKeys(r.data)).get(['id']);
  //   history.push('/applications/new', {
  //     applicationId: application.id,
  //   });
  // };

  // createNewApp();
};

export const NewApplicationButton = ({
  text = 'New Application',
  onClick,
  disabled,
}) => {
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();

  return (
    <>
      <Hidden mdDown>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '32px',
            minWidth: '32px',
            marginRight: '1rem',
            padding: '0 1.5rem',
            borderRadius: '4px',
            boxShadow: 'none',
            backgroundColor: disabled ? 'rgba(255, 255, 255, 0.6)' : null,
            color: disabled ? 'gray' : null,
          }}
          disabled={disabled}
          onClick={(ev) =>
            // history.push('/applications/start')
            onClick
              ? onClick(ev)
              : onNewApplicationClick(ev, history, setErrorAlertMessage)
          }
        >
          <Icon icon="add-circle" width={16} height={16} />
          <Text
            textVariant="body1"
            textFontSize="0.75rem"
            textFontWeight={600}
            style={{ marginLeft: '0.25rem' }}
          >
            {text}
          </Text>
        </Button>
      </Hidden>
      <Hidden lgUp>
        <Button
          variant="contained"
          color="primary"
          style={{
            height: '32px',
            minWidth: '32px',
            width: '32px',
            marginRight: '1rem',
            padding: '0',
            borderRadius: '4px',
            boxShadow: 'none',
          }}
          disabled={disabled}
          onClick={(ev) =>
            onClick
              ? onClick(ev)
              : onNewApplicationClick(ev, history, setErrorAlertMessage)
          }
        >
          <Icon icon="add-circle" width={20} height={20} />
        </Button>
      </Hidden>
    </>
  );
};

export const TableActionButton = (props) => <NewApplicationButton {...props} />;

export const VanillaNewAppButton = ({ style, children }) => {
  const history = useHistory();
  return (
    <Button
      variant="contained"
      color="primary"
      style={{
        borderRadius: '4px',
        boxShadow: 'none',
        ...style,
      }}
      onClick={() => {
        history.push(`/apply`);
      }}
    >
      <Text bold textColor="white">
        {children}
      </Text>
    </Button>
  );
};
