import { makeStyles } from '@material-ui/core/styles';

/**
 * @description this file exists because of some weird code shenanigans that were happening.
 *  This was previously defined in @see ContractFormLayout.js , but it was weirdly enough not being used in that very same file
 *  I have thus decided to move it to its own separate file, as it was being imported by several other ones, thus causing the ContractFormLayout to be unnecesarily loaded on memory
 *  This also makes it so that ContractFormLayout is only imported from when actually used
 */
export const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  title: {
    marginBottom: '0.5rem',
  },
  archiveButton: {
    color: theme.palette.error.main,
  },
  transparentButtonInvite: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
  },
  inviteButton: {
    marginLeft: '20px',
    marginTop: '10px',
  },
  formControlDatePicker: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  actionButton: {
    height: 40,
    width: '40%',
    margin: '12px',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '1rem 0',
      maxWidth: '32rem',
    },
  },
  containedButton: {
    color: 'white',
    marginTop: '10px',
    marginBottom: '1rem',
    // width: 198,
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  changeContactText: {
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    color: '#f79d3c',
    fontWeight: 500,
    cursor: 'pointer',
  },
  formTitle: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 25,
      marginTop: 25,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
  container: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  preFilledBox: {
    paddingTop: '2px',
  },
  heading: {
    padding: '16px',
    paddingLeft: '4px',
    paddingBottom: '2px',
  },
  contractButton: {
    left: '35%',
  },
  createLoanButton: {
    height: 40,
  },
  transparentButton: {
    background: 'none',
    border: 'none',
    marginTop: '0.5rem',
    color: '#f79d3c',
    fontWeight: 500,
    cursor: 'pointer',
  },

  addressField: {
    width: '74%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInput: {
    marginBottom: '20px',
    backgroundColor: 'white',

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInputError: {
    marginBottom: '20px',
    backgroundColor: 'white',
    '& .MuiInputBase-input': {
      color: 'red'
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInputAddress: {
    backgroundColor: 'white',

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  root: {
    padding: '80px',
    flexGrow: 1,
  },
  card: {
    position: 'relative',
    width: '30%',
    margin: '12px',
    // border: "2px solid"
  },
  btnClass: {
    marginTop: '17px',
    width: '91%',
    height: '30px',
    marginBottom: '1rem',
  },
  amount: {
    fontSize: '24px',
    float: 'left',
    paddingRight: '20px',
  },
  selectedAmount: {
    fontSize: '30px',
    float: 'left',
    paddingRight: '20px',
  },
  ruleLine: {
    marginTop: '0px',
    border: '1px solid',
  },
  updateButton: {
    marginTop: '10px',
    marginLeft: '70%',
  },
  updateButtonWithCancel: {
    marginTop: '10px',
    marginLeft: '87%',
  },
  buttonStyle: {
    backgroundColor: 'white',
  },
  formControl: {
    marginBottom: '20px',
    minWidth: '100%',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
