import React, { useCallback, useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import { pathSplit } from 'services/utils';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';

import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { VanillaButton } from 'components/Buttons';
import { useDisclosure } from 'services/hooks/useDisclosure';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxWidth: '28rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
  },
  dropzone: {
    backgroundColor: 'rgba(255,206,108,0.03)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255,206,108,0.1)',
    },
  },
  actionButton: {
    padding: '0 1rem',
    marginRight: '2.5rem',
  },
}));

const LicenseInput = ({ name, driverLicense }) => {
  const imgRef = useRef(null);

  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    if (FileReader && driverLicense) {
      if (driverLicense instanceof File) {
        var fr = new FileReader();
        fr.onload = () => {
          imgRef.current.src = fr.result;
        };
        fr.readAsDataURL(driverLicense);
      } else {
        imgRef.current.src = driverLicense.url;
      }
    }
  }, [driverLicense]);

  const { setFieldValue } = useFormikContext();

  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const onRemove = () => {
    onOpen();
  };
  const onRemoveConfirm = () => {
    setFieldValue(name, null);
    onClose();
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box
        border="solid 1px"
        borderColor={theme.palette.grey[500]}
        borderRadius="10px"
        marginTop="2rem"
        overflow="hidden"
        alignSelf="center"
        style={{
          backgroundColor: theme.palette.grey[50],
        }}
      >
        <Modal
          aria-label="delete-confirmation-modal"
          open={isOpen}
          onClose={onClose}
        >
          <Box className={classes.modal}>
            <Box display="flex" alignItems="center">
              <Icon icon="driverLicense" />
              <Text textSize="sm" style={{ marginLeft: '1rem', flexGrow: 1 }}>
                Are you sure you want to delete your Driver&apos;s License
              </Text>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <VanillaButton
                onClick={onClose}
                className={classes.actionButton}
                style={{
                  color: theme.palette.text.secondary,
                }}
              >
                DISMISS
              </VanillaButton>
              <VanillaButton
                color="primary"
                onClick={onRemoveConfirm}
                className={classes.actionButton}
              >
                DELETE
              </VanillaButton>
            </Box>
          </Box>
        </Modal>
        <img
          alt="your driver's license"
          style={{
            maxHeight: '14rem',
          }}
          ref={imgRef}
        />
        <Box padding="0.5rem 1rem" display="flex" alignItems="center">
          <Text textColor={theme.palette.text.secondary} semibold textSize="xs">
            Your Driver&apos;s License
          </Text>
          <VanillaButton
            color="primary"
            variant="contained"
            style={{
              width: '1.5rem',
              height: '1.5rem',
              marginLeft: 'auto',
            }}
            onClick={onRemove}
          >
            <DeleteOutlineIcon
              style={{
                fontSize: '1.125rem',
                color: 'white',
              }}
            />
          </VanillaButton>
        </Box>
      </Box>
    </Box>
  );
};

export const DriverLicenseInput = ({ name }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { values, setFieldValue } = useFormikContext();

  const driverLicense = pathSplit(values, name);

  const onDrop = useCallback((acceptedFiles) => {
    setFieldValue(name, acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.jpg, .jpeg, .png',
  });

  return Boolean(driverLicense) ? (
    <LicenseInput name={name} driverLicense={driverLicense} />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      border="dashed 1px"
      borderColor={theme.palette.primary.light}
      borderRadius="10px"
      padding="4rem 0"
      marginTop="2rem"
      className={classes.dropzone}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon
        icon="upload"
        color={theme.palette.primary.light}
        height={34}
        width={28}
      />
      <Text
        textSize="sm"
        textVariant="caption"
        textAlign="center"
        style={{ maxWidth: '16rem', marginTop: '1rem' }}
      >
        Simply drag and drop a .JPG or .PNG file or{' '}
        <Text
          textVariant="caption"
          textColor={theme.palette.primary.main}
          semibold
        >
          browse
        </Text>{' '}
        to upload, or take a picture with your device
      </Text>
    </Box>
  );
};
