import React from 'react';
import { map, addIndex } from 'ramda';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';

import Text from 'components/TextFields/Text';
import { DataComponent } from './DataComponent';

const useStyles = makeStyles(theme => ({
  bodyCell: {
    padding: '0.5rem 0',
  },
  pagination: {
    color: '#1E272E',
    '& .MuiButtonBase-root': {
      color: '#1E272E',
      '&.Mui-disabled': {
        color: 'rgba(30,39,46, 0.5)',
      },
    },
    '& *': {
      fontWeight: '600',
    },
    '& .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot': {
      margin: '0 1rem 0 0',
    },
    '& .MuiButtonBase-root.MuiIconButton-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      overflow: 'visible',
      '& .MuiToolbar-root': {
        padding: 0,
      },
      '& p': {
        fontSize: 12,
      },
      '& .MuiTablePagination-actions': {
        marginLeft: 4,
      },
    },
  },
  tableContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },

  linkMobile: {
    position: 'absolute',
    top: '-0.125rem',
    right: '-0.5rem',
  },
  itemWrapper: {
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      background: 'rgba(30,39,46,0.05)',
    },
    '&:not(:last-of-type)': {
      borderBottom: 'solid 1px rgba(30,39,46,0.1)',
    },
  },
}));

export const MobileList = ({
  headCells,
  rows,
  totalItems,
  perPageOptions,
  page,
  perPage,
  hidePagination,
  hideRowLines,
  handleClick,
  handleChangePage,
  handleChangeRowsPerPage,
  isSelected,
  onRowClick,
}) => {
  const classes = useStyles({ hideRowLines });
  const theme = useTheme();

  return (
    <Box flexDirection="column" className={classes.tableContainer}>
      <Box display="flex" flexDirection="column">
        {addIndex(map)((row, index) => {
          const isItemSelected = isSelected(row[0]);
          const rowKey = `item-${index}`;
          return (
            <Box
              onClick={event => handleClick(event, row[0])}
              aria-checked={isItemSelected}
              tabIndex={0}
              key={rowKey}
              selected={isItemSelected}
              display="flex"
              flexWrap="wrap"
              position="relative"
              padding="0.5rem"
              className={classes.itemWrapper}
            >
              {/* {!hideCheckboxes && (
              <Box padding="checkbox">
                <Checkbox
                  checked={isItemSelected}
                  inputProps={{ 'aria-label': row[0].value || row[0] }}
                />
              </Box>
            )} */}
              {addIndex(map)((cell, index) => {
                return (
                  <Box
                    key={`item-${index}-${headCells[index].id}-cell`}
                    className={clsx(classes.bodyCell)}
                    flex="0 0 50%"
                    paddingLeft="1rem"
                    onClick={ev => onRowClick(row)}
                  >
                    {headCells[index].label && (
                      <Text
                        textSize="mini"
                        textColor={theme.palette.text.secondary}
                        bold
                        style={{
                          marginBottom: '0.125rem',
                        }}
                      >
                        {(headCells[index].label || '').toUpperCase()}
                      </Text>
                    )}
                    <DataComponent cell={cell} isKey={index === 0} isMobile />
                  </Box>
                );
              }, row)}
            </Box>
          );
        }, rows)}
      </Box>
      <Box flex="1" />
      {!hidePagination && (
        <Box
          display="flex"
          alignItems="flex-start"
          flexDirection="column"
          padding="0.5rem 0.5rem"
        >
          <Text textSize="xs" bold>
            {`${totalItems} Application${totalItems > 1 ? 's' : ''} Found`}
          </Text>
          <Box flexGrow="1" />
          <TablePagination
            rowsPerPageOptions={perPageOptions}
            component="div"
            count={totalItems}
            rowsPerPage={perPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </Box>
      )}
    </Box>
  );
};
