import React from 'react';
import clsx from 'clsx';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { NavLink, useHistory } from 'react-router-dom';

// MATERAL
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
  makeStyles,
  useTheme,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';

import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import { useUserContext } from 'services/hooks/useUser';
import { fullName, translateDealerRole } from 'services/utils';
import { headerStyles, getLocation } from '../styles';
import { DEALER_MENU_ITEMS_NEW_FLOW,HOMEHOWNER_MENU_ITEMS_NEW_FLOW } from './MenuItems';

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  // desktop drawer
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9) + 1,
    },
  },
  desktopPaperDrawer: {
    borderRight: 'none',
    '& MuiList-root': {
      border: '1px solid #ECEFF2',
    },
  },
  // drawer component
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    borderBottom: 'solid 1px',
    borderBottomColor: '#ECEFF2',
    ...theme.mixins.toolbar,
  },
  itemList: { flexGrow: 1, padding: 8 },
  listItemIcon: { minWidth: 0 },
  menuButton: {
    color: theme.palette.primary.main,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
  },
  listItem: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 18,
    marginBottom: 18,
  },
  listItemText: {
    fontWeight: 600,
    letterSpacing: 0.4,
    lineHeight: '10px',
    border: 'none',
    margin: '10px 0',
    marginLeft: '16px',
    whiteSpace: 'nowrap',
  },
  avatar: {
    backgroundColor: 'rgba(30,39,46,0.1)',
    color: '#1E272E',
    fontSize: '1rem',
    fontWeight: 600,
    height: 40,
    width: 40,
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
    cursor: 'default',
    textDecoration: 'none !important',
    outline: 'none !important',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const DrawerComponent = ({ isOpen, onToggle }) => {
  const { location } = useHistory();

  const classes = useStyles();
  const headerClasses = headerStyles({
    location: getLocation(location),
  });
  const theme = useTheme();

  const { user, dealerLevelRole, userStatus } = useUserContext();
  const {
    data: { type, attributes },
  } = user;
  const dealerMenuItems = DEALER_MENU_ITEMS_NEW_FLOW;
  const homeOwnerMenuItems = HOMEHOWNER_MENU_ITEMS_NEW_FLOW;
  const dashboardRoutes =
    type === 'dealer' ? dealerMenuItems : homeOwnerMenuItems;

  return (
    <>
      <div
        className={clsx(
          'sidenavToolbar',
          classes.toolbar,
          headerClasses.dynamicHeader,
        )}
      >
        {isOpen ? (
          <>
            <Icon icon="logo" height="31" width="150" />
            <IconButton onClick={onToggle} className={classes.menuButton}>
              {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </>
        ) : (
          <IconButton onClick={onToggle} className={classes.menuButton}>
            <Menu />
          </IconButton>
        )}
      </div>

      <List className={classes.itemList}>
        {dashboardRoutes
          .filter(
            (menuItem) =>
              !menuItem.roles || menuItem.roles.includes(dealerLevelRole),
          )
          .map((menuItem) => {
            const activeLink = location.pathname === menuItem.route;
            const disabled =
              menuItem.enableStatuses &&
              !menuItem.enableStatuses.includes(userStatus)
              || sessionStorage.getItem('passwordExpiry') ==="true";

            return (
              <Link
                component={NavLink}
                to={menuItem.route}
                key={menuItem.name}
                style={{
                  color: activeLink
                    ? theme.palette.primary.main
                    : theme.palette.text.secondary,
                }}
                disabled={disabled}
                aria-disabled={disabled}
                className={clsx({ [classes.disabled]: disabled })}
              >
                <ListItem key={menuItem.name}>
                  <Tooltip title={menuItem.name} placement="right">
                    <ListItemIcon className={classes.listItemIcon}>
                      <Icon
                        icon={menuItem.icon}
                        width={24}
                        height={24}
                        className={clsx(classes.linkIcon)}
                        opacity={disabled ? 0.3 : 1}
                        color={
                          activeLink
                            ? theme.palette.primary.main
                            : theme.palette.text.secondary
                        }
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={menuItem.name}
                    primaryTypographyProps={{
                      className: clsx(classes.listItemText, {
                        [classes.hide]: !isOpen,
                        [classes.disabled]: disabled,
                      }),
                    }}
                  />
                </ListItem>
              </Link>
            );
          })}
      </List>
      <List>
        <Divider />
        <ListItem
          style={{
            justifyContent: isOpen ? 'flex-start' : 'center',
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <Box
            marginRight="0.5rem"
            className={clsx({
              [classes.hide]: !isOpen,
            })}
          >
            <StyledBadge
              overlap="circle" // will change to "circular" after packages get updated to a newer version
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant="dot"
            >
              <Avatar className={classes.avatar}>
                {attributes.name[0].toUpperCase() +
                  attributes.lastName[0].toUpperCase()}
              </Avatar>
            </StyledBadge>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            style={{ minWidth: 0, flex: '1 0 0' }}
            className={clsx({
              [classes.hide]: !isOpen,
            })}
          >
            <Text textSize="sm+" textColor="#393F47" bold ellipsisOverflow>
              {fullName(attributes)}
            </Text>
            <Text textSize="xs" textColor="#636A72">
              {type === 'homeowner'
                ? 'Homeowner'
                : translateDealerRole(dealerLevelRole)}
            </Text>
          </Box>
          {dealerLevelRole !== 'super_admin' && (
            <NavLink
              to="/settings"
              className={classes.link}
              style={{ marginLeft: isOpen ? 'auto' : 0 }}
            >
              <IconButton
                aria-label="settings"
                style={{
                  fontSize: '1.5rem',
                }}
              >
                <Icon
                  icon="settings"
                  color={
                    location.pathname === '/settings'
                      ? theme.palette.primary.main
                      : theme.palette.text.secondary
                  }
                />
              </IconButton>
            </NavLink>
          )}
        </ListItem>
      </List>
    </>
  );
};

const DesktopDrawer = ({ classes, userType,openDrawer }) => {
  const { isOpen, onToggle } = useDisclosure(openDrawer);
  return (
    <Hidden mdDown>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        })}
        classes={{
          paper: clsx(classes.desktopPaperDrawer, {
            [classes.drawerOpen]: isOpen,
            [classes.drawerClose]: !isOpen,
          }),
        }}
      >
        <DrawerComponent
          isOpen={isOpen}
          onToggle={onToggle}
          userType={userType}
        />
      </Drawer>
    </Hidden>
  );
};

const MobileDrawer = ({ isOpen, onToggle, classes, theme, userType }) => {
  return (
    <Hidden lgUp>
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={isOpen}
        onClose={onToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <DrawerComponent
          isOpen={isOpen}
          onToggle={onToggle}
          userType={userType}
        />
      </Drawer>
    </Hidden>
  );
};

export const Sidenav = ({
  isMobileDrawerOpen,
  onToggleMobileDrawer,
  userType,
  openDrawer
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const props = { classes, theme };
  return (
    <nav aria-label="navigation bar" style={{ minHeight: '100vh' }}>
      <MobileDrawer
        isOpen={isMobileDrawerOpen}
        onToggle={onToggleMobileDrawer}
        userType={userType}
        {...props}
      />
      <DesktopDrawer userType={userType} openDrawer={openDrawer} {...props} />
    </nav>
  );
};
