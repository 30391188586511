import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: '2px 14px 12px -12px #f79d3c',
    borderRadius: 4,
    color: 'white',
    fontSize: 18,
    lineHeight: '28px',
    letterSpacing: 0.6,
    marginTop: 12,
    marginBottom: 12,
    maxWidth: 345,
    minHeight: 40,
    padding: '9px 24px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      minWidth: 175,
      padding: '9px 12.5px',
      width: 'max-content',
    },
  },
}));

const PrimaryButton = ({
  text,
  variant,
  color,
  isSubmitting,
  type,
  disabledOptions = {},
  optionalDisabling,
  onClick,
  errors,
  children,
  style,
}) => {
  const classes = useStyles();
  const isEmpty = optionalDisabling
    ? Object.values(disabledOptions).every(
        value => value === null || value === '',
      )
    : Object.values(disabledOptions).some(
        value => value === null || value === '',
      );
  const hasErrors = Object.values(errors).some(
    value => value !== null || value !== '',
  );
  return (
    <Button
      onClick={onClick || null}
      type={type}
      className={classes.button}
      variant={variant}
      color={color}
      disabled={isSubmitting || isEmpty || hasErrors}
      style={{
        fontWeight: isSubmitting || isEmpty ? 400 : 600,
        ...style,
      }}
    >
      {children || text}
    </Button>
  );
};

export default PrimaryButton;
