import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';

import CloseIcon from '@material-ui/icons/Close';

import Icon from 'components/Icon';
import Text from 'components/TextFields/Text';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { CheckboxesFilter } from './Checkboxes';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    padding: '1rem',
    [theme.breakpoints.up('md')]: {
      width: 320,
    },
    '& .filter-section': {
      paddingTop: '1.5rem',
    },
    '& .filter-section:not(:last-child)': {
      paddingBottom: '1.5rem',
      borderBottom: 'solid 1px rgba(145, 152, 160, 0.25)',
    },
  },
}));

export const ExtendedFilters = ({ children }) => {
  const classes = useStyles();
  const { isOpen, onClose, onToggle } = useDisclosure(false);
  return (
    <>
      <IconButton aria-label="filter list" onClick={onToggle}>
        <Icon icon="table-filters" />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={onClose}>
        <Box display="flex" flexDirection="column" className={classes.drawer}>
          <Box display="flex" alignItems="center" marginBottom="1rem">
            <Text textSize="lg" bold>
              Filters
            </Text>
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{ marginLeft: 'auto', color: 'rgba(30,39,46,0.4)' }}
            >
              <CloseIcon fontSize="default" />
            </IconButton>
          </Box>

          {children}
        </Box>
      </Drawer>
    </>
  );
};

export { CheckboxesFilter } from './Checkboxes';
export { DateRangeFilter } from './DateRange';
