import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Tabs,
  Tab,
  Paper,
  Grid,
  Badge,
} from '@material-ui/core';
import to from 'await-to-js';
import { Documents } from 'pages/ApplicationDetail/DocumentsTab';
import { useUserContext } from 'services/hooks/useUser';
import { connect } from 'react-redux';
import { protectedGet } from 'services/http';
import Timeline from '../ApplicationDetail/TimelineTab/Timeline';
import Notes from '../ApplicationDetail/NotesTab/Notes';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0 0 12px rgba(116,125,140,0.1)',
    padding: 8,
  },
  container: {
    height: 590,
    marginTop: 30,
    overflowY: 'scroll',
    paddingLeft: 10,
    paddingRight: 17,
  },
  createLoanButton: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  badgeClass: {
    marginLeft: '10px',
    '& .MuiBadge-badge': {
      color: 'white',
    },
  },
  editLoan: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  importantInfoComplement: {
    color: '#1E272E',
    display: 'flex',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.46,
    marginTop: '1rem',
  },
}));

const SideTab = (props) => {
  const {
    appId,
    applicationDetails,
    loanDetails,
    dealsData,
    showLoader,
    setShowLoader,
    fetchedData,
    setFetchedData,
  } = props;
  const { user, dealerLevelRole } = useUserContext();
  const [documentsTab, setDocumentsTab] = useState(1);
  const [unreadMsg, setUnreadMsg] = useState(0);
  const [msgStatusUpdated, setMsgStatusUpdated] = useState(false);
  const [unreadStips, setUnreadStips] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    let numOfUnreadStips = 0;

    if (applicationDetails?.data?.attributes) {
      if (
        applicationDetails?.data?.attributes.signed_applicant === false &&
        loanDetails?.attributes?.signature_method !== 'email'
      ) {
        numOfUnreadStips += 1;
      }
      if (
        applicationDetails?.data?.attributes.signed_coapplicant === false &&
        loanDetails?.attributes?.signature_method !== 'email'
      ) {
        numOfUnreadStips += 1;
      }
      if (applicationDetails?.data?.attributes.documents.length > 0) {
        applicationDetails?.data?.attributes.documents.map((doc) => {
          if (doc?.status === 'needed') {
            numOfUnreadStips += 1;
          }
          return '';
        });
      }
      if (applicationDetails?.data?.attributes.open_stipulations.length > 0) {
        applicationDetails?.data?.attributes.open_stipulations.map((stips) => {
          if (!stips?.file) {
            numOfUnreadStips += 1;
          }
          if (stips?.file?.status === 'needed') {
            numOfUnreadStips += 1;
          }
          return '';
        });
      }
      if (applicationDetails?.data?.attributes.closed_stipulations.length > 0) {
        applicationDetails?.data?.attributes.closed_stipulations.map(
          (stips) => {
            if (stips?.file?.status === 'needed') {
              numOfUnreadStips += 1;
            }
            return '';
          },
        );
      }
      setUnreadStips(numOfUnreadStips);
    }
  }, [applicationDetails]);

  const hasSelectedDeal = applicationDetails?.included?.some(item => item.type === 'deal');

  useEffect(async () => {
    let numOfUnreadMsg = 0;
    const [err, rawApplication] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/application_notes`,
      ),
    );
    if (rawApplication?.data?.data?.length > 0) {
      rawApplication?.data?.data.map((note) => {
        if (note.attributes.read_by === null && note.attributes.admin_note) {
          numOfUnreadMsg += 1;
        }
        return numOfUnreadMsg;
      });
      setUnreadMsg(numOfUnreadMsg);
    }
  }, [applicationDetails, msgStatusUpdated]);
  const handleChange = (event, newValue) => {
    setDocumentsTab(newValue);
  };
  const returnLabel = (label, gridSize) => {
    const counter = label === 'Stips & Docs' ? unreadStips : unreadMsg;
    return (
      <Grid container >
        <Grid item xs={gridSize} sm={gridSize} md={gridSize} lg={gridSize}>
          {label}
        </Grid>
        <Grid item>
          <Badge
            badgeContent={counter}
            className={classes.badgeClass}
            color="primary"
            onClick={handleChange}
          >
          </Badge>
        </Grid>
      </Grid>);
  };
  return (
    <>
      {user !== null && (
        <Paper className={classes.paper} elevation={0}>
          <Tabs
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            value={documentsTab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={returnLabel('Stips & Docs',10)} value={1} />

            <Tab value={0} label={returnLabel('Messages',8)}></Tab>

            <Tab label="TIMELINE" value={2} />
          </Tabs>
          <div className={classes.container}>
            {documentsTab === 0 && (
              <Notes
                applicationNumber={appId}
                setMsgStatusUpdated={setMsgStatusUpdated}
                msgStatusUpdated={msgStatusUpdated}
              ></Notes>
            )}
            {documentsTab === 1 && (
              <Documents
                hasSelectedDeal={hasSelectedDeal}
                applicationNumber={appId}
                documents={applicationDetails?.data?.attributes}
                closedStipulations={
                  applicationDetails?.data?.attributes?.closed_stipulations
                }
                openStipulations={
                  applicationDetails?.data?.attributes?.open_stipulations
                }
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                newFlow
                signedApplicantNew={
                  dealsData?.signedApplicant !== undefined
                    ? dealsData.signedApplicant
                    : applicationDetails?.data?.attributes?.signed_applicant
                }
                signedCoapplicantNew={
                  dealsData?.signedCoApplicant !== undefined
                    ? dealsData?.signedCoApplicant
                    : applicationDetails?.data?.attributes?.signed_coapplicant
                }
                signatureMethod={loanDetails?.attributes?.signature_method}
                fetchedData={fetchedData}
                projectStatus={loanDetails?.attributes?.status}
                setFetchedData={setFetchedData}
              />
            )}
            {documentsTab === 2 && (
              <div>
                <Timeline applicationNumber={appId} />{' '}
              </div>
            )}
          </div>
        </Paper>
      )}
    </>
  );
};
const mapStatesToProps = (state) => {
  return {
    loanDetails: state.appReducer?.loanDetails,
    dealsData: state.appReducer,
  };
};
export default connect(mapStatesToProps)(SideTab);
