import React from 'react';

export default function index({ width, height }) {
  return (
    <svg
      width={width || '13'}
      height={height || '13'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M1.5 9.259V11.5h2.241l7.5-7.5L9 1.759l-7.5 7.5zM9.442.433l3.125 3.125a.625.625 0 0 1 0 .884l-8.125 8.125A.625.625 0 0 1 4 12.75H.875a.625.625 0 0 1-.625-.625V9c0-.166.066-.325.183-.442L8.558.433a.625.625 0 0 1 .884 0z"
          id="a"
        />
      </defs>
      <use fill="#393F47" xlinkHref="#a" fillRule="evenodd" />
    </svg>
  );
}
