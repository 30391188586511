import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import AdditionalInfo from '../../../components/AdditionalInfo';
import Text from '../../../components/TextFields/Text';
import ContainedButton from '../../../components/Buttons/ContainedButton';
import CheckDealer from '../../../components/CheckDealer';

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: -40,
    },
  },
  linkText: {
    alignItems: 'center',
    color: '#9198A0',
    display: 'flex',
    fontSize: '0.875rem',
    letterSpacing: 0.6,
    lineHeight: '18px',
    marginRight: 48,
    fontWeight: 600,
    [`&:hover`]: {
      cursor: 'pointer',
    },
  },
  buttonsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 24,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
}));

const SecondPage = ({
  setCurrentPage,
  setDealerCode,
  dealerCode,
  dealerData,
  addressData,
  businessData,
}) => {
  const classes = useStyles();
  const [hasDealer, setHasDealer] = useState(dealerCode ? 'yes' : 'no');
  return (
    <div className={classes.form}>
      <Text
        textVariant="body1"
        textColor="#636A72"
        textFontWeight={600}
        textFontSize={12}
        textLetterSpacing={0.6}
        textLineHeight="16px"
        text="DEALER ASSIGNMENT"
      />
      <Text
        semibold
        textSize="sm+"
        textColor="#1E272E"
        style={{ marginTop: '0.5rem' }}
      >
        Do you have a Dealer?
      </Text>
      <CheckDealer
        setDealerCode={setDealerCode}
        dealerCode={dealerCode}
        dealerData={dealerData}
        addressData={addressData}
        businessData={businessData}
        hasDealer={hasDealer}
        setHasDealer={setHasDealer}
      />
      <div className={classes.buttonsWrapper}>
        <Typography
          className={classes.linkText}
          onClick={() => setCurrentPage('firstPage')}
        >
          <KeyboardArrowLeft style={{ marginRight: 5 }} />
          Back
        </Typography>

        <ContainedButton
          type="submit"
          text="CREATE ACCOUNT"
          variant="contained"
          color="primary"
          disabledOptions={{}}
          errors={{}}
        />
      </div>
      {hasDealer === 'no' && (
        <AdditionalInfo
          title="Can I create an account without a Dealer?"
          text="Sure! PowerPay will help you find a contractor in your area."
        />
      )}
    </div>
  );
};

export default SecondPage;
