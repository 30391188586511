import React, { useEffect, useState } from 'react';
import { map } from 'ramda';

import { SelectField } from 'components/InputFields/SelectField';
import MenuItem from '@material-ui/core/MenuItem';
import { useFormikContext } from 'formik';


export const businessTypes = [
  { label: 'ADU Tiny Home', value: 'adu_tiny_home' },
  { label: 'Attic Insulation', value: 'attic_insulation' },
  { label: 'Basement Remodeling', value: 'basement_remodeling' },
  { label: 'Bath Remodeling', value: 'bath_remodeling' },
  { label: 'Countertops', value: 'countertops' },
  { label: 'Deck and Patio', value: 'deck_and_patio' },
  { label: 'Doors', value: 'doors' },
  { label: 'Electrical', value: 'electrical' },
  { label: 'Exterior Siding, Masonry', value: 'exterior_siding' },
  { label: 'Fence', value: 'fence' },
  { label: 'Flooring', value: 'flooring' },
  { label: 'Gutter', value: 'gutter' },
  { label: 'Hardscaping/Landscaping', value: 'hardscaping_landscaping' },
  { label: 'HVAC', value: 'hvac' },
  { label: 'Kitchen Remodeling', value: 'kitchen_remodeling' },
  { label: 'Lighting', value: 'lighting' },
  { label: 'Paint and Wall Covering', value: 'paint_and_wall_covering' },
  { label: 'Patio Enclosures', value: 'patio_enclosures' },
  { label: 'Plumbing', value: 'plumbing' },
  { label: 'Pools and Spas', value: 'pools_and_spas' },
  { label: 'Remodeler', value: 'remodeler' },
  { label: 'Roofing', value: 'roofing' },
  { label: 'Sheds and Structures', value: 'sheds_and_structures' },
  { label: 'SmartHome', value: 'smarthome' },
  { label: 'Solar', value: 'solar' },
  { label: 'Water Filtration', value: 'water_filtration' },
  { label: 'Window Treatment', value: 'window_treatment' },
  {
    label: 'Window Mfg and Installation',
    value: 'window_mfg_and_installation',
  },
  { label: 'Other', value: 'other' },
];
export const BusinessProductSelect = (props) => {
  const [businessProduct, setBusinessProduct] = useState([]);
  const {businessData} = props;
  useEffect(() => {
    if (businessProduct.length === 0) {
      const productTypeArr = [];
      businessData.data.map((productType) => {
        productTypeArr.push(productType.attributes);
        return '';
      });
      setBusinessProduct(productTypeArr);
    }
  }, [businessData]);
  return (
    <SelectField {...props}>
      <MenuItem value="">Select...</MenuItem>
      {map(
        (type) => (
          <MenuItem key={type.id} value={type.id}>
            {type.name}
          </MenuItem>
        ),
        businessProduct,
      )}
    </SelectField>
  );
};

export const BusinessTypeSelect = (props) => {
  const { values } = useFormikContext();
  const [businessType, setBusinessType] = useState([]);
  const {businessData} = props;

  useEffect(() => {
    if (values.productType) {
      const businessTypeArr = [];
      businessData?.data?.map((productType) => {
        if (productType.id === values.productType.toString()) {
          productType.attributes.business_types.map((businessType) =>{
            businessTypeArr.push(businessType);
            return '';
          });
        }
        return '';
      });
      setBusinessType(businessTypeArr);
    }
  }, [values.productType]);
  return (
    <>
      {businessType.length > 0 && (
        <SelectField {...props}>
          <MenuItem value="">Select...</MenuItem>
          {map(
            (type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.title}
              </MenuItem>
            ),
            businessType,
          )}
        </SelectField>
      )}
    </>
  );
};
