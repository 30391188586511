import { useUserContext } from 'services/hooks/useUser';
import { useHistory } from 'react-router-dom';

export const DashboardRedirect = () => {
  const { user } = useUserContext();
  const history = useHistory();
  const userType = user?.data?.type;
  if (userType === 'homeowner' || userType === 'dealer') {
    history.replace(`/${userType}/dashboard`);
  }
  return null;
};
