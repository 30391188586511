import React from 'react';
import clsx from 'clsx';

// MATERIAL
import {
  Hidden,
  AppBar,
  Box,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { isAppEmbedded } from 'services/utils';
import { Sidenav } from './helpers/Sivenav/Sidenav';
import { headerStyles, getLocation } from './helpers/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    borderBottom: 'solid 1px',
    borderBottomColor: '#ECEFF2',
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4rem',
    padding: '0 1.5rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    letterSpacing: '0.6px',
    lineHeight: '2rem',
    flexGrow: 1,
  },
}));

export const Dashboard = React.memo(
  ({
    Component,
    title = 'PowerPay',
    location,
    container,
    openDrawer,
    userType,
    HeaderActions,
    signInColor,
    ...props
  }) => {
    const classes = useStyles();
    const headerClasses = headerStyles({
      location: getLocation(location),
    });

    const {
      isOpen: isMobileDrawerOpen,
      onToggle: onToggleMobileDrawer,
    } = useDisclosure(false);

    return (
      <div className={classes.root}>
        {isAppEmbedded() || location.pathname.includes('apply') ? null : (
          <>
            <Sidenav
              isMobileDrawerOpen={isMobileDrawerOpen}
              onToggleMobileDrawer={onToggleMobileDrawer}
              userType={userType}
              openDrawer={openDrawer}
            />
          </>
        )}
        <Box display="flex" flexDirection="column" flexGrow="1" minWidth="0">
          <AppBar
            position="static"
            color="transparent"
            className={headerClasses.dynamicHeader}
            elevation={0}
          >
            <Box className={classes.appBar}>
              <Hidden lgUp>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  aria-label="open-sidenav"
                  onClick={onToggleMobileDrawer}
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
              <Typography className={clsx('appTitle', classes.title)}>
                {title}
              </Typography>
              <Box flex="1" />
              {HeaderActions && (
                <Box className="headerActions">
                  <HeaderActions />
                </Box>
              )}
              <Link to="/signout" style={{ display: 'flex' }}>
                <IconButton
                  aria-label="signOut"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  className="sign-out-button"
                >
                  <Icon icon="signOut" opacity={1} flip />
                </IconButton>
              </Link>
            </Box>
          </AppBar>
          <Box flexGrow="1" style={{ backgroundColor: '#F9FAFC', paddingBottom: '4rem' }}>
            <Component {...props} />
          </Box>
        </Box>
      </div>
    );
  },
);
