import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Card, CardContent, makeStyles, Box } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Text from 'components/TextFields/Text';
import DraftIcon from 'assets/icons/submit-draft.png';
import AcceptedIcon from 'assets/icons/submit-accept.png';
import RejectedIcon from 'assets/icons/submit-rejected.png';
import { useErrorAlertMessage } from 'components/AlertContext';
import { getToken } from '../../services/authentication';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '4rem 8rem 4rem 4rem',
    },
  },
  card: {
    flexGrow: 1,
    padding: '2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3.5rem',
      paddingBottom: '2rem',
    },
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

const items = (status) => {
  switch (status) {
    case 'conditional_approval':
    case 'pending':
      return {
        icon: DraftIcon,
        title: 'Almost there!',
        desc:
          'Your Application it’s being reviewed by our PowerPay \nAnalyst.  We may request extra information from you.',
      };
    case 'accepted':
      return {
        icon: AcceptedIcon,
        title: 'Great news!',
        desc:
          'Your application has been approved, you’re \njust click aways from starting your proyect',
      };

    default:
    case 'rejected':
      return {
        icon: RejectedIcon,
        desc:
          'We’re sorry to inform you that your application has been rejected, \nknow more details about it in your Application Details',
      };
  }
};

export const ConfirmPage = () => {
  const token = JSON.parse(getToken());
  const classes = useStyles();
  const { palette } = useTheme();
  const history = useHistory();
  const errorAlertMessage = useErrorAlertMessage();

  const { id, status } = history.location.state ?? {};

  useEffect(() => {
    if (!id || !status) {
      errorAlertMessage('Unauthorized Access');
      history.replace('/dashboard');
    }
    return () => {};
  }, [id, status]);

  const DynamicConfirm = ({ icon, title, desc }) => (
    <Box
      paddingBottom="4rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img src={icon} className={classes.image} />
      {
        title && 
        <Text
          textSize="cardTitle"
          textAlign="center"
          bold
          textColor={palette.text.primary}
        >
          {title}
        </Text>
      }
      <Text
        textSize="cardSubtitle"
        textAlign="center"
        textColor={palette.text.secondary}
        style={{ marginTop: '1rem', whiteSpace: 'pre-line' }}
      >
        {desc}
      </Text>
    </Box>
  );

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Card elevation={4} className={classes.card}>
          <CardContent>
            <Box display="flex" flexDirection="column" alignItems="center">
              <DynamicConfirm {...items(status)} />

              <Link
                component={NavLink}
                to={
                  token.data.type === 'dealer'
                    ? `/dealer/applications/${id}`
                    : `/applications/${id}`
                }
                style={{ width: '100%', maxWidth: '25rem' }}
                replace
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    padding: '0.5rem 0',
                    width: '100%',
                    borderRadius: '4px',
                  }}
                >
                  <Text bold textColor="white">
                    REVIEW APPLICATION
                  </Text>
                </Button>
              </Link>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
