import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
const useStyles = makeStyles({
  container: {
    padding: '24px',
  },
});

export const NotImplemented = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.container}>
      <p>Not Implemented</p>
    </Grid>
  );
};
