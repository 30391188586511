import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { SearchInput } from 'components/InputFields/SearchInput';
import { useDebounce } from 'services/hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
  queryFilter: {
    display: 'flex',
    padding: '0 1rem 0 1rem',
    width: '32rem',
    [theme.breakpoints.down('md')]: {
      flex: '1 0 0',
      padding: '0',
      paddingRight: '1rem',
      width: 'auto',
      maxWidth: '32rem',
    },
  },
  clearButton: {
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
    marginLeft: '0.25rem',
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

export const QueryFilter = ({ setParams, disableWindowReload, disabled }) => {
  const classes = useStyles();
  const [input, setInput] = useState('');

  const debouncedText = useDebounce(input);

  useEffect(() => {
    onQuery(debouncedText);
  }, [debouncedText]);

  const onQuery = (searchText) => {
    setParams((params) => ({
      ...params,
      searchText,
    }));
  };

  const onKeyDown = (ev) => {
    if (ev.keyCode === 13) {
      ev.currentTarget.blur();
      onQuery(ev.currentTarget.value);
    }
  };

  return (
    <Box className={classes.queryFilter}>
      <SearchInput
        id="query-filter-input"
        label="Search by Ref ID, Name, Phone or Address"
        value={input}
        onInput={(ev) => {
          setInput(ev.target.value);
        }}
        onBlur={(ev) => onQuery(ev.currentTarget.value)}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />

      <Button
        className={clsx(classes.clearButton, { [classes.hidden]: !input })}
        onClick={() => {
          setInput('');
          onQuery('');
          if(!disableWindowReload) window.location.reload();
        }}
      >
        Clear
      </Button>
    </Box>
  );
};
