import React from 'react';

export default function imageJPG({ width, height }) {
  return (
    <svg
      width={width || '32px'}
      height={height || '42px'}
      viewBox="0 0 32 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Files / Jpg@1x</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Icon-/-Files-/-Jpg"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M27.6956522,42 L4.30434783,42 C1.92712216,42 0,40.0547544 0,37.6551724 L0,4.34482759 C0,1.94524557 1.92712216,0 4.30434783,0 L23.3913043,0 L32,8.68965517 L32,37.6551724 C32,40.0547544 30.0728778,42 27.6956522,42 Z"
          id="Path"
          fill="#1DD1A1"
          fillRule="nonzero"
        />
        <path
          d="M32,8.68965517 L27.2557373,8.68965517 C24.7704559,8.68965517 23.3913043,7.03154602 23.3913043,4.54626465 L23.3913043,5.06261699e-14 L32,8.68965517 Z"
          id="Path"
          fill="#119E79"
          fillRule="nonzero"
        />
        <g
          id="Group"
          transform="translate(4.000000, 11.000000)"
          fillRule="nonzero"
        >
          <path
            d="M22.8,3.072 L22.8,6.912 C22.8,8.608512 21.5989765,9.984 20.1176471,9.984 L2.68235294,9.984 C1.20102353,9.984 0,8.608512 0,6.912 L0,3.072 C0,1.375488 1.20102353,-3.83693077e-14 2.68235294,-3.83693077e-14 L20.1176471,-3.83693077e-14 C21.5989765,-3.83693077e-14 22.8,1.375488 22.8,3.072 Z"
            id="Path"
            fill="#3FDEB3"
          />
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="2.304"
            y="6.144"
            width="1.536"
            height="1.536"
          />
          <path
            d="M6.483456,7.77216 C7.839744,7.77216 8.322816,6.747648 8.322816,5.789184 L8.322816,2.320128 L6.786816,2.320128 L6.786816,5.677824 C6.786816,6.37056 6.542592,6.428928 6.291456,6.428928 C6.077952,6.428928 5.854464,6.25536 5.707008,5.976576 L5.662464,5.892864 L4.602624,6.688512 L4.63872,6.75072 C5.043456,7.447296 5.62944,7.77216 6.483456,7.77216 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <path
            d="M11.372544,2.320128 L9.27744,2.320128 L9.27744,7.67616 L10.81344,7.67616 L10.81344,6.029568 L11.372544,6.029568 C12.36864,6.029568 13.53216,5.531136 13.53216,4.126464 C13.531392,2.944512 12.784128,2.320128 11.372544,2.320128 Z M10.81344,3.568128 L11.3088,3.568128 C11.81184,3.568128 12.02688,3.735552 12.02688,4.127232 C12.02688,4.56192 11.785728,4.782336 11.3088,4.782336 L10.81344,4.782336 L10.81344,3.568128 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M16.70784,3.568128 C17.097216,3.568128 17.303808,3.684864 17.550336,3.899904 L17.611008,3.952896 L18.465024,2.989056 L18.411264,2.935296 C17.952768,2.4768 17.342208,2.224128 16.69248,2.224128 C15.101952,2.224128 13.989888,3.381504 13.989888,5.03808 C13.989888,6.699264 15.038208,7.77216 16.660224,7.77216 C17.436672,7.77216 18.120192,7.432704 18.501888,7.113984 L18.531072,7.090176 L18.531072,4.494336 L16.387584,4.494336 L16.387584,5.774592 L17.154816,5.774592 L17.154816,6.36672 C17.075712,6.40512 16.955904,6.428928 16.836096,6.428928 C15.975936,6.428928 15.558144,5.958912 15.558144,4.990464 C15.558144,4.13952 16.019712,3.568128 16.70784,3.568128 Z"
            id="Path"
            fill="#FFFFFF"
          />
        </g>
        <g
          id="Group-9"
          opacity="0.800000012"
          transform="translate(8.000000, 26.000000)"
          fillRule="nonzero"
        >
          <polygon
            id="Path"
            fill="#68FFD2"
            opacity="0.200000003"
            points="0 0 15 0 15 12 0 12"
          />
          <polygon
            id="Path"
            fill="#FFFFFF"
            points="4.61538462 6 7.5 9.6 10.9615385 8.4 15 12 0 12"
          />
          <circle id="Oval" fill="#FFFFFF" cx="9.5" cy="4.5" r="1.5" />
        </g>
      </g>
    </svg>
  );
}
