import React, { useState, useEffect } from 'react';
import { map } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { protectedGet, protectedPatch } from 'services/http';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  useTheme,
  withStyles,
  FormControlLabel,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useRedirectOnDealerStatus } from 'services/hooks/useRedirectOnDealerStatus';
import { fullName } from 'services/utils';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import { PageTable } from 'components/PageTable';
import {
  PageTableHeader,
  QueryFilter,
  ColumnToggler,
  StateFilter,
} from 'components/PageTable/Header';
import { TableActionButton } from 'components/Buttons/NewApplicationButton';

import { PageTableWrapper, tableStyles } from 'pages/ApplicationQueue';
import { useUserContext } from 'services/hooks/useUser';
import { useTableSetup } from 'services/hooks/useTableSetup';
import usePageTableParameters from 'services/hooks/usePageTableParameters';
import { useAlertContext, useErrorAlertMessage } from 'components/AlertContext';
import { notifyErrorWithRedirect } from 'services/utils/notifyErrorWithRedirect';
import { translateDealerRoleShort } from '../../services/utils/index';

const useStyles = makeStyles((theme) => ({
  logContentRead: {
    color: '#1E272E',
    fontSize: 14,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
}));
const appFields = [
  'id',
  'name',
  'lastName',
  'email',
  'phoneNumber',
  'hierarchyLevelRole',
  'active'
];
export const getApiURL = (dealerLevelRole, dealerLevelId) => {
  switch (dealerLevelRole) {
    case 'affiliate':
      return `/v1/affiliates/${dealerLevelId}/members`;
    case 'organization':
      return `/v1/organizations/${dealerLevelId}/members`;
    case 'dealer_network_account_rep':
    case 'dealer_network_member':
    case 'dealer_network_overseer':
      return `/v1/dealer_networks/${dealerLevelId}/members`;
    default:
      return null;
  }
};

const headCells = [
  {
    id: 'id',
    label: 'Team Member',
  },
  {
    id: 'name',
    label: 'First Name',
  }, {
    id: 'lastName',
    label: 'Last Name',
  }, {
    id: 'email',
    label: 'Email',
  }, {
    id: 'phoneNumber',
    label: 'Phone Number',
  }, {
    id: 'hierarchyLevelRole',
    label: 'Role',
  },
  {
    id: 'active',
    label: 'Status'
  }
];
function useTableData(setValueFetched,valueFetched) {
  const [rows, setRows] = useState([]);
  const classes = useStyles();
  const { dealerLevelRole } = useUserContext();
  const { setAlertMessage } = useAlertContext();
  return [
    map((item) => {
      const rowCells = [];
      rowCells.push({
        type: 'link',
        value: `/team-members/${item.id}/edit`,
        subValue: fullName(item),
        state: { teamMemberId: item.id },
      });
      rowCells.push(item.name ?? '');
      rowCells.push(item.lastName ?? '');
      rowCells.push(item.email ?? '');
      rowCells.push(item.phoneNumber ?? '');
      rowCells.push(translateDealerRoleShort(item.hierarchyLevelRole) ?? '');
      if (dealerLevelRole === 'affiliate' || dealerLevelRole === 'organization') {
        rowCells.push({
          value: {
            value: item.active === true,
            disabled: item.active === false,
          },
          type: 'checkbox',
        })
      }
      else {
        rowCells.push(
          <>
            <tooltip title={item.active === true ? 'Active' : 'Inactive'}>
              <FormControlLabel
                classes={{
                  label: classes.logContentRead,
                }}
                onClick={(e)=>{
                  e.preventDefault();
                  e.stopPropagation();
                }}
                control={
                  <Switch
                    checked={item.active}
                    color="secondary"
                    onChange={(e) => {
                     
                      const update = async (item) => {
                        const [err, userRes] = await to(
                          protectedPatch(`/v1/users/${item.id}/toggle_user_state`, {
                            id: item.id,
                          }),
                        );
                        if (err) {
                          return;
                        }
                        setValueFetched(!valueFetched);
                        setAlertMessage('Operation Completed Successfully');
                      };
                      update(item);
                    }}
                  />
                }
              />
            </tooltip>
          </>)
      }


      return rowCells;
    }, rows),
    setRows,
  ];
}

export const TeamMemberList = () => {
  useRedirectOnDealerStatus();
  const classes = tableStyles();
  const history = useHistory();
  const { dealerLevelRole, dealerLevelId } = useUserContext();
  const [valueFetched,setValueFetched]= useState(false);
  const [params, setParams] = useState({
    status: 'submitted',
  });
  const [rows, setRows] = useTableData(setValueFetched,valueFetched);
  const { ...tableMethods } = usePageTableParameters({
    initialOrder: 'id',
  });
  const { page, perPage, order, sort } = tableMethods;
  // const {
  //   isOpen: isBulkAssigning,
  //   onOpen: onOpenBulkAssign,
  //   onClose: onCloseBulkAssign,
  // } = useDisclosure(false);

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  const errorAlertMessage = useErrorAlertMessage();

  useEffect(() => {
    async function fetch(url) {
      setIsLoading(true);
      const [err, dealerships] = await to(
        protectedGet(url, {
          params: decamelizeKeys({
            ...params,
            page: page + 1,
            perPage,
            sort,
            order: decamelize(order),
          }),
        }),
      );
      if (err) {
        notifyErrorWithRedirect(err, errorAlertMessage, history, '/dashboard');
        return;
      }
      const res = normalize(camelizeKeys(dealerships.data)).get(appFields);
      setIsLoading(false);
      if (res.name) {
        res.homeowner = { name: res.name, lastName: res.lastName };
      }
      setTotalItems(Number(dealerships.headers.total));
      setRows(res);
    }
    const url = getApiURL(dealerLevelRole, dealerLevelId);
    if (url) {
      fetch(url);
      tableMethods.setSelected({});
    }
  }, [params, page, perPage, sort, order,valueFetched]);
  const {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  } = useTableSetup({ rows, params, headCells });

  return (
    <>
      <PageTableWrapper isLoading={isLoading}>
        <PageTableHeader>
          {/* <StateFilter
            possibleStatuses={['submitted']}
            status={params.status}
            setParams={setParams}
          /> */}
          <Box className={classes.rightFilterWrapper}>
            {/* <QueryFilter query={params.query} setParams={setParams} /> */}
            <Hidden mdDown>
              <ColumnToggler
                columns={initialColumns.current}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
              />
            </Hidden>
          </Box>
        </PageTableHeader>
        <PageTable
          totalItems={totalItems}
          rows={filteredRows}
          headCells={filteredHeadCells}
          isLoading={isLoading}
          page={params.page}
          perPage={params.perPage}
          setParams={setParams}
          emptyStateTitle="No Team Members, yet."
          emptyStateDesc="Add a New Team Member using the upper-right button"
          itemType={`${totalItems > 1 ? 'Dealers' : 'Dealer '}`}
          onRowClick={onRowClick}
          {...tableMethods}
          hideCheckboxes
        />
      </PageTableWrapper>
      {/* <SelectedMenu selected={selected}>
        <SelectedMenuActionButton
          icon="people"
          label="Bulk Re-Assignment"
          onClick={onOpenBulkAssign}
        />
        <SelectedMenuActionButton icon="archive" label="Archive Team Member" />
        <SelectedMenuActionButton icon="cancel" label="Cancel Team Member" />
      </SelectedMenu>
      <ActionModal isOpen={isBulkAssigning} onClose={onCloseBulkAssign} /> */}
    </>
  );
};

export const TeamMemberListHeaderActions = () => {
  const history = useHistory();
  return (
    <Box>
      <TableActionButton
        text="Add Team Member"
        onClick={() => history.push('/team-members/new')}
      />
    </Box>
  );
};
