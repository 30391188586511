import React, { useEffect, useState, useMemo } from 'react';

import { updateData } from 'redux/actions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  PhoneNumberField,
  EmailField,
  TextField,
  DateField,
  formatRawPhoneNumber,
  formatNumberToCurrencyString,
  castFormattedCurrencyStringToNumber,
  removeNonDigitCharacters
} from './CustomizedMUIInputs';
import './NewApplicationForm.css';

import { MerchantSection } from './NewApplicationFormSections/MerchantSection';
import { LoanSection } from './NewApplicationFormSections/LoanSection';
import { ApplicantSection } from './NewApplicationFormSections/ApplicantSection';
import { CoApplicantSection } from './NewApplicationFormSections/CoApplicantSection';
import { PropertySection } from './NewApplicationFormSections/PropertySection';
import { TermsAndConditionsText } from './NewApplicationFormSections/TermsAndConditionsText';

import {
  IconButton,
  Button,
  LinearProgress,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChevronLeft } from '@material-ui/icons';

import { protectedGet, protectedPut, protectedPost } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import ZeroBounceSDK from '@zerobounce/zero-bounce-sdk';

const listOfUsersWithAccessToMerchantSelection = [
  'affiliate',
  'organization',
  'super_admin',
];

const zeroBounce = new ZeroBounceSDK();
zeroBounce.init(process.env.REACT_APP_ZEROBOUNCE_API_KEY);

/**
 * @todo Accesibility issue: When using tab to navigate, inputs don't get properly centered on the screen, instead they get put barely within view
 * @todo eventually, when changing the ApplicationQueue, remove redux (you can get reApplyId from URL, and ideally we shouldn't need updateData)
 * @todo separate formInformation into separate states (merchantInformation, loanInformation, etc)(having it all on a single state just makes the setState functions complicated)
 * @see https://legacy.reactjs.org/docs/hooks-faq.html#should-i-use-one-or-many-state-variables for info on todo above (page is old but still relevant)
 * also @see https://react.dev/learn/choosing-the-state-structure#avoid-deeply-nested-state , more up to date page
 * @todo consider if adding useContext is a good idea (not sure)
 * @todo prevent scrolling when awaiting backend response
 * @todo Accesibility issue: when clicking "why can't i continue?", focus on whatever element gets highlighted (maybe only if used uses enter to click on button)
 * @todo Accesibility issue: user cannot toggle checkbox using only keyboard
 * @todo Accesibility issue: tab-navigation on buttons like "add coapplicant" don't properly highlight them
 */
export const NewApplicationForm = ({ reApplyId, updateData }) => {
  /* form information */
  const [merchant, setMerchant] = useState(null);
  const [loanProduct, setLoanProduct] = useState(null);
  const [requestAmount, setRequestAmount] = useState('');
  const [projectAmountTotal, setProjectAmountTotal] = useState(''); // stage funding, VIS-1994
  const [downPayment, setDownPayment] = useState('') // stage funding, VIS-1994
  const [applicant, setApplicant] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    ssn: '',
    birthday: '',
    employmentType: '',
    annualIncome: '',
    otherHouseholdIncome: '',
    employerName: '',
    occupation: '',
    employerZipCode: '',
    ssnConfirmation: '',
    birthdayConfirmation: '',
    unmaskedSSN: '',
    unmaskedSSNConfirmation: '',
  });
  const [coapplicant, setCoapplicant] = useState(null);
  const [propertyInformation, setPropertyInformation] = useState({
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    isOwner: true,
  }); // property is often used as a programming keyword, so just to clarify: we're talking about a location, building, thing with zip code, etc
  const [disableNotifications, setDisableNotifications] = useState(true);
  const [serviceDate, setServiceDate] = useState('');
  const [merchantRepresentative, setMerchantRepresentative] = useState(null);
  const [merchantReferenceId, setMerchantReferenceId] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [zeroBounceEnabled, setZeroBounceEnabled] = useState();
  // reminder: null means that zerobounce didn't check the email. Zerobounce needs to check all emails, but user is allowed to submit regardless of ZB's response
  const [zeroBounceValidations, setZeroBounceValidations] = useState([
    {
      emailFieldId: 'applicant-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
    {
      emailFieldId: 'coapplicant-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
    {
      emailFieldId: 'merchant-representative-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
  ]);

  /* form status */
  const [canSubmit, setCanSubmit] = useState(false);
  const [backendErrorMessage, setBackendErrorMessage] = useState('');
  const [showAllErrors, setShowAllErrors] = useState(false);
  const [minIncomeAmount, setMinIncomeAmount] = useState(0);
  const [maxRequestAmount, setMaxRequestAmount] = useState(null);
  const [isAwaitingBackendResponse, setIsAwaitingBackendResponse] = useState(
    false,
  );
  const [enableStageFunding, setEnableStageFunding] = useState(null);

  const history = useHistory();
  const userContextData = useUserContext();

  /** @description on first render, fetch settings and application information for resubmit (last one only if applicable) */
  useEffect(() => {
    async function getResubmitApplicationInformation() {
      
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${reApplyId}`,
      )
        .then((res) => {
          /*
            the response object is a complete and utter chaotic mess. here's how it is divided:
            {
              data: {
                stuff be here
              },
              included: [
                {
                  address information
                },
                {
                  dealer (aka merchant) information
                },
                {
                  financial details (keep in mind there's 2, one for the homeowner (aka applicant) and another for the coapplicant)
                },
                etc
              ]
            }
            note: included[] can include other items, so we cant rely on idex :D
          */
          const mainPayload = res.data.data.attributes;
          const addressPayload = res.data.included.find(
            (object) => object.type === 'address',
          ).attributes;
          const applicantFinancialPayload = res.data.included.find(
            (object) =>
              object.type === 'financial_detail' &&
              object.attributes.user_type === 'homeowner',
          ).attributes;
          const coapplicantFinancialPayload = res.data.included.find(
            (object) =>
              object.type === 'financial_detail' &&
              object.attributes.user_type === 'coapplicant',
          )?.attributes; // can be undefined if no coapplicant

          let newServiceDate;
          // friendly reminder that, for now, both backend and us are handling dates as a simple string. Not Date objects, nor ISO date strings.
          if (
            mainPayload.service_date &&
            new Date(mainPayload.service_date) >= new Date()
          ) {
            // aka: if there's a service date and said service date is today or higher
            newServiceDate = mainPayload.service_date;
          } else newServiceDate = '';

          /*
            A couple of notes on the merchant
            1- depending on where you look at it in the app, merchant is synonim with dealer. Name seems to change at a whim. On this page, we try to use merchant for the most part
            2- the id of said merchant is kinda wonky to identify:
              -the list of merchants returned from backend has an id
              -the merchant payload (aka the object in the res.data.included array that has a type === "dealer") has an id too, but the id from merchant corresponds to hierarchy_level_id
              -the main payload (aka the applicant's information) includes the id too, but it's called dealer_network_id

              So, to recap: listOfMerchantsFromBackend[n].id === merchantPayload.hierarchy_level_id === mainPayload.dealer_network_id
              🤷
            3- the reason we're only passing the id and not the code here is because we needed a way to identify when a merchant is a resubmit merchant.
              - I decided to not include the merchant code if it is a resubmit. This then gets handled in the <MerchantSection /> component.
          */

          setMerchant({
            id: mainPayload.dealer_network_id,
            name: '',
          });
          setLoanProduct({
            attributes: null,
            id: mainPayload.loan_product_id,
          });
          setRequestAmount(
            formatNumberToCurrencyString(applicantFinancialPayload.request_amount) || '',
          );

          setDownPayment(formatNumberToCurrencyString(mainPayload.down_payment_amount)) || "";
          setRequestAmount(
            formatNumberToCurrencyString(applicantFinancialPayload.request_amount) || '',
          );

          if (mainPayload.project_amount_total){
            setProjectAmountTotal(formatNumberToCurrencyString(mainPayload.project_amount_total)) || "";
          } else {
            setProjectAmountTotal(formatNumberToCurrencyString(applicantFinancialPayload.request_amount)) || "";
          }

          setApplicant({
            firstName: mainPayload.name,
            lastName: mainPayload.last_name,
            phoneNumber: formatRawPhoneNumber(mainPayload.phone_number),
            email: mainPayload.email,
            ssn: 'XXX-XX-' + mainPayload.ssn.slice(5),
            unmaskedSSN: mainPayload.ssn.slice(0,5),
            birthday: mainPayload.birthday,
            employmentType: applicantFinancialPayload.employment_type,
            annualIncome:
              formatNumberToCurrencyString(applicantFinancialPayload.anual_income) || '',
            otherHouseholdIncome:
              formatNumberToCurrencyString(
                applicantFinancialPayload.other_household_income,
              ) || '',
            employerName: applicantFinancialPayload.employer_name,
            occupation: applicantFinancialPayload.occupation,
            employerZipCode: applicantFinancialPayload.employer_zip,
          });
          if (mainPayload.has_coapplicant) {
            setCoapplicant({
              firstName: mainPayload.coapplicant_name,
              lastName: mainPayload.coapplicant_last_name,
              phoneNumber: formatRawPhoneNumber(
                mainPayload.coapplicant_phone_number,
              ),
              email: mainPayload.coapplicant_email,
              ssn: 'XXX-XX-' + mainPayload.coapplicant_ssn.slice(5),
              unmaskedSSN: mainPayload.coapplicant_ssn.slice(0, 5),
              birthday: mainPayload.coapplicant_birthday || '',
              employmentType: coapplicantFinancialPayload.employment_type,
              annualIncome:
                formatNumberToCurrencyString(coapplicantFinancialPayload.anual_income) || '',
              employerName: coapplicantFinancialPayload.employer_name,
              occupation: coapplicantFinancialPayload.occupation,
              employerZipCode: coapplicantFinancialPayload.employer_zip,
            });
          }
          setPropertyInformation({
            streetAddress: addressPayload.street_address,
            city: addressPayload.city,
            state: addressPayload.state,
            zipCode: addressPayload.zip_code,
            isOwner: mainPayload.owner,
          });
          setDisableNotifications(mainPayload.applicant_notifications_disabled);
          setServiceDate(newServiceDate);
          if ((mainPayload.ith_dealer_email && mainPayload.ith_dealer_email !== null) || (mainPayload.ith_dealer_phone && mainPayload.ith_dealer_phone !== null)) {
            setMerchantRepresentative({
              email: mainPayload.ith_dealer_email,
              phoneNumber: formatRawPhoneNumber(mainPayload.ith_dealer_phone),
            });
          }
          setMerchantReferenceId(mainPayload.merchant_ref_id || null);
          setPromoCode(mainPayload.promo_code || null);
        })
        .catch(() => {
          setBackendErrorMessage(
            'There was a problem while fetching this application. Resetting all the fields in the form.',
          );
        })
    }

    async function getSettings() {
      protectedGet(`/v1/settings`).then((response) => {
        const minimumIncomeAmount = response.data.data.find(elem => elem.attributes.key.includes("minimum_income_amount"))?.attributes.value;
        const maximumRequestAmount = response.data.data.find(elem => elem.attributes.key.includes("maximum_request_amount"))?.attributes.value;
        const enableStageFunding = response.data.data.find(elem => elem.attributes.key.includes("down_payment_stage_funding_enable"))?.attributes.value;
        const enableZeroBounce = response.data.data.find(elem => elem.attributes.key.includes("enable_zerobounce"))?.attributes.value;;
        
        if(maximumRequestAmount) setMaxRequestAmount(maximumRequestAmount);
        if(minimumIncomeAmount) setMinIncomeAmount(minimumIncomeAmount);

        setEnableStageFunding(Boolean(enableStageFunding)) // default behaviour if undefined should be false, this accounts for it
        setZeroBounceEnabled(enableZeroBounce);


      }).catch((error) =>  {
        setBackendErrorMessage(
          'There was a problem getting the settings for the form. Using default behavior.',
        );
        console.error(error)
      })
    }

    updateData({
      name: 'dealStruct',
      value: null,
    }); // Wondering why this is here? please refer to VIS-1958 for more information. 🍝👨‍🍳

    let listOfAsyncFunctionsToCall = [getSettings()]

    if (reApplyId) {
      listOfAsyncFunctionsToCall.push(getResubmitApplicationInformation())
    }

    setIsAwaitingBackendResponse(true);
    Promise.allSettled(listOfAsyncFunctionsToCall).finally(() => {
      setIsAwaitingBackendResponse(false);
    })

    getSettings();

    return () => {
      updateData({
        name: 'reApplyId',
        value: null,
      });
    };
  }, []);

  /**
   * @description checks all emails in the zeroBounceValidations state array. If validation.isValidEmail is null, it asks ZeroBounce. if it's true or false, it doesn't.
   * @returns true if we should stop the execution of the submit, false if we can continue
   * Explanation of the logic:
   *  We must check if any emails the user inputs are valid or not using ZeroBounce
   *  However: the user must be able to submit regardless of what ZeroBounce responds.
   *  ZeroBounce is here just to prompt the user to check for grammar.
   *
   *  So, here's how it works:
   *  We store an array of zeroBounceValidations, with isValidEmail set to either true, false, or null
   *  If it's null, it means we have NOT yet asked zeroBounce to validate this email
   *  If it's either true or false, it means we did ask zerobounce.
   *
   *  ZeroBounce's validations ONLY happen when an isValidEmail is set to null
   *  @todo add a more sofisticated error handling later (i.e.: add a check using ZeroBounce's SDK to check that there are enough credits before doing the validations)
   *  I tried to implement them but i got a little stuck, i decided to leave this for another time given my workload rn
   *  @see https://github.com/zerobounce/zero-bounce-javascript
   */
  async function validateEmailsWithZerobounce() {
    /* 
      ! warning, here be dragons 
      You might look at this piece of code and think "wow! that's a lot of code repetition! lets DRY it!"

      Trust me, I tried. Either I do not understand how Promise.all() works, or you wont be able to use that. 
      It appears that calling set functions (like setZeroBounceValidations) inside a .then() statement WILL set the state to undefined. 
      You can slap as many awaits as you want.
      The state being undefined in this case will break things because we use zeroBounceValidations.find()

      Overall, while a bit repetitious, this solution works and it's easy to understand.
      If you want to improve it: by all means, go ahead, just make sure it's understandable
    */

    try {
      // even if there's an error, we must allow submittal. Idk how we'll handle metadata about Zerobounce in that case, but we'll see about it later
      const applicantValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'applicant-email',
      );
      const coapplicantValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'coapplicant-email',
      );
      const merchantRepresentativeValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'merchant-representative-email',
      );
      let stopSubmitExecution = false;

      if (applicantValidation.isValidEmail === null) {
        const response = await zeroBounce.validateEmail(
          applicantValidation.email,
        );
        applicantValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        applicantValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;
      }
      if (coapplicant !== null && coapplicantValidation.isValidEmail === null) {
        const response = await zeroBounce.validateEmail(
          coapplicantValidation.email,
        );
        coapplicantValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        coapplicantValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;      
      }
      if (
        merchantRepresentative !== null &&
        merchantRepresentativeValidation.isValidEmail === null
      ) {
        const response = await zeroBounce.validateEmail(
          merchantRepresentativeValidation.email,
        );
        merchantRepresentativeValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        merchantRepresentativeValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;
      }

      setZeroBounceValidations([
        applicantValidation,
        coapplicantValidation,
        merchantRepresentativeValidation,
      ]);
      return stopSubmitExecution;
    } catch {
      console.error("There was a problem with the Zerobounce's endpoints");
      return false;
    }
  }

  const formSubmit = async () => {
    setIsAwaitingBackendResponse(true);

    if (zeroBounceEnabled) {
      const stopSubmitExecution = await validateEmailsWithZerobounce();
      if (stopSubmitExecution) {
        // jump to topmost highlight field
        setIsAwaitingBackendResponse(false);
        const referenceToFirstHighlightedInput = document.querySelector(
          '#new-application-form .MUI-override_input-highlight input',
        );

        referenceToFirstHighlightedInput?.focus();

        referenceToFirstHighlightedInput?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        return;
      }
    }

    const submitPayload = {
      application: {
        address_attributes: {
          street_address: propertyInformation.streetAddress,
          zip_code: propertyInformation.zipCode,
          city: propertyInformation.city,
          state: propertyInformation.state,
          merchant_ref_id: merchantReferenceId,
        },
        loan_product_id: `${loanProduct.attributes.id}`,
        service_date: serviceDate,
        service_date_funding: Boolean(
          loanProduct.attributes.code === 'healthcare',
        ),
        applicant_notifications_disabled: disableNotifications,
        owner: propertyInformation.isOwner,
        promo_code: promoCode || '',
        merchant_ref_id: merchantReferenceId || '',
      },
      homeowner: {
        email: applicant.email,
        name: applicant.firstName,
        last_name: applicant.lastName,
        phone_number: applicant.phoneNumber,
        ssn: removeNonDigitCharacters(applicant.unmaskedSSN.concat(applicant.ssn)),
        birthday: applicant.birthday,
        us_citizen: true,
      },
      financial_details: {
        0: {
          anual_income: castFormattedCurrencyStringToNumber(applicant.annualIncome),
          other_household_income: castFormattedCurrencyStringToNumber(applicant.otherHouseholdIncome),
          request_amount: castFormattedCurrencyStringToNumber(requestAmount),
          occupation: applicant.occupation,
          user_type: 'homeowner',
          employer_name: applicant.employerName,
          employer_zip: applicant.employerZipCode,
          employment_type: applicant.employmentType,
        },
      },
      zero_bounce: zeroBounceValidations,
    };

    if (merchantRepresentative) {
      submitPayload.application.ith_dealer_email = merchantRepresentative.email;
      submitPayload.application.ith_dealer_phone =
        merchantRepresentative.phoneNumber;
    } else {
      submitPayload.application.ith_dealer_email = '';
      submitPayload.application.ith_dealer_phone = '';
    }

    if (
      merchant &&
      listOfUsersWithAccessToMerchantSelection.includes(
        userContextData.dealerLevelRole,
      )
    ) {
      /* 
        Weirdly enough, we don't techincally need to check if the user is in the listOfUsersWithAccessToMerchantSelection.
        That's because merchant will never be set otherwise. 
        Still, good idea to make sure just in case.

        TODO SANTI: delete or change after fixing situation with the resubmit put endpoint
          this particular if statement used to check !reApplyId, 
          but since the put for resubmits seems to no longer work, i'm not entirely sure if this is needed
      */
      submitPayload.dealer = {
        dealer_code: merchant.dealer_code,
        user_type: 'dealer',
      };
    }

    if (coapplicant) {
      submitPayload.has_coapplicant = true;
      submitPayload.financial_details[1] = {
        anual_income: castFormattedCurrencyStringToNumber(coapplicant.annualIncome),
        request_amount: castFormattedCurrencyStringToNumber(requestAmount),
        occupation: coapplicant.occupation,
        employment_type: coapplicant.employmentType,
        employer_name: coapplicant.employerName,
        employer_zip: coapplicant.employerZipCode,
        user_type: 'coapplicant',
      };
      submitPayload.coapplicant = {
        email: coapplicant.email,
        name: coapplicant.firstName,
        last_name: coapplicant.lastName,
        phone_number: coapplicant.phoneNumber,
        ssn: removeNonDigitCharacters(coapplicant.unmaskedSSN.concat(coapplicant.ssn)),
        birthday: coapplicant.birthday,
        us_citizen: true,
      };
    }

    if (enableStageFunding){
      submitPayload.application.project_amount_total = castFormattedCurrencyStringToNumber(projectAmountTotal);
      submitPayload.application.down_payment_amount = castFormattedCurrencyStringToNumber(downPayment) || 0;
    }

    /*
      the put to /v1/applications/${reApplyId} seems to not work (apparently it cant find the application to modify), 
      and it's not currently being used in staging whatsoever

      at the time of writing I'm not sure if this is intended or a bug. 
      In any case, I need it to work, so for now I'll leave the logic for switching between the endpoints commented out:
      
      const url = reApplyId
        ? `/v1/applications/${reApplyId}`
        : '/v1/applications';
      const method = reApplyId ? protectedPut : protectedPost;
    */

    const url = '/v1/applications';
    const method = protectedPost;

    /** @todo update to use better catch, @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Using_promises#error_handling */
    /*
    doSomething()
      .then((result) => doSomethingElse(result))
      .then((newResult) => doThirdThing(newResult))
      .then((finalResult) => console.log(`Got the final result: ${finalResult}`))
      .catch(failureCallback);
    */
    method(url, submitPayload)
      .then((applicationsEndpointResponse) => {
        protectedPost(
          `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/?application_id=${
            applicationsEndpointResponse?.data?.data?.id
          }&project_amount=${castFormattedCurrencyStringToNumber(requestAmount)}`,
        )
          .then((dealsetCreationResponse) => {
            setIsAwaitingBackendResponse(false);
            if (dealsetCreationResponse.data.data.attributes.incomplete) {
              history.push(
                `/applications/${applicationsEndpointResponse?.data?.data?.id}/incomplete`,
              );
            } else if (dealsetCreationResponse.data.data.attributes.declined) {
              history.push(
                `/dealer/applications/${applicationsEndpointResponse?.data?.data?.id}`,
              );
            } else if (
              dealsetCreationResponse.data.data.attributes.ineligible
            ) {
              history.push(
                `/applications/${applicationsEndpointResponse?.data?.data?.id}/ineligible`,
              );
            } else if (dealsetCreationResponse.data.data.attributes.exception) {
              history.push(
                `/applications/${applicationsEndpointResponse?.data?.data?.id}/exception`,
              );
            } else if (dealsetCreationResponse.data.data.attributes.reviewing) {
              history.push(
                `/applications/${applicationsEndpointResponse?.data?.data?.id}/reviewing`,
              );
            } else {
              history.push(
                `/applications/${applicationsEndpointResponse?.data?.data?.id}/review`,
              );
            }
          })
          .catch((err) => {
            setIsAwaitingBackendResponse(false);
            setBackendErrorMessage('There was a problem while submitting.');
            console.error(err);
          });
      })
      .catch((err) => {
        setIsAwaitingBackendResponse(false);
        setBackendErrorMessage('There was a problem while submitting.');
        console.error(err);
      });
  };

  // ----- custom state modifications -----
  /** @description If the applicant isn't employed or self_employed, then the employment information fields don't get rendered. If they don't get rendered, we should wipe their information from state. */
  useEffect(() => {
    if (!['employed', 'self_employed'].includes(applicant.employmentType)) {
      setApplicant((previousApplicant) => ({
        ...previousApplicant,
        employerName: '',
        occupation: '',
        employerZipCode: '',
      }));
    }
  }, [applicant.employmentType]);

  /** @description If the coapplicant isn't employed or self_employed, then the employment information fields don't get rendered. If they don't get rendered, we should wipe their information from state. */
  useEffect(() => {
    //  Wipe out the changes to the extra fields that appear depending on employment (coapplicant)
    if (
      coapplicant &&
      !['employed', 'self_employed'].includes(coapplicant.employmentType)
    ) {
      setCoapplicant((previousCoapplicant) => ({
        ...previousCoapplicant,
        employerName: '',
        occupation: '',
        employerZipCode: '',
      }));
    }
  }, [coapplicant?.employmentType]);

  /** @description change applicant email's validation status when the applicant's email changes */
  useEffect(() => {
    // i can't think of a better way of doing it, so for now it will remain as-is.

    if (!zeroBounceValidations) return;

    const newApplicantEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'applicant-email',
      ),
    );
    newApplicantEmailValidation.email = applicant.email;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newApplicantEmailValidation.isValidEmail !== null) {
      newApplicantEmailValidation.isValidEmail = null;
      newApplicantEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'applicant-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newApplicantEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [applicant.email]);

  /** @description change coapplicant email's validation status when the coapplicant's email changes */
  useEffect(() => {
    // i can't think of a better way of doing it, so for now it will remain as-is.

    if (!zeroBounceValidations) return;
    const newCoapplicantEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'coapplicant-email',
      ),
    );
    newCoapplicantEmailValidation.email = coapplicant
      ? coapplicant.email
      : null;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newCoapplicantEmailValidation.isValidEmail !== null) {
      newCoapplicantEmailValidation.isValidEmail = null;
      newCoapplicantEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'coapplicant-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newCoapplicantEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [coapplicant?.email]);

  /** @description change merchantRepresentative email's validation status when the merchantRepresentative's email changes */
  useEffect(() => {
    if (!zeroBounceValidations) return;

    // i can't think of a better way of doing it, so for now it will remain as-is.
    const newMerchantRepresentativeEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'merchant-representative-email',
      ),
    );
    newMerchantRepresentativeEmailValidation.email = merchantRepresentative
      ? merchantRepresentative.email
      : null;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newMerchantRepresentativeEmailValidation.isValidEmail !== null) {
      newMerchantRepresentativeEmailValidation.isValidEmail = null;
      newMerchantRepresentativeEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'merchant-representative-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newMerchantRepresentativeEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [merchantRepresentative?.email]);

  /** @description if the loanProduct code is "healthcare", then the merchantRepresentative, merchantReferenceId and promoCode fields don't get rendered. This makes sure to clean up leftover info */
  useEffect(() => {
    if (loanProduct?.attributes?.code === 'healthcare') {
      setMerchantRepresentative(null);
      setMerchantReferenceId(null);
      setPromoCode(null);
    }
  }, [loanProduct?.attributes?.code]);

  /** @description loanProduct needs to be re-set after a merchant changes, since each merchant has it's own list of loanProducts */
  useEffect(() => {
    /*
      If loanProduct.id exists, but loanProduct.attributes doesn't, its a resubmit.
      During a resubmit, the merchant will change. We don't want to reset it to null in that case.
    */
    if (loanProduct?.id && !loanProduct.attributes) return;

    if (loanProduct) {
      setLoanProduct(null);
    }
  }, [merchant]);

  /** @description if enableStageFunding is true, project amount is a static field calculated as requestAmount (renamed financedAmount) + downPayment */
  useEffect(() => {
    if (enableStageFunding) {
      const financedAmountAsNumber = castFormattedCurrencyStringToNumber(requestAmount) || 0;
      const downPaymentAsNumber = castFormattedCurrencyStringToNumber(downPayment) || 0;

      const thereIsNoFinancedAmount = !requestAmount || requestAmount === "$0";

      if(thereIsNoFinancedAmount) {
        if (projectAmountTotal !== "") {
          setProjectAmountTotal("")
        }
        return
      }

      setProjectAmountTotal(formatNumberToCurrencyString(financedAmountAsNumber + downPaymentAsNumber))
    }
  }, [requestAmount, downPayment, enableStageFunding])

  /**
   * ! form validation system
   * You might notice this form has almost no validation checks happening on the form itself.
   * Instead, the validation is implemented as follows:
   *
   * Each input decides themselves whether or not they're in an error state, and the parent form checks the dom to see if any of them is in error.
   * If there's any input that is in error, then the form cannot be submitted.
   *
   * Pros:
   * It's incredibly easy to add or remove inputs, as all you have to do is put them in there and give them the appropiate setState function.
   * It's also incredibly easy to modify the input's validations. All we need to make sure is that they are in an error state when they're supposed to be in an error state.
   *
   * Cons:
   * We have to do stuff with the DOM, and react isn't very well known for reading the DOM reliably.
   *  that said, i found that an implementation of both using a MutationObserver, as well as a useEffect, covers every single time that the DOM has changed.
   *  (though there could be some optimizations to be done here)
   * Multi-factor validation (see isValidIncomeAmount) requires passing down a prop
   *
   * From what i've been able to try out, the system works reliably and it has made adding/removing/modifying inputs a lot easier.
   */
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      /*
        A quick note on mutation observers: just remember that you can't trust the mutations. It will mutate things at random sometimes.
        It doesn't matter to us because we restrict it to aria-invalid or value, and we want to check for form validity whenever one of those changes anyways.
        But you can't predict what changes. I.e.: apparently the value will sometimes change on the merchant id input when changing the property ZIP code... 🤷
      */
      if (
        mutations.some(
          (mutationRecord) =>
            mutationRecord.attributeName === 'aria-invalid' ||
            mutationRecord.attributeName === 'value',
        )
      ) {
        runFormSubmitValidation();
      }
    });
    const newApplicationFormNode = document.getElementById(
      'new-application-form',
    );

    if (Boolean(newApplicationFormNode)) {
      observer.observe(newApplicationFormNode, {
        subtree: true,
        attributeFilter: ['aria-invalid', 'value'],
        attributeOldValue: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  /** @description call runFormSubmitValidation on state change (could this be a simple on-rerender useEffect?) */
  useEffect(() => {
    runFormSubmitValidation();
  }, [
    merchant,
    loanProduct,
    requestAmount,
    applicant,
    coapplicant,
    propertyInformation,
    serviceDate,
    merchantRepresentative,
    merchantReferenceId,
    promoCode,
  ]);

  const runFormSubmitValidation = () => {
    if (canSubmit && isAnyInputWithErrors()) {
      setCanSubmit(false);
    }
    if (!canSubmit && !isAnyInputWithErrors()) {
      setCanSubmit(true);
    }
  };

  const isAnyInputWithErrors = () => {
    const form = document.getElementById('new-application-form');
    const inputs = form.querySelectorAll('input');

    for (let i = 0; i < inputs.length; i++) {
      if (isInputInvalid(inputs[i])) {
        return true;
      }
    }

    return false;
  };

  function isInputInvalid(input) {
    if (input.type === 'date' && !input.required && input.value === '') {
      // bug found with date type inputs... tl-dr, if the input is NOT required, and it is emptied, we cant rely on the input.validity.valid to be true or false
      return false;
    } else {
      // if at any moment aria-invalid becomes unreliable, one simple fix would be to replace it with a custom data attribute for the html elements
      return (
        input.getAttribute('aria-invalid') === 'true' || !input.validity.valid
      );
    }
  }

  const addCoapplicant = () => {
    setCoapplicant({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      ssn: '',
      birthday: '',
      employmentType: '',
      annualIncome: '',
      employerName: '',
      occupation: '',
      employerZipCode: '',
      ssnConfirmation: '',
      birthdayConfirmation: '',
      unmaskedSSN: '',
      unmaskedSSNConfirmation: '',
    });
  };

  const addMerchantRepresentative = () => {
    setMerchantRepresentative({
      email: '',
      phoneNumber: '',
    });
  };

  const serviceDateLabel = useMemo(() => {
    switch (loanProduct?.attributes?.code) {
      case 'healthcare':
        return 'Treatment Date';
      case 'solar_esg':
        // The code is solar_esg even if the name is ESG-Solar
        return 'Estimated Installation Date';
      default:
        return 'Estimated Project Complete Date';
    }
  }, [loanProduct?.attributes?.code]);

  const handleWhyCantIContinue = () => {
    async function auxFunction() {
      setShowAllErrors(true);
    }
    auxFunction().finally(() => {
      const topMostInvalidInput = document.querySelector(
        "#new-application-form input[aria-invalid='true']",
      );

      topMostInvalidInput?.focus();

      topMostInvalidInput?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    });
  };

  /**
   * @description validation function passed to both applicant and coapplicant field, as their total income must be above 50.000
   * @todo this seems like a good candidate for useCallback
   */
  const isValidIncomeAmount = () => {
    const numericApplicantAnualIncome = Number(
      castFormattedCurrencyStringToNumber(applicant.annualIncome),
    );
    const numericCoapplicantAnualIncome = coapplicant
      ? castFormattedCurrencyStringToNumber(coapplicant.annualIncome)
      : 0;
    const numericTotalAnualIncome =
      numericApplicantAnualIncome + numericCoapplicantAnualIncome;
    return numericTotalAnualIncome > minIncomeAmount;
  };

  /** @todo move if healthcare statement outside */
  const getOptionalFieldSections = () => {
    if (loanProduct?.attributes?.code == 'healthcare') return;

    const highlightEmail =
      zeroBounceValidations?.find(
        (elem) => elem.emailFieldId === 'merchant-representative-email',
      )?.isValidEmail === false;
    const highlightedEmailHelperText = highlightEmail
      ? `This email appears to be invalid.
  You can still submit the application if you'd like.`
      : '';
    return (
      <>
        {merchantRepresentative ? (
          <div className="new-application-merchant-representative form-card">
            <h2>Merchant Representative (optional)</h2>
            <p>Add an in-the-house Merchant Representative</p>
            <button
              className="new-application-form-remove-field"
              onClick={() => setMerchantRepresentative(null)}
              type="button"
              disabled={isAwaitingBackendResponse}
            >
              Remove Merchant Representative
            </button>
            <div className="new-application-merchant-representative-grid">
              <PhoneNumberField
                value={merchantRepresentative.phoneNumber}
                onChange={(value) =>
                  setMerchantRepresentative((oldValue) => ({
                    ...oldValue,
                    phoneNumber: value,
                  }))
                }
                id="merchant-representative-phone-number"
                label="Phone Number"
                required={
                  merchantRepresentative.phoneNumber.length > 0 ||
                  merchantRepresentative.email.length > 0
                }
                disabled={isAwaitingBackendResponse}
                showErrorsWhileClean={showAllErrors}
              />
              <EmailField
                value={merchantRepresentative.email}
                onChange={(value) =>
                  setMerchantRepresentative((oldValue) => ({
                    ...oldValue,
                    email: value,
                  }))
                }
                id="merchant-representative-email"
                label="Email"
                required={
                  merchantRepresentative.phoneNumber.length > 0 ||
                  merchantRepresentative.email.length > 0
                }
                disabled={isAwaitingBackendResponse}
                showErrorsWhileClean={showAllErrors}
                highlight={highlightEmail}
                helperText={highlightedEmailHelperText}
              />
            </div>
          </div>
        ) : (
          <button
            className="new-application-form-add-field"
            onClick={() => addMerchantRepresentative()}
            type="button"
            disabled={isAwaitingBackendResponse}
          >
            Add a Merchant Representative
          </button>
        )}

        {typeof merchantReferenceId === 'string' ? (
          <div className="new-application-merchant-reference-section form-card">
            <h2>Merchant Reference ID (optional)</h2>
            <TextField
              value={merchantReferenceId}
              onChange={(value) => setMerchantReferenceId(value)}
              label="Merchant Reference Id"
              className="full-width-input"
              disabled={isAwaitingBackendResponse}
              showErrorsWhileClean={showAllErrors}
            />
            <button
              className="new-application-form-remove-field"
              onClick={() => setMerchantReferenceId(null)}
              type="button"
              disabled={isAwaitingBackendResponse}
            >
              Remove Merchant Reference ID
            </button>
          </div>
        ) : (
          <button
            className="new-application-form-add-field"
            onClick={() => setMerchantReferenceId('')}
            type="button"
            disabled={isAwaitingBackendResponse}
          >
            Add a Merchant Reference ID
          </button>
        )}

        {typeof promoCode === 'string' ? (
          <div className="new-application-promo-code-section form-card">
            <h2>Promo Code (optional)</h2>
            <TextField
              value={promoCode}
              onClick={(value) => setPromoCode(value)}
              label="Promo Code"
              className="full-width-input"
              disabled={isAwaitingBackendResponse}
              showErrorsWhileClean={showAllErrors}
            />
            <button
              className="new-application-form-remove-field"
              onClick={() => setPromoCode(null)}
              type="button"
              disabled={isAwaitingBackendResponse}
            >
              Remove Promo Code
            </button>
          </div>
        ) : (
          <button
            className="new-application-form-add-field"
            onClick={() => setPromoCode('')}
            type="button"
            disabled={isAwaitingBackendResponse}
          >
            Add a Promo Code
          </button>
        )}
      </>
    );
  };

  const getDate180DaysAfterToday = () => {
    // We're trying to calculate 6 months in advance, but keep in mind months vary in length.
    return new Date(new Date().setDate(new Date().getDate() + 180))
  }

  /** @ignore just letting this useEffect commented here for ease of access for debugging. Feel free to delete it if it bothers you. */
  /*
  console.log(applicant)
  console.log(coapplicant)
  console.log(propertyInformation)
  console.log(disableNotifications)
  console.log(serviceDate)
  console.log(merchantRepresentative)
  console.log(merchantReferenceId)
  console.log(promoCode)
  console.table(zeroBounceValidations)
  console.log(merchant)
  console.log(loanProduct)
  console.table({requestAmount, projectAmountTotal, downPayment})
  useEffect(() => {
   console.log(isAwaitingBackendResponse)
  }, [isAwaitingBackendResponse])
  */

  return (
    <main
      className={
        'new-application-form ' +
        (isAwaitingBackendResponse ? 'new-application-form-loading' : '')
      }
      id="new-application-form"
    >
      <Snackbar
        open={backendErrorMessage.length > 0}
        autoHideDuration={6000}
        onClose={() => setBackendErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setBackendErrorMessage('')} severity="error">
          {backendErrorMessage}
        </Alert>
      </Snackbar>
      {isAwaitingBackendResponse && (
        <LinearProgress className="new-application-form-loading-bar" />
      )}
      <div className="form-header">
        <IconButton onClick={() => history.goBack()} title="Go back">
          <ChevronLeft fontSize="large" />
        </IconButton>
        <h1>Start Application</h1>
        <p>Let's get started!</p>
      </div>

      {listOfUsersWithAccessToMerchantSelection.includes(
        userContextData.dealerLevelRole,
      ) && (
        <MerchantSection
          merchant={merchant}
          setMerchant={setMerchant}
          isLoading={isAwaitingBackendResponse}
          showAllErrors={showAllErrors}
          onError={(message) => setBackendErrorMessage(message)}
        />
      )}

      <LoanSection
        loanProduct={loanProduct}
        setLoanProduct={setLoanProduct}
        projectAmountTotal={projectAmountTotal}
        setProjectAmountTotal={setProjectAmountTotal}
        downPayment={downPayment}
        setDownPayment={setDownPayment}
        requestAmount={requestAmount}
        setRequestAmount={setRequestAmount}
        merchant={merchant}
        isLoading={isAwaitingBackendResponse}
        showAllErrors={showAllErrors}
        maxRequestAmount={maxRequestAmount}
        userLevel={userContextData.dealerLevelRole}
        userId={userContextData.user.data.id}
        onError={(message) => setBackendErrorMessage(message)}
        listOfUsersWithAccessToMerchantSelection={
          listOfUsersWithAccessToMerchantSelection
        }
        enableStageFunding={enableStageFunding}
      />
      <ApplicantSection
        applicant={applicant}
        coapplicant={coapplicant}
        setApplicant={setApplicant}
        isLoading={isAwaitingBackendResponse}
        showAllErrors={showAllErrors}
        isValidIncomeAmount={minIncomeAmount ? isValidIncomeAmount : null}
        minIncomeAmount={minIncomeAmount}
        highlightEmail={
          zeroBounceValidations?.find(
            (elem) => elem.emailFieldId === 'applicant-email',
          ).isValidEmail === false
        }
        reapplyId={reApplyId}
      />
      {coapplicant ? (
        <CoApplicantSection
          applicant={applicant}
          coapplicant={coapplicant}
          setCoapplicant={setCoapplicant}
          removeCoApplicant={() => setCoapplicant(null)}
          isLoading={isAwaitingBackendResponse}
          showAllErrors={showAllErrors}
          isValidIncomeAmount={minIncomeAmount ? isValidIncomeAmount : null}
          minIncomeAmount={minIncomeAmount}
          highlightEmail={
            zeroBounceValidations?.find(
              (elem) => elem.emailFieldId === 'coapplicant-email',
            ).isValidEmail === false
          }
          reapplyId={reApplyId}
        />
      ) : (
        <button
          className="new-application-form-add-field"
          onClick={() => addCoapplicant()}
          type="button"
          disabled={isAwaitingBackendResponse}
        >
          Add a Coapplicant
        </button>
      )}
      <PropertySection
        propertyInformation={propertyInformation}
        setPropertyInformation={setPropertyInformation}
        isLoading={isAwaitingBackendResponse}
        showAllErrors={showAllErrors}
      />
      <div className="new-application-service-date form-card">
        <h2>{serviceDateLabel}</h2>
        <DateField
          label={serviceDateLabel}
          value={serviceDate}
          onChange={(value) => setServiceDate(value)}
          id="service-date"
          keyboardButtonAriaLabel={`Pick a ${serviceDateLabel.toLocaleLowerCase()}`}
          className="new-application-service-date-input"
          isLoading={isAwaitingBackendResponse}
          required={['healthcare', 'solar_esg'].includes(
            loanProduct?.attributes?.code,
          )}
          showErrorsWhileClean={showAllErrors}
          minimumDate={new Date()} // new Date() means today
          maximumDate={loanProduct?.attributes?.code === 'healthcare' ? getDate180DaysAfterToday() : undefined}
        />
      </div>
      <div className="new-application-applicants-notification form-card">
        <h2>Applicant Notifications</h2>
        <input
          type="checkbox"
          id="new-application-disable-notifications"
          name="new-application-disable-notifications"
          value={disableNotifications}
          onChange={() =>
            setDisableNotifications((previousValue) => !previousValue)
          }
          disabled={isAwaitingBackendResponse}
          defaultChecked
        />
        <label
          htmlFor="new-application-disable-notifications"
          className={
            isAwaitingBackendResponse
              ? 'new-application-disable-notifications-disabled'
              : ''
          }
        >
          Disable Applicant Notifications until the Loan Contract is generated.
        </label>
      </div>

      {getOptionalFieldSections()}

      <TermsAndConditionsText />
      <div className="new-application-submit-container">
        {isAwaitingBackendResponse ? (
          <CircularProgress />
        ) : (
          <Button
            onClick={() => formSubmit()}
            variant="contained"
            disabled={!canSubmit || isAwaitingBackendResponse}
            className="new-application-submit-button"
            type="submit"
            title="Continue application"
          >
            Continue
          </Button>
        )}
        {!canSubmit && (
          <Button
            type="button"
            disabled={isAwaitingBackendResponse}
            onClick={() => {
              handleWhyCantIContinue();
            }}
            title="Show why the Continue button is disabled"
          >
            Why can't I Continue?
          </Button>
        )}
      </div>
    </main>
  );
};

/**
 * @todo change ProjectQueue/index.js's onReapply() to instead send reApplyId through URL
 * unfortunately this wont remove the need for redux, as the VIS-1958 bug requires us to clean the redux store from here (even if we never use it...)
 */
const mapStatesToProps = (state) => {
  return {
    reApplyId: state.appReducer?.reApplyId,
  };
};

export default connect(mapStatesToProps, {
  updateData,
})(NewApplicationForm);
