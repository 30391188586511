import React from 'react';

export default function index({ width, height }) {
  return (
    <svg
      width={width || '20px'}
      height={height || '20px'}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <defs>
        <path
          d="M15 11.667V7.5a5 5 0 0 0-10 0v4.167c0 .607-.162 1.176-.446 1.666h10.892A3.318 3.318 0 0 1 15 11.667zM18.333 15H1.667c-1.111 0-1.111-1.667 0-1.667.92 0 1.666-.746 1.666-1.666V7.5a6.667 6.667 0 0 1 13.334 0v4.167c0 .92.746 1.666 1.666 1.666 1.111 0 1.111 1.667 0 1.667zm-6.17 2.918a2.5 2.5 0 0 1-4.325 0 .833.833 0 0 1 .72-1.251h2.884a.833.833 0 0 1 .72 1.251z"
          id="a"
        />
      </defs>
      <use
        fillOpacity=".4"
        fill="#1E272E"
        xlinkHref="#a"
        fillRule="evenodd"
        opacity=".46"
      />
    </svg>
  );
}
