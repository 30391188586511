import React, { useState } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  withStyles,
  Grid,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { protectedPost } from 'services/http';
import to from 'await-to-js';
import { MoneyFormatField } from 'components/InputFields/MoneyFormatField';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },
  containedButton: {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 600,
    // width: 198,
  },
  formControlInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
}));
const FormField = withStyles(() => ({
  root: {
    marginTop: 24,
    marginBottom: 24,
    '& > *': {
      marginBottom: '0.75rem',
    },
  },
}))(Box);

const FormLabel = withStyles(() => ({
  root: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
  },
}))(Typography);

const FormFieldWrapper = ({ label, children }) => {
  return (
    <FormField>
      <FormLabel as="h3">{label}</FormLabel>
      {children}
    </FormField>
  );
};
const AddNewChangeOrder = ({
  setFetchedData,
  setcreateFlag,
  createFlag,
  applicationNumber,
  originalLoanDetails,
  setAlertMessage,
  setErrorAlertMessage,
  setChangeOrderTab,
  disableCreateChangeOrder
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [resetValue, setResetValue] = useState(true);
  const submitChangeOrder = async () => {
    const payload = {
      change_order: {
        requested_base_amount: Number(values?.newLoanAmountToRequest),
      },
    };
    const [err, res] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/change_orders`,
        payload,
      ),
    );
    if (err) {
      let errorMsg = err?.response?.data?.message?.base[0];
      if (
        err?.response?.data?.message?.base[0].includes('exceed credit limit')
      ) {
        const msgArr = err?.response?.data?.message?.base[0].split(' ');
        var currValue = parseFloat(msgArr[msgArr.length - 1])
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        msgArr.pop();
        // msgArr[msgArr.length-1] = currValue;
        errorMsg = `${msgArr.join().replaceAll(',', ' ')} $${currValue}`;
      }

      setErrorAlertMessage(errorMsg || 'Error');
      return;
    }
    setAlertMessage('Change order created succesfully');
    setChangeOrderTab(true);
    setFetchedData(false);
    setcreateFlag(false);
    setResetValue(true);
  };
  const handleCancel = () => {
    setcreateFlag(false);
    setResetValue(true);
  };
  let existingAmount = '';
  let creditLimit = '';
  if (originalLoanDetails) {
    existingAmount = originalLoanDetails?.data?.data?.attributes?.base_amount;
    const applicationData = originalLoanDetails?.data?.included?.find(
      (element) => element.type === 'application',
    );
    creditLimit = applicationData
      ? applicationData?.attributes?.credit_limit
      : '';
  }
  const formik = useFormikContext();
  if (!formik) {
    console.error('error loading form');
  }

  const { values } = formik ? formik : '';
  return (
    createFlag && (
      <div data-testid="addChangeOrder-container">
        <Grid item xs={12}>
          <NumberFormat
            autoComplete
            fullWidth
            variant="outlined"
            className={classes.formControlInput}
            value={creditLimit}
            customInput={TextField}
            prefix="$"
            disabled
            type="text"
            thousandSeparator
            label="Credit Limit"
            decimalScale={2}
            fixedDecimalScale
          />
          {/* <TextField
            variant="outlined"
            autoFocus
            disabled
            label="Credit Limit"
            fullWidth
            name="creditLimit"
            value={`$ ${creditLimit?.toFixed(2)}`}
          /> */}
        </Grid>
        <FormFieldWrapper label="Dealer Base Install Amount">
          <TextField
            variant="outlined"
            autoFocus
            disabled
            label="Base Project Amount"
            fullWidth
            name="existingAmount"
            value={`$ ${existingAmount}`}
          />
        </FormFieldWrapper>

        <FormFieldWrapper label="New Dealer Base Install Amount">
          <MoneyFormatField
            label="Base Project Amount"
            name="newLoanAmountToRequest"
            data-testid="newLoanAmountToRequest"
            resetValue={resetValue}
            onBlur={() => {
              setResetValue(false);
            }}
          />
          <Typography className={classes.additionalInfo}>
            All change orders require an updated install contract and loan
            contract. The new amount should match the updated install contract.
          </Typography>
        </FormFieldWrapper>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={() => handleCancel()}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={() => submitChangeOrder()}
            disabled={disableCreateChangeOrder || !values?.newLoanAmountToRequest}
          >
            REQUEST
          </Button>
        </div>
      </div>
    )
  );
};

export default AddNewChangeOrder;
