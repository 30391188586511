import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  link: {
    backgroundColor: '#F79D3C1A',
    borderRadius: 4,
    padding: '12px 9px',
  },
});

const LinkWithBackground = ({
  link,
  textVariant,
  textFontSize,
  textLineHeight,
  textLetterSpacing,
  textFontWeight,
  text,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Typography
      className={classes.link}
      variant={textVariant}
      style={{
        color: theme.palette.primary.main,
        display: 'inline',
        fontSize: textFontSize,
        fontWeight: textFontWeight,
        lineHeight: textLineHeight,
        letterSpacing: textLetterSpacing,
      }}
    >
      <Link to={link}>{text}</Link>
    </Typography>
  );
};

export default LinkWithBackground;
