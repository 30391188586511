import React, { useState, useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import PulseLoader from 'react-spinners/PulseLoader';

import Text from 'components/TextFields/Text';
import { onVerifyDocument } from './UploadDocForm';

export const SocureSdkForm = ({
  token,
  setPrequalification,
  setSelectedFlow,
  selectedFlow,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (selectedFlow === 'sdkFlow') {
      window.Socure.init(
        process.env.REACT_APP_SOCURE_PUBLIC_KEY_SDK,
        '#socureSDKContainer',
        {
          onProgress: () => {},
          onSuccess: async response => {
            setIsLoading(true);
            await onVerifyDocument(
              { uuid: response.documentUuid },
              {
                token,
                setPrequalification,
                setSelectedFlow,
              },
            );
          },
          onError: () => {
            console.error('onError');
          },
          qrCodeNeeded: true,
          redirectURL: 'test.com',
        },
      );
      window.Socure.start(1);
    }
    return () => {
      window.Socure.reset();
    };
  }, [selectedFlow]);

  return (
    <Box>
      <div id="socureSDKContainer" style={{ minHeight: 480 }} />
      {isLoading && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <PulseLoader color={theme.palette.primary.light} size="1rem" />
          <Text textSize="sm+" semibold textColor="secondary">
            Please wait while our systems scan your document, this process may
            take up to 30 seconds
          </Text>
        </Box>
      )}
    </Box>
  );
};
