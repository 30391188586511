import React, { useState, useEffect } from 'react';
import { map } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import { protectedGet } from 'services/http';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';

import { useRedirectOnDealerStatus } from 'services/hooks/useRedirectOnDealerStatus';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import { PageTable } from 'components/PageTable';
import {
  PageTableHeader,
  QueryFilter,
  ColumnToggler,
  StateFilter,
} from 'components/PageTable/Header';
import { TableActionButton } from 'components/Buttons/NewApplicationButton';

import { PageTableWrapper, tableStyles } from 'pages/ApplicationQueue';
import { useTableSetup } from 'services/hooks/useTableSetup';
import usePageTableParameters from 'services/hooks/usePageTableParameters';
import { useErrorAlertMessage } from 'components/AlertContext';
import { notifyErrorWithRedirect } from 'services/utils/notifyErrorWithRedirect';
const headCells = [
  {
    id: 'id',
    label: 'Organization Id',
  },
  {
    id: 'name',
    label: 'Name',
  },
];
function useTableData() {
  const [rows, setRows] = useState([]);
  return [
    map((item) => {
      const rowCells = [];

      rowCells.push({
        type: 'link',
        value: `/organizations/${item.id}/edit`,
        subValue: `${item.id}`.padStart(4, '0'),
        state: { organizationId: item.id },
      });

      rowCells.push({
        value: item.name,
      });

      return rowCells;
    }, rows),
    setRows,
  ];
}

export const OrganizationList = () => {
  useRedirectOnDealerStatus();
  const classes = tableStyles();
  const history = useHistory();

  const [params, setParams] = useState({
    status: 'submitted',
  });
  const [rows, setRows] = useTableData();
  const { ...tableMethods } = usePageTableParameters({
    initialOrder: 'id',
  });
  const { page, perPage, order, sort } = tableMethods;
  // const {
  //   isOpen: isBulkAssigning,
  //   onOpen: onOpenBulkAssign,
  //   onClose: onCloseBulkAssign,
  // } = useDisclosure(false);

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  const errorAlertMessage = useErrorAlertMessage();

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const [err, organizations] = await to(
        protectedGet('/v1/organizations', {
          params: decamelizeKeys({
            ...params,
            page: page + 1,
            perPage,
            sort,
            order: decamelize(order),
          }),
        }),
      );
      setIsLoading(false);
      if (err) {
        notifyErrorWithRedirect(err, errorAlertMessage, history, '/dashboard');
        return;
      }
      const res = normalize(camelizeKeys(organizations.data)).get([
        'id',
        'name',
      ]);
      if (res.name) {
        res.homeowner = { name: res.name, lastName: res.lastName };
      }
      setTotalItems(Number(organizations.headers.total));
      setRows(res);
    }
    fetch();
    tableMethods.setSelected({});
  }, [params, page, perPage, sort, order]);
  const {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  } = useTableSetup({ rows, params, headCells });

  return (
    <>
      <PageTableWrapper isLoading={isLoading}>
        <PageTableHeader>
          <StateFilter
            possibleStatuses={['submitted']}
            status={params.status}
            setParams={setParams}
          />
          <Box className={classes.rightFilterWrapper}>
            {/* <QueryFilter query={params.query} setParams={setParams} /> */}
            <Hidden mdDown>
              <ColumnToggler
                columns={initialColumns.current}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
              />
            </Hidden>
          </Box>
        </PageTableHeader>
        <PageTable
          totalItems={totalItems}
          rows={filteredRows}
          headCells={filteredHeadCells}
          isLoading={isLoading}
          page={params.page}
          perPage={params.perPage}
          setParams={setParams}
          emptyStateTitle="No Organizations, yet."
          emptyStateDesc="Create a New Organization using the upper-right button"
          itemType={`${totalItems > 1 ? 'Organizations' : 'Organization'}`}
          onRowClick={onRowClick}
          {...tableMethods}
          hideCheckboxes
        />
      </PageTableWrapper>
      {/* <SelectedMenu selected={selected}>
        <SelectedMenuActionButton
          icon="people"
          label="Bulk Re-Assignment"
          onClick={onOpenBulkAssign}
        />
        <SelectedMenuActionButton icon="archive" label="Archive Organization" />
        <SelectedMenuActionButton icon="cancel" label="Cancel Organization" />
      </SelectedMenu>
      <ActionModal isOpen={isBulkAssigning} onClose={onCloseBulkAssign} /> */}
    </>
  );
};

export const OrganizationListHeaderActions = () => {
  const history = useHistory();
  return (
    <Box>
      <TableActionButton
        text="New Organization"
        onClick={() => history.push('/organizations/new')}
      />
    </Box>
  );
};
