import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';

import http from 'services/http';
import SettingsSection from 'components/SettingsSection';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import SettingsSectionAction from 'components/SettingsSection/SettingsSectionAction';
import InputField from 'components/InputFields/InputField';
import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { SelectField } from 'components/InputFields/SelectField';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';

const useStyles = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  driverlicenseNumber: null,
  businessOwner: '',
};

const PersonalInformationDealer = ({ userData }) => {
  const classes = useStyles();

  const handleUpdateInformation = async (values) => {};

  return (
    <SettingsSection>
      <SettingsSectionForm
        initialValues={initialValues}
        onSubmit={handleUpdateInformation}
        validationSchema={null}
      >
        <SettingsSectionFormGroup
          spacing={3}
          title="Personal Information"
          description="If you want to update any field please contact PowerPay at 800-397-4485"
        >
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              variant="outlined"
              disabled
              label="First Name"
              type="text"
              name="firstName"
              value={(userData && userData.user.firstname) || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              variant="outlined"
              disabled
              label="Last Name"
              type="text"
              name="lastName"
              value={(userData && userData.user.lastname) || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              select
              disabled
              variant="outlined"
              label="Business Owner"
              name="businessOwner"
              type="text"
              value={(userData && userData.user.owner ? 'Yes' : 'No') || ''}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              variant="outlined"
              disabled
              label="Phone Number"
              type="text"
              name="personalPhone"
              value={(userData && userData.user.personalPhone) || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              variant="outlined"
              disabled
              label="Email"
              type="email"
              name="email"
              value={(userData && userData.user.email) || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              variant="outlined"
              disabled
              label="Driver License's Number"
              type="number"
              name="driverLicenseNumber"
              value={(userData && userData.user.driverLicenseNumber) || ''}
            />
          </Grid>
        </SettingsSectionFormGroup>
        <SettingsSectionAction>SAVE CHANGES </SettingsSectionAction>
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default PersonalInformationDealer;
