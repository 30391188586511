import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core';
import { AffiliateListProvider } from 'services/contexts/useAffiliateList';
import { OrganizationListProvider } from 'services/contexts/useOrganizationList';
import materialTheme from './services/theme';
import { UserProvider } from 'components/UserContext';
import { AlertProvider } from 'components/AlertContext';
import { SessionExpirerProvider } from 'components/SessionExpirerContext';
import { AddressLocationsProvider } from 'services/contexts/useAddressLocations';
import { DealerListProvider } from 'services/contexts/useDealerList';
import { LoanProductListProvider } from 'services/contexts/useLoanProductList';
import { Router } from 'services/routes';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Loader from './components/Loaders';

import { rollbarConfig } from 'lib/rollbarConfig';

const queryClient = new QueryClient();


export default () => (
  <QueryClientProvider client={queryClient}>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <ThemeProvider theme={materialTheme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <UserProvider>
              <SessionExpirerProvider>
                <AlertProvider>
                  <AddressLocationsProvider>
                    <DealerListProvider>
                      <LoanProductListProvider>
                        <OrganizationListProvider>
                          <AffiliateListProvider>
                            <React.Suspense fallback={<Loader/>}>
                              <Router/>
                            </React.Suspense>
                          </AffiliateListProvider>
                        </OrganizationListProvider>
                      </LoanProductListProvider>
                    </DealerListProvider>
                  </AddressLocationsProvider>
                </AlertProvider>
              </SessionExpirerProvider>
            </UserProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </Provider>
  </QueryClientProvider>
);
