import React from 'react';

export default function imageJPG({ width, height }) {
  return (
    <svg
      width={width || '32px'}
      height={height || '42px'}
      viewBox="0 0 32 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Files / Png@1x</title>
      <desc>Created with Sketch.</desc>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon-/-Files-/-Png"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M27.6956522,42 L4.30434783,42 C1.92712216,42 -8.52651283e-14,40.0547544 -8.52651283e-14,37.6551724 L-8.52651283e-14,4.34482759 C-8.52651283e-14,1.94524557 1.92712216,0 4.30434783,0 L23.3913043,0 L32,8.68965517 L32,37.6551724 C32,40.0547544 30.0728778,42 27.6956522,42 Z"
          id="Path"
          fill="#FFCE6C"
          fillRule="nonzero"
        />
        <g
          id="Group-10"
          opacity="0.300000012"
          transform="translate(10.000000, 28.000000)"
          fillRule="nonzero"
        >
          <circle id="Oval" fill="#FFFFFF" cx="4" cy="1" r="1" />
          <polygon id="Path" fill="#DB8932" points="7 10 0 10 3.5 5" />
          <polygon id="Path" fill="#F79D3C" points="13 10 4 10 8.5 2" />
        </g>
        <path
          d="M32,8.68965517 L27.2557373,8.68965517 C24.7704559,8.68965517 23.3913043,7.03154602 23.3913043,4.54626465 L23.3913043,5.06261699e-14 L32,8.68965517 Z"
          id="Path"
          fill="#CDA453"
          fillRule="nonzero"
        />
        <g
          id="Group"
          transform="translate(4.000000, 11.000000)"
          fillRule="nonzero"
        >
          <path
            d="M24,3.072 L24,6.912 C24,8.608512 22.7357647,9.984 21.1764706,9.984 L2.82352941,9.984 C1.26423529,9.984 0,8.608512 0,6.912 L0,3.072 C0,1.375488 1.26423529,-3.83693077e-14 2.82352941,-3.83693077e-14 L21.1764706,-3.83693077e-14 C22.7357647,-3.83693077e-14 24,1.375488 24,3.072 Z"
            id="Path"
            fillOpacity="0.230168269"
            fill="#DB8932"
          />
          <rect
            id="Rectangle"
            fill="#FFFFFF"
            x="2.304"
            y="6.144"
            width="1.536"
            height="1.536"
          />
          <path
            d="M5.124096,7.676928 L6.660096,7.676928 L6.660096,6.029568 L7.2192,6.029568 C8.214528,6.029568 9.378048,5.531136 9.378048,4.125696 C9.378048,2.943744 8.631552,2.318592 7.2192,2.318592 L5.124096,2.318592 L5.124096,7.676928 Z M7.154688,3.568128 C7.658496,3.568128 7.873536,3.734784 7.873536,4.126464 C7.873536,4.561152 7.631616,4.781568 7.154688,4.781568 L6.659328,4.781568 L6.659328,3.568896 L7.154688,3.568896 L7.154688,3.568128 Z"
            id="Shape"
            fill="#FFFFFF"
          />
          <path
            d="M12.903936,7.676928 L14.440704,7.676928 L14.440704,2.31936 L12.968448,2.31936 L12.968448,3.902976 C12.968448,4.386048 13.032192,4.955904 13.09056,5.436672 L12.770304,4.683264 L11.5968,2.320128 L10.0608,2.320128 L10.0608,7.677696 L11.533056,7.677696 L11.533056,6.093312 C11.533056,5.60256 11.467776,5.04576 11.40864,4.5504 L11.7312,5.305344 L12.903936,7.676928 Z"
            id="Path"
            fill="#FFFFFF"
          />
          <path
            d="M17.873664,7.772928 C18.648576,7.772928 19.332864,7.432704 19.715328,7.114752 L19.744512,7.090176 L19.744512,4.494336 L17.601024,4.494336 L17.601024,5.77536 L18.368256,5.77536 L18.368256,6.36672 C18.289152,6.40512 18.170112,6.42816 18.048768,6.42816 C17.188608,6.42816 16.770816,5.958912 16.770816,4.991232 C16.770816,4.141056 17.233152,3.568896 17.92128,3.568896 C18.310656,3.568896 18.518016,3.684864 18.763008,3.900672 L18.82368,3.952896 L19.678464,2.989824 L19.624704,2.936064 C19.166208,2.4768 18.55488,2.224128 17.905152,2.224128 C16.314624,2.224128 15.203328,3.382272 15.203328,5.038848 C15.204096,6.700032 16.252416,7.772928 17.873664,7.772928 Z"
            id="Path"
            fill="#FFFFFF"
          />
        </g>
      </g>
    </svg>
  );
}
