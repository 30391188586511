import React, { useEffect, useState } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import HomeownerTab from './Homeowner';
import HomeownerFinanceTab from './HomeOwnerFinance';
import CoApplicantTab from './CoApplicant';
import { ContactTab } from '../ContactTab';
import { DriverLicenseTab } from '../DriverLicenseTab';
import { CoApplicantDriverLicenseTab } from '../CoApplicantDriverLicenseTab';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 32,
  },
  transparentButton: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
  },
  modalWrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  modalCard: {
    position: 'relative',
    backgroundColor: 'white',
    maxWidth: '80vw',
    minWidth: '50vw',
    margin: 'auto',
    padding: '1rem',
    borderRadius: '0.5rem',
  },
  closeModal: {
    position: 'absolute',
    top: '0.5rem',
    right: '1rem',
    fontWeight: 'bolder',
    fontSize: '1.5rem',
    transform: ' scaleX(1.2)',
    fontFamily: 'sans-serif',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const ApplicationTab = ({
  application,
  setFetchedData,
}) => {
  const classes = useStyles();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [applicationFetchedData, setApplicationFetchedData] = useState(null);
  const [isDriverLicenseModalOpen, setIsDriverLicenseModalOpen] = useState(
    false,
  );
  const [
    isCoApplicantDriverLicenseModalOpen,
    setIsCoApplicantDriverLicenseModalOpen,
  ] = useState(false);
  const {
    loanRequestData,
    homeownerData,
    homeownerFinancesData,
    coapplicantData,
    coapplicantFinancesData,
    headerData,
  } = application;

  return (
    <>
      <HomeownerTab
        data={homeownerData}
        status={headerData.status}
        draftStatus={application.headerData.draftStatus}
        onUpdateContactButtonClick={() => setIsContactModalOpen(true)}
        onUpdateDriverLicenseButtonClick={() =>
          setIsDriverLicenseModalOpen(true)
        }
      />
      {homeownerFinancesData.employmentType !== 'retired' && (
        <>
          <Divider className={classes.divider} />
          <HomeownerFinanceTab data={homeownerFinancesData} />
        </>
      )}
      <Divider className={classes.divider} />

      {application.meta.hasCoapplicant ? (
        <>
          <Divider className={classes.divider} />
          <CoApplicantTab
            data={coapplicantData}
            status={headerData.status}
            onUpdateContactButtonClick={() => setIsContactModalOpen(true)}
            onUpdateCoApplicantDriverLicenseButtonClick={() =>
              setIsCoApplicantDriverLicenseModalOpen(true)
            }
          />
        </>
      ) : null}

      <Modal
        open={isContactModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setIsContactModalOpen(false)}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.modalCard}>
            <button
              className={classes.transparentButton}
              onClick={() => setIsContactModalOpen(false)}
              type="button"
            >
              <span className={classes.closeModal}>X</span>
            </button>
            <ContactTab application={application} />
          </div>
        </div>
      </Modal>
      <Modal
        open={isDriverLicenseModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setIsDriverLicenseModalOpen(false)}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.modalCard}>
            <button
              className={classes.transparentButton}
              onClick={() => setIsDriverLicenseModalOpen(false)}
              type="button"
            >
              <span className={classes.closeModal}>X</span>
            </button>
            <DriverLicenseTab
              application={application}
              setFetchedData={setFetchedData}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={isCoApplicantDriverLicenseModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={() => setIsCoApplicantDriverLicenseModalOpen(false)}
      >
        <div className={classes.modalWrapper}>
          <div className={classes.modalCard}>
            <button
              className={classes.transparentButton}
              onClick={() => setIsCoApplicantDriverLicenseModalOpen(false)}
              type="button"
            >
              <span className={classes.closeModal}>X</span>
            </button>
            <CoApplicantDriverLicenseTab
              application={application}
              setFetchedData={setFetchedData}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ApplicationTab;
