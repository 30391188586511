import React from 'react';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import Icon from 'components/Icon';

export const CancelAppDialog = ({ dialogState, handleClose, handleCancel }) => {
  return (
    <Dialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Icon icon="errorIcon" />
          </Grid>
          <Grid item xs={8}>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel the application? This action
              can't be undone.
            </DialogContentText>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleCancel} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
