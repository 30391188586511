import React from 'react';

import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Button,
} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

import Text from 'components/TextFields/Text';

import Image1 from 'assets/images/video1.jpg';
import Image2 from 'assets/images/video2.jpg';
import Image3 from 'assets/images/video3.jpg';
import Image4 from 'assets/images/video4.jpg';
import { none } from 'ramda';

const tutorials = [
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    videoPath: 'https://vimeo.com/364170292',
    imgPath: Image1,
    text: 'Welcome to POWERpay - home improvement loans',
    duration: '1:29 MINS',
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    videoPath: 'https://vimeo.com/390054126',
    imgPath: Image2,
    text: 'Home Improvement Loan program for Contractors, Remodelers and more',
    duration: '1:32 MINS',
  },
  {
    label: 'Bird',
    videoPath: 'https://vimeo.com/390053562',
    imgPath: Image3,
    text: 'Home Improvement Loan program for Contractors, Remodelers and more',
    duration: '1:32 MINS',
  },
  {
    label: 'Bali, Indonesia',
    videoPath: 'https://vimeo.com/373616444',
    imgPath: Image4,
    text: 'PowerPay Home Improvement Loan - financing program for contractors',
    duration: '1:25 MINS',
  },
];

const useStyles = makeStyles((theme) => ({
  container: {},
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  stepper: {
    background: 'transparent',
  },
  dots: {
    display: 'none',
  },
  cards: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    '&.BrainhubCarousel__arrow--disable': {
      display: 'none',
    },
  },
  responsiveCards: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  removeArrow: {
    display: 'none',
  },
  card: {
    borderRadius: 4,
    margin: '8px',
    width: 324,
  },
  cardMedia: {
    height: 159,
  },
}));

const CarouselCards = () => {
  const classes = useStyles();
  const [slideNumber, setSlideNumber] = React.useState(0);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          textColor="#1E272E"
          textFontSize={16}
          textFontWeight={600}
          textLetterSpacing={0.6}
          textLineHeight="24px"
        >
          Videos & Tutorials
        </Text>
      </div>
      <Carousel
        slidesPerPage={3}
        className={classes.cards}
        onChange={(e) => {
          setSlideNumber(e);
        }}
        arrowLeft={
          <Button>
            <KeyboardArrowLeft className={slideNumber === 0?classes.removeArrow:''} />
          </Button>
        }
        arrowRight={
          <Button>
            <KeyboardArrowRight className={slideNumber >= tutorials.length-1?classes.removeArrow:''} />
          </Button>
        }
        addArrowClickHandler
      >
        {tutorials.map((tutorial) => (
          <Card className={classes.card}>
            <CardActionArea>
              <a href={tutorial.videoPath} target="_blank" rel="noreferrer">
                <CardMedia
                  className={classes.cardMedia}
                  title={tutorial.label}
                  image={tutorial.imgPath}
                />
                <CardContent className={classes.cardContent}>
                  <Text
                    textColor="#9198A0"
                    textFontSize={12}
                    textFontWeight={600}
                    textLetterSpacing={0.6}
                    textLineHeight="16px"
                  >
                    {tutorial.duration}
                  </Text>
                  <Text
                    textColor="#1E272E"
                    textFontSize={13}
                    textFontWeight={500}
                    textLetterSpacing={0.65}
                    textLineHeight="18px"
                    text={tutorial.text}
                  />
                </CardContent>
              </a>
            </CardActionArea>
          </Card>
        ))}
      </Carousel>
      <Carousel
        slidesPerPage={1}
        className={classes.responsiveCards}
        arrowLeft={
          <Button>
            <KeyboardArrowLeft />
          </Button>
        }
        arrowRight={
          <Button>
            <KeyboardArrowRight />
          </Button>
        }
        addArrowClickHandler
      >
        {tutorials.map((tutorial) => (
          <Card className={classes.card}>
            <CardActionArea>
              <a href={tutorial.videoPath} target="_blank" rel="noreferrer">
                <CardMedia
                  className={classes.cardMedia}
                  title={tutorial.label}
                  image={tutorial.imgPath}
                />
                <CardContent className={classes.cardContent}>
                  <Text
                    textColor="#9198A0"
                    textFontSize={12}
                    textFontWeight={600}
                    textLetterSpacing={0.6}
                    textLineHeight="16px"
                  >
                    {tutorial.duration}
                  </Text>
                  <Text
                    textColor="#1E272E"
                    textFontSize={13}
                    textFontWeight={500}
                    textLetterSpacing={0.65}
                    textLineHeight="18px"
                    text={tutorial.text}
                  />
                </CardContent>
              </a>
            </CardActionArea>
          </Card>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselCards;
