
// import { SAVE_VALUES } from './newApp.action';

import produce from "immer";



const reducer = (state = {}, action) => {

    switch (action.type) {

        case "UPDATE_FIELD_DATA":
            {
              return {...state,
                  [action.payload.name]:action.payload.value
              }
            }


        default: return state;

    }

};

export default reducer;
