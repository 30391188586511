import React from 'react';
import { addIndex, map } from 'ramda';

import { makeStyles } from '@material-ui/core/styles';
import Icon from 'components/Icon';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { useAlertContext } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';

const useStyles = makeStyles(() => ({
  uploadBtn: {
    padding: '0 0.5rem',
    minWidth: 0,
    width: '100%',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start',
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.05)',
      },
    },
  },
}));

function getColor(payment) {
  switch (payment.status) {
    case 'paid':
      return { color: '#1DD1A1' };
    case 'requested':
    case 'released':
      return { color: '#ff7f50' };
    default:
    case 'pending':
      return { color: '#1E272E', opacity: 0.75 };
  }
}

export const StageFundComponent = ({
  payments,
  currentPayment,
  maxPayments,
  setSelectedRequestFund,
  onOpenRequestFundDialog,
  status,
}) => {
  const classes = useStyles();
  const { userType } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const isDisabled = payments?.[currentPayment]?.status !== 'pending';
  const hasStarted = ['in_progress', 'completed', 'finished'].includes(status);

  const isHomeowner = userType === 'homeowner';

  return (
    <Tooltip
      title={isDisabled ? 'Funding Requested' : 'Request Funding'}
      aria-label="Request Funding"
    >
      <span>
        <Button
          type="button"
          className={classes.uploadBtn}
          disabled={isHomeowner}
          onClick={(e) => {
            e.stopPropagation();
            if (!isDisabled && hasStarted) {
              const isLast = currentPayment + 1 === payments.length;
              setSelectedRequestFund(payments[currentPayment].id, isLast);
              onOpenRequestFundDialog();
            } else {
              if (!hasStarted) {
                setErrorAlertMessage('Project Has Not Started');
                return;
              }
              setErrorAlertMessage('Funding Already Requested');
            }
          }}
        >
          {addIndex(map)(
            (item, i) => (
              <Icon key={i} icon="stageFundCoin" {...getColor(payments[i])} />
            ),
            Array(maxPayments),
          )}
        </Button>
      </span>
    </Tooltip>
  );
};
