import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Icon from 'components/Icon';

import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    borderBottom: 'solid 1px',
    borderBottomColor: '#ECEFF2',
    color: theme.palette.header.project,
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '600',
    letterSpacing: '0.6px',
    lineHeight: '32px',
    flexGrow: 1,
  },
}));

export const PublicRouteLayout = React.memo(({ children }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
      minWidth="0"
      style={{
        height: '100%',
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        style={{ backgroundColor: 'white' }}
        elevation={0}
      >
        <Toolbar className={classes.appBar}>
          <Link
            href={`${process.env.REACT_APP_FRONT_URL}`}
            rel="noopener noreferrer"
          >
            <Icon icon="logo" height="31" width="150"></Icon>
          </Link>
        </Toolbar>
      </AppBar>

      <Box flexGrow="1" style={{ backgroundColor: '#F9FAFC' }}>
        {children}
      </Box>
    </Box>
  );
});
