import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';

import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { camelizeKeys } from 'humps';
import normalize from 'json-api-normalize';
import Text from '../../components/TextFields/Text';
import ApiDocumentation from './ApiDocumentation';
import PersonalInformationHomeOwner from './PersonalInformation/HomeOwner';
import PersonalInformationDealer from './PersonalInformation/Dealer';
import BusinessInformationGroup from './BusinessInformationGroup';
import Security from './Security';
import PhoneNumber from './PhoneNumber';
import DefaultPage from './DefaultPage';
import DealerCode from './DealerCode';
import MerchantPreferences from './MerchantPreferences';

const useStyles = makeStyles(() => ({
  logout: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 13,
    marginTop: 22,
    width: '100%',
  },
  logoutButton: {
    margin: '0 15px',
    maxHeight: 34,
    minWidth: 70,
  },
  divider: {
    border: 'solid 1px #C1C9D1',
    width: '90%',
  },
  footer: {
    marginTop: 13,
    marginBottom: 22,
    textAlign: 'center',
  },
  container: {
    padding: '24px',
  },
}));

const settingsSectionsByRole = {
  dealer: [
    {
      name: 'dealerCode',
      Component: DealerCode,
    },
    {
      name: 'merchantPreferences',
      Component: MerchantPreferences,
    },
    {
      name: 'apiDocumentation',
      Component: ApiDocumentation,
    },
    {
      name: 'personalInformationDealer',
      Component: PersonalInformationDealer,
    },
    {
      name: 'phoneNumber',
      Component: PhoneNumber,
    },
    {
      name: 'security',
      Component: Security,
    },
    // {
    //   name: 'Notifications',
    //   Component: Notifications,
    // },
    {
      name: 'defaultPage',
      Component: DefaultPage,
    },
    {
      name: 'businessInformationDealer',
      Component: BusinessInformationGroup,
    },
  ],
  homeowner: [
    {
      name: 'personalInformationHomeOwner',
      Component: PersonalInformationHomeOwner,
    },
    {
      name: 'security',
      Component: Security,
    },
  ],
};

const Settings = () => {
  const classes = useStyles();
  const { user, dealerLevelRole } = useUserContext();
  const [userData, setUserData] = useState(null);

  const userId = user.data.id;

  useEffect(() => {
    async function getUserData() {
      try {
        const response = camelizeKeys(
          (
            await protectedGet(
              `${process.env.REACT_APP_BASE_URL}/v1/users/${userId}`,
            )
          ).data,
        );
        const dataNormalize = normalize(response).get([
          'name',
          'lastName',
          'email',
          'driverLicenseNumber',
          'owner',
          'phoneNumber',
          'ssn',
          'activeMilitary',
          'usCitizen',
          'address.streetAddress',
          'address.addressLine2',
          'address.zipCode',
          'address.state',
          'address.city',
          'business.name',
          'business.einSsnn',
          'business.businessType',
          'business.phoneNumber',
          'business.annualValue',
          'business.legalName',
        ]);

        const settingsData = {
          user: {
            firstname: dataNormalize.name,
            lastname: dataNormalize.lastName,
            email: dataNormalize.email,
            driverLicenseNumber: dataNormalize.driverLicenseNumber,
            owner: dataNormalize.owner,
            personalPhone: dataNormalize.phoneNumber,
            ssn: dataNormalize.ssn,
            activeMilitary: dataNormalize.activeMilitary,
            usCitizen: dataNormalize.usCitizen,
          },
          business: {
            businessName: dataNormalize.business && dataNormalize.business.name,
            annualRevenue:
              dataNormalize.business && dataNormalize.business.annualValue,
            legalBusinessName:
              dataNormalize.business && dataNormalize.business.legalName,
            einSssnn: dataNormalize.business && dataNormalize.business.einSsnn,
            typeOfCompany:
              dataNormalize.business && dataNormalize.business.businessType,
            businessPhone:
              dataNormalize.business && dataNormalize.business.phoneNumber,
          },
          address: {
            streetAddress:
              dataNormalize.address && dataNormalize.address.streetAddress,
            addressLine2:
              dataNormalize.address && dataNormalize.address.addressLine2,
            zipCode: dataNormalize.address && dataNormalize.address.zipCode,
            state: dataNormalize.address && dataNormalize.address.state,
            city: dataNormalize.address && dataNormalize.address.city,
          },
        };
        setUserData(settingsData);
      } catch (e) {
        console.error(e);
      }
    }

    getUserData();
  }, []);

  return (
    <Grid container className={classes.container}>
      {settingsSectionsByRole[user.data.type] &&
        !(sessionStorage.getItem('passwordExpiry') === 'true') ? (
        settingsSectionsByRole[user.data.type].map(
          ({ name, Component }) =>
            <>
              {(dealerLevelRole !== 'affiliate' && name === "apiDocumentation")
                || ((dealerLevelRole === 'affiliate' || dealerLevelRole === 'organization')
                  && name === 'businessInformationDealer') || (dealerLevelRole !== 'dealer_network_overseer' && name === "merchantPreferences")
                ? "" :
                <Grid key={name} item xs={12}>
                  <Component userData={userData} />
                </Grid>
              }
            </>
            ,
        )
      ) : (
        <Grid key="security" item xs={12}>
          <Security userData={userData} />
        </Grid>
      )}
      <Grid item xs={12} className={classes.logout}>
        <div className={classes.divider} />
        <Link to="/signout" className={classes.logoutButton}>
          Log Out
        </Link>
        <div className={classes.divider} />
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Text
          text="GetPowerPay 2019-2020. Privacy Policy © All rights reserved."
          textVariant="subtitle1"
          textFontSize={12}
          textFontWeight={400}
          textLetterSpacing={0.55}
          textLineHeight="12px"
        />
      </Grid>
    </Grid>
  );
};

export default Settings;
