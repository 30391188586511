import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import {
    makeStyles, Button
} from '@material-ui/core';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { useAlertContext } from 'components/AlertContext';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loaders';
import Text from '../../components/TextFields/Text';
import ContainedButton from '../../components/Buttons/ContainedButton';
import PasswordField from '../../components/InputFields/PasswordField';
import UserContext from '../../components/UserContext';

import http from '../../services/http';
import { setToken } from '../../services/authentication';

const validationSchema = yup.object({
    password: yup.string().required('Password is required'),
    confirmPassword: yup
        .string()
        .required('Confirm password is required')
        .when('password', {
            is: val => !!(val && val.length > 0),
            then: yup
                .string()
                .oneOf([yup.ref('password')], 'Both password need to be the same'),
        }),
});

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        justifyContent: 'space-around',
        margin: '0 auto',
        width: '100%',
    },
    button: {
        boxShadow: '2px 14px 12px -12px #f79d3c',
        borderRadius: 4,
        color: 'white',
        fontSize: 18,
        lineHeight: '28px',
        letterSpacing: 0.6,
        marginTop: 12,
        marginBottom: 12,
        maxWidth: 345,
        minHeight: 40,
        padding: '9px 24px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            fontSize: 20,
            minWidth: 175,
            padding: '9px 12.5px',
            width: 'max-content',
        },
    },
    form: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        marginBottom: 20,
    },
    formTitle: {
        maxWidth: 345,
        width: '100%',
        [theme.breakpoints.up('md')]: {
            marginBottom: 56,
        },
    },
}));

const ConfirmInvite = ({ location, history }) => {
    const [showPassword, setShowPassword] = useState('password');
    const [showConfirmPassword, setShowConfirmPassword] = useState('password');
    const { setErrorAlertMessage,setAlertMessage } = useAlertContext();
    const classes = useStyles();
    const { setUser } = useContext(UserContext);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const [token, setToken] = useState(params.get('token'));
    const [genPassword, setGenPassword] = useState(params.get('generate_password'));
    useEffect(() => {

    }, []);
    const handleSubmit = async (values, setSubmitting, setErrors, confirmToken) => {
        const payload = {
            token: confirmToken,
            ssn: values.SSN,
            password: values.password,
        };
        try {
            const { data } = await http.post(
                `${process.env.REACT_APP_BASE_URL}/v1/homeowners/confirm_invite`,
                payload,
            );
            if(data){
                setAlertMessage("Account Confirmed Successfully");
                history.push('/signIn');

            }
        } catch (err) {
            if (err.response.status === 422) {
                if (genPassword === "true") {
                    setSubmitting(false);
                    var message = err.response.data.message.replace('Validation failed: ', '');
                    setErrorAlertMessage(err?.response?.data?.message || 'Error');
                }
                else {
                    setErrorAlertMessage(err?.response?.data?.message || 'Error');
                    // setErrors({ SSN: err.response.data.message });
                }
            } else {
                setErrorAlertMessage(err?.response?.data?.message || 'Error');
                setSubmitting(false);
            }

        }
    };

    const togglePasswordReveal = button => {
        if (button === 'password') {
            if (showPassword === 'password') {
                setShowPassword('text');
            } else {
                setShowPassword('password');
            }
            return showPassword;
        }
        if (showConfirmPassword === 'password') {
            setShowConfirmPassword('text');
        } else {
            setShowConfirmPassword('password');
        }
        return showConfirmPassword;
    };

    return (
        <div className={classes.contentWrapper}>
            <Header />
            <Formik
                initialValues={{
                    password: '',
                    confirmPassword: '',
                    SSN: ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    handleSubmit(values, setSubmitting, setErrors, token);
                }}
            >
                {({ isSubmitting, values, touched, errors }) =>
                    isSubmitting ? (
                        <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
                    ) : (
                        <Form className={classes.form}>
                            <div className={classes.formTitle}>
                                <Text
                                    textVariant="h5"
                                    textColor="#000000"
                                    textFontWeight={600}
                                    textFontSize={22}
                                    textLetterSpacing={0.5}
                                    textLineHeight="28px"
                                    text="Welcome To Powerpay!"
                                />
                                <Text
                                    textVariant="body1"
                                    textColor="rgba(30, 39, 46, 0.6)"
                                    textFontWeight={500}
                                    textFontSize={16}
                                    textLetterSpacing={0.6}
                                    textLineHeight="22px"
                                    text="Please Complete the form below to confirm your account and continue the loan Process."
                                />
                            </div>
                            <Text
                                textVariant="body1"
                                textColor="#636A72"
                                textFontWeight={600}
                                textFontSize={12}
                                textLetterSpacing={0.6}
                                textLineHeight="16px"
                                text="CONFIRM APPLICANTS'S SSN (LAST 4)"
                            />
                            <OutlinedTextField
                                label="SSN"
                                name="SSN"
                                style={{
                                    marginTop: "10px",
                                    marginBottom: "50px"
                                }}
                            />
                            {genPassword === "true" ?
                                <>
                                    <Text
                                        textVariant="body1"
                                        textColor="#636A72"
                                        textFontWeight={600}
                                        textFontSize={12}
                                        textLetterSpacing={0.6}
                                        textLineHeight="16px"
                                        text="CREATE A NEW PASSWORD"
                                    />
                                    <PasswordField
                                        showPassword={showPassword}
                                        togglePasswordReveal={() => togglePasswordReveal('password')}
                                        touched={touched}
                                        errors={errors}
                                        name="password"
                                        label="Password"
                                    />
                                    <PasswordField
                                        showPassword={showConfirmPassword}
                                        togglePasswordReveal={() =>
                                            togglePasswordReveal('confirmPassword')
                                        }
                                        touched={touched}
                                        errors={errors}
                                        name="confirmPassword"
                                        label="Confirm Password"
                                    />
                                    <ContainedButton
                                        className={classes.button}
                                        type="submit"
                                        text="DONE"
                                        variant="contained"
                                        color="primary"
                                        isSubmitting={isSubmitting}
                                        disabledOptions={values}
                                        errors={errors}
                                    />
                                </> :
                                <Button variant="contained" color="primary"
                                    className={classes.button}
                                    disabled={values?.SSN?.length !== 4}
                                    onClick={() => {
                                        handleSubmit(values, true, false, token);
                                    }}
                                >DONE</Button>
                            }

                        </Form>
                    )
                }
            </Formik>

            <Footer />
        </div>
    );
};

export default ConfirmInvite;
