import React, { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, ButtonBase, Box } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import ContainedButton from 'components/Buttons/ContainedButton';
import Icon from 'components/Icon';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';
import {
  postTwilioSendOTPCode,
  postTwilioVerifyOTPCode,
  postTwilioSendHomeownerOTPCode,
} from 'services/api/twilio';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginBottom: '0.75rem',
  },
  form: {
    marginTop: 40,
    [theme.breakpoints.up('md')]: {
      marginBottom: -40,
    },
  },
  linkText: {
    alignItems: 'center',
    color: '#9198A0',
    display: 'flex',
    fontSize: '0.875rem',
    letterSpacing: 0.6,
    lineHeight: '18px',
    marginRight: 32,
    fontWeight: 600,
    [`&:hover`]: {
      cursor: 'pointer',
    },
  },
  buttonsWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  },
  otpContainer: {
    marginTop: 22,
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  otpHeader: {
    marginTop: 16,
    fontSize: 12,
    '& button': {
      marginLeft: 4,
      '& span': {
        marginRight: 4,
      },
    },
  },
  otpHeaderCaption: {
    display: 'flex',
    alignItems: 'center',
  },
  otpFooter: {
    color: '#393F47',
    fontSize: 12,
    lineHeight: '15px',
    marginTop: 16,
    marginBottom: '2.25rem',
  },
}));

const OTP_NUM_INPUT = 4;

const PhoneVerification = ({
  phone,
  errors,
  handleNext,
  isSkippable,
  isUpdatePhone,
  isHomeOwner,
}) => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isOTPVerifying, setIsOTPVerifying] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const classes = useStyles();

  const handleSendCode = async () => {
    const response = isHomeOwner
      ? await postTwilioSendHomeownerOTPCode(phone)
      : await postTwilioSendOTPCode(phone);
    setIsCodeSent(true);
  };

  useEffect(() => {
    if (phone) {
      handleSendCode();
    }
  }, []);

  const handleVerificationCodeChange = async (newVerificationCode) => {
    setVerificationCode(`${newVerificationCode}`);
    if (`${newVerificationCode}`.length === OTP_NUM_INPUT) {
      setIsOTPVerifying(true);
      try {
        const response = await postTwilioVerifyOTPCode(
          phone,
          newVerificationCode,
        );
        setIsOTPVerifying(false);
        setAlertMessage('Phone verified successfully');
        handleNext();
      } catch (e) {
        console.error(e);
      }

      // if (error && error.response) {
      //   setErrorAlertMessage(error.response.data.message || 'Error');

      // }
    }
  };

  return (
    <>
      <Text
        className={classes.subtitle}
        textVariant="body1"
        textColor="#636A72"
        textFontWeight={600}
        textFontSize={12}
        textLetterSpacing={0.6}
        textLineHeight="16px"
        text="PHONE VERIFICATION"
      />

      {isCodeSent ? (
        <>
          <div className={classes.otpHeader}>
            <Text
              textVariant="body1"
              textColor="#1E272E"
              textFontSize={15}
              textFontWeight={500}
              textLetterSpacing={0.6}
              textLineHeight="24px"
              text="OTP Verification"
            />
            <div className={classes.otpHeaderCaption}>
              <span>Enter the code sent to </span>
              <ButtonBase type="button" onClick={() => setIsCodeSent(false)}>
                <Text
                  textVariant="button"
                  text={phone}
                  textColor="#393F47"
                  textFontSize={14}
                  textFontWeight={600}
                  textLineHeight="16px"
                  textLetterSpacing={0.23}
                />
                <Icon icon="pen" />
              </ButtonBase>
            </div>
          </div>
          <Box display="flex" flexDirection="column" maxWidth="292px">
            <OtpInput
              value={verificationCode}
              containerStyle={classes.otpContainer}
              inputStyle={{
                width: 58,
                height: 60,
                borderRadius: 6,
                backgroundColor: '#C1C9D1',
                border: 'none',
                outline: 'none',
                marginRight: 20,
                fontSize: 40,
                fontWeight: 'bold',
              }}
              isDisabled={isOTPVerifying}
              onChange={handleVerificationCodeChange}
              numInputs={OTP_NUM_INPUT}
              isInputNum
            />
            <div className={classes.otpFooter}>
              <span>Didn't receive OTP code? </span>
              <ButtonBase onClick={handleSendCode} type="button">
                <Text
                  variant="subtitle1"
                  text="Resend"
                  textFontSize={12}
                  textFontWeight={600}
                  textLineHeight="15px"
                  textLetterSpacing={0.6}
                />
              </ButtonBase>
            </div>
            {/* {isUpdatePhone && (
              <>
                <Hidden smDown>
                  <ContainedButton
                    onClick={() => handleVerificationCodeChange()}
                    text="UPDATE PHONE NUMBER"
                    variant="contained"
                    color="primary"
                    disabledOptions={{ phone }}
                    errors={errors}
                  />
                </Hidden>
                <Hidden mdUp>
                  <ContainedButton
                    onClick={handleNext}
                    text={
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        NEXT <ArrowRight />
                      </span>
                    }
                    variant="contained"
                    color="primary"
                    disabledOptions={[]}
                    errors={errors}
                  />
                </Hidden>
              </>
            )} */}
          </Box>
        </>
      ) : (
        <>
          <PhoneNumberField
            name="phone"
            label="Phone Number"
            id="phoneNumber-input"
          />
          <div className={classes.buttonsWrapper}>
            {isSkippable && (
              <Typography className={classes.linkText} onClick={handleNext}>
                Skip
              </Typography>
            )}

            <ContainedButton
              onClick={handleSendCode}
              text="SEND CODE"
              variant="contained"
              color="primary"
              disabledOptions={{ phone }}
              errors={errors}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PhoneVerification;
