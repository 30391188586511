export const applicationFields = [
  {
    title: 'First Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'name',
  },
  {
    title: 'Last Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'last_name',
  },
  {
    title: 'Mobile Phone Number',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'phone_number',
  },
  {
    title: 'Email',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'email',
  },
  {
    title: 'SSN',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'ssn',
  },
  {
    title: 'Date Of Birth',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'birthday',
    updateField: true,
    label: 'Date of Birth (MM/DD/YYYY)',
    // pad: true,
  },
  {
    title: 'SSN Confirmation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'ssn_confirmation',
  },
  {
    title: 'Date Of Birth Confirmation',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'birthday_confirmation',
    updateField: true,
    label: 'Date of Birth Confirmation (MM/DD/YYYY)',
    // pad: true,
  },
  {
    title: 'Driving License State',
    type: 'autoComplete',
    inputType: 'autoComplete',
    size: '6',
    fieldName: 'driver_license_state',
  },

  {
    title: 'Driving License Number',
    type: 'string',
    inputType: 'string',
    size: '6',
    fieldName: 'driver_license_number',
  },
  {
    title: ' Active Military',
    type: 'dropdown',
    inputType: 'dropdown',
    size: '6',
    dropDownValues: ['Yes', 'No'],
    defaultValue: '',
    fieldName: 'active_military',
    pad: true,
  },
  {
    title: "Add applicant's employment and income information.",
    type: 'text',
    inputType: 'text',
    size: '12',
    fieldName: '',
  },
  {
    title: 'Employment Type',
    type: 'dropdown',
    inputType: 'dropdown',
    dropDownValues: ['Employed', 'Self Employed', 'Retired'],
    size: '12',
    fieldName: 'employment_type',
    updateField: true,
  },
  {
    title: 'Occupation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'occupation',
  },
  {
    title: 'Employer Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'employer_name',
    updateField: true,
  },
  {
    title: 'Employer Zip',
    type: 'number',
    inputType: 'string',
    size: '12',
    fieldName: 'employer_zip',
    updateField: true,
  },
  {
    title: 'Applicant Annual Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'annual_income',
  },
  {
    title: 'Other Household Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'other_household_income',
  },
  {
    title: 'Employment Length (Years)',
    type: 'number',
    inputType: 'string',
    size: '12',
    fieldName: 'employer_time',
    updateField: true,
  },
];
export const coApplicantFields = [
  {
    title: 'First Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_name',
  },
  {
    title: 'Last Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_last_name',
  },
  {
    title: 'Mobile Phone Number',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_phone_number',
  },
  {
    title: 'Email',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_email',
  },
  {
    title: 'SSN',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_ssn',
  },
  {
    title: 'Date Of Birth',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'coapplicant_birthday',
    updateField: true,
    label: 'Date of Birth (MM/DD/YYYY)',
    // pad: true,
  },
  {
    title: 'SSN Confirmation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_ssn_confirmation',
  },
  {
    title: 'Date Of Birth Confirmation',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'coapplicant_birthday_confirmation',
    updateField: true,
    label: 'Date of Birth Confirmation (MM/DD/YYYY)',
    // pad: true,
  },
  {
    title: 'Driving License State',
    type: 'autoComplete',
    inputType: 'autoComplete',
    size: '6',
    fieldName: 'coapplicant_driver_license_state',
    editable: true,
  },

  {
    title: 'Driving License Number',
    type: 'string',
    inputType: 'string',
    size: '6',
    fieldName: 'coapplicant_driver_license_number',
    editable: true,
  },
  {
    title: 'Active Military',
    type: 'dropdown',
    inputType: 'dropdown',
    size: '6',
    dropDownValues: ['Yes', 'No'],
    defaultValue: '',
    fieldName: 'coapplicant_active_military',
    editable: true,
    pad: true,
  },
  {
    title: "Add applicant's employment and income information.",
    type: 'text',
    inputType: 'text',
    size: '12',
    fieldName: '',
  },
  {
    title: 'Employment Type',
    type: 'dropdown',
    inputType: 'dropdown',
    dropDownValues: ['Employed', 'Self Employed', 'Retired'],
    size: '12',
    fieldName: 'coapplicant_employment_type',
    updateField: true,
  },
  {
    title: 'Occupation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_occupation',
  },
  {
    title: 'Employer Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_employer_name',
    updateField: true,
  },
  {
    title: 'Employer Zip',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_employer_zip',
    updateField: true,
  },
  {
    title: 'Annual Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'coapplicant_annual_income',
  },

  {
    title: 'Employment Length (Years)',
    type: 'number',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_employer_time',
    updateField: true,
  },
];
export const propertyFields = [
  {
    title: 'Address',
    type: 'address',
    inputType: 'address',
    sectionTitle: 'Property Information',
    size: '12',
    fieldName: 'street_address',
  },
 
  {
    title: 'City',
    type: 'string',
    inputType: 'string',
    size: '4',
    fieldName: 'city',
  },
  {
    title: 'State',
    type: 'autoComplete',
    inputType: 'autoComplete',
    size: '4',
    fieldName: 'state',
  },
  {
    title: 'Zip Code',
    inputType: 'string',
    type: 'number',
    size: '4',
    fieldName: 'zip_code',
  },
  {
    title: 'Project Amount',
    inputType: 'currency',
    type: 'currency',
    size: '6',
    fieldName: 'request_amount',
  },
  {
    title: 'Own',
    type: 'dropdown',
    inputType: 'dropdown',
    defaultValue: 'Yes',
    dropDownValues: ['Yes','No'],
    size: '4',
    fieldName: 'owner',
  }
];
