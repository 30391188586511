import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import Text from '../TextFields/Text';
import Icon from '../Icon';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginLeft: '10%',
    },
  },
  contentWrapperAffiliate: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
    
  },
  textWrapper: {
    maxWidth: 395,
    textAlign: 'center',
  },
  linkText: {
    alignItems: 'center',
    color: '#9198A0',
    display: 'flex',
    fontSize: 14,
    letterSpacing: 0.6,
    lineHeight: '18px',
    marginBottom: 10,
    marginTop: 24,
    fontWeight: 500,
  },
}));

const SuccessMessage = ({ text, showBack }) => {
  const classes = useStyles();
  return (
    <div className={showBack?classes.contentWrapper:classes.contentWrapperAffiliate}>
      <Icon icon="check-email" />
      <Text
        textVariant="h5"
        textColor="#000000"
        textFontWeight={600}
        textFontSize={22}
        textLetterSpacing={0.5}
        textLineHeight="28px"
        text="Well done!"
      />
      <div className={classes.textWrapper}>
        <Text
          textVariant="body1"
          textColor="#636A72"
          textFontWeight={400}
          textFontSize={15}
          textLetterSpacing={0.56}
          textLineHeight="22px"
          text={text}
        />
      </div>
      <Typography>
        {showBack && (
          <Link to="/signin" className={classes.linkText}>
            <KeyboardArrowLeft style={{ marginRight: 0 }} />
            Go back
          </Link>
        )}
      </Typography>
    </div>
  );
};

export default SuccessMessage;
