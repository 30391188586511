import React, { useState, useEffect, useContext, useMemo } from 'react';
import to from 'await-to-js';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';

const LoanProductListContext = React.createContext();
export const { Consumer: LoanProductListConsumer, Provider } = LoanProductListContext;

export function LoanProductListProvider({ children }) {
  const [loanProductOptions, setLoanProductOptions] = useState(null);
  const { user, dealerLevelRole } = useUserContext();

  const rolesForGetAllDealers = ['affiliate', 'organization', 'super_admin'];
  useEffect(() => {
    if (!user) {
      setLoanProductOptions(null);
    }
    (async () => {
      try {
        if (
          user &&
          loanProductOptions === null 
        ) {
          const [err, data] = await to(protectedGet('/v2/loan_products'));
         
          let dropDownFormatted = data?.data?.data?.map((loanProduct) => ({
            label : loanProduct?.attributes?.name,
            value : loanProduct?.attributes?.id,
            dealerCode : loanProduct?.attributes?.id
          }));
          dropDownFormatted = dropDownFormatted.sort((a, b) => a.label.localeCompare(b.label));
          setLoanProductOptions(dropDownFormatted);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user]);
  useEffect(() => {
    if (!user && loanProductOptions) {
        setLoanProductOptions(null);
    }
  }, [loanProductOptions]);
  return (
    <Provider
      value={{
        loanProductOptions,
      }}
    >
      {children}
    </Provider>
  );
}

export const useLoanProductListContext = () => {
  const context = useContext(LoanProductListContext);
  return context;
};

export default LoanProductListContext;
