import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import http, { protectedPut } from 'services/http';
import SettingsSection from 'components/SettingsSection';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import SettingsSectionAction from 'components/SettingsSection/SettingsSectionAction';
import InputField from 'components/InputFields/InputField';
import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: '100%',
  },
  businessInformationContainer: {
    marginBottom: '2.75rem',
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  driverlicenseNumber: null,
  businessOwner: '',
};

const Security = () => {
  const classes = useStyles();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const [showCurrent, setShowCurrent] = useState('password');
  const [showPassword, setShowPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handlePasswordChange = async e => {
    const payload = {
      current_password: currentPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    };
    try {
      const response = await protectedPut(
        `${process.env.REACT_APP_BASE_URL}/auth/user/password`,
        payload,
      );
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      if (sessionStorage.getItem("passwordExpiry")==="true") {
        sessionStorage.setItem("passwordExpiry","false");
        window.location.reload();
      }
      setAlertMessage('Password changed successfully.');
    } catch (e) {
      console.error(e);
      var message = '';
      var errorMessages = e.response.data.errors.full_messages;
      var numberOfErrors = errorMessages.length;
      if (numberOfErrors === 1) {
        message = errorMessages;
      } else if (numberOfErrors > 1) {
        message = 'Multiple Errors: ';
        for (let i = 0; i < numberOfErrors; i++) {
          if (errorMessages[i].includes(
            'Current password is invalid',
          )) {
            message += 'Invalid current login credentials';
          } else {
            message += errorMessages[i];
          } if (message.charAt(message.length-1) !== '.') {
            message += '.';
          }
          message += ' '
        }
      } else {
        message = 'Error: Please try again.';
      }
      setErrorAlertMessage(message);
    }
  };

  const togglePasswordReveal = button => {
    switch (button) {
      case 'currentPassword':
        if (showCurrent === 'password') {
          setShowCurrent('text');
        } else {
          setShowCurrent('password');
        }
        break;
      case 'newPassword':
        if (showPassword === 'password') {
          setShowPassword('text');
        } else {
          setShowPassword('password');
        }
        break;
      case 'confirmPassword':
        if (showConfirmPassword === 'password') {
          setShowConfirmPassword('text');
        } else {
          setShowConfirmPassword('password');
        }
        break;
      default:
        return null;
    }
    return null;
  };

  return (
    <SettingsSection>
      <SettingsSectionForm
        initialValues={initialValues}
        onSubmit={handlePasswordChange}
        validationSchema={null}
      >
        <SettingsSectionFormGroup
          title="Security"
          description="Remember to use a secure password"
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              type={showCurrent}
              label="Current Password"
              variant="outlined"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordReveal('currentPassword')}
                      onMouseDown={() =>
                        togglePasswordReveal('currentPassword')
                      }
                    >
                      {showCurrent === 'password' ? (
                        <Visibility
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              type={showPassword}
              label="New Password"
              variant="outlined"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordReveal('newPassword')}
                      onMouseDown={() => togglePasswordReveal('newPassword')}
                    >
                      {showPassword === 'password' ? (
                        <Visibility
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              className={classes.inputField}
              type={showConfirmPassword}
              label="Confirm New Password"
              variant="outlined"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => togglePasswordReveal('confirmPassword')}
                      onMouseDown={() =>
                        togglePasswordReveal('confirmPassword')
                      }
                    >
                      {showConfirmPassword === 'password' ? (
                        <Visibility
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          style={{
                            color: '#A4B0BE',
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </SettingsSectionFormGroup>
        <SettingsSectionAction
          disabledOptions={{
            currentPassword,
            newPassword,
            confirmPassword,
            samePassword:
              newPassword === confirmPassword ? 'has the same password' : '',
          }}
          errors={[]}
        >
          CHANGE PASSWORD{' '}
        </SettingsSectionAction>
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default Security;
