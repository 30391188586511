import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(30,39,46,0.2)',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '0.5rem 0.5rem 0.5rem 0',
    },
  },
}));

export function SearchInput({ id, label, onInput, ...props }) {
  const classes = useStyles();

  return (
    <FormControl className={classes.control}>
      <OutlinedInput
        id={id}
        placeholder={label}
        onInput={onInput}
        {...props}
      />
    </FormControl>
  );
}
