import React, { useEffect, useState } from 'react';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { protectedPost } from 'services/http';
import to from 'await-to-js';
import PulseLoader from 'react-spinners/PulseLoader';
import { useTheme } from '@material-ui/core/styles';
import { Grid, Card, CardContent, makeStyles, Box } from '@material-ui/core';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Text from 'components/TextFields/Text';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DraftIcon from 'assets/icons/submit-draft.png';
import AcceptedIcon from 'assets/icons/submit-accept.png';
import RejectedIcon from 'assets/icons/submit-rejected.png';
import NotPreqIcon from 'assets/icons/submit-not-prequalified.png';
import { useErrorAlertMessage } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';
import { buildFormData } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '4rem 8rem 4rem 4rem',
    },
  },
  card: {
    flexGrow: 1,
    padding: '2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3.5rem',
      paddingBottom: '2rem',
    },
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

const items = (status, isHomeowner) => {
  switch (status) {
    case 'waitingForPrequal':
      return {
        icon: DraftIcon,
        title: 'Please wait!',
        subtitle: 'We are analyzing your information to evaluate your project.',
        desc: 'This process make take up to 30 seconds.',
      };
    case 'pending':
      return isHomeowner
        ? {
            icon: DraftIcon,
            title: 'Please wait!',
            subtitle:
              'We are validating your information, you will receive an email to continue the verification process.',
            desc:
              'Please follow the instructions, it may take up to 30 seconds to receive the email.',
          }
        : {
            icon: DraftIcon,
            title: 'Please wait!',
            subtitle:
              'We have sent an email to your client, so they can continue the verification process.',
            desc:
              'Pending a credit determination, please wait for the homeowner to confirm their identitiy.',
          };
    case 'prequalified':
      return {
        icon: AcceptedIcon,
        title: 'Good news!',
        subtitle:
          'Based on your credit profile, you pre-qualify for finance options from PowerPay.',
        desc:
          'Final lending decisions will be based on completing a full credit application and a hard credit inquiry. Additional documentation may be required during the underwriting process.',
      };
    case 'not_prequalified':
      return {
        icon: NotPreqIcon,
        title: 'We are Sorry!',
        subtitle: 'Your prequalification did not pass our automated process',
        desc:
          'At this time you do not qualify for any products. Please feel free to complete a full application for credit with one of our lending partner(s) directly for additional considerations on extending credit.',
      };
    default:
    case 'declined':
    case 'rejected':
      return {
        icon: RejectedIcon,
        subtitle:
          'We appreciate you considering PowerPay for your home improvement needs',
        desc:
          'At this time you do not qualify for any products. Please feel free to complete a full application for credit with one of our lending partner(s) directly for additional considerations on extending credit.',
      };
  }
};

export const createAppFromPrequalify = async (
  prequalify,
  history,
  setErrorAlertMessage,
) => {
  const formData = new FormData();

  buildFormData(
    formData,
    decamelizeKeys({
      application: {
        soft_pull_id: prequalify.id,
        addressAttributes: {
          streetAddress: `${prequalify.houseNumber} ${prequalify.address}`,
          zipCode: prequalify.zipCode,
          city: prequalify.city,
          state: prequalify.state,
        },
      },
      homeowner: {
        email: prequalify.email,
        name: prequalify.name,
        lastName: prequalify.lastName,
        phoneNumber: prequalify.phone || prequalify.mobilePhone,
        ssn: prequalify.ssn,
        birthday: prequalify.birthday,
      },
      financialDetails: [
        {
          anualIncome: prequalify.income || 0,
          requestAmount: prequalify.projectAmount || 0,
          userType: 'homeowner',
        },
      ],
    }),
  );
  const [err, r] = await to(protectedPost('/v1/applications', formData));

  if (err && err.response) {
    setErrorAlertMessage(err.response.data.message || 'Error');
    return;
  }

  const application = normalize(camelizeKeys(r.data)).get(['id']);
  history.push('/applications/new', {
    applicationId: application.id,
  });
};

export const DynamicConfirm = ({ status, icon, title, subtitle, desc }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  return (
    <Box
      paddingBottom="4rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img src={icon} className={classes.image} alt="confirm logo" />
      {
        title && 
        <Text
          textSize="cardTitle"
          textAlign="center"
          bold
          textColor={palette.text.primary}
        >
          {title}
        </Text>
      }
      <Text
        textSize="cardSubtitle"
        textAlign="center"
        textColor={palette.text.secondary}
        bold
        style={{ marginTop: '1rem', whiteSpace: 'pre-line' }}
      >
        {subtitle}
      </Text>
      <Text
        textSize="sm"
        textAlign="center"
        textColor={palette.text.secondary}
        style={{ marginTop: '1rem', whiteSpace: 'pre-line' }}
      >
        {desc}
      </Text>
      {status === 'waitingForPrequal' && (
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          paddingTop="1rem"
        >
          <PulseLoader color={palette.primary.light} size="1rem" />
        </Box>
      )}
    </Box>
  );
};

export const PrequalificationConfirmation = ({
  onReviewPrequalification,
  status,
  isOnPublicPage,
}) => {
  const { userType } = useUserContext();
  const isHomeowner = isOnPublicPage || userType === 'homeowner';
  const [loadingPrequal, setLoadingPrequal] = useState(false);
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth="48rem"
      margin="0 auto"
    >
      <DynamicConfirm status={status} {...items(status, isHomeowner)} />

      <Box display="flex" alignItems="center" flexWrap="wrap">
        {!isOnPublicPage && (
          <Link
            component={NavLink}
            to="/prequalify"
            style={{
              color: '#636A72',
              cursor: 'pointer',
              marginRight: '2rem',
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ChevronLeftIcon fontSize="large" />
            <Text textSize="sm" tex>
              Back to Prequalify
            </Text>
          </Link>
        )}

        {!['pending', 'waitingForPrequal'].includes(status) && (
          <Button
            type="button"
            variant="contained"
            color="primary"
            style={{
              padding: '0.5rem 0',
              width: '20rem',
              borderRadius: '4px',
            }}
            onClick={() => {
              setLoadingPrequal(true);
              onReviewPrequalification();
            }}
            disabled={loadingPrequal}
          >
            <Text bold textColor="white">
              {loadingPrequal ? 'PLEASE WAIT...' : 'REVIEW PREQUALIFICATION'}
            </Text>
          </Button>
        )}
      </Box>
      {loadingPrequal ? (
        <PulseLoader color={theme.palette.primary.light} size="1rem" />
      ) : null}
    </Box>
  );
};

export const ConfirmPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const errorAlertMessage = useErrorAlertMessage();

  const { prequalify, isOnPublicPage } = history.location.state ?? {};
  const { id, status } = prequalify || {};

  const onCreateApp = () => {
    createAppFromPrequalify(prequalify, history, errorAlertMessage);
  };

  const onReviewPrequalification = () => {
    history.push(`/prequalify/${id}/detail`);
  };

  useEffect(() => {
    if (!id || !status) {
      errorAlertMessage('Unauthorized Access');
      history.replace('/dashboard');
    }
    return () => {};
  }, [id, status]);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Card elevation={4} className={classes.card}>
          <CardContent>
            <PrequalificationConfirmation
              onCreateApp={onCreateApp}
              onReviewPrequalification={onReviewPrequalification}
              id={id}
              status={status}
              isOnPublicPage={isOnPublicPage}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
