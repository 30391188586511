import * as React from 'react';

function SvgOrganizationIcon({ width, height, color, ...props }) {
  return (
    <svg
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          d="M8.17 5.7L1 10.48V21h5v-8h4v8h5V10.25z"
          id="organization-icon_svg__a"
        />
        <path
          d="M10 3v1.51l2 1.33L13.73 7H15v.85l2 1.34V11h2v2h-2v2h2v2h-2v4h6V3H10zm9 6h-2V7h2v2z"
          id="organization-icon_svg__b"
        />
      </defs>
      <use
        fill={color || 'currentColor'}
        xlinkHref="#organization-icon_svg__a"
        fillRule="evenodd"
      />
      <use
        fill={color || 'currentColor'}
        xlinkHref="#organization-icon_svg__b"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgOrganizationIcon;
