import React from 'react';

import { makeStyles, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import trainingPdf from 'assets/documents/trainingFile.pdf';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import { useHistory } from 'react-router-dom';
import InfoCards from './InfoCards';
import CarouselCards from './CarouselCards';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px',
  },
  cards: {
    alignItems: 'center',
    display: 'flex',
    marginTop:'12px',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  card: {
    height: 207,
    margin: '1%',
    paddingTop: 16,
    paddingLeft: 24,
    position: 'relative',
    width: 359,
  },
  cardTitle: {
    maxWidth: 207,
  },
  cardTitleVariant: {
    maxWidth: 228,
  },
  cardText: {
    marginTop: 9,
    maxWidth: 216,
  },
  cardTextVariant: {
    marginTop: 9,
    maxWidth: 185,
  },
  icon: {
    display: 'none',
    position: 'absolute',
    bottom: -20,
    right: -25,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconVariant: {
    display: 'none',
    position: 'absolute',
    bottom: -25,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -12,
    right: -12,
    [theme.breakpoints.up('md')]: {
      bottom: -15,
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  iconVariantMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -10,
    right: 0,
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const Tutorials = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  if (user?.data?.type !== 'dealer') {
    setErrorAlertMessage('Unauthorized Access');
    history.replace('/dashboard');
  }

  return (
    <Grid container spacing={3} className={classes.container}>
      {/* <Card style={{
        width:'100%'
      }}>
        <CardContent>
          <Grid item xs={12}>
            <CarouselCards />
          </Grid>
          <Grid item xs={12}>
            <InfoCards />
          </Grid>
        </CardContent>
      </Card> */}

      <Grid className={classes.cards}>
        <Paper className={classes.card}>
          <div className={classes.cardTitleVariant}>
            <Text
              textColor="#4366F7"
              textFontSize={20}
              textFontWeight={600}
              textLetterSpacing={0.6}
              textLineHeight="28px"
              text="Platform Training Resources for you and your team!"
            />
          </div>
          <div className={classes.cardText}>
            <Text
              textColor="#9198A0"
              textFontSize={14}
              textLetterSpacing={0.65}
              textLineHeight="18px"
              text="You can view and download our platform training documents for reference."
            />
          </div>
          <div className={classes.link}>
            <Text
              textColor="#9198A0"
              textFontWeight={600}
              textFontSize={12}
              textLetterSpacing={0.6}
              textLineHeight="18px"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <a style={{color: "#9198a0"}} href ={trainingPdf} rel="noreferrer" target="_blank">
                {' '}
                SEE MORE
              </a>

              <KeyboardArrowRight />
            </Text>
          </div>
          <div className={classes.icon}>
            <Icon icon="dealer101" width="139px" height="184px" />
          </div>
          <div className={classes.iconMobile}>
            <Icon icon="dealer101" width="118px" height="152px" />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Tutorials;
