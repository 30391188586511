import React from 'react';

export default function DocumentDOC({ width, height }) {
  return (
    <svg
      width={width || '32px'}
      height={height || '42px'}
      viewBox="0 0 32 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Files / Pdf@1x</title>
      <desc>Created with Sketch.</desc>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon-/-Files-/-PDF"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M27.6956522,42 L4.30434783,42 C1.92712216,42 0,40.0547544 0,37.6551724 L0,4.34482759 C0,1.94524557 1.92712216,0 4.30434783,0 L23.3913043,0 L32,8.68965517 L32,37.6551724 C32,40.0547544 30.0728778,42 27.6956522,42 Z"
          id="Path"
          fill="#FF4757"
          fillRule="nonzero"
        />
        <path
          d="M32,8.68965517 L27.2557373,8.68965517 C24.7704559,8.68965517 23.3913043,7.03154602 23.3913043,4.54626465 L23.3913043,5.06261699e-14 L32,8.68965517 Z"
          id="Path"
          fill="#D73947"
          fillRule="nonzero"
        />
        <g
          id="Group-2"
          transform="translate(4.000000, 11.000000)"
          fillRule="nonzero"
        >
          <path
            d="M22.8,3.072 L22.8,6.912 C22.8,8.608512 21.5989765,9.984 20.1176471,9.984 L2.68235294,9.984 C1.20102353,9.984 0,8.608512 0,6.912 L0,3.072 C0,1.375488 1.20102353,-3.83693077e-14 2.68235294,-3.83693077e-14 L20.1176471,-3.83693077e-14 C21.5989765,-3.83693077e-14 22.8,1.375488 22.8,3.072 Z"
            id="Path"
            fill="#FF7D84"
          />
          <g
            id="Group"
            transform="translate(2.400000, 2.400000)"
            fill="#FFFFFF"
          >
            <rect id="Rectangle" x="0" y="3.84" width="1.536" height="1.536" />
            <path
              d="M4.914432,0.016128 L2.819328,0.016128 L2.819328,5.373696 L4.355328,5.373696 L4.355328,3.726336 L4.914432,3.726336 C5.90976,3.726336 7.07328,3.227904 7.07328,1.822464 C7.07328,0.640512 6.326784,0.016128 4.914432,0.016128 Z M4.356096,1.264128 L4.851456,1.264128 C5.355264,1.264128 5.570304,1.430784 5.570304,1.822464 C5.570304,2.257152 5.328384,2.477568 4.851456,2.477568 L4.356096,2.477568 C4.356096,2.477568 4.356096,1.264128 4.356096,1.264128 Z"
              id="Shape"
            />
            <path
              d="M12.121344,2.670336 C12.121344,0.933888 11.17056,0.016128 9.371136,0.016128 L7.7568,0.016128 L7.7568,5.373696 L9.451776,5.373696 C11.148288,5.372928 12.121344,4.387584 12.121344,2.670336 Z M9.292032,4.108032 L9.292032,1.281024 C9.947904,1.281024 10.553856,1.449984 10.553856,2.670336 C10.553856,3.944448 9.879552,4.108032 9.292032,4.108032 Z"
              id="Shape"
            />
            <polygon
              id="Path"
              points="16.433664 1.32864 16.433664 0.016128 12.883968 0.016128 12.883968 5.372928 14.419968 5.372928 14.419968 3.47136 16.146432 3.47136 16.146432 2.15808 14.419968 2.15808 14.419968 1.32864"
            />
          </g>
        </g>
        <g
          id="Group"
          opacity="0.300000012"
          transform="translate(5.000000, 26.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M7,2 L1,2 C0.44771525,2 0,1.55228475 0,1 C0,0.44771525 0.44771525,0 1,0 L7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z"
            id="Path"
          />
          <path
            d="M21.2525849,6 L0.747415066,6 C0.334629123,6 0,5.55228475 0,5 C0,4.44771525 0.334629123,4 0.747415066,4 L21.2525849,4 C21.6653709,4 22,4.44771525 22,5 C22,5.55228475 21.6653709,6 21.2525849,6 Z"
            id="Path"
          />
          <path
            d="M21.1886565,2 L10.8113435,2 C10.3632509,2 10,1.55228475 10,1 C10,0.44771525 10.3632509,0 10.8113435,0 L21.1886565,0 C21.6367491,0 22,0.44771525 22,1 C22,1.55228475 21.6367491,2 21.1886565,2 Z"
            id="Path"
          />
          <path
            d="M11.7353516,10 L1,10 C0.44771525,10 3.99680289e-14,9.55228475 3.99680289e-14,9 C3.99680289e-14,8.44771525 0.44771525,8 1,8 L11.7353516,8 C12.2876363,8 12.7353516,8.44771525 12.7353516,9 C12.7353516,9.55228475 12.2876363,10 11.7353516,10 Z"
            id="Path"
          />
          <path
            d="M20.7902832,10 L18,10 C17.4477153,10 17,9.55228475 17,9 C17,8.44771525 17.4477153,8 18,8 L20.7902832,8 C21.342568,8 21.7902832,8.44771525 21.7902832,9 C21.7902832,9.55228475 21.342568,10 20.7902832,10 Z"
            id="Path"
          />
        </g>
      </g>
      ;
    </svg>
  );
}
