export const personFields = {
  name: '',
  lastName: '',
  birthday: null,
  phoneNumber: '',
  email: '',
  ssn: '',
  activeMilitary: '',
  usCitizen: '',
};
export const financialDetailAttributes = {
  anualIncome: '',
  occupation: '',
  employerName: '',
  employerZip: '',
  employerTime: '',
  employmentType: 'employed',
  requestAmount: 0,
  term: 15,
};
export const newApplication = {
  hasDealer: true,
  isAppDealer: true,
  dealer: {
    dealerCode: '',
    dealerName: '',
  },
  loadedDealer: null,
  application: {
    addressAttributes: {
      streetAddress: '',
      addressLine2: '',
      zipCode: '',
      city: '',
      state: '',
    },
    owner: '',
    inTheHomeEmail: '',
    inTheHomePhoneNumber: '',
  },
  financialDetails: [
    {
      ...financialDetailAttributes,
      userType: 'homeowner',
    },
    {
      ...financialDetailAttributes,
      userType: 'coapplicant',
    },
  ],
  homeowner: { ...personFields },
  driverLicense: null,
  coapplicant: {
    ...personFields,
  },
  coapplicantDriverLicense: null,
  hasCoapplicant: false,
};
