import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {useField} from 'formik';
import {find, propEq} from 'ramda';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {createFilterOptions} from '@material-ui/lab';
import {AutocompleteInput} from "components/InputFields/AutocompleteField";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1E272E',
        },
    },
    error: {
        '& .MuiFormLabel-root': {
            color: theme.palette.error.main,
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '14px',
            marginRight: '14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${theme.palette.error.main} !important`,
        },
        '& .MuiIconButton-label': {
            color: theme.palette.error.main,
        },
    },
    queryFilter: {
        '& .MuiAutocomplete-inputRoot': {
            marginTop: '3px',
            padding: '3.5px !important',
            width: '100%',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(30,39,46,0.2)',
        },
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
            padding: '0.5rem 0.5rem 0.5rem 0',
        },
    },
}));


export const AutocompleteFieldDropDown = ({
                                              name,
                                              hideErrors = false,
                                              disabled,
                                              options,
                                              getCurrentValue,
                                              type,
                                              disable,
                                              ...props
                                          }) => {
    const classes = useStyles();
    const [field, meta, helpers] = useField({name});
    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.label} ${option.dealerCode}`,
    });
    const [currentValue, setCurrentValue] = useState(
        JSON.parse(localStorage.getItem(name)) || null
    );
    const clearValue = () => {
        setCurrentValue(null);
        localStorage.removeItem(name);
    }
    useEffect(() => {
        if (type !== 'dealerFilter' ? options && field.value : options) {
            const val = find(propEq('value', field.value))(options || []) || currentValue || (!field?.value && '')
            setCurrentValue(val);
            localStorage.setItem(name, JSON.stringify(val));
            if (type === 'dealerFilter') {
                if (currentValue !== val) {
                    getCurrentValue(
                        type !== 'dealerFilter'
                            ? find(propEq('value', field.value))(options || [])
                            : field?.value,
                    );
                }
            }
        }
    }, [options, field]);

    return (
        <>
            {type === 'dealerFilter' ? (
                <FormControl
                    disabled={disabled}
                    variant="outlined"
                    className={clsx(classes.formControl, {
                        [classes.error]: meta.error,
                    })}
                    error={Boolean(meta.error)}
                >
                    <AutocompleteInput
                        options={options}
                        disabled={disabled}
                        {...field}
                        {...props}
                        filterOptions={filterOptions}
                        renderOption={(option) => (
                            <>
                                {option.label}
                            </>
                        )}
                        getOptionLabel={(option) => {
                            return option?.label === undefined ? '' : `${option?.label}`;
                        }}
                        value={currentValue}
                        onChange={(ev, selectedValue) => {
                            const val = selectedValue?.value
                            helpers.setValue(val);
                            if (val === undefined) clearValue();
                        }}
                    />
                    {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
            ) : (
                <FormControl
                    disabled={disabled}
                    variant="outlined"
                    className={clsx(classes.formControl, {
                        [classes.error]: meta.error,
                    })}
                    error={Boolean(meta.error)}
                >
                    <AutocompleteInput
                        options={options}
                        disabled={disabled}
                        {...field}
                        {...props}

                        value={currentValue}
                        onChange={(ev, selectedValue) => {
                            const val = selectedValue?.value
                            helpers.setValue(val);
                            if (val === undefined) clearValue();
                        }}
                    />
                    {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
            )}
        </>
    );
};