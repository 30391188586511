import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserContext } from 'services/hooks/useUser';
import { getToken } from 'services/authentication';

export const useRedirectToDefaultUrl = () => {
  const history = useHistory();
  const user = getToken();
  const [previousUser, setPreviousUser] = useState(null);

  useEffect(() => {
    setPreviousUser(getToken());
  }, []);

  useEffect(() => {
    if (
      !previousUser &&
      user?.login &&
      user?.data?.attributes?.defaultUrl !== undefined &&
      user?.data?.attributes?.defaultUrl !== null &&
      user?.data?.attributes?.defaultUrl !== '' &&
      user?.data?.attributes?.defaultUrl !== '/dealer/default'
    ) {
      setTimeout(() => {
        history.replace(user.data.attributes.defaultUrl);
      }, 500);
    }
    setPreviousUser(user);
  }, [user?.accessToken]);
};
