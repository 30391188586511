import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  makeStyles,
} from '@material-ui/core';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '4rem',
    },
    padding: '1.5rem',
  },
}));

const HomeownerContactUs = (props) => {
  const classes = useStyles();
  const { user}  = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid xs={12}>
            <Card style={{ marginTop: '24px' }}>
              <iframe
                title="frame2"
                width="100%"
                id="gpp-calc"
                height="816"
                frameBorder={0}
                src="https://getpowerpay.com/contact-us/borrower/?emb=1"
              ></iframe>
            </Card>
      </Grid>
    </Grid>
  );
};

export default HomeownerContactUs;