import React from 'react';

import Grid from '@material-ui/core/Grid';
import { Typography, withStyles, makeStyles } from '@material-ui/core';

const StepNumber = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: '1rem',
    fontWeight: 600,
    letterSpacing: '0.74px',
    lineHeight: '1.25rem',
  },
}))(Typography);
const StepTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 600,
    letterSpacing: '0.6px',
    lineHeight: '1.75rem',
  },
}))(Typography);
const StepSubtitle = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    letterSpacing: '0.65px',
    lineHeight: '1.125rem',
  },
}))(Typography);
const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: '2rem',
  },
}));
export const StepHeader = ({ step, title, subtitle }) => {
  const classes = useStyles();
  return (
    <Grid item xs={10} className={classes.header}>
      {step && <StepNumber>Step {step}</StepNumber>}
      {title && <StepTitle>{title}</StepTitle>}
      {subtitle && (
        <StepSubtitle
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        ></StepSubtitle>
      )}
    </Grid>
  );
};
