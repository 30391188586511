import React, { useState, useEffect, useRef } from 'react';
import { protectedGet } from 'services/http';
import { TextField, Select, ZipCodeField } from './../CustomizedMUIInputs';
import { LinearProgress } from '@material-ui/core';

export const PropertySection = ({
  propertyInformation,
  setPropertyInformation,
  isLoading,
  showAllErrors,
}) => {
  const [listOfStates, setListOfStates] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  let autocomplete = useRef(null);

  useEffect(() => {
    const fetchListOfStates = async () => {
      protectedGet(`/v1/addresses/states`)
        .then((response) => {
          const newListOfStates = Object.keys(response.data).map((key) => {
            return {
              value: key,
              label: response.data[key],
              id: `state-${key}`,
            };
          });
          setListOfStates(newListOfStates);
          setIsFetching(false);
        })
        .catch((error) => console.error(error));
    };
    fetchListOfStates();
  }, []);

  useEffect(() => {
    /*
      Add tests here:
      Usually i wouldn't try to add tests like "does this exist", 
      but considering it's a third party script, i think it'd be a good idea to make sure the script gets loaded
    */
    if (window.google?.maps) return;
    const script = document.createElement('script');

    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=googleMapsApiCallback`;
    script.async = true;

    window.googleMapsApiCallback = () => {
      // this function is here to remove a warning on the console. It gets called after the google maps api is loaded, but we don't want it to do anything at all
      return;
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      autocomplete = null; //unsure if needed
    };
  }, []);

  useEffect(() => {
    /*
      we can't rely on an async after the script loads because it gets stored in cache, so it may already exist upon load.
      best i can think of is this useEffect that runs on every state change of the component
    */

    if (window.google && !autocomplete.current) {
      const searchInput = document.getElementById(
        'property-information-google-search-input',
      );

      autocomplete.current = new google.maps.places.Autocomplete(searchInput, {
        componentRestrictions: { country: ['us'] },
        fields: ['address_components'],
        types: ['address'],
      });

      autocomplete.current.addListener(
        'place_changed',
        setPropertyValuesFromGoogle,
      );
    }
  });

  const setPropertyValuesFromGoogle = () => {
    const place = autocomplete.current.getPlace();

    const streetNumber = place.address_components.find((elem) => elem.types.includes('street_number'))?.long_name
    const routeName = place.address_components.find((elem) => elem.types.includes('route'))?.long_name
    const locality = place.address_components.find((elem) => elem.types.includes('locality'))?.long_name
    const sublocality = place.address_components.find((elem) => elem.types.includes('sublocality'))?.long_name
    const state = place.address_components.find((elem) => elem.types.includes('administrative_area_level_1'))?.short_name
    const postalCode = place.address_components.find((elem) => elem.types.includes('postal_code'))?.long_name
    const postalCodeSuffix = place.address_components.find((elem) => elem.types.includes('postal_code_suffix'))?.long_name

    let formattedStreetAddress = `${streetNumber || ""} ${routeName || ""}`.trim()

    /*
      A note on the city field:
      There's no "city" on the google maps API. It's all localities, sublocalities and administrative areas
      Since administrative areas tend to be counties and states, i'll keep locality and sublocality as the "city" field.

      This will lead to awkward situations, like the fact that for someone living in Brooklyn NY, the "city" field will be Brooklyn, and the state New York
      I could add an exception here, but NY is not the only place in the USA that has this situation AFAIK, and i can't predict how other places will behave. 
    */
    let city = "";
    if (sublocality) {
      city = sublocality
    }
    if (locality){
      city = locality
    }

    let formattedZipCode;
    if (postalCode){
      formattedZipCode = postalCode;
      if(postalCodeSuffix){
        formattedZipCode = formattedZipCode + `-${postalCodeSuffix}`
      }
    } else {
      formattedZipCode = ""
    }

    setPropertyInformation({
      streetAddress: formattedStreetAddress,
      city: city,
      state: state || "",
      zipCode: formattedZipCode,
      isOwner: propertyInformation.isOwner
    });
  };

  return (
    <div className="property-information-section form-card">
      <h2>Property Information</h2>
      <p>Start typing to get address suggestions</p>
      <TextField
        value={propertyInformation.streetAddress}
        onChange={(newValue) => setPropertyInformation(oldValue => ({...oldValue,  streetAddress: newValue }))}
        label="Street Address"
        disabled={isFetching || isLoading}
        loading={isFetching || isLoading}
        className="property-information-street-address-input"
        id="property-information-google-search-input"
        showErrorsWhileClean={showAllErrors}
        required
      />
      <TextField
        value={propertyInformation.city}
        onChange={(newValue) => setPropertyInformation(oldValue =>({...oldValue,  city: newValue }))}
        label="City"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
      <Select
        label="State"
        value={propertyInformation.state}
        onChange={(newValue) => {
          setPropertyInformation(oldValue =>({...oldValue,  state: newValue }));
        }}
        options={listOfStates}
        inputId="property-information-state-input"
        className="full-width-input"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
      <ZipCodeField
        value={propertyInformation.zipCode}
        onChange={(newValue) => setPropertyInformation(oldValue =>({...oldValue,  zipCode: newValue }))}
        label="Zip Code"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
      <Select
        label="Own"
        value={propertyInformation.isOwner}
        onChange={(newValue) => {
          setPropertyInformation(oldValue =>({...oldValue,  isOwner: newValue }));
        }}
        options={[
          {label: "Yes", value: true, id: "OWN-YES"},
          {label: "No", value: false, id: "OWN-NO"}
        ]}
        inputId="property-information-owner-dropdown"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
      {(isFetching || isLoading) && (
        <LinearProgress className="property-information-section-loading-bar" />
      )}
    </div>
  );
};
