import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import { updateData } from 'redux/actions';
import { useAddressLocationsContext } from 'services/contexts/useAddressLocations';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useStyles } from "./useStyles";

function loadScript(src, position, id, callback) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function AddressAutocomplete(props) {
  const classes = useStyles();
  const { dealsData, autoFillAddress, formSubmitAction } = props;
  const { states } = useAddressLocationsContext();
  const [addressError, setAddressError] = useState(false);
  const [autoCompleteAttached, setAutoCompleteAttached] = useState(false);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
        () => {
          initAutocomplete();
        },
      );
    }

    loaded.current = true;
  }
  let autocomplete = '';
  let address1Field = '';
  let postalField = '';

  const initAutocomplete = () => {
    address1Field = document.querySelector('#ship-address');

    postalField = document.querySelector('#postcode');
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    autocomplete = new window.google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ['us'] },
      fields: ['address_components'],
      types: ['address'],
    });

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener('place_changed', fillInAddress);
  };

  function fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();
    let address1 = '';
    let postcode = '';
    let state = '';
    let city = '';
    place?.address_components?.map((component) => {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;

          break;
        }

        case 'route': {
          address1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postcode = `${component.long_name}${postcode}`;
          if (postcode) {
            if (autoFillAddress) {
              autoFillAddress('zipCode', postcode);
            }
            props.updateData({
              name: 'zip_code',
              value: postcode,
            });
          }
          break;
        }

        case 'postal_code_suffix': {
          postcode = `${postcode}-${component.long_name}`;

          break;
        }
        case 'locality': {
          city = component.long_name;

          break;
        }
        case 'administrative_area_level_1': {
          if (component.short_name) {
            state = component.short_name;
          }
          break;
        }

        default:
          break;
      }
      return '';
    });
    props.updateData({
      name: 'city',
      value: city,
    });
    props.updateData({
      name: 'state',
      value: state,
    });
    if (autoFillAddress) {
      autoFillAddress('city', city);
      autoFillAddress('state', state);
    }
    address1Field.value = address1;
    props.updateData({
      name: 'street_address',
      value: address1,
    });
  }
  const changeFieldValue = (fieldName, fieldValue) => {
    props.updateData({
      name: fieldName,
      value: fieldValue,
    });
  };

  return (
    <>
      <form id="address-form" action="" method="get" autoComplete="off">
        <TextField
          fullWidth
          error={
            addressError || (formSubmitAction && !dealsData.street_address)
          }
          helperText={
            addressError || (formSubmitAction && !dealsData.street_address)
              ? 'This Field is Required'
              : ''
          }
          style={{
            marginBottom: '12px !important',
          }}
          value={dealsData.street_address}
          className={classes.formControlInputAddress}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              e.preventDefault();
            }
          }}
          id="ship-address"
          onChange={(e) => {
            if (e.target.value.length > 3 && !autoCompleteAttached) {
              initAutocomplete();
              setAutoCompleteAttached(true);
            }
            props.updateData({
              name: 'street_address',
              value: e.target.value,
            });
            if (autoFillAddress) {
              autoFillAddress('streetAddress', e.target.value);
            }
            if (e.target.value) {
              setAddressError(false);
            }
          }}
          onBlur={() => {
            if (!dealsData.street_address) {
              setAddressError(true);
            }
          }}
          label="Street Address"
          name="ship-address"
          variant="outlined"
          autoComplete="off"
        />
      </form>
    </>
  );
}
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    appData: state.appReducer.appData,
  };
};
export default connect(mapStatesToProps, { updateData })(AddressAutocomplete);
