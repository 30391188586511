import React from 'react';
import Icon from 'components/Icon';
import {
  Box,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import {useUserContext} from 'services/hooks/useUser';
import {ActionsMenu} from './ActionsMenu';

import {
  getIconName,
  processFileType,
  processName,
  StyledDocStatus,
  useStyles
} from "pages/ApplicationDetail/DocumentsTab/utils";

export const DocumentCard = (
  document,
  handleUploadDocument,
  isAppCanceled,
  canEdit,
  adhocstyling,
) => {
  const classes = useStyles();
  const theme = useTheme();

  if (document) {
    const fileType = document.contentType
      ? processFileType(document.contentType)
      : processFileType(document.content_type);
    const status = document.status ? document.status : 'uploaded';
    const fileName = processName(document.name)

    const isDocUploaded = status.toLowerCase() !== 'needed';
    return (
      <div className={adhocstyling ? classes.cardwithoutBorder : classes.card}>
        <div className={classes.cardContent}>
          {/* ICON */}
          {isDocUploaded && (
            <Icon icon={getIconName(fileType)}/>
          )}

          {/* TEXT */}
          <div
            className={
              isDocUploaded
                ? classes.fileDescriptionUploaded
                : classes.fileDescription
            }
          >
            {/* TITLE */}
            {isDocUploaded && (
              <Box
                display="flex"
                alignItems="center"
                className={classes.documentTitle}
              >
                <Typography className={classes.uploadedDocument}>
                  {document.name || fileName} &nbsp;
                </Typography>
              </Box>
            )}

            {/* DESCRIPTION || UPLOAD BUTTON */}
            <>
              <Typography className={classes.documentType}>
                {`File Type: ${fileType}`}
              </Typography>
            </>
          </div>

          {/* OPTIONS MENU */}
          {isDocUploaded && (
            <ActionsMenu
              handleUploadDocument={(e) => handleUploadDocument(e, document)}
              isAppCanceled={isAppCanceled}
              classes={classes}
              urlTarget={document.url ? document.url : null}
              canEdit={false}
              isNotice
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};

const DocumentCardNotes = (
  document,
  handleUploadDocument,
  isAppCanceled,
  showClearOption,
  clearFile,
) => {
  const classes = useStyles();
  if (document) {
    const fileType = document.type;
    const status = 'uploaded';
    const fileName = document.name;
    const isDocUploaded = status.toLowerCase() !== 'needed';
    return (
      <div className={classes.card}>
        <div className={classes.cardContent}>
          {/* ICON */}
          {isDocUploaded && (
            <Icon icon={getIconName(fileType.split('/')[1].toUpperCase())}/>
          )}

          {/* TEXT */}
          <div className={classes.fileDescription}>
            {/* TITLE */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.documentTitle}
            >
              <Typography className={classes.uploadedDocument}>
                {fileName}
              </Typography>
              {/* <DocStatus status={status} /> */}
            </Box>

            {/* DESCRIPTION || UPLOAD BUTTON */}
          </div>

          {/* OPTIONS MENU */}
          {isDocUploaded && (
            <ActionsMenu
              handleUploadDocument={(e) => handleUploadDocument(e)}
              isAppCanceled={isAppCanceled}
              classes={classes}
              showClearOption
              clearFile={clearFile}
              urlTarget={document.url ? document.url : null}
              canEdit
            />
          )}
        </div>
      </div>
    );
  }
  return null;
};

export const NoticesFileList = ({
                           documents,
                           handleUploadDocument,
                           isAppCanceled,
                           canEdit,
                           newFlow,
                           notesDoc,
                           showClearOption,
                           clearFile,
                           adhocstyling,
                           showLoader,
                           stipulations,
                           showFileType,
                           loanProductCode
                         }) => {
  const classes = useStyles();
  const {user, userType} = useUserContext();
  const loanProductType = loanProductCode ? loanProductCode : '';
  if (documents && Array.isArray(documents)) {
    return documents.map((document, index) => (
      <>
        {loanProductType === 'healthcare' && document?.fileType === "install_contract" && userType === 'dealer' ?
          <>
            {document?.status !== 'verified' ?
              <div className={classes.cardDarken}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      data-testid="logItem"
                      className={classes.logContent}
                      style={{
                        marginBottom: '0px !important',
                        marginTop: "12px"
                      }}
                    >
                      <bold
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginBottom: '0px !important',
                          position: 'relative',
                          bottom: '14px',
                          right: '3px'
                        }}
                      >
                        Applicant Stipulations and Documents
                      </bold>
                    </Typography>
                    <Typography className={classes.title} style={{
                      fontSize: '14px', position: 'relative',
                      bottom: '24px', right: '3px'
                    }}>
                      There are documents and notices visible to the
                      applicant only. Please have them login into their
                      account to complete the process.
                    </Typography>
                  </Grid>
                </Grid>
              </div> : <></>}
          </>

          : (<>
            {notesDoc ? (
              <span key={index}>
                {DocumentCardNotes(
                  document,
                  handleUploadDocument,
                  isAppCanceled,
                  showClearOption,
                  clearFile,
                )}
              </span>
            ) : newFlow ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                {DocumentCard(
                  document,
                  handleUploadDocument,
                  isAppCanceled,
                  canEdit,
                  notesDoc,
                  showLoader,
                  showFileType,
                )}
              </Grid>
            ) : (
              <span key={index}>
                {DocumentCard(
                  document,
                  handleUploadDocument,
                  isAppCanceled,
                  canEdit,
                  adhocstyling,
                  showLoader,
                  showFileType,
                  stipulations,
                )}{' '}
              </span>
            )}
          </>)
        }
      </>
    ));
  }
  return null;
};
