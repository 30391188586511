import React, { useState } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';

import SettingsSectionAction from 'components/SettingsSection/SettingsSectionAction';
import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { useModalContext } from 'services/contexts/useModalContext';
import SimpleModal from 'components/Modal';
import ValidatePhone from 'pages/Dashboard/Dealer/CompleteAccountSetupForm/ValidatePhone';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';

const useStyles = makeStyles(theme => ({
  validatePhoneContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    borderRadius: '4px',
    width: '90%',
    maxWidth: '30rem',
    height: '35rem',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '-6px -8px 10px 0 rgba(116,125,140,0.1), 6px 8px 10px 0 rgba(116,125,140,0.1)',
    outline: 0,
  },
  inputField: {
    width: '100%',
  },
}));

const PhoneNumberForm = () => {
  const classes = useStyles();
  const { toggle } = useModalContext();

  return (
    <>
      <SettingsSectionFormGroup
        title="Phone Number"
        description="We'll send you a verification code in order to change your Phone Number"
      >
        <Grid item xs={12} md={4}>
          <PhoneNumberField
            name="phone"
            label="Personal Phone"
            id="phoneNumber-input"
          />
        </Grid>
        <SimpleModal>
          <Box className={classes.validatePhoneContainer}>
            <ValidatePhone isUpdatePhone toggle={toggle} />
          </Box>
        </SimpleModal>
      </SettingsSectionFormGroup>
      <SettingsSectionAction onClick={toggle}>SEND CODE </SettingsSectionAction>
    </>
  );
};

export default PhoneNumberForm;
