import React, { useState } from 'react';
import { makeStyles, setRef } from '@material-ui/core';
import { useAlertContext } from '../../../../../components/AlertContext';
import InitiateRefund from './InitiateRefund';
import RefundCard from './RefundCard';
import ActiveRefundOrder from './ActiveRefundOrder';
import AddNewRefundOrder from './AddNewRefundOrder';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },

  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
  },
  content: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  total: {
    borderRadius: 4,
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      maxWidth: 195,
    },
  },
  containedButton: {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 600,
    // width: 198,
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
}));

const RefundTab = ({
  setFetchedData,
  applicationNumber,
  changeOrderDetails,
  originalLoanDetails,
  setRefundTab,
  loanDetails,
  validHIRefund,
  isStageFundedHI,
  paymentsRefundAmount,
}) => {
  const classes = useStyles();
  const [createFlag, setcreateFlag] = useState(false);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const activeChangeOrders = changeOrderDetails.find(
    (element) => element.attributes.status === 'IN PROGRESS',
  );

  const anyPendingChangeOrder = changeOrderDetails.some(
    (element) => element.attributes.status === 'PENDING',
  );

  const anyConfirmedChangeOrder = changeOrderDetails.some(
    (element) => element.attributes.status === 'CONFIRMED',
  );

  const fundingStatus = loanDetails?.payments?.find(
    (element) =>
      element.status === 'requested' || element.status === 'released',
  );

  const noRefunds = loanDetails?.refunds === undefined ? true : false;

  const anyPartialRefund = loanDetails?.refunds?.some(
    (element) =>
      element.refundType === 'partial' && element.status !== 'rejected',
  );

  const anyPendingRefund = loanDetails?.refunds?.some(
    (element) =>
      element.status === 'pending',
  );

  const anyConfirmedFullRefund = loanDetails?.refunds?.some(
    (element) =>
      element.refundType === 'full' && element.status === 'confirmed',
  );

  const disableCreateRefundHI = anyPendingChangeOrder || anyConfirmedChangeOrder || activeChangeOrders
  const disableCreateRefund = fundingStatus || anyPendingRefund || anyConfirmedFullRefund

  return (
    <div className={classes.container}>
      {!createFlag && loanDetails?.refunds &&
        loanDetails?.refunds?.map((refundDetail) => {
          return (
            <RefundCard
              key={refundDetail.id}
              applicationNumber={applicationNumber}
              setFetchedData={setFetchedData}
              setAlertMessage={setAlertMessage}
              setErrorAlertMessage={setErrorAlertMessage}
              setRefundTab={setRefundTab}
              refundDetail={refundDetail}
            />
          );
        })}
        <InitiateRefund
          createFlag={createFlag}
          setcreateFlag={setcreateFlag}
          disableCreateRefund={disableCreateRefund}
          disableCreateRefundHI={disableCreateRefundHI}
          isStageFundedHI={isStageFundedHI}
          validHIRefund={validHIRefund}
          noRefunds={noRefunds}
        />

        <AddNewRefundOrder
          applicationNumber={applicationNumber}
          setFetchedData={setFetchedData}
          createFlag={createFlag}
          setcreateFlag={setcreateFlag}
          originalLoanDetails={originalLoanDetails}
          setAlertMessage={setAlertMessage}
          setErrorAlertMessage={setErrorAlertMessage}
          setRefundTab={setRefundTab}
          anyPartialRefund={anyPartialRefund}
          validHIRefund={validHIRefund}
          paymentsRefundAmount={paymentsRefundAmount}
        />
    </div>
  );
};

export default RefundTab;
