import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MaterialCarousel } from 'components/MaterialCarousel';
import Img from 'assets/images/app-marketing-banner-1.jpg';
import Img2 from 'assets/images/app-marketing-banner-2.jpg';
import Img3 from 'assets/images/app-marketing-banner-3.jpg';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { useUserContext } from 'services/hooks/useUser';

const useStyles = makeStyles((theme) => ({
  img: {
    height: 224,
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    borderRadius: 4,
  },
}));

const items = (classes) => {
  return [
    <img className={classes.img} src={Img} alt="" />,
    <img className={classes.img} src={Img2} alt="" />,
    <img className={classes.img} src={Img3} alt="" />,
  ];
};

export const AppMarketingCarousel = () => {
  const classes = useStyles();
  const { user, userType } = useUserContext();
  const { isOpen, onClose } = useDisclosure(true);
  if (userType !== 'homeowner') {
    return null;
  }
  return isOpen ? (
    <MaterialCarousel items={items(classes)} onClose={onClose} closable />
  ) : null;
};
