import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import http from 'services/http';
import SettingsSection from 'components/SettingsSection';
import { useFormikContext } from 'formik';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import SettingsSectionAction from 'components/SettingsSection/SettingsSectionAction';
import InputField from 'components/InputFields/InputField';
import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { SsnInput } from 'components/InputFields/SsnField';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: 320,
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.up('md')]: {
      margin: 15,
      width: 340,
    },
  },
  inputLabel: {
    marginLeft: 10,
  },
}));

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  driverlicenseNumber: null,
  businessOwner: '',
  phoneNumber: '',
  ssn: '',
  activeMilitary: '',
  usCitizen: '',
};

const PersonalInformationHomeOwner = ({ userData }) => {
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth + 80);
  }, []);
  const handleUpdateInformation = async values => {
  };

  return (
    <SettingsSection>
      <SettingsSectionForm
        initialValues={initialValues}
        onSubmit={handleUpdateInformation}
        validationSchema={null}
      >
        <SettingsSectionFormGroup
          title="Personal Information"
          description="If you want to update any field please contact PowerPay at 800-397-4485"
        >
          <TextField
            className={classes.inputField}
            variant="outlined"
            disabled
            label="First Name"
            type="text"
            name="firstName"
            value={(userData && userData.user.firstname) || ''}
          />
          <TextField
            className={classes.inputField}
            variant="outlined"
            disabled
            label="Last Name"
            type="text"
            name="lastName"
            value={(userData && userData.user.lastname) || ''}
          />
          <TextField
            className={classes.inputField}
            variant="outlined"
            disabled
            label="Email"
            type="email"
            name="email"
            value={(userData && userData.user.email) || ''}
          />
          <TextField
            className={classes.inputField}
            variant="outlined"
            disabled
            label="Personal Telephone"
            type="text"
            name="phoneNumber"
            value={(userData && userData.user.personalPhone) || ''}
          />
          <SsnInput
            className={classes.inputField}
            variant="outlined"
            disabled
            label="Social Security Number"
            type="text"
            name="ssn"
            value={(userData && userData.user.ssn) || ''}
          />
          <TextField
            className={classes.inputField}
            variant="outlined"
            disabled
            label="Driver License's Number"
            type="number"
            name="driverLicenseNumber"
            value={(userData && userData.user.driverLicenseNumber) || ''}
          />
          <FormControl className={classes.inputField}>
            <InputLabel ref={inputLabel} className={classes.inputLabel}>
              Are you an active Military?
            </InputLabel>
            <Select
              name="activeMilitary"
              variant="outlined"
              labelWidth={labelWidth}
              value={
                userData && userData.user.activeMilitary ? 'Yes' : 'No' || ''
              }
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.inputField}>
            <InputLabel ref={inputLabel} className={classes.inputLabel}>
              Are you US Citizen?
            </InputLabel>
            <Select
              name="usCitizen"
              variant="outlined"
              labelWidth={labelWidth}
              disabled
              value={userData && userData.user.usCitizen ? 'Yes' : 'No' || ''}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </SettingsSectionFormGroup>
        <SettingsSectionAction disabledOptions={{ activeMilitary: true }}>
          SAVE CHANGES{' '}
        </SettingsSectionAction>
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default PersonalInformationHomeOwner;
