import React, { useState, useEffect, useContext, useMemo } from 'react';
import to from 'await-to-js';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';

const DealerListContext = React.createContext();
export const { Consumer: DealerListConsumer, Provider } = DealerListContext;

export function DealerListProvider({ children }) {
  const [dealerNetworkOptions, setDealerNetworkOptions] = useState(null);
  const { user, dealerLevelRole } = useUserContext();

  const rolesForGetAllDealers = ['affiliate', 'organization', 'super_admin'];
  useEffect(() => {
    if (!user) {
      setDealerNetworkOptions(null);
    }
    (async () => {
      try {
        if (
          user &&
          dealerNetworkOptions === null &&
          rolesForGetAllDealers.includes(dealerLevelRole)
        ) {
          const [err, data] = await to(protectedGet('/v1/dealer_networks/active_dealers_for_options'));
          // const res = normalize(camelizeKeys(data?.data)).get([
          //   'id',
          //   'name',
          //   // 'dealerCode',
          // ]);
          let dropDownFormatted = data?.data?.map((dealerNetwork) => ({
            label: dealerNetwork?.name,
            value: dealerNetwork?.id,
            dealerCode: dealerNetwork?.dealer_code,
          }));
          dropDownFormatted = dropDownFormatted.sort((a, b) => a.label.localeCompare(b.label));
          setDealerNetworkOptions(dropDownFormatted);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user]);
  useEffect(() => {
    if (!user && dealerNetworkOptions) {
      setDealerNetworkOptions(null);
    }
  }, [dealerNetworkOptions]);
  return (
    <Provider
      value={{
        dealerNetworkOptions,
      }}
    >
      {children}
    </Provider>
  );
}

export const useDealerListContext = () => {
  const context = useContext(DealerListContext);
  return context;
};

export default DealerListContext;
