import { camelizeKeys } from 'humps';
import normalize from 'json-api-normalize';

export const parseDealStructure = (ds) => {
  const json = normalize(camelizeKeys(ds)).get([
    'id',
    'dealType',
    'stageFunded',
    'stipPay',
    'rebates',
    'stageFundedDiscount',
    'name',
    'terms',
    'ohNo',
    'equalPay',
    'buydown',
    'holdback',
    'holdbackPercentage',
    'activationFee',
    'availableTerms',
    'equalPayAvailableTerms',
    'ohNoAvailableTerms',
    'totalPaymentRatios'
  ]);

  return [json];
};
