import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Text from 'components/TextFields/Text';

import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import { SelectField } from 'components/InputFields/SelectField';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '1rem',
    minWidth: 256,
  },
}));

const DefaultPageForm = ({ onSubmit }) => {
  const classes = useStyles();

  return (
    <>
      <SettingsSectionFormGroup
        title="Default Page"
        description="Select the page you want to see first every time you login to PowerPay"
      >
        <Grid item xs={12} md={4}>
          <SelectField label="Default Page" name="defaultUrl">
            <MenuItem value="">Select...</MenuItem>
            {/* <MenuItem value="/prequalify">Prequalify</MenuItem> */}
            <MenuItem value="/dealer/dashboard">Dashboard</MenuItem>
            <MenuItem value="/applications/start">New Application</MenuItem>
            <MenuItem value="/applications">Applications</MenuItem>
            <MenuItem value="/projects">Loans</MenuItem>
          </SelectField>
        </Grid>
      </SettingsSectionFormGroup>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        type="submit"
      >
        <Text textSize="lg" textColor="white" bold>
          SAVE
        </Text>
      </Button>
    </>
  );
};

export default DefaultPageForm;
