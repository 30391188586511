import React, { useEffect, useState } from 'react';

import TextField from '@material-ui/core/TextField';
import {
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import to from 'await-to-js';
import { protectedPut, protectedDelete } from 'services/http';
import { format } from 'date-fns-tz';
import Switch from '@material-ui/core/Switch';
import { useUserContext } from 'services/hooks/useUser';
import { FileList } from '../DocumentsTab/FileList';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  swicthButton: {
    marginBottom: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  logContainer: {
    backgroundColor: '#F9FAFC',
    borderRadius: 6,
    padding: 9,
    marginBottom: 9,
  },
  logItem: {
    background: 'white',
    border: 'solid 0.5px #C1C9D1',
    borderRadius: 4,
    minHeight: 52,
    // margin: 9,
    marginTop: 0,
  },
  logContentWrapper: {
    marginLeft: 8,
    minWidth: 0,
    wordBreak: 'break-word',
    padding: '0.25rem 0.25rem 0.25rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logInformation: {
    color: 'rgba(0,0,0)',
    fontSize: 12,
    letterSpacing: 0.46,
    lineHeight: '12px',
    whiteSpace:'pre-line'
    // textTransform: 'uppercase',
  },
  logContent: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginBottom: 10,
  },
  logContentRead: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  styledIcon: {
    marginRight: 7,
    fontSize: '1.125rem',
    cursor: 'pointer',
  },
  innerContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    width: '100%',
  },
  switchBase123: {
    color: 'blue',
  },
  styledButton: {
    color: 'white',
    fontSize: '0.875 rem',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const NoteCard = ({
  data,
  applicationNumber,
  setFetchedData,
  fetchedData,
  setAlertMessage,
  setErrorAlertMessage,
}) => {
  const classes = useStyles();
  const { user } = useUserContext();

  const [editFlag, setEditFlag] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [editedValue, setEditedValue] = useState('');
  const [msgRead, setMsgRead] = useState(false);
  useEffect(() => {
    if (data?.attributes?.readBy !== null) {
      setMsgRead(true);
    }
  }, [data?.attributes?.readBy]);
  const editClick = (id, data) => {
    setEditFlag(true);
    setSelectedId(id);
    setEditedValue(data);
  };
  const handleCancel = () => {
    setSelectedId('');
  };
  const handleUpdate = async (noteId) => {
    const payload = {
      application_note: { note: editedValue },
    };
    const [err] = await to(
      protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes/${noteId}`,
        payload,
      ),
    );
    if (err) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return;
    }
    setAlertMessage('Notes updated succesfully');
    setFetchedData(!fetchedData);
    setEditFlag(false);
  };
  const handleDelete = async (noteId) => {
    const [err] = await to(
      protectedDelete(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes/${noteId}`,
      ),
    );
    if (err) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return;
    }
    setAlertMessage('Notes deleted succesfully');
    setFetchedData(!fetchedData);
  };
  const updateReadStatus = async (event) => {
    if (!event.target.checked) {
      return '';
    }
    const [err] = await to(
      protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes/${data.id}/mark_note_read `,
        {
          application_note: {
            read: event.target.checked,
            read_by: user.data.id,
          },
        },
      ),
    );
    if (err) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return;
    }
    setFetchedData(!fetchedData);
    setAlertMessage('Message marked read successfully');
    setMsgRead(event.target.checked);
  };

  const formatDate = (dateString) => {
    return format(new Date(dateString), 'MM/dd/yyyy HH:mm', { timeZone: 'America/New_York' });  };

  return (
    <div
      className={classes.logContainer}
      data-testid="notescard-container"
      style={{ marginBottom: '0px' }}
    >
      {editFlag && selectedId === data?.attributes?.id ? (
        <>
          <TextField
            id="outlined-full-width"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              style: {
                background: 'white',
                fontSize: '0.75rem',
                letterSpacing: 0.55,
                lineHeight: '0.75rem',
              },
            }}
            variant="outlined"
            defaultValue={data?.attributes?.note}
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            multiline
            rows={3}
          />
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              className={classes.styledButton}
              onClick={() => handleUpdate(data?.attributes?.id)}
            >
              UPDATE
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.styledButton}
              onClick={() => handleCancel(data?.attributes?.id)}
            >
              CANCEL
            </Button>
          </div>
        </>
      ) : (
        <>
          <div
            className={classes.logItem}
            key={data?.id}
            data-testid="viewcard-container"
          >
            <div className={classes.logContentWrapper}>
              <div className={classes.innerContentWrapper}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography
                      data-testid="logItem"
                      className={classes.logContent}
                      style={{
                        marginBottom: '0px !important',
                      }}
                    >
                      <bold
                        style={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          marginBottom: '0px !important',
                        }}
                      >
                        {data?.attributes?.createdByEmail.includes(
                          '@getpowerpay.com',
                        )
                          ? 'PowerPay'
                          : data?.attributes?.createdBy}
                      </bold>
                      &nbsp;
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {data?.attributes?.adminNote && (
                      <div
                        style={{
                          float: 'right',
                        }}
                      >
                        <FormControlLabel
                          classes={{
                            label: classes.logContentRead,
                          }}
                          control={
                            <Switch
                              size="small"
                              value="checkedC"
                              checked={data?.attributes?.readBy}
                              disabled={data?.attributes?.readBy}
                              color="secondary"
                              style={data?.attributes?.readBy ? {} : { color: 'blue' }}
                              onChange={updateReadStatus}
                            />
                          }
                          label="Read"
                          labelPlacement="start"
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>

                <Typography
                  data-testid="logItem"
                  className={classes.logContent}
                >
                  <span style={{color: 'gray'}}>
                    {`Created at: ${formatDate(data?.attributes?.createdAt)}
                    ${data?.attributes?.readAt ? `Read at: ${formatDate(data?.attributes?.readAt)}` : ''}`}
                  </span>
                </Typography>
                <Grid container>
                  <Grid item>
                    <Typography className={classes.logInformation}>
                      <p>{data?.attributes?.note.replace(
                        data?.attributes?.note.charAt('0'),
                        data?.attributes?.note.charAt('0').toUpperCase(),
                      )}</p>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            {data.attributes.file.url && (
              <Grid conatiner>
                <Grid item>
                  <Divider className={classes.divider} />
                  <FileList
                    documents={[data.attributes.file]}
                    handleUploadDocument={() => {}}
                    adhocstyling
                  />
                </Grid>
              </Grid>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default NoteCard;
