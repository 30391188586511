import React, { useState } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from 'components/TextFields/Text';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },

  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
  },
  content: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  total: {
    borderRadius: 4,
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      maxWidth: 195,
    },
  },
  containedButton: {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 600,
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
}));

const ActiveRefundOrder  = ({
  activeChangeOrders,
  setcreateFlag,
  createFlag,
  changeOrderDetails,
  fundingStatus,
  allPaymentsDone
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const [allPaymentsDone,setAllPaymentDone] = useState(sessionStorage.getItem("allPaymentsDone"));
  // const allPaymentsDone = sessionStorage.getItem("allPaymentsDone");
  return (
    changeOrderDetails.length !== 0 &&
    !createFlag && (
      <Box
        data-testid="activeChangeOrder-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        height="100%"
        width="100%"
        paddingBottom="5%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="24rem"
        >
     {!allPaymentsDone? <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={() => setcreateFlag(true)}
            disabled={(activeChangeOrders || fundingStatus) ? true : false}
          >
            {activeChangeOrders ? "Change Order In Progress" : fundingStatus ? "Funding Request In progress": "CREATE CHANGE ORDER"}
          </Button>:""}
        </Box>
      </Box>
    )
  );
};

export default ActiveRefundOrder;
