import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';

import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { SelectField } from 'components/InputFields/SelectField';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: '100%',
  },
  businessInformationContainer: {
    marginBottom: '2.75rem',
  },
}));

const BusinessAddress = ({ userData }) => {
  const classes = useStyles();

  return (
    <SettingsSectionFormGroup title="Business Address" spacing={3}>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="Street Address"
          type="text"
          name="businessAddress.streetAddress"
          value={(userData && userData.address.streetAddress) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="Adress Line 2"
          type="text"
          name="businessAddress.addressLineTwo"
          value={(userData && userData.address.addressLine2) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="Zip Code"
          type="text"
          name="businessAddress.zipCode"
          value={(userData && userData.address.zipCode) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          select
          className={classes.inputField}
          variant="outlined"
          disabled
          label="State"
          name="businessAddress.state"
          type="text"
          value={(userData && userData.address.state) || ''}
        >
          <MenuItem value={(userData && userData.address.state) || ''}>
            {userData && userData.address.state}
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="City"
          type="text"
          name="businessAddress.city"
          value={(userData && userData.address.city) || ''}
        />
      </Grid>
    </SettingsSectionFormGroup>
  );
};

export default BusinessAddress;
