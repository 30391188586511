import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import ToggleButtons from '../Buttons/ToggleButtons';
import Text from '../TextFields/Text';

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16,
  },
  inputField: {
    marginTop: 32,
  },
  dealerDataWrapper: {
    marginTop: 16,
  },
  paper: {
    borderRadius: 10,
    borderWidth: 1,
    marginTop: 12,
    padding: '16px 14px',
    width: '100%',
  },
  helperText: {
    fontSize: 10,
  },
}));

const CheckDealer = ({
  setDealerCode,
  dealerCode,
  dealerData,
  addressData,
  businessData,
  hasDealer,
  setHasDealer,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <ToggleButtons
        values={['yes', 'no']}
        setFieldValue={setHasDealer}
        hasValue={hasDealer}
      />
      {hasDealer === 'yes' ? (
        <TextField
          variant="outlined"
          type="text"
          label="Dealer ID / Name"
          helperText="Your Dealer ID is a 4 or 5-digit number and can be obtained by contacting your dealer or calling us at (800) 397-4485"
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          onChange={e => setDealerCode(e.target.value)}
          value={dealerCode}
          className={classes.inputField}
        />
      ) : null}
      {hasDealer === 'yes' && dealerData && (
        <div className={classes.dealerDataWrapper}>
          <Text
            textVariant="body1"
            textColor="#393F47"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            text="YOUR DEALER"
          />
          <Paper elevation={0} className={classes.paper} variant="outlined">
            <Text
              textVariant="body1"
              textColor="#636A7280"
              textFontWeight={600}
              textLetterSpacing={0.6}
              textLineHeight="16px"
              textFontSize={12}
              text={`${businessData.attributes.name}`}
            />
            <Text
              textVariant="body1"
              textColor="#1E272E"
              textFontWeight={600}
              textLetterSpacing={0.8}
              textLineHeight="26px"
              textFontSize={20}
              text={`${dealerData.attributes.name} ${dealerData.attributes.last_name}`}
            />
            <Text
              textVariant="body1"
              textColor="#636A72"
              textFontWeight={500}
              textLetterSpacing={0.65}
              textLineHeight="18px"
              textFontSize={14}
              text={`${addressData.attributes.city}, ${addressData.attributes.state}`}
            />
            <Divider style={{ margin: '8px 0' }} />
            <Text
              textVariant="body1"
              textColor="#636A7280"
              textFontWeight={500}
              textLetterSpacing={0.55}
              textLineHeight="12px"
              textFontSize={12}
              text="Contact information"
            />
            <Text
              textVariant="body1"
              textColor="#393F47"
              textFontWeight={500}
              textLetterSpacing={0.8}
              textLineHeight="18px"
              textFontSize={14}
              text={`${dealerData.attributes.phone_number}`}
            />
            <Text
              textVariant="body1"
              textColor="#393F47"
              textFontWeight={500}
              textLetterSpacing={0.8}
              textLineHeight="18px"
              textFontSize={14}
              text={`${dealerData.attributes.email}`}
            />
          </Paper>
        </div>
      )}
    </div>
    /* <div>
        <Typography className={classes.textLabel}>
          Do you have a dealer?
        </Typography>
        <div className={classes.toggleWrapper}>
          <Button
            variant={values.hasDealer ? 'contained' : 'outlined'}
            color="primary"
            style={{ color: values.hasDealer ? 'white' : null }}
            onClick={() => setValue('hasDealer', true)}
            className={classes.toggleButton}
          >
            Yes
          </Button>
          <Button
            variant={!values.hasDealer ? 'contained' : 'outlined'}
            color="primary"
            style={{ color: !values.hasDealer ? 'white' : null }}
            onClick={() => setValue('hasDealer', false)}
            className={classes.toggleButton}
          >
            No
          </Button>
        </div>
        {values.hasDealer && (
          <TextField
            className={classes.textField}
            name="dealerCode"
            type="text"
            label="Dealer Code"
            variant="outlined"
            helperText="Your Dealer ID is a 4 or 5-digit number and can be obtained by contacting your dealer or calling us at (800) 397-4485"
            FormHelperTextProps={{
              classes: {
                root: classes.defaultHelper,
                error: classes.defaultHelperText,
              },
            }}
            inputProps={{
              onChange: event => {
                setDealerCode(event.target.value);
                setValue('dealerCode', dCode);
              },
            }}
          />
        )}
      </div>
      {dealerInfo && values.hasDealer ? (
        <div>
          <Typography className={classes.cardIntroduction}>
            YOUR DEALER
          </Typography>
          <Paper elevation={0} className={classes.card}>
            <div className={classes.cardHeader}>
              <div className={classes.cardHeaderLeft}>
                <Typography className={classes.cardTitle}>
                  {dealerInfo.name}
                </Typography>
                <Typography className={classes.cardSubtitle}>
                  COMPANY
                </Typography>
                <Typography className={classes.cardSubtitleVariant}>
                  LOCATION
                </Typography>
              </div>
              <div className={classes.cardHeaderRight}>
                <Avatar className={classes.avatar}>
                  {dealerInfo.name.charAt(0).toUpperCase() +
                    dealerInfo.last_name.charAt(0).toUpperCase()}
                </Avatar>
              </div>
            </div>

            <Divider />
            <Typography className={classes.cardBody}>
              Contact Information
            </Typography>
            <Typography className={classes.cardText}>NUMBER</Typography>
            <Typography className={classes.cardText}>
              {dealerInfo.email}
            </Typography>
          </Paper>
        </div>
      ) : null}
    </div> */
  );
};

export default CheckDealer;
