import React from 'react';
import IMask from 'imask';

import { MaskedField, MaskedInput } from './SsnField';

export const einPipe = IMask.createPipe({
  mask: '00-0000000',
});

export const hideEin = ein => {
  const regex = /^(\d{1,2})-?(\d{1,7})?$/gi;
  return (
    ein.replace(regex, (match, p1, p2, offset, string) => {
      let hiddenValue = '';
      if (p1) {
        hiddenValue += p1.replace(/\d/g, 'X');
      }
      if (p2) {
        hiddenValue += `-${p2.substr(0, 3).replace(/\d/g, 'X')}${p2.substr(
          3,
          4,
        )}`;
      }
      return hiddenValue;
    }) || ''
  );
};

export const EinField = props => {
  return <MaskedField {...props} pipeFn={einPipe} hideFn={hideEin} />;
};

export const EinInput = props => {
  return <MaskedInput {...props} pipeFn={einPipe} hideFn={hideEin} />;
};
