import React, { useEffect, useState } from 'react';
import { makeStyles, Typography, Button, useTheme } from '@material-ui/core';
import { format } from 'date-fns';
import HelloSign from 'hellosign-embedded';
import to from 'await-to-js';
import CheckIcon from '@material-ui/icons/Check';
import { SelectField } from 'components/InputFields/SelectField';
import { map } from 'ramda';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Info from '@material-ui/icons/Info';
import { protectedGet, protectedPost } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { Launch } from '@material-ui/icons';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useAlertContext } from '../../../../../components/AlertContext';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    // border: 'solid 0.5px rgba(193,201,209,0.4)',
    marginBottom: 24,
  },
  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  icon: {
    marginLeft: 11,
  },
  cardButton: {
    borderColor: '#F79D3C',
    borderWidth: '1.5px',
    marginLeft: '10%',
    marginTop: '3.5%',
    width: '80%',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
  },
  content: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  outlinedButton: {
    display: 'block',
    width: 198,
  },
  outlinedButtonsignature: {
    display: 'block',
    width: 198,
    marginTop: '12px',
  },
  containedButton: {
    color: 'white',
    display: 'block',
    width: 198,
  },
  importantInfo: {
    backgroundColor: '#F9FAFC',
    borderRadius: 4,
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0.5,
    marginTop: 14,
    marginBottom: 14,
    padding: 0,
    display: 'block',
    width: '100%',
  },
}));

const PaymentCard = ({
  appData,
  applicationNumber,
  loanId,
  payment,
  loanDetails,
  index,
  payments,
  setFetchedData,
  setShowPayments,
  setShowTotalProjectAmount,
  changeOrderDetails,
  applicationDetail,
  disableRequestAndFinish,
}) => {
  const { user } = useUserContext();
  const [showFinishProjectDialog, setShowFinishProjectDialog] = useState(false);
  const client = new HelloSign({
    clientId: process.env.REACT_APP_HELLO_SIGN_API_KEY,
  });

  client.on('message', (data) => {
    if (data.type === HelloSign.messages.USER_SIGN_REQUEST) {
      sessionStorage.setItem('signedPac', true);
      setFetchedData(false);
    }
  });

  const theme = useTheme();
  const classes = useStyles();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const [showPAC, setShowPAC] = useState(false);
  const [pacType, setPacType] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [isPacSendButtonDisabled, setisPacSendButtonDisabled] = useState(false);
  const [showFinal,setShowFinal] = useState(false);

  const handleStage = () => {
    switch (index) {
      case 0:
        return index !== payments.length - 1 ? 'FIRST STAGE' : 'FINAL STAGE';
      case 1:
        return index !== payments.length - 1 ? 'SECOND STAGE' : 'FINAL STAGE';
      case 2:
        return index !== payments.length - 1 ? 'THIRD STAGE' : 'FINAL STAGE';
      case 3:
        return index !== payments.length - 1 ? 'FOURTH STAGE' : 'FINAL STAGE';
      case 4:
        return index !== payments.length - 1 ? 'FIFTH STAGE' : 'FINAL STAGE';
      default:
        return `FINAL STAGE`;
    }
  };
  const onDownloadPAC = async () => {
    const [err, res] = await to(
      protectedGet(
        `/v1/applications/${applicationNumber}/loans/${loanId}/pac_sign_download`,
      ),
    );
    return err || window.open(res.data.download_url);
  };
  const handleCancelPAC = async () => {
    const [err, res] = await to(
      protectedPost(
        `/v1/applications/${applicationNumber}/loans/${loanId}/cancel_pac`
      ),
    );
    if (!err) {
      window.location.reload()
    } else {
      setErrorAlertMessage("Error while cancelinc PAC, try again later")
      console.log(err)
    }
  }
  const handleButtonDisplay = (hasPermission) => {
    const activeChangeOrders = changeOrderDetails?.find(
      (element) => element.attributes.status === 'IN PROGRESS',
    );
    let allPaymentsDone = false;
    payments.map((payment) => {
      allPaymentsDone = payment.status === 'paid' ? true : false;
      return '';
    });
    sessionStorage.setItem('allPaymentsDone', allPaymentsDone);
    const prevPaymentStatus = index > 0 ? payments[index - 1].status : 'paid';
    if (
      (prevPaymentStatus === 'paid' &&
        payment.status !== 'paid' &&
        (index < payments.length - 1 || payment.status === 'requested')) ||
      ((prevPaymentStatus === 'requested' || index === 0) &&
        payment.status === 'requested') ||
      payment.status === 'released'
    ) {
      return (
        <>
          {loanDetails.status !== 'completed' && loanDetails.status !== 'finished' ? (
            <Button
              color="primary"
              variant="outlined"
              className={classes.outlinedButton}
              onClick={
                payment.status === 'requested'
                  ? handleCancelPaymentRequest
                  : handlePaymentRequest
              }
              disabled={
                disableRequestAndFinish ||
                !hasPermission ||
                payment.status === 'released' ||
                activeChangeOrders ||
                index === payments.length - 1
              }
            >
              {index === payments.length - 1
                ? getButtonText(payment.status, true)
                : getButtonText(payment.status, false)}
            </Button>
          ) : (
             loanDetails.status === 'completed' &&
             index === payments.length - 1 && (
              <Grid>
                <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.containedButton}
                      style={{marginBottom: "1rem"}}
                      onClick={() => handleCancelPAC()}
                    >
                      CANCEL PAC
                    </Button>
                  </Grid>
                {loanDetails.pacSignatureMethod === 'physical' && (
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.containedButton}
                      onClick={onDownloadPAC}
                    >
                      DOWNLOAD PAC
                    </Button>
                  </Grid>
                )}
                {loanDetails.pacSignatureMethod === 'platform' && (
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.containedButton}
                      onClick={() => onSignEnvelope(false)}
                    >
                      SIGN PAC
                      <Launch style={{ fontSize: 15 }} />
                    </Button>
                  </Grid>
                  // <Button
                  //   color="primary"
                  //   variant="outlined"
                  //   className={classes.cardButton}
                  //   onClick={() => onSignEnvelope(false)}
                  // >
                  //   <span
                  //     style={{ marginRight: 8, fontSize: 12, fontWeight: 600 }}
                  //   >
                  //     SIGN PAC
                  //   </span>
                  //   <Launch style={{ fontSize: 15 }} />
                  // </Button>
                )}
                <Grid item>
                  <Button
                    color="primary"
                    variant={
                      loanDetails.pacSignatureMethod === 'email'
                        ? 'contained'
                        : 'outlined'
                    }
                    className={
                      loanDetails.pacSignatureMethod === 'email'
                        ? classes.containedButton
                        : classes.outlinedButtonsignature
                    }
                    onClick={handleFinalPayment}
                  >
                    RESEND PAC
                  </Button>
                </Grid>
              </Grid>
            )
          )}
        </>
      );
    }

    if (payment.status !== 'paid' && payment.status !== 'requested') {
      if (payments.length === 2) {
        return (
          <div>
            <Button
              color="primary"
              variant="contained"
              className={classes.containedButton}
              onClick={() => {
                setShowFinishProjectDialog(true);
              }
              }
              disabled={disableRequestAndFinish || !hasPermission || activeChangeOrders}
            >
                FINISH
            </Button>
            <div>
              <ConfirmationDialog
                desc="Are you sure you want to finish the project? Changes to the loan amount will not be available once the project is finished."
                dialogState={showFinishProjectDialog}
                handleCancel={() => handleFinalPayment()}
                handleClose={() => { setShowFinishProjectDialog(false) }}
              />
            </div>
          </div>


        );
      }
      const before2PaymentStatus =
        index > 1 ? payments[index - 2].status : 'paid';
      if (index === payments.length - 1 && before2PaymentStatus === 'paid') {
        return (
          <div>
            <div>
              <Button
                color="primary"
                variant="contained"
                className={classes.containedButton}
                onClick={() => {
                  setShowFinishProjectDialog(true);
                }
                }
                disabled={disableRequestAndFinish || !hasPermission || activeChangeOrders}
              >
                FINISH
              </Button>
            </div>
            <div>
              <ConfirmationDialog
                desc="Are you sure you want to finish the project? Changes to the loan amount will not be available once the project is finished."
                dialogState={showFinishProjectDialog}
                handleCancel={() => handleFinalPayment()}
                handleClose={() => { setShowFinishProjectDialog(false) }}
              />
            </div>
          </div>
        );
      }
    }
  };
  const getButtonText = (status, lastPayment) => {
    let buttonText = 'REQUEST FUNDING';
    if (lastPayment && status === 'requested') {
      buttonText = 'FUNDING REQUESTED';
      return buttonText;
    }
    if (status === 'requested') {
      buttonText = 'CANCEL REQUEST';
    } else if (status === 'released') {
      buttonText = 'FUNDING RELEASED';
    }
    return buttonText;
  };

  const handlePaymentRequest = async () => {
    try {
      await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/loans/${loanId}/payments/${payment.id}/request_payment`,
      );
      setFetchedData(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleCancelPaymentRequest = async () => {
    try {
      await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/loans/${loanId}/payments/${payment.id}/cancel_fund_request`,
      );
      setFetchedData(false);
    } catch (e) {
      console.error(e);
    }
  };

  const sendPacToHomeOwner = async () => {
    setisPacSendButtonDisabled(true);

    const [err, res] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${loanId}/complete?pac_signature_method=${pacType}`,
      ),
    );

    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }

    setFetchedData(false);
    setAlertMessage(confirmMsg);
  };

  const sendToComplete = async () => {
    const [err, res] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${loanId}/complete`,
      ),
    );
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }
    setFetchedData(false);
    // setAlertMessage(confirmMsg);
  };

  const handleFinalPayment = async () => {

    // event.preventDefault();
    if(applicationDetail?.applicationData?.programRequiresPac )
    {
    setShowPAC(true);
    setShowTotalProjectAmount(false);
    setPacType('');
    }
    if(!applicationDetail?.applicationData?.programRequiresPac)
    {
      sendToComplete();
    }
  };

  const cancelSendPac = () => {
    setShowPAC(false);
    setShowTotalProjectAmount(true);
    setPacType('');
    setShowFinishProjectDialog(false);
  };

  const PACTYPES = appData.applicationData.loanPacSignatureMethods;
  const getPACType = () => {
    let pacMethodToShow = '';
    if (pacType) {
      pacMethodToShow =
        PACTYPES[pacType].name === 'physical'
          ? 'Wet Sign'
          : PACTYPES[pacType].name === 'platform'
            ? 'On-screen'
            : PACTYPES[pacType].label;
    }
    return pacMethodToShow;
  };
  const onSignEnvelope = async (isCoapp = false) => {
    const [err, res] = await to(
      protectedGet(
        `/v1/applications/${applicationNumber}/loans/${loanId}/pac_sign_url`,
      ),
    );
    if (err) {
      return;
    }
    client.open(res.data.sign_url, { skipDomainVerification: true });
  };
  const setPACDeliveryMethod = (value) => {
    if (value === 'email') {
      setConfirmMsg('PAC Sent to Homeowner');
    } else if (value === 'physical') {
      setConfirmMsg(
        'A document has been created. Please upload document to complete the PAC request',
      );
    } else {
      setConfirmMsg(
        'A document has been created. Please Sign the document to complete the PAC request',
      );
    }
    setPacType(value);
  };

  const showPACConfirmation = () => {
    return (
      <>
        <Grid container>
          <p>Please select how you would like to send the PAC.</p>
          <SelectField
            label="Signature Method"
            name="signatureMethod"
            type="text"
            disabled={false}
            onChange={setPACDeliveryMethod}
            value={getPACType}
          >
            {map(
              (pac) => (
                <MenuItem key={pac.name} value={pac.name}>
                  {pac.name === 'physical'
                    ? 'Wet Sign'
                    : pac.name === 'platform'
                      ? 'On-screen'
                      : pac.label}
                </MenuItem>
              ),
              PACTYPES,
            )}
          </SelectField>

          <Typography className={classes.importantInfo}>
            <Info style={{ marginRight: 7, color: '#9198A0', fontSize: 13 }} />A
            PAC must be signed by the applicant(s) to complete the loan.
          </Typography>
        </Grid>
        <Grid
          container
          justifycontent="space-between"
          direction="row"
          alignItems="flex-end"
          style={{ paddingLeft: '14%' }}
        >
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              className={classes.containedButton}
              onClick={cancelSendPac}
            >
              CANCEL
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              className={classes.containedButton}
              disabled={pacType === '' || isPacSendButtonDisabled}
              onClick={sendPacToHomeOwner}
            >
              SEND
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };
  useEffect(() => {
    setShowPayments(!showPAC);
  }, [showPAC]);
  return (
    <div className={classes.card}>
      {showPAC ? (
        <>{showPACConfirmation()}</>
      ) :   (
        <>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>
              {handleStage()}
              {payment.status === 'paid' && (
                <span className={classes.icon}>
                  <CheckIcon style={{ color: theme.palette.success.main }} />
                </span>
              )}
            </Typography>
          </div>
          <div className={classes.contentWrapper}>
            <Typography className={classes.content}>
              <span className={classes.contentLabel}>Fund Date Request</span>
              {payment.requestedAt
                ? format(new Date(payment.requestedAt), 'MM/dd/yyyy')
                : '--'}
            </Typography>
            {payment.status === 'paid' && (
              <Typography className={classes.content}>
                <span className={classes.contentLabel}>Payment Date</span>
                {payment.paidAt
                  ? format(new Date(payment.paidAt), 'MM/dd/yyyy')
                  : '--'}
              </Typography>
            )}

            <Typography className={classes.content}>
              <span className={classes.contentLabel}>Payment</span>
              {payment.amount}
            </Typography>
            {handleButtonDisplay(
              user
                ? user.data.type !== 'homeowner' &&
                user.data.meta.canManageResources
                : true,
            )}
          </div>
        </>
      ) }
        
      
    </div>
  );
};

export default PaymentCard;
