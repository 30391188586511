import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Folder from '@material-ui/icons/FolderOutlined';
import File from '@material-ui/icons/InsertDriveFileOutlined';
import { format } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  logItem: {
    background: 'white',
    border: `solid 1px ${theme.palette.text.darkGray}`,
    borderRadius: 4,
    display: 'flex',
    minHeight: 52,
    margin: '0.5rem',
    marginTop: 0,
  },
  logIcon: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    width: 50,
    flex: '0 0 50px',
  },
  logContentWrapper: {
    marginLeft: '0.5rem',
    minWidth: 0,
    wordBreak: 'break-word',
    padding: '0.25rem 0.25rem 0.25rem 0',
  },
  logInformation: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: '0.625rem',
    letterSpacing: 0.46,
    lineHeight: '0.75rem',
  },
  logContent: {
    color: theme.palette.text.primary,
    fontSize: '0.75rem',
    letterSpacing: 0.55,
    lineHeight: '0.75rem',
  },
  timelineItemFolderIcon: {
    fontSize: '1.25rem',
    color: '#70A1FF',
  },
  timelineItemFileIcon: {
    fontSize: '1.25rem',
    color: '#F79D3C',
  },
}));

const TimelineLogCard = ({ logData }) => {
  const classes = useStyles();
  return (
    <>
      {logData &&
        logData.map((data, index) => (
          <div className={classes.logItem} key={data?.id}>
            <div
              className={classes.logIcon}
              style={{
                backgroundColor:
                  data?.actionType === 'documentation_change'
                    ? '#FFCE6C80'
                    : '#A6D2FF80',
              }}
            >
              {data?.actionType === 'documentation_change' ? (
                <File className={classes.timelineItemFileIcon} />
              ) : (
                <Folder className={classes.timelineItemFolderIcon} />
              )}
            </div>
            <div className={classes.logContentWrapper}>
              <Typography className={classes.logInformation}>
                STATUS |{' '}
                {format(new Date(data?.createdAt), 'MM/dd/yyyy hh:mm aa', {
                  timeZone: 'America/New_York',
                })}
              </Typography>
              <Typography
                data-testid={`logItem${index}`}
                className={classes.logContent}
              >
                {data?.description}
              </Typography>
            </div>
          </div>
        ))}
    </>
  );
};
export default TimelineLogCard;
