import React from 'react';

export default function index({ width, height }) {
  return (
    <svg
      width={width || '20px'}
      height={height || '20px'}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <defs>
        <path
          d="M18.333 11.667c.35 0 .65.215.773.52l.004.011c.038.098.057.2.057.304V17.5a.833.833 0 0 1-1.667 0v-3.074l-2.444 2.296a8.333 8.333 0 0 1-13.75-3.11.833.833 0 1 1 1.571-.557 6.667 6.667 0 0 0 11.019 2.471l2.334-2.193h-2.897a.833.833 0 1 1 0-1.666h5zm-6.49-10.628a8.333 8.333 0 0 1 6.018 5.348.833.833 0 1 1-1.572.556 6.667 6.667 0 0 0-11.018-2.47L2.937 6.664h2.896a.833.833 0 1 1 0 1.667h-5A.834.834 0 0 1 .06 7.81L.056 7.8A.834.834 0 0 1 0 7.496V2.498a.833.833 0 0 1 1.667 0v3.074L4.11 3.276a8.333 8.333 0 0 1 7.733-2.237z"
          id="a"
        />
      </defs>
      <use
        fillOpacity=".4"
        fill="#1E272E"
        transform="matrix(-1 0 0 1 19.167 0)"
        xlinkHref="#a"
        fillRule="evenodd"
        opacity=".46"
      />
    </svg>
  );
}
