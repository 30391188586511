import React from 'react';

import { makeStyles, Paper, Grid } from '@material-ui/core';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';

const useStyles = makeStyles(theme => ({
  cards: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  card: {
    height: 207,
    marginBottom: 25,
    marginRight: 8,
    paddingTop: 16,
    paddingLeft: 24,
    position: 'relative',
    width: 359,
  },
  cardTitle: {
    maxWidth: 207,
  },
  cardTitleVariant: {
    maxWidth: 228,
  },
  cardText: {
    marginTop: 9,
    maxWidth: 216,
  },
  cardTextVariant: {
    marginTop: 9,
    maxWidth: 185,
  },
  link: {
    marginTop: 10,
  },
  icon: {
    display: 'none',
    position: 'absolute',
    bottom: -20,
    right: -25,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconVariant: {
    display: 'none',
    position: 'absolute',
    bottom: -25,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -12,
    right: -12,
    [theme.breakpoints.up('md')]: {
      bottom: -15,
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  iconVariantMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -10,
    right: 0,
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const InfoCards = () => {
  const classes = useStyles();
  return (
    <Grid className={classes.cards}>
      {/* <Paper className={classes.card}>
        <Text
          textColor="#4366F7"
          textFontSize={20}
          textFontWeight={600}
          textLetterSpacing={0.6}
          textLineHeight="28px"
          text="Get more clients with our
            Marketing tools"
        />
        <div className={classes.cardTextVariant}>
          <Text
            textColor="#9198A0"
            textFontSize={14}
            textLetterSpacing={0.65}
            textLineHeight="18px"
            text="From links to badges and Full custome websites"
          />
        </div>
        <div className={classes.link}>
          <Text
            textColor="#9198A0"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            SEE MORE
            <KeyboardArrowRight />
          </Text>
        </div>
        <div className={classes.icon}>
          <Icon icon="portatil" width="185px" height="127px" />
        </div>
        <div className={classes.iconMobile}>
          <Icon icon="portatil" width="185px" height="127px" />
        </div>
      </Paper>
      <Paper className={classes.card}>
        <div className={classes.cardTitle}>
          <Text
            textColor="#4366F7"
            textFontSize={20}
            textFontWeight={600}
            textLetterSpacing={0.6}
            textLineHeight="28px"
            text="Know more about our Legal Terms"
          />
        </div>
        <div className={classes.cardText}>
          <Text
            textColor="#9198A0"
            textFontSize={14}
            textLetterSpacing={0.65}
            textLineHeight="18px"
            text="Discover all the current documents we use in our transactions"
          />
        </div>
        <div className={classes.link}>
          <Text
            textColor="#9198A0"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            SEE MORE
            <KeyboardArrowRight />
          </Text>
        </div>
        <div className={classes.iconVariant}>
          <Icon icon="book" width="131px" height="174px" />
        </div>
        <div className={classes.iconVariantMobile}>
          <Icon icon="book" width="131px" height="174px" />
        </div>
      </Paper>
      <Paper className={classes.card}>
        <div className={classes.cardTitleVariant}>
          <Text
            textColor="#4366F7"
            textFontSize={20}
            textFontWeight={600}
            textLetterSpacing={0.6}
            textLineHeight="28px"
            text="Dealer Training, for you and your team!"
          />
        </div>
        <div className={classes.cardText}>
          <Text
            textColor="#9198A0"
            textFontSize={14}
            textLetterSpacing={0.65}
            textLineHeight="18px"
            text="You can request training whenever you or your team need training."
          />
        </div>
        <div className={classes.link}>
          <Text
            textColor="#9198A0"
            textFontWeight={600}
            textFontSize={12}
            textLetterSpacing={0.6}
            textLineHeight="16px"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            SEE MORE
            <KeyboardArrowRight />
          </Text>
        </div>
        <div className={classes.icon}>
          <Icon icon="dealer101" width="139px" height="184px" />
        </div>
        <div className={classes.iconMobile}>
          <Icon icon="dealer101" width="118px" height="152px" />
        </div>
      </Paper> */}
    </Grid>
  );
};

export default InfoCards;
