import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Icon from '../Icon';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    marginTop: 20,
    marginBottom: 20,
    minHeight: 50,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
    },
  },
  responsiveLogo: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const Header = ({ type, match }) => {
  const classes = useStyles();
  return (
    <div className={classes.headerWrapper}>
      <Grid item xs={12} className={classes.responsiveLogo}>
        <Icon icon="logo" color="#f79d3c" height="51" width="216" />
      </Grid>
    </div>
  );
};

export default Header;
