import React from 'react';
import {
  translateUserStatus,
  translateApplicationState,
  translatePrequalStatus,
} from 'services/utils';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from 'components/TextFields/Text';

const StyledBadge = withStyles(theme => ({
  root: {
    borderRadius: '50%',
    opacity: 0.95,
    width: '10px',
    height: '10px',
    backgroundColor: ({ status }) => {
      return theme.palette.appState[status];
    },
    marginRight: '0.5rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '0.25rem',
    },
  },
}))(Box);

const useStyles = makeStyles(() => ({
  wrapper: {
    whiteSpace: 'nowrap',
  },
}));

function getLabel({ status, draftStatus, overrideLabel, kind }) {
  if (overrideLabel) {
    return overrideLabel;
  }
  switch (kind) {
    case 'user':
      return translateUserStatus(status).toUpperCase();
    case 'prequal':
      return translatePrequalStatus(status).toUpperCase();
    case 'application':
    default:
      return translateApplicationState(status, draftStatus).toUpperCase();
  }
}

export const ApplicationStatusBadge = ({
  status,
  draftStatus,
  textProps,
  overrideLabel = '',
  kind = 'application',
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.wrapper}>
      <StyledBadge status={draftStatus === 'canceled' || status ==="expired" ? 'canceled' :status ==="refunded" ?"completed": status} />
      <Text {...textProps}>
        {getLabel({ status, draftStatus, overrideLabel, kind })}
      </Text>
    </Box>
  );
};
