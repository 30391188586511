import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';

import LoanTab from './Loan';
import HomeownerTab from './Homeowner';
import HomeownerFinanceTab from './HomeOwnerFinance';
import CoApplicantTab from './CoApplicant';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 32,
  },
}));

const ApplicationTab = ({
  applicationDetail,
  loanRequestData,
  homeownerData,
  homeownerFinancesData,
  coapplicantData,
  coapplicantFinancesData,
}) => {
  const classes = useStyles();
  return (
    <>
      <HomeownerTab data={homeownerData} />
      {homeownerFinancesData.employmentType !== 'retired' && (
        <>
          <Divider className={classes.divider} />
          <HomeownerFinanceTab data={homeownerFinancesData} />
        </>
      )}
      <Divider className={classes.divider} />
      <LoanTab data={loanRequestData} />
      {applicationDetail.meta.hasCoapplicant ? (
        <>
          <Divider className={classes.divider} />
          <CoApplicantTab data={coapplicantData} />
        </>
      ) : null}
    </>
  );
};

export default ApplicationTab;
