import React, { useState } from 'react';

import SettingsSection from 'components/SettingsSection';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import { ModalProvider } from 'services/contexts/useModalContext';
import PhoneNumberForm from './PhoneNumberForm';

const initialValues = {
  phone: '',
};

const PhoneNumber = () => {
  return (
    <SettingsSection>
      <SettingsSectionForm
        onSubmit={() => {}}
        initialValues={initialValues}
        validationSchema={null}
      >
        <ModalProvider>
          <PhoneNumberForm />
        </ModalProvider>
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default PhoneNumber;
