import React, { useEffect, useState } from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';

import Text from 'components/TextFields/Text';
import { DealerNetworkAutocomplete } from 'components/InputFields/DealerNetworkAutocomplete';

const useStyles = makeStyles((theme) => ({
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: '3.5rem 3rem',
    width: '90%',
    minHeight: '28rem',
    [theme.breakpoints.up('sm')]: {
      width: '32rem',
    },
    color: theme.palette.text.primary,
    '&:focus': {
      outline: 'none',
    },
  },
  title: {
    marginBottom: '0.5rem',
  },
  subTitle: {
    marginTop: 22,
    marginBottom: 14,
  },
  dropzone: {
    backgroundColor: 'rgba(255,206,108,0.03)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255,206,108,0.1)',
    },
  },
  actionButton: {
    height: 40,
    width: '100%',
    color: 'white',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1,
    marginTop: 'auto',
  },
  loadingCircle: {
    margin: 'auto',
  },
}));

export const AssignmentActionModal = ({
  selectedIds,
  isOpen,
  onClose,
  onBulkAssign,
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDealerNetworkId, setSelectedDealerNetworkId] = useState(null);
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false);
    }
  }, [isOpen]);
  return (
    <Modal
      aria-label="delete-confirmation-modal"
      open={isOpen}
      onClose={onClose}
    >
      <Box className={classes.modal}>
        <Text textSize="lg" bold className={classes.title}>
          Applications Bulk Re-Assignment
        </Text>
        <Text textSize="sm">
          Re-assign applications in bulks easily by picking the new dealer who
          will be in charge of them, if you want to change them individually you
          can do it in the table
        </Text>

        <Text textSize="sm+" bold className={classes.subTitle}>
          Applications Re-Assignemnt
        </Text>

        <Text textSize="sm" style={{ marginBottom: '.5rem' }}>
          {`Applications ${selectedIds.map((row) => row.subValue)}
           will be assigned to:`}
        </Text>
        <DealerNetworkAutocomplete
          label="select a delaer"
          onChange={(ev, selectedValue) => {
            if (!isLoading) {
              setSelectedDealerNetworkId(selectedValue.value);
            }
          }}
          disabled={isLoading}
        />
        {!isLoading ? (
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setIsLoading(true);
              onBulkAssign(selectedDealerNetworkId);
            }}
            disabled={!selectedDealerNetworkId}
            className={classes.actionButton}
          >
            RE-ASSIGN
          </Button>
        ) : (
          <CircularProgress className={classes.loadingCircle} />
        )}
      </Box>
    </Modal>
  );
};
