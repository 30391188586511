import React, { useState, useEffect } from 'react';
import { AutocompleteSelect } from './../CustomizedMUIInputs';
import { protectedGet } from 'services/http';

export const MerchantSection = ({
  merchant,
  setMerchant,
  isLoading,
  showAllErrors,
  onError,
}) => {
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    const fetchMerchants = async () => {
      const newListOfOptions = await protectedGet(
        '/v1/dealer_networks/active_dealers_for_options',
      );
      setOptions(newListOfOptions.data);
      setIsFetching(false);
    };

    fetchMerchants();
  }, []);

  useEffect(() => {
    if (merchant && !merchant.dealer_code && options.length > 0) {
      /* 
        If merchant.dealer_code exists, but merchant.id does not, that means it's a resubmit, 
        and we need to wait for the list of merchants fetch to arrive
      */
      const merchantWithResubmitId = options.find(
        (responseMerchant) => responseMerchant.id === merchant.id,
      );
      if (merchantWithResubmitId) {
        setMerchant(merchantWithResubmitId);
      } else {
        onError(
          "Resubmit's merchant not found in list of available merchants. Clearing merchant selection.",
        );
        setMerchant(null);
      }
    }
  }, [merchant, options]);

  return (
    <div
      className={
        'form-merchant-section form-card ' +
        (isFetching ? 'form-section-fetching' : '')
      }
    >
      <h2>Merchant</h2>
      <p>Please assign this <strong>application</strong> to a <strong>Merchant</strong>.</p>
      <AutocompleteSelect
        value={merchant}
        onChange={(newValue) => {
          setMerchant(newValue);
        }}
        options={options}
        getOptionLabel={(option) => `${option.name} (${option.dealer_code})`}
        loading={isFetching || isLoading}
        inputId="merchant-input"
        label="Select a Merchant"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
    </div>
  );
};
