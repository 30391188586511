import React from 'react';

export default function DocumentDOC({ width, height }) {
  return (
    <svg
      width={width || '32px'}
      height={height || '42px'}
      viewBox="0 0 32 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Files / Pdf@1x</title>
      <desc>Created with Sketch.</desc>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon-/-Files-/-Doc"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M27.6956522,42 L4.30434783,42 C1.92712216,42 7.81597009e-14,40.0547544 7.81597009e-14,37.6551724 L7.81597009e-14,4.34482759 C7.81597009e-14,1.94524557 1.92712216,0 4.30434783,0 L23.3913043,0 L32,8.68965517 L32,37.6551724 C32,40.0547544 30.0728778,42 27.6956522,42 Z"
          id="Path"
          fill="#70A1FF"
          fillRule="nonzero"
        />
        <path
          d="M32,8.68965517 L27.2557373,8.68965517 C24.7704559,8.68965517 23.3913043,7.03154602 23.3913043,4.54626465 L23.3913043,5.06261699e-14 L32,8.68965517 Z"
          id="Path"
          fill="#5981CD"
          fillRule="nonzero"
        />
        <g
          id="003-doc"
          opacity="0.400000006"
          transform="translate(6.000000, 27.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Group">
            <path
              d="M12.0289171,1.64150943 L0.633100902,1.64150943 C0.283448929,1.64150943 0,1.3175678 0,0.917965546 C0,0.51836329 0.283448929,0.194421657 0.633100902,0.194421657 L12.0289171,0.194421657 C12.3785691,0.194421657 12.662018,0.51836329 12.662018,0.917965546 C12.662018,1.3175678 12.3785691,1.64150943 12.0289171,1.64150943 Z"
              id="Path"
            />
            <path
              d="M19.626128,4.53568499 L9.49651354,4.53568499 C9.14686156,4.53568499 8.86341263,4.21174335 8.86341263,3.8121411 C8.86341263,3.41253884 9.14686156,3.08859721 9.49651354,3.08859721 L19.626128,3.08859721 C19.9757799,3.08859721 20.2592289,3.41253884 20.2592289,3.8121411 C20.2592289,4.21174335 19.9757799,4.53568499 19.626128,4.53568499 Z"
              id="Path"
            />
            <path
              d="M15.1944217,7.42986054 L0.633100902,7.42986054 C0.283448929,7.42986054 0,7.10591891 0,6.70631665 C0,6.3067144 0.283448929,5.98277276 0.633100902,5.98277276 L15.1944217,5.98277276 C15.5440736,5.98277276 15.8275226,6.3067144 15.8275226,6.70631665 C15.8275226,7.10591891 15.5440736,7.42986054 15.1944217,7.42986054 Z"
              id="Path"
            />
            <path
              d="M19.626128,1.64150943 L14.5613208,1.64150943 C14.2116688,1.64150943 13.9282199,1.3175678 13.9282199,0.917965546 C13.9282199,0.51836329 14.2116688,0.194421657 14.5613208,0.194421657 L19.626128,0.194421657 C19.9757799,0.194421657 20.2592289,0.51836329 20.2592289,0.917965546 C20.2592289,1.3175678 19.9757799,1.64150943 19.626128,1.64150943 Z"
              id="Path"
            />
            <path
              d="M6.96410993,4.53568499 L0.633100902,4.53568499 C0.283448929,4.53568499 0,4.21174335 0,3.8121411 C0,3.41253884 0.283448929,3.08859721 0.633100902,3.08859721 L6.96410993,3.08859721 C7.3137619,3.08859721 7.59721083,3.41253884 7.59721083,3.8121411 C7.59721083,4.21174335 7.3137619,4.53568499 6.96410993,4.53568499 Z"
              id="Path"
            />
            <path
              d="M19.626128,7.42986054 L17.7268253,7.42986054 C17.3771733,7.42986054 17.0937244,7.10591891 17.0937244,6.70631665 C17.0937244,6.3067144 17.3771733,5.98277276 17.7268253,5.98277276 L19.626128,5.98277276 C19.9757799,5.98277276 20.2592289,6.3067144 20.2592289,6.70631665 C20.2592289,7.10591891 19.9757799,7.42986054 19.626128,7.42986054 Z"
              id="Path"
            />
            <path
              d="M4.43170632,10.3240361 L0.633100902,10.3240361 C0.283448929,10.3240361 0,10.0000945 0,9.60049221 C0,9.20088995 0.283448929,8.87694832 0.633100902,8.87694832 L4.43170632,8.87694832 C4.78135829,8.87694832 5.06480722,9.20088995 5.06480722,9.60049221 C5.06480722,10.0000945 4.78135829,10.3240361 4.43170632,10.3240361 Z"
              id="Path"
            />
            <path
              d="M13.2951189,10.3240361 L6.96410993,10.3240361 C6.61445795,10.3240361 6.33100902,10.0000945 6.33100902,9.60049221 C6.33100902,9.20088995 6.61445795,8.87694832 6.96410993,8.87694832 L13.2951189,8.87694832 C13.6447709,8.87694832 13.9282199,9.20088995 13.9282199,9.60049221 C13.9282199,10.0000945 13.6447709,10.3240361 13.2951189,10.3240361 Z"
              id="Path"
            />
            <path
              d="M19.626128,10.3240361 L15.8275226,10.3240361 C15.4778706,10.3240361 15.1944217,10.0000945 15.1944217,9.60049221 C15.1944217,9.20088995 15.4778706,8.87694832 15.8275226,8.87694832 L19.626128,8.87694832 C19.9757799,8.87694832 20.2592289,9.20088995 20.2592289,9.60049221 C20.2592289,10.0000945 19.9757799,10.3240361 19.626128,10.3240361 Z"
              id="Path"
            />
          </g>
        </g>
        <g
          id="Group-2"
          transform="translate(5.000000, 11.000000)"
          fillRule="nonzero"
        >
          <path
            d="M24,3.072 L24,6.912 C24,8.608512 22.7357647,9.984 21.1764706,9.984 L2.82352941,9.984 C1.26423529,9.984 0,8.608512 0,6.912 L0,3.072 C0,1.375488 1.26423529,-3.83693077e-14 2.82352941,-3.83693077e-14 L21.1764706,-3.83693077e-14 C22.7357647,-3.83693077e-14 24,1.375488 24,3.072 Z"
            id="Path"
            fill="#A6D2FF"
            opacity="0.487095424"
          />
          <g
            id="Group"
            transform="translate(2.304000, 2.208000)"
            fill="#FFFFFF"
          >
            <rect id="Rectangle" x="0" y="3.936" width="1.536" height="1.536" />
            <path
              d="M4.4352,0.112128 L2.820096,0.112128 L2.820096,5.469696 L4.51584,5.469696 C6.212352,5.469696 7.186176,4.484352 7.186176,2.766336 C7.185408,1.029888 6.234624,0.112128 4.4352,0.112128 Z M5.617152,2.766336 C5.617152,4.040448 4.943616,4.204032 4.355328,4.204032 L4.355328,1.377024 C5.0112,1.377024 5.617152,1.545984 5.617152,2.766336 Z"
              id="Shape"
            />
            <path
              d="M12.712704,2.766336 C12.712704,1.069824 11.757312,0.016128 10.21824,0.016128 C8.679168,0.016128 7.723776,1.069824 7.723776,2.766336 C7.723776,4.466688 8.702976,5.565696 10.21824,5.565696 C11.733504,5.565696 12.712704,4.46592 12.712704,2.766336 Z M10.21824,4.219392 C9.646848,4.219392 9.292032,3.662592 9.292032,2.765568 C9.292032,1.88544 9.6384,1.360128 10.21824,1.360128 C10.79808,1.360128 11.145216,1.88544 11.145216,2.765568 C11.145216,3.66336 10.789632,4.219392 10.21824,4.219392 Z"
              id="Shape"
            />
            <path
              d="M17.581056,4.768512 L17.627904,4.714752 L16.770048,3.766272 L16.710144,3.830784 C16.465152,4.092672 16.186368,4.22016 15.858432,4.22016 C15.207936,4.22016 14.820096,3.683328 14.820096,2.783232 C14.820096,1.945344 15.26016,1.360896 15.890688,1.360896 C16.210176,1.360896 16.419072,1.487616 16.652544,1.692672 L16.713216,1.744896 L17.568,0.781824 L17.51424,0.728064 C17.061888,0.275712 16.464384,0.016128 15.87456,0.016128 C14.571264,0.016128 13.252608,0.98304 13.252608,2.830848 C13.252608,4.492032 14.26944,5.565696 15.843072,5.565696 C16.521984,5.564928 17.139456,5.282304 17.581056,4.768512 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
