
const HOMEHOWNER_MENU_ITEMS = [
  {
    route: '/homeowner/dashboard',
    name: 'Dashboard',
    icon: 'dashboard',
  },
  {
    route: '/applications/start',
    name: 'New Application',
    icon: 'prequalify',
  },
];
const HOMEHOWNER_MENU_ITEMS_NEW_FLOW = [
  {
    route: '/homeowner/dashboard',
    name: 'Dashboard',
    icon: 'dashboard',
  },
  {
    route: '/homeowner/payment-center',
    name: 'Payment Center',
    icon: 'payment',

  },
  {
    route: '/homeowner/contact-us',
    name: 'Contact Us',
    icon: 'contactUs',
  },
];
const DEALER_MENU_ITEMS = [
  {
    route: '/dealer/dashboard',
    name: 'Dashboard',
    icon: 'dashboard',
  },
  {
    route: '/applications/start',
    name: 'New Application',
    icon: 'prequalify',
    enableStatuses: ['approved'],
  },
  {
    route: '/applications',
    name: 'Applications',
    icon: 'applications',
    enableStatuses: ['approved'],
  },
  {
    route: '/projects',
    name: 'Projects',
    icon: 'projects',
    enableStatuses: ['approved'],
  },
  {
    route: '/organizations',
    name: 'Organizations',
    icon: 'organization',
    roles: ['affiliate'],
    enableStatuses: ['approved'],
  },
  {
    route: '/dealerships',
    name: 'Merchants',
    icon: 'business',
    roles: ['organization', 'affiliate'],
    enableStatuses: ['approved'],
  },
  {
    route: '/team-members',
    name: 'Team Members',
    icon: 'team-members',
    enableStatuses: ['approved'],
    roles: ['organization', 'affiliate', 'dealer_network_overseer'],
  },
  {
    route: '/tutorials',
    name: 'Tutorials',
    icon: 'help',
  },
];

const DEALER_MENU_ITEMS_NEW_FLOW = [
  {
    route: '/dealer/dashboard',
    name: 'Dashboard',
    icon: 'dashboard',
  },
  {
    route: '/applications/start',
    name: 'New Application',
    icon: 'prequalify',
    enableStatuses: ['approved'],
  },
  {
    route: '/applications',
    name: 'Applications',
    icon: 'applications',
    enableStatuses: ['approved'],
  },
  {
    route: '/projects',
    name: 'Loans',
    icon: 'projects',
    enableStatuses: ['approved'],
  },
  {
    route: '/organizations',
    name: 'Organizations',
    icon: 'organization',
    roles: ['affiliate'],
    enableStatuses: ['approved'],
  },
  {
    route: '/dealerships',
    name: 'Merchants',
    icon: 'business',
    roles: ['organization', 'affiliate'],
    enableStatuses: ['approved'],
  },
  {
    route: '/team-members',
    name: 'Team Members',
    icon: 'team-members',
    enableStatuses: ['approved'],
    roles: ['organization', 'affiliate', 'dealer_network_overseer'],
  },
   {
    route: '/merchant-resources',
    name: 'Merchant Resources',
    icon: 'merchantResources',
  },
  
  {
    route: '/contact-us',
    name: 'Contact us',
    icon: 'contactUs',
  },
];

export { DEALER_MENU_ITEMS_NEW_FLOW,HOMEHOWNER_MENU_ITEMS_NEW_FLOW };
