import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  TextField as MUITextField,
  MenuItem,
  LinearProgress,
} from '@material-ui/core';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import './MUIStylesOverride.css';

export const removeNonDigitCharacters = (string) => {
  if (string) return string.replace(/\D/g, '')

  return '';
};

/**
 *
 * @param {string} stringToTransform
 * @returns {Date}
 * @todo improve docs, add better error handling for odd cases, add tests
 */
export function stringToDate(stringToTransform) {
  const regex = /(\d+|january|february|march|april|may|june|july|august|september|october|november|december|jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)/gi;
  const aux = stringToTransform.match(regex);

  if (!aux) {
    return new Date('INVALID DATE'); // we have to check that aux isn't null, because new Date(null) is actually a valid date. It's the UNIX Epoch, 1st of january of 1970 at 00:00:00:00
  }
  return new Date(aux);
}

/*
  TODO:
  - implement the highlight prop for all components
  - make sure helperText and highlight both get overriden by an error state
  - add the css changes for the highlight
  - add JSDoc comments on all the functions here (for now, optional. At least start documenting params slowly)
  - test it all works
  
  - move to separate folder
  - create global path
*/

/**
 * @param {string}  value - String to display in the input, which should mutate when onChange is called
 * @param {function} onChange - Function that takes the value after user modification, and updates said value
 * 
 * @param id - string or number to be passed down to the input as both the id and the key. @todo change this if it causes problems
 * @param {string} [className] - class to be passed to the CONTAINER wrapping the input as well as the loading bar. You can use child selectors to target internal components.
 * @param {string} label - the text that will be displayed above the input, as well as on the input while empty and blured (note: minimum input width is determined by label length, to avoid weird folds)
 * 
 * @param {boolean} [required] - Marks the input as required.
 * @param {boolean} [disabled] - Disables the input.
 * @param {boolean} [readOnly] - Disables the input without applying disabled CSS styles
 * @param {boolean} [showErrorsWhileClean] - Display all errors even if user hasn't yet interacted with the input. (aka even if the input is clean)
 * @param {boolean} [highlight] - Adds CSS to highlight the input
 * @param {boolean} [loading] - Disables dirty-ing and displays loading bar below icon. Doesn't disable the input itself.
 *
 * @param {string} [helperText] - Displays text under the input. Overriden when there's an error. Displays even while on focus
 * @param {string} [requiredErrorText="This field is required"] - Replaces default text that's displayed when input is marked as required but is empty.
 * @param {Object} [customError] - Adds a special error validation.
 * @param {function} [customError.validationFunction] - Function which will be called after the value changes, this function must return true if the value is valid or false if it is invalid. With the objective to allow the user of this function more freedom, it is the function's responsibility to make sure the error doesn't get triggered if the input isn't required and the value would otherwise be false (@see VIS-1994 staged funding, LoanSection, downPayment's custom error for an example)
 * @param {string} [customError.errorMessage] - Message to display when the validationFunction in customError returns false.
 * 
 * @todo implement readOnly in all fields
 * @todo implement error (just forces the input on error)
 * @todo implement functions like onBlur and onFocus
 * @todo make sure all fields follow the correct className implementation (currently TextField and others doesn't)
 * @todo make sure all fields follow the correct error handling implementation (currently only CurrencyField does)
 * @todo add docs for css selector targeting of all the child components
 * @todo implement getInputClassName on all fields, rename it to something like getInputClassWithModifiers (this functions handles adding styles like disabled and stuff)(maybe it should be responsibility of the MUI-override_input-container)
 * @todo make the above an interface, and make the other fields implement it. @see https://jsdoc.app/tags-implements.html
 * @todo add some sort of individual id generation in case user doesn't pass the id prop
 * @todo add JSDoc comments to all exported functions, create a naming convention for all functions that cast values
 */

export const TextField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'This field is required',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && value.length === 0) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        type="text"
        variant="outlined"
        autoComplete="off"
        style={{ minWidth: `${label.length + 3}ch` }}
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export const AutocompleteSelect = ({
  options,
  getOptionLabel,
  wrapperClassName,

  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'This field is required',
  customError,
}) => {
  // the || "" is to avoid an MUI bug due to a falsely reported bug of an uncontrolled value (aka don't pass undefined to an input or it will think it needs to be inside of a form tag)
  const [internalValue, setInternalValue] = useState('');
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  /*
    Notes on the MUI Autocomplete component:
    1- For some reason, they decided it'd be a great idea to separate the value (as in, what's the currently selected element) with the inputValue(what element is displayed)...
    2- this part: 
            inputProps={{...props.inputProps}}
            {...props.InputProps}
      is mandatory. I don't understand why (-.- )
 */

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && internalValue.length === 0) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUIAutocomplete
        value={value}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        inputValue={internalValue}
        onInputChange={(_, newValue) => {
          setInternalValue(newValue);
        }}
        options={options}
        getOptionLabel={(option) => getOptionLabel(option)}
        loading={loading}
        onBlur={() => {
          setIsBlured(true);
        }}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        className={`${wrapperClassName || ''}`}
        disabled={disabled}
        renderInput={(props) => {
          return (
            <MUITextField
              {...props}
              id={id}
              label={label}
              variant="outlined"
              autoComplete="off"
              className={`${className || ''} ${
                disabled ? 'MUI-override_input-disabled' : ''
              } ${highlight ? 'MUI-override_input-highlight' : ''}`}
              style={{ minWidth: `${label.length + 3}ch` }}
              error={errorCheck()}
              helperText={getHelperText()}
              required={required}
            />
          );
        }}
      ></MUIAutocomplete>
      {loading && <LinearProgress />}
    </div>
  );
};

export const formatRawPhoneNumber = (rawPhoneNumber) => {
  //  i'm gonna intentionaly write it "bad" just to make it easier to understand. If i DRY this piece of code, it'll be a mess to add new features, and its not worth the optimization IMHO
  //  format is (012)345-6789
  let aux = rawPhoneNumber;
  if (rawPhoneNumber.length > 6) {
    //  0123456789 => 012345-6789
    aux = aux.slice(0, 6).concat('-').concat(aux.slice(6));
  }
  if (rawPhoneNumber.length > 3) {
    //  012345-6789 => 012)345-6789
    aux = aux.slice(0, 3).concat(')').concat(aux.slice(3));
  }
  if (rawPhoneNumber.length > 0) {
    //  (012)345-6789 => (012)345-6789
    aux = '('.concat(aux);
  }
  return aux;
};

export const isValidUSPhoneNumber = (rawPhoneNumber) => {
  /*
      From what i've seen, the US phone number format is the following:
      +1 (NXX)NXX-XXXX
      Where +1 is implicit, X is a number from 0-9, and N is a number from 2-9
    */
  const usPhoneNumberRegex = /^\([2-9][0-9]{2}\)[2-9][0-9]{2}-[0-9]{4}$/g;
  return usPhoneNumberRegex.test(rawPhoneNumber);
};

export const PhoneNumberField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'Please enter a valid US phone number',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [newSelectionRange, setNewSelectionRange] = useState({
    selectionStart: null,
    selectionEnd: null,
  });
  const referenceToInput = useRef(null);

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const handleOnChange = (event) => {
    const rawNewValue = removeNonDigitCharacters(event.target.value);

    const onlyNumbersRegex = /^[0-9]*$/g;
    if (!onlyNumbersRegex.test(rawNewValue) || rawNewValue.length > 10) return; // no need to keep executing the function if we don't want to change the value

    const newValue = event.target.value;
    const oldValue = value;
    const newlyFormattedValue = formatRawPhoneNumber(rawNewValue);
    const diff = newValue.length - oldValue.length;
    const { selectionStart, selectionEnd } = event.target;
    let newSelectionStart = selectionStart;
    let newSelectionEnd = selectionEnd;

    if (diff > 0) {
      // this means a character got added
      const newCharacterIndex = selectionStart - 1;
      if (
        newlyFormattedValue[newCharacterIndex] !== newValue[newCharacterIndex]
      ) {
        // a character was added that would throw off the caret selection. Add +1 to correct for it
        newSelectionStart = newSelectionStart + 1;
        newSelectionEnd = newSelectionEnd + 1;
      }
    }

    onChange(newlyFormattedValue);

    setNewSelectionRange({
      selectionStart: newSelectionStart,
      selectionEnd: newSelectionEnd,
    });
  };

  useEffect(() => {
    if (
      !referenceToInput.current ||
      newSelectionRange.selectionStart === null ||
      newSelectionRange.selectionEnd === null
    )
      return;
    referenceToInput.current.setSelectionRange(
      newSelectionRange.selectionStart,
      newSelectionRange.selectionEnd,
    );
  }, [newSelectionRange]);

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && value.length === 0) return true;
      if (value.length > 0 && !isValidUSPhoneNumber(value)) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => handleOnChange(e)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        type="tel"
        variant="outlined"
        autoComplete="off"
        style={{ minWidth: `${label.length + 3}ch` }}
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
        inputRef={referenceToInput}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export const isValidEmail = (email) => {
  // This is the Devise email regex pattern, converted into javascript. Since we use Devise on backend to validate emails
  // it's probably a good idea to use a similar pattern here

  const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

  return emailRegex.test(email);
};

export const EmailField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText = '',
  requiredErrorText = 'Please enter a valid Email',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && value.length === 0) return true;
      if (value.length > 0 && !isValidEmail(value)) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  /** @todo helperText is bugged on the other inputs. Fix it to mimic behaviour here */
  /** there has to be a more elegant way here to handle the errors. Maybe a setState? */
  const getHelperText = () => {
    const hasActiveCustomError =
      customError &&
      customError.errorMessage &&
      !customError.validationFunction(value);

    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (hasActiveCustomError) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    }
    return helperText;
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        style={{ minWidth: `${label.length + 3}ch` }}
        error={errorCheck()}
        helperText={getHelperText()}
        type="email"
        variant="outlined"
        autoComplete="off"
        required={required}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export const formatRawSSN = (rawSSN) => {
  //  i'm gonna intentionaly write it "bad" just to make it easier to understand. If i DRY this piece of code, it'll be a mess to add new features, and its not worth the optimization IMHO
  //  format is 012-34-5678
  let aux = rawSSN;
  if (rawSSN.length > 5) {
    //  012345678 => 01234-5678
    aux = aux.slice(0, 5).concat('-').concat(aux.slice(5));
  }
  if (rawSSN.length > 3) {
    //  012345-678 => 012-34-5678
    aux = aux.slice(0, 3).concat('-').concat(aux.slice(3));
  }
  return aux;
};

const formatRawSSNMasked = (rawSSN) => {
  return ('XXX-XX-').concat(rawSSN.slice(5));
};

const isValidSSN = (ssn, unMaskedSSN) => {
  /*
    We will no longer check that the SSN is actually valid. We will only check that it follows the format:
    XXX-XX-XXXX, where X is a number from 0-9

    Still, changing this is incredibly simple. I'll leave 2 regex here in case we want to start testing for actually valid SSNs
    1- Valid SSN only: /^(?!0{3})(?!6{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/g
    2- Valid SSN, but allow 666 at the beginning (QA uses those SSNs to test): /^(?!0{3})[0-8]\d{2}-(?!0{2})\d{2}-(?!0{4})\d{4}$/g
  */

  if (ssn.length < 11) return false;

  let fullSSN = removeNonDigitCharacters(unMaskedSSN.concat(ssn));

  fullSSN = `${fullSSN.slice(0, 3)}-${fullSSN.slice(3, 5)}-${fullSSN.slice(5)}`;

  const ssnRegex = /^\d{3}-\d{2}-\d{4}$/g;

  return ssnRegex.test(fullSSN);
};

export const SSNField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'Please enter a valid SSN',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [newSelectionRange, setNewSelectionRange] = useState({
    selectionStart: null,
    selectionEnd: null,
  });
  const [unmaskedSSN, setUnmaskedSSN] = useState('');
  const unmaskedSSNRef = useRef('');
  const referenceToInput = useRef();

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const handleOnChange = (event) => {
    const rawNewValue = removeNonDigitCharacters(event.target.value);
    const newValue = event.target.value;
    const oldValue = value;
    let newlyFormattedValue;

    if (rawNewValue.length < 6 && !newValue.includes('X')) {
      unmaskedSSNRef.current = rawNewValue;
      setUnmaskedSSN(rawNewValue);
    }

    if (newValue.length > 11) return;

    if (newValue.length === 11) {
      newlyFormattedValue = formatRawSSNMasked(rawNewValue);
    } else if (oldValue.length === 11 && newValue.length === 10) {
      newlyFormattedValue = formatRawSSN(unmaskedSSNRef.current.concat(rawNewValue));
    } else {
      newlyFormattedValue = formatRawSSN(rawNewValue);
    }

    onChange({ ssn: newlyFormattedValue, unmaskedSSN: unmaskedSSNRef.current});
  };

  useEffect(() => {
    if (
      !referenceToInput.current ||
      newSelectionRange.selectionStart === null ||
      newSelectionRange.selectionEnd === null
    )
      return;
    referenceToInput.current.setSelectionRange(
      newSelectionRange.selectionStart,
      newSelectionRange.selectionEnd,
    );
  }, [newSelectionRange]);

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && value.length === 0) return true;
      if (value.length > 0 && !isValidSSN(value, unmaskedSSN)) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean)) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => handleOnChange(e)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        style={{ minWidth: `${label.length + 3}ch` }}
        variant="outlined"
        autoComplete="off"
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
        inputRef={referenceToInput}
        onPaste={(e) => { if (label.includes('Confirmation')) e.preventDefault() }}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

export const formatDateToInputFormat = (dateToFormat) => {
  // This function takes a Date object and returns it's string in this format: "yyyy-mm-dd" (that's what the min and max fields need)
  // this is the format accepted by the input
  return dateToFormat.toISOString().split('T')[0];
};

const formatDateInputToUSAStandardDateFormat = (dateToFormat) => {
  return dateToFormat.toLocaleDateString('en-US');
};

export const DateField = ({
  minimumDate,
  maximumDate,

  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'Please enter a valid Date',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const referenceToInput = useRef();

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  /*
    BEWARE, HERE BE DRAGONS:
    Date objects are unintuitive. So are date pickers and date strings.
    especially when working with MUI

    we're basically mixing and matching strings and dates, converting strigns to dates, dates to strings, and its causing a mess
    this is because Dates, counterintuitively, were not meant to represent a date, but rather a complete time with hours, minutes, seconds and miliseconds.
    The problem comes when you pass a string date as a value for new Date()

    Picture this example: 
    Let's say I want to compare a date chosen by the Datepicker (which comes in string format, "2023-08-29") with today (same day), accounted for my UTC time zone
    when I do new Date(), it automatically gets the date of today from my local system calendar. So, the new date is effectively 28th of august, 2023, whatever hours and minutes may be at the time
    HOWEVER
    When I try to do new Date("2023-08-29"), this gets passed as the date of the 29th of august, 2023, but at UTC +0
    Since we didn't specify hours, minutes, seconds, or miliseconds, the date becomes midnight.
    and THEN, since I'm in UTC-3, it gets converted to the 28th of august, 2023, at 21 hours sharp.

    The solution? new Date(2023, 7, 29) (7 means august, months indexed at 0 for some reason)

    Why does this work any different? I have no clue. 
  */

  const errorCheck = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (required && value.length === 0) return true;
      if (minimumDate) {
        /* for an explanation on the 4 lines below this, see VIS-2025 */
        let aux = value.split('-').map((elem) => Number(elem));
        --aux[1];
        let currentlySelectedDate = new Date(...aux);
        minimumDate.setHours(0, 0, 0, 0);

        if (currentlySelectedDate < minimumDate) {
          return true;
        }
      }
      if (maximumDate) {
        /* for an explanation on the 4 lines below this, see VIS-2025 */
        let aux = value.split('-').map((elem) => Number(elem));
        --aux[1];
        let currentlySelectedDate = new Date(...aux);
        maximumDate.setHours(0, 0, 0, 0);

        if (currentlySelectedDate > maximumDate) {
          return true;
        }
      }
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    } else if (helperText) return helperText;
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      }

      if (minimumDate) {
        /* for an explanation on the 4 lines below this, see VIS-2025 */
        let aux = value.split('-').map((elem) => Number(elem));
        --aux[1];
        let currentlySelectedDate = new Date(...aux);
        minimumDate.setHours(0, 0, 0, 0);

        if (currentlySelectedDate < minimumDate) {
          return `Date must be ${formatDateInputToUSAStandardDateFormat(
            minimumDate,
          )} or afterwards`;
        }
      }
      if (maximumDate) {
        /* for an explanation on the 4 lines below this, see VIS-2025 */
        let aux = value.split('-').map((elem) => Number(elem));
        --aux[1];
        let currentlySelectedDate = new Date(...aux);
        maximumDate.setHours(0, 0, 0, 0);

        if (currentlySelectedDate > maximumDate) {
          return `Date must be ${formatDateInputToUSAStandardDateFormat(
            maximumDate,
          )} or before`;
        }
      }

      if (errorCheck()) {
        return requiredErrorText;
      }
    }
    return '';
  };

  useEffect(() => {
    function handleDatePaste(event) {
      if (document.activeElement === referenceToInput.current) {
        /*
          For some reason, we can't rely on event.target here, its almost completely random.
          Instead, we have to check the currently active element, aka what element has focus
        */
        event.preventDefault;
        let dateUserWantsToCopyPaste = stringToDate(
          event.clipboardData.getData('text'),
        );

        if (!isNaN(dateUserWantsToCopyPaste)) {
          onChange(formatDateToInputFormat(dateUserWantsToCopyPaste));
        } else {
          onChange('');
        }
      }
    }
    document.addEventListener('paste', handleDatePaste);

    return () => {
      document.removeEventListener('paste', handleDatePaste);
    };
  }, []);

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        id={id}
        label={label}
        variant="outlined"
        autoComplete="off"
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        style={{ minWidth: `${label.length + 3}ch` }}
        type="date"
        inputProps={{
          min: minimumDate ? formatDateToInputFormat(minimumDate) : null,
          max: maximumDate ? formatDateToInputFormat(maximumDate) : null,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
        inputRef={referenceToInput}
        onPaste={(e) => { if (label.includes('Confirmation')) e.preventDefault() }}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

// options format: {label: "TEXT-TO-DISPLAY", value: "THING-TO-SAVE-TO-STATE", id: "IMMUTABLE-STRING-OR-NUMBER-FOR-REACT-REASONS"}
export const Select = ({
  options,

  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'This field is required',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (
        required &&
        (!Boolean(value) || (typeof value === 'string' && value.length === 0))
      )
        return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        style={{ minWidth: `${label.length + 3}ch` }}
        variant="outlined"
        autoComplete="off"
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
      >
        {options.map((option) => {
          return (
            <MenuItem
              aria-label={option.label}
              value={option.value}
              key={String(option.value)}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MUITextField>

      {loading && <LinearProgress />}
    </div>
  );
};

/**
 * @param {number} numberToFormat the number you wish to format into a USA currency string 
 * @returns a USA currency string, or null if invalid
 */
export const formatNumberToCurrencyString = (numberToFormat) => {
  /*
    We're just gonna use Intl.NumberFormat, which comes packed in by default in ECMAScript
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat#examples
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
    https://stackoverflow.com/questions/23645374/how-to-format-number-to-currency-without-decimal-point-in-javascript
    
    returns the number formatted as currency, or null if the value is invalid

    Add tests here:
    rather than having a specific test, i would do a bit more research on how Intl.NumberFormat works
    this sounds like the sort of thing that a locale change would break
    if there's anything that requires it, i'd add some tests
  */
  if ([NaN, null, undefined].includes(numberToFormat)) return null
  let aux = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(numberToFormat);
  if (['$NaN', '$undefined', '$null'].includes(aux)) {
    return null;
  }
  return aux;
};

export const formatNumberToCurrencyStringWithTwoDecimal = (numberToFormat) => {
  /*
    This is just a altered version of above method formatNumberToCurrencyString to format the string
    with 2 fractional point. For numbers with single fractional value, it's better to use this method
    to get the better formatting.
  */
  if ([NaN, null, undefined].includes(numberToFormat)) return null
  let aux = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numberToFormat);
  if (['$NaN', '$undefined', '$null'].includes(aux)) {
    return null;
  }
  return aux;
};

/**
 * @param {(string|number)} value a string of the format "$1,234,567.89" (USA currency format), or a number
 * @returns {number} value casted into a number (0 for undefined|null|"")(NaN for invalid values)(casts negative 0 to regular 0)
 * @example castFormattedCurrencyStringToNumber("$1,234.56") => 1234.56
 * 
 * maybe we should move this function to a utils file or something? idk
 */
export function castFormattedCurrencyStringToNumber (value) {

  if ([undefined, null, ""].includes(value)){
    return 0
  }
  if (!["number", "string"].includes(typeof value)) {
    return NaN
  }

  let aux;

  typeof value === "string" ? aux = Number(value.replace(/([$,])/g, '')) : aux = value; 
  
  if (aux === 0) return 0 //this is to cover for negative-zero cases, so -0 returns 0

  else return aux

}

export const CurrencyField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  readOnly,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'Please enter a valid amount of USD',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [newSelectionRange, setNewSelectionRange] = useState({
    selectionStart: null,
    selectionEnd: null,
  });
  const [isError, setIsError] = useState(false);
  const [inputText, setInputText] = useState("");
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(false);
  const referenceToInput = useRef();

  /** @description updates the selection range of the input's reference whenever newSelectionRange is changed */
  useEffect(() => {
    if (
      !referenceToInput.current ||
      newSelectionRange.selectionStart === null ||
      newSelectionRange.selectionEnd === null
    )
      return;
    referenceToInput.current.setSelectionRange(
      newSelectionRange.selectionStart,
      newSelectionRange.selectionEnd,
    );
  }, [newSelectionRange]);

  /** @description check if input should be in error state whenever a related variable/prop changes */
  useEffect(() => {
    if (isDirty || showErrorsWhileClean) {

      if (required && (value.length === 0)) {
        setIsError(true);
        return;
      } 

      if(value.at(-1) === "." || ["0", "00"].includes(value.split(".")[1])){
        setIsError(true);
        return
      }

      if (customError && customError.validationFunction) {
        setIsError(!customError.validationFunction(value));
        return; 
      }
    } 
    setIsError(false)
  }, [value, customError, showErrorsWhileClean, isDirty, required])

  /** @description whether or not we can show errors. Not to be confused with whether or not there are errors! */
  useEffect(() => {
    setShouldDisplayErrors((isDirty && isBlured) || (showErrorsWhileClean || readOnly))
  }, [isDirty, isBlured, showErrorsWhileClean, readOnly])

  /** 
   * @description the value that goes in the MUI input's helperText prop. 
   * Name can be a bit confusing since we merge both the error texts 
   *  and the helper text provided on this component's props into one, before passing it to MUI. 
   * Feel free to change the name if a better one comes up. 
   */
  useEffect(() => {
    if (shouldDisplayErrors) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {

        setInputText(customError.errorMessage);
        return;

      } else if (required && (value.length === 0)) {
        setInputText(requiredErrorText);
        return;

      } else if (value.at(-1) === ".") {
        setInputText("Value cannot end in dot . Either remove the dot, or add a decimal value");
        return;

      } else if (["0", "00"].includes(value.split(".")[1])){
        setInputText("Decimal cannot be 0. Either remove it, or add a non-zero decimal value" )
        return;
      }

    } else if (helperText) {
      setInputText(helperText);
      return;
    }
    setInputText('');
  }, [isError, shouldDisplayErrors, customError, value, helperText])

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const handleOnChange = (event) => {
    const newValue = event.target.value; /** @type {string} the value the user typed or pasted on the input */
    const oldValue = value; /** @type {string} the value currently stored in state */
    const diff = newValue.length - oldValue.length;
    const { selectionStart, selectionEnd } = event.target;
    let newSelectionStart = selectionStart;
    let newSelectionEnd = selectionEnd;

    let newlyFormattedValue = formatNumberToCurrencyString(castFormattedCurrencyStringToNumber(event.target.value)); /** @type {string} the value the user typed or pasted on the input, after running it through formatting */

    if (newlyFormattedValue && newlyFormattedValue[0] === '-') return;

    if (["$0", "$", "", null].includes(newlyFormattedValue)) newlyFormattedValue = "";
    if (newValue.at(-1) === ".") newlyFormattedValue = newlyFormattedValue + "." // if the user inputted a . to add fractions
    if (newValue.at(-2) === "." && newValue.at(-1) === "0") newlyFormattedValue = newlyFormattedValue + ".0" // if user tries to type a sub-first digit decimal, like 0.04

    // this means a character got added
    if (diff > 0) {
      const amountOfNonNumericDigitsInNewlyFormattedValue = newlyFormattedValue.split(/\d+/).filter(elem => elem !== "").length;
      const amountOfNonNumericDigitsInNewValue = newValue.split(/\d+/).filter(elem => elem !== "").length;
      const characterDifferenceInNewValueBeforeAndAfterFormatting = amountOfNonNumericDigitsInNewlyFormattedValue - amountOfNonNumericDigitsInNewValue

      // this means a new character like "$" or "," got automatically added. Add +1 to selection range to keep it as expected.
      if (amountOfNonNumericDigitsInNewlyFormattedValue > amountOfNonNumericDigitsInNewValue) {
        newSelectionStart = newSelectionStart + characterDifferenceInNewValueBeforeAndAfterFormatting;
        newSelectionEnd = newSelectionEnd + characterDifferenceInNewValueBeforeAndAfterFormatting;
      }
    }

    if (event.target.value[0] !== '$' && event.target.value.length > 0) {
      /* 
          If the $ symbol is not at the beginning, that means the user deleted it while selecting a big chunk of text.
          For example: if [] are the start and end of selection, the event.target.value would change like this: [$42]3 => 13
          If this happens, the input will add the $ character at the beginning, but it wouldn't change the character selection,
            meaning that the typing caret would end at $|13 instead of $1|3, where it should end.
          This if statement fixes that.
        */
      newSelectionStart = newSelectionStart + 1;
      newSelectionEnd = newSelectionEnd + 1;
    }

    onChange(newlyFormattedValue);

    setNewSelectionRange({
      selectionStart: newSelectionStart,
      selectionEnd: newSelectionEnd,
    });
  };

  const classnameWithCustomStatus = useMemo(() => {
    let inputClassName = "";
    if (disabled) inputClassName += " MUI-override_input-disabled";
    if (highlight) inputClassName += " MUI-override_input-highlight";
    return inputClassName
  }, [className])

  return (
    <div className={"MUI-override_input-container " + (className ? className : "")}>
      <MUITextField
        value={value}
        onChange={(e) => handleOnChange(e)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={classnameWithCustomStatus}
        disabled={disabled}
        style={{ minWidth: `${label.length + 3}ch` }}
        variant="outlined"
        autoComplete="off"
        error={isError}
        helperText={inputText}
        required={required}
        inputRef={referenceToInput}
        readOnly={readOnly}
      />
      {loading && <LinearProgress />}
    </div>
  );
};

const formatRawZipCode = (rawZipCode) => {
  let aux = rawZipCode;
  if (rawZipCode.length > 5) {
    //  012345678 => 01234-5678
    aux = aux.slice(0, 5).concat('-').concat(aux.slice(5));
  }
  return aux;
};

export const isValidZipCode = (zipCode) => {
  // source: https://regexlib.com/REDetails.aspx?regexp_id=74
  /*
    notes:
    1- we're only considering US zip codes
    2- as far as i am aware, there is no regex that checks for all the weird cases in the US zip code system
    So, there may be a "valid" zip code that doesn't exist. We can only check that the formatting is correct.
  */
  const zipCodeRegex = /^\d{5}(-\d{4})?$/;

  return zipCodeRegex.test(zipCode);
};

export const ZipCodeField = ({
  value,
  onChange,

  id,
  className,
  label,

  required,
  disabled,
  showErrorsWhileClean,
  highlight,
  loading,

  helperText,
  requiredErrorText = 'Please enter a valid US Zip code',
  customError,
}) => {
  const [isBlured, rawSetIsBlured] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [newSelectionRange, setNewSelectionRange] = useState({
    selectionStart: null,
    selectionEnd: null,
  });
  const referenceToInput = useRef();

  const setIsBlured = (value) => {
    if (!loading) {
      rawSetIsBlured(value);
    }
  };

  const handleOnChange = (event) => {
    const rawNewValue = removeNonDigitCharacters(event.target.value);

    const onlyNumbersRegex = /^[0-9]*$/g;
    if (!onlyNumbersRegex.test(rawNewValue) || rawNewValue.length > 9) return; // no need to keep executing the function if we don't want to change the value

    const newValue = event.target.value;
    const oldValue = value;
    const newlyFormattedValue = formatRawZipCode(rawNewValue);
    const diff = newValue.length - oldValue.length;
    const { selectionStart, selectionEnd } = event.target;
    let newSelectionStart = selectionStart;
    let newSelectionEnd = selectionEnd;

    if (diff > 0) {
      // this means a character got added
      const newCharacterIndex = selectionStart - 1;
      if (
        newlyFormattedValue[newCharacterIndex] !== newValue[newCharacterIndex]
      ) {
        // a character was added that would throw off the caret selection. Add +1 to correct for it
        newSelectionStart = newSelectionStart + 1;
        newSelectionEnd = newSelectionEnd + 1;
      }
    }

    onChange(newlyFormattedValue);

    setNewSelectionRange({
      selectionStart: newSelectionStart,
      selectionEnd: newSelectionEnd,
    });
  };

  useEffect(() => {
    if (
      !referenceToInput.current ||
      newSelectionRange.selectionStart === null ||
      newSelectionRange.selectionEnd === null
    )
      return;
    referenceToInput.current.setSelectionRange(
      newSelectionRange.selectionStart,
      newSelectionRange.selectionEnd,
    );
  }, [newSelectionRange]);

  const errorCheck = () => {
    if (isDirty || showErrorsWhileClean) {
      if (required && value.length === 0) return true;
      if (value.length > 0 && !isValidZipCode(value)) return true;
      if (customError && customError.validationFunction) {
        return !customError.validationFunction(value);
      }
    }
    return false;
  };

  const getHelperText = () => {
    if ((isDirty || showErrorsWhileClean) && isBlured) {
      if (
        customError &&
        customError.errorMessage &&
        !customError.validationFunction(value)
      ) {
        return customError.errorMessage;
      } else if (errorCheck()) {
        return requiredErrorText;
      }
    } else if (helperText) {
      return helperText;
    }
    return '';
  };

  return (
    <div className="MUI-override_input-container">
      <MUITextField
        value={value}
        onChange={(e) => handleOnChange(e)}
        onBlur={() => setIsBlured(true)}
        onFocus={() => {
          if (!isDirty) setIsDirty(true);
          setIsBlured(false);
        }}
        label={label}
        id={id}
        key={id}
        className={`${className || ''} ${
          disabled ? 'MUI-override_input-disabled' : ''
        } ${highlight ? 'MUI-override_input-highlight' : ''}`}
        disabled={disabled}
        type="text"
        variant="outlined"
        autoComplete="off"
        style={{ minWidth: `${label.length + 3}ch` }}
        error={errorCheck()}
        helperText={getHelperText()}
        required={required}
        inputRef={referenceToInput}
      />
      {loading && <LinearProgress />}
    </div>
  );
};
