import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { Formik, Form, yupToFormErrors } from 'formik';

import to from 'await-to-js';
import { protectedGet, protectedPost, protectedPatch } from 'services/http';

import { mergeDeepWith } from 'ramda';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Text from 'components/TextFields/Text';
import { StepHeader } from 'pages/NewApplication/StepHeader';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { merger } from 'services/utils';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { OutlinedTextField } from 'components/InputFields/InputField';
import Icon from 'components/Icon';

export const useNewItemStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem 1rem 2rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 2rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  card: {
    position: 'relative',
    paddingBottom: '3rem',
  },
  formContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 51,
      paddingRight: '2rem',
    },
  },
  actionButton: {
    height: 40,
    width: '24rem',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  backButton: {
    padding: 0,
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
}));
export const NewItemWrapper = ({ children }) => {
  const classes = useNewItemStyles();
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Card elevation={4} className={classes.card}>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const createEntity = async ({
  isEdit,
  url,
  values,
  setSubmitting,
  setErrorAlertMessage,
}) => {
  setSubmitting(true);
  const request = isEdit ? protectedPatch : protectedPost;
  const [err, r] = await to(request(url, decamelizeKeys(values)));
  setSubmitting(false);
  if (err && err.response) {
    if (err.response.status === 422) {
      setErrorAlertMessage(
        err?.response?.data?.message?.name &&
          err?.data?.response?.message?.name.length !== 0
          ? err?.response?.data?.message?.name[0]
          : 'Error',
      );
    } else {
      setErrorAlertMessage(err.response.data.message || 'Error');
    }
  }
  return r?.data;
};

export const addUserToEntity = async ({
  url,
  values,
  setSubmitting,
  setErrorAlertMessage,
  role,
  isUpdate = false,
}) => {
  setSubmitting(true);
  const request = isUpdate ? protectedPatch : protectedPost;
  const [err, r] = await to(
    request(url, {
      dealer: decamelizeKeys({
        ...values.dealer,
        role,
      }),
    }),
  );
  setSubmitting(false);
  if (err && err.response) {
    setErrorAlertMessage(err?.response?.data?.message?.email[0] && `Email ${err?.response?.data?.message?.email[0]}` || 'Error');
    console.error(err.response);
    return false;
  }
  return r;
};

const initialValuesObj = (isEdit) => ({
  isEdit,
  organization: { name: '' },
  dealer: { name: '', lastName: '', email: '' },
});

export const NewOrganization = () => {
  const classes = useNewItemStyles();
  const { palette } = useTheme();
  const history = useHistory();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const { organizationId } = useParams();
  const isEdit = useRouteMatch('/organizations/:organizationId/edit');
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const [validationErrors] = await to(
      validationSchema.validate(
        { ...values },
        {
          abortEarly: false,
        },
      ),
    );

    if (validationErrors) {
      setErrors(yupToFormErrors(validationErrors));
      return;
    }

    const orgRes = await createEntity({
      url: isEdit ? `/v1/organizations/${organizationId}` : `/v1/organizations`,
      isEdit,
      values,
      setSubmitting,
      setErrorAlertMessage,
    });

    if (!isEdit && orgRes) {
      const userRes = await addUserToEntity({
        url: `/v1/organizations/${orgRes.data.id}/members`,
        values,
        setSubmitting,
        setErrorAlertMessage,
        role: 'organization',
      });

      if (userRes) {
        setAlertMessage('Organization Created Successfully');
        history.replace('/organizations');
      }
    } else if (isEdit) {
      if (orgRes) {
        setAlertMessage('Organization Updated Successfully');
        history.replace('/organizations');
      }
    }
  };

  const [initialValues, setInitialValues] = useState(initialValuesObj(isEdit));
  useEffect(() => {
    async function fetch() {
      if (isEdit) {
        const [err, organization] = await to(
          protectedGet(`/v1/organizations/${organizationId}`),
        );
        if (err) {
          return;
        }
        const res = normalize(camelizeKeys(organization.data)).get(['name']);
        setInitialValues((state) => {
          return mergeDeepWith(merger, { organization: res }, state);
        });
      }
    }
    if (organizationId) {
      fetch();
    }
  }, [organizationId]);
  return (
    <NewItemWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Box className={clsx(classes.stepForm, 'form-step')}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <IconButton
                        className={classes.backButton}
                        style={{ marginRight: '1rem' }}
                        onClick={() => history.goBack()}
                      >
                        <ChevronLeftIcon fontSize="large" />
                      </IconButton>
                      <StepHeader
                        title={
                          isEdit
                            ? `${initialValues.organization.name}`
                            : 'Add a New Organization'
                        }
                        subtitle={
                          isEdit
                            ? 'Organization'
                            : 'Fill out the information to create a new organization'
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={3}
                    className={classes.formContainer}
                  >
                    <Grid item xs={12} md={6}>
                      <OutlinedTextField
                        name="organization.name"
                        id="organization.name"
                        label="Organization Name"
                      />
                    </Grid>
                  </Grid>
                  {!isEdit && (
                    <>
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={3}
                        className={classes.formContainer}
                        style={{
                          paddingTop: '32px',
                        }}
                      >
                        <Grid item xs={12}>
                          <Text textSize="md" semibold>
                            User Information
                          </Text>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            label="First Name"
                            name="dealer.name"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            label="Last Name"
                            name="dealer.lastName"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            label="Email"
                            name="dealer.email"
                          />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.formContainer}>
                        <Box
                          marginTop="2rem"
                          paddingY="1rem"
                          paddingX="0.5rem"
                          borderRadius="6px"
                          border={`solid 1px ${palette.divider}`}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon icon="check-email" width={80} height={80} />
                          <Box marginLeft="0.5rem">
                            <Text
                              textSize="md+"
                              bold
                              textColor={palette.text.primary}
                            >
                              Invite this dealer to join Powerpay
                            </Text>
                            <Text
                              textSize="sm"
                              semibold
                              textColor={palette.text.secondary}
                            >
                              We’ll send an email to this dealer to create{' '}
                              <br />
                              an account and manage their own deals
                            </Text>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginTop="3rem"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <Text textSize="md" bold>
                          {isEdit ? 'SAVE' : 'ADD NEW ORGANIZATION'}
                        </Text>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </NewItemWrapper>
  );
};

const validationSchema = Yup.object({
  organization: Yup.object({
    name: Yup.string().required('Field Required').trim(),
  }),
  dealer: Yup.object().when('isEdit', {
    is: (value) => !value,
    then: Yup.object({
      name: Yup.string().required('Field Required').trim(),
      lastName: Yup.string().required('Field Required').trim(),
      email: Yup.string()
        .required('Field Required')
        .email('invalid Email')
        .trim(),
    }),
  }),
});
