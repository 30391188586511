import React, { useState } from 'react';

import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import to from 'await-to-js';
import { protectedPost } from 'services/http';
import Icon from 'components/Icon';

import { useAlertContext } from '../../../components/AlertContext';
import { FileList } from '../DocumentsTab/FileList';

const useStyles = makeStyles((theme) => ({
  addNoteContainer: {
    paddingBottom: 9,
    paddingRight: 9,
    paddingLeft: 9,
    marginTop: 9,
    marginBottom: 9,
    fontSize: '0.5rem',
  },
  dropdownInput: {
    display: 'flex',
    width: '100%;',
    height: '100%',
    position: 'absolute',
    opacity: 0,
  },
  cardButton: {
    borderColor: '#F79D3C',
    borderWidth: '1.5px',
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
  addButton: {
    color: 'white',
    fontSize: '0.875 rem',
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
}));
const AddNote = ({
  applicationNumber,
  setFetchedData,
  fetchedData,
  setAlertMessage,
  setErrorAlertMessage,
}) => {
  const classes = useStyles();

  const [noteValue, setNoteValue] = useState('');
  const [noteFile, setNoteFile] = useState();
  const [fileToShow, setFileToShow] = useState();
  const clearFile = () =>{
    setFileToShow();
    setNoteFile();
  }
  const handleSubmit = async (data) => {
    const payload = {
      application_note: { note: noteValue },
    };
    if (fileToShow) {
      setNoteFile(noteFile.append(`[application_note][note]`, noteValue));
    }
    const [err] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/application_notes`,
        fileToShow ? noteFile : payload,
      ),
    );
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      setNoteFile();
      setFileToShow();
      return;
    }
    setAlertMessage('Message is added succesfully');
    setFetchedData(!fetchedData);
    setNoteValue('');
    setNoteFile();
    setFileToShow();
  };
  const handleUploadDocument = (event, document) => {
    const file = event.target.files[0];
    setFileToShow(file);
    const data = new FormData();
    data.append(`[application_note][file]`, file);
    setNoteFile(data);
    // handleSubmit(data);
  };
  const addDoc = () => {
    return (
      <>
        <span>
          <Button
            color="primary"
            variant="outlined"
            className={classes.cardButton}
            type="button"
            component="span"
            // disabled={isAppCanceled || !canEdit}
          >
            <input
              type="file"
              onChange={(e) => handleUploadDocument(e, document)}
              className={classes.dropdownInput}
            />
            <span style={{ marginRight: 8, fontSize: 12, fontWeight: 600 }}>
              UPLOAD DOCUMENT
            </span>
            <Icon icon="uploadIcon" color="rgba(0, 0, 0, 0.26)" />
          </Button>
        </span>
      </>
    );
  };
  return (
    <div className={classes.addNoteContainer} data-testid="addnote-container">
      <TextField
        id="outlined-full-width"
        placeholder="Send a new message..."
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          style: {
            fontSize: '0.75rem',
            letterSpacing: 0.55,
            lineHeight: '0.75rem',
          },
        }}
        variant="outlined"
        value={noteValue}
        onChange={(e) => setNoteValue(e.target.value)}
        multiline
        textSize="sm+"
        rows={3}
        style={{ marginTop: 0 }}
      />
      {fileToShow && (
        <FileList
          documents={[fileToShow]}
          handleUploadDocument={handleUploadDocument}
          notesDoc
          clearFile={clearFile}
          showClearOption
        />
      )}
      {!fileToShow && addDoc()}
      <Button
        variant="contained"
        color="primary"
        className={classes.addButton}
        disabled={!(noteValue || fileToShow)}
        onClick={() => handleSubmit()}
      >
        SEND
      </Button>
    </div>
  );
};

export default AddNote;
