import React, { useEffect } from 'react';

import { useTheme } from '@material-ui/core/styles';
import { Card, CardContent, makeStyles, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Text from 'components/TextFields/Text';
import DraftIcon from 'assets/icons/submit-draft.png';
import AcceptedIcon from 'assets/icons/submit-accept.png';
import RejectedIcon from 'assets/icons/submit-rejected.png';

const useStyles = makeStyles((theme) => ({
  card: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    position: 'relative',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

const items = {
  pending: {
    icon: DraftIcon,
    title: 'Almost there!',
    desc:
      'Your Application is being reviewed by our PowerPay \nAnalyst.  We may request extra information from you.',
  },
  accepted: {
    icon: AcceptedIcon,
    title: 'Great news!',
    desc:
      'Your application has been approved, you’re \njust click aways from starting your project',
  },
  rejected: {
    icon: RejectedIcon,
    desc:
      'We’re sorry to inform you that your application has been rejected, \nknow more details about it in your Application Details',
  },
};

const ConfirmSubmit = ({ status, id }) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const DynamicConfirm = ({ icon, title, desc }) => (
    <Box
      paddingBottom="4rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img src={icon} className={classes.image} />
      {
        title && 
        <Text
          textSize="cardTitle"
          textAlign="center"
          bold
          textColor={palette.text.primary}
        >
          {title}
        </Text>
      }
      <Text
        textSize="cardSubtitle"
        textAlign="center"
        textColor={palette.text.secondary}
        style={{ marginTop: '1rem', whiteSpace: 'pre-line' }}
      >
        {desc}
      </Text>
    </Box>
  );

  return (
    <Card elevation={4} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <DynamicConfirm {...items[status]} />
          {id && (
            <Link
              component={NavLink}
              to={`/applications/${id}`}
              style={{ width: '100%', maxWidth: '25rem' }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  padding: '0.5rem 0',
                  width: '100%',
                  borderRadius: '4px',
                }}
              >
                <Text bold textColor="white">
                  REVIEW APPLICATION
                </Text>
              </Button>
            </Link>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ConfirmSubmit;
