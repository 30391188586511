import React, { useEffect, useState } from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import Text from 'components/TextFields/Text';

import { SignatureField } from 'components/InputFields/SignatureField';
import Loader from 'components/Loaders';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: '2rem',
    [theme.breakpoints.up('md')]: {
      paddingRight: '4rem',
    },
  },
  text: {
    paddingLeft: '1rem',
    [theme.breakpoints.down('xs')]: { paddingLeft: '0.25rem' },
  },
  button: {
    height: 40,
    width: '24rem',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
}));

export const Step7LegalTerms = ({
  isActive,
  setChildCallbacks,
  isDealer,
  onApplicationonSubmit,
}) => {
  const [isSending, setIsSending] = useState(false);
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({});
    }
    return () => {};
  }, [isActive]);

  const { palette } = useTheme();
  const theme = useTheme();
  const classes = useStyles();

  return isSending ? (
    <Loader width="100%" height="32rem" />
  ) : (
    <Grid container>
      {isDealer && (
        <Grid item xs={12}>
          <Text textColor={palette.text.primary} textSize="sm+" semibold>
            Homeowner Agreement
          </Text>
          <Text textColor={palette.text.secondary} textSize="xs+" semibold>
            This step will ensure that your Homeowner agrees with PowerPay Terms
            and Conditions.
          </Text>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" className={classes.container} marginTop="2rem">
          <CheckIcon width="0.75rem" color="primary" />
          <Text
            textSize="sm+"
            textcolor={palette.text.primary}
            className={classes.text}
            semibold
          >
            I&apos;ve read, understand, accept, and Consent to the terms of the
            Account Opening Disclosures, Electronic Records and Communication,
            Privacy Policy, Demand Response Terms of Service, and Installation
            and Services Agreement and agree to receive electronic disclosures
            with any loan obtained as a result of this application. If a joint
            application is filed, these Terms of Use apply to each of the
            applicants equally: I authorize POWERpay and/or any Lender in its
            Lender Network, its representatives, successors, and assigns to
            investigate my creditworthiness and obtain a credit report from me
            for any lawful purpose, including, but not limited to, any extension
            of credit, renewal, servicing and collections. Upon request, Lender
            will inform me of whether a credit report was obtained and if a
            report was obtained, the names and addresses of the credit reporting
            agencies that furnished the report.
            <br />
            <br />I authorize POWERpay to contact me at the phone number I
            provided via text, an automated telephone dialing system, or
            artificial or prerecorded voice messages, for any purpose. This is
            not required to apply. I can opt-out by contacting POWERPay at
            800-397-4485
          </Text>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        {isDealer && (
          <Text textColor={theme.palette.text.primary} textSize="sm+" semibold>
            Client’s Signature
          </Text>
        )}
        <Text
          textColor={theme.palette.text.secondary}
          textSize="xs+"
          semibold
          style={{ marginBottom: '1rem' }}
        >
          By signing below, you agree to all our Terms and Conditions.
        </Text>
        <SignatureField
          id="homeownerSignature"
          name="application.homeownerSignature"
          setSaveCallback={() => {}}
        />
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" marginTop="3rem">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            type="button"
            onClick={() => {
              setIsSending(true);
              onApplicationonSubmit();
            }}
          >
            <Text textSize="md" bold>
              SUBMIT
            </Text>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
