import React, { useState } from 'react';
import Icon from 'components/Icon';
// import {ContentCopyOutlinedIcon} from '@material-ui/icons/ContentCopyOutlined';
import SettingsSection from 'components/SettingsSection';
import Text from 'components/TextFields/Text';

import {
  Button,
  makeStyles,
  TextField,
  Grid,
  Tooltip,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import {
  StepSubtitle,
  StepTitle,
} from 'pages/NewApplicationDealer/DealerSection';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '40px',
  },

  cardButton: {
    borderColor: '#F79D3C',
    borderWidth: '1.5px',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const getText = (dealerCode, dealerLevelRole, openApiKeyDetails) => {
  const classes = useStyles();
  const { setAlertMessage } = useAlertContext();
  const [passwordInput, setPasswordInput] = useState('');
  const [showApi, setShowApi] = useState('password');
  const togglePasswordReveal = (button) => {
    switch (button) {
      case 'passwordInput':
        if (showApi === 'password') {
          setShowApi('text');
        } else {
          setShowApi('password');
        }
        break;
      default:
        return null;
    }
    return null;
  };
  const handleTokenChange = (e) => {
    setPasswordInput(e.target.value);
  };

  switch (dealerLevelRole) {
    case 'affiliate':
      return (
        <>
          <StepTitle>API Documentation</StepTitle>
          <StepSubtitle>Please review our API documentation.</StepSubtitle>
          <br></br>
          <span>
            <Button
              color="primary"
              variant="outlined"
              className={classes.cardButton}
              type="button"
              component="span"
              onClick={(e) => {
                const url = 'https://getpowerpay.readme.io';
                window.open(url, '_blank');
              }}
            >
              <span
                style={{
                  marginRight: 8,
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                Read Documentation
              </span>
            </Button>
          </span>

          <br></br>
          <br></br>
          <br></br>
          <StepTitle>API Authorization Token</StepTitle>
          <StepSubtitle>
            Your authorization token should be safeguarded and kept secret at
            all times.
          </StepSubtitle>
          <br></br>
          <Grid item xs={12} md={4}>
            {openApiKeyDetails &&
              openApiKeyDetails[0] !== '' &&
              openApiKeyDetails[1] === true && (
                <TextField
                  disabled="true"
                  className={classes.inputField}
                  id="apiToken"
                  label="API TOKEN"
                  variant="outlined"
                  value={openApiKeyDetails[0]}
                  type={showApi}
                  onChange={handleTokenChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => togglePasswordReveal('passwordInput')}
                          onMouseDown={() =>
                            togglePasswordReveal('passwordInput')
                          }
                        >
                          {showApi === 'password' ? (
                            <Visibility
                              style={{
                                color: '#A4B0BE',
                              }}
                            />
                          ) : (
                            <VisibilityOff
                              style={{
                                color: '#A4B0BE',
                              }}
                            />
                          )}
                        </IconButton>
                        <Tooltip title="Click to copy Api Token">
                          <Button
                            style={{
                              minWidth: '35px',
                              padding: '0px',
                            }}
                            onClick={(e) => {
                              var copyText = document.getElementById(
                                'apiToken',
                              );
                              copyText.select();
                              copyText.setSelectionRange(0, 99999);
                              navigator.clipboard.writeText(copyText.value);
                              setAlertMessage('Api token copied to clipboard');
                            }}
                          >
                            <Icon icon="copy" />
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            {openApiKeyDetails &&
              openApiKeyDetails[0] !== '' &&
              openApiKeyDetails[1] === false && (
                <StepSubtitle>Your API access is under review.</StepSubtitle>
              )}
            {!openApiKeyDetails && (
              <StepSubtitle>
                Please contact us to request API access.
              </StepSubtitle>
            )}
          </Grid>
        </>
      );
    default:
      return 0;
  }
};

const ApiDocumentation = () => {
  const classes = useStyles();
  const { user, dealerLevelRole } = useUserContext();

  return (
    <SettingsSection className={classes.dealerCodeSection}>
      <Text textSize="lg" textColor="primary">
        {getText(
          user?.data?.attributes?.dealerCode,
          dealerLevelRole,
          user?.data?.attributes?.openApiKeyDetails,
        )}
      </Text>
    </SettingsSection>
  );
};

export default ApiDocumentation;
