import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import { useHistory } from 'react-router-dom';
import { ModalProvider } from 'services/contexts/useModalContext';
import { HelpCard } from '../HelpCard';
import Banner from './Banner';
import DealerCode from '../../Settings/DealerCode';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '24px',
  },
  cards: {
    alignItems: 'center',
    display: 'flex',
    margin: '1%',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  card: {
    height: 207,
    margin: '1%',
    paddingTop: 16,
    paddingLeft: 24,
    position: 'relative',
    width: 359,
  },
  cardTitle: {
    maxWidth: 207,
  },
  cardTitleVariant: {
    maxWidth: 228,
  },
  cardText: {
    marginTop: 9,
    maxWidth: 216,
  },
  cardTextVariant: {
    marginTop: 9,
    maxWidth: 185,
  },
  link: {
    marginTop: 10,
  },
  icon: {
    display: 'none',
    position: 'absolute',
    bottom: -20,
    right: -25,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconVariant: {
    display: 'none',
    position: 'absolute',
    bottom: -25,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  iconMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -12,
    right: -12,
    [theme.breakpoints.up('md')]: {
      bottom: -15,
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  iconVariantMobile: {
    display: 'block',
    position: 'absolute',
    bottom: -10,
    right: 0,
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { user, userStatus } = useUserContext(); // userStatus is now unused, keeping prior further decision
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  if (user?.data?.type !== 'dealer') {
    setErrorAlertMessage('Unauthorized Access');
    history.replace('/dashboard');
  }
  // This user flow is now disabled as per the implementation of VIS-1594
  // const dealerStatus = userStatus;
  // const completedBusiness = user.data.meta.completedBusiness;
  // let showCompleteAccount = !completedBusiness;
  // if (dealerStatus === 'approved') {
  //   showCompleteAccount = false;
  // }
  return (
    <Grid container spacing={3} className={classes.container}>
      {/* This user flow is now disabled as per the implementation of VIS-1594 */}
      {/* {showCompleteAccount && ( */}
      {/*  <Grid item xs={12}> */}
      {/*    <ModalProvider> */}
      {/*      <Banner /> */}
      {/*    </ModalProvider> */}
      {/*  </Grid> */}
      {/* )} */}
      <Grid item xs={12}>
        <HelpCard title="Need help?" subheader="We're here to assist." />
      </Grid>
      <Grid item xs={12} >
        <DealerCode />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
