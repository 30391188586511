import React, { useState, useEffect } from 'react';
import { camelizeKeys, decamelizeKeys } from 'humps';
import normalize from 'json-api-normalize';
import to from 'await-to-js';
import { protectedGet, protectedPost, protectedPatch } from 'services/http';
import { addIndex, map, mergeDeepWith } from 'ramda';

import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SimpleModal from 'components/Modal';
import { StepsForm } from 'components/StepsForm';
import ConfirmSubmit from 'components/ConfirmSubmit';

import { useUserContext } from 'services/hooks/useUser';
import { buildFormData } from 'services/utils';
import { useModalContext } from 'services/contexts/useModalContext';
import { format, parseISO } from 'date-fns';

import { patchBusinessByID } from 'services/api/business';
import { useAlertContext } from 'components/AlertContext';
import Loader from 'components/Loaders';
import { useSessionExpirerContext } from 'components/SessionExpirerContext';
import Onboarding from './Onboarding';
import ValidatePhone from './ValidatePhone';
import CompanyDetails from './CompanyDetails';
import IncomeVerification from './IncomeVerification';
import LegalRepresentatDetails from './LegalRepresentatDetails';
import BankInformation from './BankInformation';

const useStyles = makeStyles((theme) => ({
  validatePhoneContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    borderRadius: '4px',
    width: '90%',
    maxWidth: '30rem',
    height: '35rem',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '-6px -8px 10px 0 rgba(116,125,140,0.1), 6px 8px 10px 0 rgba(116,125,140,0.1)',
    outline: 0,
  },
  stepsFormContainer: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    borderRadius: '4px',
    width: '90vw',
    maxWidth: '64rem',
    height: '90vh',
    overflowY: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      '-6px -8px 10px 0 rgba(116,125,140,0.1), 6px 8px 10px 0 rgba(116,125,140,0.1)',
    outline: 0,
  },
}));

const applicationStateMerger = (state, res) => {
  const merger = (left, right) => {
    if (left === undefined || left === null) {
      return right;
    }
    if (Array.isArray(left)) {
      // financial Details
      return [
        mergeDeepWith(merger, left[0], right[0]),
        mergeDeepWith(merger, left[1], right[1]),
      ];
    }
    return left;
  };
  return mergeDeepWith(merger, res, state);
};

const newApplication = {
  companyDetails: {
    companyType: 'public_business',
    legalBusinessName: '',
    EIN: '',
    bankAccountNumber: '',
    bankRoutingNumber: '',
  },
  legalRepresentatDetails: {
    license: null,
    businessOwnerPolicy: null,
    certificateOfInsurance: null,
    COIExpirationDate: null,
  },
  isIncomeVerified: false,
};
// WARD About the new bank acccount fields
const steps = [
  {
    component: (props) => <CompanyDetails {...props} />,
    title: 'Company Details',
    subtitle:
      'If you are having issues completing this step, please contact your sales \nrepresentative or email app.support@getpowerpay.com',
  },

  {
    component: (props) => <LegalRepresentatDetails {...props} />,
    title: 'Verification Documents',
    subtitle:
      'If you are having issues completing this step, please contact your sales \nrepresentative or email app.support@getpowerpay.com',
  },
  {
    component: (props) => <BankInformation {...props} />,
    title: 'Bank Account Information',
    subtitle:
      'If you are having issues completing this step, please contact your sales \nrepresentative or email app.support@getpowerpay.com',
  },
  {
    component: (props) => <IncomeVerification {...props} />,
    title: 'Identity and Bank Account Verification',
    subtitle:
      'If you are having issues completing this step, please contact your sales \nrepresentative or email app.support@getpowerpay.com',
  },
];

export default function CompleteAccountSetupForm() {
  const [currentPage, setCurrentPage] = useState('stepsForm');
  const [isLoading, setIsLoading] = useState(false);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const { user } = useUserContext();
  const classes = useStyles();
  const theme = useTheme();

  const handleOnboardingActionClick = () => {
    setCurrentPage('stepsForm');
  };

  const handleNext = () => {};

  const [initialValues, setInitialValues] = useState(newApplication);
  const sendRequest = async (values) => {
    const [error, res] = await patchBusinessByID(
      user.data.relationships.business.data.id,
      values,
    );
    if (error && error.response) {
      setErrorAlertMessage(error.response.data.message || 'Error');
      return null;
    }
    // setAlertMessage('Data Saved Succesfully');
    return res;
  };
  const handleAccountSetupDraft = async (values, methods, ignoreAlert) => {
    const r = await sendRequest(values);

    if (!ignoreAlert) {
      // fetch();
      setAlertMessage('Draft saved successfully');
    }
  };

  const handleAccountSetupSubmit = async (values) => {
    const r = await sendRequest(values);
    setCurrentPage('formSubmit');
    setAlertMessage('Account setup completed successfully');
  };

  const { setSessionExpiredCallback } = useSessionExpirerContext();
  const onExpireCb = handleAccountSetupDraft;
  const { open: isModalOpen } = useModalContext();
  useEffect(() => {
    if (!isModalOpen) {
      setSessionExpiredCallback(null);
    }
    return () => {
      setSessionExpiredCallback(null);
    };
  }, [isModalOpen, currentPage]);

  useEffect(() => {
    if (isModalOpen) fetch();
  }, [isModalOpen]);
  const fetch = async () => {
    setIsLoading(true);
    const [err, business] = await to(
      protectedGet(
        `/v1/businesses/${user?.data?.relationships?.business?.data?.id}`,
      ),
    );
    if (err) {
      console.error(err);
      return null;
    }
    const res = normalize(camelizeKeys(business.data)).get([
      'annualValue',
      'legalName',
      'businessType',
      'completed',
      'einSsnn',
      'id',
      'name',
      'ceoDriverLicense',
      'ownerPolicy',
      'workerComp',
      'address.streetAddress',
      'lastStep',
      'coiExpirationDate',
      'bankAccountNumber',
      'bankRoutingNumber',
    ]);
    setInitialValues((state) =>
      applicationStateMerger(state, {
        companyDetails: {
          companyType: res.businessType,
          legalBusinessName: res.legalName,
          EIN: res.einSsnn,
          EIN2: res.einSsnn,
          bankAccountNumber: res.bankAccountNumber,
          bankRoutingNumber: res.bankRoutingNumber,
        },
        legalRepresentatDetails: {
          license: res.ceoDriverLicense,
          businessOwnerPolicy: res.ownerPolicy,
          certificateOfInsurance: res.workerComp,
          COIExpirationDate:res.coiExpirationDate?res.coiExpirationDate.split('T')[0]:res.coiExpirationDate
          // COIExpirationDate:format(
          //   parseISO(res.coiExpirationDate),
          //   'MM/dd/yyyy',
          // )
        },
        isIncomeVerified: false,
      }),
    );
    setIsLoading(false);
  };
  return (
    <SimpleModal>
      <Box className={classes.stepsFormContainer}>
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            flex="1"
            height="100%"
            width="100%"
            paddingTop="1rem"
          >
            <Loader height="100%" width="100%" />
          </Box>
        ) : (
          <>
            {currentPage === 'validatePhone' && <ValidatePhone />}
            {currentPage === 'onboarding' && (
              <Onboarding handleActionClick={handleOnboardingActionClick} />
            )}
            {currentPage === 'stepsForm' && (
              <StepsForm
                onSubmit={handleAccountSetupSubmit}
                onSaveDraft={handleAccountSetupDraft}
                steps={steps}
                initialValues={initialValues}
                onExpireCb={onExpireCb}
              />
            )}
            {currentPage === 'formSubmit' && <ConfirmSubmit status="pending" />}
          </>
        )}
      </Box>
    </SimpleModal>
  );
}
