import { findIndex, propEq } from 'ramda';

export function useGlobalTableMethods(rows, setRows) {
  const updateRow = (rowData) => {
    const index = findIndex(propEq('id', rowData.id), rows);
    setRows([
      ...rows.slice(0, index),
      rowData,
      ...rows.slice(index + 1, rows.length),
    ]);
  };

  return {
    updateRow,
  };
}
