import React from 'react';
import { Route, Redirect, useHistory, useParams } from 'react-router-dom';
import { UserConsumer } from './UserContext';
import { Dashboard } from '../templates/Dashboard';
import {
  getToken,
  getRedirectPage,
  setRedirectPage,
} from '../services/authentication/index';

export default function ProtectedRoute({
  path,
  title,
  comp: Component,
  headerActions: HeaderActions,
  ...rest
}) {
  let openDrawer = true;
  const history = useHistory();
  if (title === 'New Application') {
    openDrawer = false;
  }
  if (path?.includes('apply')) {
    const dealerCode = rest.computedMatch.params.dealerCode;
    if (dealerCode) {
      sessionStorage.setItem('dealerCode', dealerCode);
    }
    sessionStorage.setItem('applyPage', 'true');
    history.push('/signout');
  }
  const userToken = getToken();
  if (userToken === null || userToken === undefined) {
    setRedirectPage(rest.location.pathname);
  }
  const redirectPage = getRedirectPage();
  return (
    <Route
      {...rest}
      path={path}
      exact
      strict
      render={(props) => (
        <>
          <UserConsumer>
            {({ user }) => {
              const userData = JSON.parse(user);
              if (user) {
                if (
                  sessionStorage.getItem('passwordExpiry') === 'true' &&
                  !props?.location.pathname.includes('/settings')
                ) {
                  return <Redirect to="/settings" />;
                }
                return (
                  <Dashboard
                    title={title}
                    Component={Component}
                    HeaderActions={HeaderActions}
                    userType={userData.data.type}
                    openDrawer={openDrawer}
                    {...props}
                  />
                );
              }
              if (
                sessionStorage.getItem('passwordExpiry') === 'true' &&
                !props?.location.pathname.includes('/settings')
              ) {
                return <Redirect to="/signin" />;
              }

              return <Redirect to="/signin" />;
            }}
          </UserConsumer>
        </>
      )}
    />
  );
}
