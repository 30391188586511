import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateData } from 'redux/actions';
import { Grid, Box, TextField } from '@material-ui/core';
import { StepSubtitle, StepTitle } from './DealerSection';
import { useStyles } from "./useStyles";

const MerchantReference = (props) => {
  // can't delete, used in the consumer form
  const classes = useStyles();
  const { appData } = props;
  const [showMerchantReference , setShowMerchantReference] = useState(false);
  return (
    <>
      <Grid container spacing={3} rowSpacing={3}>
        <Grid item sm={12} xs={12}>
          <Box
            pt={6}
            pb={3}
            style={{
              paddingBottom: '0px',
            }}
          >
            <StepTitle>Merchant Reference ID</StepTitle>
            <StepSubtitle>A reference ID be added to the application.</StepSubtitle>
          </Box>
        </Grid>
        {showMerchantReference && (
          <Grid item sm={12} xs={12} md={6} lg={6}>
            <TextField
              fullWidth
              className={classes.formControlInput}
              label="Enter Merchant Reference ID"
              type="string"
              value={appData?.merchant_ref_id}
              onChange={(e) => {
                props.updateData({
                  name: 'merchant_ref_id',
                  value: e.target.value,
                });
              }}
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>

      <Box pt={1}>
        <button
          onClick={() => {
            if (showMerchantReference) {
              setShowMerchantReference(false);
              props.updateData({
                name: 'merchant_ref_id',
                value: '',
              });
            } else {
              setShowMerchantReference(true);
            }
          }}
          style={{
            padding: '0px',
          }}
          type="button"
          className={classes.transparentButton}
        >
          {!showMerchantReference
            ? 'Want to add a reference ID?'
            : 'Want to remove the reference ID?'}
        </button>
      </Box>
    </>
  );
};

const mapStatesToProps = (state) => {
  return {
    message: state.appReducer.message,
    appData: state.appReducer,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(MerchantReference);
