import React from 'react';

export default function index({ width, height }) {
  return (
    <svg
      width={width || '20px'}
      height={height || '18px'}
      viewBox="0 0 20 18"
      version="1.1"
    >
      <defs>
        <path
          d="M10.833 10.667A4.167 4.167 0 0 1 15 14.833V16.5a.833.833 0 1 1-1.667 0v-1.667a2.5 2.5 0 0 0-2.5-2.5H4.167a2.5 2.5 0 0 0-2.5 2.5V16.5A.833.833 0 0 1 0 16.5v-1.667a4.167 4.167 0 0 1 4.167-4.166zm5.027.733a.833.833 0 0 1 1.015-.599A4.167 4.167 0 0 1 20 14.833V16.5a.833.833 0 1 1-1.667 0v-1.666a2.5 2.5 0 0 0-1.875-2.419.833.833 0 0 1-.598-1.015zM7.5.667A4.167 4.167 0 1 1 7.5 9a4.167 4.167 0 0 1 0-8.333zm5.026.735A.833.833 0 0 1 13.54.8a4.167 4.167 0 0 1 0 8.073.833.833 0 1 1-.413-1.615 2.5 2.5 0 0 0 0-4.843.833.833 0 0 1-.601-1.014zM7.5 2.333a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5z"
          id="a"
        />
      </defs>
      <use fill="currentColor" xlinkHref="#a" fillRule="evenodd" />
    </svg>
  );
}
