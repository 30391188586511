import React from 'react';
import { reduce } from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import PulseLoader from 'react-spinners/PulseLoader';
import Text from 'components/TextFields/Text';
import EmptyImage from 'assets/icons/empty-img.png';
import { TableContent } from './TableContent';
import { MobileList } from './MobileList';

export const PageTable = (props) => {
  const {
    rows = [],
    isLoading,
    totalItems = 0,
    page = 0,
    perPage = 10,
    setPage,
    setPerPage,
    emptyStateTitle,
    emptyStateDesc,
    selected = {},
    setSelected = () => {},
    order,
    setOrder,
    sort,
    setSort,
    onRowClick = () => {},
  } = props;
  const theme = useTheme();

  const handleClick = (event, name) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [name]: !prevSelected[name],
    }));
    event.currentTarget.blur();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = order === property && sort === 'asc';
    setSort(isAsc ? 'desc' : 'asc');
    setOrder(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = reduce(
        (obj, i) => {
          return { ...obj, [i]: true };
        },
        {},
        rows.map((item, index) => index),
      );

      setSelected(newSelecteds);
      return;
    }
    setSelected({});
  };
  const handleChangePage = (event, p) => {
    setPage(p);
  };
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const isSelected = (name) => Boolean(selected[name]);

  const emptyRows = perPage - Math.min(perPage, totalItems - page * perPage);
  const  perPageOptions = [10,25];
  const generatedProps = {
    order,
    sort,
    selected,
    //
    handleClick,
    handleRequestSort,
    handleSelectAllClick,
    handleChangePage,
    handleChangeRowsPerPage,
    isSelected,
    emptyRows,
    onRowClick,
  };

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          flex="1"
          height="100%"
          width="100%"
          paddingTop="1rem"
        >
          <PulseLoader color={theme.palette.primary.light} size="1rem" />
        </Box>
      ) : totalItems > 0 ? (
        <>
          <Hidden mdDown>
            <TableContent {...generatedProps} {...props} 
             perPageOptions = {perPageOptions}/>
          </Hidden>
          <Hidden lgUp>
            <MobileList {...generatedProps} {...props} />
          </Hidden>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex="1"
          height="100%"
          width="100%"
          paddingBottom="5%"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            maxWidth="24rem"
          >
            <img
              alt="No Items"
              src={EmptyImage}
              height="156px"
              width="172px"
              style={{ marginRight: '24px' }}
            />
            <Text
              textSize="lg"
              textColor={theme.palette.text.primary}
              bold
              textAlign="center"
              style={{ margin: '0.25rem 0' }}
            >
              {emptyStateTitle}
            </Text>
            <Text
              textSize="sm"
              textColor={theme.palette.text.primary}
              textAlign="center"
            >
              {emptyStateDesc}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
};
