export function notifyErrorWithRedirect(
  err,
  errorAlertMessage,
  history,
  redirectPage,
) {
  if (err.message.includes('404')) {
    errorAlertMessage(
      'You do not have access to the page or the page does not exist.',
    );
    history.replace(redirectPage);
  }
  if (err.message.includes('401')) {
    errorAlertMessage('You do not have access to the page or session expired.');
  }
}
