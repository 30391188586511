import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { Formik, Form } from 'formik';
import { makeStyles, Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';
import { useUserContext } from 'services/hooks/useUser';
import { useStateWithCallback } from 'services/hooks/useStateWithCallback';
import { useAlertContext, useErrorAlertMessage } from 'components/AlertContext';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { format } from 'date-fns';
import Details from './Details';
import Sidebar from './Sidebar';
import { protectedGet, protectedPost } from '../../../services/http';
import Loader from '../../../components/Loaders';
import { parseApplication } from '../../../services/utils/parseApplication';
import { parseLoan } from '../../../services/utils/parseLoan';
import { parseDealStructure } from '../../../services/utils/parseDealStructure';
import { ConfirmationDialog } from '../../../components/ConfirmationDialog';
import { notifyErrorWithRedirect } from '../../../services/utils/notifyErrorWithRedirect';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    height: '100vh',
    padding: '36px 24px',
    width: '100%',
  },
});

const getInitialValues = (loanDetails) => {
  return {
    validBaseProjectAmount: false,
    baseProjectAmount: loanDetails?.baseAmount || '',
    stageFunding: loanDetails?.stageFunding || false,
    dealStructId: loanDetails?.dealStructId || '',
    buydown: loanDetails?.buydown || false,
    buydownPoints: loanDetails?.buydownPoints || '',
    stipPay: loanDetails?.stipPay || false,
    rebates: loanDetails?.rebates || false,
    firstStage: loanDetails?.firstStage,
    secondStage: loanDetails?.secondStage,
    thirdStage: loanDetails?.thirdStage,
    projectType: loanDetails?.projectType || '',
    unitDescription: loanDetails?.unitDescription || '',
    remoteMonitoring: loanDetails?.monitoringTerm || '',
    remoteMonitoringCost: loanDetails?.monitoringAmount || '',
    serviceAgreement: loanDetails?.serviceAgreeTerm || '',
    serviceAgreementCost: loanDetails?.serviceAgreeAmount || '',
    manufacturerWarranties: loanDetails?.manufacturerTerm || '',
    manufacturerWarrantiesCost: loanDetails?.manufacturerAmount || '',
    ohNo: loanDetails?.ohNo || false,
    ohNoMonths: loanDetails?.ohNoMonths || '',
    equalPay: loanDetails?.equalPay || false,
    equalPayMonths: loanDetails?.equalPayMonths || '',
    signatureMethod: loanDetails?.signatureMethod || 'email',
  };
};

const ApplicationDetail = ({ match, history }) => {
  const isChangeOrderPageRequested = sessionStorage.getItem('changeOrderTab');
  const isRefundPageRequested = sessionStorage.getItem('refundTab');
  sessionStorage.removeItem('changeOrderTab');
  sessionStorage.removeItem('refundTab');
  const applicationNumber = match.params.id;
  const classes = useStyles();

  const [dialogState, setDialogState] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState(null);
  const [application, setApplication] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [term, setTerm] = useState(loanDetails?.term ?? null);
  const [stageFunding, setStageFunding] = useState(
    loanDetails ? loanDetails.stageFunding : false,
  );
  // WARD de aqui viene el term
  const [createLoanEnvelope, setCreateLoanEnvelope] = useState(false);
  const [sendedLoanDocs, setSendedLoanDocs] = useState(false);
  const [processingLoanDocs, setProcessingLoanDocs] = useState(false);
  const [sendingApp, setSendingApp] = useState(false);
  const [hasLoan, setHasLoan] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [fetchedStatus, setFetchedStatus] = useState(true);
  const [detailTab, setDetailTab] = useState(0);
  const { setErrorAlertMessage } = useAlertContext();
  const { user, dealerLevelRole } = useUserContext();
  const [dealerStructData, setDealerStructData] = useState(
    dealerLevelRole !== 'super_admin'
      ? user.data.attributes.activeDealStructs
      : null,
  );
  const [loanDocsSent, setloanDocsSent] = useState(false);
  const [changeOrderDetails, setChangeOrderDetails] = useState([]);
  const [originalLoanDetails, setOriginalLoanDetails] = useState([]);
  const [changeOrderTab, setChangeOrderTab] = useState(false);
  const [refundTab,setRefundTab] = useState(false);
  const [isConditionalApproval, setConditionalApproval] = useState(false);
  const [rawApplicationsEndpointResponse, setRawApplicationsEndpointResponse] = useState(null)
  // update loanpayload in state along with callback using hook
  const [loanPayload, setLoanPayload] = useStateWithCallback(
    null,
    (loanPayload) => {
      if (isConditionalApproval && !loanDocsSent) {
        onOpenCoditionalDisclaimerDialog();
      } else {
        if (loanPayload && !loanDocsSent) submitLoanData(); // submit loan data if loandocs not sent
        if (loanPayload && loanDocsSent) onOpenResendDocsDialog(); // open confirmation dialog if loandocs need to be resent
      }
    },
  );

  const errorAlertMessage = useErrorAlertMessage();
  useEffect(() => {
    (async () => {
      try {
        if (!fetchedData) {
          fetchData();
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [fetchedData]);
  const fetchData = async () => {
    const [err, rawApplication] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
      ),
    );
    if (err) {
      notifyErrorWithRedirect(err, errorAlertMessage, history, '/applications');
      return;
    }
    setRawApplicationsEndpointResponse(rawApplication)
    const [application, formattedApp] = parseApplication(rawApplication.data);

    if (application.status === 'transferred') {
      errorAlertMessage(
        'This application has been transferred and is no longer available. For more details, please contact PowerPay at 800-397-4485.',
      );
      return history.goBack();
    }

    setApplicationDetail(formattedApp);
    setApplication(application);
    const { data, error } = await protectedGet(
      `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/change_orders`,
    );
    setChangeOrderDetails(data?.data);
    if (
      dealerLevelRole === 'super_admin' &&
      rawApplication?.data?.data?.attributes?.dealer_network_id
    ) {
      const dealerStructData = await protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/dealer_networks/${rawApplication?.data?.data?.attributes?.dealer_network_id}/deal_structs?member_id=${rawApplication?.data?.data?.relationships?.dealer?.data?.id}`,
      );

      const [structuredDealStructs] = parseDealStructure(dealerStructData.data);
      setDealerStructData(structuredDealStructs);
    }
    if (formattedApp.headerData.status === 'conditional_approval') {
      setConditionalApproval(true);
    }
    if (application?.loan?.id) {
      const rawLoanDetails = await protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${application.loan.id}`,
      );
      setloanDocsSent(true); // enable loandocsSent flag if loandocs sent already
      const [loan, formattedLoan] = parseLoan(rawLoanDetails.data);
      if (
        loan.status === 'in_progress' ||
        loan.status === 'completed' ||
        loan.status === 'change_requested'
      ) {
        setDetailTab(changeOrderTab || isChangeOrderPageRequested ? 3 : 2);
      } else {
        // history.push(`/applications/${application?.id}/review`);
        setDetailTab(changeOrderTab || isChangeOrderPageRequested ? 3 : 1);
      }
      if(isRefundPageRequested){
        setDetailTab(4);
      }
      if (loan.pacSignatureMethod) {
        const [err, rawApplication] = await to(
          protectedGet(
            `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
          ),
        );
        if (err) {
          notifyErrorWithRedirect(
            err,
            errorAlertMessage,
            history,
            '/applications',
          );
          return;
        }
        const [application, formattedApp] = parseApplication(
          rawApplication.data,
        );
        setApplicationDetail(formattedApp);
        setApplication(application);
      }
      setOriginalLoanDetails(rawLoanDetails);
      setHasLoan(true);
      setCreateLoanEnvelope(true);
      setLoanDetails(formattedLoan);
      setTerm(loan.termYears);
      setStageFunding(loan.stageFunding);
      // docs are generated async - so we need to poll backend for when they're ready
      if (loan.signatureMethod === 'physical') {
        setProcessingLoanDocs(true);

        let retries = 0;
        const interval = 5000; // 5 seconds
        const maxRetries = 36; // try for three minutes (I think this should be plenty - in testing it seems to take about ~10-15 seconds)

        const isDownloadReady = () => {
          const check = async () => {
            const [err, response] = await to(
              protectedGet(
                `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${loan.id}/sign_download`,
              ),
            );

            return err ? false : response.data.ready;
          };

          check().then((ready) => {
            if (ready) {
              setProcessingLoanDocs(false);
            } else if (retries > maxRetries) {
              setErrorAlertMessage(
                'Please try again...documents failed to generate',
              ); // this shouldn't happen in practice
            } else {
              retries++;
              setTimeout(isDownloadReady, interval);
            }
          });
        };

        isDownloadReady();
      }
      if (
        loan.pacSignatureMethod === 'platform' &&
        sessionStorage.getItem('signedPac') === 'true'
      ) {
        let retries = 0;
        setFetchedStatus(false);
        sessionStorage.removeItem('signedPac');
        const interval = 5000; // 5 seconds
        const maxRetries = 36; // try for three minutes (I think this should be plenty - in testing it seems to take about ~10-15 seconds)

        const isStatusChanged = () => {
          const check = async () => {
            let statusReady = false;

            const rawLoanDetails = await protectedGet(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${application.loan.id}`,
            );
            setloanDocsSent(true); // enable loandocsSent flag if loandocs sent already
            const [loan, formattedLoan] = parseLoan(rawLoanDetails.data);
            if (loan.status === 'finished') {
              const [err, rawApplication] = await to(
                protectedGet(
                  `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
                ),
              );
              if (err) {
                notifyErrorWithRedirect(
                  err,
                  errorAlertMessage,
                  history,
                  '/applications',
                );
                return;
              }
              const [application, formattedApp] = parseApplication(
                rawApplication.data,
              );
              setApplicationDetail(formattedApp);
              setApplication(application);
              if (
                loan.status === 'in_progress' ||
                loan.status === 'completed' ||
                loan.status === 'change_requested'
              ) {
                setDetailTab(
                  changeOrderTab || isChangeOrderPageRequested ? 3 : 2,
                );
              } else {
                // history.push(`/applications/${application?.id}/review`);
                setDetailTab(
                  changeOrderTab || isChangeOrderPageRequested ? 3 : 1,
                );
              }
              setOriginalLoanDetails(rawLoanDetails);
              setHasLoan(true);
              setCreateLoanEnvelope(true);
              setLoanDetails(formattedLoan);
              setTerm(loan.termYears);
              setStageFunding(loan.stageFunding);

              statusReady = true;
            }
            return statusReady;
          };

          check().then((statusReady) => {
            if (statusReady) {
              setFetchedStatus(true);
            } else if (retries > maxRetries) {
              setErrorAlertMessage(
                'Please try again...documents failed to generate',
              ); // this shouldn't happen in practice
            } else {
              retries++;
              setTimeout(isStatusChanged, interval);
            }
          });
        };

        isStatusChanged();
      }
      setFetchedData(true);
      setShowLoader(false);
    } else if(formattedApp.headerData?.draftStatus === "canceled" ||formattedApp.headerData?.status === "rejected" || formattedApp.headerData?.status === "expired" ){
      setFetchedData(true);
      setShowLoader(false);
  }
  else{
    history.push(`/applications/${application?.id}/review`);
  }
  }
  // SPLIT UP handleSubmit() FUNCTION INTO TWO
  // IN handleSubmit() WE ARE GETTING FORM VALUES AND SETTING IN STATE
  const handleSubmit = (values) => {
    const payload = {
      loan: {
        base_amount: Number(values.baseProjectAmount) || 0,
        term_years: Number(term),
        warranty: true,
        stage_funding: stageFunding,
        first_stage:
          stageFunding && values.firstStage
            ? String(format(new Date(values.firstStage), 'yyyy-MM-dd'))
            : '',
        second_stage:
          stageFunding && values.secondStage
            ? String(format(new Date(values.secondStage), 'yyyy-MM-dd'))
            : '',
        third_stage:
          stageFunding && values.thirdStage
            ? String(format(new Date(values.thirdStage), 'yyyy-MM-dd'))
            : '',
        project_type: values.projectType,
        unit_description: values.unitDescription,
        monitoring_term: Number(values.remoteMonitoring),
        monitoring_amount: Number(values.remoteMonitoringCost),
        service_agree_term: Number(values.serviceAgreement),
        service_agree_amount: Number(values.serviceAgreementCost),
        manufacturer_term: Number(values.manufacturerWarranties),
        manufacturer_amount: Number(values.manufacturerWarrantiesCost),
        buydown: Boolean(values.buydown),
        buydown_points: Number(values.buydownPoints),
        stip_pay: Boolean(values.stipPay),
        rebates: Boolean(values.rebates),
        oh_no: Boolean(values.ohNo),
        oh_no_months: Number(values.ohNoMonths),
        equal_pay: Boolean(values.equalPay),
        equal_pay_months: Number(values.equalPayMonths),
        signature_method: values.signatureMethod,
      },
      deal_struct_id: +values.dealStructId,
    };
    setLoanPayload(payload); // setting the payload data in state
  };

  // api calls from handleSubmit() moved here
  const submitLoanData = async () => {
    setSendingApp(true);
    const [err, rawLoanDetails] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans`,
        loanPayload,
      ),
    );

    if (err) {
      setSendingApp(false);
      onCloseResendDocsDialog();
      onCloseOpenCoditionalDisclaimerDialog();
      setErrorAlertMessage(
        err?.response?.data?.message ||
          'Error while updating application. Please try again.',
      );
      return;
    }

    // make sure we have most recent loan number
    const rawApplication = await protectedGet(
      `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
    );
    const [application, formattedApp] = parseApplication(rawApplication.data);
    setApplicationDetail(formattedApp);

    setHasLoan(true);
    setFetchedData(false);
    setSendingApp(false);
    onCloseResendDocsDialog();
    onCloseOpenCoditionalDisclaimerDialog();
  };

  const handleStartProject = async () => {
    const missingDoc = applicationDetail.applicationData.requestedDocs.some(
      (doc, i) => !applicationDetail.applicationData?.documents?.[i]?.url,
    );

    if (missingDoc) {
      setErrorAlertMessage('Please Upload All Required Documents');
      return;
    }

    try {
      await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${loanDetails.id}/start`,
      );
      setFetchedData(false);
    } catch ({ response }) {
      if (response) {
        if (response?.data?.message) {
          setErrorAlertMessage(response.data.message[0]);
        }
      }
    }
  };
  const [selectedDS, setSelectedDS] = useState('');
  useEffect(() => {
    if (dealerStructData) {
      setSelectedDS(
        dealerStructData.find((DS) => DS.id === loanDetails?.dealStructId),
      );
    }
  }, [dealerStructData]);
  const {
    isOpen: isResendDocsDialogOpen,
    onOpen: onOpenResendDocsDialog,
    onClose: onCloseResendDocsDialog,
  } = useDisclosure(false);
  const {
    isOpen: isOpenCoditionalDisclaimerDialogOpen,
    onOpen: onOpenCoditionalDisclaimerDialog,
    onClose: onCloseOpenCoditionalDisclaimerDialog,
  } = useDisclosure(false);
  return (
    <div className={classes.container}>
      {}
      {fetchedData && fetchedStatus && applicationDetail && !sendingApp ? (
        <>
          <Formik
            initialValues={getInitialValues({ ...selectedDS, ...loanDetails })}
            onSubmit={handleSubmit}
          >
            {({ values, setValues }) => (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8}>
                    <Details
                      {...{
                        applicationNumber,
                        applicationDetail,
                        loanDetails,
                        history,
                        detailTab,
                        term,
                        setTerm,
                        setStageFunding,
                        stageFunding,
                        values,
                        hasLoan,
                        setFetchedData,
                        createLoanEnvelope,
                        changeOrderDetails,
                        originalLoanDetails,
                        setChangeOrderTab,
                        setRefundTab,
                        dealerStructData,
                        rawApplicationsEndpointResponse
                      }}
                      handleClickOpen={() => setDialogState(true)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Sidebar
                      application={application}
                      applicationNumber={applicationDetail?.applicationData?.id}
                      applicationStatus={applicationDetail?.headerData?.status}
                      documents={applicationDetail.applicationData}
                      draftStatus={applicationDetail?.headerData?.draftStatus}
                      signatureMethod={loanDetails?.signatureMethod}
                      loanContractSignatureMethods={
                        applicationDetail?.loanContractSignatureMethods
                      }
                      openStipulations={
                        applicationDetail?.applicationData?.openStipulations
                      }
                      closedStipulations={
                        applicationDetail?.applicationData?.closedStipulations
                      }
                      hasLoan={hasLoan}
                      handleStartProject={handleStartProject}
                      loanId={loanDetails?.id}
                      loan={loanDetails}
                      projectStatus={loanDetails?.status}
                      sendedLoanDocs={sendedLoanDocs}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      processingLoanDocs={processingLoanDocs}
                      setFetchedData={setFetchedData}
                      dealStructs={dealerStructData}
                      createLoanEnvelope={createLoanEnvelope}
                      selectedLoan={selectedDS}
                      updateLoan={() => {
                        if (hasLoan) {
                          if (
                            loanDetails.dealStruct &&
                            dealerStructData.findIndex(
                              (dealStruct) =>
                                parseInt(dealStruct.id, 10) ===
                                parseInt(loanDetails.dealStruct.id, 10),
                            ) === -1
                          ) {
                            setValues(getInitialValues());
                          }
                          setHasLoan(false);
                        } else {
                          handleSubmit();
                        }
                      }}
                      values={values}
                      handleLoanCreation={() => {
                        setCreateLoanEnvelope(true);
                        setDetailTab(1);

                        // When sending the loan docs in a vertical orientation, the button
                        // gets placed at bottom of page however the action the user needs
                        // to take are at top of page.
                        window.scroll({ top: 0, behavior: 'smooth' });
                      }}
                      handleSendLoanDocs={async () => {
                        try {
                          await protectedPost(
                            `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${loanDetails.id}/send_contract`,
                          );
                          setFetchedData(false);
                          setSendedLoanDocs(true);
                        } catch (e) {
                          console.error(e);
                        }
                      }}
                      changeOrderDetails={changeOrderDetails}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <ConfirmationDialog
            desc="Attention :"
            subDesc="Loan docs have already been generated for this application. Do you want to proceed with new loan documentation?"
            dialogState={isResendDocsDialogOpen}
            handleClose={() => onCloseResendDocsDialog()}
            handleCancel={() => submitLoanData()}
          />
          <ConfirmationDialog
            desc="Attention :"
            subDesc="The application is in “Conditional Approval” status. You will not be able to progress the project until the application is “Approved”. Do you want to proceed ?"
            dialogState={isOpenCoditionalDisclaimerDialogOpen}
            handleClose={() => onCloseOpenCoditionalDisclaimerDialog()}
            handleCancel={() => submitLoanData()}
          />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ApplicationDetail;
