import React, { useState, useContext } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import to from 'await-to-js';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loaders';
import Text from '../../components/TextFields/Text';
import ContainedButton from '../../components/Buttons/ContainedButton';
import PasswordField from '../../components/InputFields/PasswordField';
import UserContext from '../../components/UserContext';

import http, { protectedGet } from '../../services/http';
import { setToken } from '../../services/authentication';

const validationSchema = yup.object({
  password: yup.string().required('Password is required'),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref('password')], 'Both password need to be the same'),
    }),
});

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formTitle: {
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 56,
    },
  },
}));

const SetPassword = ({ location, history }) => {
  const [showPassword, setShowPassword] = useState('password');
  const [showConfirmPassword, setShowConfirmPassword] = useState('password');

  const classes = useStyles();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async (values, setSubmitting, setErrors) => {
    const passwordToken = location.search.split('=');
    const payload = {
      reset_password_token: passwordToken[1],
      password: values.password,
      password_confirmation: values.confirmPassword,
    };
    try {
      const { data } = await http.put(
        `${process.env.REACT_APP_BASE_URL}/set_password`,
        payload,
      );
      setToken(JSON.stringify(data));
      setUser(JSON.stringify(data));
      sessionStorage.setItem('flow', 'new');
      setSubmitting(false);
      let newWflow = false;
      const [err, res] = await to(protectedGet(`/v1/settings`));
      if (err) {
        return;
      }
      if (res) {
        res.data.data.map((featureFlags) => {
          if (featureFlags?.attributes?.key === 'feature.new_app_workflow') {
            if (featureFlags?.attributes?.value) {
              newWflow = true;
            }
          }
          return '';
        });
        if (newWflow) {
          sessionStorage.setItem('flow', 'new');
        } else {
          sessionStorage.setItem('flow', 'new');
        }
      }
      if (data.data.type === 'dealer') {
        history.replace('/dealer/dashboard');
        return;
      }
      if (data.data.meta) {
        if (data.data.type === 'homeowner') {
          if (data.data.meta.applications_count === 0) {
            history.replace('/applications/onboarding');
            return;
          }
        }
      }
      history.replace('/dashboard');
    } catch (err) {
      if (err.response.status === 422) {
        var message = err.response.data.message.replace('Validation failed: ','');
        setErrors({ confirmPassword: message });
      } else {
        setErrors({ confirmPassword: 'Oops!, an error occurred, try again' });
      }
      setSubmitting(false);
    }
  };

  const togglePasswordReveal = button => {
    if (button === 'password') {
      if (showPassword === 'password') {
        setShowPassword('text');
      } else {
        setShowPassword('password');
      }
      return showPassword;
    }
    if (showConfirmPassword === 'password') {
      setShowConfirmPassword('text');
    } else {
      setShowConfirmPassword('password');
    }
    return showConfirmPassword;
  };

  return (
    <div className={classes.contentWrapper}>
      <Header />
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          handleSubmit(values, setSubmitting, setErrors);
        }}
      >
        {({ isSubmitting, values, touched, errors }) =>
          isSubmitting ? (
            <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
          ) : (
            <Form className={classes.form}>
              <div className={classes.formTitle}>
                <Text
                  textVariant="h5"
                  textColor="#000000"
                  textFontWeight={600}
                  textFontSize={22}
                  textLetterSpacing={0.5}
                  textLineHeight="28px"
                  text="Welcome to Powerpay!"
                />
                <Text
                  textVariant="body1"
                  textColor="rgba(30, 39, 46, 0.6)"
                  textFontWeight={500}
                  textFontSize={16}
                  textLetterSpacing={0.6}
                  textLineHeight="22px"
                  text="You are one click away to start your loan process"
                />
              </div>
              <Text
                textVariant="body1"
                textColor="#636A72"
                textFontWeight={600}
                textFontSize={12}
                textLetterSpacing={0.6}
                textLineHeight="16px"
                text="CREATE A NEW PASSWORD"
              />
              <PasswordField
                showPassword={showPassword}
                togglePasswordReveal={() => togglePasswordReveal('password')}
                touched={touched}
                errors={errors}
                name="password"
                label="Password"
              />
              <PasswordField
                showPassword={showConfirmPassword}
                togglePasswordReveal={() =>
                  togglePasswordReveal('confirmPassword')
                }
                touched={touched}
                errors={errors}
                name="confirmPassword"
                label="Confirm Password"
              />

              <ContainedButton
                className={classes.button}
                type="submit"
                text="DONE"
                variant="contained"
                color="primary"
                isSubmitting={isSubmitting}
                disabledOptions={values}
                errors={errors}
              />
            </Form>
          )
        }
      </Formik>

      <Footer />
    </div>
  );
};

export default SetPassword;
