import React, { useEffect, useState } from 'react';
import to from 'await-to-js';
import {
  makeStyles,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Box,
  useTheme,
  Grid,
} from '@material-ui/core';

import Launch from '@material-ui/icons/Launch';
import Info from '@material-ui/icons/Info';
import { protectedGet, protectedPut } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { useFormikContext } from 'formik';
import HelloSign from 'hellosign-embedded';
import { useErrorAlertMessage } from 'components/AlertContext';
import { Stipulations } from './stipulations';
import { FileList } from './FileList';
import { InactiveFileList } from "./InactiveFileList";
import { Notices } from "./notices";
import {format} from "date-fns-tz";
import {toCapitalCase} from "./utils";

export const useLoanEnvelopeSign = (appId, loanId) => {
  const client = new HelloSign({
    clientId: process.env.REACT_APP_HELLO_SIGN_API_KEY, // debug: true
  });

  // TODO: WE NEED TO HANDLE THESE TRANSITIONS BETTER - IT IS VERY CLUNKY
  client.on('finish', (data) => {
    window.location.reload();
  });

  client.on('cancel', (data) => {
    window.location.reload();
  });

  const onSignEnvelope = async (isCoapp = false) => {
    const [err, res] = await to(
      protectedGet(
        `/v1/applications/${appId}/loans/${loanId}/sign_url?is_coapp=${isCoapp}`,
      ),
    );
    if (err) {
      return;
    }
    client.open(res.data.sign_url, { skipDomainVerification: true });
  };

  const onDownloadEnvelope = async () => {
    const [err, res] = await to(
      protectedGet(`/v1/applications/${appId}/loans/${loanId}/sign_download`),
    );

    return err || window.open(res.data.download_url);
  };

  return {
    onSignEnvelope,
    onDownloadEnvelope,
  };
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#636A72',
    lineHeight: '18px',
    letterSpacing: 0.5,
    paddingTop: 12,
    fontSize: '.875rem'
  },
  cardDarken: {
    border: 'solid 0.5px rgb(193,201,209)',
    borderRadius: 4,
    minHeight: 76,
    marginTop: 12,
    marginBottom: 0,
    padding: 12,
    height: '120px'
  },
  card: {
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    borderRadius: 4,
    minHeight: 76,
    marginTop: 11,
    marginBottom: 12,
    padding: 12,
  },
  cardTitle: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginBottom: 8,
  },
  cardButton: {
    borderColor: '#F79D3C',
    borderWidth: '1.5px',
    marginLeft: '10%',
    marginTop: '3.5%',
    width: '80%',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
  stips: {
    paddingTop: 12,
  },
  divider: {
    backgroundColor: '#F3F4F4',
    marginTop: 12,
    marginBottom: 12,
  },
  importantInfo: {
    backgroundColor: '#F9FAFC',
    borderRadius: 4,
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0.5,
    marginBottom: 14,
    padding: 10,
  },
  importantInfoComplement: {
    color: '#1E272E',
    display: 'block',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.46,
  },
  importantInfoComplementList: {
    color: '#1E272E',
    display: 'block',
    fontSize: '10px',
    fontWeight: '400',
    lineHeight: '12px',
    marginLeft: '8px',
    marginBottom: '4px',
    letterSpacing: '0.46px',
    '&::before': {
      width: '8px',
      height: '8px',
      content: '""',
      position: 'relative',
      left: 'calc(-8px / 2)',
      display: 'inline-block',
      borderRadius: '8px',
      top: '0',
      background: 'rgb(145, 152, 160)',
    },
  },
  createLoanButton: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  editLoan: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 600,
    marginTop: 24,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    width: '100%',
  },
  logContainer: {
    backgroundColor: '#F9FAFC',
    borderRadius: 6,
    padding: 9,
    marginBottom: 9,
  },
  logItem: {
    backgroundColor: 'white',
    border: 'solid 0.5px #C1C9D1',
    borderRadius: 4,
    marginBottom: '12px',
  },
  logContentWrapper: {
    marginLeft: 8,
    minWidth: 0,
    wordBreak: 'break-word',
    padding: '0.25rem 0.25rem 0.25rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logInformation: {
    color: 'rgba(0,0,0)',
    fontSize: 12,
    letterSpacing: 0.46,
    lineHeight: '12px',
  },
  logContent: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginBottom: 10,
  },
  logContentRead: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  innerContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    width: '100%',
  },
}));

export const ImportantInfoCard = ({ title, message }) => {
  const classes = useStyles();
  return (
    <Typography className={classes.importantInfo}>
      {title && (
        <>
          <Info style={{ marginRight: 7, color: '#9198A0', fontSize: 13 }} />
          {title}
        </>
      )}
      {message && Array.isArray(message) ? (
        message.map((stub, index) => (
          <span key={index} className={classes.importantInfoComplementList}>
            {stub}
          </span>
        ))
      ) : (
        <span className={classes.importantInfoComplement}>{message}</span>
      )}
    </Typography>
  );
};

export const canEdit = (status, changeOrderCheck) => {
  if (changeOrderCheck) {
    if (status === 'in_progress' || status === 'approved') {
      return true;
    }
    if (status === 'completed' || status === 'finished') {
      return false;
    }
    return true;
  }
  if (!changeOrderCheck) {
    if (
      status === 'in_progress' ||
      status === 'completed' ||
      status === 'finished'
    ) {
      return false;
    }
    return true;
  }
};
export const Documents = ({
  applicationNumber,
  documents,
  signatureMethod,
  fetchedData,
  signedCoapplicantNew,
  signedApplicantNew,
  isAppCanceled,
  loanId,
  closedStipulations,
  openStipulations,
  projectStatus,
  setFetchedData,
  processingLoanDocs,
  changeOrderDetails,
  showLoader,
  setShowLoader,
  newFlow,
  notices,
  hasSelectedDeal
}) => {
  const { user, userType } = useUserContext();
  const { values } = useFormikContext() || {};
  const theme = useTheme();
  const [showFileType, setShowFileType] = useState('');
  const [showNotificationLink, setShowNotificationLink] = useState('true');
  const [showOtherDocs, setShowOtherDocs] = useState(true);
  const classes = useStyles();

  const { onSignEnvelope } = useLoanEnvelopeSign(applicationNumber, loanId);
  const { onDownloadEnvelope } = useLoanEnvelopeSign(applicationNumber, loanId);
  const errorAlertMessage = useErrorAlertMessage();
  useEffect(() => {
    if (documents?.documents && documents?.documents.length === 1 && documents?.loanProductCode === 'healthcare' && documents?.documents[0]?.fileType === "install_contract" && userType === 'dealer' && documents?.documents[0]?.status === 'verified') {
      setShowOtherDocs(false);
    }
  }, [documents?.documents])
  const changeOrderCheck = changeOrderDetails?.find(
    (element) => element.attributes.status === 'IN PROGRESS',
  );
  const handleSubmit = async (payload, document) => {
    try {
      await protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/documents/${document.id}`,
        payload,
      );

    } catch (err) {
      const message = err?.response?.data?.message || 'file upload error';
      errorAlertMessage(message);
    }
    if (newFlow) {
      setFetchedData(true);
    }
    else {
      setFetchedData(false);
    }
  };

  const handleUploadDocument = (event, document) => {
    setShowLoader(true);
    setShowFileType(document?.file_type || document.fileType);
    const documentType = document.fileType;
    const file = event.target.files[0];
    const data = new FormData();
    data.append('application_id', Number(applicationNumber));
    data.append('document', file, file.name);
    data.append('file_type', documentType);
    handleSubmit(data, document);
  };
  let signedApplicant = documents?.signedApplicant;
  let signedCoapplicant = documents?.signedCoapplicant;
  if (newFlow) {
    signedApplicant = signedApplicantNew;
    signedCoapplicant = signedCoapplicantNew;
  }
  const importantInfoMessages = [
    {
      title: 'What formats can I upload?',
      message:
        'For now, file formats for DL (jpg and png) and for other docs (jpg, png, and pdf).',
    },
  ];
  const inactiveDocs = documents?.inactiveDocuments || documents?.inactive_documents;
  return (
    <div className={classes.content}>
      <Typography className={classes.title}>Required Signatures</Typography>
      <div className={classes.card}>
        <Typography className={classes.cardTitle}>
          <b>Loan Envelope</b>
        </Typography>

        {signatureMethod === 'physical' && processingLoanDocs && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}

        {signatureMethod === 'physical' && !processingLoanDocs && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.cardButton}
            onClick={() => onDownloadEnvelope()}
            disabled={
              processingLoanDocs ||
              signedApplicant ||
              isAppCanceled ||
              projectStatus !== 'sent' ||
              (user.data.type !== 'homeowner' &&
                !user.data.meta.canManageResources)
            }
          >
            <span style={{ marginRight: 8, fontSize: 12, fontWeight: 600 }}>
              DOWNLOAD & SIGN
            </span>

            <Launch style={{ fontSize: 15 }} />
          </Button>
        )}

        {signatureMethod !== 'physical' && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.cardButton}
            onClick={() => onSignEnvelope(false)}
            disabled={
              !(signatureMethod === 'platform') ||
              signedApplicant ||
              isAppCanceled ||
              projectStatus !== 'sent' ||
              (user.data.type !== 'homeowner' &&
                !user.data.meta.canManageResources)
            }
          >
            <span style={{ marginRight: 8, fontSize: 12, fontWeight: 600 }}>
              APPLICANT SIGN
            </span>
            <Launch style={{ fontSize: 15 }} />
          </Button>
        )}

        {signedCoapplicant !== null && signatureMethod !== 'physical' && (
          <Button
            color="primary"
            variant="outlined"
            className={classes.cardButton}
            onClick={() => onSignEnvelope(true)}
            disabled={
              !(signatureMethod === 'platform') ||
              signedCoapplicant ||
              isAppCanceled ||
              projectStatus !== 'sent' ||
              (user.data.type !== 'homeowner' &&
                !user.data.meta.canManageResources)
            }
          >
            <span style={{ marginRight: 8, fontSize: 12, fontWeight: 600 }}>
              COAPPLICANT SIGN
            </span>
            <Launch style={{ fontSize: 15 }} />
          </Button>
        )}
      </div>


      {documents?.documents && documents?.documents.length > 0 && showOtherDocs && (
        <>
          <div className={classes.stips}>
            <Typography className={classes.title}>Other Documents</Typography>
            <FileList
              documents={documents?.documents}
              handleUploadDocument={handleUploadDocument}
              isAppCanceled={isAppCanceled}
              showLoader={showLoader}
              showFileType={showFileType}
              hasTicketsForApplicant={documents?.hasTicketsForApplicant}
              loanProductCode={documents?.loanProductCode}
              canEdit={
                canEdit(projectStatus, changeOrderCheck) &&
                (user.data.type === 'homeowner' ||
                  user.data.meta.canManageResources)
              }
            />
          </div>
          <Divider className={classes.divider} />
        </>
      )}

      {(documents?.enableInactiveDocsSection || documents?.enable_inactive_docs_section) && (inactiveDocs?.length > 0) && (
        <>
          <Typography className={classes.title}>Inactive Documents</Typography>
          <div className={classes.stips}>
            {inactiveDocs.map((document) => (
              <>
                <div
                  className={classes.logItem}
                  key={document?.id}
                  data-testid="viewcard-container"
                >
                  <div className={classes.logContentWrapper}>
                    <div className={classes.innerContentWrapper}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            data-testid="logItem"
                            className={classes.logContent}
                            style={{
                              marginBottom: '0px !important',
                            }}
                          >
                            <bold
                              style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                marginBottom: '0px !important',
                              }}
                            >
                              {toCapitalCase(document?.fileType || document?.file_type)}
                            </bold>
                          </Typography>
                        </Grid>
                      </Grid>

                      <Typography
                        data-testid="logItem"
                        className={classes.logContent}
                      >
                            <span style={{color: 'gray'}}>
                              {format(
                                new Date(
                                  document?.created_at ||
                                  document?.createdAt,
                                ),
                                'MM/dd/yyyy',
                                {
                                  timeZone: 'America/New_York',
                                },
                              )}
                            </span>
                      </Typography>
                    </div>
                  </div>
                  <>
                    <InactiveFileList
                      documents={[document]}
                      handleUploadDocument={handleUploadDocument}
                      isAppCanceled={isAppCanceled}
                    />
                  </>
                </div>
              </>
            ))
            }
          </div>
        </>
      )}


      {(openStipulations?.length > 0 || documents?.hasTicketsForApplicant || documents?.has_tickets_for_applicant) && (
        <>
          <Typography className={classes.title}>Open Stipulations</Typography>
          {(documents?.hasTicketsForApplicant || documents.has_tickets_for_applicant) && userType === 'dealer'  && (
            <div className={classes.cardDarken}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    data-testid="logItem"
                    className={classes.logContent}
                    style={{
                      marginBottom: '0px !important',
                      marginTop: "12px"

                    }}
                  >
                    <bold
                      style={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginBottom: '0px !important',
                        position: 'relative',
                        bottom: '14px',
                        right: '3px'
                      }}
                    >
                      Applicant Stipulations and Documents
                    </bold>
                  </Typography>
                  <Typography className={classes.title} style={{
                    fontSize: '14px', position: 'relative',
                    bottom: '24px', right: '3px'
                  }}>
                    There are documents and stipulations visible to the
                    applicant only. Please have them login into their
                    account to complete the process.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )
          }
          {openStipulations?.length > 0 && (
            <div className={classes.stips}>
              <Stipulations
                hasSelectedDeal={hasSelectedDeal}
                stipulations={openStipulations}
                handleUploadDocument={handleUploadDocument}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                showFileType={showFileType}
                showNotificationLink={showNotificationLink}
                loanProductCode={documents.loan_product_code}
              ></Stipulations>
            </div>
          )
          }

        </>
      )}

      {closedStipulations?.length > 0 && (
        <>
          <Typography className={classes.title}>Closed Stipulations</Typography>
          <div className={classes.stips}>
            <Stipulations
              stipulations={closedStipulations}
              handleUploadDocument={handleUploadDocument}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              showFileType={showFileType}
              loanProductCode={documents.loan_product_code}
            ></Stipulations>
          </div>
        </>
      )}

      {notices?.length > 0 && (
        <>
          <Typography className={classes.title}>Document Notifications</Typography>
          <div className={classes.stips}>
            <Notices
              notices={notices}
              handleUploadDocument={handleUploadDocument}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              showFileType={showFileType}
              loanProductCode={documents.loan_product_code}
            ></Notices>
          </div>
        </>
      )}

      {importantInfoMessages.map((data, index) => (
        <ImportantInfoCard
          key={index}
          title={data.title}
          message={data.message}
        />
      ))}
    </div>
  );
};

export default Documents;
