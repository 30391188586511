import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Slider, LinearProgress } from '@material-ui/core';
import { updateData } from 'redux/actions';
import { useAlertContext } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';
import { protectedPut, protectedGet } from '../../services/http/index';
import {
  CurrencyField,
  Select,
  castFormattedCurrencyStringToNumber,
  formatNumberToCurrencyString,
} from './CustomizedMUIInputs';
import './ContractFormLayout.css';

/** @description the menu on the left that appears on the deals page. (Keep in mind, the "Deals" page doesn't exist, its an amalgam of @see ApplicationWrapper , this component, and @see DealStructCards )  */
const ContractFormLayout = ({ dealsData, dealStructData, dealStructIncluded, appId, updateData, setAdvancedFundingState }) => {
  const { user } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();

  const [requestAmount, setRequestAmount] = useState((dealsData?.request_amount_contract && formatNumberToCurrencyString(dealsData.request_amount_contract)) ? formatNumberToCurrencyString(dealsData.request_amount_contract) : ""); /** @type {string} USD currency format string */

  const [selectedBuyDown, setSelectedBuyDown] = useState(0); /** @type {number} percentage of buyDown. Since the first deals fetch is done with buyDown=0, it starts at 0 regardless of deal information (@see ApplicationWrapper.js ) */
  const [availableBuyDownPoints, setAvailableBuyDownPoints] = useState([0, 1, 2, 3]) /** @type {number[]} The potential available buyDownPoints. Default is 0-3, but it can be overriden */

  const [stagedFunding, setStagedFunding] = useState(Boolean(dealsData?.stagedFunding || user?.data?.attributes?.defaultStageFunding)); /** @type {boolean} (weirdly enough, of the 3 loan option dropdowns, this is the only one that actually gets read by @see ApplicationWrapper when doing its endpoint, thus we'll also read it here. The other ones should be considered as false) */
  const [stipPay, setStipPay] = useState(false) /** @type {boolean} i think we're no longer using stipPay, I may delete it if i can */

  const [sortCriteria, setSortCriteria] = useState('Lowest Payment') /** @type {string} one of: ['Lowest Payment', 'Highest Payment'] */

  const [isFetchingDeals, setIsFetchingDeals] = useState(false); /** @type {boolean} whether or not we're currently fetching the deals endpoint. Disables inputs and renders the loading bar. */
  const [isFetchingSettings, setIsFetchingSettings] = useState(false); /** @type {boolean} whether or not we're currently fetching the settings endpoint. Disables inputs and renders the loading bar. */

  const isFetching = useMemo(() => {
    return (isFetchingDeals || isFetchingSettings)
  }, [isFetchingDeals, isFetchingSettings])

  /**
   * Reminder of how staged funding works:
   * if enableStageFunding (obtained from fetching settings) is true:
   *  projectAmount - downPayment = requestAmount
   *
   * When enableStageFunding goes from false to true, projectAmount will be initialized to requestAmount's value, and any subsequent value of requestAmount will be projectAmount - downPayment
   */
  const [enableStageFunding, setEnableStageFunding] = useState(false);
  const [projectAmount, setProjectAmount] = useState("");
  const [downPayment, setDownPayment] = useState("");

  /** a consequence of moving to using useEffect is a lack of distinction between the first render and onChange. We need this to make sure we're not triggering the backend call on first render for no reason, we already have the data. */
  const skipFetchWhenInitialOptionsDropdownsRenders = useRef(true);

  /** this one also gets re-set whenever we update requestAmount based on backend's response. (Which only happens on load, due to shenaningans and implementation details) */
  const skipFetchWhenRequestAmountChanges = useRef(true);

  /** @description a collection of useMemos and a useCallback to handle the creation of the customError for the request amount input. They're set as useMemo and useCallback so they don't get computed every 3 seconds. */
  const creditLimit = useMemo(() => {
    return dealStructData?.attributes.max_request_amount.cents / 100
  }, [dealsData?.appData?.data?.attributes?.credit_limit])

  const minRequestAmount = useMemo(() => {
    return dealStructData?.attributes.min_request_amount.cents / 100
  }, [dealStructData?.attributes?.min_request_amount?.cents])

  const isRequestAmountValid = useCallback((value) => {
    const valueAsNumber = castFormattedCurrencyStringToNumber(value);

    if (creditLimit && valueAsNumber > creditLimit) return false;
    if (minRequestAmount && valueAsNumber < minRequestAmount) return false;
    return true;
  }, [creditLimit, minRequestAmount])

  const requestAmountCustomError = useMemo(() => {
    /** @see CustomizedMUIInputs.js for API of customError */
    if (creditLimit || minRequestAmount) {
      return {
        validationFunction: isRequestAmountValid,
        errorMessage: "",
      };
    } else return null;
  }, [isRequestAmountValid]);

  /** @description fetch dealsData info from backend and update the redux store with it. (Why is it a put? idk, old code) */
  const putAndFetchDealsDataInformation = async () => {
    setIsFetchingDeals(true);
    const baseUrl = `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/${dealStructData.attributes.id}`;
    const applicationIdParam = `application_id=${appId}`;
    const projectAmountParam = `project_amount=${castFormattedCurrencyStringToNumber(requestAmount)}`;
    const stageFundedParam = `stage_funded=${stagedFunding}`;
    const stipPayParam = `stip_pay=${stipPay}`;
    const buydownPointsParam = `buydown_points=${selectedBuyDown}`;

    let downPaymentAmountParam = "";
    if (enableStageFunding && dealsData?.appData?.data?.attributes?.loan_product_code !== "healthcare") {
      downPaymentAmountParam = `down_payment_amount=${castFormattedCurrencyStringToNumber(downPayment)}`;
    }

    const url = `${baseUrl}?${applicationIdParam}&${projectAmountParam}&${stageFundedParam}&${stipPayParam}&${buydownPointsParam}&${downPaymentAmountParam}`;
    await protectedPut(url).then((response) => {
      updateData({
        name: 'dealStruct',
        value: response.data,
      });
      updateData({
        name: 'request_amount_contract',
        value: requestAmount,
      });
      updateData({
        name: 'projectAmount',
        value: requestAmount,
      });

      /** the following 3 updateDatas are exclusively because they're used in @see DealStructCards.js for highlighting text */
      updateData({
        name: 'buyDown',
        value: selectedBuyDown
      });
      updateData({
        name: 'stipPay',
        value: stipPay
      });
      updateData({
        name: 'stagedFunding',
        value: stagedFunding
      })
    }).catch((error) => {
      console.error(error)
      setErrorAlertMessage(
        error?.response?.data?.message || 'Error while Updating Deals.',
      );
    }).finally(() => {
      setIsFetchingDeals(false)
    })
  }

  /** @description re-fetch available deals whenever one of the dropdown options change */
  useEffect(() => {
    if (skipFetchWhenInitialOptionsDropdownsRenders.current) {
      skipFetchWhenInitialOptionsDropdownsRenders.current = false;
    } else {
      putAndFetchDealsDataInformation()
    }
  }, [selectedBuyDown, stagedFunding, stipPay])

  /** @description do a delayed fetch of available deals whenever the request amount changes and its valid */
  useEffect(() => {
    let timer;
    if (skipFetchWhenRequestAmountChanges.current) {
      skipFetchWhenRequestAmountChanges.current = false;
    } else {
      /**
       * Adding a small delay timer to make sure we're not pinging backend on every keystroke
       * I could've done a system that mimics the old behavior, getting called on blur.
       * But I hate the UX of that, as the user could be left wondering why nothing is happening until they click away
       */
        //@todo I think this could be solve using request cancellation on type. that way we prevent using this timer
    const secondsDelay = 2.5;
    timer = setTimeout(() => {
      putAndFetchDealsDataInformation()
    }, secondsDelay*1000);
    }

     return () => clearTimeout(timer);
  }, [requestAmount, downPayment])

  /** @description fetch settings on load */
  useEffect(() => {
    // I'm setting this variable to the context api so it gets loaded to the deal struct card component correctly
    // however this is a hack and should be removed

    updateData({
      name: 'stagedFunding',
      value: stagedFunding
    })

    async function getSettings() {
      setIsFetchingSettings(true);
      protectedGet(`/v1/settings`).then((response) => {
        const enableStageFunding = response.data.data.find(elem => elem.attributes.key.includes("down_payment_stage_funding_enable"))?.attributes.value;
        setEnableStageFunding(Boolean(enableStageFunding)) // default behavior if undefined should be false, this accounts for it
      }).catch((error) =>  {
        setErrorAlertMessage(
          'There was a problem getting the settings for the form. Using default behavior.',
        );
        console.error(error)
      }).finally(() => {
        setIsFetchingSettings(false);
      })
    }
    getSettings();
  }, []);

  useEffect(() => {
    if (enableStageFunding) {
      /** this should only ever happen when the settings endpoint replies, and the setting is set to true */
      const downPaymentFromFirstEndpointResponse = dealsData?.appData?.data?.attributes?.down_payment_amount;
      const newDownPayment = downPaymentFromFirstEndpointResponse ? formatNumberToCurrencyString(downPaymentFromFirstEndpointResponse) : ""
      const loan_product_is_healthcare = dealsData?.appData?.data?.attributes?.loan_product_code === "healthcare"

      const projectAmountTotalFromFirstEndpointResponse = dealsData?.appData?.data?.attributes?.project_amount_total;
      const newProjectAmount = projectAmountTotalFromFirstEndpointResponse ? formatNumberToCurrencyString(projectAmountTotalFromFirstEndpointResponse) : requestAmount;
      setProjectAmount(newProjectAmount);
      setDownPayment(loan_product_is_healthcare ? "" : newDownPayment);
    }
  }, [enableStageFunding])

  /** @description if enableStageFunding is true, projectAmount becomes a static field, calculated as requestAmount (now financedAmount) + downPayment */
  useEffect(() => {
    if (enableStageFunding) {
      const financedAmountAsNumber = castFormattedCurrencyStringToNumber(requestAmount) || 0; /** if enableStageFunding === true, requestAmount becomes financedAmount */
      const downPaymentAsNumber = castFormattedCurrencyStringToNumber(downPayment) || 0;

      const thereIsNoFinancedAmount = !requestAmount || requestAmount === "$0" || financedAmountAsNumber === 0

      if (thereIsNoFinancedAmount){
        if(projectAmount !== "") {
          setProjectAmount("")
        }
        return;
      }

      setProjectAmount(formatNumberToCurrencyString(financedAmountAsNumber + downPaymentAsNumber))
    }
  }, [requestAmount, downPayment])

  /** @description update availableBuyDownPoints with info from dealsData whenever it changes and its valid  */
  useEffect(() => {
    let buyDownPoints = dealsData?.dealStruct?.data?.attributes?.buydown_points_available;
    buyDownPoints = buyDownPoints ? [0, ...buyDownPoints] : null;

    if (buyDownPoints && Array.isArray(buyDownPoints) && buyDownPoints.length > 0){
      setAvailableBuyDownPoints(buyDownPoints.map(elem => Number(elem)));
    }
  }, [dealsData?.dealStruct?.data?.attributes?.buydown_points_available])

  /** @description update requestAmount if dealsData.request_amount_contract changes, its valid, and its different from requestAmount. (This is necessary because dealsData.request_amount_contract is undefined on first render) */
  useEffect(() => {
    if (!dealsData || !dealsData.request_amount_contract) return
    const formattedDealsDataRequestAmountContract = formatNumberToCurrencyString(dealsData.request_amount_contract);
    if (formattedDealsDataRequestAmountContract !== null && formattedDealsDataRequestAmountContract !== requestAmount){
      skipFetchWhenRequestAmountChanges.current = true
      setRequestAmount(formattedDealsDataRequestAmountContract)
    }
  }, [dealsData?.request_amount_contract])

  /** @description update sortDeals and maxDiscount in the redux store whenever sortCriteria is updated */
  useEffect(() => {
    updateData({
      name: 'sortDeals',
      value: sortCriteria,
    });
  }, [sortCriteria])


  return (
    <div className="contract-form">
      {isFetching && <LinearProgress />}
      <div className="contract-form_application-options">
        <h1>Loan Options</h1>
        {
          /* I decided to have a bit more code repetition to simplify later down the line if we want to remove the non-stage funding process */
          enableStageFunding ? <>
            <CurrencyField
              value={requestAmount}
              onChange={(newValue) => {
                setRequestAmount(newValue)
              }}
              label="Financed Amount (USD)"
              customError={(creditLimit || minRequestAmount) && requestAmountCustomError}
              disabled={isFetching}
              showErrorsWhileClean
            />
            { dealsData?.appData?.data?.attributes?.loan_product_code !== "healthcare"
                ? <>
                  <CurrencyField
                      value={downPayment}
                      onChange={(newValue) => {
                        setDownPayment(newValue);
                      }}
                      label="Down Payment (USD)"
                      disabled={isFetching}
                      showErrorsWhileClean
                  />
                  <CurrencyField
                      value={projectAmount}
                      onChange={(newValue) => {
                        /* no-op, leaving it just in case */
                      }}
                      label="Project Amount (USD)"
                      disabled={isFetching}
                      showErrorsWhileClean
                      readOnly
                  />
                </>
                : null
            }
          </> :
          <CurrencyField
            value={requestAmount}
            onChange={(value) => {
              setRequestAmount(value);
            }}
            label="Request Amount"
            customError={(creditLimit || minRequestAmount) && requestAmountCustomError}
            required={creditLimit || minRequestAmount}
            disabled={
              [undefined, null, NaN].includes(requestAmount) || isFetching
            }
            showErrorsWhileClean
          />
        }
        {dealStructData.attributes.buydown_available && (
          <Select
            label="Buy Down(%)"
            value={selectedBuyDown}
            onChange={(newValue) => setSelectedBuyDown(newValue)}
            options={availableBuyDownPoints.map((buyDownPercentage) => {
              return {
                id: `contract-form-layout-buydown-option-${buyDownPercentage}`,
                label: `${buyDownPercentage}%`,
                value: buyDownPercentage,
              };
            })}
            disabled={isFetching}
          />
        )}
        {dealStructData.attributes.stage_funding_available && (
          <Select
            label="Advance Funding"
            value={stagedFunding}
            onChange={(newValue) => {
                setStagedFunding(newValue);
                setAdvancedFundingState(newValue);
            }}
            options={[
              {
                id: 'contract-form-layout-advance-funding-option-yes',
                label: 'Yes',
                value: true,
              },
              {
                id: 'contract-form-layout-advance-funding-option-no',
                label: 'No',
                value: false,
              },
            ]}
            disabled={isFetching}
          />
        )}
        {dealStructData.attributes.stip_pay_available && (
          <Select
            label="Stip Pay"
            value={stipPay}
            onChange={(newValue) => setStipPay(newValue)}
            options={[
              {
                id: 'contract-form-layout-stip-pay-option-yes',
                label: 'Yes',
                value: true,
              },
              {
                id: 'contract-form-layout-stip-pay-option-no',
                label: 'No',
                value: false,
              },
            ]}
            disabled={isFetching}
          />
        )}
      </div>

      <div className="contract-form_sort-deals">
        <h1>Sort Deals</h1>
        <Select
          label="Monthly Payment"
          value={sortCriteria}
          onChange={(newValue) => setSortCriteria(newValue)}
          options={[
            {
              id: 'contract-form-layout-sort-criteria-lowest-payment',
              label: 'Lowest Payment',
              value: 'Lowest Payment',
            },
            {
              id: 'contract-form-layout-sort-criteria-highest-payment',
              label: 'Highest Payment',
              value: 'Highest Payment',
            },
          ]}
          disabled={isFetching}
        />
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    dealStructData: state.appReducer.dealStruct.data,
    dealStructIncluded: state.appReducer.dealStruct.included,
    appId: state.appReducer.appId,
  };
};

export default connect(mapStatesToProps, {
  updateData,
})(ContractFormLayout);
