import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const Loader = ({ height, width, size }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        height: height || '100vh',
        justifyContent: 'center',
        width: width || '100vw',
      }}
    >
      <ClipLoader color="#f89d3c" size={size || 72} />
    </div>
  );
};

export default Loader;
