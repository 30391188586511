import React from 'react';

import { makeStyles, Typography, Grid } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import { ssnFormat } from "./Homeowner";

const useStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  sectionTitle: {
    marginBottom: 24,
  },
  subsection: {
    marginTop: 24,
    marginBottom: 24,
  },
  subsectionItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subsectionItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 16,
  },
  dataItem: {
    color: '#1E272E',
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.65,
    lineHeight: '18px',
  },
  changeContactText: {
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    color: '#f79d3c',
    fontWeight: 500,
    cursor: 'pointer',
  },
  transparentButton: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
  },
}));

const CoApplicantTab = ({
  data,
  onUpdateCoApplicantDriverLicenseButtonClick,
  status,
}) => {
  const birthdayFormat = (birthday) => {
    const format = birthday.split('-');
    const newBirthday = `${format[1]}/${format[2]}/${format[0]}`;
    return newBirthday;
  };
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Text
          textColor="#1E272E"
          textFontSize={16}
          textFontWeight={600}
          textLetterSpacing={0.8}
          textLineHeight="16px"
          textVariant="subtitle1"
          text="COAPPLICANT"
        />
      </div>
      <div className={classes.subsection}>
        <Text
          textColor="#9198A0"
          textFontSize={10}
          textFontWeight={600}
          textLetterSpacing={0.46}
          textLineHeight="12px"
          textVariant="subtitle1"
          text="BASIC INFORMATION"
        />
        <div className={classes.subsectionItems}>
          <Typography className={classes.subsectionItem}>
            First Name
            <span className={classes.dataItem}>{data.firstname}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            Last Name
            <span className={classes.dataItem}>{data.lastname}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            Date of Birth
            <span className={classes.dataItem}>
              {birthdayFormat(data.birthday)}
            </span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            Social Security Number
            <span className={classes.dataItem}>{ssnFormat(data.ssn)}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            Active Military?
            <span className={classes.dataItem}>
              {data.activeMilitary ? 'Yes' : 'No'}
            </span>
          </Typography>
        </div>
      </div>
      <Grid container>
        <Grid item xs={12} xl={5}>
          <div className={classes.subsection}>
            <Text
              textColor="#9198A0"
              textFontSize={10}
              textFontWeight={600}
              textLetterSpacing={0.46}
              textLineHeight="12px"
              textVariant="subtitle1"
              text="CONTACT INFORMATION"
            />
            <div className={classes.subsectionItems}>
              <Typography className={classes.subsectionItem}>
                Email
                <span className={classes.dataItem}>{data.email}</span>
              </Typography>
              <Typography className={classes.subsectionItem}>
                Phone
                <span className={classes.dataItem}>{data.phone}</span>
              </Typography>
            </div>
          </div>
          <div className={classes.subsection}>
            <Text
              textColor="#9198A0"
              textFontSize={10}
              textFontWeight={600}
              textLetterSpacing={0.46}
              textLineHeight="12px"
              textVariant="subtitle1"
              text="Drivers License"
            />
            <div className={classes.subsectionItems}>
              <Typography className={classes.subsectionItem}>
                State
                <span className={classes.dataItem}>
                  {data.driverLicenseState}
                </span>
              </Typography>
              <Typography className={classes.subsectionItem}>
                Number
                <span className={classes.dataItem}>
                  {data.driverLicenseNumber}
                </span>
              </Typography>
            </div>
            {status !== 'canceled' && status !== 'rejected' && status !== 'expired' && (
              <button
                onClick={onUpdateCoApplicantDriverLicenseButtonClick}
                type="button"
                className={classes.transparentButton}
              >
                <span className={classes.changeContactText}>
                  Need to update coapplicant's drivers license?
                </span>
              </button>
            )}
          </div>
        </Grid>
        <Grid item xs={12} xl={7}>
          <div className={classes.subsection}>
            <Text
              textColor="#9198A0"
              textFontSize={10}
              textFontWeight={600}
              textLetterSpacing={0.46}
              textLineHeight="12px"
              textVariant="subtitle1"
              text="ADDITIONAL"
            />
            <div className={classes.subsectionItems}>
              <Typography className={classes.subsectionItem}>
                US Citizien?
                <span className={classes.dataItem}>
                  {data.usCitizen ? 'Yes' : 'No'}
                </span>
              </Typography>
              <Typography className={classes.subsectionItem}>
                Is an owner?
                <span className={classes.dataItem}>
                  {data.owner ? 'Yes' : 'No'}
                </span>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={classes.subsection}>
        <Text
          textColor="#9198A0"
          textFontSize={10}
          textFontWeight={600}
          textLetterSpacing={0.46}
          textLineHeight="12px"
          textVariant="subtitle1"
          text="ADDRESS"
        />
        <div className={classes.subsectionItems}>
          <Typography className={classes.subsectionItem}>
            Address Name
            <span className={classes.dataItem}>{data.addressName}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            Address Line 2
            <span className={classes.dataItem}>{data.addressName2}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            State
            <span className={classes.dataItem}>{data.state}</span>
          </Typography>
          <Typography className={classes.subsectionItem}>
            City
            <span className={classes.dataItem}>{data.city}</span>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CoApplicantTab;
