import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { Formik, Form, yupToFormErrors } from 'formik';

import to from 'await-to-js';
import { protectedGet } from 'services/http';

import { map, mergeDeepWith } from 'ramda';

import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Text from 'components/TextFields/Text';
import { StepHeader } from 'pages/NewApplication/StepHeader';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { merger, translateDealerRoleShort } from 'services/utils';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';
import Icon from 'components/Icon';
import { SelectField } from 'components/InputFields/SelectField';
import { MenuItem, Typography } from '@material-ui/core';

import {
  useNewItemStyles,
  NewItemWrapper,
  addUserToEntity,
} from 'pages/NewOrganization';
import { useUserContext } from 'services/hooks/useUser';
import { getApiURL } from 'pages/TeamMemberList';

const initialValuesObj = (isEdit) => ({
  isEdit,
  dealer: { name: '', lastName: '', email: '', phoneNumber: '', role: '' },
});

export const NewTeamMember = () => {
  const classes = useNewItemStyles();
  const { palette } = useTheme();
  const history = useHistory();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { user, dealerLevelRole, dealerLevelId } = useUserContext();

  const { memberId } = useParams();
  const isEdit = useRouteMatch('/team-members/:memberId/edit');
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const [validationErrors] = await to(
      validationSchema.validate(
        { ...values },
        {
          abortEarly: false,
        },
      ),
    );

    if (validationErrors) {
      setErrors(yupToFormErrors(validationErrors));
      return;
    }

    const userRes = await addUserToEntity({
      url: isEdit
        ? `${getApiURL(dealerLevelRole, dealerLevelId)}/${memberId}`
        : `${getApiURL(dealerLevelRole, dealerLevelId)}`,
      values,
      role: values.dealer.role,
      setSubmitting,
      setErrorAlertMessage,
      isUpdate: isEdit,
    });

    if (userRes) {
      if (isEdit) {
        setAlertMessage('Team Member Updated Successfully');
        history.replace('/team-members');
      } else {
        setAlertMessage('Team Member Created Successfully');
        history.replace('/team-members');
      }
    }
  };
  const [initialValues, setInitialValues] = useState(initialValuesObj(isEdit));
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    async function fetch() {
      const [err, res] = await to(protectedGet(`v1/dealer_networks/roles`));
      if (err) {
        return;
      }
      const dealerRoles = res?.data?.dealer_roles;
      if (dealerRoles.length) {
        const mappedDealerRoles = dealerRoles.map((str, index) => ({
          name: str,
          id: index + 1,
        }));
        setRoles(mappedDealerRoles);
      }
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      if (isEdit) {
        const [err, dealership] = await to(
          protectedGet(
            `${getApiURL(dealerLevelRole, dealerLevelId)}/${memberId}`,
          ),
        );
        if (err) {
          return;
        }
        const res = normalize(camelizeKeys(dealership.data)).get([
          'name',
          'lastName',
          'email',
          'phoneNumber',
          'role',
        ]);
        setInitialValues((state) => {
          return mergeDeepWith(merger, { dealer: res }, state);
        });
      }
    }
    if (memberId) {
      fetch();
    }
  }, [memberId]);
  return (
    <NewItemWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Box className={clsx(classes.stepForm, 'form-step')}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <IconButton
                        className={classes.backButton}
                        style={{ marginRight: '1rem' }}
                        onClick={() => history.goBack()}
                      >
                        <ChevronLeftIcon fontSize="large" />
                      </IconButton>
                      <StepHeader
                        title={
                          isEdit
                            ? `${initialValues.dealer.name} ${initialValues.dealer.lastName}`
                            : 'Add a New Team Member'
                        }
                        subtitle={
                          isEdit
                            ? 'Team Member'
                            : 'Fill out the information to add a new team member'
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={3}
                    className={classes.formContainer}
                    style={{
                      paddingTop: '32px',
                    }}
                  >
                    <Grid item xs={12} md={6}>
                      <OutlinedTextField
                        label="First Name"
                        name="dealer.name"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <OutlinedTextField
                        label="Last Name"
                        name="dealer.lastName"
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <OutlinedTextField label="Email" name="dealer.email" />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <PhoneNumberField
                        name="dealer.phoneNumber"
                        id="dealer.phoneNumber"
                        label="Phone Number"
                      />
                    </Grid>

                    {dealerLevelRole !== 'organization' &&
                      dealerLevelRole !== 'affiliate' && (
                        <Grid item xs={12} md={6}>
                          <SelectField
                            label="Roles"
                            name="dealer.role"
                            id="dealer.role"
                            type="text"
                            disabled={
                              dealerLevelRole === 'dealer_network_member' ||
                              dealerLevelRole === 'dealer_network_account_rep'
                            }
                            value={roles.length === 1 ? roles.name : null}
                          >
                            {roles.length >= 1 && (
                              <MenuItem value="">Select...</MenuItem>
                            )}
                            {roles.length > 0 &&
                              map(
                                (roles) => (
                                  <MenuItem
                                    key={roles.id}
                                    value={roles.name}
                                    disabled={roles.name === 'overseer'}
                                  >
                                    {translateDealerRoleShort(roles.name)}
                                  </MenuItem>
                                ),
                                roles,
                              )}
                          </SelectField>
                          <Typography className={classes.additionalInfo}>
                            If you need to make changes to the overseer, please
                            contact PowerPay.
                          </Typography>
                        </Grid>
                      )}
                  </Grid>

                  {!isEdit && (
                    <>
                      <Grid item xs={12} className={classes.formContainer}>
                        <Box
                          marginTop="2rem"
                          paddingY="1rem"
                          paddingX="0.5rem"
                          borderRadius="6px"
                          border={`solid 1px ${palette.divider}`}
                          display="flex"
                          alignItems="center"
                        >
                          <Icon icon="check-email" width={80} height={80} />
                          <Box marginLeft="0.5rem">
                            <Text
                              textSize="md+"
                              bold
                              textColor={palette.text.primary}
                            >
                              Invite this dealer to join Powerpay
                            </Text>
                            <Text
                              textSize="sm"
                              semibold
                              textColor={palette.text.secondary}
                            >
                              We’ll send an email to this dealer to create{' '}
                              <br />
                              an account and manage their own deals
                            </Text>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginTop="3rem"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <Text textSize="md" bold>
                          {isEdit ? 'SAVE' : 'ADD NEW TEAM MEMBER'}
                        </Text>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </NewItemWrapper>
  );
};

const validationSchema = Yup.object({
  dealer: Yup.object({
    name: Yup.string().required('Field Required').trim(),
    lastName: Yup.string().required('Field Required').trim(),
    email: Yup.string()
      .required('Field Required')
      .email('invalid Email')
      .trim(),
    phoneNumber: Yup.string()
      .matches(/^(?:\+1)?\(\d{3}\)\d{3}-\d{4}$/gm, {
        excludeEmptyString: true,
        message: 'Invalid Phone Number',
      })
      .trim()
      .nullable(),
  }),
});
