import React, { useState, useEffect } from 'react';
import { map, sortBy, prop } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import { protectedGet, protectedPost, protectedPut } from 'services/http';
import to from 'await-to-js';
import { fullName } from 'services/utils';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDealerListContext } from 'services/contexts/useDealerList';
import { useLoanProductListContext } from 'services/contexts/useLoanProductList';
import { useOrganizationListContext } from 'services/contexts/useOrganizationList';
import Box from '@material-ui/core/Box';
import Icon from 'components/Icon';
import Grid from '@material-ui/core/Grid';
import { Button, Modal } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Hidden from '@material-ui/core/Hidden';
import {
  PageTableHeader,
  StateFilter,
  QueryFilter,
  ColumnToggler,
} from 'components/PageTable/Header';
import { useAlertContext } from 'components/AlertContext';
import { AutocompleteFieldDropDown } from 'components/InputFields/AutocompleteFieldDropDown';
import { PageTable } from 'components/PageTable';
import { PageTableWrapper, tableStyles, clearFilters } from 'pages/ApplicationQueue';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { SelectedMenu } from 'components/PageTable/SelectedMenu';
import { SelectedMenuActionButton } from 'components/PageTable/SelectedMenu/ActionButton';
import { useRedirectOnDealerStatus } from 'services/hooks/useRedirectOnDealerStatus';
import { useTableSetup } from 'services/hooks/useTableSetup';
import usePageTableParameters from 'services/hooks/usePageTableParameters';
import { useGlobalTableMethods } from 'services/hooks/useGlobalTableMethods';
import { useDebounce } from 'services/hooks/useDebounce';
import { useUserContext } from 'services/hooks/useUser';
import { updateData } from 'redux/actions';
import { connect } from 'react-redux';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { values } from 'lodash';
import { useAffiliateListContext } from 'services/contexts/useAffiliateList';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";

const projectFields = [
  'ppId',
  'id',
  'status',
  'dealer.id',
  'dealer.name',
  'dealer.lastName',
  'dealer.businessName',
  'dealer.role',
  'createdAt',
  'baseAmount',
  'total',
  'dealerDiscount',
  'application.id',
  'application.name',
  'application.lastName',
  'application.hasUnreadMessages',
  'application.loanProductCode',
  'application.coapplicantName',
  'application.coapplicantLastName',
  'application.hasCoapplicant',
  'application.concierge',
  'dealerNetFunding',
  'dealerDiscount',
  'payments.id',
  'payments.status',
  // meta
  'currentPayment',
  'stageFundedDate',
  'maxPayments',
  'stageFunding',
  'refunds.status',
  'refunds.refundAmountCents',
  'dealerNetworkName',
  'dealerNetworkCode',
  'application.defiApplicationNumber',
  'application.expirationDate',
  'application.merchantRefId',
  'application.serviceDate',
];
const highLevelRoles = ['organization', 'affiliate', 'super_admin'];
const localHeadCells = [
  {
    id: 'ppId',
    label: 'Loan Number',
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Application Date',  
    sortable: true
  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    sortable: true


  },
  {
    id: 'merchantRefId',
    label: 'Merchant Ref Id',
    sortable: true

  },
  {
    id: 'merchantMember',
    label: 'Merchant Member',
    subLabel: 'Role',
    sortable: false
  },
  {
    id: 'applicantName',
    label: 'Applicant',
    subLabel: 'CoApplicant',
    sortable: false
  },
  {
    id: 'baseAmountCents',
    label: 'Loan Amount',
    sortable: false
  },
  {
    id: 'dealerNetFundingCents',
    label: 'Merchant Net Funding',
    sortable: true
  },
  {
    id: 'dealerDiscountCents',
    label: 'Merchant Discount Fees',
    sortable: false
  },
  {
    id: 'stageFunding',
    label: 'Funding Status',
    sortable: false
  },
  {
    id: 'treatmentDate',
    label: 'Treatment Date',
  },
  {
    id: 'stageFundedDate',
    label: 'Funded Date',
    sortable: false
  },

  {
    id: 'status',
    label: 'status',
    sortable: false
  },
  {
    id: 'unReadMsg',
    label: 'Unread Messages',
    sortable: false
  },
  {
    id: 'changOrder',
    label: 'Change Order',
    sortable: false
  },
  {
    id: 'Refunds',
    label: 'Refunds',
    sortable: false
  },
];
const highLevelHeadCells = [
  {
    id: 'ppId',
    label: 'Loan Number',
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Application Date',
    sortable: true
  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    sortable: true

  },
  {
    id: 'merchantRefId',
    label: 'Merchant Ref Id',
    sortable: true
  },
  {
    id: 'dealerNetworkName',
    label: 'Merchant Name',
    subLabel: 'Code',
    sortable: true
  },

  {
    id: 'merchantMember',
    label: 'Merchant Member',
    subLabel: 'Role',
    sortable: false
  },
  {
    id: 'applicantName',
    label: 'Applicant',
    subLabel: 'CoApplicant',
    sortable: false
  },

  {
    id: 'baseAmountCents',
    label: 'Loan Amount',
    sortable: false
  },
  {
    id: 'dealerNetFundingCents',
    label: 'Merchant Net Funding',
    sortable: false
  },
  {
    id: 'dealerDiscountCents',
    label: 'Merchant Discount Fees',
    sortable: false
  },
  {
    id: 'stageFunding',
    label: 'Funding Status',
    sortable: false
  },
  {
    id: 'treatmentDate',
    label: 'Treatment Date',
  },
  {
    id: 'stageFundedDate',
    label: 'Funded Date',
    sortable: false
  },

  {
    id: 'status',
    label: 'status',
    sortable: false
  },
  {
    id: 'unReadMsg',
    label: 'Unread Messages',
    sortable: false
  },
  {
    id: 'changOrder',
    label: 'Change Order',
    sortable: false
  },
  {
    id: 'Refunds',
    label: 'Refunds',
    sortable: false
  },
];

function useLocalTableMethods(setParams) {
  const { setErrorAlertMessage } = useAlertContext();
  const {
    isOpen: isRequestFundDialogOpen,
    onOpen: onOpenRequestFundDialog,
    onClose: onCloseRequestFundDialog,
  } = useDisclosure(false);
  const [selectedRequestFund, setSelectedRequestFund] = useState(null);
  const requestFund = async () => {
    const { projectId, paymentId, applicationId, isLast } = selectedRequestFund;
    const [err] = await to(
      protectedPost(
        isLast
          ? `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationId}/loans/${projectId}/complete`
          : `${process.env.REACT_APP_BASE_URL}/v1/loans/${projectId}/payments/${paymentId}/request_payment`,
      ),
    );
    onCloseRequestFundDialog();
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }
    setParams((state) => ({ ...state, refresh: Date.now }));
  };

  return {
    // request funding
    isRequestFundDialogOpen,
    onOpenRequestFundDialog,
    onCloseRequestFundDialog,
    selectedRequestFund,
    setSelectedRequestFund,
    requestFund,
  };
}

function useTableData(setParams, dealerLevelRole) {
  const [rows, setRows] = useState([]);
  const { updateRow } = useGlobalTableMethods(rows, setRows);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const {
    isRequestFundDialogOpen,
    onOpenRequestFundDialog,
    onCloseRequestFundDialog,
    selectedRequestFund,
    setSelectedRequestFund,
    requestFund,
  } = useLocalTableMethods(setParams);
  const { user } = useUserContext();
  return [
    map((project) => {
      let allPaymentMade = true;
      (project?.payments?.map(payment => {
        if (payment.status !== 'paid')
        allPaymentMade =false;
      return '';
    }))
      const rowCells = [];
      rowCells.push({
        type: 'link',
        value: `/dealer/applications/${project.application.id}`,
        subValue: `${project.ppId}`,
        state: {
          projectId: project.id,
        },
        subValue2: `${project.application.defiApplicationNumber != null
          ? `D${project.application.defiApplicationNumber}`
          : ''
          }`,
      });

      rowCells.push({
        value: project.createdAt
          ? format(new Date(project.createdAt), 'MM/dd/yyyy')
          : 'N/A',
        subValue: project.createdAt
          ? format(new Date(project.createdAt), 'hh:mm aaa')
          : '',
      });

      rowCells.push({
        value: project.application.expirationDate
          ? format(new Date(project.application.expirationDate), 'MM/dd/yyyy')
          : 'N/A',
        subValue: project.application.expirationDate
          ? format(new Date(project.application.expirationDate), 'hh:mm aaa')
          : '',
      });

      rowCells.push({
        type: 'merchantEditor',
        value: project.application.merchantRefId || '',
        appId: project.application.id,

        callbackFn: async (merchantRefId) => {
          if (merchantRefId === null) {
            return '';
          }
          const formData = {
            application: {
              merchant_ref_id: merchantRefId,
            },
          };
          const [err] = await to(
            protectedPut(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${project.application.id}/update_submitted_app`,
              formData,
            ),
          );
          if (err) {
            console.error(err);
            return;
          }
          setAlertMessage('Merchant Reference Id Updated Succesfully');
          setParams((state) => ({ ...state, refresh: Date.now }));
        },
      });

      if (highLevelRoles.includes(dealerLevelRole)) {
        rowCells.push({
          value: project.dealerNetworkName || 'N/A',
          code: project.dealerNetworkCode || 'N/A',
          type: 'dealerNetwork',
        });
      }
      rowCells.push({
        type: 'dealerSelect',
        value: {
          name: project.dealer.name,
          role: project.dealer.role,
          id: project.dealer.id,
          appId: project.application.id,
        },
        callbackFn: async (dealer) => {
          const params = {
            dealer_id: dealer.id,
          };
          const [err] = await to(
            protectedPut(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${project.application.id}/set_dealer_member_for_app`,
              null,
              {
                params,
              },
            ),
          );
          if (err) {
            console.error(err);
            return;
          }
          setAlertMessage('Merchant user updated successfully');
          setParams((state) => ({ ...state, refresh: Date.now }));
        },
      });

      rowCells.push({
        value: fullName({
          name: project.application.name,
          lastName: project.application.lastName,
        }),
        subValue: project.application.hasCoapplicant
          ? fullName({
            name: project.application.coapplicantName,
            lastName: project.application.coapplicantLastName,
          })
          : null,
      });

      rowCells.push({
        value: project?.total,
        type: 'money',
      });

      rowCells.push({
        value: project?.dealerNetFunding,
        type: 'money',
      });

      // TEMP FIX
      rowCells.push({
        value: project?.dealerDiscount,
        type: 'money',
      });

      rowCells.push({
        value: {
          canEdit: user.data.meta.canManageResources,
          payments: project.payments,
          currentPayment: project.currentPayment,
          maxPayments: project.maxPayments,
          status: project.status,
          setSelectedRequestFund: (paymentId, isLast) =>
            setSelectedRequestFund({
              paymentId,
              projectId: project.id,
              applicationId: project.application.id,
              isLast,
            }),
          onOpenRequestFundDialog,
        },
        type: 'stage-fund',
      });

      rowCells.push({
        value: project.application.serviceDate
          ? format(new Date(project.application.serviceDate), 'MM/dd/yyyy')
          : 'N/A',
      });

      rowCells.push({
        value: project.stageFundedDate
          ? format(new Date(project.stageFundedDate), 'MM/dd/yyyy')
          : 'N/A',
      });

      rowCells.push({
        value: project.status,
        type: 'status',
      });
      // App UnreadMsgs
      rowCells.push({
        value: {
          value: project.application.hasUnreadMessages,
          disabled: project.application.hasUnreadMessages === false,
        },
        type: 'message',
      });

      // App Concierge

      if (user.data.attributes.hierarchyLevelRole === 'super_admin') {
        rowCells.push({
          value: {
            value: project.application.concierge,
            disabled: !project.application.concierge,
          },
          type: 'concierge',
        });
      }
      // rowCells.push('view docs');
      // rowCells.push('ror date');
      // rowCells.push('funding reports');
      rowCells.push(
        project.status === 'in_progress' && project.stageFunding
          ? {
            value: `/dealer/applications/${project.application.id}`,
            type: 'link',
            subValue: 'Request',
            state: {
              projectId: project.id,
            },
          }
          : '',
      );

      rowCells.push(
        
        project?.application?.loanProductCode === 'healthcare' &&
        allPaymentMade && !project?.refunds ?
          {
            value: `/dealer/applications/${project.application.id}`,
            type: 'link',
            subValue: 'Refund Request',
          } :
          {
            value: project?.refunds || [],
            type: 'refundsType'
          }

      );
      return rowCells;
    }, rows),
    setRows,
    {
      updateRow,
      isRequestFundDialogOpen,
      onOpenRequestFundDialog,
      onCloseRequestFundDialog,
      selectedRequestFund,
      setSelectedRequestFund,
      requestFund,
    },
  ];
}

const ProjectQueue = (props) => {
  useRedirectOnDealerStatus();
  const classes = tableStyles();
  const history = useHistory();
  const { user, dealerLevelRole } = useUserContext();
  const [params, setParams] = useState({
    status: 'active',
  });
  const [backendResponseLoans, setBackendResponseLoans] = useState(null)
  const [queryParams, setQueryParams] = useState({
    searchText: '',
  });
  const debouncedQueryParams = useDebounce(queryParams, 144);

  const [
    rows,
    setRows,
    {
      selectedRequestFund,
      isRequestFundDialogOpen,
      onCloseRequestFundDialog,
      requestFund,
    },
  ] = useTableData(setParams, dealerLevelRole);
  const { ...tableMethods } = usePageTableParameters({
    initialOrder: 'ppId',
    rows,
  });
  const { selected, selectedIds, page, perPage, order, sort } = tableMethods;

  const onReapply = () => {
    if (backendResponseLoans) {
      /* yes that's a really big line below, its just a complete mess on the backend response. c'est la vie */
      const applicationIdForLoan = backendResponseLoans.included.find(includedArrayElem => (includedArrayElem.type === "application") && (includedArrayElem.relationships.loan?.data.id === selectedIds[0].id)).id
      
      props.updateData({
        name: 'reApplyId',
        value: applicationIdForLoan,
      });
      history.push(`/applications/start`);
    }
  };

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  const { dealerNetworkOptions } = useDealerListContext();
  const { loanProductOptions} = useLoanProductListContext();
  const { organizationOptions} = useOrganizationListContext();
  const { affiliateOptions} = useAffiliateListContext();
  const [currentValue, getCurrentValue] = useState('');
  const [currentLoanProduct, getCurrentLoanProduct] = useState('');
  const [currentOrganization, getCurrentOrganization] = useState('');
  const [currentAffiliate, getCurrentAffiliate] = useState('');
  const [canReapply, setCanReapply] = useState(false);
  const [candBulkDelete, setCanBulkDelete] = useState(false);
  useEffect(() => {
    clearFilters();
  }, []);
  const {
    isOpen: isBulkAssigning,
    onOpen: onOpenBulkAssign,
    onClose: onCloseBulkAssign,
  } = useDisclosure(false);
  useEffect(() => {
    if (selectedIds.length === 1) {
      setCanReapply(true);
    } else {
      setCanReapply(false);
    }
    const rowsCopy = [...rows];
    let canDeleteBulk = true;
    const appWithProject = selectedIds.find((rowIds) => {
      const selectedRowIndex = rowsCopy.findIndex(
        (row) => row[0].subValue === rowIds.subValue,
      );
      const selectedRow = rowsCopy[selectedRowIndex];
      rowsCopy.splice(selectedRowIndex, 1);
      if (selectedRow[selectedRow.length - 1].type === 'empty') {
        canDeleteBulk = false;
      }
      return !selectedRow[13];
    });
    setCanBulkDelete(canDeleteBulk);
  }, [selectedIds]);
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      if (debouncedQueryParams.searchText !== '') {
        if (
          localStorage.getItem('searchText') ===
          debouncedQueryParams.searchText &&
          localStorage.getItem('status') === params.status
        ) {
          params.page = page + 1;
        } else {
          params.page = 1;
        }
      } else {
        params.page = page + 1;
      }
      localStorage.setItem('searchText', debouncedQueryParams.searchText);
      localStorage.setItem('status', params.status);
      const [err, projectsRes] = await to(
        protectedGet('/v1/loans/projects', {
          params: decamelizeKeys({
            ...params,
            ...debouncedQueryParams,
            page: params.page,
            perPage,
            sort,
            order: decamelize(order),
            status: null,
            canceled: params.status === 'canceled',
            dealerNetworkId: currentValue ? currentValue : null,
            loanProductId: currentLoanProduct ? currentLoanProduct : null,
            organizationId: currentOrganization ? currentOrganization : null,
            affiliateId: currentAffiliate ? currentAffiliate : null,
          }),
        }),
      );
      setIsLoading(false);
      if (err) {
        console.error(err);
        return;
      }
      const projects = projectsRes.data;
      setBackendResponseLoans(projects)
      projects.data = map(
        (project) => ({
          ...project,
          attributes: {
            ...project.attributes,
            ...project.meta,
            maxPayments: project?.meta?.payments,
          },
          meta: null,
        }),
        projects?.data,
      );

      let res = normalize(camelizeKeys(projects)).get(projectFields);

      res = map((prj) => {
        return {
          ...prj,
          payments: sortBy(prop('id'), prj.payments),
        };
      }, res);
      setTotalItems(Number(projectsRes.headers.total));
      setRows(res);
    }
    fetch();
    tableMethods.setSelected({});
  }, [params, debouncedQueryParams, page, perPage, sort, order, currentValue, currentLoanProduct, currentOrganization, currentAffiliate]);
  const columnIndexToPlaceConciergeAt = 14; // declaring this as a const to make it easier to see
  /* for some reason we're manually splicing the concierge header. Needless to say, the moment you add or remove a column, bugs will occur */
  if (
    user.data.attributes.hierarchyLevelRole === 'super_admin' &&
    localHeadCells[columnIndexToPlaceConciergeAt].label !== 'Concierge' &&
    highLevelHeadCells[columnIndexToPlaceConciergeAt + 1].label !== 'Concierge'
  ) {
    localHeadCells.splice(columnIndexToPlaceConciergeAt, 0, {
      id: 'concierge',
      label: 'Concierge',
    });
    highLevelHeadCells.splice(columnIndexToPlaceConciergeAt + 1, 0, {
      id: 'concierge',
      label: 'Concierge',
    });
  } else if (
    user.data.attributes.hierarchyLevelRole !== 'super_admin' &&
    localHeadCells[columnIndexToPlaceConciergeAt].label === 'Concierge' &&
    highLevelHeadCells[columnIndexToPlaceConciergeAt + 1].label === 'Concierge'
  ) {
    localHeadCells.splice(columnIndexToPlaceConciergeAt, 1);
    highLevelHeadCells.splice(columnIndexToPlaceConciergeAt + 1, 1);
  }
  const {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  } = useTableSetup({
    rows,
    params,
    headCells: highLevelRoles.includes(dealerLevelRole)
      ? highLevelHeadCells
      : localHeadCells,
  });
  const { webUiPreference } = useUserContext();

  const [initialValues, setInitialValues] = useState({
    dealerList: '',
  });
  const [loanProductInitialValues, setLoanProductInitialValues]  = useState({
    loanProductList: '',
  });
  const [organizationInitialValues, setOrganizationInitialValues] = useState({
    organizationList: '',
  })
  const [affiliateInitialValues, setAffiliateInitialValues  ] = useState({
    affiliateList: '',
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <PageTableWrapper isLoading={isLoading}>
        <PageTableHeader>
          <StateFilter
            possibleStatuses={['active', 'expiring', 'canceled', 'all']}
            status={params.status}
            setParams={setParams}
            {...tableMethods}
          />
          <Box className={classes.rightFilterWrapper}>
          {dealerLevelRole === 'super_admin' && (
              <>
                <Button
                  style={{
                    minWidth: '35px',
                    padding: '0px',
                  }}
                  onClick={handleOpen}
                >
                  <Icon icon="table-filters" />
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box className={classes.modalContent}>
                  <Button 
                    style={{
                      minWidth: '35px',
                      padding: '0px',
                      marginTop: '10px',
                      marginLeft: '450px',
                    }}
                    onClick={handleClose}
                  >
                     {/* <Icon icon="close"></Icon> */}
                     <HighlightOffIcon style={{fontSize: '35px'}} />

                  </Button>
                    <br></br>
                  <Grid item xs={12} md={6} >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={null}
                      enableReinitialize
                    >
                      {({ values }) => {
                        return (
                          <>
                            <Form
                            style={{padding: '0 1rem 0 1rem', width: '330px'}}>
                              <AutocompleteFieldDropDown
                                placeholder="Filter by dealer"
                                name="dealerList"
                                options={dealerNetworkOptions}
                                disabled={isLoading}
                                getCurrentValue={getCurrentValue}
                                type="dealerFilter"

                              />
                            </Form>
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} md={6} >
                    <Formik
                      initialValues={loanProductInitialValues}
                      validationSchema={null}
                      enableReinitialize
                    >
                      {({ values }) => {
                        return (
                          <>
                            <Form
                            style={{padding: '0 1rem 0 1rem', width: '330px'}}>
                              <AutocompleteFieldDropDown
                                placeholder="Filter by Loan Product"
                                name="loanProductList"
                                options={loanProductOptions}
                                disabled={isLoading}
                                getCurrentValue={getCurrentLoanProduct}
                                type="dealerFilter"

                              />
                            </Form>                           
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} md={6} >
                    <Formik
                      initialValues={organizationInitialValues}
                      validationSchema={null}
                      enableReinitialize
                    >
                      {({ values }) => {
                        return (
                          <>
                            <Form
                            style={{padding: '0 1rem 0 1rem', width: '330px'}}>
                              <AutocompleteFieldDropDown
                                placeholder="Filter by Organization"
                                name="organizationList"
                                options={organizationOptions}
                                disabled={isLoading}
                                getCurrentValue={getCurrentOrganization}
                                type="dealerFilter"

                              />
                            </Form>    
                            <br></br>                          
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <Formik
                      initialValues={affiliateInitialValues}
                      validationSchema={null}
                      enableReinitialize
                    >
                      {({ values }) => {
                        return (
                          <>
                            <Form
                            style={{padding: '0 1rem 0 1rem', width: '330px'}}>
                              <AutocompleteFieldDropDown
                                placeholder="Filter by Affiliate"
                                name="affiliateList"
                                options={affiliateOptions}
                                disabled={isLoading}
                                getCurrentValue={getCurrentAffiliate}
                                type="dealerFilter"

                              />
                            </Form>    
                            <br></br>                          
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                    <QueryFilter setParams={setQueryParams} disableWindowReload />
                    <br></br>
                    <Button
                        className={clsx(classes.clearButton )}
                        onClick={() => {
                          clearFilters();
                          window.location.reload();
                        }}
                    >
                      Clear All
                    </Button>
                  </Box>
                </Modal>
              </>
            )}
            {dealerLevelRole !== 'super_admin' &&
              <QueryFilter setParams={setQueryParams} />
            }
            <Hidden mdDown>
              <ColumnToggler
                hiddenColumns={webUiPreference?.loans?.hiddenColumns || []}
                columns={initialColumns.current}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
                showSave
                loans
              />
            </Hidden>
            {/* <ExtendedFilters>
                  <CheckboxesFilter
                    possibleValues={possibleStatuses}
                    currentValues={params.statusFilters}
                    setParams={setParams}
                  />
                  <DateRangeFilter
                    possibleValues={dateRanges}
                    currentValues={params.dateRange}
                    setParams={setParams}
                  />
                </ExtendedFilters> */}
          </Box>
        </PageTableHeader>
        <PageTable
          totalItems={totalItems}
          rows={filteredRows}
          headCells={filteredHeadCells}
          isLoading={isLoading}
          page={params.page}
          perPage={params.perPage}
          setParams={setParams}
          emptyStateTitle="No Projects, yet."
          emptyStateDesc="Once the Applicant has signed his Loan Envelope, the application will become a Project."
          itemType={`${totalItems > 1 ? 'Projects' : 'Project'}`}
          onRowClick={onRowClick}
          hideCheckboxes={!user.data.meta.canManageResources}
          {...tableMethods}
        />
      </PageTableWrapper>
      <SelectedMenu selected={selected}>
        {(user.data.attributes.hierarchyLevelRole === 'organization' ||
          user.data.attributes.hierarchyLevelRole === 'affiliate') && (
            <SelectedMenuActionButton
              icon="people"
              label="Bulk Re-Assignment"
              onClick={onOpenBulkAssign}
            />
          )}
        {canReapply && (
          <SelectedMenuActionButton
            icon="prequalify"
            label="ReSubmit"
            onClick={onReapply}
          />
        )}
      </SelectedMenu>
      <ConfirmationDialog
        desc="Are you sure you want to request funding for this loan?"
        subDesc={
          selectedRequestFund?.isLast && 'This process will finish this loan.'
        }
        dialogState={isRequestFundDialogOpen}
        handleClose={onCloseRequestFundDialog}
        handleCancel={requestFund}
      />
    </>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    merchantRefId: state.appReducer?.merchant_ref_id,
    dealStruct: state.appReducer?.dealStruct,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(ProjectQueue);
