import React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  makeStyles,
  withStyles,
  Box,
  Button
} from '@material-ui/core';

import {
  InfoOutlined,
  PhoneOutlined,
  VideocamOutlined,
  MailOutlined,
} from '@material-ui/icons';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import BuildIcon from '@material-ui/icons/Build';
import MerchantResources from 'pages/MerchantResources';
import ContactUs from 'pages/ContactUs';
import { addIndex, map } from 'ramda';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '4rem',
    },
  },
  LinkDecoration: {
    '&:hover': {

      textDecoration: 'underline',

    },

  },
  cardTitle: {
    fontSize: '1.5rem',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  cardSubHeader: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.875rem',
    },
  },
  helpItem: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '1.375rem',
    },
  },
}));

const Icon = withStyles((theme) => ({
  root: {
    color: theme.palette.text.hint,
    marginRight: '0.5rem',
  },
}))(({ Component, classes: { root } }) => {
  return <Component className={root} />;
});

const items = [
  {
    IconC: PhoneOutlined,
    label: '800-397-4485',
  },
  {
    IconC: MailOutlined,
    label: 'app.support@getpowerpay.com',
  },

 
];

const merchantList = [
  {
    IconC: BuildIcon,
    label: "Merchant Resources",
  },

];

const contactList = [
  {
    IconC: ContactSupportIcon,
    label: 'Contact Us',
  },
 
];

export const HelpCard = (props) => {
  const classes = useStyles();
  const { title, subheader } = props;
  return (
    <Card>
      <CardHeader
        titleTypographyProps={{ className: classes.cardTitle }}
        title={title}
        subheader={subheader}
        // title="Do you need any help?"
        // subheader="We are here to assist you in any case!"
        subheaderTypographyProps={{ className: classes.cardSubHeader }}
      />
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
          className={classes.container}
        >
          {addIndex(map)(
            ({ IconC, label }, index) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={index}
                className={classes.helpItem}
              >
                <Icon Component={IconC} />
                <span>{label}</span>
              </Box>
            ),
            items,
            
          )}
          {addIndex(map)(
            ({ IconC, label }, index) => (
              <Box
                
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={index}
                className={classes.helpItem}
              >
                <Icon Component={IconC} />
                <span className={classes.LinkDecoration}><a style = {{color: '#1E272E'}} href='/merchant-resources'>{label}</a></span>
              </Box>
            ),
            merchantList,
            
          )}
          {addIndex(map)(
            ({ IconC, label }, index) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                key={index}
                className={classes.helpItem}
              >
                <Icon Component={IconC} />
                <span className={classes.LinkDecoration}><a  style = {{color: '#1E272E'}} href='/contact-us'>{label}</a></span>
              </Box>
            ),
            contactList,
            
          )}
           
           
        </Box>
      </CardContent>
    </Card>
  );
};
