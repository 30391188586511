import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import to from 'await-to-js';
import axios from 'axios';
import { protectedPatch, protectedPost } from 'services/http';
import { camelizeKeys } from 'humps';
import PulseLoader from 'react-spinners/PulseLoader';

import { addIndex, map } from 'ramda';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import { DriverLicenseInput } from 'components/InputFields/DriverLicenseInput';
import { useAlertContext } from 'components/AlertContext';
import { pathSplit, buildFormData } from 'services/utils';

const verifyDocument = async (
  file,
  {
    coapplicant,
    setLoading,
    setFieldValue,
    setAlertMessage,
    setErrorAlertMessage,
    setSubmitting,
    appId,
  },
) => {
  const formData = new FormData();

  buildFormData(formData, {
    documentType: 'license',
    documentFront: file,
  });

  setLoading(true);
  const [err, r] = await to(
    axios.post(`https://upload.socure.com/api/3.0/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `SocureApiKey ${process.env.REACT_APP_SOCURE_PUBLIC_KEY}`,
      },
    }),
  );
  if (err && err.response) {
    setLoading(false);
    setErrorAlertMessage(err.response.data.message || 'Error');
    return;
  }
  const resData = camelizeKeys(r.data);

  const [err2, r2] = await to(
    protectedPost(`/v1/socure_proxies/email_auth_score`, {
      modules: ['documentverification'],
      documentUuid: resData.uuid,
      country: 'us',
    }),
  );
  if (err2) {
    setLoading(false);
    setErrorAlertMessage(err2.response.data.message || 'Error');
    return;
  }
  const documentData = camelizeKeys(JSON.parse(r2.data.body))
    ?.documentVerification?.documentData;
  const address = documentData?.parsedAddress?.physicalAddress?.split(' ');
  const info = {
    name: documentData.firstName,
    lastName: documentData.surName,
    birthday: documentData.dob,
    houseNumber: address?.[0],
    address: address.slice(1, address.lenght).join(' '),
    zipCode: documentData?.parsedAddress?.zip,
    state: documentData?.parsedAddress?.state,
    city: documentData?.parsedAddress?.city,
  };
  if (coapplicant) {
    const [patchUUIDError, patchUUIDRes] = await to(
      protectedPatch(`/v1/applications/${appId}/coapp_driver_license_uuid`, {
        coapplicant_driver_license_uuid: resData.uuid,
      }),
    );

    if (patchUUIDError) {
      setLoading(false);
      setErrorAlertMessage('Error saving the coapplicant data.');
      return;
    }

    setFieldValue('coapplicantDriverLicense.uuid', resData.uuid);
    setFieldValue('coapplicant.name', info.name);
    setFieldValue('coapplicant.lastName', info.lastName);
    setFieldValue('coapplicant.birthday', info.birthday);
  } else {
    setFieldValue('driverLicense.uuid', resData.uuid);
    setFieldValue('homeowner.name', info.name);
    setFieldValue('homeowner.lastName', info.lastName);
    setFieldValue('homeowner.birthday', info.birthday);
    setFieldValue(
      'application.addressAttributes.streetAddress',
      address.join(' '),
    );
    setFieldValue('application.addressAttributes.zipCode', info.zipCode);
    setFieldValue('application.addressAttributes.city', info.city);
    setFieldValue('application.addressAttributes.state', info.state);
  }
  setAlertMessage('License uploaded successfully');
  setLoading(false);
  setSubmitting(false);
};

const getTexts = (isDealer = false, dealerRole = 'dealership') => {
  const HOTexts = {
    stepTitle: 'Identity Verification',
    stepSubtitle: 'Let us know more about you',
    title: "Driver's License",
    subtitle: 'Upload or take a picture of your document',
    subtitleCO: "Upload or take a picture of your Co-Applicant's document",
  };
  const dealershipTexts = {
    stepTitle: 'Identity Verification',
    stepSubtitle: 'Let us know more about your Client',
    title: "Driver's License",
    subtitle: "Upload or take a picture of your Client's document",
    subtitleCO: "Upload or take a picture of the Co-Applicant's document",
  };
  if (isDealer) {
    switch (dealerRole) {
      case 'dealership':
      default:
        return dealershipTexts;
    }
  } else {
    return HOTexts;
  }
};

const useStyles = makeStyles((theme) => ({
  infoItemsContainer: {
    paddingTop: '2rem',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '2rem',
    },
  },
  infoItem: {},
}));

export const Step2DriverLicense = ({
  isActive,
  setChildCallbacks,
  coapplicant,
  isDealer,
  dealerRole,
  appId,
}) => {
  const { values, setFieldValue, setSubmitting } = useFormikContext();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return TEXTS.stepTitle;
        },
        get stepSubtitle() {
          return TEXTS.stepSubtitle;
        },
      });
    }
    return () => {};
  }, [isActive]);

  const currentLicense = pathSplit(
    values,
    coapplicant
      ? `application.coapplicantDriverLicense`
      : `application.driverLicense`,
  );
  useEffect(() => {
    if (currentLicense && !currentLicense?.url && !currentLicense?.uuid) {
      setSubmitting(true);
      verifyDocument(currentLicense, {
        coapplicant,
        setLoading,
        setFieldValue,
        setAlertMessage,
        setErrorAlertMessage,
        setSubmitting,
        appId,
      });
    }
  }, [currentLicense]);

  const theme = useTheme();
  const classes = useStyles();

  const TEXTS = getTexts(isDealer, dealerRole);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Text textColor={theme.palette.text.primary} textSize="sm+" semibold>
            {TEXTS.title}
          </Text>
          <Text
            textColor={theme.palette.text.secondary}
            textSize="xs+"
            semibold
          >
            {coapplicant ? TEXTS.subtitleCO : TEXTS.subtitle}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <DriverLicenseInput
          name={
            coapplicant
              ? `application.coapplicantDriverLicense`
              : `application.driverLicense`
          }
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.infoItemsContainer}>
        {addIndex(map)(
          (item, index) => (
            <InfoItem key={index} {...item} />
          ),
          infoItems(isDealer, dealerRole),
        )}
      </Grid>
      <Grid item xs={12}>
        {isLoading && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <PulseLoader color={theme.palette.primary.light} size="1rem" />
            <Text textSize="sm+" semibold textColor="secondary">
              Please wait while our systems scan your document, this process may
              take up to 30 seconds
            </Text>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const infoItems = (isDealer) => [
  {
    title: 'Let’s save time',
    desc: `Please make sure you’re uploading a legible image of ${
      isDealer ? 'the' : 'your'
    } Driver's License.`,
  },
  {
    title: `Why do we need ${isDealer ? 'their' : 'your'} Driver's License?`,
    desc: `To confirm ${
      isDealer ? 'their' : 'your'
    } identity and to save you some time filling out the application!`,
  },
  {
    title: `Is ${isDealer ? 'their' : 'my'} Driver's License Safe?`,
    desc: `Yes! All of ${
      isDealer ? 'their' : 'your'
    } personal information is stored safely and securely.`,
  },
];
export const InfoItem = ({ title, desc }) => {
  const classes = useStyles();
  return (
    <Box marginBottom="1.5rem" className={classes.infoItem}>
      <Box display="flex" alignItems="center" marginBottom="0.125rem">
        <Icon icon="info-filled" color="#9198A0" fontSize="1.5rem" />
        <Text textSize="sm" bold style={{ marginLeft: '0.5rem' }}>
          {title}
        </Text>
      </Box>
      <Text textSize="sm">{desc}</Text>
    </Box>
  );
};
