import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { translateEmploymentType, pathSplit } from 'services/utils';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';

import Text from 'components/TextFields/Text';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 3px',
    borderColor: ({ isSelected }) =>
      isSelected ? theme.palette.primary.light : '#C1C9D1',
    width: '14rem',
    height: '8rem',
    borderRadius: 10,
    backgroundColor: ({ isSelected }) =>
      isSelected ? 'rgba(255,206,108,0.02)' : '#F9FAFC',

    [theme.breakpoints.down('sm')]: {
      width: '9rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '9rem',
      margin: '0.5rem 0',
      height: '6.5rem',
    },

    '&:hover': {
      backgroundColor: ({ isSelected }) =>
        isSelected ? 'rgba(255,206,108,0.1)' : 'rgba(255,255,255,0.1)',
    },
  },
  image: {
    width: 70,
    height: 70,
    [theme.breakpoints.down('xs')]: {
      width: 48,
      height: 48,
    },
  },
}));

const CompanyTypeOption = ({
  companyType,
  imageActive,
  imageInactive,
  onClick,
  isSelected,
}) => {
  const classes = useStyles({ isSelected });
  const theme = useTheme();
  return (
    <Box className={classes.container} selected={isSelected} onClick={onClick}>
      <img
        src={isSelected ? imageActive : imageInactive}
        className={classes.image}
      />
      <Text
        textSize="sm"
        semibold
        textColor={
          isSelected ? theme.palette.primary.main : theme.palette.text.secondary
        }
        textAlign="center"
        style={{ pointerEvents: 'none' }}
        className={{}}
      >
        {companyType.toUpperCase()}
      </Text>
    </Box>
  );
};

export default CompanyTypeOption;
