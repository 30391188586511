import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { map, addIndex } from 'ramda';
import to from 'await-to-js';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Checkbox from '@material-ui/core/Checkbox';

import Icon from 'components/Icon';
import Text from 'components/TextFields/Text';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { Button } from '@material-ui/core';
import { protectedPost } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    padding: '1rem',
    '&, & *': {
      outline: 0,
    },
  },
  actionButton: {
    height: 40,
    width: '100%',
    margin: '12px',
    color: 'white',
    [theme.breakpoints.down('md')]: {
      width: '90%',
      margin: '1rem 0',
      maxWidth: '32rem',
    },
  },
  menuItem: {
    padding: '0.375rem 0',
    cursor: 'pointer',
  },
  checkbox: {
    padding: 0,
    marginRight: '0.5rem',
  },
}));

export const ColumnToggler = ({
  columns,
  activeColumns,
  setActiveColumns,
  hiddenColumns,
  loans,
  showSave,
}) => {
  const classes = useStyles();
  const { setAlertMessage } = useAlertContext();
  const [selectAll, setSelectAll] = useState(false);
  const anchorRef = React.useRef(null);
  const { webUiPreference, clearUserStatus, user } = useUserContext();
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    onClose();
  };
  const toggleColumn = (columnId) => () => {
    setActiveColumns((state) => {
      const s = { ...state };
      s[columnId] = !s[columnId];
      return s;
    });
  };
  useEffect(() => {
    if (hiddenColumns?.length > 0 && showSave) {
      columns.map((column) => {
        if (hiddenColumns.includes(column.label)) {
          setActiveColumns((state) => {
            const s = { ...state };
            s[column.id] = !s[column.id];
            return s;
          });
        }
        return '';
      });
    }
  }, []);

  const showAllColumns = (e) => {
    // setSelectAll(true);
    columns.map((column) => {
      setActiveColumns((state) => {
        const s = { ...state };
        if (!s[column.id]) s[column.id] = true;
        return s;
      });

      return '';
    });
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      onClose();
    }
  }
  const savePrefernces = async () => {
    const hiddenColumnArr = [];

    columns.map((column) => {
      if (!activeColumns[column.id]) {
        hiddenColumnArr.push(column.label);
      }
      return hiddenColumnArr;
    });
    const payload = {
      user: {
        column_filters: {
          applications: {
            hidden_columns: loans
              ? webUiPreference?.applications?.hiddenColumns || []
              : hiddenColumnArr,
          },
          loans: {
            hidden_columns: loans
              ? hiddenColumnArr
              : webUiPreference?.loans?.hiddenColumns || [],
          },
        },
      },
    };
    const [err, rawLoanDetails] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/users/1/set_preferences`,
        payload,
      ),
    );
    if (rawLoanDetails) {
      setAlertMessage('Columns View Updated Succesfully');

      clearUserStatus();
      onClose();
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure(false);
  const prevOpen = React.useRef(isOpen);
  React.useEffect(() => {
    if (prevOpen.current === true && isOpen === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        aria-controls={isOpen ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={onToggle}
        aria-label="toggle active columns"
      >
        <Icon icon="table-columns" />
      </IconButton>
      <Popper
        open={isOpen}
        style={{
          zIndex: '2',
        }}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              marginTop: placement === 'top' && '32%',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.menuWrapper}
                >
                  <Text textSize="sm" bold>
                    Choose columns to display
                  </Text>
                  {Object.keys(activeColumns).some(
                    (k) => activeColumns[k] === false,
                  ) && (
                    <Box
                      // key={column.id}
                      onClick={showAllColumns}
                      className={classes.menuItem}
                      display="flex"
                      textAlign="left"
                      alignItems="center"
                    >
                      <Checkbox
                        inputProps={{ 'aria-label': 'display column' }}
                        color="primary"
                        className={clsx(classes.checkbox, {
                          [classes.checkboxActive]: selectAll,
                        })}
                        checked={selectAll}
                      />

                      <Text textSize="sm" bold>
                        SELECT ALL
                      </Text>
                    </Box>
                  )}
                  <MenuList
                    autoFocusItem={isOpen}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                    className={classes.menuList}
                  >
                    {map((column) => {
                      const checked = activeColumns[column.id];
                      if (!column.label || (user.data.attributes.showNetFundingAndCost === false && column.label === 'Merchant Net Funding')
                          || (user.data.attributes.showNetFundingAndCost === false && column.label === 'Merchant Discount Fees') ) {
                        return '';
                      }
                      return (
                        <Box
                          key={column.id}
                          onClick={toggleColumn(column.id)}
                          className={classes.menuItem}
                          display="flex"
                          textAlign="left"
                          alignItems="center"
                        >
                          <Checkbox
                            inputProps={{ 'aria-label': 'display column' }}
                            color="primary"
                            className={clsx(classes.checkbox, {
                              [classes.checkboxActive]: checked,
                            })}
                            checked={checked}
                          />
                          <Text
                            textSize="sm+"
                            textColor={checked ? '#F79D3C' : '#9198A0'}
                            semibold
                          >
                            {column.label.toUpperCase()}
                          </Text>
                        </Box>
                      );
                    }, columns)}
                    {showSave && (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        type="button"
                        style={{ marginTop: '10px' }}
                        onClick={(e) => {
                          savePrefernces();
                        }}
                      >
                        Save Preference
                      </Button>
                    )}
                  </MenuList>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
