import React, { useEffect, useState } from 'react';
import { map, filter } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { protectedGet , protectedPost} from 'services/http';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, makeStyles, Button, Box,Grid } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import { VanillaNewAppButton } from 'components/Buttons/NewApplicationButton';
import { PageTable } from 'components/PageTable';
import { format } from 'date-fns';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles((theme) => ({
  header: {
    background: 'rgba(57, 63, 71, 0.02)',
  },
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.6px',
  },
  cardContent: {
    padding: '0 !important',
  },
  card: {
    flexGrow: 1,
    padding: '2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3.5rem',
      paddingBottom: '2rem',
    },
  },
  inline:{
    flexDirection: "row" , justifyContent: 'space-evenly'
  }
}));

const headCells = [
  {
    id: 'appNumber',
    label: 'App Number',
  },
  {
    id: 'dealer',
    label: 'Contractor',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'dateCreated',
    label: 'Date Created',
  },
  {
    id: 'requestAmount',
    label: 'Amount Requested',
  },
  {
    id: 'status',
    label: 'status',
  },
];

function buildTableData(applications) {
  return map((application) => {
    const rowCells = [];
    rowCells.push({
      type: 'link',
      value:
        application.status === 'pending' &&
          application.draftStatus === 'drafted'
          ? `/applications/new`
          : `/applications/${application.id}`,
      state: { applicationId: application.id },
      subValue: application.ppId,
    });

    rowCells.push(application?.dealer?.businessName);

    rowCells.push({
      value: application?.address?.streetAddress || 'N/A',
      subValue: application?.address?.addressLine2,
    });

    rowCells.push({
      value: application.createdAt
        ? format(new Date(application.createdAt), 'MM/dd/yyyy')
        : 'N/A',
      subValue: application.createdAt
        ? format(new Date(application.createdAt), 'hh:mm aaa')
        : '',
    });

    rowCells.push({
      value: application?.financialDetails?.[0]?.requestAmount,
      type: 'money',
    });

    rowCells.push({
      value: application.status,
      subValue: application.draftStatus,
      type: 'status',
    });
    return rowCells;
  }, applications);
}

export const ApplicationCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUserContext();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { palette } = useTheme();
  const [totalItems, setTotalItems] = useState(0);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    page: 0,
    perPage: 10,
  });
  const [isCreationDisabled, setIsCreationDisabled] = useState(false);

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const [err, applications] = await to(
        protectedGet('/v1/applications', {
          params: decamelizeKeys({ ...params, page: params.page + 1 }),
        }),
      );
      setIsLoading(false);
      if (err) {
        console.error(err);
        return;
      }

      applications.data.data = map(
        (app) => ({
          ...app,
          attributes: { ...app.attributes, ...app.meta },
        }),
        applications.data?.data,
      );
      const res = normalize(camelizeKeys(applications.data)).get([
        'id',
        'ppId',
        'status',
        'draftStatus',
        'dealer.id',
        'dealer.name',
        'dealer.lastName',
        'dealer.businessName',
        'address.streetAddress',
        'address.addressLine2',
        'address.state',
        'address.city',
        'address.zipCode',
        'createdAt',
        'financialDetails.requestAmount',
      ]);
      const approved = filter((app) => app.status === 'approved', res);
      setIsCreationDisabled(approved.length >= 3);
      setTotalItems(Number(applications.headers.total));
      setRows(buildTableData(res));
    }
    fetch();
  }, [params]);

  const linkapplication = async () =>
  {
    const [err , response] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/homeowners/link_existing_app`, 
        {
        email: user?.data?.attributes?.email
        }
      ),
    );
    if (response)
    {
      setAlertMessage(response?.data?.messages);
    }
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message);
      return '';
  }
}
  return (
    <>
      {totalItems > 0 && (
        <Card>
          <CardHeader

            className={classes.header}
            titleTypographyProps={{ className: classes.cardTitle }}
            title="Your Applications"
            // action={<NewApplicationButton disabled={isCreationDisabled} />}
            action={<div className={classes.inline}>
              <VanillaNewAppButton style={{ maxWidth: '28rem',marginRight: '15px' }} setFlag>
                New Application
              </VanillaNewAppButton>
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: '4px',
                  boxShadow: 'none',
                  maxWidth: '28rem',
                  display:'inline',
                  marginRight: '15px'
                }}
                onClick={linkapplication} >
                <Text bold textColor="white">
                  Existing Application
                </Text>
              </Button>
            </div>
            }
          />
          <CardContent className={classes.cardContent}>
            <PageTable
              totalItems={totalItems}
              rows={rows}
              headCells={headCells}
              isLoading={isLoading}
              page={params.page}
              perPage={params.perPage}
              setParams={setParams}
              hidePagination
              hideCheckboxes
              noSort
              tableHorizontalPadding="1.5rem"
              onRowClick={onRowClick}
            />
          </CardContent>
        </Card>
      )
      }
      {totalItems === 0 && (
        <Card>
          <CardHeader
            className={classes.header}
            titleTypographyProps={{ className: classes.cardTitle }}
          />
          <CardContent className={classes.card}>
            <Box display="flex" flexDirection="column" alignItems="center" >
              <Text
                textSize="cardTitle"
                textAlign="center"
                bold
                textColor={palette.text.primary}
              >
                Apply Now!
              </Text>
              <Text
                textSize="cardSubtitle"
                textAlign="center"
                textColor={palette.text.secondary}
                style={{ marginTop: '1rem' }}
              >
                Simple and Quick Application Process
              </Text>
              <br></br>

              <VanillaNewAppButton style={{ maxWidth: '24rem', width:"100%" ,padding: '0.5rem 0',}} setFlag>
                New Application
              </VanillaNewAppButton>
            </Box>
          </CardContent>
        </Card>
      )
      }
    </>

  );
};
