import React, { useState } from 'react';

import { makeStyles, Tabs, Tab, Paper } from '@material-ui/core';

import { Documents } from 'pages/ApplicationDetail/DocumentsTab';
import Timeline from '../../TimelineTab/Timeline';

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: '0 0 12px rgba(116,125,140,0.1)',
    padding: 8,
  },
  tab: {
    minWidth: 'auto',
  },
  container: {
    height: 590,
    marginTop: 30,
    overflowY: 'scroll',
    paddingLeft: 10,
    paddingRight: 17,
  },
}));

const Sidebar = React.memo(
  ({
    documents,
    applicationNumber,
    sendedLoanDocs,
    processingLoanDocs,
    fetchedData,
    setFetchedData,
    projectStatus,
    draftStatus,
    openStipulations,
    closedStipulations,
    loanId,
    signatureMethod,
    showLoader,
    setShowLoader,
    notices,
    hasSelectedDeal,
  }) => {
    const classes = useStyles();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const isAppCanceled = draftStatus === 'canceled';

    return (
      <Paper className={classes.paper} elevation={0}>
        <Tabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label="DOCUMENTS" value={0} />
          <Tab label="TIMELINE" value={1} />
          <Tab label="" value={2} disabled />
        </Tabs>
        <div className={classes.container}>
          {value === 0 && (
            <Documents
              hasSelectedDeal={hasSelectedDeal}
              applicationNumber={applicationNumber}
              documents={documents}
              signatureMethod={signatureMethod}
              fetchedData={fetchedData}
              openStipulations={openStipulations}
              closedStipulations={closedStipulations}
              notices={notices}
              isAppCanceled={isAppCanceled}
              loanId={loanId}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              projectStatus={projectStatus}
              sendedLoanDocs={sendedLoanDocs}
              processingLoanDocs={processingLoanDocs}
              setFetchedData={setFetchedData}
            />
          )}
          {value === 1 && <Timeline applicationNumber={applicationNumber} />}
        </div>
      </Paper>
    );
  },
);

export default Sidebar;
