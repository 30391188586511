import { path, split, assocPath, reduce, mergeDeepWith } from 'ramda';
export const isAppEmbedded = () => {
  const pattern = /dealer\/application/; // for now just the dealer application page
  const path = window.location.pathname;
  const embeddablePage = pattern.test(path);

  return embeddablePage && window.location !== window.parent.location;
};
export const merger = (left, right) => {
  if (!left) {
    return right;
  }
  if (Array.isArray(left)) {
    return [
      mergeDeepWith(merger, left[0], right[0]),
      mergeDeepWith(merger, left[1], right[1]),
    ];
  }
  return left;
};

export const translateDealerRole = (role) => {
  switch (role) {
    case 'super_admin':
      return 'Super Admin';
    case 'affiliate':
      return 'Affiliate';
    case 'organization':
      return 'Organization';
    case 'dealer_network_overseer':
      return 'Dealership Overseer';
    case 'dealer_network_member':
      return 'Dealership Member';
    case 'dealer_network_account_rep':
      return 'Account Rep';
    default:
      return 'N/T';
  }
};

export const translateDealerRoleShort = (role) => {
  switch (role) {
    case 'super_admin':
      return 'Super Admin';
    case 'affiliate':
      return 'Affiliate';
    case 'organization':
      return 'Organization';
    case 'dealer_network_overseer':
    case 'overseer':
      return 'Overseer';
    case 'dealer_network_member':
    case 'member':
      return 'Member';
    case 'dealer_network_account_rep':
    case 'account_rep':
      return 'Account Rep';
    default:
      return 'N/T';
  }
};

export const translateUserStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'rejected':
      return 'Declined';
    case 'approved':
      return 'Approved';
    default:
      return 'N/T';
  }
};

export const translatePrequalStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Pending';
    case 'prequalified':
    case 'approved':
      return 'Prequalified';
    case 'declined':
    case 'rejected':
      return 'Declined';
    case 'not_prequalified':
      return 'Not Prequalified';
    default:
      return 'N/T';
  }
};

export const translateApplicationState = (status, draftStatus) => {
  if (draftStatus === 'canceled') {
    return 'Canceled';
  }
  switch (status) {
    case 'draft':
    case 'drafted':
      return 'Draft';
    case 'pending':
    case 'in_progress':
      return 'In Progress';
    case 'needs_review':
      return 'Needs Review';
    case 'rejected':
      return 'Declined';
    case 'approved':
      return 'Approved';
    case 'expired':
      return 'Expired';
    case 'active':
      return 'Active';
    case 'inactive':
      return 'Inactive';
    case 'canceled':
      return 'Canceled';
    case 'docs_received':
      return 'Docs Received';
    case 'completed':
      return 'Completed';
    case 'finished':
      return 'Finished';
    case 'conditional_approval':
      return 'Conditional Approval';
    case 'declined':
      return 'Declined';
    case 'change_requested':
      return 'Change Requested';
    case 'transferred':
      return 'Transferred';
    case 'refunded':
      return 'Refunded';
    default:
      return 'N/T';
  }
};

export const translateEmploymentType = (employmentType) => {
  switch (employmentType) {
    case 'employed':
      return 'Employed';
    case 'self_employed':
      return 'Self Employed';
    case 'retired':
      return 'Retired';
    default:
      return 'N/T';
  }
};

export const fullName = (object = {}) => {
  let fn = object.name || object.firstName || object.first_name || 'N/A';
  if (object.lastName || object.last_name) {
    fn += ` ${object.lastName || object.last_name}`;
  }
  return fn;
};

export function isValidDate(d) {
  return d instanceof Date && !Number.isNaN(d);
}

export function pathSplit(item, pathStr, char = '.') {
  const str = pathStr.replace('[', '.').replace(']', '');
  return path(split(char, str), item);
}

export function fillObjPath(obj, pathStr, val, char = '.') {
  const keys = pathStr.split(char);
  const filled = reduce(
    (accum, key) => {
      const [currentPath, currentObject] = accum;
      const nextPath = [...currentPath, key];
      const nextObject = assocPath(
        nextPath,
        {
          ...path(nextPath, obj),
        },
        currentObject,
      );
      return [nextPath, nextObject];
    },
    [[], ''],
    keys,
  )[1];
  return assocPath(keys, val, filled);
}
export function arrayToObjWithKey(array, key = 'key') {
  if (!array) {
    return null;
  }
  return reduce(
    (obj, val) => {
      return fillObjPath(obj, val[key], val);
    },
    {},
    array,
  );
}

export function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !(data instanceof Blob)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key,
      );
    });
  } else {
    let value = data;

    if (typeof value === 'string') {
      value = value.trim();
    }

    if (value != null && value !== undefined && value !== '') {
      formData.append(parentKey, value);
    }
  }
}

export function dataURLtoBlob(dataurl) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export * from './parseApplicationObjToFormJson';
