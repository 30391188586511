import React, { useState, useEffect, useContext, useMemo } from 'react';
import to from 'await-to-js';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';

const OrganizationListContext = React.createContext();
export const { Consumer: OrganizationListConsumer, Provider } = OrganizationListContext;

export function OrganizationListProvider({ children }) {
  const [organizationOptions, setOrganizationOptions] = useState(null);
  const { user, dealerLevelRole } = useUserContext();

  const rolesForGetAllDealers = ['affiliate', 'organization', 'super_admin'];
  useEffect(() => {
    if (!user) {
      setOrganizationOptions(null);
    }

    if (user?.data?.attributes?.role !== 'super_admin') return;

    (async () => {
      try {
        if (
          user &&
          organizationOptions === null 
          // rolesForGetAllDealers.includes(dealerLevelRole)
        ) {
          const [err, data] = await to(protectedGet('/v1/organizations/all'));
          // const res = normalize(camelizeKeys(data?.data)).get([
          //   'id',
          //   'name',
          //   // 'dealerCode',
          // ]);
          let dropDownFormatted = data?.data?.data?.map((organization) => ({
            label : organization?.attributes?.name,
            value : organization?.attributes?.id,
            dealerCode : organization?.attributes?.id

          }));
          dropDownFormatted = dropDownFormatted.sort((a, b) => a.label.localeCompare(b.label));
          setOrganizationOptions(dropDownFormatted);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user]);
  useEffect(() => {
    if (!user && organizationOptions) {
        setOrganizationOptions(null);
    }
  }, [organizationOptions]);
  return (
    <Provider
      value={{
        organizationOptions,
      }}
    >
      {children}
    </Provider>
  );
}

export const useOrganizationListContext = () => {
  const context = useContext(OrganizationListContext);
  return context;
};

export default OrganizationListContext;
