import React from 'react';
import {
  makeStyles,
  Typography,
  Button,
  useTheme,
  Grid,
} from '@material-ui/core';
import { format } from 'date-fns';
import CheckIcon from '@material-ui/icons/Check';
import { protectedPatch } from 'services/http';
import { InformationItem } from 'pages/ApplicationDetail/Dealer/Details/LoanTab/InformationItem';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    marginBottom: 24,
  },
  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  icon: {
    marginLeft: 11,
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
    marginBottom: '1rem',
  },
  content: {
    color: '#1E272E',
    fontSize: '0.875rem',
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  outlinedButton: {
    display: 'block',
    width: 198,
  },
  containedButton: {
    color: 'white',
    float:'right'
    // width: 100,
  },
  informationItemWrapper: {
    backgroundColor: '#F9FAFC',
    marginBottom: 18,
    paddingBottom: 25,
    paddingLeft: 22,
    paddingTop: 18,
    '& .informationItem': {
      marginBottom: 12,
    },
  },
  informationItemTitle: {
    color: '#1E272E',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.7,
    lineHeight: '18px',
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 22,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
}));

const ChangeOrderCard = ({
  key,
  applicationNumber,
  detail,
  setFetchedData,
  setAlertMessage,
  setErrorAlertMessage,
  setChangeOrderTab,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleCancel = async (changeOrderId) => {
    try {
      await protectedPatch(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/change_orders/${changeOrderId}/cancel`,
      );
      setChangeOrderTab(true);
      setFetchedData(false);
      setAlertMessage('Change order updated succesfully');
    } catch (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
    }
  };

  return (
    <>
      <div>
        <div className={classes.card} key={key} data-testid="change-order-card">
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>
              CHANGE ORDER {detail.attributes.id} - {detail.attributes.status}
              {(detail.attributes.status === 'COMPLETED' ||
                detail.attributes.status === 'CONFIRMED') && (
                <span className={classes.icon}>
                  <CheckIcon style={{ color: theme.palette.success.main }} />
                </span>
              )}
            </Typography>
          </div>
          {/* <div className={classes.contentWrapper}> */}
            <Grid className={classes.contentWrapper}>
              <Grid item lg={11} md={11} sm={6} xs={6}>
                <Grid container>
                <Grid item lg={3} md={3}>
                  <Typography className={classes.content}>
                    <span className={classes.contentLabel}>Request Date</span>
                    {detail.attributes.created_at
                      ? format(
                          new Date(detail.attributes.created_at),
                          'MM/dd/yyyy',
                        )
                      : '--'}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3}>
                  <Typography className={classes.content}>
                    <span className={classes.contentLabel}>
                      Base Project Amount
                    </span>
                    {detail.attributes.base_amount}
                  </Typography>
                </Grid>
                <Grid item lg={3} md={3}>
                <Typography className={classes.content}>
                  <span className={classes.contentLabel}>
                    New Base Project Amount
                  </span>
                  {detail.attributes.requested_base_amount}
                </Typography>
                </Grid>
                <Grid item lg={3} md={3}>
                <Typography className={classes.content}>
                  <span className={classes.contentLabel}>Difference</span>
                  {detail.attributes.difference_amount}
                </Typography>
                </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={6} xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.containedButton}
                  onClick={() => handleCancel(detail.attributes.id)}
                  disabled={detail.attributes.status !== 'IN PROGRESS'}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          {/* </div> */}

          <InformationItem
            title="Total Project Cost"
            value={detail.attributes.requested_base_amount}
          />

          <InformationItem
            title="Dealer Net Funding"
            value={detail.attributes.co_dealer_net_funding}
          />

          <InformationItem
            title="Monthly Payment"
            value={detail.attributes.co_monthly_rate}
          />
        </div>
      </div>
    </>
  );
};

export default ChangeOrderCard;
