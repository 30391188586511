import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useField } from 'formik';
import { find, propEq } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Autocomplete,createFilterOptions} from '@material-ui/lab';
import { OutlinedTextInput } from './InputField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
  queryFilter: {
    '& .MuiAutocomplete-inputRoot': {
      marginTop: '3px',
      padding: '3.5px !important',
      width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(30,39,46,0.2)',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '0.5rem 0.5rem 0.5rem 0',
    },
  },
}));

export const AutocompleteInput = ({ label, options, ...props }) => {
  const classes = useStyles();
  if (!options) {
    console.warn('no options value');
    return null;
  }
  // eslint-disable-next-line react/destructuring-assignment
  const placeholder = props?.placeholder;
  return (
    <Autocomplete
      options={options}
      autoHighlight
      getOptionSelected={(option, value) => option?.value === value}
      getOptionLabel={(option) => {
        return option?.label === undefined ? '' : option?.label;
      }}
      renderOption={(option) => <>{option.label}</>}
      renderInput={(params) => (
        <OutlinedTextInput
          label={label}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          className={placeholder && classes.queryFilter}
          placeholder={placeholder}
          size={placeholder ? 'small' : ''}
        />
      )}
      {...props}
    />
  );
};

export const AutocompleteField = ({
  name,
  hideErrors = false,
  disabled,
  options,
  getCurrentValue,
  type,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });
  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.label} ${option.dealerCode}`,
  });
  const [currentValue, setCurrentValue] = useState(null);
  useEffect(() => {
    if (type !== 'dealerFilter' ? options && field.value : options) {
      setCurrentValue(
        find(propEq('value', field.value))(options || []) ||
          (!field?.value && ''),
      );
      type === 'dealerFilter' &&
        getCurrentValue(
          type !== 'dealerFilter'
            ? find(propEq('value', field.value))(options || [])
            : field?.value,
        );
    }
  }, [options, field]);

  return (
    <>
      {type === 'dealerFilter' ? (
        <FormControl
          disabled={disabled}
          variant="outlined"
          className={clsx(classes.formControl, {
            [classes.error]: meta.error,
          })}
          error={Boolean(meta.error)}
        >
          <AutocompleteInput
            options={options}
            disabled={disabled}
            {...field}
            {...props}
            filterOptions={filterOptions}
            renderOption={(option) => (
              <>
                {option.label} ({option?.dealerCode})
              </>
            )}
            getOptionLabel={(option) => {
              return option?.label === undefined ? '' : `${option?.label} (${option.dealerCode})`;
            }}
            value={currentValue}
            onChange={(ev, selectedValue) => {
              helpers.setValue(selectedValue?.value);
            }}
          />
          {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      ) : (
        <FormControl
          disabled={disabled}
          variant="outlined"
          className={clsx(classes.formControl, {
            [classes.error]: meta.error,
          })}
          error={Boolean(meta.error)}
        >
          <AutocompleteInput
            options={options}
            disabled={disabled}
            {...field}
            {...props}
           
            value={currentValue}
            onChange={(ev, selectedValue) => {
              helpers.setValue(selectedValue?.value);
            }}
          />
          {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      )}
    </>
  );
};
