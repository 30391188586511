import { reduce } from 'ramda';
import { useEffect, useState } from 'react';

const usePageTableParameters = ({ initialOrder, rows }) => {
  const [selected, setSelected] = useState({});
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [order, setOrder] = useState(initialOrder);
  const [sort, setSort] = useState('desc');

  const [selectedIds, setSelectedIds] = useState([]);
  useEffect(() => {
    setSelectedIds(
      reduce(
        (object, index) => {
          if (selected[index]) {
            const rowData = rows[index][0];
            const state = rowData.state;
            return [
              ...object,
              {
                id: state.applicationId || state.projectId,
                subValue: rowData.subValue,
              },
            ];
          }
          return object;
        },
        [],
        Object.keys(selected),
      ),
    );
  }, [selected]);

  return {
    selected,
    setSelected,
    order,
    setOrder,
    sort,
    setSort,
    page,
    setPage,
    perPage,
    setPerPage,
    selectedIds,
    setSelectedIds,
  };
};

export default usePageTableParameters;
