import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import { useAlertContext } from 'components/AlertContext';
import { protectedPost } from 'services/http';
import { StateSelectField } from 'components/InputFields/StateSelectField';
import Text from 'components/TextFields/Text';

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 24,
      },
    },
    editSection: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
    },
    editSection__label: {
      color: theme.palette.primary.main,
      marginBottom: '0.5rem',
    },

    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',

      '& > *': {
        maxWidth: '50%',
        '&:first-child': {
          marginRight: '1rem',
        },
        '&:last-child': {
          marginLeft: '1rem',
        },
      },
    },
    outlinedTextInput: {
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1E272E',
      },
    },
    saveButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    saveButton: {
      backgroundColor: `${theme.palette.primary.main}`,
      color: 'white',
      fontWeight: 'bolder',
      padding: '0.5rem 2rem',
    },
    actionButton: {
      height: 40,
      width: '32%',
      margin: '0 1rem',
      [theme.breakpoints.down('md')]: {
        width: '90%',
        margin: '1rem 0',
        maxWidth: '32rem',
      },
    },
  };
});

// Edit Section wrapper
const EditSection = ({ title, classes, children }) => (
  <Box className={classes.editSection}>
    <Box className={classes.editSection__label}>{title}</Box>
    <Box className={classes.inputRow}>{children}</Box>
  </Box>
);

const CoApplicantDriverLicenseTab = ({ application, setFetchedData }) => {
  const classes = useStyles();
  const [icoapplicantData, setIcoapplicantData] = useState(null);

  const [coapplicantData, setcoapplicantData] = useState(null);
  const [fieldsUpdated, setFieldsUpdated] = useState(false);
  const [canSave, setCanSave] = useState(false);
  useEffect(() => {
    const { coapplicantData } = application || {
      coapplicantData: null,
    };
    // Saving initial data
    setIcoapplicantData(coapplicantData);
    // Saving copy for edit
    setcoapplicantData(coapplicantData);
    setInitialValues({
      coApplicantDlState: coapplicantData.driverLicenseState,
    });
  }, []);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  useEffect(() => {
    const coapplicantFields = ['driverLicenseNumber'];
    let hasChanges = false;
    if (coapplicantData) {
      coapplicantFields.forEach((field) => {
        if (coapplicantData[field] !== icoapplicantData[field]) {
          hasChanges = true;
        }
        if (hasChanges) {
          return null;
        }
      });
    }
    setCanSave(hasChanges);
  }, [coapplicantData]);

  const [initialValues, setInitialValues] = useState({
    coApplicantDlState: '',
  });
  const onSubmit = async (values) => {
    const applicationId = application.applicationData.id;
    const url = `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationId}/update_driver_license`;
    try {
      await protectedPost(url, {
        coapplicant_driver_license_number: coapplicantData?.driverLicenseNumber,
        coapplicant_driver_license_state: selectedState,
      });
      setFetchedData(false);
      setAlertMessage('Co-applicant data updated succesfully');
    } catch (e) {
      setErrorAlertMessage(
        e?.response?.data?.message || 'There was an error updating your info',
      );
    }
  };
  const [selectedState, setSelectedState] = useState('');
  return coapplicantData ? (
    <div className={classes.container}>
      <EditSection title="COAPPLICANT DRIVER LICENSE" classes={classes}>
        <TextField
          className={classes.outlinedTextInput}
          label="Number"
          name="number"
          type="string"
          variant="outlined"
          value={coapplicantData.driverLicenseNumber}
          onChange={(e) =>
            setcoapplicantData((old) => ({
              ...old,
              driverLicenseNumber: e.target.value,
            }))
          }
        />
      </EditSection>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values }) => {
          return (
            <>
              <Form>
                <EditSection title="" classes={classes}>
                  <StateSelectField
                    label="State"
                    name="coApplicantDlState"
                    type="codeType"
                    setSelectedState={setSelectedState}
                    inputProps={{ autoComplete: 'none' }}
                  />
                </EditSection>
                <Box className={classes.saveButtonWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionButton}
                    type="submit"
                    disabled={canSave === false && fieldsUpdated === false}
                  >
                    <Text textSize="md" textColor="white" bold>
                      SAVE CHANGES
                    </Text>
                  </Button>
                </Box>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  ) : (
    <Box className={classes.inputRow}>Loading...</Box>
  );
};

export { CoApplicantDriverLicenseTab };
