import * as React from 'react';

function SignOutIcon({ width, height, color, flip }) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{
        enableBackground: 'new 0 0 512 512',
        transform: flip ? 'rotate(180deg)' : null,
      }}
      xmlSpace="preserve"
      width={width || '24px'}
      height={height || '24px'}
    >
      <g fill={color}>
        <path
          d="M452.033,0.142H60.251c-33.066,0-59.967,26.901-59.967,59.967v79.956h39.978V60.109c0-11.022,8.967-19.989,19.989-19.989
			h391.783c11.022,0,19.989,8.967,19.989,19.989v391.783c0,11.022-8.967,19.989-19.989,19.989H60.251
			c-11.022,0-19.989-8.967-19.989-19.989v-79.956H0.284v79.956c0,33.066,26.901,59.967,59.967,59.967h391.783
			c33.066,0,59.967-26.901,59.967-59.967V60.109C512,27.043,485.099,0.142,452.033,0.142z"
        />
        <polygon
          points="364.082,236.011 76.526,236.011 152.483,160.053 124.215,131.785 0,256 124.215,380.215 152.483,351.947 
			76.526,275.989 364.082,275.989"
        />
      </g>
    </svg>
  );
}

export default SignOutIcon;
