import { useState, useEffect } from 'react';
import { parse } from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import to from 'await-to-js';

import { setToken } from 'services/authentication';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';

export const RedirectComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [fetched, setFetched] = useState(false);
  const { user, setUser } = useUserContext();
  const { destination, id, uid, 'access-token': accessToken, client, 'new_flow': newFlow } = parse(
    location.search.replace('?', ''),
  );

  useEffect(() => {
    setToken(
      JSON.stringify({
        'access-token': accessToken,
        client,
        uid,
      }),
    );

    const fetch = async () => {
      const [, res] = await to(protectedGet(`/v1/users/-1`));

      if (res) {
        const userObj = {
          tokenLogin: true,
          'access-token': accessToken,
          client,
          uid,
          ...res.data,
        };
        setToken(JSON.stringify(userObj));
        setUser(userObj);
        setFetched(true);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    if (fetched && !user?.data) {
      console.error('no user');
      return;
    }
    if (user?.data) {
      switch (destination) {
        case 'application':
          history.push('/applications/new', {
            applicationId: id,
          });
          break;
         case 'dealer-member-application': {
          newFlow === "true"
            ? history.push(`/applications/${id}/review`)
            : history.push(`/dealer/applications/${id}`)
          break;
        }
        case 'prequal-detail':
          history.push(`/prequalify/${id}/detail`);
          break;
        default:
          break;
      }
    }
  }, [user, destination]);

  return null;
};
