import React from 'react';
import { TextField } from 'formik-material-ui';

import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: 12,
    marginBottom: 12,
    maxWidth: 345,
    minHeight: 61,
    width: '100%',

    '& .MuiInputBase-root.MuiOutlinedInput-root': {
      paddingRight: 0,
    },
    '& .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
      position: 'absolute',
      right: 0,
    },
  },
}));

const PasswordField = ({
  showPassword,
  togglePasswordReveal,
  touched,
  errors,
  name,
  label,
}) => {
  const classes = useStyles();

  return (
    <Field
      component={TextField}
      className={classes.textField}
      name={name}
      type={showPassword}
      label={label}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordReveal}
              onMouseDown={togglePasswordReveal}
            >
              {showPassword === 'password' ? (
                <Visibility
                  style={{
                    color:
                      touched.password && errors.password
                        ? '#ff4757'
                        : '#A4B0BE',
                  }}
                />
              ) : (
                <VisibilityOff
                  style={{
                    color:
                      touched.password && errors.password
                        ? '#ff4757'
                        : '#A4B0BE',
                  }}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
