import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PhoneVerification from 'components/PhoneVerification';
import Text from 'components/TextFields/Text';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 480,
    height: 560,
    padding: '2.75rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '4rem 2.75rem',
    },
  },
  title: {
    marginBottom: '0.5rem',
  },
  grid: {
    [theme.breakpoints.up('sm')]: {
      padding: '1rem',
    },
  },
}));

export default function ValidatePhone({ isUpdatePhone, toggle }) {
  const classes = useStyles();
  const { values, errors } = useFormikContext();

  const handleNext = () => {
    setTimeout(() => {
      toggle();
    }, 0);
  };
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid className={classes.grid} item xs={12} md={12}>
          <Text
            textColor="#1E272E"
            textFontSize={22}
            textFontWeight={600}
            textLetterSpacing={0.5}
            textLineHeight="28px"
            className={classes.title}
          >
            Confirm your Phone Number
          </Text>
          <Text
            textColor="#636A72"
            textFontSize={14}
            textLetterSpacing={0.65}
            textLineHeight="18px"
          >
            Unlock all your Powerpay benefits
          </Text>
        </Grid>
        <Grid className={classes.grid} item xs={12} md={12}>
          <PhoneVerification
            isUpdatePhone={isUpdatePhone}
            handleNext={handleNext}
            phone={values.phone}
            errors={errors}
          />
        </Grid>
      </Grid>
    </div>
  );
}
