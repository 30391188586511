import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import to from 'await-to-js';
import NumberFormat from 'react-number-format';
import { updateData } from 'redux/actions';
import {
  Grid,
  Box,
  TextField,
  FormControl,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { useStyles } from './useStyles';
import "./MUIStylesOverride.css";
import { StepSubtitle, StepTitle } from './DealerSection';
import { castFormattedCurrencyStringToNumber, formatNumberToCurrencyString, AutocompleteSelect } from "./CustomizedMUIInputs";

const LoanProduct = ({
  selectedLoanProduct,
  primaryId,
  dealerCodeRes,
  dealsData,
  changeFieldValue,
  updateData,
  enableStageFunding,
  maximumRequestAmount
}) => {
  // can't delete, used in the consumer form
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUserContext();
  const { dealerLevelRole } = useUserContext();

  const [loanAmountError, setLoanAmountError] = useState('');
  const [projectAmountTotalError, setProjectAmountTotalError] = useState("");
  const [downPaymentError, setDownPaymentError] = useState("")
  const [productAndDescription, setProductAndDescription] = useState(false);
  
  const [internallySelectedLoanProduct, setInternallySelectedLoanProduct] = useState(null); /** @type {Object} the currently selected loan product off of the loanProductsOptions */
  const [loanProductsOptions, setLoanProductsOptions] = useState(null); /** @type {Object[]} literal response of backend's fetch of loan options for any given dealer */

  useEffect(() => {
    if (selectedLoanProduct !== internallySelectedLoanProduct) {
      updateData({
        name: 'selectedLoanProduct',
        value: internallySelectedLoanProduct,
      });
    }
  }, [internallySelectedLoanProduct])

  useEffect(() => {

    const getListOfOptionsForNewDealer = async () => {
      let url = '';
      let loanProductParam = '';
      let primaryProductId = primaryId;
      if (user) {
        if (
          ['affiliate', 'organization', 'super_admin'].includes(dealerLevelRole)
        ) {
          if (dealsData.dealer_code) {
            const [err, res] = await to(
              protectedGet(
                `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_code?dealer_code=${dealsData.dealer_code}`,
              ),
            );
            if (res) {
              if (res?.data?.data?.attributes?.primary_product_id) {
                primaryProductId =
                  res?.data?.data?.attributes?.primary_product_id;
              }
              url = `/v2/loan_products/public_index?dealer_code=${dealsData.dealer_code}`;
            }
          }
        } else {
          url = `/v1/dealers/${user?.data?.id}/loan_products`;
        }
      } else {
        if (dealerCodeRes) {
          url = `/v2/loan_products/public_index?dealer_code=${dealerCodeRes}`;
        }
  
        const pathParams = window.location.search.split('=');
        loanProductParam = pathParams[pathParams.length - 1].replaceAll('_', ' ');
      }
  
      if (url !== '') {
        const [error, endpointResponseLoanProducts] = await to(protectedGet(url));
        const newLoanProducts = endpointResponseLoanProducts?.data?.data;
  
        if (!newLoanProducts || newLoanProducts.length === 0 || error) {
          updateData({
            name: 'invalidDealerConfig',
            value: true,
          });
          history.push('/errorPage');
        }
        if (endpointResponseLoanProducts) {
          setLoanProductsOptions(newLoanProducts);
  
          if (loanProductParam) { // honestly idk what this does, and it looks badly designed, but i'm leaving as close to the original iteration as possible just in case. Maybe later i should take a look at it.
            const loanProductWithNameEqualToLoanProductParam = newLoanProducts.find(elem => loanProductParam === elem.attributes.name.toLowerCase());
            if (loanProductWithNameEqualToLoanProductParam){
              setInternallySelectedLoanProduct(loanProductWithNameEqualToLoanProductParam);
              return;
            }
          }
          if (primaryProductId){
            const loanProductWithIdEqualToPrimaryProductId = newLoanProducts.find(elem => elem.attributes.id === primaryProductId);
            if (loanProductWithIdEqualToPrimaryProductId){
              setInternallySelectedLoanProduct(loanProductWithIdEqualToPrimaryProductId);
              return;
            }
          }
          // if no loanProductParam or primaryProductId match, select the first element of newLoanProducts as the newly selected loan product
          setInternallySelectedLoanProduct(newLoanProducts[0]);
        }
      }
    }

    getListOfOptionsForNewDealer();
  
  }, [dealerCodeRes]);

  useEffect(() => {
    if (!enableStageFunding) return;

    const financedAmountAsNumber = dealsData?.request_amount ? castFormattedCurrencyStringToNumber(dealsData?.request_amount) : 0;
    const downPaymentAsNumber = dealsData?.down_payment_amount ? castFormattedCurrencyStringToNumber(dealsData?.down_payment_amount) : 0;

    const thereIsNoFinancedAmount = !financedAmountAsNumber || !dealsData?.request_amount || dealsData?.request_amount === "$0"

    if (thereIsNoFinancedAmount){
      if (!dealsData?.project_amount_total || dealsData?.project_amount_total !== ''){
        changeFieldValue('project_amount_total', '');
      }
      return;
    }

    changeFieldValue('project_amount_total', `${financedAmountAsNumber + downPaymentAsNumber}`);

  }, [dealsData?.request_amount, dealsData?.down_payment_amount]);

  return (
    <>
      <Box pt={3} pb={3}>
        <StepTitle>Loan Product</StepTitle>
        <StepSubtitle>
          Please select the loan product that best describes the purpose of your
          loan or
          <button
            type="button"
            style={{
              backgroundColor: 'white',
              border: 'none',
              fontSize: '0.8rem',
              marginTop: '0.5rem',
              color: '#f79d3c',
              fontWeight: 600,
              cursor: 'pointer',
            }}
            className="link-button"
            onClick={() => setProductAndDescription(true)}
          >
            review the product details and eligibility.
          </button>
        </StepSubtitle>
        <ConfirmationDialog
          desc={selectedLoanProduct?.attributes?.name}
          subDesc={selectedLoanProduct?.attributes?.description}
          dialogState={productAndDescription}
          handleClose={() => setProductAndDescription(false)}
          infoPopup
        />
      </Box>
      <Grid container spacing={3} rowSpacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
          >
            {
              loanProductsOptions && 
              <AutocompleteSelect 
                options={loanProductsOptions}
                value={internallySelectedLoanProduct}
                getOptionLabel={(option) => option.attributes.name || ""}
                onChange={(newOption) =>  {
                  setInternallySelectedLoanProduct(newOption);
                  if (newOption?.attributes?.code === "healthcare"){
                    changeFieldValue('down_payment_amount', '');
                  }
                }}
                label="Select a Loan Product"
                required
              />
            }
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={6}>
          {enableStageFunding ? (
              <>
                <NumberFormat
                  fullWidth
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                  error={loanAmountError}
                  variant="outlined"
                  helperText={loanAmountError}
                  onBlur={(e) => {
                    if (!e.target.value) {
                      setLoanAmountError('This Field is required');
                      return;
                    }
                    if (maximumRequestAmount && dealsData?.request_amount > maximumRequestAmount) {
                      setLoanAmountError('Maximum Financed Amount Limit Exceeded');
                      return;
                    }
                    setLoanAmountError('');
                  }}
                  className={classes.formControlInput}
                  label="Financed Amount"
                  value={dealsData?.request_amount}
                  customInput={TextField}
                  allowNegative={false}
                  allowLeadingZeros={false}
                  prefix="$"
                  type="text"
                  isAllowed={({ value: v }) => {
                    return v === '0' ? false : true;
                  }}
                  thousandSeparator
                  isNumericString
                  autoComplete="off"
                  onValueChange={({ value: v }) => {
                    if (v) {
                      setLoanAmountError('');
                    }
                    changeFieldValue('request_amount', v);
                  }}
                />
                {
                  selectedLoanProduct?.attributes?.code !== "healthcare"
                      ? <>
                          <NumberFormat
                              fullWidth
                              inputProps={{
                                autoComplete: 'new-password',
                              }}
                              error={downPaymentError}
                              variant="outlined"
                              helperText={downPaymentError}
                              className={classes.formControlInput}
                              label="Down Payment"
                              value={dealsData?.down_payment_amount}
                              customInput={TextField}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              prefix="$"
                              type="text"
                              isAllowed={({ value: v }) => {
                                return v === '0' ? false : true;
                              }}
                              thousandSeparator
                              isNumericString
                              autoComplete="off"
                              onValueChange={({ value: v }) => {
                                changeFieldValue('down_payment_amount', v);
                              }}
                          />
                          <NumberFormat
                              fullWidth
                              inputProps={{
                                autoComplete: 'new-password',
                              }}
                              error={projectAmountTotalError}
                              variant="outlined"
                              helperText={projectAmountTotalError}
                              className={`${classes.formControlInput} MUI-override_input-read-only`}
                              label="Project Amount Total"
                              value={dealsData?.project_amount_total}
                              customInput={TextField}
                              allowNegative={false}
                              allowLeadingZeros={false}
                              prefix="$"
                              type="text"
                              isAllowed={({ value: v }) => {
                                return v === '0' ? false : true;
                              }}
                              thousandSeparator
                              isNumericString
                              autoComplete="off"
                              disabled
                          />
                      </>
                      : null
                }
              </>
            ) : (
              <NumberFormat
                fullWidth
                inputProps={{
                  autoComplete: 'new-password',
                }}
                error={loanAmountError}
                variant="outlined"
                helperText={loanAmountError}
                onBlur={(e) => {
                  if (!e.target.value) {
                    setLoanAmountError('This Field is required');
                    return;
                  }
                  if (maximumRequestAmount && dealsData?.request_amount > maximumRequestAmount) {
                    setLoanAmountError('Maximum Request Amount Limit Exceeded');
                    return;
                  }
                  setLoanAmountError('');
                }}
                className={classes.formControlInput}
                label="Request Amount"
                value={dealsData?.request_amount}
                customInput={TextField}
                allowNegative={false}
                allowLeadingZeros={false}
                prefix="$"
                type="text"
                isAllowed={({ value: v }) => {
                  return v === '0' ? false : true;
                }}
                thousandSeparator
                isNumericString
                autoComplete="off"
                onValueChange={({ value: v }) => {
                  if (v) {
                    setLoanAmountError('');
                  }
                  changeFieldValue('request_amount', v);
                }}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    selectedLoanProduct: state.appReducer.selectedLoanProduct,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(LoanProduct);
