import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import TimelineLogCard from './TimelineLogCard';

const useStyles = makeStyles((theme) => ({
  logContainer: {
    backgroundColor: theme.palette.text.lightGray,
    padding: '0.5rem',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
  },
  logTitle: {
    color: theme.palette.text.primary,
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1rem',
    letterSpacing: 0.5,
    marginBottom: '0.5rem',
  },
}));

const TimelineStageCard = ({ timelineData }) => {
  const classes = useStyles();
  return (
    <>
      {timelineData &&
        timelineData.map(
          (data, index) =>
            data?.attributes?.applicationLogs?.length !== 0 && (
              <div key={index} className={classes.logContainer}>
                <Typography
                  data-testid={`stageitem${index}`}
                  key={index}
                  className={classes.logTitle}
                >
                  {data?.attributes?.label}
                </Typography>
                <TimelineLogCard logData={data?.attributes?.applicationLogs} />
              </div>
            ),
        )}
    </>
  );
};

export default TimelineStageCard;
