import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import IMask from 'imask';

import { makeStyles } from '@material-ui/core/styles';
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },

    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      padding: '0 0.25rem',
      background: 'white',
    },
  },
}));

export const ssnEinPipe = IMask.createPipe({
  mask: [{mask:'000-00-0000'},{mask:'00-0000000'}]
});
export const ssnPipe = IMask.createPipe({
  mask: '000-00-0000',
});
export const hideSsn = (ssn) => {
  const regex = /^(\d{1,3})-?(\d{1,2})?-?(\d{1,4})?$/gi;
  return (
    ssn.replace(regex, (match, p1, p2, p3, offset, string) => {
      let hiddenValue = '';
      if (p1) {
        hiddenValue += p1.replace(/\d/g, 'X');
      }
      if (p2) {
        hiddenValue += `-${p2.replace(/\d/g, 'X')}`;
      }
      if (p3) {
        hiddenValue += `-${p3}`;
      }
      return hiddenValue;
    }) || ''
  );
};

export const MaskedField = ({
  id,
  name,
  label,
  disabled,
  hideErrors = false,
  className,
  hideFn,
  pipeFn,
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });

  const [currentValue, setCurrentValue] = useState({
    value: hideFn(pipeFn(field.value)),
    unmaskedValue: pipeFn(field.value),
  });

  useEffect(() => {
    if (field.value !== currentValue.unmaskedValue) {
      setCurrentValue({
        value: hideFn(pipeFn(field.value)),
        unmaskedValue: pipeFn(field.value),
      });
    }
  }, [field.value]);

  if (!id) {
    console.warn('no id for input');
  }

  return (
    <FormControl
      className={clsx(classes.formControl, className)}
      variant="outlined"
      error={Boolean(meta.error)}
    >
      <InputLabel variant="outlined" htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        type="tel"
        value={currentValue.value}
        disabled={disabled}
        onChange={(ev) => {
          const v = ev.currentTarget.value;
          setCurrentValue((state) => ({
            ...state,
            value: pipeFn(v),
          }));
        }}
        inputProps={{
          onFocus: () => {
            setCurrentValue((state) => ({
              ...state,
              value: state.unmaskedValue,
            }));
          },
          onBlur: () => {
            helpers.setValue(currentValue.value);
            setCurrentValue((state) => ({
              ...state,
              value: hideFn(state.value),
              unmaskedValue: state.value,
            }));
          },
        }}
      />
      {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};

export const MaskedInput = ({
  id,
  label,
  disabled,
  value,
  className,
  hideFn,
  pipeFn,
}) => {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState({
    value: hideFn(pipeFn(value)),
    unmaskedValue: pipeFn(value),
  });

  useEffect(() => {
    if (value !== currentValue.unmaskedValue) {
      setCurrentValue({
        value: hideFn(pipeFn(value)),
        unmaskedValue: pipeFn(value),
      });
    }
  }, [value]);

  if (!id) {
    console.warn('no id for input');
  }

  return (
    <FormControl
      className={clsx(classes.formControl, className)}
      variant="outlined"
    >
      <InputLabel variant="outlined" htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        type="tel"
        value={currentValue.value}
        disabled={disabled}
        onChange={(ev) => {
          const v = ev.currentTarget.value;
          setCurrentValue((state) => ({
            ...state,
            value: pipeFn(v),
          }));
        }}
        inputProps={{
          onFocus: () => {
            setCurrentValue((state) => ({
              ...state,
              value: state.unmaskedValue,
            }));
          },
          onBlur: () => {
            setCurrentValue((state) => ({
              ...state,
              value: hideFn(state.value),
              unmaskedValue: state.value,
            }));
          },
        }}
      />
    </FormControl>
  );
};

export const SsnField = (props) => {
  const {ssnEin} = props;
  return <MaskedField {...props} pipeFn={ssnEin?ssnEinPipe:ssnPipe} hideFn={hideSsn}  />;
};

export const SsnInput = (props) => {
  return <MaskedInput {...props} pipeFn={ssnPipe} hideFn={hideSsn} />;
};
