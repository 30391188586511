import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { pathSplit, translateEmploymentType } from 'services/utils';
import { map, addIndex } from 'ramda';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles, MenuItem } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { MoneyFormatField } from 'components/InputFields/MoneyFormatField';
import EmployeeActive from 'assets/icons/employee-active.png';
import EmployeeInactive from 'assets/icons/employee-inactive.png';
import SelfEmployeeActive from 'assets/icons/self-employee-active.png';
import SelfEmployeeInactive from 'assets/icons/self-employee-inactive.png';
import RetiredActive from 'assets/icons/retired-active.png';
import RetiredInactive from 'assets/icons/retired-inactive.png';
import { SelectField } from 'components/InputFields/SelectField';
import { financialDetailAttributes } from './helpers';

const getTexts = (isDealer = false, dealerRole = 'dealership') => {
  const HOTexts = {
    stepTitle: '',
    stepSubtitle: '',
    title: '',
    subtitle: 'Choose your type of employment and fill out the information',
    subtitleCO: 'This is the information related to your Co-Applicant',
  };
  const dealershipTexts = {
    stepTitle: '',
    stepSubtitle: '',
    title: '',
    subtitle:
      "Choose your client's type of employment and fill out the information",
    subtitleCO: 'This is the information related to the Co-Applicant',
  };
  if (isDealer) {
    switch (dealerRole) {
      case 'dealership':
      default:
        return dealershipTexts;
    }
  } else {
    return HOTexts;
  }
};

const validationSchemaFn = (coapplicant) => {
  const schema = Yup.object({
    employmentType: Yup.mixed().oneOf(['employed', 'self_employed', 'retired']),
    anualIncome: Yup.string().required('Field Required').trim(),
    employerName: Yup.string().when('employmentType', {
      is: (val) => val !== 'retired',
      then: Yup.string().required('Field Required').trim(),
    }),
    employerZip: Yup.string().when('employmentType', {
      is: (val) => val !== 'retired',
      then: Yup.string().required('Field Required').trim(),
    }),
    occupation: Yup.mixed().when('employmentType', (value, schema2) => {
      switch (value) {
        case 'employed':
          return schema2.test('is-empty', 'Field Required', (val) =>
            Boolean(val),
          );
        case 'self_employed':
          return schema2.oneOf(
            [
              'CEO',
              'President',
              'General Manager',
              'Manager',
              'VP',
              'Owner',
              'Self',
            ],
            'Field Required',
          );
        case 'retired':
          return schema2;
        default:
          return null;
      }
    }),
    employerTime: Yup.string().when('employmentType', {
      is: (val) => val !== 'retired',
      then: Yup.string().required('Field Required'),
    }),
  }).nullable();
  return Yup.object({
    financialDetails: Yup.array()
      .transform((current) => {
        return addIndex(map)((item, index) => {
          return index === (coapplicant ? 0 : 1) ? null : item;
        }, current); // sets the unneeded object to null so it passes through the validation
      })
      .of(schema),
  });
};

const useStyles = makeStyles((theme) => ({
  employmentContainer: {
    justifyContent: 'space-between',
    padding: '3rem 0.75rem',
    [theme.breakpoints.up('md')]: {
      padding: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0 0.75rem',
    },
  },
  employmentBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'solid 3px',
    borderColor: ({ isSelected }) =>
      isSelected ? theme.palette.primary.light : '#C1C9D1',
    width: '14rem',
    height: '8rem',
    borderRadius: 10,
    backgroundColor: ({ isSelected }) =>
      isSelected ? 'rgba(255,206,108,0.02)' : '#F9FAFC',

    [theme.breakpoints.down('sm')]: {
      width: '9rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '9rem',
      margin: '0.5rem 0',
      height: '4.5rem',
    },

    '&:hover': {
      backgroundColor: ({ isSelected }) =>
        isSelected ? 'rgba(255,206,108,0.1)' : 'rgba(255,255,255,0.1)',
    },
  },
  employmentImage: {
    width: 70,
    height: 70,
    [theme.breakpoints.down('xs')]: {
      width: 48,
      height: 48,
    },
  },
}));

const SpacedGrid = withStyles((theme) => ({
  root: {
    padding: '0.75rem',
  },
}))(Grid);

export const EmploymentBox = ({ employment, coapplicant }) => {
  const { values, setFieldValue } = useFormikContext();

  const key = coapplicant ? 'financialDetails[1]' : 'financialDetails[0]';
  const employmentType = pathSplit(values, `${key}.employmentType`);
  const isSelected = employmentType === employment ? 1 : 0;

  const classes = useStyles({ isSelected });
  const theme = useTheme();

  const setEmploymentType = (employmentType) => () => {
    setFieldValue(`${key}`, {
      ...financialDetailAttributes,
      userType: coapplicant ? 'coapplicant' : 'homeowner',
    });
    setFieldValue(`${key}.employmentType`, employmentType);
  };

  return (
    <Box
      className={classes.employmentBox}
      selected={isSelected}
      onClick={setEmploymentType(employment)}
    >
      <img
        alt="employee icon"
        src={(() => {
          switch (employment) {
            case 'employed':
              return isSelected ? EmployeeActive : EmployeeInactive;
            case 'self_employed':
              return isSelected ? SelfEmployeeActive : SelfEmployeeInactive;
            case 'retired':
              return isSelected ? RetiredActive : RetiredInactive;
            default:
              return null;
          }
        })()}
        className={classes.employmentImage}
      />
      <Text
        textSize="sm"
        semibold
        textColor={
          isSelected ? theme.palette.primary.main : theme.palette.text.secondary
        }
        textAlign="center"
        style={{ pointerEvents: 'none' }}
        className={{}}
      >
        {translateEmploymentType(employment).toUpperCase()}
      </Text>
    </Box>
  );
};

export const Step5Employment = ({
  isActive,
  setChildCallbacks,
  coapplicant,
  isDealer,
  dealerRole,
}) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return TEXTS.stepTitle;
        },
        get stepSubtitle() {
          return TEXTS.stepSubtitle;
        },
        get validationSchema() {
          return validationSchemaFn(coapplicant);
        },
      });
    }
    return () => {};
  }, [isActive]);

  const theme = useTheme();
  const classes = useStyles();

  const { values } = useFormikContext();
  const key = coapplicant ? 'financialDetails[1]' : 'financialDetails[0]';
  const employmentType = pathSplit(values, `${key}.employmentType`);

  const TEXTS = getTexts(isDealer, dealerRole);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Text textColor={theme.palette.text.primary} textSize="sm+" semibold>
            {coapplicant ? 'Employment' : 'Employment Type'}
          </Text>
          <Text
            textColor={theme.palette.text.secondary}
            textSize="xs+"
            semibold
          >
            {coapplicant ? TEXTS.subtitleCO : TEXTS.subtitle}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" className={classes.employmentContainer}>
          <EmploymentBox coapplicant={coapplicant} employment="employed" />
          <EmploymentBox coapplicant={coapplicant} employment="self_employed" />
          <EmploymentBox coapplicant={coapplicant} employment="retired" />
        </Box>
      </Grid>

      {(() => {
        switch (employmentType) {
          case 'employed':
            return <EmployedForm coapplicant={coapplicant} />;
          case 'self_employed':
            return <SelfEmployedForm coapplicant={coapplicant} />;
          case 'retired':
            return <RetiredForm coapplicant={coapplicant} />;
          default:
            return null;
        }
      })()}
    </Grid>
  );
};

const EmployedForm = ({ coapplicant }) => {
  const key = coapplicant ? 'financialDetails[1]' : 'financialDetails[0]';
  return (
    <>
      <SpacedGrid item xs={12} md={4}>
        <MoneyFormatField
          label="Gross Annual Income"
          name={`${key}.anualIncome`}
        />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={4}>
        <OutlinedTextField label="Employer Name" name={`${key}.employerName`} />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={4}>
        <OutlinedTextField
          label="Employer Zip Code"
          name={`${key}.employerZip`}
        />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField label="Occupation" name={`${key}.occupation`} />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField
          label="Years of Employment"
          name={`${key}.employerTime`}
          type="number"
        />
      </SpacedGrid>
    </>
  );
};

const SelfEmployedForm = ({ coapplicant }) => {
  const key = coapplicant ? 'financialDetails[1]' : 'financialDetails[0]';
  return (
    <>
      <SpacedGrid item xs={12} md={6}>
        <MoneyFormatField
          label="Gross Annual Income"
          name={`${key}.anualIncome`}
        />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <SelectField label="Title" name={`${key}.occupation`}>
          <MenuItem value="CEO">CEO</MenuItem>
          <MenuItem value="President">President</MenuItem>
          <MenuItem value="General Manager">General Manager</MenuItem>
          <MenuItem value="Manager">Manager</MenuItem>
          <MenuItem value="VP">VP</MenuItem>
          <MenuItem value="Owner">Owner</MenuItem>
          <MenuItem value="Self">Self</MenuItem>
        </SelectField>
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField label="Company Name" name={`${key}.employerName`} />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField
          label="Company Zip Code"
          name={`${key}.employerZip`}
        />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField
          label="Years of Employment"
          name={`${key}.employerTime`}
          type="number"
        />
      </SpacedGrid>
    </>
  );
};

const RetiredForm = ({ coapplicant }) => {
  const key = coapplicant ? 'financialDetails[1]' : 'financialDetails[0]';
  return (
    <>
      <SpacedGrid item xs={12} md={4}>
        <MoneyFormatField
          label="Gross Annual Income"
          name={`${key}.anualIncome`}
        />
      </SpacedGrid>
    </>
  );
};
