import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Text from 'components/TextFields/Text';
import EmptyImage from '../../../../../assets/icons/empty-img.png';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },

  titleWrapper: {
    backgroundColor: '#C1C9D166',
    padding: 11,
  },
  title: {
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    display: 'flex',
    fontSize: 10,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: '16px',
  },
  contentWrapper: {
    backgroundColor: '#F9FAFC',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    paddingLeft: 13,
  },
  content: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
    width: 130,
  },
  contentLabel: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    lineHeight: '12px',
    letterSpacing: 0.55,
    display: 'block',
  },
  total: {
    borderRadius: 4,
    border: 'solid 0.5px rgba(193,201,209,0.4)',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 'auto',
      maxWidth: 195,
    },
  },
  containedButton: {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 600,
    // width: 198,
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
}));

const NoChangeOrders = ({
  changeOrderDetails,
  createFlag,
  setcreateFlag,
  fundingStatus,
  allPaymentsDone,
  disableCreateChangeOrder
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    changeOrderDetails.length === 0 &&
    !createFlag && (
      <Box
        data-testid="noChangeOrder-container"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        height="100%"
        width="100%"
        paddingBottom="5%"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="24rem"
        >
          <img
            alt="No Items"
            src={EmptyImage}
            height="156px"
            width="172px"
            style={{ marginRight: '24px' }}
          />
          <Text
            textSize="lg"
            textColor={theme.palette.text.primary}
            bold
            textAlign="center"
            style={{ margin: '0.25rem 0' }}
          >
            No Change orders, yet.
          </Text>
          <Text
            textSize="sm"
            textColor={theme.palette.text.primary}
            textAlign="center"
          >
            {fundingStatus
              ? 'Funding Request In progress'
              : 'Click below button to create one.'}
          </Text>
          {!allPaymentsDone? <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={() => setcreateFlag(true)}
            disabled={fundingStatus || disableCreateChangeOrder}
          >
            CREATE CHANGE ORDER
          </Button>:""}
        </Box>
      </Box>
    )
  );
};

export default NoChangeOrders;
