import { useState, useEffect, useLayoutEffect } from 'react';

export const useStateWithCallback = (initialState, callback) => {
  const [state, setState] = useState(initialState);

  useLayoutEffect(() => {
      callback(state);
    }, [state]);

  return [state, setState];
};

