import { combineReducers } from "redux";
import reducer from './reducers';


const rootReducer = combineReducers({
  appReducer: reducer,
});

export default rootReducer;

