import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Tabs, Tab } from '@material-ui/core';

import { ApplicationDescriptionHeader } from '../../HeadSection';
import ApplicationTab from './ApplicationTab';
import LoanTab from './LoanTab';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0 0 12px rgba(116,125,140,0.1)',
    padding: 14,
    paddingBottom: 24,
    paddingRight: 32,
    width: '100%',
  },
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  sectionTitle: {
    marginBottom: 24,
  },
  subsection: {
    marginTop: 24,
    marginBottom: 24,
  },
  subsectionItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subsectionItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 16,
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 32,
  },
  tabs: {
    marginBottom: 20,
    marginLeft: 32,
    marginTop: 26,
  },
  tab: {
    minWidth: 'auto',
  },
}));

const ApplicationDetail = ({
  history,
  applicationNumber,
  handleClickOpen,
  applicationDetail,
  detailTab,
  loanDetails,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(detailTab);
  const [isProjectStarted, setIsProjectStarted] = useState(false);
  useEffect(() => {
    setValue(detailTab);
    return () => {};
  }, [detailTab]);
  useEffect(() => {
    if (loanDetails) {
      setIsProjectStarted(true);
    }
  }, [loanDetails]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <ApplicationDescriptionHeader
        onGoBack={() => {
          history.goBack();
        }}
        applicationNumber={
          applicationDetail?.applicationData?.ppId || applicationNumber
        }
        defiApplicationNumber={
          applicationDetail?.applicationData?.defiApplicationNumber
        }
        handleClickOpen={handleClickOpen}
        headerData={applicationDetail.headerData}
        isProject={isProjectStarted}
      />
      <div className={classes.tabs}>
        <Tabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          <Tab label="DETAILS" value={0} classes={{ root: classes.tab }} />
          <Tab
            label={
              loanDetails &&
              (loanDetails.status === 'approved' ||
                loanDetails.status === 'in_progress' ||
                loanDetails.status === 'completed')
                ? 'LOAN'
                : ''
            }
            value={1}
            classes={{ root: classes.tab }}
            disabled={
              loanDetails &&
              (loanDetails.status === 'pending' ||
                loanDetails.status === 'sent')
            }
          />
        </Tabs>
      </div>
      {value === 0 && (
        <ApplicationTab
          applicationDetail={applicationDetail}
          loanRequestData={applicationDetail.loanRequestData}
          homeownerData={applicationDetail.homeownerData}
          homeownerFinancesData={applicationDetail.homeownerFinancesData}
          coapplicantData={applicationDetail.coapplicantData}
          coapplicantFinancesData={applicationDetail.coapplicantFinancesData}
        />
      )}
      {value === 1 && <LoanTab loanDetails={loanDetails} />}
    </Paper>
  );
};

export default ApplicationDetail;
