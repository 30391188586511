import { useEffect, useState } from 'react';
import { equals } from 'ramda';

export function useDebounce(
  value,
  delay = 500,
  options = {
    equalityCheck: true,
  },
) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (options.equalityCheck) {
        if (!equals(debouncedValue, value)) {
          setDebouncedValue(value);
        }
      } else {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return debouncedValue;
}
