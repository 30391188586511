import to from 'await-to-js';
import http, { protectedPost } from 'services/http';
import { buildFormData } from 'services/utils';

export async function postTwilioSendOTPCode(phoneNumber) {
  let data;
  try {
    // const formData = new FormData();
    const payload = {
      phone_number: phoneNumber,
    };
    // buildFormData(formData, { phone_number: phoneNumber });
    data = await protectedPost(
      `${process.env.REACT_APP_BASE_URL}/v1/users/send_code`,
      payload,
    );
  } catch (error) {
    data = error;
  }
  return data;
}

export async function postTwilioVerifyOTPCode(phoneNumber, otp) {
  const formData = new FormData();
  buildFormData(formData, { phone_number: phoneNumber, otp });
  const [err, r] = await to(
    protectedPost(
      `${process.env.REACT_APP_BASE_URL}/v1/users/verify_code`,
      formData,
    ),
  );
  return [err, r];
}

export async function postTwilioSendHomeownerOTPCode(phoneNumber) {
  let data;
  try {
    // const formData = new FormData();
    // buildFormData(formData, { phone_number: phoneNumber });
    const payload = {
      phone_number: phoneNumber,
    };
    data = await http.post(
      `${process.env.REACT_APP_BASE_URL}/v1/homeowners/send_code`,
      payload,
    );
  } catch (error) {
    data = error;
  }
  return data;
}
