import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import http, { protectedGet } from 'services/http';
import { connect } from 'react-redux';
import { updateData } from 'redux/actions';

import SuccessMessage from 'components/SuccessMessage';
import { useUserContext } from 'services/hooks/useUser';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loaders';
import Text from 'components/TextFields/Text';
import LinkText from 'components/TextFields/LinkText';
import to from 'await-to-js';
import PersonalInfoPage from './UserInfo';
import BusinessInfoPage from './BusinessInfo';

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formTitle: {
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 56,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
}));

const SignUp = (props) => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { affiliateFlow } = props;
  const [currentPage, setCurrentPage] = useState('firstPage');
  const [accountCreated, setAccountCreated] = useState(false);
  const [showLegalBusinessName, setShowLegalBusinessName] = useState('');
  const [affiliateId, setAffiliateId] = useState('');
  const [isOwner, setIsOwner] = useState('');
  useEffect(async () => {
    const pathParams = window.location.search.split('=');
    const affiliateId = pathParams[pathParams.length - 1];
    const [err, response] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/affiliates/public_show?affiliate_id=${affiliateId}`,
      ),
    );
    if (response) {
      setShowLegalBusinessName(response?.data?.name);
      setAffiliateId(affiliateId);
    }
  }, []);
  const handleSignUp = async (values, { setSubmitting, setErrors }) => {
    const payload = {
      referral_name: values.referralName || showLegalBusinessName,
      affiliate_id: affiliateId || user?.data?.id,
      dealer: {
        name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        owner: isOwner,
        phone_number: values.phoneNumber,
        primary_business_type_id: values.businessType,
        primary_product_id: values.productType,
      },
      address: {
        street_address: values.streetAddress,
        address_line_2: values.addressLine,
        state: values.state,
        city: values.city,
        zip_code: values.zipCode,
      },
      business: {
        legal_name: values.businessName,
        annual_finance_value: Number(values.annualFinanceVolume),
        annual_value: Number(values.businessValue),
        name: values.dbaBusinessName,
        phone_number: values.mobilePhone,
        website_url: values.websiteUrl,
      },
    };

    try {
      await http.post(`${process.env.REACT_APP_BASE_URL}/v1/dealers`, payload);
      setSubmitting(false);
      setAccountCreated(true);
      props.updateData({
        name: 'street_address',
        value: '',
      });
    } catch (e) {
      setCurrentPage('firstPage');
      setSubmitting(false);
      setErrors({ email: 'Email has already been taken' });
    }
  };
  useEffect(() => {
    props.updateData({
      name: 'street_address',
      value: '',
    });
  }, []);
  return (
    <div className={classes.contentWrapper}>
      {!affiliateFlow && <Header />}
      {accountCreated ? (
        <SuccessMessage
          text="Your application is currently being reviewed. If you are approved, you will receive an email with further instructions."
          showBack={affiliateFlow?false:true}
        />
      ) : (
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            ownerStatus: false,
            phoneNumber: '',
            streetAddress: '',
            addressLine: '',
            state: '',
            city: '',
            zipCode: '',
            businessName: '',
            dbaBusinessName: '',
            annualFinanceVolume: '',
            businessValue: '',
            websiteUrl: '',
            mobilePhone: '',
            businessType: '',
            referralName: '',
          }}
          validationSchema={null}
          onSubmit={handleSignUp}
        >
          {({ isSubmitting }) =>
            isSubmitting ? (
              <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
            ) : (
              <Form className={classes.form}>
                {!affiliateFlow ? (
                  <div className={classes.formTitle}>
                    <Text
                      textVariant="h5"
                      textColor="#000000"
                      textFontWeight={600}
                      textFontSize={22}
                      textLetterSpacing={0.5}
                      textLineHeight="28px"
                      text="Become a Merchant today!"
                    />
                    <div className={classes.formText}>
                      Already a Merchant?{' '}
                      <LinkText
                        variant="subtitle1"
                        link="/signin"
                        text="Log in"
                        textFontSize="12px"
                        textFontWeight={600}
                        textLineHeight="16px"
                        textLetterSpacing={0.6}
                      />
                    </div>
                    {currentPage === 'firstPage' ? (
                      <BusinessInfoPage
                        setCurrentPage={setCurrentPage}
                        ownerValue={isOwner}
                        setOwnerValue={setIsOwner}
                        showLegalBusinessName={showLegalBusinessName}
                      />
                    ) : (
                      <PersonalInfoPage
                        setCurrentPage={setCurrentPage}
                        ownerValue={isOwner}
                        setOwnerValue={setIsOwner}
                      />
                    )}
                  </div>
                ) : (
                  <BusinessInfoPage
                    setCurrentPage={setCurrentPage}
                    ownerValue={isOwner}
                    setOwnerValue={setIsOwner}
                    showLegalBusinessName
                    gridSize={6}
                  />
                )}
              </Form>
            )
          }
        </Formik>
      )}
      {!affiliateFlow && <Footer />}
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    appData: state.appReducer.appData,
  };
};
export default connect(mapStatesToProps, { updateData })(SignUp);
