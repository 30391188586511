import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loaders';
import Text from 'components/TextFields/Text';
import InputField from 'components/InputFields/InputField';
import ContainedButton from 'components/Buttons/ContainedButton';
import SuccessMessage from 'components/SuccessMessage';
import AdditionalInfo from 'components/AdditionalInfo';

import http from '../../services/http';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Wrong email address')
    .required('Email is required'),
});

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: '4rem',
  },
  form: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: '4rem',
  },
  formTitle: {
    marginBottom: 20,
    maxWidth: 345,
  },
}));

const ForgotPassword = () => {
  const [sendEmail, setSendEmail] = useState(false);

  const classes = useStyles();

  const handleSubmit = async (values, setSubmitting, setErrors) => {
    const payload = {
      email: values.email,
    };
    try {
      await http.post(
        `${process.env.REACT_APP_BASE_URL}/reset_password`,
        payload,
      );
      setSendEmail(true);
      setSubmitting(false);
    } catch (e) {
      setErrors({
        email: e.message.includes('404')
          ? 'User not found, check if the email address is correct.'
          : e?.response?.data?.message,
      });
      setSubmitting(false);
    }
  };
  return (
    <div className={classes.contentWrapper}>
      <Header />
      {sendEmail ? (
        <>
          <SuccessMessage text="Check your email! We’ve send you a password recovery instruction" />
          <Box marginBottom="4rem" />
        </>
      ) : (
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleSubmit(values, setSubmitting, setErrors);
          }}
        >
          {({ isSubmitting, values, errors }) =>
            isSubmitting ? (
              <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
            ) : (
              <Form className={classes.form}>
                <div className={classes.formTitle}>
                  <Text
                    textVariant="h5"
                    textColor="#000000"
                    textFontWeight={600}
                    textFontSize={22}
                    textLetterSpacing={0.5}
                    textLineHeight="28px"
                    text="Forgot my password?"
                  />
                  <Text
                    textVariant="body1"
                    textColor="#636A72"
                    textFontWeight={500}
                    textFontSize={15}
                    textLetterSpacing={0.6}
                    textLineHeight="22px"
                    text="Please type the email associated with your account"
                  />
                </div>
                <InputField name="email" type="email" label="Email" />
                <ContainedButton
                  className={classes.button}
                  type="submit"
                  text="DONE"
                  variant="contained"
                  color="primary"
                  isSubmitting={isSubmitting}
                  disabledOptions={values}
                  errors={errors}
                />
                <AdditionalInfo
                  title="Lost your Email?"
                  textComponent={
                    <Text textSize="sm" textColor="#1E272E">
                      If you can’t access the email address associated with
                      <br />
                      your Powerpay account.
                      <br />
                      Contact us at{' '}
                      <a
                        style={{ color: '#1E272E', fontWeight: 500 }}
                        href="mailto:support@powerpay.freshdesk.com"
                      >
                        app.support@getpowerpay.com
                      </a>
                    </Text>
                  }
                />
              </Form>
            )
          }
        </Formik>
      )}
      <Footer />
    </div>
  );
};

export default ForgotPassword;
