import React from 'react';

import { IconButton, makeStyles, Grid } from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SaveAlt from '@material-ui/icons/SaveAlt';
import SyncAlt from '@material-ui/icons/SyncAlt';
import UpdateIcon from '@material-ui/icons/SystemUpdateAlt';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.primary.main,
  },
  dropdownInput: {
    alignItems: 'center',
    display: 'flex',
    position: 'absolute',
    opacity: '0%',
  },
}));

export const ActionsMenu = ({
  isAppCanceled,
  handleUploadDocument,
  urlTarget,
  canEdit,
  showClearOption,
  clearFile,
  isNotice,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {!showClearOption && (
          <MenuItem disabled={urlTarget == null}>
            <a
              href={urlTarget}
              download
              rel="noopener noreferrer"
              target="_blank"
            >
              <Grid container>
                <Grid item>
                  <SaveAlt />
                </Grid>
                <Grid item> Download</Grid>
              </Grid>
            </a>
          </MenuItem>
        )}
        {!showClearOption && (
          <MenuItem disabled={urlTarget == null}>
            <a href={urlTarget} rel="noopener noreferrer" target="_blank">
              <Grid container>
                <Grid item>
                  <SyncAlt />
                </Grid>
                <Grid item> See</Grid>
              </Grid>
            </a>
          </MenuItem>
        )}
        {!isAppCanceled && !isNotice && (
          <MenuItem className={classes.menuItem} disabled={!canEdit}>
            <input
              type="file"
              className={classes.dropdownInput}
              onChange={(e) => {
                handleUploadDocument(e);
                handleClose();
              }}
            />
            <UpdateIcon />
            {/* NBPS since the autoclosing input tag 
            doesn't add space between the icons like the a element */}
            &nbsp;Update
          </MenuItem>
        )}
        {showClearOption && (
          <MenuItem>
            <a
              href={urlTarget}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => {
                clearFile();
              }}
            >
              <Grid container>
                <Grid item>
                  <ClearIcon />
                </Grid>
                <Grid item> Clear</Grid>
              </Grid>
            </a>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
