import React from 'react';

import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import PublishIcon from '@material-ui/icons/Publish';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { NavLink, useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import Link from '@material-ui/core/Link';
import MessageIcon from '@material-ui/icons/Message';
import Text from 'components/TextFields/Text';
import NumberFormat from 'react-number-format';
import { ApplicationStatusBadge } from 'components/ApplicationStatusBadge';
import { protectedPost } from 'services/http';
import { useAlertContext } from 'components/AlertContext';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import {
  ApplicationStatusSelector,
  ApplicationDealerSelector,
  MerchantReferenceIdEditor,
} from './ApplicationStatusSelector';
import { StageFundComponent } from './StageFundComponent';

const useStyles = makeStyles(() => ({
  linearProgress: {
    width: '100%',
    height: 6,
    borderRadius: 6,
    backgroundColor: 'rgba(30,39,46,0.1)',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#1DD1A1',
    },
  },
  link: {
    fontWeight: 600,
    textDecoration: 'underline',
  },
  uploadBtn: {
    padding: 0,
    minWidth: 0,
  },
  hiddenInput: {
    cursor: 'pointer',
    position: 'absolute',
    opacity: '0%',
    width: '100%',
  },
  containedButton: {
    color: 'white',
    fontWeight: 600,
    '&:hover': {
      color: 'white',
      textDecoration: 'none',
    },
  },
}));

const CancelComponent = ({ setSelectedCancelApp, onOpenCancelDialog }) => {
  const classes = useStyles();
  return (
    <Tooltip title="Cancel Application" aria-label="cancel application">
      <Button
        type="button"
        className={classes.uploadBtn}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedCancelApp();
          onOpenCancelDialog();
        }}
      >
        <CancelIconComponent />
      </Button>
    </Tooltip>
  );
};

const CancelIconComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: theme.palette.error.light,
  },
}))(HighlightOffIcon);

const CheckboxUploadComponent = ({ value, disabled, callbackFn }) => {
  const classes = useStyles();
  if (disabled || value !== null) {
    return <CheckboxComponent value={value} disabled={disabled} />;
  }
  return (
    <Tooltip title="Upload File" aria-label="upload file">
      <Button
        type="button"
        className={classes.uploadBtn}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <input
          type="file"
          className={classes.hiddenInput}
          onChange={(e) => {
            callbackFn(e, document);
          }}
        />
        <PublishIconComponent />
      </Button>
    </Tooltip>
  );
};

const CheckboxComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(CheckCircleOutlineRoundedIcon);
const CheckboxComponentDeal = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(CheckCircleOutlineRoundedIcon);
const MessageComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(MessageIcon);

const ConciergeComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(RoomServiceIcon);
const get = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(CheckCircleOutlineRoundedIcon);
const CrossComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: ({ value, disabled }) => {
      if (disabled) {
        return theme.palette.appState.pending;
      }
      if (value) {
        return theme.palette.appState.completed;
      }
      return theme.palette.appState.rejected;
    },
  },
}))(HighlightOffIcon);

const getStipPayItem = (stipPayAcepted, status, onClick) => {
  if (stipPayAcepted === 'not_selected' && status === 'conditional_approval') {
    return (
      <Box
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <CrossComponent disabled />
      </Box>
    );
  }
  if (stipPayAcepted === 'yes_stip_pay') {
    return (
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CrossComponent value />
      </Box>
    );
  }
  if (stipPayAcepted === 'no_stip_pay') {
    return (
      <Box>
        <CrossComponent value={false} />
      </Box>
    );
  }
};

const aprPromoFormatter = (value) => {
  const {
    palette: { text },
  } = useTheme();
  const { percentage, promos } = value;

  return (
    <Box>
      {!([null, undefined, '', '0', 0].includes(percentage) )&& (
          <Text textSize="sm+" textColor={text.primary}>
            {typeof percentage === 'string' ? percentage : percentage.toString()}
          </Text>
        )}
      <Text textSize="xs" semibold textColor={text.hint}>
        {Array.isArray(promos) ? promos.length && promos.join(', ') : promos}
      </Text>
    </Box>
  );
};
const dealSelectFormatter = (value) => {
  const {
    palette: { text },
  } = useTheme();
  const { dealDetails } = value;
  const promoDetails = `${dealDetails.promoDescription.split(' ')[0]} MO ${dealDetails.promoCode
    }`;
  // const interestRate =
  return (
    <>
      <Box>
        <Text textSize="sm" textColor={text.primary}>
          {promoDetails}
        </Text>

        {dealDetails.interestRate > 0 && (
          <Text textSize="xs" semibold textColor={text.hint}>
            {dealDetails.promo_composite_apr || dealDetails.interestRate}
            {dealDetails.buydownApplied ? '*' : ''}
          </Text>
        )}
      </Box>
    </>
  );
};
const PublishIconComponent = withStyles((theme) => ({
  root: {
    fontSize: '2rem',
    color: theme.palette.text.secondary,
  },
}))(PublishIcon);

export const DataComponent = ({ cell, _, isMobile }) => {
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  const startProjectLink = async (appId, loanId) => {
    try {
      await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/loans/${loanId}/start`,
      );
      history.push(`/dealer/applications/${appId}`);
    } catch ({ response }) {
      if (response) {
        if (response?.data?.message) {
          setErrorAlertMessage(
            response.data.message[0] || 'An Error Occurred!!',
          );
        }
      }
    }
  };
  const {
    palette: { text },
  } = useTheme();
  const classes = useStyles();
  const {
    value,
    subValue,
    subValue2,
    appId,
    type,
    component,
    setMerchantRefIdFn,
    state,
    values,
    callbackFn,
    code,
    kind,
  } = cell || {};
  switch (type) {
    case 'custom':
      return component;
    case 'cancel':
      return (
        <Box display="flex" paddingLeft="1rem">
          <CancelComponent {...value} />
        </Box>
      );
    case 'checkbox':
      return (
        <Box display="flex" paddingLeft="1rem">
          <CheckboxComponent {...value} />
        </Box>
      );
    case 'checkboxDeal':
      return (
        <Box display="flex" paddingLeft="1rem">
          {value.dealDetails ? (
            dealSelectFormatter(value)
          ) : (
            <CheckboxComponentDeal {...value} />
          )}
        </Box>
      );
    case 'message':
      return (
        <Box display="flex" paddingLeft="1.5rem">
          <MessageComponent {...value} />
        </Box>
      );
    case 'concierge':
      return (
        <Box display="flex" paddingLeft="1.5rem">
          <ConciergeComponent {...value} />
        </Box>
      );
    case 'checkboxUpload':
      return (
        <Box display="flex" paddingLeft="1rem">
          <CheckboxUploadComponent {...value} />
        </Box>
      );
    case 'link':
      return (
        <Box>
          <Link
            component={NavLink}
            className={classes.link}
            to={{ pathname: value, state }}
            onClick={(ev) => {
              ev.stopPropagation();
              if (subValue === 'Request') {
                sessionStorage.setItem('changeOrderTab', true);
              }
              else if (subValue === 'Refund Request'){
                sessionStorage.setItem('refundTab', true);
              }
            }}
          >
            {subValue}
          </Link>
          {subValue2 !== null && subValue2 !== undefined && (
            <Text textSize="xs" semibold textColor={text.hint}>
              {subValue2}
            </Text>
          )}
        </Box>
      );
    case 'startProject':
      return (
        <Box>
          <Button
            className={classes.link}
            style={{
              color: '#f79d3c',
              textTransform: 'none',
            }}
            // to={{ pathname: value, state }}
            onClick={(ev) => {
              ev.stopPropagation();
              startProjectLink(state.applicationId, state.loanId);
            }}
          >
            {subValue}
          </Button>
        </Box>
      );
    case 'empty':
      return <Text></Text>;
    case 'money':
      return (
        <Text textSize="sm+" textColor={text.primary}>
          <NumberFormat
            value={value}
            displayType="text"
            prefix="$"
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale
          />
        </Text>
      );
    case 'multi-money':
      return (
        <Box>
          <Text textSize="sm+" textColor={text.primary}>
            {' '}
            <NumberFormat
              value={value[0]}
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </Text>
          <Text textSize="xs" textColor={text.hint}>
            <NumberFormat
              value={value[1]}
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </Text>
        </Box>
      );
    case 'status':
      return (
        <Box display="flex" alignItems="center">
          <ApplicationStatusBadge
            textProps={{
              textSize: 'xs+',
              bold: true,
              textColor: text.secondary,
            }}
            status={value}
            draftStatus={subValue}
            kind={kind}
          />
        </Box>
      );
    case 'statusSelect':
      return (
        <ApplicationStatusSelector
          currentStatus={value}
          statuses={values}
          callbackFn={callbackFn}
        />
      );
    case 'dealerSelect':
      return (
        <ApplicationDealerSelector
          currentDealer={value}
          callbackFn={callbackFn}
        />
      );
    case 'merchantEditor':
      return (
        <MerchantReferenceIdEditor
          currentMerchantId={value}
          appId={appId}
          callbackFn={callbackFn}

        />
      );
    case 'refundsType':
      return (
        value?.length > 0 && value?.map(refund => {
        
         if (refund.status === 'pending' || refund.status === 'in_progress') {
           return (
           <Text style={{color: '#1E272E', opacity: 0.75 , paddingLeft:"23px"}}> 
          <NumberFormat
           value={refund.refundAmountCents / 100}
           displayType="text"
           prefix="$"
           thousandSeparator
           decimalScale={2}
           fixedDecimalScale
         />
         </Text>);
          }
          
          if (refund.status === 'confirmed')
          { 
            return(
            <>
            
            
            <Text style={{color: '#000000', display:'flex'}}> 
            <Icon icon="checkMark"  style={{ display:'flex'}} /> 
            <NumberFormat
             value={refund.refundAmountCents / 100}
             displayType="text"
             prefix="$"
             thousandSeparator
             decimalScale={2}
             fixedDecimalScale
             style={{display:'flex' , textAlign:'right'}}
           />
           </Text>
           </>);
          }
          return "";  
        })
      );
    case 'progress':
      return (
        <Box
          display="flex"
          alignItems="center"
          padding={isMobile ? '0 2rem 0 0' : '0 2rem 0 1rem'}
          minWidth="6rem"
        >
          <LinearProgress
            className={classes.linearProgress}
            variant="determinate"
            value={value}
          />
        </Box>
      );
    case 'stage-fund':
      return (
        <Box display="flex">
          <StageFundComponent {...value} />
        </Box>
      );
    case 'stipPayButton': {
      return value.show ? (
        <Box>
          {getStipPayItem(value.stipPayAccepted, value.status, value.onClick)}
        </Box>
      ) : null;
    }
    case 'dealerNetwork':
      return (
        <Box>
          <Text textSize="sm+" textColor={text.primary}>
            {value !== null && value !== undefined ? `${value}` : cell}
          </Text>
          {code !== null && code !== undefined && (
            <Text textSize="xs" semibold textColor={text.hint}>
              {code}
            </Text>
          )}
        </Box>
      );
    case 'aprPromos':
      return aprPromoFormatter(value);
    case 'value':
    default:
      return (
        <Box>
          <Text textSize="sm+" textColor={text.primary}>
            {value !== null && value !== undefined ? `${value}` : cell}
          </Text>
          {subValue !== null && subValue !== undefined && (
            <Text textSize="xs" semibold textColor={text.hint}>
              {subValue}
            </Text>
          )}
        </Box>
      );
  }
};
