import React from 'react';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import MobileStepper from '@material-ui/core/MobileStepper';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: 'relative',
  },
  itemWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 16,
    height: 32,
    width: 32,
    color: '#FFF',
    opacity: 0.6,
  },
}));

const StyledMobileStepper = withStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transform: 'translateX(-50%)',
    background: 'transparent',
  },
  dot: {
    opacity: 0.2,
    backgroundColor: ({ darkindicator }) =>
      darkindicator === 'true' ? '#9198A0' : 'white',
  },
  dotActive: {
    opacity: 1,
  },
}))(MobileStepper);

export const MaterialCarousel = ({
  items = [],
  darkIndicator,
  onClose,
  closable,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = items.length;

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <div className={classes.root}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={5000}
      >
        {items.map((item, index) => (
          <div className={classes.itemWrapper} key={index}>
            {Math.abs(activeStep - index) <= 2 ? item : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <StyledMobileStepper
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
        darkindicator={`${darkIndicator}`}
        className={classes.stepper}
      />

      {closable && (
        <IconButton
          className={classes.closeButton}
          aria-label="close"
          size="small"
          onClick={onClose}
        >
          <CancelIcon fontSize="default" />
        </IconButton>
      )}
    </div>
  );
};
