import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const VanillaButton = withStyles(theme => ({
  root: {
    padding: 0,
    margin: 0,
    minHeight: 'unset',
    minWidth: 'unset',
  },
}))(Button);
