import React, { useState, useEffect, useRef, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';

import { makeStyles } from '@material-ui/core';
import Text from './TextFields/Text';

const useStyles = makeStyles(theme => ({
  alert: {
    position: 'fixed',
    padding: '0.25rem 1rem',
    maxWidth: '40vw',
    minWidth: '16vw',
    top: '2rem',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 9999,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '90%',
    },

    '& .MuiAlert-icon': {
      display: 'none',
    },
  },
}));

const AlertContext = React.createContext();
export const { Consumer: AlertConsumer, Provider } = AlertContext;

export function AlertProvider({ children }) {
  const classes = useStyles();
  const [alert, setAlert] = useState({
    visible: false,
    message: null,
    config: {},
  });

  const clearAlert = () => {
    setAlert(state => ({
      ...state,
      visible: false,
    }));
  };
  const setAlertMessage = (message, config = {}) => {
    clearAlert();
    setAlert({
      visible: true,
      message,
      config: { ...config, severity: 'success' },
    });
  };
  const setErrorAlertMessage = (message, config) =>
    setAlert({
      visible: true,
      message,
      config: { ...config, severity: 'error' },
    });

    const setWarningAlertMessage = (message, config = {}) => {
      clearAlert();
      setAlert({
        visible: true,
        message,
        config: { ...config, severity: 'warning' },
      });
    };

  const { visible, message, config } = alert;
  const timeout = useRef(null);
  useEffect(() => {
    clearTimeout(timeout.current);
    if (visible) {
      timeout.current = setTimeout(() => {
        clearAlert();
      }, config.timeout || 5000);
    }
    return () => {
      // clearTimeout(timeout.current);
      // clearAlert();
    };
  }, [alert]);

  return (
    <Provider
      value={{
        setAlertMessage,
        setErrorAlertMessage,
        setWarningAlertMessage
      }}
    >
      <Fade in={visible}>
        <Alert
          className={classes.alert}
          variant="filled"
          onClose={() => clearAlert()}
          {...config}
        >
          <Box display="flex" className="wrapper">
            <Text textSize="md" bold textColor="white">
              {`${message}`}
            </Text>
          </Box>
        </Alert>
      </Fade>
      {children}
    </Provider>
  );
}

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  return context;
};
export const useAlertMessage = () => {
  const { setAlertMessage } = useContext(AlertContext);
  return setAlertMessage;
};
export const useErrorAlertMessage = (message, config) => {
  const { setErrorAlertMessage } = useContext(AlertContext);
  return setErrorAlertMessage;
};

export default AlertContext;
