import React from 'react';
import clsx from 'clsx';
import { map, addIndex } from 'ramda';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import { useUserContext } from 'services/hooks/useUser';
import Text from 'components/TextFields/Text';
import { DataComponent } from './DataComponent';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headCellLabel: {
    opacity: '0.8',
    color: 'rgba(30,39,46,0.8)',
    fontSize: '0.75rem',
    fontWeight: '600',
    letterSpacing: '0.5px',
    lineHeight: '1rem',
  },
  bodyCell: {
    padding: '0.75rem 0.5rem',
  },
  pagination: {
    color: '#1E272E',
    '& .MuiButtonBase-root': {
      color: '#1E272E',
      '&.Mui-disabled': {
        color: 'rgba(30,39,46, 0.5)',
      },
    },
    '& *': {
      fontWeight: '600',
    },
  },
  tableContainer: {
    flex: '1',
    display: 'flex',
    maxHeight: '650px',
    flexDirection: 'column',
    '& .MuiTableCell-root': {
      borderBottom: ({ hideRowLines }) =>
        hideRowLines ? 'none' : '1px solid rgba(224, 224, 224, 1)',
    },
    '& .MuiTableBody-root .MuiTableRow-root:last-of-type': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
    '& .MuiTableRow-root': {
      '&.Mui-selected': {
        background: 'rgba(247,157,60,0.08)',
      },
    },
  },
  tableRowPadding: {
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: ({ tableHorizontalPadding }) => {
        return `${tableHorizontalPadding}`;
      },
    },
    '& td:last-of-type, & th:last-of-type': {
      paddingRight: ({ tableHorizontalPadding }) => {
        return `${tableHorizontalPadding}`;
      },
    },
  },
  tableRow: {
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: ({ oddEvenBgs }) =>
        oddEvenBgs ? 'rgba(193, 201, 209, 0.1)' : '',
    },
  },
}));
function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    sortable,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    hideCheckboxes,
    noSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    if (noSort) {
      return;
    }
    onRequestSort(event, property);
  };
  const {
    palette: { text },
  } = useTheme();
  const { user, dealerLevelRole } = useUserContext();
  return (
    <TableHead>
      <TableRow className={classes.tableRowPadding}>
        {!hideCheckboxes && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checkedIcon={<CheckBoxOutlinedIcon />}
              checked={rowCount > 0 && numSelected === rowCount}
              onClick={(ev) => {
                ev.stopPropagation();
              }}
              onChange={onSelectAllClick}
              color="primary"
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) =>
          headCell.invisibleInTable ? null : (
            !user.data.attributes.showNetFundingAndCost && headCell.label !== 'Merchant Net Funding'
              && headCell.label !== 'Merchant Discount Fees' ?
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
                className={clsx(classes.bodyCell, `Cell-${headCell.id}`)}

              >
                {headCell.sortable ? <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                  className={classes.headCellLabel}
                  hideSortIcon={noSort}
                >
                  <Box>
                    {(headCell.label || '').toUpperCase()}

                    {headCell.subLabel && (
                      <Text textSize="xs" semibold textColor={text.hint}>
                        {headCell.subLabel.toUpperCase()}
                      </Text>
                    )}
                  </Box>
                  {!noSort && orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel> :



                  <Box className={classes.headCellLabel}>
                    {(headCell.label || '').toUpperCase()}

                    {headCell.subLabel && (
                      <Text textSize="xs" semibold textColor={text.hint}>
                        {headCell.subLabel.toUpperCase()}
                      </Text>
                    )}
                  </Box>

                }
              </TableCell>
              :
              <>
                {
                  user.data.attributes.showNetFundingAndCost &&
                  <TableCell
                    key={headCell.id}
                    align="left"
                    sortDirection={orderBy === headCell.id ? order : false}
                    className={clsx(classes.bodyCell, `Cell-${headCell.id}`)}

                  >
                    {headCell.sortable ? <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={createSortHandler(headCell.id)}
                      className={classes.headCellLabel}
                      hideSortIcon={noSort}
                    >
                      <Box>
                        {(headCell.label || '').toUpperCase()}

                        {headCell.subLabel && (
                          <Text textSize="xs" semibold textColor={text.hint}>
                            {headCell.subLabel.toUpperCase()}
                          </Text>
                        )}
                      </Box>
                      {!noSort && orderBy === headCell.id ? (
                        <span className={classes.visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel> :



                      <Box className={classes.headCellLabel}>
                        {(headCell.label || '').toUpperCase()}

                        {headCell.subLabel && (
                          <Text textSize="xs" semibold textColor={text.hint}>
                            {headCell.subLabel.toUpperCase()}
                          </Text>
                        )}
                      </Box>

                    }
                  </TableCell>
                }
              </>

          ),
        )}
      </TableRow>
    </TableHead>

  );
}

export const TableContent = ({
  headCells,
  rows,
  totalItems,
  perPageOptions,
  page,
  perPage,
  hidePagination,
  hideRowLines,
  hideCheckboxes,
  //
  order,
  sort,
  selected,
  sortable,
  handleClick,
  handleRequestSort,
  handleSelectAllClick,
  handleChangePage,
  handleChangeRowsPerPage,
  isSelected,
  noSort,
  itemType,
  oddEvenBgs,
  tableHorizontalPadding,
  emptyRows,
  onRowClick,
}) => {
  const classes = useStyles({
    hideRowLines,
    oddEvenBgs,
    tableHorizontalPadding,
  });
  const { user, dealerLevelRole } = useUserContext();
  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table aria-label={`${itemType}table`} stickyHeader>
          <EnhancedTableHead
            classes={classes}
            numSelected={Object.values(selected).reduce(
              (acc, sel) => (sel ? acc + 1 : acc),
              0,
            )}
            order={sort}
            orderBy={order}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            hideCheckboxes={hideCheckboxes}
            noSort={noSort}
          />
          <TableBody>
            {addIndex(map)((row, index) => {
              const isItemSelected = isSelected(index);
              const rowKey = `table-row-${index}`;
              return (

                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={rowKey}
                  selected={isItemSelected}
                  className={clsx(classes.tableRow, classes.tableRowPadding)}
                  onClick={(ev) => onRowClick(row)}
                >
                  {!hideCheckboxes && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        checkedIcon={<CheckBoxOutlinedIcon />}
                        color="primary"
                        inputProps={{ 'aria-label': row[0].value || row[0] }}
                        onClick={(ev) => {
                          ev.stopPropagation();
                        }}
                        onChange={(event) => handleClick(event, index)}
                      />
                    </TableCell>
                  )}
                  {addIndex(map)(
                    (cell, index2) =>
                      cell && cell.isInvisible ? null : (
                        !user.data.attributes.showNetFundingAndCost && headCells[index2].label !== 'Merchant Net Funding'
                          && headCells[index2].label !== 'Merchant Discount Fees' ?
                        <TableCell
                          key={`item-${index2}-${headCells[index2].id}-cell`}
                          className={clsx(
                            classes.bodyCell,
                            `Cell-${headCells[index2].id}`,
                          )}
                        >
                          <DataComponent cell={cell} isKey={index2 === 0} />
                        </TableCell>
                        :
                        <>
                        { user.data.attributes.showNetFundingAndCost &&
                          <TableCell
                          key={`item-${index2}-${headCells[index2].id}-cell`}
                          className={clsx(
                            classes.bodyCell,
                            `Cell-${headCells[index2].id}`,
                          )}
                        >
                          <DataComponent cell={cell} isKey={index2 === 0} />
                        </TableCell>
                        }
                        </>
                      ),
                    row,
                  )}
                </TableRow>
              );
            }, rows)}
          </TableBody>
        </Table>
      </TableContainer>
      <Box flex="1" />
      {!hidePagination && (
        <Box display="flex" alignItems="center" padding="0 1rem">
          <Text textSize="xs" bold>
            {`${totalItems} ${itemType} Found`}
          </Text>
          <Box flexGrow="1" />
          <TablePagination
            rowsPerPageOptions={perPageOptions}
            component="div"
            count={totalItems}
            rowsPerPage={perPage}
            page={emptyRows >= page * perPage ? 0 : page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </Box>
      )}
    </>
  );
};
