import React, { useEffect, useMemo, useState } from 'react';
import StatusButton from 'components/Buttons/StatusButton';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { updateData } from 'redux/actions';
import Text from 'components/TextFields/Text';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { PulseLoader } from 'react-spinners';

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TableHead,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import { useHistory } from 'react-router-dom';
import to from 'await-to-js';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RejectedIcon from 'assets/icons/submit-rejected.png';
import { useAlertContext } from 'components/AlertContext';
import NumberFormat from 'react-number-format';
import { useUserContext } from 'services/hooks/useUser';
import { protectedPut, protectedGet } from '../../services/http/index';
import { castFormattedCurrencyStringToNumber, formatNumberToCurrencyStringWithTwoDecimal } from "./CustomizedMUIInputs";

const accordionStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    fontSize: '20px',
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  verticalLine: {
    width: '2px' /* Line width */,
    backgroundColor: 'rgb(249, 250, 252)' /* Line color */,
    height: '100%',
  },
  vl: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  focussedClass: {
    fontSize: 'x-large',
    fontWeight: 'bold',
  },
  secondaryAccordian: {
    borderTop: '1px solid #efefef',
    backgroundColor: '#FAFAFA',
  },
}));

const DealStructCards = ({
  advancedFundingState,
  dealStruct,
  projectAmount,
  maxReqAmount,
  minReqAmount,
  buyDown,
  stagedFunding,
  stipPay,
  minPayment,
  maxPayment,
  minTerm,
  maxTerm,
  appId,
  appData,
  appDataIncluded,
  minDiscount,
  maxDiscount,
  sortDeals,
  updateData,
  enableStagedFunding
  }) => {
  const classes = accordionStyles();
  const { user } = useUserContext();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const history = useHistory();
  const theme = useTheme();
  const {palette: { text }} = useTheme();

  const costArrDealer = {};
  const costArrUser = {};
  const dealStructData = dealStruct?.included;
  const dealsDropdown = {
    Selected: [],
    Base: [],
    BalPay: [],
    '12 Month OHNO': [],
    POP: [],
  };
  const dealsArr = [[], [], [], [], []];
  let totalPromoCost = 0;

  const [updateLoanConfirm, setUpdateLoanConfirm] = useState(false);
  const [secondLookPopup, setSecondLookPopup] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(0);
  const [secondLookText, setSecondLookText] = useState('');
  const [updatedDocumentsText, setUpdatedDocumentsText] = useState('');
  const [selectedRadioDeal, setSelectedRadioDeal] = useState('');
  const [selectedRadioInstall, setSelectedRadioInstall] = useState('');
  const [selectedRadioBP, setSelectedRadioBP] = useState();
  const [selectedRadioOH, setSelectedRadioOH] = useState();
  const [selectedRadioPOP, setSelectedRadioPOP] = useState();
  const [costArrStateDealer, setCostArrStateDealer] = useState();
  const [costArrStateUser, setCostArrStateUser] = useState();
  const [loanDetails, setLoanDetails] = useState({});
  const [showDeals, setShowDeals] = useState(dealsArr);

  useEffect(() => {
    
    const sortedDeals = structuredClone(showDeals);
    switch (sortDeals){
      case "Lowest Payment":
          selectInitialAccordion(sortedDeals, false);
        break;
      case "Highest Payment":
          selectInitialAccordion(sortedDeals, true);
        break;
    }
    setShowDeals(sortedDeals);
  }, [sortDeals]);

  useEffect(() => {
    setShowDeals([]);
    dealStructData.map((item) => {
      if (item.type !== 'deal') {
        if (item.attributes.payer === 'dealer') {
          if (!costArrDealer[item.attributes.deal_id]) {
            costArrDealer[item.attributes.deal_id] = [];
          }
          costArrDealer[item.attributes.deal_id].push(item.attributes);
        } else {
          if (!costArrUser[item.attributes.deal_id]) {
            costArrUser[item.attributes.deal_id] = [];
          }
          costArrUser[item.attributes.deal_id].push(item.attributes);
        }
        return '';
      }

      const discount =
        (item.attributes.project_amount.cents -
          item.attributes.dealer_net_funding.cents) /
        100;
      if (discount < minDiscount || discount > maxDiscount) {
        return;
      }
      if (
        item.attributes.monthly_payment.cents / 100 < minPayment ||
        item.attributes.monthly_payment.cents / 100 > maxPayment
      ) {
        return;
      }
      if (
        item.attributes.term_months < minTerm ||
        item.attributes.term_months > maxTerm
      ) {
        return;
      }
      if (item.attributes.status === 'selected') {
        dealsArr[0].push(item);
        dealsDropdown.Selected.push(item.attributes.promo_description);
      }
      if (
        item.attributes.promo_code === 'IL' &&
        item.attributes.status === 'active'
      ) {
        dealsArr[1].push(item);
        dealsDropdown.Base.push(item.attributes.promo_description);
        setSelectedRadioInstall(dealsDropdown.Base[0]);
      }
      if (
        item.attributes.promo_code === 'ON' &&
        item.attributes.status === 'active'
      ) {
        if (!selectedRadioOH) {
          setSelectedRadioOH(item.attributes.promo_description);
        }
        dealsArr[3].push(item);
        dealsDropdown['12 Month OHNO'].push(item.attributes.promo_description);
      }
      if (
        item.attributes.promo_code === 'BP' &&
        item.attributes.status === 'active'
      ) {
        if (!selectedRadioBP) {
          setSelectedRadioBP(item.attributes.promo_description);
        }
        dealsArr[2].push(item);
        dealsDropdown.BalPay.push(item.attributes.promo_description);
      }
      if (
        item.attributes.promo_code === 'POP' &&
        item.attributes.status === 'active'
      ) {
        if (!selectedRadioPOP) {
          setSelectedRadioPOP(item.attributes.promo_description);
        }
        dealsArr[4].push(item);
        dealsDropdown.POP.push(item.attributes.promo_description);
      }

      return '';
    });

    setCostArrStateDealer(costArrDealer);
    setCostArrStateUser(costArrUser);
    setShowDeals(dealsArr);
    selectInitialAccordion(dealsArr, false);
  }, [
    dealStruct,
    maxPayment,
    minPayment,
    minTerm,
    maxTerm,
    minDiscount,
    maxDiscount,
  ]);


  useEffect(() => {
    getApplicationData(appId);
  }, [appId]);

  const handleChange = (promoCode, promoDesc) => (event, isExpanded) => {
    switch (promoCode) {
      case 'selected': {
        if (selectedRadioDeal === `${promoDesc}_selected`) {
          setSelectedRadioDeal('');
        } else {
          setSelectedRadioDeal(`${promoDesc}_selected`);
        }
        break;
      }
      case 'IL': {
        if (selectedRadioInstall === promoDesc) {
          setSelectedRadioInstall('');
        } else {
          setSelectedRadioInstall(promoDesc);
        }
        break;
      }
      case 'BP': {
        if (selectedRadioBP === promoDesc) {
          setSelectedRadioBP('');
        } else {
          setSelectedRadioBP(promoDesc);
        }
        break;
      }
      case 'ON': {
        if (selectedRadioOH === promoDesc) {
          setSelectedRadioOH('');
        } else {
          setSelectedRadioOH(promoDesc);
        }
        break;
      }
      case 'POP': {
        if (selectedRadioPOP === promoDesc) {
          setSelectedRadioPOP('');
        } else {
          setSelectedRadioPOP(promoDesc);
        }
        break;
      }
      default:
        break;
    }
  };

  const noDealsCard = (text, info) => {
    return (
      <>
        {showDeals[1]?.length + showDeals[2]?.length + showDeals[3]?.length + showDeals[4]?.length ===
          0 ? (
          <Card className={classes.card}>
            <CardContent>
              <Box display="flex" alignItems="center" flexDirection="column">

                {info ? <><PulseLoader color={theme.palette.primary.light} size="1rem" />
                  <Text textSize="sm+" semibold textColor="secondary">
                    Please wait while we generate deals...
                  </Text>
                </>
                  :
                  <img
                    src={RejectedIcon}
                    className={classes.image}
                    alt="confirm logo"
                  />}


                <Box style={info ? {
                  color: 'black'
                } : { color: "red" }}>
                  {text}
                </Box>

              </Box>
            </CardContent>
          </Card>
        ) : (
          ''
        )}
      </>
    );
  };

  const selectDeal = async (dealSetId, dealId, secondLook) => {
    const [err, res] = await to(
      protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/${dealSetId}/select_deal?deal_id=${dealId}&application_id=${appId}`,
        {
          second_look_confirmed: secondLook,
        },
      ),
    );
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }
    if (res) {
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
        ),
      );
      if (rawApplication) {
        updateData({
          name: 'appData',
          value: rawApplication.data,
        });
        setAlertMessage('Deal Selected');
        updateData({
          name: 'dealsData',
          value: res.data.included,
        });
        history.push(`/applications/${appId}/complete`);
      }

      if (err) {
        setErrorAlertMessage(err?.response?.data?.message || 'Error');
      }
    }
  };


  const selectInitialAccordion = (sortedDeals, reverse) => {
    for (var i = 0; i < sortedDeals.length; i++) {
      if (reverse) {
        sortedDeals[i].reverse();
      }
      if (sortedDeals[i][0]?.attributes?.promo_code === 'IL') {
        setSelectedRadioInstall(sortedDeals[i][0].attributes.id);
      } else if (sortedDeals[i][0]?.attributes?.promo_code === 'BP') {
        setSelectedRadioBP(sortedDeals[i][0].attributes.id);
      } else if (sortedDeals[i][0]?.attributes?.promo_code === 'POP') {
        setSelectedRadioPOP(sortedDeals[i][0].attributes.id);
      } else {
        setSelectedRadioOH(sortedDeals[i][0]?.attributes?.id);
      }
    }
  };

  const getApplicationData = async (appId) => {
    const [err, response] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
      ),
    );
    if (response) {
      setLoanDetails(response.data.data.relationships.loan);
    }
  };

  const errorCard = useMemo(() => {
    /**
     * showDeals[0] is the selected product, which can appear simultaneously with the error card.
     * Besides showDeals[0], in order to show the noDealsCard, we need to make sure that NONE of the other entries of showDeals is empty
     */
    if (showDeals.slice(1).some(elem => elem.length > 0)) return;
    let errorText;
    const state = appDataIncluded?.find((elem) => elem.type === "address")?.attributes?.state
    const percentage = appData?.max_state_stage_funding

    if (castFormattedCurrencyStringToNumber(projectAmount) > maxReqAmount) {
      errorText = `The maximum request amount is ${formatNumberToCurrencyStringWithTwoDecimal(maxReqAmount)} for this application.`;
    } else if (castFormattedCurrencyStringToNumber(projectAmount) < minReqAmount) {
      errorText = `The minimum request amount is ${formatNumberToCurrencyStringWithTwoDecimal(minReqAmount)} for this application.`;
    } else if (enableStagedFunding && advancedFundingState) {
      errorText = `Attention! The maximum initial draw for this loan in the state of ${state} is ${percentage}%.
      Based on the information provided, PowerPay will not be able to disburse additional funds until the job is complete. 
      Please select 'No' in the Advanced Funding dropdown to see Deal options and continue with the loan contract.
      If you have any questions, please call 800-397-4485.`;
    } else {
      errorText = 'No Deals Available for Current Selection';
    }
    return noDealsCard(errorText)
  }, [
      projectAmount,
      maxReqAmount,
      minReqAmount,
      showDeals,
      advancedFundingState,
      enableStagedFunding,
      appDataIncluded,
      appData
    ]
  );

  const updatedDocumentMessage = (isLoanPresent, newInstallContractNeeded) => {
    let documentConfirmDialogText = '';

    if (isLoanPresent && !newInstallContractNeeded){
      documentConfirmDialogText = `A loan contract has already been generated. The selection of a new deal will require an updated loan contract. Are you sure you want to continue?`;
    } else if (!isLoanPresent && newInstallContractNeeded){
      documentConfirmDialogText = `The updated project amount will require an updated install contract. Are you sure you want to continue?`;
    } else {
      documentConfirmDialogText = `A loan contract has already been generated. The updated project amount will require an updated loan contract and install contract. Are you sure you want to continue?`;
    }
    return documentConfirmDialogText;
  }

  return (
    <div className={classes.root}>
      <Box>
        <p>
          <span>
            Application {appData.pp_id} / {appData.name} {appData.last_name}
          </span>
          <StatusButton
            status={appData.status}
            draftStatus={appData.draft_status}
          />
        </p>
      </Box>

      <ConfirmationDialog
          desc="Attention :"
          subDesc={updatedDocumentsText}
          dialogState={updateLoanConfirm}
          handleClose={() => {
            setUpdateLoanConfirm(false);
            setSecondLookPopup(false);
          }}
          handleCancel={() => selectDeal(dealStruct?.data.id, selectedDeal)}
      />

      <ConfirmationDialog
          desc="Attention :"
          secondLook
          subDesc={secondLookText}
          dialogState={secondLookPopup}
          handleClose={() => setSecondLookPopup(false)}
          handleCancel={() => {
            if (loanDetails.data) {
              setUpdateLoanConfirm(true);
            } else selectDeal(dealStruct?.data.id, selectedDeal, true);
          }}
      />

      {dealStruct &&
        showDeals.map((item, index) => {
          if (item.length === 0) {
            return '';
          }
          totalPromoCost = 0;

          const selectedRadio =
            index === 0
              ? selectedRadioDeal
              : index === 1
                ? selectedRadioInstall
                : index === 2
                  ? selectedRadioBP
                  : index === 3
                    ? selectedRadioOH
                    : selectedRadioPOP;
          return (
            <>
              {item.map((deal) => {
                totalPromoCost = 0;
                if (deal.attributes.status === 'stale') {
                  return '';
                }
                return (
                  <>
                    <Accordion
                      expanded={
                        deal.attributes.status === 'selected'
                          ? `${deal.attributes.id}_selected` ===
                          selectedRadio
                          : deal.attributes.id === selectedRadio
                      }
                      onChange={
                        deal.attributes.status === 'selected'
                          ? handleChange(
                            'selected',
                            deal.attributes.id,
                          )
                          : handleChange(
                            deal.attributes.promo_code,
                            deal.attributes.id,
                          )
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          className={
                            deal.attributes.promo_description === selectedRadio
                              ? classes.focussedClass
                              : classes.amount
                          }
                          style={{ fontWeight: 'bold', fontSize: '1.25rem' }}
                        >
                          {deal.attributes.status === 'selected' && (
                            <>
                              <small
                                style={{
                                  fontSize: 'small',
                                  color: 'rgb(29,209,161)',
                                }}
                              >
                                Selected Deal
                              </small>
                              <br />
                            </>
                          )}
                          {deal.attributes.promo_description}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Grid container>
                              <Grid item xs={12} md={7}>
                                {/* Monthly Payment */}
                                <Box>
                                  <Typography style={{ fontSize: 'large' }}>
                                    Monthly Payment
                                  </Typography>
                                  <Typography
                                    style={{
                                      fontSize: 'xxx-large',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    <NumberFormat
                                      className={classes.dataItem}
                                      value={
                                        deal.attributes?.teaser_available
                                          ? deal.attributes
                                            .teaser_monthly_payment.cents /
                                          100
                                          : deal.attributes.monthly_payment
                                            .cents / 100
                                      }
                                      displayType="text"
                                      prefix="$"
                                      thousandSeparator
                                      decimalScale={2}
                                      fixedDecimalScale
                                    />
                                    {/* {`$${
                                    deal.attributes.monthly_payment.cents / 100
                                  }`} */}
                                  </Typography>
                                </Box>
                                {deal.attributes?.teaser_available &&
                                  deal.attributes?.teaser_tag_line && (
                                    <Text
                                      textSize="xs"
                                      semibold
                                      textColor={text.hint}
                                    >
                                      {deal.attributes?.teaser_tag_line}
                                    </Text>
                                  )}
                                {/* Applied Loan Options */}
                                <Box pb={3}>
                                  {stagedFunding &&
                                    deal.attributes.status !== 'selected' ? (
                                    <Box
                                      display="flex"
                                      alignContent="center"
                                      style={{
                                        color: theme.palette.success.main,
                                      }}
                                    >
                                      <CheckIcon></CheckIcon>
                                      <Typography>Advance Funding</Typography>
                                    </Box>
                                  ) : (
                                    ''
                                  )}
                                  {stipPay &&
                                    deal.attributes.status !== 'selected' ? (
                                    <Box
                                      display="flex"
                                      alignContent="center"
                                      style={{
                                        color: theme.palette.success.main,
                                      }}
                                    >
                                      <CheckIcon></CheckIcon>
                                      <Typography>Stip Pay</Typography>
                                    </Box>
                                  ) : (
                                    ''
                                  )}
                                  {buyDown &&
                                    deal.attributes.status !== 'selected' ? (
                                    <Box
                                      display="flex"
                                      alignContent="center"
                                      style={{
                                        color: theme.palette.success.main,
                                      }}
                                    >
                                      <CheckIcon></CheckIcon>
                                      <Typography>Buy Down</Typography>
                                    </Box>
                                  ) : (
                                    ''
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={false} md={1}>
                                <Divider orientation="vertical" />
                              </Grid>
                              <Grid item xs={12} md={4}>
                                {/* Select */}
                                <Box pb={3}>
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      setSelectedDeal(deal?.attributes?.id);
                                      setSecondLookText(
                                        `I understand this offer is in the PowerDown program and has a ${appData?.second_look_merchant_discount}% discount along with stipulations.`,
                                      );

                                      const isSecondLook = deal?.attributes?.second_look;
                                      const tempStatus = deal?.attributes?.status;
                                      const prevSelectedDeal = appDataIncluded?.find((elem) => elem.type === "deal");
                                      const isNewProjectAmount = prevSelectedDeal?.attributes?.project_amount.cents !== deal?.attributes?.project_amount.cents;
                                      const isInstallContractUploaded = appData?.install_contract_uploaded;
                                      const isLoanPresent = loanDetails?.data !== null;

                                      if (isSecondLook && tempStatus !== "selected"){
                                        setSecondLookPopup(true)
                                      } else if (tempStatus === "selected"){
                                        history.push(`/applications/${appId}/complete`) /** this can probably be removed, as this routing already happens in @see selectDeal */
                                      } else if (isLoanPresent || (isNewProjectAmount && isInstallContractUploaded)){
                                        setUpdatedDocumentsText(updatedDocumentMessage(isLoanPresent, isNewProjectAmount && isInstallContractUploaded));
                                        setUpdateLoanConfirm(true);
                                      } else {
                                        selectDeal(
                                            dealStruct?.data.id,
                                            deal?.attributes.id,
                                            deal?.attributes?.second_look,
                                        );
                                      }
                                    }}
                                  >
                                    <Text textSize="md" textColor="white" bold>
                                      {deal.attributes.status === 'selected'
                                        ? 'Continue'
                                        : ' Select'}
                                    </Text>
                                  </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid xs={12}>
                            <Box px={1}>
                              <Divider />
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            {/* Project Amount */}
                            <Box>
                              <Typography style={{ fontSize: 'small' }}>
                                {enableStagedFunding ? "Financed Amount" : "Project Amount"}
                              </Typography>
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 'small',
                                }}
                              >
                                <NumberFormat
                                  className={classes.dataItem}
                                  value={
                                    deal.attributes.project_amount.cents / 100
                                  }
                                  displayType="text"
                                  prefix="$"
                                  thousandSeparator
                                  decimalScale={2}
                                  fixedDecimalScale
                                />
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {/* Rate TODO: test that it works once show_promo_apr is in staging */}
                            <Box>
                              <Typography style={{ fontSize: 'small' }}>
                                { deal.attributes.show_promo_apr ? "APR" : "Interest Rate"}
                              </Typography>
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 'small',
                                }}
                              >{deal.attributes.show_promo_apr ? `${deal.attributes.promo_composite_apr}%` : `${deal.attributes.interest_rate}%`}</Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {/* Term */}
                            <Box>
                              <Typography style={{ fontSize: 'small' }}>
                                Term
                              </Typography>
                              <Typography
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: 'small',
                                }}
                              >
                                {deal.attributes.term_months} Months
                              </Typography>
                            </Box>
                          </Grid>

                          <Grid xs={12}>
                            <Accordion
                              elevation={0}
                              defaultExpanded={deal.attributes.second_look}
                              square
                              className={classes.secondaryAccordian}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                              >
                                <Box>
                                  <Typography style={{ fontSize: 'small' }}>
                                    Loan Details
                                  </Typography>
                                </Box>
                              </AccordionSummary>

                              <AccordionDetails
                                style={{
                                  paddingLeft: '0px',
                                }}
                              >
                                {/* TODO: These don't make sense as is */}
                                {/* Costs */}
                                <Box
                                  style={{
                                    width: '100%',
                                  }}
                                >
                                  <TableContainer
                                    component={Paper}
                                    style={{
                                      boxShadow: 'none',
                                      backgroundColor: '#FAFAFA',
                                    }}
                                  >
                                    <Table
                                      sx={{ minWidth: 800 }}
                                      size="small"
                                      aria-label="a dense table"
                                    >
                                      {
                                        user.data.attributes.showNetFundingAndCost &&
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>
                                              <b> Merchant Net Funding </b>
                                            </TableCell>
                                            <TableCell colSpan={2} align="right">
                                              <b>
                                                <NumberFormat
                                                  className={classes.dataItem}
                                                  value={
                                                    deal.attributes
                                                      .dealer_net_funding.cents /
                                                    100
                                                  }
                                                  displayType="text"
                                                  prefix="$"
                                                  thousandSeparator
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                />
                                              </b>{' '}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                      }

                                      <TableBody>
                                        {
                                          user.data.attributes.showNetFundingAndCost &&
                                          costArrStateDealer[
                                          deal?.attributes?.id
                                          ] &&
                                          costArrStateDealer[
                                            deal?.attributes?.id
                                          ].map((item, index) => {
                                            return (
                                              <TableRow key={item.label}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {item.label} (
                                                  {(
                                                    (item.amount.cents /
                                                      deal.attributes
                                                        .project_amount.cents) *
                                                    100
                                                  ).toFixed(2)}
                                                  %)
                                                </TableCell>
                                                <TableCell align="right">
                                                  <NumberFormat
                                                    className={classes.dataItem}
                                                    value={
                                                      item.amount.cents / 100
                                                    }
                                                    displayType="text"
                                                    prefix="$"
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                  />
                                                </TableCell>
                                              </TableRow>
                                            );
                                          })}
                                        { user.data.attributes.showNetFundingAndCost &&
                                          <TableRow>
                                            <TableCell>
                                              <b> {enableStagedFunding ? "Financed Amount" : "Project Amount"} </b>
                                            </TableCell>
                                            <TableCell colSpan={2} align="right">
                                              <b>
                                                <NumberFormat
                                                  className={classes.dataItem}
                                                  value={
                                                    deal.attributes.project_amount
                                                      .cents / 100
                                                  }
                                                  displayType="text"
                                                  prefix="$"
                                                  thousandSeparator
                                                  decimalScale={2}
                                                  fixedDecimalScale
                                                />
                                              </b>
                                            </TableCell>
                                          </TableRow>
                                        }
                                        {user.data.attributes.showNetFundingAndCost &&
                                        costArrStateUser[
                                          deal?.attributes?.id
                                        ] &&
                                          costArrStateUser[
                                            deal?.attributes?.id
                                          ].map((item, index) => {
                                            totalPromoCost += item.amount.cents;
                                            return (
                                              <TableRow key={item.label}>
                                                <TableCell
                                                  component="th"
                                                  scope="row"
                                                >
                                                  {item.label}
                                                </TableCell>
                                                <TableCell align="right">
                                                  <NumberFormat
                                                    className={classes.dataItem}
                                                    value={
                                                      item.amount.cents / 100
                                                    }
                                                    displayType="text"
                                                    prefix="$"
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                  />{' '}
                                                </TableCell>
                                              </TableRow>
                                            );
                                          })}
                                        <TableRow>
                                          <TableCell>
                                            <b>Loan Amount </b>
                                          </TableCell>
                                          <TableCell colSpan={2} align="right">
                                            <b>
                                              <NumberFormat
                                                className={classes.dataItem}
                                                value={
                                                  (deal.attributes
                                                    .project_amount.cents +
                                                    totalPromoCost) /
                                                  100
                                                }
                                                displayType="text"
                                                prefix="$"
                                                thousandSeparator
                                                decimalScale={2}
                                                fixedDecimalScale
                                              />
                                            </b>{' '}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                            {deal.attributes.product_description && (
                              <Accordion
                                elevation={0}
                                square
                                className={classes.secondaryAccordian}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                >
                                  <Box>
                                    <Typography style={{ fontSize: 'small' }}>
                                      Product Details and Eligibility
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails
                                  style={{
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Box
                                    style={{
                                      width: '100%',
                                      paddingLeft: '20px',
                                    }}
                                  >
                                    <Box>
                                      <Typography
                                        style={{
                                          fontSize: 'small',
                                          whiteSpace: 'pre-line',
                                        }}
                                      >
                                        {deal.attributes.product_description}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
              <p></p>
            </>
          );
        })}
      {!(showDeals.slice(1).some(elem => elem.length > 0)) && errorCard}
    </div>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealStruct: state.appReducer.dealStruct,
    maxReqAmount: (
      state.appReducer.dealStruct.data.attributes.max_request_amount.cents / 100),
    minReqAmount: (
      state.appReducer.dealStruct.data.attributes.min_request_amount.cents / 100),
    projectAmount: state.appReducer.request_amount_contract,
    buyDown: state.appReducer.buyDown,
    sortDeals: state.appReducer.sortDeals,
    stagedFunding: state.appReducer.stagedFunding,
    stipPay: state.appReducer.stipPay,
    minPayment: state.appReducer.minPayment,
    maxPayment: state.appReducer.maxPayment,
    minTerm: state.appReducer.minTerm,
    maxTerm: state.appReducer.maxTerm,
    minDiscount: state.appReducer.minDiscount,
    maxDiscount: state.appReducer.maxDiscount,
    appId: state.appReducer.appId,
    dealsData: state.appReducer,
    appDataIncluded: state.appReducer.appData.included,
    appData: state.appReducer.appData.data.attributes,
  };
};
export default connect(mapStatesToProps, { updateData })(DealStructCards);
