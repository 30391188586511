import React, { useContext } from 'react';

const ModalContext = React.createContext();

export function ModalProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const toggle = React.useCallback(() => setOpen((oldOpen) => !oldOpen), []);
  const value = React.useMemo(() => ({ open, toggle }), [open]);
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}

export function useModalContext() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(`useModalContext must be used within a ModalProvider`);
  }
  return context;
}
