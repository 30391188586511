import React from 'react';
import { map } from 'ramda';

import { useTheme } from '@material-ui/core/styles';
import { Grid, Card, CardContent, makeStyles, Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { MaterialCarousel } from 'components/MaterialCarousel';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Text from 'components/TextFields/Text';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '4rem 8rem 4rem 4rem',
    },
  },
  card: {
    flexGrow: 1,
    padding: '2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3.5rem',
      paddingBottom: '2rem',
    },
  },
  itemContainer: {
    marginTop: '3rem',
    marginBottom: '6rem',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '48rem',

    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

const FormOnboarding = ({
  items,
  title,
  subtitle,
  actionText,
  onActionClick,
}) => {
  const classes = useStyles();
  const { palette } = useTheme();

  const itemComponents = (carousel = false) =>
    map(
      item => (
        <Box key={item.text} className={classes.item}>
          <img src={item.icon} className={classes.image} />
          <Text
            textSize="sm"
            semibold
            textAlign="center"
            textcolor={palette.text.hint}
            style={{
              whiteSpace: 'pre-line',
              marginBottom: carousel ? '3rem' : '',
            }}
          >
            {item.text}
          </Text>
        </Box>
      ),
      items,
    );

  return (
    <Card elevation={4} className={classes.card}>
      <CardContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid container justify="center">
            <Grid item xs={6}>
              <Text
                textSize="cardTitle"
                textAlign="center"
                bold
                textColor={palette.text.primary}
              >
                {title}
              </Text>
              <Text
                textSize="cardSubtitle"
                textAlign="center"
                textColor={palette.text.secondary}
                style={{ marginTop: '1rem' }}
              >
                {subtitle}
              </Text>
            </Grid>
          </Grid>

          <Box display="flex" className={classes.itemContainer}>
            <Hidden smDown>{itemComponents()}</Hidden>
            <Hidden mdUp>
              <MaterialCarousel darkIndicator items={itemComponents(true)} />
            </Hidden>
          </Box>

          <Button
            onClick={onActionClick}
            variant="contained"
            color="primary"
            style={{
              padding: '0.5rem 0',
              width: '100%',
              borderRadius: '4px',
              maxWidth: '25rem',
            }}
          >
            <Text bold textColor="white">
              {actionText}
            </Text>
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default FormOnboarding;
