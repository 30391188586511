import React from 'react';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';

import SettingsSection from 'components/SettingsSection';
import Text from 'components/TextFields/Text';

import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';

import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles((theme) => ({

  container: {
    marginTop: '40px',
  },
}));

const getText = (
  dealerCode,
  dealerLevelRole,
  dealerLevelId,
  dealerLevelTitle,
  onDealerLinkClicked,
  dealerMemberId,
) => {
  const classes = useStyles();
  const printRef = React.useRef();
  const downloadQR = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');

    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'QR_Code.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  switch (dealerLevelRole) {
    case 'affiliate':
      return (
        <>
          Your Affiliate Id/Name is:{' '}
          <strong>
            {dealerLevelId} - {dealerLevelTitle}
          </strong>
        </>
      );
    case 'organization':
      return (
        <>
          Your Organization Id/Name is:{' '}
          <strong>
            {dealerLevelId} - {dealerLevelTitle}
          </strong>
        </>
      );
    case 'dealer_network_overseer':
    case 'dealer_network_member':
    case 'dealer_network_account_rep':
      return (
        <>
          <div>
            Your Dealer ID is: <strong>{dealerCode}</strong>
          </div>
          <Tooltip
            title="Click the link to copy it"
            aria-label="cancel application"
          >
            <Button
              style={{
                padding: 0,
                textTransform: 'unset',
                textAlign:'left'
              }}
              onClick={onDealerLinkClicked}
            >
              <span
              style={{
                whiteSpace:'pre-line'
              }}
                id={`${process.env.REACT_APP_FRONT_URL}/apply/${dealerCode}/${dealerMemberId}`}
              >
                Your Dealer link is &nbsp;
                <strong>{`${process.env.REACT_APP_FRONT_URL}/apply/${dealerCode}/${dealerMemberId}`}</strong>
              </span>
            </Button>
          </Tooltip>
          <div
            ref={printRef}
            style={{
              marginTop: '40px',
            }}
          >
            <QRCode
              ref={printRef}
              styles={{ left: 'auto' }}
              id="QrCodeGen"
              title="Use the QR code above to access your consumer application or download it to share"
              value={`${process.env.REACT_APP_FRONT_URL}/apply/${dealerCode}/${dealerMemberId}`}
            />
          </div>
          <br />

          <span>
            <Button
              variant="contained"
              color="primary"
              className={classes.actionButton}
              onClick={downloadQR}
            >
              Download QR Code
            </Button>
          </span>
          <Text
            textSize="xs"
            bold
            style={{
              marginTop: '12px',
            }}
          >
            Use the QR code above to access your consumer application or
            download it to share.
          </Text>
        </>
      );
    default:
      return null;
  }
};

const DealerCode = () => {
  const classes = useStyles();
  const {
    user,
    dealerLevelTitle,
    dealerLevelRole,
    dealerLevelId,
  } = useUserContext();
  const { setAlertMessage } = useAlertContext();

  const onDealerLinkClicked = () => {
    const textarea = document.createElement('textarea');
    textarea.value = `${process.env.REACT_APP_FRONT_URL}/apply/${user?.data?.attributes?.dealerCode}/${user?.data?.attributes?.dealerMemberId}`;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand('copy');
    document.body.appendChild(textarea);
    setAlertMessage('Dealer link copied to clipboard');
    textarea.remove();
  };
  return (
    <>
      {dealerLevelRole !== 'super_admin' &&
        <SettingsSection className={classes.dealerCodeSection}>
          <Text textSize="lg" textColor="primary">
            {getText(
              user?.data?.attributes?.dealerCode,
              dealerLevelRole,
              dealerLevelId,
              dealerLevelTitle,
              onDealerLinkClicked,
              user?.data?.attributes?.dealerMemberId,
            )}
          </Text>
        </SettingsSection>
      }
    </>
  );
};

export default DealerCode;
