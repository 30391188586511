import React from 'react';
import { TextField as FormikTextField } from 'formik-material-ui';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useField, Field } from 'formik';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: 12,
    marginBottom: 12,
    maxWidth: 345,
    minHeight: 61,
    width: '100%',
  },
}));

const InputField = ({ name, type, label, ...props }) => {
  const classes = useStyles();
  return (
    <Field
      component={FormikTextField}
      className={classes.textField}
      name={name}
      type={type}
      label={label}
      variant="outlined"
      {...props}
    />
  );
};

export default InputField;

export const TrimField = ({ name, type, label, setFieldValue, ...props }) => {
  const classes = useStyles();
  const avoidWhiteSpace = (e) => {
    const emailValue = e.target.value.trim();
    setFieldValue('email', emailValue);
  };

  return (
    <Field
      component={FormikTextField}
      className={classes.textField}
      name={name}
      type={type}
      label={label}
      variant="outlined"
      onChange={(e) => avoidWhiteSpace(e)}
      onKeyUp={(e) => avoidWhiteSpace(e)}
      {...props}
    />
  );
};

const useStyles2 = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
}));
export const OutlinedTextInput = ({
  variant = 'outlined',
  label,
  ...props
}) => {
  const classes = useStyles2();
  return (
    <TextField
      label={label}
      variant={variant}
      className={classes.formControl}
      {...props}
    />
  );
};
export const OutlinedTextField = ({
  name,
  type = 'text',
  error,
  hideErrors = false,
  ...props
}) => {
  const [field, meta] = useField({ name, type });
  return (
    <OutlinedTextInput
      error={Boolean(meta.error)}
      helperText={!hideErrors ? meta.error : ''}
      {...field}
      {...props}
    />
  );
};
