import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import SafeIcon from 'assets/icons/safe-icon.png';
import CoinsIcon from 'assets/icons/coins-icon.png';

import StepPlaid from 'components/StepPlaid';

const validationSchemaFn = () => {
  const schema = Yup.object({
    isIncomeVerified: Yup.bool().oneOf(
      [true],
      '',
    ),
  }).nullable();
  return schema;
};

const plaidCardsItems = [
  {
    title: 'Safety First!',
    description:
      'All of your banking information is safely secured.',
    image: SafeIcon,
  },
  {
    title: 'Why do I need to provide this?',
    description:
      'Providing this information allows us to easily confirm your identity and account details.',
    image: CoinsIcon,
  },
];

const IncomeVerification = ({ isActive, setChildCallbacks, onSaveDraft }) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get validationSchema() {
          return validationSchemaFn();
        },
      });
    }
    return () => {};
  }, [isActive]);
  const [displayVerify, setDisplayVerify]= useState(true);

  return <StepPlaid plaidCardsItems={plaidCardsItems} onSaveDraft={onSaveDraft} displayVerify={displayVerify} />;
};

export default IncomeVerification;
