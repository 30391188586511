import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles, Hidden } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'absolute',
    top: '1rem',
    right: '1.5rem',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    zIndex: 999,
    minWidth: 0,

    [theme.breakpoints.down('sm')]: {
      top: '0.5rem',
      right: '0.5rem',
    },
  },
}));
export const SaveDraftButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={onClick}>
      <Icon icon="save" />
      <Hidden smDown>
        <Text textSize="xs" bold style={{ marginLeft: '0.25rem' }}>
          SAVE DRAFT
        </Text>
      </Hidden>
    </Button>
  );
};
