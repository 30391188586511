import React from 'react';

import { makeStyles, Paper, Grid } from '@material-ui/core';
import Text from 'components/TextFields/Text';
import ContainedButton from 'components/Buttons/ContainedButton';
import Icon from 'components/Icon';
import { useModalContext } from 'services/contexts/useModalContext';
import CompleteAccountSetupForm from './CompleteAccountSetupForm';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 450,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 212,
    },
  },
  banner: {
    display: 'block',
    [theme.breakpoints.only('md')]: {
      display: 'none',
    },
  },
  bannerTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    marginLeft: 16,
    marginRight: 18,
    marginTop: 22,
    minHeight: 212,
    [theme.breakpoints.up('md')]: {
      marginLeft: 26,
      paddingRight: 0,
      paddingTop: 0,
    },
  },
  bannerTitle: {
    textAlign: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
      padding: 0,
    },
  },
  bannerText: {
    marginTop: 6,
    paddingLeft: 30,
    paddingRight: 30,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: 417,
      textAlign: 'left',
      padding: 0,
    },
  },
  buttonWrapper: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  },
  bannerImgWrapper: {
    position: 'relative',
    width: '100%',
  },
  bannerImg: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      bottom: -8,
      right: -30,
      display: 'block',
      position: 'absolute',
    },
    [theme.breakpoints.up('xl')]: {
      left: '25%',
    },
  },
  bannerImgVariant: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      bottom: 8,
      right: -30,
      display: 'block',
      position: 'absolute',
    },
    [theme.breakpoints.up('xl')]: {
      left: '25%',
    },
  },
  responsiveBannerImg: {
    position: 'absolute',
    left: 85,
    bottom: -250,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  responsiveBannerImgVariant: {
    position: 'absolute',
    left: 85,
    bottom: -230,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const UnderRevisionBanner = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.bannerTextWrapper}>
        <div className={classes.bannerTitle}>
          <Text
            textColor="#1E272E"
            textFontWeight={600}
            textLineHeight="28px"
            textLetterSpacing={0.5}
            textFontSize={22}
            text="You're under revision"
          />
        </div>
        <div className={classes.bannerText}>
          <Text
            textColor="#393F47"
            semibold
            textLineHeight="18px"
            textLetterSpacing={0.65}
            textFontSize={14}
            text="Your enrollment request is under review.  We will notify you as soon as you’re ready to start processing loan applications."
          />
        </div>
      </Grid>
      <Grid item xs={12} md={5} className={classes.bannerImgWrapper}>
        <div className={classes.bannerImgVariant}>
          <Icon icon="pendingReview" />
        </div>
        <div className={classes.responsiveBannerImgVariant}>
          <Icon icon="responsivePendingReview" />
        </div>
      </Grid>
    </Grid>
  );
};

const CompleteAccountSetup = () => {
  const classes = useStyles();
  const { toggle } = useModalContext();

  return (
    <Grid container>
      <Grid item xs={12} md={6} className={classes.bannerTextWrapper}>
        <div className={classes.bannerTitle}>
          <Text
            textColor="#1E272E"
            textFontWeight={600}
            textLineHeight="28px"
            textLetterSpacing={0.5}
            textFontSize={22}
            text="You're almost done!"
          />
        </div>
        <div className={classes.bannerText}>
          <Text
            textColor="#393F47"
            semibold
            textLineHeight="18px"
            textLetterSpacing={0.65}
            textFontSize={14}
            text="We are working hard on your enrollment request. A few more details can really speed things up!"
          />
        </div>
        <div className={classes.buttonWrapper}>
          <ContainedButton
            onClick={toggle}
            text="CONTINUE"
            variant="contained"
            color="primary"
            disabledOptions={[]}
            errors={[]}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={5} className={classes.bannerImgWrapper}>
        <div className={classes.bannerImg}>
          <Icon icon="accountSetup" />
        </div>
        <div className={classes.responsiveBannerImg}>
          <Icon icon="responsiveAccountSetup" />
        </div>
      </Grid>
      <CompleteAccountSetupForm />
    </Grid>
  );
};

const Banner = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <CompleteAccountSetup />
    </Paper>
  );
};

export default Banner;
