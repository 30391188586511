import * as React from 'react';

function SvgArchiveIcon({ width, height, ...props }) {
  return (
    <svg
      width={width || '20px'}
      height={height || '18px'}
      viewBox="0 0 22 20"
      {...props}
    >
      <defs>
        <path
          d="M7.465 2H3a1 1 0 00-1 1v14a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1h-9a1 1 0 01-.832-.445L7.465 2zm3.07 1H19a3 3 0 013 3v11a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h5a1 1 0 01.832.445L10.535 3z"
          id="archive-icon_svg__a"
        />
      </defs>
      <use
        fill="currentColor"
        xlinkHref="#archive-icon_svg__a"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgArchiveIcon;
