import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Tabs, Tab } from '@material-ui/core';
import { useField } from 'formik';
import to from 'await-to-js';
import { protectedGet, protectedPost } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';
import { ApplicationDescriptionHeader } from '../../HeadSection';
import ApplicationTab from './ApplicationTab';
import LoanTab from './LoanTab';
import PaymentTab from './PaymentTab';
import ChangeOrderTab from './ChangeOrderTab';
import RefundTab from './RefundTab';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: '0 0 12px rgba(116,125,140,0.1)',
    padding: 14,
    paddingBottom: 24,
    paddingRight: 32,
    width: '100%',
  },
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  sectionTitle: {
    marginBottom: 24,
  },
  subsection: {
    marginTop: 24,
    marginBottom: 24,
  },
  subsectionItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subsectionItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 16,
  },
  divider: {
    backgroundColor: '#F3F4F4',
    marginTop: 24,
    marginBottom: 24,
    marginLeft: 32,
  },
  tabs: {
    marginBottom: 20,
    marginLeft: 32,
    marginTop: 26,
  },
  tab: {
    minWidth: 'auto',
  },
}));
const FUNDING_TAB_STATES = [
  'in_progress',
  'completed',
  'finished',
  'refunded',
  'change_requested',
];
const CHANGE_ORDER_TAB_STATES = ['in_progress', 'change_requested']; // change order to be enabled only if the project is started

const ApplicationDetail = ({
  history,
  applicationNumber,
  handleClickOpen,
  applicationDetail,
  createLoanEnvelope,
  detailTab,
  term,
  setTerm,
  setStageFunding,
  stageFunding,
  hasLoan,
  loanDetails,
  setFetchedData,
  values,
  changeOrderDetails,
  originalLoanDetails,
  setChangeOrderTab,
  setRefundTab,
  dealerStructData,
  rawApplicationsEndpointResponse
}) => {
  const classes = useStyles();
  const { user, dealerLevelRole } = useUserContext();
  const [value, setValue] = useState(detailTab);
  const [displayRefundTab, setDisplayRefundTab] = useState(true);
  const [isStageFundedHI, setIsStageFundedHI] = useState(false); // to be used in refund tab
  const [validHIRefund, setValidHIRefund] = useState(false);
  const [paymentsRefundAmount, setPaymentsRefundAmount] = useState(''); // to be used in refund tab

  useEffect(() => {
    if (
      applicationDetail?.headerData?.draftStatus === 'canceled' ||
      applicationDetail?.headerData?.status === 'rejected' ||
      applicationDetail?.headerData?.status === 'expired'
    ) {
      setValue(0);
    } else if (
      dealerLevelRole === 'dealer_network_account_rep' &&
      detailTab === 2
    ) {
      setValue(0);
    } else {
      setValue(detailTab);
    }
  }, [detailTab]);

  useEffect(() => {
    const loanIsHI = applicationDetail?.applicationData?.loanProductCode === "home_improvement";
    const isStageFunded = loanDetails?.stageFunding;

    if (loanIsHI && isStageFunded){
      setIsStageFundedHI(true);
      const validPayments = loanDetails?.payments.filter(elem => ["paid", "released"].includes(elem.status));
      const hasOnePayment = validPayments.length > 0

      if (!hasOnePayment) return;

      let refundAmount = validPayments.reduce((total, payment) => {
        let amount = Number(payment.amount.replace(/[^0-9.-]+/g,""));
        return total + amount;
      }, 0);

      refundAmount = "$" + refundAmount.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});

      setValidHIRefund(true);
      setPaymentsRefundAmount(refundAmount);
    } else {
      loanDetails?.payments?.forEach(payment => {
        if (payment.status !== 'paid') setDisplayRefundTab(false);
      })
    }

  }, [loanDetails, applicationDetail]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [field] = useField({ name: 'dealStructId' });
  const [creditLimitOptions, setCreditLimitOptions] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      if (field.value && applicationDetail?.applicationData?.id) {
        const [err, res] = await to(
          protectedGet(
            `/v1/applications/${applicationDetail.applicationData.id}/loans/default_limits`,
          ),
        );
        if (err) {
          console.error(err);
        } else {
          setCreditLimitOptions(res.data);
        }
      }
    };
    fetch();
  }, [field.value]);
  
  return (
    <Paper className={classes.paper} elevation={0}>
      <ApplicationDescriptionHeader
        onGoBack={() => {
          if (
            history.location.pathname.includes('dealer/applications') &&
            document.referrer.includes('complete')
          ) {
            history.push('/projects');
          } else {
            history.goBack();
          }
        }}
        applicationNumber={
          applicationDetail?.applicationData?.ppId || applicationNumber
        }
        defiApplicationNumber={
          applicationDetail?.applicationData?.defiApplicationNumber
        }
        handleClickOpen={handleClickOpen}
        headerData={applicationDetail.headerData}
        isProject={!!loanDetails}
      />
      <div className={classes.tabs}>
        <Tabs
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
          value={
            dealerLevelRole === 'dealer_network_account_rep' && value === 2
              ? 0
              : value
          }
          onChange={handleChange}
        >
          <Tab label="DETAILS" value={0} classes={{ root: classes.tab }} />
          {createLoanEnvelope &&
            applicationDetail?.headerData?.draftStatus !== 'canceled' &&
            applicationDetail?.headerData?.status !== 'rejected' &&
            applicationDetail?.headerData?.status !== 'expired' ? (
            <Tab label="LOAN" value={1} classes={{ root: classes.tab }} />
          ) : null}
          {FUNDING_TAB_STATES.includes(loanDetails?.status) &&
            dealerLevelRole !== 'dealer_network_account_rep' &&
            applicationDetail?.headerData?.draftStatus !== 'canceled' &&
            applicationDetail?.headerData?.status !== 'rejected' &&
            applicationDetail?.headerData?.status !== 'expired' ? (
            <Tab label="FUNDING" value={2} classes={{ root: classes.tab }} />
          ) : null}
          {CHANGE_ORDER_TAB_STATES.includes(loanDetails?.status) &&
            loanDetails?.stageFunding === true &&
            dealerLevelRole !== 'dealer_network_account_rep' &&
            applicationDetail?.headerData?.draftStatus !== 'canceled' &&
            applicationDetail?.headerData?.status !== 'rejected' &&
            applicationDetail?.headerData?.status !== 'expired' ? (
            <Tab
              label="CHANGE ORDERS"
              value={3}
              classes={{ root: classes.tab }}
            />
          ) : null}

          {
            displayRefundTab &&
              ['home_improvement', 'healthcare'].includes(applicationDetail?.headerData?.loanProductCode) && 
              dealerLevelRole !== 'dealer_network_account_rep' &&
              applicationDetail?.headerData?.draftStatus !== 'canceled' &&
              applicationDetail?.headerData?.status !== 'rejected' &&
              applicationDetail?.headerData?.status !== 'expired' ? (
              <Tab
                label="REFUNDS"
                value={4}
                classes={{ root: classes.tab }}
              />
            ) : null}
        </Tabs>
      </div>
      {value === 0 && (
        <ApplicationTab
          application={applicationDetail}
          setFetchedData={setFetchedData}
        />
      )}
      {value === 1 && (
        <LoanTab
          applicationId={applicationDetail.applicationData.id}
          setTerm={setTerm}
          applicationDetail={applicationDetail}
          setStageFunding={setStageFunding}
          stageFunding={stageFunding}
          hasLoan={hasLoan}
          term={term}
          values={values}
          loanDetails={loanDetails}
          originalLoanDetails={originalLoanDetails}
          creditLimitOptions={creditLimitOptions}
          loanContractSignatureMethods={
            applicationDetail.applicationData.loanContractSignatureMethods
          }
          dealerStructData={dealerStructData}
          rawApplicationsEndpointResponse={rawApplicationsEndpointResponse}
        />
      )}
      {value === 2 && (
        <PaymentTab
          stageFunding={stageFunding}
          applicationDetail={applicationDetail}
          applicationNumber={applicationNumber}
          appData={applicationDetail}
          loanDetails={loanDetails}
          setFetchedData={setFetchedData}
          changeOrderDetails={changeOrderDetails}
        />
      )}
      {value === 3 && (
        <ChangeOrderTab
          setFetchedData={setFetchedData}
          applicationNumber={applicationNumber}
          changeOrderDetails={changeOrderDetails}
          originalLoanDetails={originalLoanDetails}
          setChangeOrderTab={setChangeOrderTab}
          loanDetails={loanDetails}
        />
      )}
      {value === 4 && (
        <RefundTab
          setFetchedData={setFetchedData}
          applicationNumber={applicationNumber}
          changeOrderDetails={changeOrderDetails}
          originalLoanDetails={originalLoanDetails}
          setChangeOrderTab={setChangeOrderTab}
          setRefundTab={setRefundTab}
          loanDetails={loanDetails}
          validHIRefund={validHIRefund}
          paymentsRefundAmount={paymentsRefundAmount}
          isStageFundedHI={isStageFundedHI}
        />
      )}
    </Paper>
  );
};

export default ApplicationDetail;
