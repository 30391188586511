import React from 'react';
import { map } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import Text from 'components/TextFields/Text';

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}));

export const CheckboxesFilter = ({
  possibleValues,
  currentValues,
  setParams,
  parentKey = 'statusFilters',
}) => {
  const classes = useStyles();

  if (!currentValues) {
    console.error('error, currentValues prop is missing');
  }

  const toggleValue = value => () => {
    setParams(params => ({
      ...params,
      [parentKey]: {
        ...params[parentKey],
        [value]: !params.statusFilters[value],
      },
    }));
  };

  return (
    <Box display="flex" flexDirection="column" className="filter-section">
      <Text
        textSize="sm"
        textColor="secondary"
        style={{ marginBottom: '0.5rem' }}
        bold
      >
        FILTER BY STATUS
      </Text>
      {map(({ value, label }) => {
        const checked = currentValues[value];
        return (
          <Box display="flex" alignItems="center" key={value}>
            <Checkbox
              color="primary"
              checked={checked}
              checkedIcon={<CheckBoxOutlinedIcon />}
              onChange={toggleValue(value)}
              className={classes.checkbox}
            />
            <Text
              textSize="md"
              bold
              textColor={checked ? '#FF980F' : 'primary'}
            >
              {label}
            </Text>
          </Box>
        );
      })(possibleValues)}
    </Box>
  );
};
