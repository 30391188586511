import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'components/Icon';
import {
  Grid,
  Divider,
  Button,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';

import { format } from 'date-fns-tz';
import { useUserContext } from 'services/hooks/useUser';
import { FileList } from './FileList';

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  swicthButton: {
    marginBottom: '10px',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
  margin: {
    margin: theme.spacing(1),
  },
  logContainer: {
    backgroundColor: '#F9FAFC',
    borderRadius: 6,
    padding: 9,
    marginBottom: 9,
  },
  logItem: {
    backgroundColor: 'white',
    border: 'solid 0.5px #C1C9D1',
    borderRadius: 4,
    marginBottom: '12px',
  },
  logContentWrapper: {
    marginLeft: 8,
    minWidth: 0,
    wordBreak: 'break-word',
    padding: '0.25rem 0.25rem 0.25rem 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  logInformation: {
    color: 'rgba(0,0,0)',
    fontSize: 12,
    letterSpacing: 0.46,
    lineHeight: '12px',
  },
  logContent: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginBottom: 10,
  },
  logContentRead: {
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  styledIcon: {
    marginRight: 7,
    fontSize: '1.125rem',
    cursor: 'pointer',
  },
  innerContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 5,
    width: '100%',
  },
  switchBase123: {
    color: 'blue',
  },
  divider: {
    backgroundColor: '#F3F4F4',
  },
  styledButton: {
    color: 'white',
    fontSize: '0.875 rem',
    fontWeight: 600,
    marginTop: 10,
    marginBottom: 10,
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
  },
  cardButton: {
    borderColor: '#F79D3C',
    margin: '12px',
    borderWidth: '1.5px',
    width: '94%',
    overflow: 'hidden',
    '&:hover': {
      borderWidth: '1.5px',
    },
  },
}));
export const Stipulations = (props) => {
  const {
    stipulations,
    handleUploadDocument,
    showLoader,
    setShowLoader,
    showFileType,
    showNotificationLink,
    loanProductCode,
    hasSelectedDeal
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUserContext();
  
  useEffect(() => {
    /*
      This useEffect is here because the notification links for the stipulations are either notificationLinkUrl or notification_link_url

      I could've just simply made sure to use whichever one exists and that's it. However, that's not a good coding practice. 

      If it's a backend decision, they should decide how to send the information. 
      If it's a frontend issue, then at some point i'll find it and i'll fix it. 

      For now, it doesn't matter, i'll just manually make notificationLinkUrl === notification_link_url. That way we can stop adding conditionals.

      Maybe one day we'll be able to remove this useEffect. Or maybe we'll just delete this whole file. Who knows.
    */

    if (stipulations.some(stipulation => 
        !(stipulation.notificationLinkUrl) && stipulation.notification_link_url
      )){
        stipulations.forEach((stipulation, index, array) => {
          // array is a reference to, in this case, the stipulations array
          if (!(stipulation.notificationLinkUrl) && stipulation.notification_link_url) {
            // eslint-disable-next-line no-param-reassign
            stipulation.notificationLinkUrl = stipulation.notification_link_url
          }
        })
    }
  }, [stipulations])

  return (
    <>
      <>
        {loanProductCode === 'healthcare' && (
          <>
            {stipulations.map((stipulation) => (
              <>
                {((stipulation?.file && stipulation?.file?.active) ||
                  !stipulation?.file) &&
                  stipulation?.file?.file_type !== 'install_contract' && (
                    <div
                      className={classes.logItem}
                      key={stipulation?.id}
                      data-testid="viewcard-container"
                    >
                      <div className={classes.logContentWrapper}>
                        <div className={classes.innerContentWrapper}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Typography
                                data-testid="logItem"
                                className={classes.logContent}
                                style={{
                                  marginBottom: '0px !important',
                                }}
                              >
                                <bold
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    marginBottom: '0px !important',
                                  }}
                                >
                                  {stipulation?.notification_name ||
                                    stipulation?.notificationName}
                                </bold>
                              </Typography>
                            </Grid>
                          </Grid>

                          <Typography
                            data-testid="logItem"
                            className={classes.logContent}
                          >
                            <span style={{ color: 'gray' }}>
                              {format(
                                new Date(
                                  stipulation?.created_at ||
                                    stipulation?.createdAt,
                                ),
                                'MM/dd/yyyy',
                                {
                                  timeZone: 'America/New_York',
                                },
                              )}
                            </span>
                          </Typography>
                          <Grid container>
                            <Grid item>
                              <Typography className={classes.logInformation}>
                                <p>{stipulation?.notification_summary}</p>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'end' }}>
                          <IconButton
                            disableFocusRipple
                            disableRipple
                            // disabled={data?.attributes?.userId !== user?.data?.attributes?.id}
                            style={{ backgroundColor: 'transparent' }}
                          ></IconButton>
                        </div>
                      </div>
                      {(stipulation?.notification_link_url ||
                        stipulation?.notificationLinkUrl) &&
                        showNotificationLink && (
                          <Button
                            color="primary"
                            variant="outlined"
                            className={classes.cardButton}
                            type="button"
                            style={{ marginTop: '10px' }}
                            onClick={(e) => {
                              const url =
                                stipulation?.notification_link_url ||
                                stipulation?.notificationLinkUrl;
                              window.open(url, '_blank');
                            }}
                          >
                            <span
                              style={{
                                marginRight: 8,
                                fontSize: 12,
                                fontWeight: 600,
                              }}
                            >
                              {stipulation?.notification_link_name ||
                                stipulation?.notificationLinkName}
                            </span>

                            <Icon icon="launchIcon" />
                          </Button>
                        )}
                      {stipulation?.file && (
                        <>
                          <Divider className={classes.divider} />

                          <FileList
                            hasSelectedDeal={hasSelectedDeal}
                            documents={[stipulation?.file]}
                            handleUploadDocument={handleUploadDocument}
                            adhocstyling
                            showLoader={showLoader}
                            stipulations
                            showFileType={showFileType}
                          />
                        </>
                      )}
                    </div>
                  )}
              </>
            ))}
          </>
        )}
      </>
      <>
        {loanProductCode !== 'healthcare' && (
          <>
            {stipulations.map((stipulation) => (
              <>
                {((stipulation?.file && stipulation?.file?.active) ||
                  !stipulation?.file) && (
                  <div
                    className={classes.logItem}
                    key={stipulation?.id}
                    data-testid="viewcard-container"
                  >
                    <div className={classes.logContentWrapper}>
                      <div className={classes.innerContentWrapper}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              data-testid="logItem"
                              className={classes.logContent}
                              style={{
                                marginBottom: '0px !important',
                              }}
                            >
                              <bold
                                style={{
                                  fontWeight: 'bold',
                                  fontSize: '14px',
                                  marginBottom: '0px !important',
                                }}
                              >
                                {stipulation?.notification_name ||
                                  stipulation?.notificationName}
                              </bold>
                              {(stipulation?.notification_link_url ||
                                stipulation?.notificationLinkUrl) &&
                                showNotificationLink && (
                                  <Button
                                    type="button"
                                    onClick={() =>
                                      navigator.clipboard.writeText(
                                        stipulation.notificationLinkUrl,
                                      )
                                    }
                                    title="Copy Link"
                                    style={{
                                      fontSize: '0.6rem',
                                      marginLeft: '0.5rem',
                                    }}
                                  >
                                    {/* TODO: move this to a CSS classname in the future */}
                                    <Icon icon="copy" />
                                    Copy
                                  </Button>
                                )}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Typography
                          data-testid="logItem"
                          className={classes.logContent}
                        >
                          <span style={{ color: 'gray' }}>
                            {format(
                              new Date(
                                stipulation?.created_at ||
                                  stipulation?.createdAt,
                              ),
                              'MM/dd/yyyy',
                              {
                                timeZone: 'America/New_York',
                              },
                            )}
                          </span>
                        </Typography>
                        <Grid container>
                          <Grid item>
                            <Typography className={classes.logInformation}>
                              <p>{stipulation?.notification_summary}</p>
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>

                      <div style={{ display: 'flex', alignItems: 'end' }}>
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          // disabled={data?.attributes?.userId !== user?.data?.attributes?.id}
                          style={{ backgroundColor: 'transparent' }}
                        ></IconButton>
                      </div>
                    </div>
                    {(stipulation?.notification_link_url ||
                      stipulation?.notificationLinkUrl) &&
                      showNotificationLink && (
                        <Button
                          color="primary"
                          variant="outlined"
                          className={classes.cardButton}
                          type="button"
                          style={{ marginTop: '10px' }}
                          onClick={(e) => {
                            const url =
                              stipulation?.notification_link_url ||
                              stipulation?.notificationLinkUrl;
                            window.open(url, '_blank');
                          }}
                        >
                          <span
                            style={{
                              marginRight: 8,
                              fontSize: 12,
                              fontWeight: 600,
                            }}
                          >
                            {stipulation?.notification_link_name ||
                              stipulation?.notificationLinkName}
                          </span>

                          <Icon icon="launchIcon" />
                        </Button>
                      )}
                    {stipulation?.file && (
                      <>
                        <Divider className={classes.divider} />

                        <FileList
                          hasSelectedDeal={hasSelectedDeal}
                          documents={[stipulation?.file]}
                          handleUploadDocument={handleUploadDocument}
                          adhocstyling
                          showLoader={showLoader}
                          stipulations
                          showFileType={showFileType}
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            ))}
          </>
        )}
      </>
    </>
  );
};
