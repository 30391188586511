import React, { useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useFormikContext, yupToFormErrors } from 'formik';
import to from 'await-to-js';
import { addIndex, map } from 'ramda';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Text from 'components/TextFields/Text';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { ActiveButton } from 'components/Buttons/ActiveButton';
import SwipeableViews from 'react-swipeable-views';
import { personFields } from './helpers';
import { Step2DriverLicense } from './Step2DriverLicense';
import { Step3PersonalInfo } from './Step3PersonalInfo';
import { Step5Employment } from './Step5Employment';

const HOTexts = {
  title: 'Do you have a Co-Applicant?',
  subtitle: 'This person will be your partner in this loan',
};
const dealershipTexts = {
  title: 'Does your client have a Co-Applicant?',
  subtitle: 'This person will be their partner in this loan',
};

const getTexts = (isDealer = false) => (isDealer ? dealershipTexts : HOTexts);

const useStyles = makeStyles(() => ({
  subFormStep: {
    minHeight: '20rem',
    overflow: 'hidden',
  },
  hide: {
    height: 0,
  },
}));

const steps = (values) => {
  const fixedSteps = [
    {
      component: (props) => <Step3PersonalInfo {...props} />,
    },
    {
      component: (props) => <Step5Employment {...props} />,
    },
  ];
  return values.application.coapplicantDriverLicense?.url
    ? fixedSteps
    : [
        {
          component: (props) => (
            <Step2DriverLicense appId={values?.applicationId} {...props} />
          ),
        },
        ...fixedSteps,
      ];
};

export const CoApplicantStep = ({
  isActive,
  setChildCallbacks,
  isFirst,
  isDealer,
  dealerRole,
}) => {
  const { values, setFieldValue, setErrors } = useFormikContext();
  const { state } = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [subChildCallbacks, setSubChildCallbacks] = useState({});

  const theme = useTheme();
  const classes = useStyles();
  useEffect(() => {
    if (!values.hasCoapplicant) {
      setActiveStep(0);
      setFieldValue('coapplicant', personFields);
    }
  }, [values.hasCoapplicant]);

  /// isActive Logic
  /// step logic
  const handleNext = async () => {
    if (values.hasCoapplicant && activeStep < activeSteps.length - 1) {
      const validationSchema = subChildCallbacks.validationSchema;
      if (validationSchema) {
        const [validationErrors] = await to(
          validationSchema.validate(values, {
            abortEarly: false,
          }),
        );
        if (validationErrors && yupToFormErrors(validationErrors).coapplicant) {
          setErrors(yupToFormErrors(validationErrors));
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
      return false;
    }
    return true;
  };

  const handleBack = () => {
    if (activeStep > 0 && values.hasCoapplicant) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return '';
        },
        get stepSubtitle() {
          return '';
        },

        onSaveDraft: () => setActiveStep(0),
        onNext: handleNext,
        onBack: handleBack,
        canNext: () => true,
        canBack: () => !isFirst,
      });
    }
  }, [isActive, activeStep, values]);

  const TEXTS = getTexts(isDealer);
  const activeSteps = steps({ ...values, applicationId: state.applicationId });
  const setHasCoApplicant = (val) => () => setFieldValue('hasCoapplicant', val);
  // validation logic
  const setSubChildCallbacksFn = (callbacks) =>
    setSubChildCallbacks(() => ({ ...callbacks }));
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          <Text textColor={theme.palette.text.primary} textSize="sm+" semibold>
            {TEXTS.title}
          </Text>
          <Text
            id="loan-amount-label"
            textColor={theme.palette.text.secondary}
            textSize="xs+"
            semibold
          >
            {TEXTS.subtitle}
          </Text>

          <Box display="flex" justifyContent="space-between" maxWidth="12rem">
            <ActiveButton
              isActive={values.hasCoapplicant}
              color="primary"
              onClick={setHasCoApplicant(true)}
            >
              Yes
            </ActiveButton>
            <ActiveButton
              isActive={!values.hasCoapplicant}
              color="primary"
              onClick={setHasCoApplicant(false)}
            >
              No
            </ActiveButton>
          </Box>
        </Box>
      </Grid>
      {values.hasCoapplicant && (
        <Grid item xs={12}>
          <Box className={clsx(classes.stepForm, 'subform-step')}>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              disabled
            >
              {addIndex(map)(
                (step, index) => (
                  <Box
                    className={clsx(classes.subFormStep, {
                      [classes.hide]: index !== activeStep,
                    })}
                    key={index}
                  >
                    {step.component({
                      coapplicant: true,
                      setChildCallbacks: setSubChildCallbacksFn,
                      isActive: index === activeStep,
                      isDealer,
                      dealerRole,
                    })}
                  </Box>
                ),
                activeSteps,
              )}
            </SwipeableViews>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
