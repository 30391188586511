import React from 'react';
import { map, props } from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MaterialCarousel } from 'components/MaterialCarousel';
import Hidden from '@material-ui/core/Hidden';
import Text from 'components/TextFields/Text';
import { VanillaNewAppButton } from 'components/Buttons/NewApplicationButton';
import { AppMarketingCarousel } from 'components/AppMarketingCarousel';
import SvgPrequalifyIcon from 'components/Icon/PrequalifyIcon';
import DraftIcon from 'assets/icons/draft-icon.png';
import MoneyIcon from 'assets/icons/money-icon.png';
import IdIcon from 'assets/icons/id-icon.png';
import Icon from 'components/Icon';
import { NavigateBefore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '2rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '4rem 8rem 4rem 4rem',
    },
  },
  card: {
    flexGrow: 1,
    padding: '2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3.5rem',
      paddingBottom: '2rem',
    },
  },
  itemContainer: {
    marginTop: '3rem',
    marginBottom: '6rem',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '48rem',

    [theme.breakpoints.down('md')]: {
      marginBottom: '2rem',
    },
  },
  cardContent: {
    padding: '0 !important',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

export const Onboarding = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { palette } = useTheme();
  const { hasApplications } = props;

  return (
    <>
      {!hasApplications ? (
        <Grid container className={classes.container} spacing={3}>
          <Grid item xs={12}>
            <AppMarketingCarousel />
          </Grid>

          <Grid item xs={12}>
            <Card elevation={4} className={classes.card}>
              <CardContent>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Text
                    textSize="cardTitle"
                    textAlign="center"
                    bold
                    textColor={palette.text.primary}
                  >
                    Apply Now!
                  </Text>
                  <Text
                    textSize="cardSubtitle"
                    textAlign="center"
                    textColor={palette.text.secondary}
                    style={{ marginTop: '1rem' }}
                  >
                    Simple and Quick Application Process
                  </Text>
                  <br></br>

                  <VanillaNewAppButton style={{ maxWidth: '24rem' }} setFlag>
                    New Application
                  </VanillaNewAppButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Card
          elevation={!hasApplications ? 4 : 1}
          className={!hasApplications && classes.card}
        >
          <CardContent className={hasApplications && classes.card}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Text
                textSize="cardTitle"
                textAlign="center"
                bold
                textColor={palette.text.primary}
              >
                Apply Now!
              </Text>
              <Text
                textSize="cardSubtitle"
                textAlign="center"
                textColor={palette.text.secondary}
                style={{ marginTop: '1rem' }}
              >
                Simple and Quick Application Process
              </Text>
              <br></br>

              <VanillaNewAppButton style={{ maxWidth: '24rem' }} setFlag>
                New Application
              </VanillaNewAppButton>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
};
