import React from 'react';

import { withStyles } from '@material-ui/core';
import _Button from '@material-ui/core/Button';

import Icon from 'components/Icon';
import Text from 'components/TextFields/Text';

const Button = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(30,39,46,0.6)',
    '& svg': {
      marginRight: 8,
    },
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))(_Button);

export const SelectedMenuActionButton = ({ icon, label, onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon icon={icon} />

      <Text textSize="sm+" semibold>
        {label}
      </Text>
    </Button>
  );
};
