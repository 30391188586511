import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  Button,
  TextField,
  withStyles,
  Grid,

} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';
import { useFormikContext } from 'formik';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { protectedPost } from 'services/http';
import to from 'await-to-js';
import { MoneyFormatField } from 'components/InputFields/MoneyFormatField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { VanillaButton } from 'components/Buttons';
import { useDisclosure } from 'services/hooks/useDisclosure';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import { useAlertContext } from 'components/AlertContext';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: 24,
    },
  },
  modal: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxWidth: '28rem',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 2),
  },
  containedButton: {
    color: 'white',
    marginTop: '1rem',
    marginBottom: '1rem',
    padding: 8,
    paddingLeft: 24,
    paddingRight: 24,
    fontWeight: 600,
    // width: 198,
  },
  formControlInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  additionalInfo: {
    backgroundColor: '#F9FAFC',
    border: 'solid 1px #F9FAFC',
    borderRadius: 8,
    color: '#1E272E',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    padding: 12,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'nowrap',
  },
}));
const FormField = withStyles(() => ({
  root: {
    marginTop: 24,
    marginBottom: 24,
    '& > *': {
      marginBottom: '0.75rem',
    },
  },
}))(Box);

const FormLabel = withStyles(() => ({
  root: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '18px',
  },
}))(Typography);



const FormFieldWrapper = ({ label, children }) => {
  return (
    <FormField>
      <FormLabel as="h3">{label}</FormLabel>
      {children}
    </FormField>
  );
};
const AddNewRefundOrder = ({
  setFetchedData,
  setcreateFlag,
  createFlag,
  applicationNumber,
  originalLoanDetails,
  setAlertMessage,
  setRefundTab,
  setErrorAlertMessage,
  anyPartialRefund,
  validHIRefund,
  paymentsRefundAmount,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [resetValue, setResetValue] = useState(true);
  const [refundType, setRefundType] = useState(validHIRefund ? 1 : 0);


  const submitRefundOrders = async () => {
    const payload = {
      refund: {
        refund_type: refundType,
        refund_amount: amount,
        loan_id: Number(loanId),
        reason: String(reason)
      },
    };
    const [err, res] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/refunds`,
        payload,
      ),
    );
    if (err) {
      let errorMsg = err?.response?.data?.message?.base[0];
      if (
        err?.response?.data?.message?.base[0].includes('exceed credit limit')
      ) {
        const msgArr = err?.response?.data?.message?.base[0].split(' ');
        var currValue = parseFloat(msgArr[msgArr.length - 1])
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
        msgArr.pop();
        // msgArr[msgArr.length-1] = currValue;
        errorMsg = `${msgArr.join().replaceAll(',', ' ')} $${currValue}`;
      }

      setErrorAlertMessage(errorMsg || 'Error');
      return;
    }
    setAlertMessage('Refund created succesfully');
    setRefundTab(true);
    setFetchedData(false);
    setcreateFlag(false);
    setResetValue(true);
  };


  const handleCancel = () => {
    const partialRefundAmount = 0;
    setcreateFlag(false);
    setResetValue(true);
    setReason('');
    setRefundType(0);
    setAmount(partialRefundAmount);
    setCanEdit(true);

  };
  let existingAmount = '';
  let loanId = '';
  let creditLimit = '';
  if (originalLoanDetails) {
    existingAmount = paymentsRefundAmount
      ? paymentsRefundAmount
      : originalLoanDetails?.data?.data?.attributes?.dealer_net_funding;
    loanId = originalLoanDetails?.data?.data?.attributes?.id;
    const applicationData = originalLoanDetails?.data?.included?.find(
      (element) => element.type === 'application',
    );
    creditLimit = applicationData
      ? applicationData?.attributes?.credit_limit
      : '';
  }
  const formik = useFormikContext();
  if (!formik) {
    console.error('error loading form');
  }



  const { values } = formik ? formik : '';
  const [canEdit, setCanEdit] = useState(true);
  const [radioValue, setRadioValue] = useState('');
  const handleRefundValue = (event) => {

    setRadioValue(event.target.value);
    if (event.target.value === 'Partial') {
      const partialRefundAmount = 0;
      setCanEdit(true);
      setReason('');
      setAmount(partialRefundAmount);
      setRefundType(0);
    }
    if (event.target.value === 'Full') {
      const fullRefundAmount = `  $ ${existingAmount}`;
      setCanEdit(false);
      setReason('');
      setAmount(fullRefundAmount);
      setRefundType(1);
    }

  };
  const [amount, setAmount] = useState(paymentsRefundAmount ?  paymentsRefundAmount : 0);

  const [reason, setReason] = useState("");
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const [refundRequest, setRefundRequest] = useState(false);

  return (
    createFlag && (
      <div data-testid="addChangeOrder-container">
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            defaultValue="Partial"
            onChange={handleRefundValue}
          >

            {!validHIRefund && (<FormControlLabel value="Partial" control={<Radio />} label="Partial Refund" />)}
            <FormControlLabel checked={validHIRefund} value="Full" control={<Radio />} label="Full Refund" disabled={anyPartialRefund} />
          </RadioGroup>
        </Grid>
        <FormFieldWrapper label="Refund Amount">
          <TextField
            variant="outlined"
            autoFocus
            id='refund-amount'
            fullWidth
            name="refundAmount"
            required
            value={amount}
            prefix="$"
            disabled={Boolean(paymentsRefundAmount) || !canEdit}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
          />
        </FormFieldWrapper>

        <FormFieldWrapper label="Reason" >
          <TextField
            variant='outlined'
            value={reason}
            autoFocus
            fullWidth
            autoComplete='off'
            name='reason'
            onChange={handleReasonChange}
            required
          >

          </TextField>
        </FormFieldWrapper>
        <div className={classes.buttonWrapper}>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={() => handleCancel()}
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.containedButton}
            onClick={
              () => {
                if (refundType === 0) {
                  if (amount > existingAmount || amount === 0) {
                    setErrorAlertMessage('Refund Amount should not Exceed Total Project Amount');
                    return '';
                  }    
                  setRefundRequest(true);
                          
                }
                setRefundRequest(true);
              }
            }

            disabled={(!(reason && amount)) || amount <1}
          >
            REQUEST
          </Button>
          <ConfirmationDialog
            desc="Are you sure you want to request a refund? This action can not be canceled"
            dialogState={refundRequest}
            handleClose={() => { setRefundRequest(false) }}
            handleCancel={() => submitRefundOrders()}
          // infoPopup 
          />
        </div>
      </div>
    )
  );

};

export default AddNewRefundOrder;
