import React from 'react';

import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
} from '@material-ui/core';

import Icon from 'components/Icon';

const useStyles = makeStyles(() => ({
  subDesc: {
    fontSize: '0.875rem',
  },
}));

export const ConfirmationDialog = ({
  desc,
  subDesc,
  dialogState,
  handleClose,
  handleCancel,
  infoPopup,
  secondLook,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <Icon icon="errorIcon" />
          </Grid>
          <Grid item xs={8}>
            <DialogContentText id="alert-dialog-description">
              {desc}
            </DialogContentText>

            {subDesc && (
              <DialogContentText
                id="alert-dialog-sub-description"
                className={classes.subDesc}
                dangerouslySetInnerHTML={{ __html: subDesc }}
              >
                {/* {subDesc} */}
              </DialogContentText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {secondLook ? (
          <>
            <Button onClick={handleClose}>DECLINE</Button>
            <Button onClick={handleCancel} color="primary" autoFocus>
              ACCEPT
            </Button>
          </>
        ) : !infoPopup ? (
          <>
            <Button onClick={handleClose}>No</Button>
            <Button onClick={handleCancel} color="primary" autoFocus>
              Yes
            </Button>
          </>
        ) : (
          <Button onClick={handleClose}>Okay</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
