import React, { useEffect, useMemo } from 'react';
import { updateData } from 'redux/actions';
import { useQuery } from 'react-query';
import Grid from '@material-ui/core/Grid';
import Text from 'components/TextFields/Text';
import { useUserContext } from 'services/hooks/useUser';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { connect } from 'react-redux';
import { Box, FormControl, TextField, Typography } from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import PulseLoader from 'react-spinners/PulseLoader';
const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    paddingBottom: 12,
  },
  container: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  title: {
    marginBottom: '0.5rem',
  },
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
      paddingTop: '10px',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
  queryFilter: {
    '& .MuiAutocomplete-inputRoot': {
      marginTop: '3px',
      padding: '3.5px !important',
      width: '100%',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(30,39,46,0.2)',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input': {
      padding: '0.5rem 0.5rem 0.5rem 0',
    },
  },
}));
export const StepTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 600,
    letterSpacing: '0.6px',
    lineHeight: '1.75rem',
  },
}))(Typography);

export const StepSubtitle = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    letterSpacing: '0.65px',
    lineHeight: '1.125rem',
  },
}))(Typography);

const DealerSection = (props) => {
  const { dealerLevelId, dealerLevelRole } = useUserContext();
  const theme = useTheme();
  const {
    isEdit,
    updateMerchantData,
    dealerNetworkOptions,
    dealsData,
    noHeader,
    setShowLoader,
  } = props;
  const classes = useStyles();
  const { data: dealerNetworks = null } = useQuery(
    ['dealerNetworks', { dealerLevelId }],
    dealerNetworkOptions,
  );

  const isNullOrUndefined = (value) => {
    if (value === null || value === undefined) {
      return true;
    }
    return false;
  };

  const filterOptions = createFilterOptions({
    stringify: (option) => `${option.label} ${option.dealerCode}`,
  });

  useEffect(() => {
    if (
      !isNullOrUndefined(dealerNetworks) ||
      !['affiliate', 'organization', 'super_admin'].includes(dealerLevelRole)
    ) {
      updateMerchantData(true);
    }
  }, [dealerNetworks]);

  return (
    <>
      <>
        {!noHeader && (
          <Box pt={6} pb={3}>
            <StepTitle>Merchant</StepTitle>

            <StepSubtitle>
              Please assign this <strong>application</strong> to a{' '}
              <strong>Merchant</strong>.
            </StepSubtitle>
          </Box>
        )}
        {!dealerNetworkOptions && (
          <Box p={6} display="flex" alignItems="center" flexDirection="column">
            <PulseLoader color={theme.palette.primary.light} size="1rem" />
            <Text textSize="sm+" semibold textColor="secondary">
              Please wait while we fetch Merchants for you...
            </Text>
          </Box>
        )}
        {dealerNetworkOptions && (
          <Grid container spacing={3} rowSpacing={3}>
            {noHeader && <Grid item xs={12} md={4} lg={4}></Grid>}
            <Grid item xs={12} md={noHeader ? 4 : 6} lg={noHeader ? 4 : 6}>
              {' '}
              <FormControl
                variant="outlined"
                className={classes.formControl}
                //   error={Boolean(meta.error)}
              >
                <Autocomplete
                  options={dealerNetworkOptions}
                  autoHighlight
                  filterOptions={filterOptions}
                  value={
                    dealsData.dealer_code &&
                    dealerNetworkOptions.find(
                      (v) => v.dealerCode === dealsData.dealer_code,
                    )
                  }
                  getOptionSelected={(option, value) => {
                    option?.value === value;
                  }}
                  onChange={(option, value) => {
                    if (setShowLoader) {
                      setShowLoader(true);
                    }
                    props.updateData({
                      name: 'dealer_code',
                      value: value?.dealerCode,
                    });
                  }}
                  getOptionLabel={(option) => {
                    return option?.label === undefined
                      ? ''
                      : `${option?.label} (${option.dealerCode})`;
                  }}
                  renderOption={(option) => (
                    <>
                      {option.label} ({option.dealerCode})
                    </>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Select a Merchant"
                      placeholder="Select a Merchant"
                      variant="outlined"
                      size="large"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            {noHeader && <Grid item xs={12} md={4} lg={4}></Grid>}
          </Grid>
        )}
      </>
    </>
  );
};
const mapStatesToProps = (state) => {
  return { dealsData: state.appReducer };
};

export default connect(mapStatesToProps, {
  updateData,
})(DealerSection);
