import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import to from 'await-to-js';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { format, parseISO } from 'date-fns';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  FormHelperText,
  IconButton,
} from '@material-ui/core';

import Text from 'components/TextFields/Text';
import { updateData } from 'redux/actions';
import { map } from 'ramda';
import Documents, {
  ImportantInfoCard,
} from 'pages/ApplicationDetail/DocumentsTab';
import { useAlertContext } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';
import { ConfirmationDialog } from 'components/ConfirmationDialog';
import StatusButton from 'components/Buttons/StatusButton';
import { useAddressLocationsContext } from 'services/contexts/useAddressLocations';
import { translateEmploymentType } from 'services/utils';
import { phoneMaskPipe } from 'components/InputFields/PhoneNumberField';
import { Autocomplete } from '@material-ui/lab';
import NumberFormat from 'react-number-format';
import { protectedGet, protectedPost, protectedPut } from '../../services/http';
import { finalFieldsSection } from './InputFields';
import { useStyles } from "./useStyles";

// TODO: Make these standalone components
const StepTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 600,
    letterSpacing: '0.6px',
    lineHeight: '1.75rem',
  },
}))(Typography);

const StepSubtitle = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    letterSpacing: '0.65px',
    lineHeight: '1.125rem',
  },
}))(Typography);

const StartProject = ({ dealsData, appData, updateData, enableStagedFunding }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { appId } = useParams();
  const { user } = useUserContext();
  const { states } = useAddressLocationsContext();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();

  const [editFieldsInitial, setEditFieldsInitial] = useState(false);
  const [businessTypes, setBusinessTypes] = useState();
  const [addCancelButton, setAddCancelButton] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [disableInviteInput, setDisableInviteInput] = useState(true);
  const [disableCoAppInput, setDisableCoAppInput] = useState(true);
  const [disableUpdate, setDisableUpdate] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState({});
  const [showProjectSection, setShowProjectSection] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [coAppEmailError, setCoAppEmailError] = useState(false);
  const [enableLoanContract, setEnableLoanContract] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [openConirmationDialog, setOpenConirmationDialog] = useState(false);
  const [loanContractSentDialog, setLoanContractSentDialog] = useState(false);
  const [changeSelectedDeal, setChangeSelectedDeal] = useState(false);
  const [openEditInfoDialog, setOpenEditInfoDialog] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loanContractValidation, setLoanContractValidation] = useState(false);
  const [processingLoanDocs, setProcessingLoanDocs] = useState(false);
  const [documentVerified, setDocumentVerified] = useState(true);
  const [loanContractPreRequisite, setLoanContractPreRequisite] = useState();
  const [startProjectPreRequisite, setStartProjectPreRequisite] = useState();

  const isAppCanceled = appData?.data?.attributes?.draft_status === 'canceled';

  useEffect(() => {
    let loanPreReqString =
      'Please check the following items are complete: ' +
      '<br /> &#9679; All application information provided.' +
      '<br /> &#9679; Application Should be in either  Conditional-Approval or Approval State' +
      '<br /> &#9679; Loan Description and Type Provided' +
      '<br /> <br /><span style=margin-Top:"10px"> If you continue to have issues, then please message Power Pay or call  800-397-4485 for assistance.</span>';
    let startLoanPreReqString = '';
    appData?.data?.attributes?.reasons_project_cant_be_started.map((item) => {
      startLoanPreReqString = `${startLoanPreReqString}  <br /> &#9679;  ${item}`;
      return '';
    });
    startLoanPreReqString = `${startLoanPreReqString}  <br /> <br /><span style=margin-Top:"10px"> If you continue to have issues, then please message Power Pay or call  800-397-4485 for assistance.</span>`;

    if (!editFieldsInitial) {
      loanPreReqString = loanPreReqString.replace(
        '<br /> &#9679; All application information provided.',
        '',
      );
    }

    if (
      dealsData?.status === 'conditional_approval' ||
      dealsData?.status === 'approved'
    ) {
      loanPreReqString = loanPreReqString.replace(
        '<br /> &#9679; Application Should be in either  Conditional-Approval or Approval State',
        '',
      );
    }

    setLoanContractPreRequisite(loanPreReqString);
    setStartProjectPreRequisite(startLoanPreReqString);
  }, [
    editFieldsInitial,
    dealsData?.status,
    documentVerified,
    dealsData?.homeOwnerAccount,
    appData?.data?.attributes?.reasons_project_cant_be_started,
  ]);
  const fieldsToUpdateApplicant = [
    'phone_number',
    'driver_license_state',
    'driver_license_number',
    'active_military',
    'service_date'
  ];
  const fieldsToUpdateCOApplicant = [
    'coapplicant_phone_number',
    'coapplicant_driver_license_state',
    'coapplicant_driver_license_number',
    'coapplicant_active_military',
  ];
  useEffect(() => {
    (async () => {
      try {
        if (dealsData?.loanDetails) {
          // docs are generated async - so we need to poll backend for when they're ready
          if (dealsData.signatureMethod === 'Wet Sign') {
            setProcessingLoanDocs(true);

            let retries = 0;
            const interval = 5000; // 5 seconds
            const maxRetries = 36; // try for three minutes (I think this should be plenty - in testing it seems to take about ~10-15 seconds)

            const isDownloadReady = () => {
              const check = async () => {
                const [err, response] = await to(
                  protectedGet(
                    `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/loans/${dealsData.loanDetails.id}/sign_download`,
                  ),
                );

                return err ? false : response.data.ready;
              };

              check().then(async (ready) => {
                if (ready) {
                  setProcessingLoanDocs(false);
                  const [err, rawApplication] = await to(
                    protectedGet(
                      `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
                    ),
                  );

                  if (rawApplication) {
                    updateData({
                      name: 'appData',
                      value: rawApplication.data,
                    });
                  }
                } else if (retries > maxRetries) {
                  setErrorAlertMessage(
                    'Please try again...documents failed to generate',
                  );
                } else {
                  retries++;
                  setTimeout(isDownloadReady, interval);
                }
              });
            };

            isDownloadReady();
          }

          // setFetchedData(true);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dealsData.loanDetails]);

  useEffect(() => {
    if (enableLoanContract && !editFieldsInitial) {
      setLoanContractValidation(true);
    }
  }, [enableLoanContract, editFieldsInitial]);
  const handleClickOpen = (isProject) => {
    if (isProject) {
      setOpenProjectDialog(true);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    if (!dealsData.signatureMethod) {
      updateData({
        name: 'signatureMethod',
        value: 'email',
      });
    }
    if (appData) {
      sectionArray.map((sectionName, index) => {
        finalFieldsSection[sectionName].map((item) => {
          if (
            sectionName.includes('Co-Applicant') &&
            !appData.data.meta.has_coapplicant
          ) {
            return '';
          }
          getFieldValue(sectionName, item.fieldName, appData);
          return '';
        });
        setFetchedData(true);
        return '';
      });
    }
    fetchAppData();
  }, []);

  const fetchAppData = async (shouldUpdateData) => {
    const [err, rawApplication] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
      ),
    );
    if (!err) {
      if (rawApplication?.data?.data?.relationships?.homeowner?.data?.id) {
        setInviteSent(true);
      }
      updateData({
        name: 'service_date',
        value: rawApplication?.data?.data?.attributes?.service_date,
      });
      if (!appData || shouldUpdateData) {
        updateData({
          name: 'appData',
          value: rawApplication.data,
        });
        sectionArray.map((sectionName, index) => {
          finalFieldsSection[sectionName].map((item) => {
            if (
              sectionName.includes('Co-Applicant') &&
              !rawApplication.data.data.meta.has_coapplicant
            ) {
              return '';
            }
            getFieldValue(sectionName, item.fieldName, rawApplication.data);
            return '';
          });
          setFetchedData(true);
          return '';
        });
      }
      let docsAllVerified = true;
      rawApplication.data.data.attributes.documents.map((document) => {
        if (document.status !== 'verified') {
          setDocumentVerified(false);
          docsAllVerified = false;
        }
        if (
          document.file_type === 'loan_contract' &&
          (document.status === 'signed' || document.status === 'verified')
        ) {
          setShowProjectSection(true);
        }
        return '';
      });
      updateData({
        name: 'status',
        value: rawApplication?.data?.data?.attributes?.status,
      });
      if (rawApplication?.data?.data?.relationships?.homeowner?.data?.id) {
        updateData({
          name: 'homeOwnerAccount',
          value: true,
        });
      }
      if (
        (rawApplication?.data?.data?.attributes?.status ===
          'conditional_approval' ||
          rawApplication?.data?.data?.attributes?.status === 'approved') &&
        !editFieldsInitial
      ) {
        setEnableLoanContract(true);
      }
      rawApplication.data.included.map((item) => {
        if (item.type === 'loan') {
          const hasStarted = [
            'in_progress',
            'completed',
            'finished',
            'change_requested',
          ].includes(item?.attributes?.status);
          if (hasStarted) {
            window.location.href = `/dealer/applications/${appId}`;
            // history.push(`/dealer/dashboard`);
          } else {
            updateData({
              name: 'signatureMethod',
              value: setSignatureMethod(item.attributes.signature_method),
            });
            updateData({
              name: 'loanDetails',
              value: item,
            });
            if (
              rawApplication?.data?.data?.attributes?.can_start_project &&
              rawApplication?.data?.data?.attributes
                ?.reasons_project_cant_be_started.length === 0
            ) {
              setDisableButton(false);
            }
          }
        }
        return '';
      });
      const dealSetId =
        rawApplication?.data?.data?.relationships?.deal_set?.data?.id;
      const dealId = rawApplication?.data?.data?.relationships?.deal?.data?.id;
      if (!dealId) {
        history.push(`/applications/${appId}/review`);
      }
      if (rawApplication?.data?.data?.relationships?.loan?.data?.id) {
        updateData({
          name: 'loanId',
          value: rawApplication?.data?.data?.relationships?.loan?.data?.id,
        });
      }
      const [err, dealsData] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/${dealSetId}?application_id=${appId}`,
        ),
      );
      if (dealsData) {
        updateData({
          name: 'deal_struct_id',
          value: dealsData.data.data.relationships?.deal_struct?.data?.id,
        });
        dealsData.data.included.map((deal) => {
          if (deal.id === dealId && deal.type === 'deal') {
            setSelectedDeal(deal.attributes);
          }

          return '';
        });
      }
      if (err) {
        setErrorAlertMessage(
          err?.response?.data?.message || 'Error while fetching Deals details.',
        );
      }
    }
    if (err) {
      setErrorAlertMessage(
        err?.response?.data?.message ||
          'Error while fetching application details.',
      );
    }
  };

  const sectionArray = [
    "Applicant's Information",
    "Co-Applicant's Information",
    'Property Information',
    'Treatment Information',
    'Update Information',
    'User Account',
    'Documentation',
    'Loan Contract',
  ];

  const sections = [
    {
      key: 'applicant_information',
      name: "Applicant's Information",
      heading: 'Application Information',
      subHeading: "Please review the applicant's information for correctness.",
    },
    {
      key: 'coapplicant_information',
      name: "Co-Applicant's Information",
      heading: '',
      subHeading:
        "Please review the co-applicant's information for correctness.",
    },
    {
      key: 'property_information',
      name: 'Property Information',
      heading: '',
      subHeading: 'Please review the property information for correctness.',
    },
    {
      key: 'treatment_information',
      name: 'Treatment Information',
      heading: '',
      subHeading: 'Please review the treatment information for correctness.',
    },
    {
      key: 'update_info',
      name: 'Update Information',
      heading: '',
      subHeading: '',
    },
    {
      key: 'user_account',
      name: 'User Account',
      heading: 'User Account',
      subHeading:
        'An email was sent inviting the applicant to link their user account to the application.',
    },
    {
      key: 'documentation',
      name: 'Documentation',
      heading: 'Stipulations & Documents',
      subHeading:
        'Please review stipulations and upload documents in the <strong>Stips &amp; Docs</strong> panel located in the right sidebar (or below on mobile devices).',
    },
    {
      key: 'loan_contract',
      name: 'Loan Contract',
      heading: 'Loan Contract',
      subHeading:
        'Generate a loan contract, but please note that our team will still have to <strong>review the application and documentation</strong>.',
    },
  ];

  const getDropDownValues = (fieldName) => {
    const businessTypeArray = [];

    if (fieldName === 'project_type' && businessTypes) {
      businessTypes.map((businessType) => {
        businessTypeArray.push(businessType.title);
        return businessTypeArray;
      });
      return businessTypeArray;
    }

    const methodArray = [];
    appData.data.attributes.loan_contract_signature_methods.map((method) => {
      const value =
        method.label === 'Platform'
          ? 'On-screen'
          : method.label === 'Physical'
          ? 'Wet Sign'
          : 'Email';
      methodArray.push(value);
      return methodArray;
    });
    return methodArray;
  };
  const changeFieldValue = (fieldName, fieldValue) => {
    if (fieldName.includes('driver_license_number') && fieldValue.length > 20) {
      return '';
    }

    if (
      fieldName.includes('employer_time') &&
      (fieldValue < 0 || fieldValue > 100)
    ) {
      return '';
    }
    if (fieldName.includes('email')) {
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (fieldName === 'coapplicant_email') {
        if (!pattern.test(fieldValue)) {
          setCoAppEmailError(true);
        } else {
          setCoAppEmailError(false);
        }
      } else if (fieldName === 'email') {
        if (!pattern.test(fieldValue)) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }
      }
    }
    updateData({
      name: fieldName,
      value: fieldValue,
    });
  };
  const ssnFormat = (ssn) => {
    const lastDigits = ssn.slice(-4);
    const newSsn = `XXX-XX-${lastDigits}`;
    return newSsn;
  };
  const getSelectedDealFields = () => {
    return (
      <>
        <StepTitle>Deal Selected</StepTitle>
        <StepSubtitle>{selectedDeal.promo_description}</StepSubtitle>
        <Grid
          container
          style={{
            marginTop: '24px',
            marginBottom: '48px',
          }}
        >
          {(selectedDeal.stage_funding_applied ||
            selectedDeal.buydown_applied) && (
            <Grid item xs={12} md={4}>
              {/* Project Amount */}
              <Box>
                <Typography style={{ fontSize: 'small' }}>
                  Loan Options
                </Typography>
                <Typography
                  style={{
                    fontWeight: 'bold',
                    fontSize: 'small',
                  }}
                >
                  {selectedDeal.stage_funding_applied && 'Advance Funding'}
                  {selectedDeal.buydown_applied &&
                    selectedDeal.stage_funding_applied &&
                    ', '}
                  {selectedDeal.buydown_applied && 'Buy Down'}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={
              selectedDeal.stage_funding_applied || selectedDeal.buydown_applied
                ? 2
                : 3
            }
          >
            <Box>
              <Typography style={{ fontSize: 'small' }}>
                {enableStagedFunding ? "Financed Amount" : "Project Amount"}
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                }}
              >
                <NumberFormat
                  className={classes.dataItem}
                  value={selectedDeal.project_amount.cents / 100}
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={
              selectedDeal.stage_funding_applied || selectedDeal.buydown_applied
                ? 2
                : 3
            }
          >
            {/* Rate  */}
            <Box>
              <Typography style={{ fontSize: 'small' }}>
                {selectedDeal.show_promo_apr ? 'APR' : 'Interest Rate'}
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                }}
              >
                {`${
                  selectedDeal.show_promo_apr
                    ? selectedDeal.promo_composite_apr
                    : selectedDeal.interest_rate
                }%`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={
              selectedDeal.stage_funding_applied || selectedDeal.buydown_applied
                ? 2
                : 3
            }
          >
            {/* Term */}
            <Box>
              <Typography style={{ fontSize: 'small' }}>Term</Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                }}
              >
                {selectedDeal.term_months} Months
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={
              selectedDeal.stage_funding_applied || selectedDeal.buydown_applied
                ? 2
                : 3
            }
          >
            {/* Term */}
            <Box>
              <Typography style={{ fontSize: 'small' }}>
                Monthly Payment
              </Typography>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontSize: 'small',
                }}
              >
                <NumberFormat
                  className={classes.dataItem}
                  value={
                    selectedDeal.teaser_available
                      ? selectedDeal.teaser_monthly_payment.cents / 100
                      : selectedDeal.monthly_payment.cents / 100
                  }
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  };
  const getArrayFields = (type, fieldName, coApp, appData) => {
    let value = '';
    appData?.included.map((item) => {
      if (item.type === type) {
        if (
          type === 'financial_detail' &&
          item.attributes.user_type !== 'homeowner'
        ) {
          if (coApp) {
            value = item.attributes[fieldName];
          } else {
            return true;
          }
        } else if (!coApp) {
          switch (type) {
            case 'address': {
              value = `${item.attributes.street_address} ${item.attributes.city} ${item.attributes.state} ${item.attributes.zip_code}`;
              break;
            }
            case 'loan': {
              value = item.attributes[fieldName];
              break;
            }
            case 'financial_detail': {
              value = item.attributes[fieldName];
              break;
            }
            default:
              break;
          }
        }
      }
      return true;
    });
    return value;
  };

  const buttonActions = async (title) => {
    if (title === 'Send Contract') {
      if (dealsData.loanId) {
        setLoanContractSentDialog(true);
      } else if (dealsData.status === 'conditional_approval') {
        setOpenConirmationDialog(true);
      } else {
        sendLoanContract(selectedDeal);
      }
    } else {
      const { err, data } = await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/homeowners/create_homeowner_new_flow`,
        {
          email: dealsData.email
            ? dealsData.email
            : appData.data.attributes.email,
          application_id: appId,
        },
      );
      if (data) {
        if (dealsData.email) {
          const url = `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/applicant_email_change_order`;
          try {
            await protectedPost(url, {
              applicant_email: dealsData.email,
              coapplicant_email: null,
            });
            window.location.reload();
          } catch (e) {
            setErrorAlertMessage(
              e?.response?.data?.message ||
                'There was an error updating your info',
            );
          }
        } else {
          setAlertMessage('Email Invite Sent!');
        }
      }
      if (err) {
        setErrorAlertMessage(
          err?.response?.data?.message ||
            'Error while Sending Homeowner Email.',
        );
      }
    }
  };
  const updateCoAppEmail = async () => {
    if (dealsData.coapplicant_email) {
      const url = `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/applicant_email_change_order`;
      try {
        await protectedPost(url, {
          applicant_email: dealsData.email
            ? dealsData.email
            : appData.data.attributes.email,
          coapplicant_email: dealsData.coapplicant_email,
        });
        window.location.reload();
      } catch (e) {
        setErrorAlertMessage(
          e?.response?.data?.message || 'There was an error updating your info',
        );
      }
    }
  };
  const stageFunding = '';
  const term = '';
  const sendLoanContract = async (values) => {
    const ohNo = values.promo_code === 'ON' ? true : false;
    const equalPay = values.promo_code === 'BP' ? true : false;
    let ohNoMonths = '';
    let equalPayMonths = '';
    if (ohNo) {
      ohNoMonths = values.promo_description.split(' ')[0];
    }
    if (equalPay) {
      equalPayMonths = values.promo_description.split(' ')[0];
    }
    const payload = {
      loan: {
        project_type: dealsData?.project_type,
        unit_description: dealsData?.unit_description,
        signature_method: getSignatureMethod(),
      },
    };

    const [err, rawLoanDetails] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/loans`,
        payload,
      ),
    );
    if (rawLoanDetails) {
      setAlertMessage('Loan Contract Sent Succesfully');
      setLoanContractSentDialog(false);
      setOpenConirmationDialog(false);
      if (getSignatureMethod() !== 'physical') {
        setTimeout(updateDocs, 3000);
      }
      // rawLoanDetails.data.included.map((data) => {
      //   if (data.type === 'application') {
      //     updateData({
      //       name: 'appData',
      //       value: {data},
      //     });
      //   }
      //   return '';
      // });
      updateData({
        name: 'signatureMethod',
        value: setSignatureMethod(
          rawLoanDetails?.data?.data?.attributes?.signature_method,
        ),
      });
      updateData({
        name: 'loanDetails',
        value: rawLoanDetails.data.data,
      });
      rawLoanDetails.data.included.map((item) => {
        if (item.type === 'application') {
          updateData({
            name: 'signedApplicant',
            value: item.attributes.signed_applicant,
          });
          updateData({
            name: 'signedCoApplicant',
            value: item.attributes.signed_coapplicant,
          });
        }
        return '';
      });
      updateData({
        name: 'loanId',
        value: rawLoanDetails.data.data.attributes.id,
      });
    }
    if (err) {
      setErrorAlertMessage(
        err?.response?.data?.message || 'Error while Sending Loan Contract.',
      );
    }
    // setting the payload data in state
  };
  const updateDocs = async () => {
    const [err, rawApplication] = await to(
      protectedGet(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
      ),
    );

    if (rawApplication) {
      updateData({
        name: 'appData',
        value: rawApplication.data,
      });
    }
  };
  const getSignatureMethod = () => {
    let signatureMethod = '';
    if (dealsData.signatureMethod === 'On-screen') {
      signatureMethod = 'platform';
    } else if (dealsData.signatureMethod === 'Wet Sign') {
      signatureMethod = 'physical';
    } else {
      signatureMethod = 'email';
    }
    return signatureMethod;
  };
  const setSignatureMethod = (signatureMethodInput) => {
    let signatureMethod = '';
    if (signatureMethodInput === 'platform') {
      signatureMethod = 'On-screen';
    } else if (signatureMethodInput === 'physical') {
      signatureMethod = 'Wet Sign';
    } else {
      signatureMethod = 'Email';
    }
    return signatureMethod;
  };
  const handleStartProject = async () => {
    const filesArray = appData.data.attributes.documents;
    appData?.data?.attributes?.closed_stipulations?.map((doc) => {
      if (doc?.file) filesArray.push(doc?.file);
      return filesArray;
    });

    appData?.data?.attributes?.closed_stipulations_homeowner?.map((doc) => {
      if (doc?.file) filesArray.push(doc?.file);
      return filesArray;
    });
    appData?.data?.attributes?.open_stipulations_homeowner?.map((doc) => {
      if (doc?.file) filesArray.push(doc?.file);
      return filesArray;
    });

    appData.data.attributes?.open_stipulations?.map((doc) => {
      if (doc?.file) filesArray.push(doc?.file);
      return filesArray;
    });
    const loanId = appData.data.relationships.loan.data.id;
    const missingDoc = appData.data.attributes.required_docs.some(
      (doc, i) => !filesArray?.[i]?.url,
    );

    if (missingDoc) {
      setErrorAlertMessage('Please Upload All Required Documents');
      return;
    }

    try {
      await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/loans/${loanId}/start`,
      );
      history.push(`/dealer/applications/${appId}`);
    } catch ({ response }) {
      if (response) {
        if (response?.data?.message) {
          setErrorAlertMessage(response.data.message[0]);
        }
      }
    }
  };
  const updateApplicationFields = async () => {
    const formData = {
      application: {
        service_date: dealsData?.service_date
          ? format(new Date(dealsData?.service_date), 'yyyy-MM-dd')
          : null,
      },
      homeowner: {
        phone_number: dealsData.phone_number.substring(0, 13),
        active_military: dealsData.active_military === 'Yes' ? true : false,
      },
      financial_details: {
        0: {
          employer_time: dealsData.employer_time,
          user_type: 'homeowner',
        },
      },
    };
    if (appData.data.meta.has_coapplicant) {
      formData.has_coapplicant = true;
      formData.coapplicant = {
        phone_number: dealsData.coapplicant_phone_number.substring(0, 13),
        active_military:
          dealsData.coapplicant_active_military === 'Yes' ? true : false,
      };
      formData.financial_details['1'] = {
        employer_time: dealsData.coapplicant_employer_time,
        user_type: 'coapplicant',
      };
    }
    //   dealsData.employer_time;
    const method = protectedPut;
    const url = `/v1/applications/${appId}/update_submitted_app`;
    const [err, res] = await to(method(url, formData));
    if (err) {
      setErrorAlertMessage(
        err?.response?.data?.message ||
          'Error while updating application. Please try again.',
      );
    }

    if (res) {
      /*
        This block of code inside of this if is proof of how little thoughtprocess was put into making this code any good
        And trying to make it good is something i simply don't have the patience for today. Maybe one day
      */
      const url = `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/update_driver_license`;
      try {
        const [driverLicenseErr, _] = await protectedPost(url, {
          coapplicant_driver_license_number:
            dealsData.coapplicant_driver_license_number,
          coapplicant_driver_license_state:
            dealsData.coapplicant_driver_license_state,
          driver_license_number: dealsData.driver_license_number,
          driver_license_state: dealsData.driver_license_state,
        });
        window.location.reload();
        setAlertMessage('Information updated succesfully');
        if (driverLicenseErr) {
          setErrorAlertMessage(
            driverLicenseErr?.response?.data?.message ||
              'Error while updating application. Please try again.',
          );
        }
      } catch (e) {
        window.location.reload();
        if (err) {
          setErrorAlertMessage(
            err?.response?.data?.message ||
              'Error while updating application. Please try again.',
          );
        }
      }
    }
  };
  const handleUploadDocument = (event, document) => {
    setShowLoader(true);
    const documentType = document.fileType;
    const file = event.target.files[0];
    const data = new FormData();
    data.append('application_id', Number(appId));
    data.append('document', file, documentType);
    data.append('file_type', documentType);
    handleSubmit(data, document);
  };
  const handleSubmit = async (payload, document) => {
    try {
      await protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/documents/${document.id}`,
        payload,
      );
      window.location.reload();
    } catch (e) {
      setShowLoader(false);
      let message = 'file upload error';
      if (e?.response?.data?.message) {
        message = e?.response?.data?.message[0];
      }
      setErrorAlertMessage(message);
    }
  };
  const getFieldValue = (sectionName, fieldName, appData) => {
    let value = '';
    if (
      sectionName.includes('Information') ||
      sectionName === 'Loan Contract'
    ) {
      value = appData?.data.attributes[fieldName];
      switch (fieldName) {
        case 'fullName': {
          value = `${appData?.data.attributes.name} ${appData?.data.attributes.last_name} `;
          break;
        }
        case 'unit_description': {
          value = getArrayFields('loan', 'unit_description', false, appData);
          break;
        }
        case 'project_type': {
          value = getArrayFields('loan', 'project_type', false, appData);
          break;
        }
        case 'coapplicant_fullName': {
          value = `${appData?.data.attributes.coapplicant_name} ${appData?.data.attributes.coapplicant_last_name} `;
          break;
        }
        case 'dob': {
          value = format(
            parseISO(appData?.data.attributes?.birthday),
            'MM/dd/yyyy',
          );
          break;
        }
        case 'service_date': {
          value = appData?.data.attributes?.service_date
            ? format(
                parseISO(appData?.data.attributes?.service_date),
                'MM/dd/yyyy',
              )
            : '';

          break;
        }
        case 'coapplicant_dob': {
          value = format(
            parseISO(appData?.data.attributes?.coapplicant_birthday),
            'MM/dd/yyyy',
          );
          break;
        }
        case 'ssn': {
          value = ssnFormat(appData?.data.attributes[fieldName]);
          break;
        }
        case 'coapplicant_ssn': {
          value = ssnFormat(appData?.data.attributes[fieldName]);
          break;
        }
        case 'address': {
          value = getArrayFields('address', 'address', false, appData);
          break;
        }
        case 'request_amount': {
          value = getArrayFields(
            'financial_detail',
            'request_amount',
            false,
            appData,
          );
          break;
        }
        case 'anual_income': {
          value = getArrayFields(
            'financial_detail',
            'anual_income',
            false,
            appData,
          );
          break;
        }

        case 'employer_name': {
          value = getArrayFields(
            'financial_detail',
            'employer_name',
            false,
            appData,
          );
          break;
        }
        case 'employer_time': {
          value = getArrayFields(
            'financial_detail',
            'employer_time',
            false,
            appData,
          );
          break;
        }

        case 'employer_zip': {
          value = getArrayFields(
            'financial_detail',
            'employer_zip',
            false,
            appData,
          );
          break;
        }
        case 'occupation': {
          value = getArrayFields(
            'financial_detail',
            'occupation',
            false,
            appData,
          );
          break;
        }
        case 'employment_type': {
          value = translateEmploymentType(
            getArrayFields(
              'financial_detail',
              'employment_type',
              false,
              appData,
            ),
          );
          break;
        }
        case 'coapplicant_employer_zip': {
          value = getArrayFields(
            'financial_detail',
            'employer_zip',
            true,
            appData,
          );
          break;
        }
        case 'coapplicant_employer_time': {
          value = getArrayFields(
            'financial_detail',
            'employer_time',
            true,
            appData,
          );
          break;
        }
        case 'coapplicant_occupation': {
          value = getArrayFields(
            'financial_detail',
            'occupation',
            true,
            appData,
          );
          break;
        }
        case 'coapplicant_employment_type': {
          value = translateEmploymentType(
            getArrayFields(
              'financial_detail',
              'employment_type',
              true,
              appData,
            ),
          );
          break;
        }
        case 'coapplicant_employer_name': {
          value = getArrayFields(
            'financial_detail',
            'employer_name',
            true,
            appData,
          );
          break;
        }
        case 'coapplicant_anual_income': {
          value = getArrayFields(
            'financial_detail',
            'anual_income',
            true,
            appData,
          );
          break;
        }
        default:
          break;
      }
    } else if (fieldName === 'email') {
      value = dealsData.email
        ? dealsData.email
        : `${appData?.data.attributes.email}`;
    } else if (fieldName === 'coapplicant_email') {
      value = dealsData.coapplicant_email
        ? dealsData.coapplicant_email
        : `${appData?.data.attributes.coapplicant_email}`;
    } else {
      value = dealsData[fieldName];
    }
    let fieldValue = value;
    if (fieldName.includes('active_military')) {
      fieldValue = value === false ? 'No' : value === true ? 'Yes' : null;
    }

    if (fieldsToUpdateApplicant.includes(fieldName) && !fieldValue) {
      if (
        appData?.data?.attributes?.loan_product_code !== 'healthcare' &&
        fieldName === 'service_date'
      ) {
        return '';
      }
      if (
        fieldName === 'employer_time' &&
        translateEmploymentType(
          getArrayFields('financial_detail', 'employment_type', false, appData),
        ) === 'Retired'
      ) {
        return;
      }
      setEditFieldsInitial(true);
    }
    if (
      appData?.data?.meta?.has_coapplicant &&
      fieldsToUpdateCOApplicant.includes(fieldName) &&
      !fieldValue
    ) {
      if (
        fieldName === 'coapplicant_employer_time' &&
        translateEmploymentType(
          getArrayFields('financial_detail', 'employment_type', true, appData),
        ) === 'Retired'
      ) {
        return;
      }
      setEditFieldsInitial(true);
    }

    updateData({
      name: fieldName,
      value: fieldValue,
    });
  };
  const goBackToReviewPage = () => {
    updateData({
      name: 'dealStruct',
      value: '',
    });
    updateData({
      name: 'appData',
      value: '',
    });
    updateData({
      name: 'loanDetails',
      value: '',
    });
    history.push(`/applications/${appId}/review`);
    window.location.reload();
  };
  useEffect(() => {
    let incompleteData = false;
    fieldsToUpdateApplicant.map((item) => {
      if (
        appData?.data?.attributes?.loan_product_code !== 'healthcare' &&
        item === 'service_date'
      ) {
        return '';
      }
      if (item === 'employer_time' && dealsData.employment_type === 'Retired') {
        return;
      }
      if (!dealsData[item]) {
        incompleteData = true;
      }
      return incompleteData;
    });
    if (appData?.data?.meta?.has_coapplicant) {
      fieldsToUpdateCOApplicant.map((item) => {
        if (
          item === 'coapplicant_employer_time' &&
          dealsData.coapplicant_employment_type === 'Retired'
        ) {
          return;
        }
        if (!dealsData[item]) {
          incompleteData = true;
        }
        return incompleteData;
      });
    }
    setDisableUpdate(incompleteData);
  }, [dealsData]);
  useEffect(async () => {
    if (appData?.data?.attributes?.loan_product_id && !businessTypes) {
      const [err, response] = await to(
        protectedGet(`${process.env.REACT_APP_BASE_URL}/v2/loan_products`),
      );
      if (response) {
        response?.data?.data.map((loanProduct) => {
          if (
            loanProduct.id ===
            appData?.data?.attributes?.loan_product_id.toString()
          ) {
            setBusinessTypes(loanProduct?.attributes?.business_types);
          }
          return businessTypes;
        });
      }
    }
  }, [appData?.data?.attributes?.loan_product_id]);

  const getApplicationField = (sectionFields, sectionName) => {
    return sectionFields.map((item) => {
      if (
        appData.data.attributes.loan_product_code !== 'healthcare' &&
        item.fieldName === 'service_date'
      ) {
        if (!dealsData?.service_date) {
          updateData({
            name: 'service_date',
            value: '0',
          });
        }
        return <Grid item xs={item.size}></Grid>;
      }
      if (
        (item.fieldName === 'employer_time' &&
          dealsData.employment_type === 'Retired') ||
        (item.fieldName === 'coapplicant_employer_time' &&
          dealsData.coapplicant_employment_type === 'Retired')
      ) {
        return;
      }
      if (
        (inviteSent && item.fieldName === 'confirmEmail') ||
        (!appData.data.meta.has_coapplicant && item.coAppField)
      ) {
        return '';
      }
      if (
        sectionName === "Applicant's Information" &&
        dealsData?.employment_type?.toUpperCase() === 'RETIRED' &&
        (item.fieldName === 'employer_name' ||
          item.fieldName === 'employer_zip')
      ) {
        return '';
      }
      if (
        sectionName === "Co-Applicant's Information" &&
        dealsData?.coapplicant_employment_type?.toUpperCase() === 'RETIRED' &&
        (item.fieldName === 'coapplicant_employer_name' ||
          item.fieldName === 'coapplicant_employer_zip')
      ) {
        return '';
      }
      switch (item.type) {
        case 'string': {
          return (
            <Grid item xs={item.size}>
              <TextField
                inputProps={{
                  autoComplete: 'new-password',
                }}
                className={classes.formControlInput}
                label={item.title}
                type={item.inputType ? item.inputType : item.type}
                error={
                  item.fieldName === 'email'
                    ? emailError
                    : item.editable && 
                      !dealsData[item.fieldName] &&
                      sectionName !== 'Loan Contract'
                      ? true
                      : loanContractValidation && !dealsData[item.fieldName] 
                }
                helperText={
                  item.fieldName === 'email' && emailError
                    ? 'Invalid Email'
                    : item.editable &&
                      !dealsData[item.fieldName] &&
                      sectionName !== 'Loan Contract'
                      ? 'This Field is Required'
                      : loanContractValidation && !dealsData[item.fieldName] 
                        ? 'This Field is Required'
                        : ''
                }
                value={
                  item.fieldName.includes('phone_number')
                    ? phoneMaskPipe(
                        dealsData[item.fieldName]
                          ? dealsData[item.fieldName]
                          : '',
                      )
                    : dealsData[item.fieldName]
                }
                style={{
                  width: '100%',
                  marginBottom: '0px',
                }}
                onChange={(e) => {
                  changeFieldValue(item.fieldName, e.target.value);
                }}
                disabled={
                  sectionName.includes('Information')
                    ? editFieldsInitial === true
                      ? !item.editable
                      : true
                    : item.fieldName === 'email'
                    ? disableInviteInput
                    : sectionName === 'Loan Contract'
                    ? !enableLoanContract ||
                      showProjectSection ||
                      editFieldsInitial
                    : false
                }
                variant="outlined"
              />
              {item.fieldName === 'email' &&
                !sectionName.includes('Information') &&
                disableInviteInput &&
                !inviteSent && (
                  <button
                    onClick={() => {
                      setDisableInviteInput(false);
                    }}
                    type="button"
                    className={classes.transparentButtonInvite}
                  >
                    <span className={classes.changeContactText}>
                      Need to update email?
                    </span>
                  </button>
                )}
            </Grid>
          );
        }
        case 'date_picker': {
          return (
            <Grid item sm={12} xs={12} md={6} lg={6}>
              <FormControl className={clsx(classes.formControlDatePicker)}>
                <KeyboardDatePicker
                  className={classes.formControlInput}
                  helperText={
                    editFieldsInitial ? dealsData?.invalid_service_date : ''
                  }
                  error={editFieldsInitial && dealsData?.invalid_service_date}
                  inputProps={{
                    autoComplete: 'treatment-date',
                  }}
                  fullWidth
                  openTo="year"
                  label="Treatment Date (MM/DD/YYYY)"
                  format="MM/dd/yyyy"
                  value={
                    dealsData?.service_date
                        ? dealsData?.service_date.replace('-', '/')
                        : null
                  }
                  disablePast
                  disabled={
                    sectionName.includes('Information')
                      ? editFieldsInitial === true
                        ? !item.editable
                        : true
                      : item.fieldName === 'email'
                      ? disableInviteInput
                      : sectionName === 'Loan Contract'
                      ? !enableLoanContract ||
                        showProjectSection ||
                        editFieldsInitial
                      : false
                  }
                  onError={(e) => {
                    if (
                      e === 'Date should not be before minimal date' &&
                      dealsData?.invalid_service_date !==
                        'Date cannot be set in the past'
                    ) {
                      updateData({
                        name: 'invalid_service_date',
                        value: 'Date cannot be set in the past',
                      });
                    }
                  }}
                  onChange={(date, text) => {
                    if (
                      date?.toDateString() === 'Invalid Date' ||
                      date?.toDateString() ===
                        'Date should not be before minimal date'
                    ) {
                      updateData({
                        name: 'invalid_service_date',
                        value: date?.toDateString(),
                      });
                    } else {
                      if (dealsData?.invalid_service_date) {
                        updateData({
                          name: 'invalid_service_date',
                          value: '',
                        });
                      }
                      changeFieldValue(item.fieldName, text);
                      // updateData({
                      //   name: 'service_date',
                      //   value: text,
                      // })
                    }
                    changeFieldValue(item.fieldName, text);
                  }}
                  inputVariant="outlined"
                />
              </FormControl>
            </Grid>
          );
        }
        case 'coapplicant_email': {
          return (
            <Grid item xs={item.size}>
              <TextField
                id={item.title}
                className={classes.formControlInput}
                label={item.title}
                error={coAppEmailError}
                helperText={coAppEmailError ? 'Invalid Email' : ''}
                value={dealsData[item.fieldName]}
                style={{
                  width: '100%',
                  marginBottom: '0px',
                }}
                onChange={(e) => {
                  changeFieldValue(item.fieldName, e.target.value);
                }}
                disabled={disableCoAppInput}
                variant="outlined"
                autoComplete="off"
              />
              {item.fieldName === 'coapplicant_email' &&
                !sectionName.includes('Information') &&
                disableCoAppInput &&
                !inviteSent && (
                  <button
                    onClick={() => {
                      setDisableCoAppInput(false);
                    }}
                    type="button"
                    className={classes.transparentButtonInvite}
                  >
                    <span className={classes.changeContactText}>
                      Need to update email?
                    </span>
                  </button>
                )}
            </Grid>
          );
        }
        case 'stateDropDown': {
          return (
            <Grid item xs={item.size}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={
                  item.editable && !dealsData[item.fieldName] ? true : false
                }
                autoComplete="off"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  {item.title}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  autoComplete="off"
                  key={dealsData[item.fieldName]}
                  value={dealsData[item.fieldName]}
                  variant="outlined"
                  onChange={(e) => {
                    changeFieldValue(item.fieldName, e.target.value);
                  }}
                  disabled={editFieldsInitial === true ? !item.editable : true}
                >
                  {map(
                    (state) => (
                      <MenuItem key={state.code} value={state.code}>
                        {state.code}
                      </MenuItem>
                    ),
                    states || [],
                  )}
                </Select>
                {item.editable && !dealsData[item.fieldName] ? (
                  <FormHelperText>This Field is Required</FormHelperText>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          );
        }
        case 'buttonLink': {
          return (
            <>
              {editFieldsInitial && (
                // <Grid container>
                //   <Grid item xs={false} md={2} lg={2} />
                <Grid item xs={12} md={12} lg={12}>
                  <button
                    onClick={() => {
                      setOpenEditInfoDialog(true);
                    }}
                    type="button"
                    style={{
                      marginLeft: '30%',
                    }}
                    className={classes.transparentButton}
                  >
                    {item.title}
                  </button>
                </Grid>
                //   <Grid item xs={2} md={2} lg={2}></Grid>
                // </Grid>
              )}
            </>
          );
        }
        case 'dropdown': {
          return (
            <Grid item xs={6}>
              {' '}
              {item?.heading === 'Sort Deals' ? (
                <Typography
                  style={{
                    marginTop: '10px',
                  }}
                  gutterBottom
                >
                  {item.heading}
                </Typography>
              ) : (
                ''
              )}
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={
                  item.editable &&
                  !dealsData[item.fieldName] &&
                  sectionName !== 'Loan Contract'
                    ? true
                    : loanContractValidation &&
                      !dealsData[item.fieldName] &&
                      item.fieldName !== 'signatureMethod' &&
                      item.editable
                }
                autoComplete="off"
              >
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  style={{
                    backgroundColor: 'white',
                  }}
                >
                  {item.title}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  autoComplete="off"
                  value={
                    !dealsData[item.fieldName]
                      ? item.defaultValue
                      : dealsData[item.fieldName]
                  }
                  key={
                    !dealsData[item.fieldName]
                      ? item.defaultValue
                      : dealsData[item.fieldName]
                  }
                  disabled={
                    item.fieldName === 'signatureMethod' ||
                    item.fieldName === 'project_type'
                      ? !enableLoanContract ||
                        showProjectSection ||
                        editFieldsInitial
                      : editFieldsInitial === true
                      ? !item.editable
                      : true
                  }
                  variant="outlined"
                  onChange={(e) => {
                    changeFieldValue(item.fieldName, e.target.value);
                  }}
                >
                  {map(
                    (state, index) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ),
                    item.dropDownValues || getDropDownValues(item.fieldName),
                  )}
                </Select>
                {item.editable &&
                !dealsData[item.fieldName] &&
                sectionName.includes('Information') ? (
                  <FormHelperText>This Field is Required</FormHelperText>
                ) : loanContractValidation &&
                  !dealsData[item.fieldName] &&
                  item.fieldName !== 'signatureMethod' &&
                  item.editable ? (
                  <FormHelperText>This Field is Required</FormHelperText>
                ) : (
                  ''
                )}
                {item.fieldName === 'signatureMethod' ? (
                  <Grid container>
                    <>
                      {!(
                        dealsData.unit_description && dealsData.project_type
                      ) ? (
                        <Grid item>
                          <button
                            onClick={() => {
                              setOpen(true);
                            }}
                            type="button"
                            // style={{
                            //   marginLeft: '20%',
                            // }}
                            className={classes.transparentButton}
                          >
                            <span className={classes.changeContactText}>
                              Why can't I send the loan contract?
                            </span>
                          </button>
                        </Grid>
                      ) : (
                        ''
                      )}
                      {/* <Grid item xs={12} md={10} lg={6}></Grid> */}
                    </>
                  </Grid>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          );
        }
        case 'autoComplete': {
          return (
            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                error={!dealsData[item.fieldName]}
                autoComplete="off"
              >
                <Autocomplete
                  options={states}
                  autoHighlight
                  autoSelect
                  getOptionSelected={(option, value) => {
                    option?.code === value;
                  }}
                  disabled={editFieldsInitial === true ? !item.editable : true}
                  inputValue={
                    dealsData[item.fieldName] ? dealsData[item.fieldName] : ''
                  }
                  onInputChange={(e, newValue) => {
                    let value = newValue;
                    if (!value) {
                      value =
                        e === null && dealsData[item.fieldName]
                          ? dealsData[item.fieldName]
                          : e.target.value;
                    }
                    changeFieldValue(item.fieldName, value);
                  }}
                  onChange={(e, newValue) => {
                    changeFieldValue(
                      item.fieldName,
                      newValue ? newValue.code : '',
                    );
                  }}
                  getOptionLabel={(option) => {
                    return option?.code === undefined ? '' : option?.code;
                  }}
                  renderOption={(option) => <>{option.code}</>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      label={item.title}
                      placeholder={item.title}
                      variant="outlined"
                      size="large"
                      error={!dealsData[item.fieldName]}
                      helperText={
                        !dealsData[item.fieldName]
                          ? 'This Field is Required'
                          : ''
                      }
                    />
                  )}
                />
              </FormControl>
            </Grid>
          );
        }
        case 'button': {
          return (
            <Grid item xs={6}>
              <Button
                variant={
                  sectionName === 'Documentation' ? 'outlined' : 'contained'
                }
                color={sectionName !== 'Documentation' ? 'primary' : 'inherit'}
                type="button"
                onClick={(e) => {
                  buttonActions(item.title);
                }}
                disabled={
                  item.title === 'Send Contract'
                    ? !enableLoanContract ||
                      showProjectSection ||
                      !(dealsData.unit_description && dealsData.project_type) ||
                      editFieldsInitial
                    : inviteSent || emailError
                }
                className={
                  item.class
                    ? classes[item.class]
                    : clsx(classes.actionButton, classes.archiveButton)
                }
              >
                {sectionName !== 'Documentation' ? (
                  <Text textSize="md" textColor="white" bold>
                    {!disableInviteInput && item.title === 'Re-send Invite'
                      ? 'Update and Send Invite'
                      : item.title === 'Send Contract' && dealsData?.loanDetails
                      ? 'Resend Contract'
                      : item.title}
                  </Text>
                ) : (
                  item.title
                )}
              </Button>
              {item.title === 'Driver License Upload' ? <div></div> : ''}{' '}
            </Grid>
          );
        }
        case 'updateButton': {
          return (
            <>
              {editFieldsInitial ? (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={(e) => {
                      updateApplicationFields();
                    }}
                    disabled={dealsData?.invalid_service_date || disableUpdate}
                    className={
                      addCancelButton
                        ? classes.updateButton
                        : classes.updateButtonWithCancel
                    }
                  >
                    <Text textSize="md" textColor="white" bold>
                      {item.title}
                    </Text>
                  </Button>
                </Grid>
              ) : (
                <>
                  {!dealsData?.loanDetails && (
                    <Grid container>
                      <Grid item xs={12} md={10} lg={7}>
                        <button
                          onClick={() => {
                            updateData({
                              name: 'invalid_service_date',
                              value: '',
                            });
                            setEditFieldsInitial(true);
                            setAddCancelButton(true);
                          }}
                          type="button"
                          style={{
                            marginLeft: '2%',
                          }}
                          className={classes.transparentButton}
                        >
                          <span className={classes.changeContactText}>
                            Need to update the application?
                          </span>
                        </button>
                      </Grid>
                      <Grid item xs={false} md={1} lg={2}></Grid>
                      <Grid item xs={12} md={10} lg={6}></Grid>
                    </Grid>
                  )}
                </>
              )}
            </>
          );
        }
        case 'cancelButton': {
          return (
            <>
              {addCancelButton && editFieldsInitial && (
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.containedButton}
                    type="button"
                    disabled={disableUpdate}
                    onClick={(e) => {
                      updateData({
                        name: 'invalid_service_date',
                        value: '',
                      });
                      fetchAppData(true);
                      setEditFieldsInitial(false);
                    }}
                  >
                    <Text textSize="md" textColor="white" bold>
                      {item.title}
                    </Text>
                  </Button>
                </Grid>
              )}
            </>
          );
        }
        case 'coAppButton': {
          return (
            <Grid item xs={6}>
              {!disableCoAppInput && (
                <Button
                  variant={
                    sectionName === 'Documentation' ? 'outlined' : 'contained'
                  }
                  color={
                    sectionName !== 'Documentation' ? 'primary' : 'inherit'
                  }
                  type="button"
                  onClick={(e) => {
                    updateCoAppEmail();
                  }}
                  disabled={coAppEmailError}
                  className={
                    item.class
                      ? classes[item.class]
                      : clsx(classes.actionButton, classes.archiveButton)
                  }
                >
                  {sectionName !== 'Documentation' ? (
                    <Text textSize="md" textColor="white" bold>
                      {inviteSent && item.title === 'Re-send Invite'
                        ? 'Invite Sent'
                        : item.title}
                    </Text>
                  ) : (
                    item.title
                  )}
                </Button>
              )}
              {item.title === 'Driver License Upload' ? <div></div> : ''}{' '}
            </Grid>
          );
        }
        case 'uploadInput': {
          return (
            <>
              {showLoader && (
                <Box
                  p={6}
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  style={{
                    paddingLeft: '20%',
                  }}
                >
                  <PulseLoader
                    color={theme.palette.primary.light}
                    size="1rem"
                  />
                  <Text textSize="sm+" semibold textColor="secondary">
                    Please wait while the documents are getting uploaded...
                  </Text>
                </Box>
              )}
              {/* {!showLoader && (
                <FileList
                  documents={appData?.data.attributes.documents}
                  handleUploadDocument={handleUploadDocument}
                  isAppCanceled={isAppCanceled}
                  newFlow
                  canEdit
                />
              )} */}
            </>
          );
        }
        case 'text': {
          return (
            <ImportantInfoCard
              title={item.title}
              message={item.description}
            ></ImportantInfoCard>
          );
        }
        case 'checkBox': {
          return (
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={<Checkbox color="primary" />}
                label={item.title}
                labelPlacement="end"
                autoComplete="off"
              />
            </FormGroup>
          );
        }
        default:
          break;
      }
      return '';
    });
  };

  return (
    <>
      {
        <div>
          {appData && (
            <>
              {/* Heading */}

              <Box style={{ marginBottom: 0, padding: 0 }}>
                <p>
                  <IconButton
                    style={{ margin: 0, padding: 0 }}
                    onClick={() => {
                      goBackToReviewPage();
                      // if (dealsData?.loanDetails) {
                      //   setChangeSelectedDeal(true);
                      // } else {
                      //   goBackToReviewPage();
                      // }
                    }}
                  >
                    <ChevronLeftIcon fontSize="large" />
                  </IconButton>
                  <span>Application {appData?.data?.attributes?.pp_id}</span>
                  <StatusButton
                    status={appData?.data?.attributes?.status}
                    draftStatus={appData?.data?.attributes?.draft_status}
                  />
                  <br></br>
                  <IconButton
                    style={{
                      margin: 0,
                      padding: 0,
                      paddingLeft: '38px',
                      boxShadow: 'none',
                      backgroundColor: 'white',
                      textDecoration: 'underline',
                    }}
                    color="primary"
                    onClick={() => {
                      goBackToReviewPage();
                    }}
                  >
                    <Text
                      style={{
                        boxShadow: 'none',
                        backgroundColor: 'white',
                        fontSize: 'small',
                        marginTop: 0,
                      }}
                    >
                      Update Deal Selection
                    </Text>
                  </IconButton>
                </p>
              </Box>
            </>
          )}
        </div>
      }
      {Object.keys(selectedDeal).length > 0 && getSelectedDealFields()}
      {appData &&
        sections.map((section) => {
          if (
            section.key === 'coapplicant_information' &&
            !appData.data.meta.has_coapplicant
          ) {
            return;
          }
          if (editFieldsInitial && !section.name.includes('Information')) {
            return '';
          }
          if (
            section.key === 'treatment_information' &&
            appData.data.attributes.loan_product_code !== 'healthcare'
          ) {
            return '';
          }
          if (section.key === 'user_account') {
            return (
              <>
                <Box pt={6} pb={2}>
                  <StepTitle>{section.heading}</StepTitle>
                  <StepSubtitle>{section.subHeading}</StepSubtitle>
                </Box>
                <Box>
                  <StepSubtitle>
                    Current applicant email is:{' '}
                    <strong>{dealsData?.email}</strong>
                  </StepSubtitle>
                  <StepSubtitle>
                    Please have applicant contact PowerPay to update email
                    address information.
                  </StepSubtitle>
                </Box>
              </>
            );
          }
          return (
            <>
              {section.key !== 'update_info' && (
                <Box
                  pt={
                    [
                      'applicant_information',
                      'coapplicant_information',
                      'property_information',
                    ].includes(section.key)
                      ? 2
                      : 6
                  }
                  pb={3}
                >
                  <StepTitle>{section.heading}</StepTitle>

                  {/* The subHeading are static strings set above - not user input */}
                  <StepSubtitle>
                    <span
                      dangerouslySetInnerHTML={{ __html: section.subHeading }}
                    />
                  </StepSubtitle>
                </Box>
              )}
              <Grid container spacing={3} rowSpacing={3}>
                {fetchedData &&
                  getApplicationField(
                    finalFieldsSection[section.name],
                    section.name,
                  )}
              </Grid>
            </>
          );
        })}
      {dealsData?.loanDetails?.attributes?.signature_method ===
        getSignatureMethod() &&
        dealsData?.signatureMethod &&
        dealsData?.signatureMethod !== 'Email' && (
          <Documents
            applicationNumber={appId}
            documents={appData?.data?.attributes?.documents}
            signatureMethod={getSignatureMethod()}
            newFlow
            signedApplicantNew={
              dealsData?.signedApplicant !== undefined
                ? dealsData.signedApplicant
                : appData?.data.attributes.signed_applicant
            }
            signedCoapplicantNew={
              dealsData?.signedCoApplicant !== undefined
                ? dealsData?.signedCoApplicant
                : appData?.data.attributes.signed_coapplicant
            }
            isAppCanceled={isAppCanceled}
            loanId={dealsData.loanDetails.id}
            projectStatus={dealsData.loanDetails.attributes.status}
            processingLoanDocs={processingLoanDocs}
          ></Documents>
        )}
      {appData && (
        <>
          <>
            {showProjectSection && (
              <>
                <Box pt={6} pb={6}>
                  <StepTitle>
                    {['solar_esg', 'home_improvement', 'esg_hi'].includes(
                      appData?.data?.attributes?.loan_product_code,
                    )
                      ? 'Start Project'
                      : 'Fund Loan'}
                  </StepTitle>
                  <StepSubtitle>
                    Please click{' '}
                    {['solar_esg', 'home_improvement', 'esg_hi'].includes(
                      appData?.data?.attributes?.loan_product_code,
                    )
                      ? 'Start Project'
                      : 'Fund Loan'}{' '}
                    to continue!
                  </StepSubtitle>
                </Box>

                <Grid container>
                  <Grid item xs={false} md={1} lg={3} />
                  <Grid item xs={12} md={10} lg={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.createLoanButton}
                      disabled={
                        disableButton || !user.data.meta.canManageResources
                      }
                      onClick={handleStartProject}
                    >
                      <Text textSize="md" textColor="white" bold>
                        {['solar_esg', 'home_improvement', 'esg_hi'].includes(
                          appData?.data?.attributes?.loan_product_code,
                        )
                          ? 'START PROJECT'
                          : 'FUND LOAN'}
                      </Text>
                    </Button>
                  </Grid>
                  <Grid item xs={false} md={1} lg={3} />
                </Grid>
              </>
            )}
          </>
          {showProjectSection && disableButton && (
            <Grid container>
              <Grid item xs={false} md={1} lg={3} />
              <Grid item xs={12} md={10} lg={6}>
                <button
                  onClick={() => {
                    handleClickOpen(true);
                  }}
                  type="button"
                  style={{
                    marginLeft: '20%',
                  }}
                  className={classes.transparentButton}
                >
                  <span className={classes.changeContactText}>
                    Why can’t I fund my loan?
                  </span>
                </button>
              </Grid>
              <Grid item xs={12} md={10} lg={6}></Grid>
            </Grid>
          )}
          <ConfirmationDialog
            desc="Attention :"
            subDesc="Loan docs have already been generated for this application. Do you want to proceed with new loan documentation?"
            dialogState={loanContractSentDialog}
            handleClose={() => setLoanContractSentDialog(false)}
            handleCancel={() => sendLoanContract(selectedDeal)}
          />
          <ConfirmationDialog
            desc="Attention :"
            subDesc="The loan contract has been generated. To change the deal please contact PowerPay at 800-397-4485."
            dialogState={changeSelectedDeal}
            handleClose={() => setChangeSelectedDeal(false)}
            infoPopup
          />
          <ConfirmationDialog
            desc="Information :"
            subDesc="Some fields are not editable because they would change the approval status of the application.  Please contact Power Pay at 800-397-4485 for assistance updating those fields."
            dialogState={openEditInfoDialog}
            handleClose={() => setOpenEditInfoDialog(false)}
            infoPopup
          />
          <ConfirmationDialog
            desc="Information :"
            subDesc={loanContractPreRequisite}
            dialogState={open}
            handleClose={() => setOpen(false)}
            infoPopup
          />
          <ConfirmationDialog
            desc="Information :"
            subDesc={startProjectPreRequisite}
            dialogState={openProjectDialog}
            handleClose={() => setOpenProjectDialog(false)}
            infoPopup
          />
          <ConfirmationDialog
            desc="Attention :"
            subDesc="The application is in “Conditional Approval” status. You will not be able to progress the project until the application is “Approved”. Do you want to proceed ?"
            dialogState={openConirmationDialog}
            handleClose={() => setOpenConirmationDialog(false)}
            handleCancel={() => sendLoanContract(selectedDeal)}
          />{' '}
        </>
      )}
    </>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    appData: state.appReducer.appData,
  };
};
export default connect(mapStatesToProps, { updateData })(StartProject);
