/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Text from 'components/TextFields/Text';
import { map } from 'ramda';
import { format } from 'date-fns';
import ReCAPTCHA from 'react-google-recaptcha';
import clsx from 'clsx';
import PulseLoader from 'react-spinners/PulseLoader';
import to from 'await-to-js';
import { updateData } from 'redux/actions';
import { useAddressLocationsContext } from 'services/contexts/useAddressLocations';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useAlertContext } from 'components/AlertContext';
import {
  applicationFields,
  coApplicantFields,
  propertyFields,
} from './InputField';
import { protectedGet, protectedPost } from '../../services/http/index';
import ZeroBounceSDK from '@zerobounce/zero-bounce-sdk';

import {
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
  Typography,
  Link,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Autocomplete } from '@material-ui/lab';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PromoCodeSection from 'pages/NewApplicationDealer/PromoCodeSection';
import { StepHeader } from 'pages/NewApplication/StepHeader';
import LoanProduct from 'pages/NewApplicationDealer/LoanProduct';
import MerchantReferenceSection from 'pages/NewApplicationDealer/MerchantReferenceSection';
import TreatmentSection from 'pages/NewApplicationDealer/TreatmentSection';
import AddressAutocomplete from 'pages/NewApplicationDealer/AddressAutocomplete';
import { castFormattedCurrencyStringToNumber, formatRawSSN } from "pages/NewApplicationDealer/CustomizedMUIInputs";

// TODO: Make these standalone components
export const StepTitle = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: '1.25rem',
    fontWeight: 600,
    letterSpacing: '0.6px',
    lineHeight: '1.75rem',
  },
}))(Typography);

export const StepSubtitle = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[700],
    fontSize: '0.875rem',
    letterSpacing: '0.65px',
    lineHeight: '1.125rem',
  },
}))(Typography);

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formControlDatePicker: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formTitle: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 25,
      marginTop: 25,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
  container: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  addressField: {
    width: '74%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  projectAmount: {
    width: '23%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  root: {
    padding: '80px',
    flexGrow: 1,
  },
  card: {
    position: 'relative',
    paddingBottom: '3rem',
  },
  buttonStyle: {
    backgroundColor: 'white',
  },
  actionButton: {
    height: 40,
  },
  formControl: {
    minWidth: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  transparentButton: {
    background: 'none',
    border: 'none',
    marginTop: '0.5rem',
    color: '#f79d3c',
    fontWeight: 500,
    cursor: 'pointer',
  },
}));

// This is the Devise email regex pattern, converted into javascript. Since we use Devise on backend to validate emails
// it's probably a good idea to use a similar pattern here
export const emailRegex = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;

const zeroBounce = new ZeroBounceSDK();
zeroBounce.init(process.env.REACT_APP_ZEROBOUNCE_API_KEY);

const fieldsToReset = [
  'name',
  'coapplicant_name',
  'coapplicant',
  'invalidServiceDate',
  'loanDetails',
  'serviceDate',
  'promo_code',
  'coapplicant_driver_license_number',
  'driver_license_number',
  'coapplicant_active_military',
  'active_military',
  'last_name',
  'coapplicant_last_name',
  'email',
  'coapplicant_email',
  'coapplicant_ssn',
  'coapplicant_ssn_confirmation',
  'coapplicant_annual_income',
  'birthday',
  'birthday_confirmation',
  'ssn',
  'ssn_confirmation',
  'annual_income',
  'other_household_income',
  'request_amount',
  'city',
  'state',
  'employer_name',
  'employer_zip',
  'zip_code',
  'street_address',
  'signatureMethod',
  'occupation',
  'employment_type',
  'coapplicant_employer_name',
  'coapplicant_employer_zip',
  'phone_number',
  'coapplicant_phone_number',
  'coapplicant_birthday',
  'coapplicant_birthday_confirmation',
  'coapplicant_occupation',
  'dealer_code',
  'coapplicant_employment_type',
  'years_employment',
  'ith_dealer_email',
  'ith_dealer_phone',
  'coapplicant_years_employment',
  'merchant_ref_id',
];

const dateFieldConfirmationMap = {
  'birthday': 'birthday_confirmation',
  'coapplicant_birthday': 'coapplicant_birthday_confirmation',
  'birthday_confirmation': 'birthday',
  'coapplicant_birthday_confirmation': 'coapplicant_birthday',
};

const ssnFieldConfirmationMap = {
  'ssn': 'ssn_confirmation',
  'coapplicant_ssn': 'coapplicant_ssn_confirmation',
  'ssn_confirmation': 'ssn',
  'coapplicant_ssn_confirmation': 'coapplicant_ssn',
}

const initialSSN = {ssn: '', coapplicant_ssn: '', ssn_confirmation: '', coapplicant_ssn_confirmation: ''};

const ConsumerForm = ({ dealsData, selectedLoanProduct, updateData }) => {
  const { states } = useAddressLocationsContext();
  const { setErrorAlertMessage, setWarningAlertMessage } = useAlertContext();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const { dealerCode, dealerMemberId } = useParams();

  const [invalidRepEmail, setInvalidRepEmail] = useState(false);
  const [inHomeRep, setInHomeRep] = useState(false);
  const [captchaInvalid, setCaptchaInvalid] = useState(true);
  const [invalidDOB, setInvalidDOB] = useState(false);
  const [invalidCoAppDOB, setInvalidCoAppDOB] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isCoApplicant, setIsCoApplicant] = useState();
  const [zeroBounceEnabled, setZeroBounceEnabled] = useState();
  const [errorFields, setErrorFields] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [primaryId, setPrimaryId] = useState();
  const [formError, setFormError] = useState(true);
  const [retiredApplicant, setRetiredApplicant] = useState(false);
  const [retiredCoApplicant, setRetiredCoApplicant] = useState(false);
  const [enableStageFunding, setEnableStageFunding] = useState(false);
  const [maximumRequestAmount, setMaximumRequestAmount] = useState();
  const [minimumIncomeAmount, setMinimumIncomeAmount] = useState(0);
  const [incomes, setIncomes] = useState({
    annual_income: '',
    other_household_income: '',
    coapplicant_annual_income: '',
  });
  const [defaultDealerCode, setDefaultDealerCode] = useState(null); // variable name matters, even if i'd love to rename dealerCode to avoid the confusion of all the times .dealerCode or function(dealerCode){} is used...
  const [defaultDealerName, setDefaultDealerName] = useState(null);
  const [defaultDealerLoanProductId, setDefaultDealerLoanProductId] = useState(
    null,
  );
  const [isValidDealer, setIsValidDealer] = useState(false);
  const [zeroBounceValidations, setZeroBounceValidations] = useState([
    {
      emailFieldId: 'applicant-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
    {
      emailFieldId: 'coapplicant-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
    {
      emailFieldId: 'merchant-representative-email',
      email: null,
      isValidEmail: null,
      response: null,
    },
  ]);
  const [unmaskedSSN, setUnmaskedSSN] = useState(initialSSN);
  const [validSSN, setValidSSN] = useState(initialSSN);


  /**
   * @description checks all emails in the zeroBounceValidations state array. If validation.isValidEmail is null, it asks ZeroBounce. if it's true or false, it doesn't.
   * @returns true if we should stop the execution of the submit, false if we can continue
   * Explanation of the logic:
   *  We must check if any emails the user inputs are valid or not using ZeroBounce
   *  However: the user must be able to submit regardless of what ZeroBounce responds.
   *  ZeroBounce is here just to prompt the user to check for grammar.
   *
   *  So, here's how it works:
   *  We store an array of zeroBounceValidations, with isValidEmail set to either true, false, or null
   *  If it's null, it means we have NOT yet asked zeroBounce to validate this email
   *  If it's either true or false, it means we did ask zerobounce.
   *
   *  ZeroBounce's validations ONLY happen when an isValidEmail is set to null
   */
  async function validateEmailsWithZerobounce() {
    /* 
        ! warning, here be dragons 
        You might look at this piece of code and think "wow! that's a lot of code repetition! lets DRY it!"
  
        Trust me, I tried. Either I do not understand how Promise.all() works, or you wont be able to use that. 
        It appears that calling set functions (like setZeroBounceValidations) inside a .then() statement WILL set the state to undefined. 
        You can slap as many awaits as you want.
        The state being undefined in this case will break things because we use zeroBounceValidations.find()
  
        Overall, while a bit repetitious, this solution works and it's easy to understand.
        If you want to improve it: by all means, go ahead, just make sure it's understandable
      */
    try {
      // even if there's an error, we must allow submittal. Idk how we'll handle metadata about Zerobounce in that case, but we'll see about it later
      const applicantValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'applicant-email',
      );
      const coapplicantValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'coapplicant-email',
      );
      const merchantRepresentativeValidation = zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'merchant-representative-email',
      );
      let stopSubmitExecution = false;

      if (applicantValidation.isValidEmail === null) {
        const response = await zeroBounce.validateEmail(
          applicantValidation.email,
        );
        applicantValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        applicantValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;
      }
      if (
        dealsData?.coapplicant_email &&
        coapplicantValidation.isValidEmail === null
      ) {
        const response = await zeroBounce.validateEmail(
          coapplicantValidation.email,
        );
        coapplicantValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        coapplicantValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;
      }
      if (
        dealsData?.ith_dealer_email &&
        merchantRepresentativeValidation.isValidEmail === null
      ) {
        const response = await zeroBounce.validateEmail(
          merchantRepresentativeValidation.email,
        );
        merchantRepresentativeValidation.isValidEmail =
          response.status === 'valid' ? true : false;
        merchantRepresentativeValidation.response = response;
        if (!stopSubmitExecution && response.status !== 'valid')
          if (!response.hasOwnProperty("error"))
            stopSubmitExecution = true;
      }

      setZeroBounceValidations([
        applicantValidation,
        coapplicantValidation,
        merchantRepresentativeValidation,
      ]);
      return stopSubmitExecution;
    } catch {
      console.error("There was a problem with the Zerobounce's endpoints");
      return false;
    }
  }

  /** @description change applicant email's validation status when the applicant's email changes */
  useEffect(() => {
    // i can't think of a better way of doing it, so for now it will remain as-is.

    if (!zeroBounceValidations) return;

    const newApplicantEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'applicant-email',
      ),
    );
    newApplicantEmailValidation.email = dealsData.email;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newApplicantEmailValidation.isValidEmail !== null) {
      newApplicantEmailValidation.isValidEmail = null;
      newApplicantEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'applicant-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newApplicantEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [dealsData?.email]);

  /** @description change coapplicant email's validation status when the coapplicant's email changes */
  useEffect(() => {
    // i can't think of a better way of doing it, so for now it will remain as-is.

    if (!zeroBounceValidations) return;
    const newCoapplicantEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'coapplicant-email',
      ),
    );
    newCoapplicantEmailValidation.email = dealsData.coapplicant_email
      ? dealsData.coapplicant_email
      : null;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newCoapplicantEmailValidation.isValidEmail !== null) {
      newCoapplicantEmailValidation.isValidEmail = null;
      newCoapplicantEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'coapplicant-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newCoapplicantEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [dealsData?.coapplicant_email]);

  /** @description change merchantRepresentative email's validation status when the merchantRepresentative's email changes */
  useEffect(() => {
    if (!zeroBounceValidations) return;

    // i can't think of a better way of doing it, so for now it will remain as-is.
    const newMerchantRepresentativeEmailValidation = structuredClone(
      zeroBounceValidations.find(
        (elem) => elem.emailFieldId === 'merchant-representative-email',
      ),
    );
    newMerchantRepresentativeEmailValidation.email = dealsData?.ith_dealer_email
      ? dealsData?.ith_dealer_email
      : null;

    // if the email changed, and the isValidEmail is different from null, we need to re-set it to null so that the zeroBounce validation runs again
    if (newMerchantRepresentativeEmailValidation.isValidEmail !== null) {
      newMerchantRepresentativeEmailValidation.isValidEmail = null;
      newMerchantRepresentativeEmailValidation.response = null;
    }

    const indexToReplaceAt = zeroBounceValidations.findIndex(
      (elem) => elem.emailFieldId === 'merchant-representative-email',
    );
    const modifiedZeroBounceValidations = [...zeroBounceValidations];
    modifiedZeroBounceValidations[
      indexToReplaceAt
    ] = newMerchantRepresentativeEmailValidation;

    setZeroBounceValidations(modifiedZeroBounceValidations);
  }, [dealsData?.ith_dealer_email]);

  const currentlyFocusedInput = useRef(null);
  const [newSelectionRange, setNewSelectionRange] = useState(null);

  const boxSubTitle =
    'Please complete the form below to apply or <a href="/signIn" color ="black;" >sign in</a> if you are a merchant.';
  const buttonText = 'CONTINUE';

  useEffect(async () => {
    
    // why is this here? i genuinely do not know, will try to remove it later
    updateData({
      name: 'owner',
      value: 'Yes',
    });

    async function getSettings() {
      protectedGet('/v1/settings/consumer_index').then((response) => {

        const minimumIncomeAmount = response.data.min_income_amount;
        const maxRequestAmount = response.data.max_request_amount;
        const enableStageFunding = Boolean(response.data.down_payment_stage_funding_enabled); // casting to boolean in case it's undefined in the API. 
        const defaultDealer = dealerCode || response.data.default_dealer; // if we already have a default dealer code, use that. If not, use the one from the endpoint response
        const dealerMember = dealerMemberId || null;
        const enableZeroBounce = response.data.enable_zerobounce;

        setDefaultDealerCode(defaultDealer);
        getDefaultDealerInformation(defaultDealer, dealerMember); //dealerMember should, in theory, only exist if dealerCode exists (merchant specific link rather than general apply)
        setMaximumRequestAmount(maxRequestAmount);
        setMinimumIncomeAmount(minimumIncomeAmount)
        setEnableStageFunding(enableStageFunding);
        setZeroBounceEnabled(enableZeroBounce);


      }).catch((error) =>  {
        setErrorAlertMessage(
          'There was a problem getting the settings for the form.',
        );
        history.push("/errorPage")
      })
    }

    getSettings();
  }, []);

  const getDefaultDealerInformation = async (dealer, dealerMember) => {
    let url = '';
    if (dealerMember)
      url = `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_public_code?dealer_code=${dealer}&dealer_member_id=${dealerMember}`;
    else
      url = `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_public_code?dealer_code=${dealer}&`;
    protectedGet(url)
      .then((response) => {
        updateData({
          name: 'dealerName',
          value: response.data.included.find((elem) => elem.type === 'business')
            .attributes.name,
        });
        setPrimaryId(response?.data?.data?.attributes?.primary_product_id);
        setDefaultDealerName(
          response.data.included.find((elem) => elem.type === 'business')
            .attributes.name,
        );
        setDefaultDealerLoanProductId(
          response?.data?.data?.attributes?.primary_product_id,
        );
      })
      .catch((error) => {
        console.error(error);
        history.push('/errorPage');
      });
  };

  const checkDealerValidityAndGetLoanProduct = async (dealerCode) => {
    let dealerCodeWithExtraCharacterIfNeeded;
    if (dealsData?.dealerCode) {
      dealerCodeWithExtraCharacterIfNeeded = dealsData.dealerCode;
      if (dealsData.dealerCode.length === 4) {
        dealerCodeWithExtraCharacterIfNeeded = '1' + dealerCode;
      }
    }

    let url = '';
    if (dealerMemberId)
      url = `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_public_code?dealer_code=${dealerCodeWithExtraCharacterIfNeeded}&dealer_member_id=${dealerMemberId}`;
    else
      url = `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_public_code?dealer_code=${dealerCodeWithExtraCharacterIfNeeded}&`;

    protectedGet(url)
      .then((response) => {
        updateData({
          name: 'dealerCode',
          value: dealerCodeWithExtraCharacterIfNeeded,
        });
        updateData({
          name: 'dealerName',
          value: response.data.included.find((elem) => elem.type === 'business')
            .attributes.name,
        });
        setPrimaryId(response?.data?.data?.attributes?.primary_product_id);
        setIsValidDealer(true);
      })
      .catch((error) => {
        console.error(error);
        updateData({
          name: 'dealerCode',
          value: dealerCodeWithExtraCharacterIfNeeded,
        });
        updateData({
          name: 'dealerName',
          value: defaultDealerName,
        });
        setPrimaryId(defaultDealerLoanProductId);
        setIsValidDealer(false);
      });
  };

  const getDealerInput = () => {
    return (
      <Grid
        container
        spacing={3}
        rowSpacing={3}
        style={{
          paddingBottom: '48px',
        }}
      >
        <Grid item sm={12} xs={12} md={6} lg={6}>
          <TextField
            fullWidth
            inputProps={{
              autoComplete: 'new-password',
            }}
            className={classes.formControlInput}
            label="Merchant Code"
            type="string"
            value={getFieldValue('dealerCode')}
            onChange={(e) => {
              handleFieldChange(e, 'dealerCode');
            }}
            onBlur={(e) => {
              checkDealerValidityAndGetLoanProduct(e.target.value);
              currentlyFocusedInput.current = null;
            }}
            variant="outlined"
          />
        </Grid>
      </Grid>
    );
  };

  const formSubmit = async () => {
    setShowLoader(true);

    if (zeroBounceEnabled) {
      const stopSubmitExecution = await validateEmailsWithZerobounce();
      if (stopSubmitExecution) {
        // jump to topmost highlight field
        setShowLoader(false);

        let listOfErroneousEmails = [];
        zeroBounceValidations.forEach((validation) => {
          if (validation.email !== null) {
            listOfErroneousEmails = [...listOfErroneousEmails, validation.email];
          }
        });

        setWarningAlertMessage(
          `${listOfErroneousEmails.toLocaleString()} have been found to be invalid. You can still submit the application if you'd like`,
        );
        return;
      }
    }

    const employeeType = translateEmploymentType(dealsData.employment_type);

    const formData = {
      dealer_code: isValidDealer ? dealsData?.dealerCode : defaultDealerCode,
      dealer_member_id: dealerMemberId,
      application: {
        address_attributes: {
          street_address: dealsData?.street_address,
          zip_code: dealsData?.zip_code,
          city: dealsData?.city,
          state: dealsData?.state,
          active_military: dealsData?.active_military,
        },

        owner:
          dealsData?.selectedLoanProduct?.attributes?.name === 'HealthCare'
            ? false
            : dealsData?.owner === 'Yes'
              ? true
              : false,
        driver_license_number: dealsData.driver_license_number,
        driver_license_state: dealsData.driver_license_state,
        loan_product_id: selectedLoanProduct?.id,
      },
      homeowner: {
        email: dealsData.email,
        name: dealsData.name,
        last_name: dealsData.last_name,
        phone_number: dealsData.phone_number.substring(0, 13),
        ssn: validSSN.ssn,
        birthday: dealsData.birthday
          ? format(new Date(dealsData.birthday), 'yyyy-MM-dd')
          : dealsData.birthday,
        us_citizen: true,
        active_military: dealsData.active_military === 'Yes' ? true : false,
      },
      dealer: {},
      financial_details: {
        0: {
          anual_income: dealsData.annual_income,
          other_household_income: Number(dealsData.other_household_income),
          request_amount: dealsData.request_amount,
          occupation: dealsData.occupation,
          employer_time: dealsData.employer_time,
          user_type: 'homeowner',
        },
      },
      zero_bounce: zeroBounceValidations,
    };
    if (!dealerCode) {
      formData.application.merchant_name = dealsData?.dealerName;
    }
    if (
      dealsData?.selectedLoanProduct?.attributes?.name.trim() === 'HealthCare'
    ) {
      formData.application.service_date_funding = true;
      formData.application.service_date = dealsData?.serviceDate
        ? format(new Date(dealsData.serviceDate), 'yyyy-MM-dd')
        : dealsData.serviceDate;
    }
    (formData.application.merchant_ref_id = dealsData?.merchant_ref_id),
      (formData.application.ith_dealer_email = dealsData?.ith_dealer_email
        ? dealsData?.ith_dealer_email
        : '');
    formData.application.promo_code = dealsData?.promo_code
      ? dealsData?.promo_code
      : '';
    formData.application.ith_dealer_phone = dealsData?.ith_dealer_phone
      ? dealsData?.ith_dealer_phone
      : '';
    formData.application.applicant_notifications_disabled = true;
    if (isCoApplicant) {
      formData.has_coapplicant = true;
      formData.financial_details['1'] = {
        anual_income: dealsData.coapplicant_annual_income,
        request_amount: dealsData.request_amount,
        occupation: dealsData.coapplicant_occupation,
        employer_time: dealsData.coapplicant_employer_time,

        user_type: 'coapplicant',
      };
      formData.coapplicant = {
        email: dealsData.coapplicant_email,
        name: dealsData.coapplicant_name,
        last_name: dealsData.coapplicant_last_name,
        phone_number: dealsData.coapplicant_phone_number.substring(0, 13),
        ssn: validSSN.coapplicant_ssn,
        birthday: dealsData.coapplicant_birthday
          ? format(new Date(dealsData.coapplicant_birthday), 'yyyy-MM-dd')
          : dealsData.coapplicant_birthday,
        us_citizen: true,
        active_military:
          dealsData.coapplicant_active_military === 'Yes' ? true : false,
        driver_license_state: dealsData.coapplicant_driver_license_state,
        driver_license_number: dealsData.coapplicant_driver_license_number,
      };
    }

    formData.dealer.dealer_code = isValidDealer
      ? dealsData?.dealerCode
      : defaultDealerCode;
    if (dealerMemberId) {
      formData.dealer.dealer_member_id = dealerMemberId;
    }
    formData.dealer.user_type = 'dealer';

    const url = dealerMemberId
      ? `/v1/applications/create_public?dealer_member_id=${dealerMemberId}`
      : '/v1/applications/create_public';
    if (isCoApplicant) {
      const coemployeeType = translateEmploymentType(
        dealsData.coapplicant_employment_type,
      );
      formData.financial_details[1].employment_type = coemployeeType;
      formData.financial_details[1].employer_name =
        dealsData.coapplicant_employer_name;
      formData.financial_details[1].employer_zip =
        dealsData.coapplicant_employer_zip;
    }
    formData.financial_details[0].employer_name = dealsData.employer_name;
    formData.financial_details[0].employer_zip = dealsData.employer_zip;
    formData.financial_details[0].employment_type = employeeType;

    if (enableStageFunding) {
      formData.application.project_amount_total = castFormattedCurrencyStringToNumber(dealsData?.project_amount_total);
      formData.application.down_payment_amount = castFormattedCurrencyStringToNumber(dealsData?.down_payment_amount)
    }

    const [err, res] = await to(protectedPost(url, formData));
    const finalDealerCode = isValidDealer
      ? dealsData?.dealerCode
      : defaultDealerCode;
    const finaldealerMemberId = dealerMemberId ? dealerMemberId : '';
    if (res) {
      const { data } = await protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/create_deal_set_send_invite_mail?application_id=${res?.data?.data?.id}&project_amount=${dealsData.request_amount}&dealer_code=${finalDealerCode}&dealer_member_id=${finaldealerMemberId}`,
      );
      if (data) {
        fieldsToReset.map((item) => {
          updateData({
            name: item,
            value: '',
          });
          return '';
        });

        updateData({
          name: 'message',
          value: data.data.attributes.message,
        });
        if (data.data.attributes.incomplete) {
          history.push(`/confirmApplication/${res?.data?.data?.id}`);
        } else if (data.data.attributes.declined) {
          history.push(`/applications/${res?.data?.data?.id}/declined`);
        } else if (data.data.attributes.ineligible) {
          history.push(`/applications/${res?.data?.data?.id}/ineligible`);
        } else if (data.data.attributes.exception) {
          history.push(`/applications/${res?.data?.data?.id}/exception`);
        } else if (data.data.attributes.reviewing) {
          history.push(`/applications/${res?.data?.data?.id}/reviewing`);
        } else {
          history.push(`/confirmApplication/${res?.data?.data?.id}`);
        }
      }
      // }
    }
    if (err) {
      setCaptchaInvalid(true);
      setShowLoader(false);

      setErrorAlertMessage(
        err?.response?.data?.message ||
          'Error while updating application. Please try again.',
      );
    }
    setShowLoader(false);
  };

  useEffect(() => {
    if (!isCoApplicant) {
      setIsCoApplicant(false);
    }
    fieldsToReset.map((item) => {
      updateData({
        name: item,
        value: '',
      });
      return '';
    });
  }, []);

  useEffect(() => {
    if (dealsData?.employment_type?.toUpperCase() === 'RETIRED') {
      updateData({ name: 'employer_name', value: '' });
      updateData({ name: 'employer_zip', value: '' });
      updateData({ name: 'occupation', value: '' });
      setRetiredApplicant(true);
    } else {
      setRetiredApplicant(false);
    }
    if (dealsData?.coapplicant_employment_type?.toUpperCase() === 'RETIRED') {
      updateData({ name: 'coapplicant_employer_name', value: '' });
      updateData({ name: 'coapplicant_employer_zip', value: '' });
      updateData({ name: 'coapplicant_occupation', value: '' });
      setRetiredCoApplicant(true);
    } else {
      setRetiredCoApplicant(false);
    }
  }, [dealsData.employment_type, dealsData.coapplicant_employment_type]);

  useEffect(() => {
    /* this useEffect controls the formError variable to disable the submit */
    let isFormWithErrors = false;

    applicationFields.forEach((field) => {
      if (isFormWithErrors) return;
      if (field.type === 'text' || field.fieldName === 'dealerName') {
        return;
      }
      if (
        retiredApplicant &&
        (field.fieldName.includes('employer_') ||
          field.fieldName === 'occupation')
      ) {
        return;
      }
      if (field.fieldName === 'other_household_income') {
        return;
      }
      if (!dealsData[field.fieldName]) {
        console.warn(
          "Can't submit form: no application information for field ",
          field.fieldName,
        );
        isFormWithErrors = true;
        return;
      }
      if (errorFields.includes(field.fieldName)) {
        console.warn(
          "Can't submit form: invalid information for field ",
          field.fieldName,
        );
        isFormWithErrors = true;
        return;
      }
    });
    propertyFields.forEach((field) => {
      if (isFormWithErrors) return;
      if (!dealsData[field.fieldName]) {
        console.warn(
          "Can't submit form: no property information for field ",
          field.fieldName,
        );
        isFormWithErrors = true;
        return;
      }
      if (errorFields.includes(field.fieldName)) {
        console.warn(
          "Can't submit form: invalid information for field ",
          field.fieldName,
        );
        isFormWithErrors = true;
        return;
      }
    });
    if (isCoApplicant) {
      coApplicantFields.forEach((field) => {
        if (isFormWithErrors) return;
        if (field.type === 'text') {
          return;
        }
        if (
          retiredCoApplicant &&
          (field.fieldName.includes('employer_') ||
            field.fieldName === 'coapplicant_occupation')
        ) {
          return;
        }
        if (!dealsData[field.fieldName]) {
          console.warn(
            "Can't submit form: no coapplicant information for field ",
            field.fieldName,
          );
          isFormWithErrors = true;
          return;
        }
        if (errorFields.includes(field.fieldName)) {
          console.warn(
            "Can't submit form: invalid information for field ",
            field.fieldName,
          );
          isFormWithErrors = true;
          return;
        }
      });
    }
    if ((invalidDOB || invalidRepEmail) && !isFormWithErrors) {
      console.warn('Cant submit form: invalid email or date of birth');
      isFormWithErrors = true;
    }

    if (inHomeRep && !isFormWithErrors) {
      if (
        !dealsData?.ith_dealer_email ||
        errorFields.includes('ith_dealer_email')
      ) {
        console.warn(
          'Cant submit form: Incomplete or wrong information for in-the-home merchant representative (email)',
        );
        isFormWithErrors = true;
      }
      if (
        !dealsData?.ith_dealer_phone ||
        errorFields.includes('ith_dealer_phone')
      ) {
        console.warn(
          'Cant submit form: Incomplete or wrong information for in-the-home merchant representative (phone)',
        );
        isFormWithErrors = true;
      }
    }

    /*
      Quick explanation to answer the following question: why do we have the variable invalidServiceDate?

      tldr: the old devs didn't know what they were doing, or were scared of the Date type, so dealsData.serviceDate is NOT an actual date.
      It's just a string. So we can't really know if it's valid, because it's the text being displayed in the input, not the actual date.

      This is a problem for when the user manually types a date. I.e.:

      If the user typed: 22/22/22__, dealsData.serviceDate would be "22/22/22__", even if it'd be more useful for us to have the Date
      type so that we can then use Date.toDateString(), which would return "Invalid Date" given the aforementioned value.

      But, since serviceDate is a string, we can't rely on that. Still, it is important to check whether or not the date is invalid.
      And we cant just simply use !dealsData?.serviceDate

      Ergo, the existance of invalidServiceDate.

      What's the correct solution here? of course it'd be to delete all instances of invalidServiceDate and instead change serviceDate
      to be a Date type, but i am not going to sift through all the files in which invalidServiceDate is called at some point just to make
      sure that i didn't break something accidentally.

      I'm deleting this whole page as soon as i'm done with my rework anyways.
    */
    if (
      ['healthcare', 'solar_esg'].includes(
        dealsData?.selectedLoanProduct?.attributes?.code,
      ) &&
      !dealsData?.serviceDate &&
      !isFormWithErrors
    ) {
      console.warn('Cant submit form: required service date not set');
      isFormWithErrors = true;
    }

    /*
      We shouldn't send an invalid service date even if unrequired, we should check the invalidServiceDate separately
    */
    if (dealsData?.invalidServiceDate && !isFormWithErrors) {
      console.warn('Cant Submit Form: invalid service date');
      isFormWithErrors = true;
    }

    setFormError(isFormWithErrors);
  }, [dealsData]);

  let timeoutId = null;

  const translateEmploymentType = (employmentType) => {
    switch (employmentType) {
      case 'Employed':
        return 'employed';
      case 'Self Employed':
        return 'self_employed';
      case 'Retired':
        return 'retired';
      default:
        return employmentType;
    }
  };

  const isFieldValid = (fieldName, fieldValue) => {
    /* generic function that checks if a field is valid, returns boolean */

    /*
      The old page was implemented so that there wouldn't be any non-mandatory fields.
      That was a BRILLIANT idea, so the following bit of code is for when we inevitably had to add a non-mandatory field.

      ? may need to move this to the bottom if we still need to validate ignored fields. For now it doesn't matter.
    */

    const listOfFieldsToIgnore = ['other_household_income'];
    if (listOfFieldsToIgnore.includes(fieldName)) return true;

    if (fieldValue instanceof Date) {
      return Boolean(fieldValue.toDateString() !== 'Invalid Date');
    }

    if (
      ['employer_name', 'employer_zip', 'employer_time', 'occupation'].includes(
        fieldName,
      ) &&
      (dealsData?.employment_type?.toUpperCase() === 'RETIRED' ||
        !dealsData?.employment_type)
    ) {
      return true;
    }

    if (
      [
        'coapplicant_employer_name',
        'coapplicant_employer_zip',
        'coapplicant_employer_time',
        'coapplicant_occupation',
      ].includes(fieldName) &&
      (dealsData?.coapplicant_employment_type?.toUpperCase() === 'RETIRED' ||
        !dealsData?.coapplicant_employment_type)
    ) {
      return true;
    }

    if (
      ['email', 'coapplicant_email', 'ith_dealer_email'].includes(fieldName)
    ) {
      if (fieldName === 'ith_dealer_email' && !inHomeRep) {
        // if it's the ith_dealer_email, and there's no value put in the ith_dealer_email yet, we want to ignore it. Otherwise, check it like a normal email field.
        return true;
      }

      // if it's the ith_dealer_email, and there's no value put in the ith_dealer_phone yet, we want to ignore it so long as it's empty. Otherwise, check it like a normal email field.
      if (emailRegex.test(fieldValue)) {
        return true;
      } else {
        console.warn('Invalid Email for field ', fieldName);
        return false;
      }
    }

    if (
      ['phone_number', 'coapplicant_phone_number', 'ith_dealer_phone'].includes(
        fieldName,
      )
    ) {
      if (fieldName === 'ith_dealer_phone' && !inHomeRep) {
        // if it's the ith_dealer_phone, and there's no value put in the ith_dealer_email yet, we want to ignore it. Otherwise, check it like a normal phone field.
        return true;
      }

      // currently we should be able to delete the phoneWithFormattingPattern, but i think better to leave it there for now. Page is getting deleted soon-ish anyways
      const phoneWithFormattingPattern = /\([0-9]{3}\)[0-9]{3}\-[0-9]{4}/g;
      const rawPhonePattern = /[0-9]{10}/g;

      if (
        !(
          phoneWithFormattingPattern.test(fieldValue) ||
          rawPhonePattern.test(fieldValue)
        )
      ) {
        console.warn('Invalid Phone number for field ', fieldName);
        return false;
      }
    }

    if (['annual_income', 'coapplicant_annual_income'].includes(fieldName)) {
      if (sumAnnualIncome() < minimumIncomeAmount) {
        return false;
      }
    }
    return Boolean(fieldValue);
  };

  const dateFormatIsValid = (date) => {
    return Boolean(Date.parse(date).toString() !== 'NaN');
  }

  const dateConfirmationMatches = (fieldName) => {
    return readInputValue(fieldName) === readInputValue(dateFieldConfirmationMap[fieldName]);
  }

  const dateFieldAndConfirmationValid = (fieldName) => {
    return dateFormatIsValid(readInputValue(fieldName)) && dateConfirmationMatches(fieldName);
  }

  const ssnFormatIsValid = (fieldName) => {
    const rawValue = readInputValue(fieldName);
    if (rawValue.length < 11) return false;

    const ssnWithFormattingPattern = /^\d{3}-\d{2}-\d{4}$/g;
    const rawNumbers = concatSSNStrings(unmaskedSSN[fieldName], rawValue);
    const formattedSSN = rawNumbers.replace(/^(\d{3})(\d{2})(\d{4})$/, "$1-$2-$3");

    return ssnWithFormattingPattern.test(formattedSSN);
  }

  const ssnConfirmationMatches = (fieldName) => {
    const cleanSSN = getUnmaskedSSN(fieldName);
    const cleanConfirmation = getUnmaskedSSN(ssnFieldConfirmationMap[fieldName]);

    return cleanSSN === cleanConfirmation;
  }

  const isSameSSN = () => {
    const applicantSSN = getUnmaskedSSN('ssn');
    const coapplicantSSN = getUnmaskedSSN('coapplicant_ssn');

    if (!applicantSSN || !coapplicantSSN) return false;
    return getUnmaskedSSN('ssn') === getUnmaskedSSN('coapplicant_ssn');
  }

  const getUnmaskedSSN = (fieldName) => {
    const value = readInputValue(fieldName);
    const unmasked = unmaskedSSN[fieldName]
    return concatSSNStrings(unmasked, value)
  }

  function concatSSNStrings(str1, str2) {
    if (!str1 || !str2) return '';

    const cleanedStr2 = str2?.replace(/\D/g, '');
    return cleanedStr2.startsWith(str1) ? cleanedStr2 : str1 + cleanedStr2;
  }

  const ssnFieldAndConfirmationValid = (fieldName) => {
    return ssnFormatIsValid(fieldName) && ssnConfirmationMatches(fieldName);
  }

  const highlightDateErrors = (fieldName, fieldValue) => {
    let newErrorFields = new Set(errorFields);

    if (fieldValue === '' ) {
      newErrorFields.add(fieldName);
      newErrorFields.add(dateFieldConfirmationMap[fieldName]);
    } else if (!dateFormatIsValid(fieldValue)) {
      newErrorFields.add(fieldName);
      newErrorFields.add(dateFieldConfirmationMap[fieldName]);
    } else if (!dateConfirmationMatches(fieldName)) {
      newErrorFields.add(fieldName);
      newErrorFields.add(dateFieldConfirmationMap[fieldName]);
    } else if (dateFieldAndConfirmationValid(fieldName)) {
      newErrorFields.delete(fieldName);
      newErrorFields.delete(dateFieldConfirmationMap[fieldName]);
    }

    setErrorFields([...newErrorFields]);
  }

  const addFieldNameAndConfirmationToErrorFields = (fieldName, errorFields) => {
    errorFields.add(fieldName);
    errorFields.add(ssnFieldConfirmationMap[fieldName]);
    return errorFields;
  };

  const deleteSSNAndConfirmationFromErrorFields = (fieldName, errorFields) => {
    errorFields.delete(fieldName);
    errorFields.delete(ssnFieldConfirmationMap[fieldName]);
    return errorFields;
  };

  const handleCoapplicant = (fieldName, errorFields) => {
    return fieldName.includes('coapplicant') ?
      deleteSSNAndConfirmationFromErrorFields('ssn', errorFields) :
      deleteSSNAndConfirmationFromErrorFields('coapplicant_ssn', errorFields);
  };

  const highlightSSNErrors = (fieldName, fieldValue) => {
    let newErrorFields = new Set(errorFields);
    const key = fieldName.includes('coapplicant') ? 'coapplicant_ssn' : 'ssn';
    const value = `${unmaskedSSN[fieldName]}${getRawNumbers(fieldValue)}`;

    if (fieldValue === '' || !ssnFormatIsValid || !ssnConfirmationMatches(fieldName) || isSameSSN()) {
      newErrorFields = addFieldNameAndConfirmationToErrorFields(fieldName, newErrorFields);
    } else if (ssnFieldAndConfirmationValid(fieldName)) {
      setValidSSN((prev) => ({...prev, [key]: value}));
      newErrorFields = deleteSSNAndConfirmationFromErrorFields(fieldName, newErrorFields);
      if (!isSameSSN()) {
        newErrorFields = handleCoapplicant(fieldName, newErrorFields);
      }
    }
    setErrorFields([...newErrorFields]);
  }

  const highlightErrorFields = (fieldName, fieldValue) => {
    let newErrorFields = new Set(errorFields);

    if (isFieldValid(fieldName, fieldValue)) {
      newErrorFields.delete(fieldName);
    } else {
      newErrorFields.add(fieldName);
    }

    setErrorFields([...newErrorFields]);
  };

  const highlightApplicantsIncomeErrors = () => {
    let newErrorFields = new Set(errorFields);

    if (sumAnnualIncome() < minimumIncomeAmount) {
      newErrorFields.add('annual_income');
      newErrorFields.add('coapplicant_annual_income');
    } else {
      newErrorFields.delete('annual_income');
      newErrorFields.delete('coapplicant_annual_income');
    }

    setErrorFields([...newErrorFields]);
  }

  const changeFieldValue = (fieldName, fieldValue) => {
    // TODO Santi: delete when unused
    if (fieldName.includes('zip') && fieldValue.length > 5) {
      return;
    }
    if (
      fieldName.includes('years_employment') &&
      (fieldValue > 100 || fieldValue < 0)
    ) {
      return '';
    }

    if (fieldName.includes('ssn')) {
      if (fieldValue.length > 11) {
        return;
      }
    }

    updateData({
      name: fieldName,
      value: fieldValue,
    });
  };

  const formatRawPhoneNumber = (rawPhoneNumber, _fieldName) => {
    let aux = rawPhoneNumber;
    if (rawPhoneNumber.length > 6) {
      //  0123456789 => 012345-6789
      aux = aux.slice(0, 6).concat('-').concat(aux.slice(6));
    }
    if (rawPhoneNumber.length > 3) {
      //  012345-6789 => 012)345-6789
      aux = aux.slice(0, 3).concat(')').concat(aux.slice(3));
    }
    if (rawPhoneNumber.length > 0) {
      //  012)345-6789 => (012)345-6789
      aux = '('.concat(aux);
    }
    return aux;
  };

  const formatRawSSN = (rawSSN) => {
    let aux = rawSSN;
    if (rawSSN.length > 5) {
      aux = aux.slice(0, 5).concat('-').concat(aux.slice(5));
    }
    if (rawSSN.length > 3) {
      aux = aux.slice(0, 3).concat('-').concat(aux.slice(3));
    }
    return aux;
  };

  const formatMaskedSSN = (rawSSN) => {
    return ('XXX-XX-').concat(rawSSN.slice(5));
  };

  const getRawNumbers = (string) => {
    return string?.replace(/([^0-9])/g, '');
  };

  const getStringFieldErrorText = (field) => {
    const fieldName = typeof field === 'string' ? field : field?.fieldName;
    if (['ith_dealer_email', 'ith_dealer_phone'].includes(fieldName)) {
      if (!dealsData[fieldName]) {
        return 'This field is required';
      }
      if (errorFields.includes(fieldName)) {
        return 'Field value invalid';
      }
    }
    if (fieldName.includes('ssn')) {
      if (!ssnConfirmationMatches(fieldName)) {
        return 'SSN does not match';
      } else if (isSameSSN()) {
        return 'Applicant and Co-Applicant SSN cannot be the same';
      }
    }
    if (errorFields.includes(fieldName)) {
      if (!dealsData[fieldName]) {
        return 'This field is required';
      } else {
        return 'Field value invalid';
      }
    }
    return '';
  };

  const errorTextForDateField = (fieldName, isInvalid) => {
    const confirmationField = dateFieldConfirmationMap[fieldName];
    const fieldCurrentValue = readInputValue(fieldName);
    const confirmationFieldCurrentValue = readInputValue(confirmationField);
    const fieldHasError = errorFields.includes(fieldName);
    const fieldIsEmpty = fieldCurrentValue === '';
    const confirmationNoMatch = fieldCurrentValue !== confirmationFieldCurrentValue;

    if (fieldHasError && confirmationNoMatch) return 'Date does not match';
    if (fieldHasError && fieldIsEmpty) return 'This field is required';
    if (fieldHasError && isInvalid) return 'Invalid Date';

    return '';
  };

  const getFieldValue = (field) => {
    const fieldName = typeof field === 'string' ? field : field?.fieldName;
    if (fieldName.includes('employment_type')) {
      switch (dealsData[fieldName]) {
        case 'employed':
          return 'Employed';
        case 'self_employed':
          return 'Self Employed';
        case 'retired':
          return 'Retired';
        default:
          return dealsData[fieldName];
      }
    }

    if (!dealsData[fieldName]) return '';

    let newValue = dealsData[fieldName];

    if (
      ['phone_number', 'coapplicant_phone_number', 'ith_dealer_phone'].includes(
        fieldName,
      )
    ) {
      newValue = formatRawPhoneNumber(getRawNumbers(dealsData[fieldName]));
    }

    if (
      Boolean(currentlyFocusedInput.current) &&
      Boolean(newSelectionRange) &&
      newSelectionRange.fieldName === fieldName
    ) {
      currentlyFocusedInput.current.setSelectionRange(
        newSelectionRange.newSelectionStartValue,
        newSelectionRange.newSelectionStartValue,
      );
    }

    return newValue;
  };

  const readInputValue = (uniqueIdentifier) => {
    const inputElement = document.getElementById(`consumer-form-input-${uniqueIdentifier}`);

    return inputElement ? inputElement.value : '';
  }

  const handleSSNChange = (event, field) => {
    const newValue = event.target.value;
    if (newValue.length > 11) return;

    const fieldName = field.fieldName;
    const oldValue = dealsData[fieldName];
    const newRawValue = getRawNumbers(newValue);
    let newlyFormattedValue;

    if (newRawValue.length < 6 && !newValue.includes('X')) {
      setUnmaskedSSN((prev) => ({...prev, [fieldName]: newRawValue}));
    }

    if (newValue.length === 11) {
      newlyFormattedValue = formatMaskedSSN(newRawValue, unmaskedSSN[fieldName]);
    } else if (oldValue.length === 11 && newValue.length === 10) {
      newlyFormattedValue = formatRawSSN(unmaskedSSN[fieldName].concat(newRawValue));
    } else {
      newlyFormattedValue = formatRawSSN(newRawValue);
    }

    highlightSSNErrors(fieldName, newRawValue);

    updateData({
      name: fieldName,
      value: newlyFormattedValue,
    });
  }

  const handleDateChange = (date, text, field) => {
    ['birthday', 'birthday_confirmation'].includes(field)
      ? setInvalidDOB(!dateFormatIsValid(date))
      : setInvalidCoAppDOB(!dateFormatIsValid(date));

    updateData({
      name: field,
      value: text,
    });

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      highlightDateErrors(
        field,
        text,
      );
    }, 50);
  }

  const handleFieldChange = (event, field) => {
    const fieldName = typeof field === 'string' ? field : field?.fieldName;
    const newValue = event.target.value;
    let newRawValue = newValue;
    const oldValue = dealsData[fieldName];

    if (
      ['phone_number', 'coapplicant_phone_number', 'ith_dealer_phone'].includes(
        fieldName,
      )
    ) {
      newRawValue = getRawNumbers(newValue);
      if (newRawValue.length > 10) {
        return;
      }
    }

    if (fieldName.includes('zip') && newValue.length > 5) {
      return;
    }

    if (fieldName === 'dealerName') {
      return;
    } else {
      highlightErrorFields(fieldName, newRawValue);
    }

    updateData({
      name: fieldName,
      value: newRawValue,
    });

    const selectionStartIsAtTheEnd =
      event.target.selectionStart === event.target.value.length;
    const valueWillRequireFormatting = [
      'phone_number',
      'coapplicant_phone_number',
      'ith_dealer_phone',
    ].includes(fieldName);

    if (!selectionStartIsAtTheEnd && valueWillRequireFormatting) {
      const selectionStartIndex = event.target.selectionStart;
      const formattedNewValue = formatRawPhoneNumber(newRawValue, fieldName);
      const characterDifferenceBetweenRawOldAndRawNew =
        newRawValue.length - oldValue.length; // can be 1, 0, or -1
      currentlyFocusedInput.current = event.target;

      let offset = 0;
      if (characterDifferenceBetweenRawOldAndRawNew > 0) {
        // we know a character got added here
        const characterThatGotAdded = newValue[selectionStartIndex - 1];
        const whatsAtTheIndexOfTheNewlyAddedCharacterAfterFormatting =
          formattedNewValue[selectionStartIndex - 1];
        if (
          characterThatGotAdded !==
          whatsAtTheIndexOfTheNewlyAddedCharacterAfterFormatting
        ) {
          // if this is the case, it means the formatting added a character in between what the user typed and the previous number. Offset +1 to compensate
          offset = 1;
        }
      }
      setNewSelectionRange({
        fieldName,
        newSelectionStartValue: selectionStartIndex + offset,
      });
    } else {
      setNewSelectionRange(null);
    }
  };

  const getInHomeRepFields = () => {
    return (
      <>
        <Grid item sm={12} xs={12} md={6} lg={6}>
          <TextField
            fullWidth
            className={classes.formControlInput}
            helperText={getStringFieldErrorText('ith_dealer_email')}
            error={
              !dealsData['ith_dealer_email'] ||
              errorFields.includes('ith_dealer_email') ||
              invalidRepEmail
            }
            label="In the home Rep Email Address"
            type="string"
            variant="outlined"
            value={getFieldValue('ith_dealer_email')}
            onChange={(e) => {
              handleFieldChange(e, 'ith_dealer_email');
            }}
            onBlur={(e) => {
              highlightErrorFields('ith_dealer_email', e.target.value);
              currentlyFocusedInput.current = null;
            }}
          />
        </Grid>
        <Grid item sm={12} xs={12} md={6} lg={6}>
          {' '}
          <TextField
            fullWidth
            className={classes.formControlInput}
            error={
              !dealsData['ith_dealer_phone'] ||
              errorFields.includes('ith_dealer_phone') ||
              invalidRepEmail
            }
            label="In the home Rep Mobile Number"
            helperText={getStringFieldErrorText('ith_dealer_phone')}
            type="tel"
            value={getFieldValue('ith_dealer_phone')}
            onChange={(e) => {
              handleFieldChange(e, 'ith_dealer_phone');
            }}
            onBlur={(e) => {
              highlightErrorFields('ith_dealer_phone', e.target.value);
              currentlyFocusedInput.current = null;
            }}
            variant="outlined"
          />
        </Grid>

        <Box pt={1}>
          <button
            onClick={() => {
              setInHomeRep(false);
              setInvalidRepEmail(false);
              updateData({
                name: 'ith_dealer_email',
                value: '',
              });
              updateData({
                name: 'ith_dealer_phone',
                value: '',
              });
              const errorArr = errorFields;

              let index = errorArr.indexOf('ith_dealer_email');
              errorArr.splice(index, 1);
              index = errorArr.indexOf('ith_dealer_phone');
              errorArr.splice(index, 1);
              setErrorFields(errorArr);
            }}
            type="button"
            className={classes.transparentButton}
          >
            Want to remove an in-the-home merchant representative?
          </button>
        </Box>
      </>
    );
  };

  const clearCoApplicant = () => {
    setIsCoApplicant(false);
    coApplicantFields.map((item) => {
      if (errorFields.includes(item.fieldName)) {
        const errorArr = errorFields;

        const index = errorArr.indexOf(item.fieldName);
        errorArr.splice(index, 1);
        setErrorFields(errorArr);
      }
      return updateData({
        name: item.fieldName,
        value: '',
      });
    });
    // checkErrorValidation('', '');
  };

  const sumAnnualIncome = () => {
    let annualIncome = getRawNumbers(readInputValue('annual_income'));
    let coApplicantannualIncome = getRawNumbers(readInputValue('coapplicant_annual_income'));
    annualIncome = isNaN(annualIncome) ? 0 : annualIncome;
    coApplicantannualIncome = isNaN(coApplicantannualIncome) ? 0 : coApplicantannualIncome;

    return Number(coApplicantannualIncome) + Number(annualIncome);
  }

  useEffect(() => {
    if (formError) {
      setCanSubmit(false);
      return;
    }
    if (captchaInvalid) {
      setCanSubmit(false);
      return;
    }
    setCanSubmit(true);
  }, [formError, captchaInvalid]);

  return (
    <>
      {defaultDealerCode && (
        <Grid container className={classes.container}>
          <Grid item sm={12} xs={12}>
            <Card className={classes.card}>
              <CardContent>
                {/* Loader */}
                {showLoader && (
                  <Box
                    p={6}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <PulseLoader
                      color={theme.palette.primary.light}
                      size="1rem"
                    />
                    <Text textSize="sm+" semibold textColor="secondary">
                      Please wait while we Process Details...
                    </Text>
                  </Box>
                )}

                {/* Form */}
                {!showLoader && (
                  <>
                    <Box px={3}>
                      {/* Heading */}
                      <Box display="flex" alignItems="flex-start">
                        <IconButton
                          className={classes.backButton}
                          style={{ margin: 0, padding: 0 }}
                          onClick={() => history.goBack()}
                        >
                          <ChevronLeftIcon fontSize="large" />
                        </IconButton>
                        <StepHeader
                          title={`${
                            dealsData?.dealerName || defaultDealerName || ''
                          }`}
                          subtitle={boxSubTitle}
                        />
                      </Box>
                      {!dealerCode && (
                        <>
                          <Box
                            pt={6}
                            pb={3}
                            style={{
                              paddingTop: '0px',
                            }}
                          >
                            <StepTitle>Merchant Name</StepTitle>
                            <StepSubtitle>
                              Please enter merchant name and/or code.
                            </StepSubtitle>
                          </Box>

                          {getDealerInput()}
                        </>
                      )}
                      {primaryId && (
                        <LoanProduct
                          primaryId={primaryId}
                          dealerCodeRes={
                            dealsData.dealerCode || defaultDealerCode
                          }
                          changeFieldValue={changeFieldValue}
                          enableStageFunding={enableStageFunding}
                          maximumRequestAmount={maximumRequestAmount}
                          openPage
                        ></LoanProduct>
                      )}
                      {/* Applicant Information */}
                      <Box pt={3} pb={3}>
                        <StepTitle>
                          {selectedLoanProduct?.attributes
                            ?.application_applicant_heading
                            ? selectedLoanProduct?.attributes
                                ?.application_applicant_heading
                            : "Applicant's Information"}
                        </StepTitle>
                        {selectedLoanProduct?.attributes
                          ?.application_applicant_subheading ? (
                          <StepSubtitle>
                            {
                              selectedLoanProduct?.attributes
                                ?.application_applicant_subheading
                            }
                          </StepSubtitle>
                        ) : (
                          <StepSubtitle>
                            All applicants must be <strong>US citizens</strong>.
                          </StepSubtitle>
                        )}
                      </Box>

                      <Grid container spacing={3} rowSpacing={3}>
                        {applicationFields.map((inputField) => {
                          // if the applicant is retired, and the input is about their occupation or employer, then don't show the input
                          if (
                            (inputField.fieldName.includes('employer_') ||
                              inputField.fieldName === 'occupation') &&
                            (dealsData?.employment_type?.toUpperCase() ===
                              'RETIRED' ||
                              !dealsData?.employment_type ||
                              retiredApplicant)
                          ) {
                            return '';
                          }
                          const type =
                            inputField.type === 'currency'
                              ? 'number'
                              : inputField.type;

                          switch (inputField.inputType) {
                            case 'string': {
                              return (
                                <>
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    {inputField.subtitle && (
                                      <StepSubtitle
                                        style={{
                                          marginBottom: '12px',
                                        }}
                                      >
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: inputField.subtitle,
                                          }}
                                        />
                                      </StepSubtitle>
                                    )}
                                    <TextField
                                      fullWidth
                                      onFocus={(event) => {
                                        currentlyFocusedInput.current =
                                          event.target;
                                      }}
                                      error={
                                        inputField.updateField
                                          ? !dealsData[inputField.fieldName]
                                          : errorFields.includes(
                                              inputField.fieldName,
                                            )
                                            ? true
                                            : inputField.fieldName.includes('ssn') && isSameSSN()
                                      }
                                      inputProps={{
                                        autoComplete: 'new-password',

                                      }}
                                      className={classes.formControlInput}
                                      label={inputField.title}
                                      type={type}
                                      id={`consumer-form-input-${inputField.fieldName}`}
                                      value={getFieldValue(inputField)}
                                      helperText={getStringFieldErrorText(inputField)}
                                      onChange={(e) => {
                                        if(inputField.fieldName.includes('ssn')) {
                                          handleSSNChange(e, inputField) 
                                        } else {
                                          handleFieldChange(e, inputField);
                                        }
                                      }}
                                      onBlur={(e) => {
                                        if (inputField.fieldName.includes('ssn')) {
                                          highlightSSNErrors(inputField.fieldName, e.target.value);
                                        } else {
                                          highlightErrorFields(inputField.fieldName, e.target.value,);
                                        }
                                        currentlyFocusedInput.current = null;
                                      }}
                                      onPaste={(e) => {
                                        if (inputField.fieldName.includes('ssn_confirmation')) {
                                          e.preventDefault();
                                        }
                                      }}
                                      variant="outlined"
                                    />
                                  </Grid>
                                  {inputField?.pad && <Grid xs={6}></Grid>}
                                </>
                              );
                            }
                            case 'text': {
                              return (
                                <Grid item sm={12} xs={12}>
                                  <StepSubtitle>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: inputField.title,
                                      }}
                                    />
                                  </StepSubtitle>
                                </Grid>
                              );
                            }
                            case 'currency': {
                              return (
                                <Grid item sm={12} xs={12} md={6} lg={6}>
                                  <NumberFormat
                                    id={`consumer-form-input-${inputField.fieldName}`}
                                    fullWidth
                                    onFocus={(event) => {
                                      currentlyFocusedInput.current =
                                        event.target;
                                    }}
                                    inputProps={{
                                      autoComplete: 'new-password',
                                    }}
                                    error={errorFields.includes(
                                      inputField.fieldName,
                                    )}
                                    variant="outlined"
                                    helperText={
                                      (errorFields.includes(
                                        inputField.fieldName,
                                      ) &&
                                        (inputField.fieldName ===
                                          'annual_income' && sumAnnualIncome() > 0
                                          ? `Income should be greater than ${minimumIncomeAmount}`
                                          : 'This Field is Required')) ||
                                      ''
                                    }
                                    onBlur={(e) => {
                                      highlightApplicantsIncomeErrors();
                                      currentlyFocusedInput.current = null;
                                    }}
                                    className={classes.formControlInput}
                                    label={inputField.title}
                                    value={incomes[inputField.fieldName]}
                                    customInput={TextField}
                                    allowNegative={false}
                                    allowLeadingZeros={false}
                                    prefix="$"
                                    type="text"
                                    isAllowed={({ value }) => {
                                      return value === '0' ? false : true;
                                    }}
                                    thousandSeparator
                                    autoComplete="off"
                                    isNumericString
                                    onValueChange={({ value }) => {
                                      let auxValue = value;
                                      setIncomes({
                                        ...incomes,
                                        [inputField.fieldName]: auxValue,
                                      });
                                      highlightErrorFields(
                                        inputField.fieldName,
                                        auxValue,
                                      );
                                      changeFieldValue(
                                        inputField.fieldName,
                                        auxValue,
                                      );
                                    }}
                                  />
                                </Grid>
                              );
                            }
                            case 'dropdown': {
                              return (
                                <Grid item sm={12} xs={12} md={6} lg={6}>
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    helperText="This Field is Required"
                                    error={
                                      errorFields.includes(
                                        inputField.fieldName,
                                      ) && !dealsData[inputField.fieldName]
                                    }
                                  >
                                    <InputLabel
                                      id="demo-simple-select-outlined-label"
                                      style={{
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      {inputField.title}
                                    </InputLabel>
                                    <Select
                                      error={
                                        errorFields.includes(
                                          inputField.fieldName,
                                        ) && !dealsData[inputField.fieldName]
                                      }
                                      inputProps={{
                                        autoComplete: 'new-password',
                                      }}
                                      helperText="This Field is Required"
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={getFieldValue(inputField)}
                                      onBlur={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e.target.value,
                                        );
                                        currentlyFocusedInput.current = null;
                                      }}
                                      variant="outlined"
                                      onChange={(e) => {
                                        handleFieldChange(e, inputField);
                                      }}
                                    >
                                      {map(
                                        (state) => (
                                          <MenuItem key={state} value={state}>
                                            {state}
                                          </MenuItem>
                                        ),
                                        inputField.dropDownValues || [],
                                      )}
                                    </Select>
                                    {errorFields.includes(
                                      inputField.fieldName,
                                    ) && !dealsData[inputField.fieldName] ? (
                                      <FormHelperText>
                                        This Field is Required
                                      </FormHelperText>
                                    ) : (
                                      ''
                                    )}
                                  </FormControl>
                                </Grid>
                              );
                            }
                            case 'date': {
                              return (
                                <>
                                  <Grid item sm={12} xs={12} md={6} lg={6}>
                                    <FormControl
                                      className={clsx(
                                        classes.formControlDatePicker,
                                      )}
                                    >
                                      <KeyboardDatePicker
                                        id={`consumer-form-input-${inputField.fieldName}`}
                                        error={
                                          errorFields.includes(
                                            inputField.fieldName,
                                          ) || invalidDOB
                                        }
                                        inputProps={{
                                          autoComplete: 'new-password',
                                        }}
                                        fullWidth
                                        openTo="year"
                                        label={inputField.label}
                                        onPaste={(e) => {
                                          if (inputField.fieldName.includes('confirmation')) {
                                            e.preventDefault();
                                          }
                                        }}
                                        disableFuture
                                        format="MM/dd/yyyy"
                                        helperText={`${errorTextForDateField(inputField.fieldName, invalidDOB)}`}
                                        value={
                                          dealsData[inputField.fieldName]
                                            ? dealsData[inputField.fieldName]
                                            : null
                                        }
                                        onChange={(date, text) => {
                                          handleDateChange(date, text, inputField.fieldName);
                                        }}
                                        inputVariant="outlined"
                                        onBlur={(e) => {
                                          highlightDateErrors(
                                            inputField.fieldName,
                                            e.target.value,
                                          );
                                          currentlyFocusedInput.current = null;
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  {inputField?.pad && <Grid xs={6}></Grid>}
                                </>
                              );
                            }
                            case 'autoComplete': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={Number(inputField.size)}
                                  lg={Number(inputField.size)}
                                >
                                  {states && (
                                    <Autocomplete
                                      fullWidth
                                      variant="outlined"
                                      autoSelect
                                      value={dealsData[inputField.fieldName]}
                                      className={classes.formControl}
                                      autoHighlight
                                      onChange={(e, newValue) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          newValue ? newValue.code : '',
                                        );
                                        changeFieldValue(
                                          inputField.fieldName,
                                          newValue ? newValue.code : '',
                                        );
                                      }}
                                      onBlur={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e?.target?.value,
                                        );
                                        currentlyFocusedInput.current = null;
                                      }}
                                      options={states}
                                      getOptionLabel={(option) => {
                                        return option?.code === undefined
                                          ? option
                                            ? option
                                            : ''
                                          : option?.code;
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                          }}
                                          label={inputField.title}
                                          placeholder={inputField.title}
                                          variant="outlined"
                                          size="large"
                                          value={
                                            dealsData[inputField.fieldName] ||
                                            ''
                                          }
                                          onBlur={(e) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              e.target.value,
                                            );
                                            currentlyFocusedInput.current = null;
                                          }}
                                          helperText={
                                            errorFields.includes(
                                              inputField.fieldName,
                                            ) &&
                                            !dealsData[inputField.fieldName]
                                              ? 'This Field is Required'
                                              : ''
                                          }
                                          error={
                                            errorFields.includes(
                                              inputField.fieldName,
                                            ) &&
                                            !dealsData[inputField.fieldName]
                                          }
                                        />
                                      )}
                                    />
                                  )}
                                  {/* </FormControl> */}
                                </Grid>
                              );
                            }
                            default:
                              break;
                          }
                          return '';
                        })}
                      </Grid>

                      {!isCoApplicant && (
                        <Box pt={1}>
                          <button
                            onClick={() => {
                              setIsCoApplicant(true);
                              setFormError(true);
                            }}
                            type="button"
                            className={classes.transparentButton}
                          >
                            Want to add a co-applicant?
                          </button>
                        </Box>
                      )}

                      {/* Co-applicant Information */}

                      {isCoApplicant && (
                        <>
                          <Box pt={6} pb={3}>
                            <StepTitle>Co-Applicant's Information</StepTitle>
                            <StepSubtitle>
                              All applicants must be{' '}
                              <strong>US citizens</strong>.
                            </StepSubtitle>
                          </Box>

                          <Grid container spacing={3} rowSpacing={3}>
                            {coApplicantFields.map((inputField) => {
                              // if the coapplicant is retired, and the input is about their occupation or employer, then don't show the input
                              if (
                                (inputField.fieldName.includes('employer_') ||
                                  inputField.fieldName ===
                                    'coapplicant_occupation') &&
                                (dealsData?.coapplicant_employment_type?.toUpperCase() ===
                                  'RETIRED' ||
                                  !dealsData?.coapplicant_employment_type ||
                                  retiredCoApplicant)
                              ) {
                                return '';
                              }

                              const type =
                                inputField.type === 'currency'
                                  ? 'number'
                                  : inputField.type;

                              switch (inputField.inputType) {
                                case 'string': {
                                  return (
                                    <>
                                      <Grid item sm={12} xs={12} md={6} lg={6}>
                                        <TextField
                                          id={`consumer-form-input-${inputField.fieldName}`}
                                          fullWidth
                                          InputProps={{
                                            autoComplete: 'new-password',
                                          }}
                                          error={
                                            inputField.updateField &&
                                            dealsData.coApplicant
                                              ? !dealsData[inputField.fieldName]
                                              : errorFields.includes(
                                                  inputField.fieldName,
                                                )
                                                ? true
                                                : inputField.fieldName.includes('ssn') && isSameSSN()
                                          }
                                          onFocus={(event) => {
                                            currentlyFocusedInput.current =
                                              event.target;
                                          }}
                                          className={classes.formControlInput}
                                          label={inputField.title}
                                          type={type}
                                          value={getFieldValue(inputField)}
                                          helperText={getStringFieldErrorText(inputField)}
                                          onChange={(e) => {
                                            if(inputField.fieldName.includes('ssn')) {
                                              handleSSNChange(e, inputField)
                                            } else {
                                              handleFieldChange(e, inputField);
                                            }
                                          }}
                                          onBlur={(e) => {
                                            if (inputField.fieldName.includes('ssn')) {
                                              highlightSSNErrors(inputField.fieldName, e.target.value);
                                            } else {
                                              highlightErrorFields(inputField.fieldName, e.target.value,);
                                            }
                                            currentlyFocusedInput.current = null;
                                          }}
                                          variant="outlined"
                                        />
                                      </Grid>
                                      {inputField?.pad && <Grid xs={6}></Grid>}
                                    </>
                                  );
                                }
                                case 'text': {
                                  return (
                                    <Grid item sm={12} xs={12}>
                                      <StepSubtitle>
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: inputField.title,
                                          }}
                                        />
                                      </StepSubtitle>
                                    </Grid>
                                  );
                                }
                                case 'dropdown': {
                                  return (
                                    <Grid item sm={12} xs={12} md={6} lg={6}>
                                      <FormControl
                                        variant="outlined"
                                        className={classes.formControl}
                                        error={
                                          errorFields.includes(
                                            inputField.fieldName,
                                          ) || !dealsData[inputField.fieldName]
                                        }
                                      >
                                        <InputLabel
                                          id="demo-simple-select-outlined-label"
                                          style={{
                                            backgroundColor: 'white',
                                          }}
                                        >
                                          {inputField.title}
                                        </InputLabel>
                                        <Select
                                          inputProps={{
                                            autoComplete: 'new-password',
                                          }}
                                          error={
                                            errorFields.includes(
                                              inputField.fieldName,
                                            ) ||
                                            !dealsData[inputField.fieldName]
                                          }
                                          labelId="demo-simple-select-outlined-label"
                                          id="demo-simple-select-outlined"
                                          value={getFieldValue(inputField)}
                                          onBlur={(e) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              e.target.value,
                                            );
                                            currentlyFocusedInput.current = null;
                                          }}
                                          variant="outlined"
                                          onChange={(e) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              e.target.value,
                                            );
                                            changeFieldValue(
                                              inputField.fieldName,
                                              e.target.value,
                                              true,
                                            );
                                          }}
                                        >
                                          {map(
                                            (state) => (
                                              <MenuItem
                                                key={state}
                                                value={state}
                                              >
                                                {state}
                                              </MenuItem>
                                            ),
                                            inputField.dropDownValues || [],
                                          )}
                                        </Select>
                                        {errorFields.includes(
                                          inputField.fieldName,
                                        ) ||
                                        !dealsData[inputField.fieldName] ? (
                                          <FormHelperText>
                                            This Field is Required
                                          </FormHelperText>
                                        ) : (
                                          ''
                                        )}
                                      </FormControl>
                                    </Grid>
                                  );
                                }
                                case 'currency': {
                                  return (
                                    <Grid item sm={12} xs={12} md={6} lg={6}>
                                      <NumberFormat
                                        id={`consumer-form-input-${inputField.fieldName}`}
                                        fullWidth
                                        onFocus={(event) => {
                                          currentlyFocusedInput.current =
                                            event.target;
                                        }}
                                        inputProps={{
                                          autoComplete: 'new-password',
                                        }}
                                        error={errorFields.includes(
                                          inputField.fieldName,
                                        )}
                                        variant="outlined"
                                        helperText={
                                          (errorFields.includes(
                                              inputField.fieldName,
                                            ) &&
                                            (inputField.fieldName ===
                                            'coapplicant_annual_income' && sumAnnualIncome() > 0
                                              ? `Income should be greater than ${minimumIncomeAmount}`
                                              : 'This Field is Required')) ||
                                          ''
                                        }
                                        onBlur={(e) => {
                                          highlightApplicantsIncomeErrors();
                                          currentlyFocusedInput.current = null;
                                        }}
                                        className={classes.formControlInput}
                                        label={inputField.title}
                                        value={incomes[inputField.fieldName]}
                                        customInput={TextField}
                                        prefix="$"
                                        type="text"
                                        allowNegative={false}
                                        isAllowed={({ value }) => {
                                          return value === '0' ? false : true;
                                        }}
                                        thousandSeparator
                                        onValueChange={({ value }) => {
                                          setIncomes({
                                            ...incomes,
                                            [inputField.fieldName]: value,
                                          });
                                          highlightErrorFields(
                                            inputField.fieldName,
                                            value,
                                          );
                                          changeFieldValue(
                                            inputField.fieldName,
                                            value,
                                          );
                                        }}
                                      />
                                    </Grid>
                                  );
                                }
                                case 'date': {
                                  return (
                                    <>
                                      <Grid item sm={12} xs={12} md={6} lg={6}>
                                        <FormControl
                                          className={clsx(
                                            classes.formControlDatePicker,
                                          )}
                                        >
                                          <KeyboardDatePicker
                                            id={`consumer-form-input-${inputField.fieldName}`}
                                            error={errorFields.includes(
                                              inputField.fieldName,
                                            )}
                                            fullWidth
                                            inputProps={{
                                              autoComplete: 'new-password',
                                            }}
                                            openTo="year"
                                            label={inputField.label}
                                            onPaste={(e) => {
                                              if (inputField.fieldName.includes('confirmation')) {
                                                e.preventDefault();
                                              }
                                            }}
                                            disableFuture
                                            format="MM/dd/yyyy"
                                            helperText={`${errorTextForDateField(inputField.fieldName, invalidCoAppDOB)}`}
                                            value={
                                              dealsData[inputField.fieldName]
                                                ? dealsData[inputField.fieldName]
                                                : null
                                            }
                                            onChange={(date, text) => {
                                              handleDateChange(date, text, inputField.fieldName);
                                            }}
                                            inputVariant="outlined"
                                            onBlur={(e) => {
                                              highlightDateErrors(
                                                inputField.fieldName,
                                                e.target.value,
                                              );
                                              currentlyFocusedInput.current = null;
                                            }}
                                          />
                                        </FormControl>
                                      </Grid>
                                      {inputField?.pad && <Grid xs={6}></Grid>}
                                    </>
                                  );
                                }
                                case 'autoComplete': {
                                  return (
                                    <Grid
                                      item
                                      sm={12}
                                      xs={12}
                                      md={Number(inputField.size)}
                                      lg={Number(inputField.size)}
                                    >
                                      {states && (
                                        <Autocomplete
                                          fullWidth
                                          variant="outlined"
                                          autoSelect
                                          value={
                                            dealsData[inputField.fieldName]
                                          }
                                          className={classes.formControl}
                                          autoHighlight
                                          onChange={(e, newValue) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              newValue ? newValue.code : '',
                                            );
                                            changeFieldValue(
                                              inputField.fieldName,
                                              newValue ? newValue.code : '',
                                            );
                                          }}
                                          onBlur={(e) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              e?.target?.value,
                                            );
                                            currentlyFocusedInput.current = null;
                                          }}
                                          options={states}
                                          getOptionLabel={(option) => {
                                            return option?.code === undefined
                                              ? option
                                                ? option
                                                : ''
                                              : option?.code;
                                          }}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              fullWidth
                                              inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                              }}
                                              label={inputField.title}
                                              placeholder={inputField.title}
                                              variant="outlined"
                                              size="large"
                                              value={
                                                dealsData[
                                                  inputField.fieldName
                                                ] || ''
                                              }
                                              onBlur={(e) => {
                                                highlightErrorFields(
                                                  inputField.fieldName,
                                                  e.target.value,
                                                );
                                                currentlyFocusedInput.current = null;
                                              }}
                                              helperText={
                                                errorFields.includes(
                                                  inputField.fieldName,
                                                ) &&
                                                !dealsData[inputField.fieldName]
                                                  ? 'This Field is Required'
                                                  : ''
                                              }
                                              error={
                                                errorFields.includes(
                                                  inputField.fieldName,
                                                ) &&
                                                !dealsData[inputField.fieldName]
                                              }
                                            />
                                          )}
                                        />
                                      )}
                                      {/* </FormControl> */}
                                    </Grid>
                                  );
                                }
                                default:
                                  break;
                              }
                              return '';
                            })}
                          </Grid>
                          <Box pt={1}>
                            <button
                              onClick={clearCoApplicant}
                              type="button"
                              className={classes.transparentButton}
                            >
                              Want to remove a co-applicant?
                            </button>
                          </Box>
                        </>
                      )}
                      {/* Property Information */}
                      <Box pt={6} pb={3}>
                        <StepTitle>
                          {selectedLoanProduct?.attributes
                            ?.application_address_heading
                            ? selectedLoanProduct?.attributes
                                ?.application_address_heading
                            : 'Property Information'}
                        </StepTitle>

                        {selectedLoanProduct?.attributes
                          ?.application_address_subheading ? (
                          <StepSubtitle>
                            {
                              selectedLoanProduct?.attributes
                                ?.application_address_subheading
                            }
                          </StepSubtitle>
                        ) : (
                          <StepSubtitle>
                            All applicants must <strong>own and live</strong> at
                            the property, which must be a{' '}
                            <strong>primary residence</strong>.
                          </StepSubtitle>
                        )}
                      </Box>

                      <Grid container spacing={3} rowSpacing={3}>
                        {/* TODO: Add autocomplete */}
                        {propertyFields.map((inputField) => {
                          const type =
                            inputField.type === 'currency'
                              ? 'number'
                              : inputField.type;
                          if (
                            dealsData?.selectedLoanProduct?.attributes?.code ===
                              'healthcare' &&
                            inputField.type === 'dropdown'
                          ) {
                            return '';
                          }
                          switch (inputField.inputType) {
                            case 'string': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={Number(inputField.size)}
                                  lg={Number(inputField.size)}
                                >
                                  <TextField
                                    fullWidth
                                    onFocus={(event) => {
                                      currentlyFocusedInput.current =
                                        event.target;
                                    }}
                                    error={errorFields.includes(
                                      inputField.fieldName,
                                    )}
                                    className={classes.formControlInput}
                                    label={inputField.title}
                                    type={type}
                                    value={getFieldValue(inputField)}
                                    helperText={getStringFieldErrorText(inputField)}
                                    onChange={(e) => {
                                      handleFieldChange(e, inputField);
                                    }}
                                    InputProps={{
                                      autoComplete: 'new-password',
                                    }}
                                    onBlur={(e) => {
                                      highlightErrorFields(
                                        inputField.fieldName,
                                        e.target.value,
                                      );
                                      currentlyFocusedInput.current = null;
                                    }}
                                    variant="outlined"
                                    autoComplete="off"
                                  />
                                </Grid>
                              );
                            }
                            case 'address': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  style={{
                                    paddingBottom: '0px',
                                  }}
                                >
                                  <AddressAutocomplete></AddressAutocomplete>
                                </Grid>
                              );
                            }
                            case 'dropdown': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={Number(inputField.size)}
                                  lg={Number(inputField.size)}
                                >
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    helperText="This Field is Required"
                                    error={
                                      errorFields.includes(
                                        inputField.fieldName,
                                      ) && !dealsData[inputField.fieldName]
                                    }
                                  >
                                    <InputLabel
                                      id="demo-simple-select-outlined-label"
                                      style={{
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      {inputField.title}
                                    </InputLabel>
                                    <Select
                                      error={
                                        errorFields.includes(
                                          inputField.fieldName,
                                        ) && !dealsData[inputField.fieldName]
                                      }
                                      inputProps={{
                                        autoComplete: 'new-password',
                                      }}
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={getFieldValue(inputField)}
                                      helperText={getStringFieldErrorText(inputField)}
                                      onChange={(e) => {
                                        handleFieldChange(e, inputField);
                                      }}
                                      onBlur={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e.target.value,
                                        );
                                        currentlyFocusedInput.current = null;
                                      }}
                                      variant="outlined"
                                    >
                                      {map(
                                        (state) => (
                                          <MenuItem key={state} value={state}>
                                            {state}
                                          </MenuItem>
                                        ),
                                        inputField.dropDownValues || [],
                                      )}
                                    </Select>
                                    {errorFields.includes(
                                      inputField.fieldName,
                                    ) && !dealsData[inputField.fieldName] ? (
                                      <FormHelperText>
                                        This Field is Required
                                      </FormHelperText>
                                    ) : (
                                      ''
                                    )}
                                  </FormControl>
                                </Grid>
                              );
                            }
                            case 'autoComplete': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={Number(inputField.size)}
                                  lg={Number(inputField.size)}
                                >
                                  {states && (
                                    <Autocomplete
                                      fullWidth
                                      variant="outlined"
                                      autoSelect
                                      value={dealsData?.state}
                                      className={classes.formControl}
                                      autoHighlight
                                      onChange={(e, newValue) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          newValue ? newValue.code : '',
                                        );
                                        changeFieldValue(
                                          inputField.fieldName,
                                          newValue ? newValue.code : '',
                                        );
                                      }}
                                      onBlur={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e?.target?.value,
                                        );
                                        currentlyFocusedInput.current = null;
                                      }}
                                      options={states}
                                      getOptionLabel={(option) => {
                                        return option?.code === undefined
                                          ? option
                                            ? option
                                            : ''
                                          : option?.code;
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                          }}
                                          label={inputField.title}
                                          placeholder={inputField.title}
                                          variant="outlined"
                                          size="large"
                                          value={
                                            dealsData[inputField.fieldName] ||
                                            ''
                                          }
                                          onBlur={(e) => {
                                            highlightErrorFields(
                                              inputField.fieldName,
                                              e.target.value,
                                            );
                                            currentlyFocusedInput.current = null;
                                          }}
                                          helperText={
                                            errorFields.includes(
                                              inputField.fieldName,
                                            ) &&
                                            !dealsData[inputField.fieldName]
                                              ? 'This Field is Required'
                                              : ''
                                          }
                                          error={
                                            errorFields.includes(
                                              inputField.fieldName,
                                            ) &&
                                            !dealsData[inputField.fieldName]
                                          }
                                        />
                                      )}
                                    />
                                  )}
                                  {/* </FormControl> */}
                                </Grid>
                              );
                            }
                            case 'state': {
                              return (
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={Number(inputField.size)}
                                  lg={Number(inputField.size)}
                                >
                                  {' '}
                                  <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    error={errorFields.includes(
                                      inputField.fieldName,
                                    )}
                                    autoComplete="off"
                                  >
                                    <InputLabel
                                      id="demo-simple-select-outlined-label"
                                      style={{
                                        backgroundColor: 'white',
                                      }}
                                    >
                                      State
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      inputProps={{
                                        autoComplete: 'new-password',
                                      }}
                                      value={dealsData[inputField.fieldName]}
                                      variant="outlined"
                                      onBlur={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e.target.value,
                                        );
                                        currentlyFocusedInput.current = null;
                                      }}
                                      onChange={(e) => {
                                        highlightErrorFields(
                                          inputField.fieldName,
                                          e.target.value,
                                        );
                                        changeFieldValue(
                                          inputField.fieldName,
                                          e.target.value,
                                        );
                                      }}
                                    >
                                      {map(
                                        (state) => (
                                          <MenuItem
                                            key={state.code}
                                            value={state.code}
                                          >
                                            {state.code}
                                          </MenuItem>
                                        ),
                                        states || [],
                                      )}
                                    </Select>
                                    {errorFields.includes(
                                      inputField.fieldName,
                                    ) ? (
                                      <FormHelperText>
                                        This Field is Required
                                      </FormHelperText>
                                    ) : (
                                      ''
                                    )}
                                  </FormControl>
                                </Grid>
                              );
                            }
                            default:
                              break;
                          }
                          return '';
                        })}
                      </Grid>

                      {dealsData?.selectedLoanProduct?.attributes?.code && (
                        <TreatmentSection
                          loanProductType={
                            dealsData?.selectedLoanProduct?.attributes?.code
                          }
                        ></TreatmentSection>
                      )}

                      <Grid container spacing={3} rowSpacing={3}>
                        <Grid item sm={12} xs={12}>
                          <Box
                            pt={6}
                            pb={3}
                            style={{
                              paddingBottom: '0px',
                            }}
                          >
                            <StepTitle>Merchant Representative</StepTitle>
                            <StepSubtitle>
                              Merchant representatives may be added to the
                              application.
                            </StepSubtitle>
                          </Box>
                        </Grid>
                        {inHomeRep && getInHomeRepFields()}
                      </Grid>
                      {!inHomeRep && (
                        <Box pt={1}>
                          <button
                            onClick={() => {
                              setInHomeRep(true);
                              setFormError(true);
                              updateData({
                                name: 'homeRep',
                                value: false,
                              });
                            }}
                            style={{
                              padding: '0px',
                            }}
                            type="button"
                            className={classes.transparentButton}
                          >
                            Want to add an in-the-home merchant representative?
                          </button>
                        </Box>
                      )}
                      <MerchantReferenceSection></MerchantReferenceSection>
                      <PromoCodeSection></PromoCodeSection>

                      {/* Continue */}
                      <Box
                        pt={3}
                        pb={3}
                        style={{
                          marginTop: '24px',
                        }}
                      >
                        <StepTitle>Verification</StepTitle>
                        <StepSubtitle>
                          Please click the checkbox below to complete the
                          verification check.
                        </StepSubtitle>
                      </Box>
                      <Grid container>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={10}
                          lg={6}
                          style={{
                            padding: '12px',
                            paddingLeft: '0px',
                          }}
                        >
                          <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                            onChange={(token) => setCaptchaInvalid(false)}
                            onExpired={() => {
                              setCaptchaInvalid(true);
                            }}
                          ></ReCAPTCHA>
                        </Grid>
                      </Grid>
                      <Box pt={6} pb={6}>
                        <StepTitle>Disclaimer</StepTitle>
                        <StepSubtitle>
                          By clicking button below, I, the applicant, agree to
                          the following: I've read, understand, accept, and
                          Consent to the terms of the
                          <Link
                            href="https://getpowerpay.com/account_opening_disclosures/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {' Account Opening Disclosures'}
                          </Link>
                          ,{' '}
                          <Link
                            href="https://getpowerpay.com/esign_consent/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Electronic Records and Communication
                          </Link>{' '}
                          and{' '}
                          <Link
                            href="https://getpowerpay.com/privacy-policy/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>
                          , and agree to receive electronic disclosures with any
                          loan obtained as a result of this application. If a
                          joint application is filed, these Terms of Use apply
                          to each of the applicants equally: I authorize
                          PowerPay and/or any Lender in its Lender Network, its
                          representatives, successors, and assigns to
                          investigate my creditworthiness and obtain a credit
                          report from me for any lawful purpose, including, but
                          not limited to, any extension of credit, renewal,
                          servicing and collections. Upon request, Lender will
                          inform me of whether a credit report was obtained and
                          if a report was obtained, the names and addresses of
                          the credit reporting agencies that furnished the
                          report. All credit offers expire 30 days from the
                          initial application submission. If the loan has been
                          executed within the initial 30 day period but has not
                          been fully funded within 180 calendar days from the
                          application submission date Powerpay and/or the Lender
                          reserves the right to rescind or change the credit
                          offer. I authorize PowerPay to contact me at the phone
                          number I provided via text, an automated telephone
                          dialing system, or artificial or prerecorded voice
                          messages, for any purpose. This is not required to
                          apply. I can opt-out by contacting PowerPay at
                          1-800-397-4485.
                        </StepSubtitle>
                      </Box>

                      <Grid container>
                        <Grid item xs={false} md={1} lg={3} />
                        <Grid item sm={12} xs={12} md={10} lg={6}>
                          <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.actionButton}
                            onClick={formSubmit}
                            disabled={!canSubmit}
                          >
                            <Text textSize="md" textColor="white" bold>
                              {buttonText}
                            </Text>
                          </Button>
                        </Grid>
                        <Grid item xs={false} md={1} lg={3} />
                      </Grid>
                    </Box>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    selectedLoanProduct: state.appReducer.selectedLoanProduct,
  };
};

export default connect(mapStatesToProps, {
  updateData,
})(ConsumerForm);
