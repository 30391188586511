import * as React from 'react';

function SvgCancelIcon({ width, height, ...props }) {
  return (
    <svg
      width={width || '20px'}
      height={height || '18px'}
      viewBox="0 0 20 22"
      {...props}
    >
      <defs>
        <path
          d="M12 0a3 3 0 013 3v1h4a1 1 0 01.993.883L20 5a1 1 0 01-1 1h-1v13a3 3 0 01-2.824 2.995L15 22H5a3 3 0 01-3-3V6H1a1 1 0 01-.993-.883L0 5a1 1 0 011-1h4V3A3 3 0 017.824.005L8 0zm4 6H4v13a1 1 0 00.883.993L5 20h10a1 1 0 001-1V6zm-4-4H8a1 1 0 00-1 1v1h6V3a1 1 0 00-.883-.993L12 2z"
          id="cancel-icon_svg__a"
        />
      </defs>
      <use
        fill="currentColor"
        xlinkHref="#cancel-icon_svg__a"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCancelIcon;
