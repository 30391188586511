import React, { useEffect } from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { connect } from 'react-redux';
import { updateData } from 'redux/actions';
import clsx from 'clsx';
import FormControl from '@material-ui/core/FormControl';
import { StepSubtitle, StepTitle } from './DealerSection';
const useStyles = makeStyles((theme) => ({

  formControlDatePicker: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInput: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const TreatmentSection = ({ appData, loanProductType, updateData }) => {
  // can't delete, used in the consumer form
  const classes = useStyles();

  const getUpperCaseLoanProductText = () => {
    switch (loanProductType){
      case "healthcare": 
        return "Treatment Date";
      case "solar_esg":
        return "Estimated Installation Date";
      default: 
        return "Estimated Project Complete Date";
    }
  }

  const getDate180DaysAfterToday = () => {
    // We're trying to calculate 6 months in advance, but keep in mind months vary in length.
    return new Date(new Date().setDate(new Date().getDate() + 180))
  }

  useEffect(() => {
    if (loanProductType === "healthcare") {

      const isTreatmentDateValid = appData?.serviceDate && new Date(appData?.serviceDate) <= getDate180DaysAfterToday();

      if(!isTreatmentDateValid){
        updateData({
          name: 'serviceDate',
          value: "",
        })
      }
    }
  }, [loanProductType])

  return (
    <>
      <Grid container spacing={3} rowSpacing={3}>

        <Grid item sm={12} xs={12}>
          <Box
            pt={6}
            pb={3}
            style={{
              paddingBottom: '0px',
            }}
          >
            <StepTitle>{getUpperCaseLoanProductText()}</StepTitle>
            <StepSubtitle>Please enter the {getUpperCaseLoanProductText().toLocaleLowerCase()}.</StepSubtitle>
          </Box>
        </Grid>
        <br />
        <Grid item sm={12} xs={12} md={6} lg={6}>
          <FormControl
            className={clsx(
              classes.formControlDatePicker,
            )}
          >
            <KeyboardDatePicker
              className={classes.formControlInput}
              helperText={appData?.invalidServiceDate}
              error={appData?.invalidServiceDate}
              inputProps={{
                autoComplete: 'treatment-date',
              }}
              fullWidth
              openTo="year"
              label={`${getUpperCaseLoanProductText()} (MM/DD/YYYY)`}
              format="MM/dd/yyyy"
              value={appData?.serviceDate ? appData?.serviceDate : null}
              disablePast
              maxDate={loanProductType === "healthcare" ? getDate180DaysAfterToday() : undefined }
              onError={(e) => {
                if (e === 'Date should not be before minimal date' && appData?.invalidServiceDate !== 'Date cannot be set in the past') {
                  updateData({
                    name: 'invalidServiceDate',
                    value: 'Date cannot be set in the past',
                  })
                }
              }
              }
              onChange={(date, text) => {
                if (date?.toDateString() === 'Invalid Date' || date?.toDateString() === 'Date should not be before minimal date') {
                  updateData({
                    name: 'invalidServiceDate',
                    value: date?.toDateString(),
                  })
                } else {
                  if (appData?.invalidServiceDate) {
                    updateData({
                      name: 'invalidServiceDate',
                      value: '',
                    })
                  }
                  updateData({
                    name: 'serviceDate',
                    value: text,
                  })
                }
                updateData({
                  name: 'serviceDate',
                  value: text,
                })
              }
            
            }

              inputVariant="outlined"

            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

const mapStatesToProps = (state) => {
  return {
    message: state.appReducer.message,
    appData: state.appReducer,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(TreatmentSection);
