import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserContext } from './useUser';
import { getToken } from '../authentication';

export const useRedirectOnDealerStatus = () => {
  const { user } = useUserContext();
  const history = useHistory();
  const userStatus = JSON.parse(getToken())?.data?.attributes?.status;
  useEffect(() => {
    if (user.data.type === 'dealer' && userStatus !== 'approved') {
      history.replace('/dashboard');
    }
  }, []);
};
