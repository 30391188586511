import React, { useState, useMemo } from 'react';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { pathSplit } from 'services/utils';

import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
}));

const NumberFormatCustom = (props) => {
  const { inputRef, value, min, max, onFormikChange, onBlur, ...other } = props;
  const [currentValue, setCurrentValue] = useState(value || '');

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={currentValue}
      onValueChange={(values) => {
        let val = values.value;
        if (min !== undefined) {
          val = Math.max(val, min);
        }
        if (max !== undefined) {
          val = Math.min(val, max);
        }

        if (val === 0) {
          val = '';
        }

        setCurrentValue(val);
      }}
      onBlur={(ev) => {
        if (currentValue !== value) {
          onFormikChange(currentValue);
        }
        onBlur(ev);
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};
export const MoneyFormatField = ({
  name,
  label,
  min,
  max,
  hideErrors,
  resetValue,
  ...props
}) => {
  const classes = useStyles();
  const formik = useFormikContext();
  let resetFlag = resetValue;
  if (!formik) {
    console.error('error loading form');
  }

  const { values, setFieldValue, errors } = formik ? formik : '';
  const value = pathSplit(values, name);
  const onChange = (val) => {
    resetFlag = false;
    setFieldValue(name, val);
  };
  if (resetFlag) {
    if (values[name]) {
      setFieldValue(name, '');
    }
  }

  const error = pathSplit(errors, name);
  return (
    <FormControl
      className={clsx(classes.formControl, {
        [classes.error]: Boolean(error),
      })}
      error={Boolean(error)}
    >
      <TextField
        label={label}
        variant="outlined"
        value={value}
        InputProps={{
          inputComponent: (inputProps) => {
            return (
              <NumberFormatCustom
                {...inputProps}
                value={value}
                min={min}
                max={max}
                onFormikChange={onChange}
              />
            );
          },
        }}
        {...props}
      />
      {!hideErrors && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
