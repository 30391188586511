import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useField, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import SignaturePad from 'signature_pad';
import { pathSplit } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    border: 'solid 1px',
    borderColor: ({ disabled }) =>
      disabled ? 'rgba(64,68,64,0.15)' : '#9198A0',
    borderRadius: 4,
    padding: '1rem',
    position: 'relative',
    height: '10rem',
    background: ({ disabled }) =>
      disabled ? 'rgba(64,68,64,0.05)' : 'transparent',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  signatureLine: {
    position: 'absolute',
    bottom: '2rem',
    left: '2.5rem',
    right: '2.5rem',
    background: '#9198A0',
    height: 2,
  },
  clearBtn: {
    color: 'rgba(30,39,46,0.4)',
    position: 'absolute',
    bottom: '1.5rem',
    left: '1.5rem',
    transform: 'translate2D(-100%, -100%)',
  },
  error: {
    borderColor: `${theme.palette.error.main} !important`,
  },
}));

export const SignatureField = ({
  id,
  name,
  label,
  hideErrors = false,
  setSaveCallback,
  disabled,
}) => {
  const classes = useStyles({ disabled });
  const [field, meta] = useField({ name });
  const { values } = useFormikContext();
  const loadedDataURL = pathSplit(values, `${name}_loaded`);
  const fieldValue =
    field?.value && field?.value?.includes('data:image/png;base64,')
      ? field?.value.split('base64,')[1]
      : field?.value;
  const [currentDataURL, setCurrentDataURL] = useState(fieldValue);
  const currentDataURLRef = useRef(fieldValue);
  useEffect(() => {
    if (loadedDataURL) {
      setCurrentDataURL(`data:image/png;base64,${fieldValue}`);
    }
  }, [fieldValue, loadedDataURL]);
  useEffect(() => {
    currentDataURLRef.current = currentDataURL;
  }, [currentDataURL]);

  if (!id) {
    console.warn('no id for input');
  }

  const signaturePadRef = useRef(null);
  const resizeCanvas = () => {
    const canvas = document.querySelector(`#${id}`);
    const wrapper = wrapperRef.current;
    const rect = canvas.getBoundingClientRect();
    const dataUrl = currentDataURLRef.current;

    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    canvas.width = wrapper.offsetWidth;
    canvas.height = wrapper.offsetHeight;
    if (dataUrl) {
      canvas.getContext('2d').scale(ratio, ratio);
    }
    signaturePadRef.current.clear();
    if (dataUrl) {
      signaturePadRef.current.fromDataURL(dataUrl);
    }
  };

  const wrapperRef = useRef(null);
  useEffect(() => {
    const canvas = document.querySelector(`#${id}`);
    // const wrapper = wrapperRef.current;
    // canvas.width = wrapper.offsetWidth;
    // canvas.height = wrapper.offsetHeight;
    if (canvas) {
      signaturePadRef.current = new SignaturePad(canvas, {
        dotSize: 2,
      });
      if (!disabled) {
        signaturePadRef.current.on();
      } else {
        signaturePadRef.current.off();
      }
      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();
    }

    return () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.off();
      }
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  useEffect(() => {
    setSaveCallback(() => {
      const pad = signaturePadRef.current;
      if (pad) {
        if (pad.isEmpty()) {
          return null;
        }
        const data = pad.toDataURL();
        return data;
      }
    });
  }, [signaturePadRef?.current]);

  useEffect(() => {
    if (wrapperRef.current) {
      resizeCanvas();
    }
  }, [wrapperRef.current?.offsetWidth, currentDataURL]);

  const clearSignature = () => {
    signaturePadRef.current.clear();
    signaturePadRef.current.on();
  };

  return (
    <Box
      ref={wrapperRef}
      id={`${id}-wrapper`}
      className={clsx(classes.wrapper, {
        [classes.error]: Boolean(meta.error),
      })}
    >
      <canvas id={id} className={classes.canvas}></canvas>
      <IconButton
        className={classes.clearBtn}
        onClick={clearSignature}
        disabled={disabled}
      >
        <CloseIcon />
      </IconButton>
      <Box className={classes.signatureLine}></Box>
    </Box>
  );
};
