import { makeStyles } from '@material-ui/core/styles';

export const getLocation = (location) => {
  const { pathname } = location;
  if (pathname.includes('applications')) {
    return 'app';
  }
  if (pathname.includes('projects')) {
    return 'project';
  }
  if (pathname.includes('prequalify')) {
    return 'prequal';
  }
  return null;
};

export const headerStyles = makeStyles((theme) => {
  const whiteOrPrimaryFn = ({ location }) =>
    location ? 'white' : theme.palette.primary.main;
  return {
    dynamicHeader: {
      background: ({ location }) => {
        switch (location) {
          case 'app':
            return theme.palette.header.app;
          case 'project':
            return theme.palette.header.project;
          case 'prequal':
            return theme.palette.header.prequal;
          default:
            return 'white';
        }
      },
      '& .appTitle, &.sidenavToolbar svg': {
        color: whiteOrPrimaryFn,
      },
      '& .headerActions button': {
        background: whiteOrPrimaryFn,
        '&, & svg': {
          color: ({ location }) =>
            location ? theme.palette.text.primary : 'white',
        },
      },
      '& .sign-out-button svg g': {
        fill: ({ location }) => (location ? 'white' : 'black'),
      },
    },
  };
});
