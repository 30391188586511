import React from 'react';
import { useField, useFormikContext, ErrorMessage } from 'formik';
import {
  makeStyles,
  Box,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import Text from 'components/TextFields/Text';
import Icon from 'components/Icon';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '0.5rem',
  },
  container: {
    height: '100%',
    border: '0.5px solid rgba(193,201,209,0.4)',
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    padding: '0.75rem 1rem',
  },
  input: {
    display: 'none',
  },
  formControl: {
    width: '100%',
  },
  formHelperText: {
    overflowWrap: 'break-word',
  },
}));

function DocumentUploadInput({ name, children }) {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });

  const handleChange = (e) => {
    const reader = new FileReader();
    const file = e.currentTarget.files[0];
    if (file) {
      reader.onloadend = () => {};
      reader.readAsDataURL(file);
      helpers.setValue(file);
    }
  };

  return (
    <>
      <Box className={classes.container}>
        <Text
          className={classes.header}
          textColor="#1E272E"
          textFontSize="0.75rem"
          textLineHeight="0.75rem"
          textLetterSpacing="0.55px"
        >
          {children}
        </Text>
        <FormControl error={meta.error} className={classes.formControl}>
          <label htmlFor={`document-file-${name}`}>
            <input
              onChange={handleChange}
              accept=".pdf,.jpg,.jpeg,.png"
              className={classes.input}
              type="file"
              id={`document-file-${name}`}
            />
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              component="span"
              endIcon={<Icon icon="uploadIcon" />}
            >
              UPLOAD DOCUMENT
            </Button>
          </label>
          {field.value && (
            <FormHelperText className={classes.formHelperText}>
              {field.value.name}
            </FormHelperText>
          )}
          {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
      </Box>
    </>
  );
}

export default DocumentUploadInput;
