import React from 'react';
import { Link } from 'react-router-dom';
import { useFormikContext, yupToFormErrors } from 'formik';
import to from 'await-to-js';
import * as yup from 'yup';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import { OutlinedTextField } from 'components/InputFields/InputField';
import Text from 'components/TextFields/Text';
import ToggleButtons from 'components/Buttons/ToggleButtons';

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required('Field Required')
    .trim(),
  lastname: yup
    .string()
    .required('Field Required')
    .trim(),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Field Required')
    .trim(),
  ownerStatus: yup
    .string()
    .required('Field Required')
    .trim(),
});

const useStyles = makeStyles(theme => ({
  toggleWrapper: {
    marginTop: 16,
    marginBottom: 32,
  },

  button: {
    width: 175,
    height: 46,
    padding: '9px 0',
  },

  linkWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  linkText: {
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
}));

const FirstPage = ({ setCurrentPage, ownerValue, setOwnerValue }) => {
  const classes = useStyles();

  const { values, setErrors, handleSubmit } = useFormikContext();

  const onHandleSubmit = e => {
    e.preventDefault();
    const validate = async () => {
      const [validationErrors] = await to(
        validationSchema.validate(values, {
          abortEarly: false,
        }),
      );

      if (validationErrors) {
        setErrors(yupToFormErrors(validationErrors));
        return false;
      }
      handleSubmit(e);
      return true;
    };

    validate();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text
          textVariant="body1"
          textColor="#636A72"
          textFontWeight={600}
          textFontSize={12}
          textLetterSpacing={0.6}
          textLineHeight="16px"
          text="PERSONAL INFORMATION"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedTextField name="firstname" type="text" label="First Name" />
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedTextField name="lastname" type="text" label="Last Name" />
      </Grid>

      <Grid item xs={12}>
        <OutlinedTextField name="email" type="email" label="Email" />
      </Grid>

      <Grid item xs={12}>
        <Text
          textVariant="body1"
          textColor="#1E272E"
          textFontWeight={500}
          textFontSize={15}
          textLetterSpacing={0.56}
          textLineHeight="24px"
          text="Are you a Business Owner?"
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.toggleWrapper}>
          <ToggleButtons
            values={['yes', 'no']}
            setFieldValue={setOwnerValue}
            hasValue={ownerValue}
          />
        </div>
      </Grid>

      <Grid item xs={4} className={classes.linkWrapper}>
        <Typography
          className={classes.linkText}
          onClick={() => setCurrentPage('firstPage')}
        >
          <KeyboardArrowLeft style={{ marginRight: 5 }} />
          Back
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          type="button"
          onClick={onHandleSubmit}
        >
          <Text
            textSize="md"
            bold
            style={{ display: 'flex', alignItems: 'center' }}
            textColor="white"
          >
            CREATE ACCOUNT
          </Text>
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.terms}>
          By submitting this application, you are certifying that you have read
          and agree to the{' '}
          <Link to="/" className={classes.boldLink}>
            Disclosures
          </Link>
          . and{' '}
          <Link to="/" className={classes.boldLink}>
            {' '}
            Program Agreement{' '}
          </Link>
          . Sole Proprietorships and Partnerships: We may verify credit history
          and secure credit reports of Applicant, principals, owners, or
          partners. You hereby approve any third party to provide reports to
          POWERpay®.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FirstPage;
