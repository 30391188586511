import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Formik, Form, yupToFormErrors } from 'formik';

import to from 'await-to-js';

import { addIndex, map } from 'ramda';

import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { Grid, Card, CardContent, makeStyles, Box } from '@material-ui/core';

import { StepHeader } from 'pages/NewApplication/StepHeader';
import { SaveDraftButton } from 'pages/NewApplication/SaveDraftButton';
import { useSessionExpirerContext } from 'components/SessionExpirerContext';

const useStyles = makeStyles((theme) => ({
  card: {
    overflowY: 'auto',
    position: 'relative',
  },
  stepContainer: {
    overflow: 'hidden',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem',
      paddingRight: 0,
    },
  },
  stepper: {
    flexGrow: 1,
    background: 'transparent',
    padding: '8px 0',
  },
  stepForm: {
    minHeight: '32rem',
  },
  hide: { height: 0 },
}));

export const StepsForm = ({
  onSubmit,
  onSaveDraft,
  steps,
  initialValues,
  onExpireCb,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { setSessionExpiredCallback } = useSessionExpirerContext();
  useEffect(() => {
    return () => {
      setSessionExpiredCallback(null);
    };
  }, []);

  const [childCallbacks, setChildCallbacks] = useState({});
  const setChildCallbacksFn = (callbacks) =>
    setChildCallbacks((current) => ({ ...current, ...callbacks }));

  // ///
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Card elevation={4} className={classes.card}>
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={null}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ values, setErrors, errors, submitForm, setSubmitting }) => {
            setSessionExpiredCallback(() => onExpireCb(values));
            const handleNext = async () => {
              const onNext =
                !childCallbacks.onNext || (await childCallbacks.onNext());

              if (onNext) {
                if (childCallbacks.validationSchema) {
                  const [validationErrors] = await to(
                    childCallbacks.validationSchema.validate(values, {
                      abortEarly: false,
                    }),
                  );

                  if (validationErrors) {
                    setErrors(yupToFormErrors(validationErrors));
                    return;
                  }
                }
                if (activeStep === steps.length - 1) {
                  submitForm();
                  return;
                }
                onSaveDraft(values, { setSubmitting }, true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
              }
            };
            const handleBack = () => {
              if (!childCallbacks.onBack || childCallbacks.onBack()) {
                onSaveDraft(values, { setSubmitting }, true);
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }
            };
            return (
              <>
                <SaveDraftButton
                  onClick={() => {
                    onSaveDraft(values, { setSubmitting });
                  }}
                />
                <Form>
                  <Box className={clsx(classes.stepForm, 'form-step')}>
                    <SwipeableViews
                      axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                      index={activeStep}
                      disabled
                    >
                      {addIndex(map)(
                        (step, index) => (
                          <Box
                            className={clsx(classes.stepContainer, {
                              [classes.hide]: index !== activeStep,
                            })}
                            key={index}
                          >
                            <StepHeader
                              step={index + 1}
                              title={step.title}
                              subtitle={step.subtitle}
                            />
                            {step.component({
                              isActive: index === activeStep,
                              setChildCallbacks: setChildCallbacksFn,
                              isFirst: index === 0,
                              onSaveDraft: () => {
                                onSaveDraft(values, { setSubmitting });
                              },
                            })}
                          </Box>
                        ),
                        steps,
                      )}
                    </SwipeableViews>
                  </Box>
                  <MobileStepper
                    variant="progress"
                    steps={steps.length}
                    position="static"
                    activeStep={activeStep}
                    className={classes.stepper}
                    nextButton={
                      <Button size="small"
                        onClick={handleNext}
                        disabled={activeStep===3}
                        color="primary">
                        {
                          activeStep < 3 && 'Next'
                        }

                        {theme.direction === 'rtl' ?
                          (
                            <KeyboardArrowLeft />
                          ) : activeStep < 3 && (
                            <KeyboardArrowRight />
                          ) 
                        }
                      </Button>
                    }

                    backButton={
                      <Button
                        size="small"
                        onClick={handleBack}
                        disabled={
                          childCallbacks.canBack
                            ? !childCallbacks.canBack()
                            : activeStep === 0
                        }
                      >
                        {theme.direction === 'rtl' ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        Back
                      </Button>
                    }
                    LinearProgressProps={{ color: 'secondary' }}
                  />
                </Form>
              </>
            );
          }}
        </Formik>
      </CardContent>
    </Card>
  );
};
