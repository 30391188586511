import React from 'react';

export default function CheckIcon({ width, height, color }) {
  return (
    <svg
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Filled / Check@1x</title>
      <g
        id="Icon-/-Filled-/-Check"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 Z M16.1503906,7.3771495 L10.3326667,13.2346667 L8.18294276,11.0694572 C7.68356888,10.5665912 6.87198667,10.5665912 6.37261279,11.0694572 C5.87579574,11.5697485 5.87579574,12.3789695 6.37261279,12.8792608 L9.42816835,15.9561838 C9.92754223,16.4590498 10.7391244,16.4590498 11.2384983,15.9561838 L17.9607205,9.18695306 C18.4575376,8.68666176 18.4575376,7.87744081 17.9607205,7.3771495 C17.4613467,6.8742835 16.6497644,6.8742835 16.1503906,7.3771495 Z"
          id="Combined-Shape"
          fill={color || '#1DD1A1'}
        />
      </g>
    </svg>
  );
}
