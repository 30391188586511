import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import rootReducer from 'redux/rootReducer';
import thunk from 'redux-thunk';
import App from './App';
import './styles/main.scss';
import * as serviceWorker from './serviceWorker';
ReactDOM.render(
  <BrowserRouter>
    <Provider store={createStore(rootReducer,applyMiddleware(thunk))}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
