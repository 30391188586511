import React, { useState, useEffect, useRef } from 'react';
import normalize from 'json-api-normalize';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { camelizeKeys } from 'humps';
import { protectedGet, protectedPut } from 'services/http';
import to from 'await-to-js';
import { addIndex, map, props } from 'ramda';
import PulseLoader from 'react-spinners/PulseLoader';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { ApplicationStatusBadge } from 'components/ApplicationStatusBadge';
import Text from 'components/TextFields/Text';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDisclosure } from 'services/hooks/useDisclosure';
import {
  Button,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
} from '@material-ui/core';
import { Label } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    whiteSpace: 'nowrap',
  },
  statusSelect: {
    padding: '0.5rem',
    '&:hover': {
      borderRadius: 4,
      border: 'solid 1px rgba(30,39,46,0.4)',
      cursor: 'pointer',
      margin: '-1px',
    },
  },
  formControl: {
    marginBottom: '20px',
    minWidth: '100%',
    width: '15rem',
    backgroundColor: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  formControlInput: {
    marginBottom: '20px',
    backgroundColor: 'white',

    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  actionButton: {
    // width: '100%',
    color: 'white',
  },
  preSelected: {
    backgroundColor: 'rgb(249, 250, 252)',
  },
  menu: {
    // padding: '0 0.5rem',
    // position: 'absolute',
    // overflow: 'auto',
    // height: '300px',
    // top: '115%',
    // left: 0,
    borderRadius: 4,
    // border: 'solid 1px rgba(30,39,46,0.4)',
    background: 'white',
    // zIndex: 99,
    '& > div': {
      margin: '0.5rem 0',
      padding: '0.25rem 0.15rem',
      borderRadius: 4,
      '&:hover': {
        background: 'rgba(30,39,46,0.05)',
        cursor: 'pointer',
      },
    },
  },
}));

export const ItemSelector = ({
  currentItem,
  items,
  ItemComponent,
  MenuItemComponent,
  onItemCallback,
  setIsMenuOpen,
}) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const theme = useTheme();

  useEffect(() => {
    if (setIsMenuOpen) {
      setIsMenuOpen(isOpen);
    }
  }, [isOpen]);

  const wrapperRef = useRef(null);
  const handleClick = (ev) => {
    if (!wrapperRef.current.contains(ev.target)) {
      onClose();
    }
  };

  return (
    <Box ref={wrapperRef} className={classes.wrapper}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.statusSelect}
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onOpen();
        }}
      >
        <ItemComponent {...currentItem} />
      </Box>

      <Dialog open={isOpen}>
        <DialogContent
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            // onItemCallback(item);
            // onClose();
          }}
        >
          {items.length === 0 ? (
            <Box
              p={6}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <PulseLoader color={theme.palette.primary.light} size="1rem" />
              <Text textSize="sm+" semibold textColor="secondary">
                Loading Merchants...
              </Text>
            </Box>
          ) : (
            <>
              <Grid
                item
                xs={12}
                onClick={(ev) => {
                  ev.stopPropagation();
                  ev.preventDefault();
                  // onItemCallback(item);
                  // onClose();
                }}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    // onItemCallback(item);
                    // onClose();
                  }}
                  autoComplete="off"
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    style={{
                      backgroundColor: 'white',
                    }}
                  >
                    Merchant Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    autoComplete="off"
                    // autoWidth
                    onClick={(ev) => {
                      ev.stopPropagation();
                      ev.preventDefault();
                      // onItemCallback(item);
                      // onClose();
                    }}
                    key={currentItem?.id}
                    value={currentItem?.id}
                    variant="outlined"
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onItemCallback({ id: e.target.value });
                      onClose();
                      // changeFieldValue(item.fieldName, e.target.value);
                    }}
                  >
                    {addIndex(map)(
                      (dealer, index) => (
                        <option
                          key={dealer.name}
                          value={dealer.id}
                          className={index === 0 ? classes.preSelected : ''}
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          {dealer?.name} {dealer?.lastName} ({' '}
                          {dealer?.role.charAt(0).toUpperCase()}
                          {dealer?.role.slice(1)})
                        </option>
                        // <MenuItem key={dealer.name} value={dealer.name}>
                        //   {dealer.name}
                        // </MenuItem>
                      ),
                      items,
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export const ItemSelectorMerchant = ({
  currentItem,
  items,
  ItemComponent,
  MenuItemComponent,
  onItemCallback,
  setIsMenuOpen,
}) => {
  const classes = useStyles();
  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const changeHandle = (e) => {
    sessionStorage.setItem('refId', e.target.value);
  };
  useEffect(() => {
    if (setIsMenuOpen) {
      setIsMenuOpen(isOpen);
    }
  }, [isOpen]);

  const wrapperRef = useRef(null);
  const handleClick = (ev) => {
    if (!wrapperRef.current.contains(ev.target)) {
      onClose();
    }
  };

  return (
    <Box ref={wrapperRef} className={classes.wrapper}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.statusSelect}
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onOpen();
        }}
      >
        <ItemComponent {...currentItem} />
      </Box>

      <Dialog open={isOpen}>
        <DialogContent
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <TextField
            margin="dense"
            id="refId"
            className={classes.formControlInput}
            defaultValue={currentItem}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            autoComplete="off"
            onChange={changeHandle}
            label="Merchant Ref Id"
            type="string"
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={(ev) => {
              sessionStorage.removeItem('refId');
              ev.stopPropagation();
              ev.preventDefault();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.actionButton}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              onItemCallback(sessionStorage.getItem('refId'));
              sessionStorage.removeItem('refId');
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export const ApplicationStatusSelector = ({
  currentStatus,
  statuses,
  callbackFn,
}) => {
  const {
    palette: { text },
  } = useTheme();

  return (
    <ItemSelector
      currentItem={currentStatus}
      items={statuses}
      onItemCallback={callbackFn}
      ItemComponent={(props) => (
        <ApplicationStatusBadge
          textProps={{
            textSize: 'xs+',
            bold: true,
            textColor: text.secondary,
          }}
          kind="user"
          {...props}
        />
      )}
    />
  );
};

export const ApplicationDealerSelector = ({ currentDealer, callbackFn }) => {
  const [dealers, setDealers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    palette: { text },
  } = useTheme();
  useEffect(() => {
    async function fetch() {
      const [err, members] = await to(
        protectedGet(
          `/v1/applications/${currentDealer.appId}/get_all_members_for_app_dealer`,
          {
            params: {},
          },
        ),
      );
      if (err) {
        console.error(err);
        setDealers([]);
        return;
      }

      const res = normalize(camelizeKeys(members.data)).get([
        'id',
        'name',
        'lastName',
        'role',
      ]);
      let itemFound = false;
      res.map((merchant) => {
        if (itemFound) {
          return true;
        }
        if (merchant.id === currentDealer.id) {
          res.splice(res.indexOf(merchant), 1);
          res.unshift(merchant);
          itemFound = true;
        }
        return res;
      });
      setDealers(res);
    }
    if (isMenuOpen) {
      fetch();
    }
  }, [isMenuOpen]);

  return (
    <ItemSelector
      currentItem={currentDealer}
      items={dealers}
      onItemCallback={callbackFn}
      ItemComponent={(dealer) => (
        <Box>
          <Text textSize="sm+" textColor={text.primary}>
            {dealer?.name} {dealer?.lastName}
          </Text>

          <Text textSize="xs" semibold textColor={text.hint}>
            {dealer?.role?.charAt(0).toUpperCase()}
            {dealer?.role?.slice(1)}
          </Text>
        </Box>
      )}
      MenuItemComponent={(dealer) => (
        <>
          <Text textSize="sm+" textColor={text.primary}>
            {dealer?.name} {dealer?.lastName}
          </Text>

          <Text textSize="xs" semibold textColor={text.hint}>
            {dealer?.role.charAt(0).toUpperCase()}
            {dealer?.role.slice(1)}
          </Text>
        </>
      )}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
};

export const MerchantReferenceIdEditor = ({
  currentMerchantId,
  callbackFn,

  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const {
    palette: { text },
  } = useTheme();

  return (
    <ItemSelectorMerchant
      currentItem={currentMerchantId}
      items={[currentMerchantId]}
      onItemCallback={callbackFn}
      ItemComponent={(dealer) => (
        <Box>
          <Text textSize="sm+" textColor={text.primary}>
            {currentMerchantId}
          </Text>
        </Box>
      )}
      setIsMenuOpen={setIsMenuOpen}
    />
  );
};
