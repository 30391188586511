import {
  DocStatus,
  getIconName,
  processFileType,
  processName,
  useStyles
} from "pages/ApplicationDetail/DocumentsTab/utils";
import React from "react";
import {Box, Typography, useTheme} from "@material-ui/core";
import Icon from "components/Icon";
import {ActionsMenu} from "./ActionsMenu";

export const InactiveDocumentCard = (
  document,
  handleUploadDocument,
  isAppCanceled,
) => {
  const classes = useStyles();
  const theme = useTheme();

  if (document) {
    const fileType = document.contentType
      ? processFileType(document.contentType)
      : processFileType(document.content_type);
    const status = 'inactive'
    const fileName = document.fileType
      ? processName(document.fileType)
      : processName(document.file_type);
    return (
      <div className={classes.inactiveCard}>
        <div className={classes.cardContent}>
          {/* ICON */}
          <Icon icon={getIconName(fileType)}/>

          {/* TEXT */}
          <div
            className={classes.fileDescriptionUploaded}
          >
            {/* TITLE */}
            <Box
              display="flex"
              alignItems="center"
              className={classes.documentTitle}
            >
              <Typography className={classes.uploadedDocument}>
                {document.name || fileName} &nbsp;
              </Typography>
              <DocStatus status={status}/>
            </Box>
            {/* DESCRIPTION || UPLOAD BUTTON */}
            <Typography className={classes.documentType}>
              {`File Type: ${fileType}`}
            </Typography>

          </div>

          {/* OPTIONS MENU */}
          <ActionsMenu
            handleUploadDocument={(e) => handleUploadDocument(e, document)}
            isAppCanceled={isAppCanceled}
            classes={classes}
            urlTarget={document.url ? document.url : null}
            canEdit={false}
          />
        </div>
      </div>
    );
  }

  return null;
};