import React, { useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
import getTime from 'date-fns/getTime';
import * as yup from 'yup';
import { connect } from 'react-redux';
import to from 'await-to-js';
// Material
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useAlertContext } from 'components/AlertContext';
import { updateData } from 'redux/actions';
import { useDealerListContext } from 'services/contexts/useDealerList';

import http, { protectedGet } from '../../services/http/index';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loaders';

import Text from '../../components/TextFields/Text';
import LinkText from '../../components/TextFields/LinkText';
import LinkWithBackground from '../../components/TextFields/LinkWithBackground';
import ContainedButton from '../../components/Buttons/ContainedButton';
import PasswordField from '../../components/InputFields/PasswordField';
import { TrimField } from '../../components/InputFields/InputField';
import UserContext from '../../components/UserContext';
import {
  getToken,
  setToken,
  getRedirectPage,
  removeRedirectPage,
} from '../../services/authentication';
const validationSchema = yup.object({
  email: yup.string().email('Wrong email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formTitle: {
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 56,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
  forgotPassword: {
    textAlign: 'center',
    marginTop: -16,
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  resendLink: {
    marginTop: 32,
  },
}));

const SignIn = (props) => {
  const [showPassword, setShowPassword] = useState('password');
  const fieldsToReset = [
    'name',
    'coapplicant_name',
    'loanDetails',
    'invalidServiceDate',
    'productId',
    'promo_code',
    'serviceDate',
    "stagedFunding",
    'coapplicant_driver_license_number',
    'driver_license_number',
    'coapplicant_active_military',
    'active_military',
    'last_name',
    'coapplicant_last_name',
    'email',
    'coapplicant_email',
    'coapplicant_ssn',
    'coapplicant_anual_income',
    'birthday',
    'ssn',
    'anual_income',
    'other_household_income',
    'request_amount',
    'city',
    'state',
    'employer_name',
    'employer_zip',
    'zip_code',
    'street_address',
    'signatureMethod',
    'occupation',
    'employment_type',
    'coapplicant_employer_name',
    'coapplicant_employer_zip',
    'phone_number',
    'coapplicant_phone_number',
    'coapplicant_birthday',
    'coapplicant_occupation',
    'coapplicant_employment_type',
    'dealer_code',
    'ith_dealer_email',
    'ith_dealer_phone',
    'years_employment',
    'coapplicant_years_employment',
    'applicant_notifications_disabled',
    'merchant_ref_id',
  ];
  const classes = useStyles();
  const { setUser } = useContext(UserContext);
  const redirectPath = getRedirectPage();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  useEffect(() => {
    if (props.location.search === '?dealer_error=confirm_link_expired') {
      setErrorAlertMessage('Your account is already confirmed. Please login.');
    }
  }, []);

  const redirectLogic = (userData) => {
    // if(sessionStorage.getItem('settings') === "true"){
    //   props.history.replace('/settings');
    //   return;
    // }
    if (userData.data.type === 'dealer') {
      const path =
        userData?.data?.attributes?.default_url !== '' &&
        userData?.data?.attributes?.default_url !== '/dealer/default' &&
        userData?.data?.attributes?.default_url !== null &&
        userData?.data?.attributes?.default_url !== undefined &&
        userData?.data?.attributes?.hierarchyLevelRole !== 'super_admin'
          ? userData?.data?.attributes?.default_url
          : '/dealer/dashboard';
      props.history.replace(path);
      // props.history.replace('/settings');
      return;
    }
    if (userData.data.meta) {
      if (userData.data.type === 'homeowner') {
        if (userData.data.meta.applications_count === 0) {
          props.history.replace('/applications/onboarding');
          return;
        }
        props.history.replace('/dashboard');
      }
    }
  };
  const initialState = getToken();
  if (initialState) {
    redirectLogic(JSON.parse(initialState));
  }
  const handleSignIn = async (values, setSubmitting, setErrors) => {
    const payload = {
      email: values.email,
      password: values.password,
    };
    try {
      fieldsToReset.map((item) => {
        props.updateData({
          name: item,
          value: '',
        });
        return '';
      });
      const { data } = await http.post(
        `${process.env.REACT_APP_BASE_URL}/auth/user/sign_in`,
        payload,
      );
      const userData = { ...data, login: getTime(new Date()) };
      setToken(JSON.stringify(userData));
      setUser(JSON.stringify(userData));

      if (
        userData.data.attributes.need_change_password ||
        sessionStorage.getItem('passwordExpiry') === 'true'
      ) {
        sessionStorage.setItem('passwordExpiry', 'true');
        props.history.replace('/settings');
        setErrorAlertMessage(
          'Your password has expired. Please change your password.',
        );
        return;
      }
      sessionStorage.setItem('passwordExpiry', 'false');
      let newWflow = false;
      const [err, res] = await to(protectedGet(`/v1/settings`));
      if (err) {
        return;
      }
      if (res) {
        res.data.data.map((featureFlags) => {
          if (featureFlags?.attributes?.key === 'feature.new_app_workflow') {
            if (featureFlags?.attributes?.value) {
              newWflow = true;
            }
          }
          if (featureFlags?.attributes?.key === 'feature.maximum_request_amount') {
            props.updateData({
              name: "maximumRequestAmount",
              value: featureFlags?.attributes?.value,
            });
           
          }
          if (featureFlags?.attributes?.key === 'feature.minimum_income_amount') {
            props.updateData({
              name: "minimumIncomeAmount",
              value: featureFlags?.attributes?.value,
            });
          }
          return '';
        });
        if (newWflow) {
          sessionStorage.setItem('flow', 'new');
        } else {
          sessionStorage.setItem('flow', 'new');
        }
      }

      if (redirectPath) {
        props.history.replace(redirectPath);
        removeRedirectPage();
        return;
      }
      redirectLogic(userData);
    } catch (err) {
      setErrors({
        password:
          err?.response?.data?.errors[0] ||
          'Oops! an error occurred, please try again in a moment',
      });
      // if (err?.message?.includes('500')) {
      //   setErrors({
      //     password: 'Oops! an error occurred, please try again in a moment',
      //   });
      // } else if (
      //   err?.response?.data?.errors[0]?.includes(
      //     'Invalid login credentials. Please try again.',
      //   )
      // ) {
      //   setErrors({ password: 'Incorrect Email or password' });
      // } else if (err?.message?.includes('401')) {
      //   setErrors({
      //     password:
      //       'There is a problem with your account, contact PowerPay support team',
      //   });
      // } else {
      //   setErrors({
      //     password: 'Oops! an error occurred, please try again in a moment',
      //   });
      // }
    }
    setSubmitting(false);
  };

  const togglePasswordReveal = () => {
    if (showPassword === 'password') {
      setShowPassword('text');
    } else {
      setShowPassword('password');
    }
    return showPassword;
  };
  return (
    <div className={classes.contentWrapper}>
      <Header />
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          handleSignIn(values, setSubmitting, setErrors);
        }}
      >
        {({ isSubmitting, errors, values, touched, setFieldValue }) =>
          isSubmitting ? (
            <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
          ) : (
            <Form className={classes.form}>
              <div className={classes.formTitle}>
                <Text
                  textVariant="h5"
                  textColor="#000000"
                  textFontWeight={600}
                  textFontSize={22}
                  textLetterSpacing={0.5}
                  textLineHeight="28px"
                  text="It’s nice to see you again!"
                />
                <Typography gutterBottom className={classes.formText}>
                  Don&apos;t have an account?{' '}
                  {/* <LinkText
                    variant="subtitle1"
                    // link="/signup"
                    link="/apply"
                    text="Apply Now"
                    textFontSize="12px"
                    textFontWeight={600}
                    textLineHeight="16px"
                    textLetterSpacing={0.6}
                  /> */}
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <LinkText
                      variant="subtitle1"
                      // link="/signup"
                      link="/dealer/signup"
                      text="Enroll as a Merchant"
                      textFontSize="12px"
                      textFontWeight={600}
                      textLineHeight="16px"
                      textLetterSpacing={0.6}
                    />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    <LinkText
                      variant="subtitle1"
                      // link="/signup"

                      link="/apply"
                      text="Apply For a Loan"
                      textFontSize="12px"
                      textFontWeight={600}
                      textLineHeight="16px"
                      textLetterSpacing={0.6}
                    />
                  </Grid>
                </Grid>
              </div>

              <TrimField
                name="email"
                type="email"
                label="Email"
                setFieldValue={setFieldValue}
              />
              <PasswordField
                showPassword={showPassword}
                togglePasswordReveal={togglePasswordReveal}
                touched={touched}
                errors={errors}
                name="password"
                label="Password"
              />
              <div className={classes.forgotPassword}>
                <LinkText
                  variant="subtitle1"
                  link="/forgot-password"
                  text="Forgot your password?"
                  textFontSize="12px"
                  textFontWeight={600}
                  textLineHeight="16px"
                  textLetterSpacing={0.6}
                />
              </div>
              <ContainedButton
                className={classes.button}
                type="submit"
                text="SIGN IN"
                variant="contained"
                color="primary"
                isSubmitting={isSubmitting}
                disabledOptions={values}
                errors={errors}
              />
              <div className={classes.resendLink}>
                <LinkWithBackground
                  variant="subtitle1"
                  link="/resend-link"
                  text="Didn't receive your confirmation email?"
                  textFontSize="12px"
                  textFontWeight={600}
                  textLineHeight="16px"
                  textLetterSpacing={0.6}
                />
              </div>
            </Form>
          )
        }
      </Formik>
      <Footer />
    </div>
  );
};
const mapStatesToProps = (state) => {
  return { dealsData: state.appReducer };
};
export default connect(mapStatesToProps, {
  updateData,
})(SignIn);
