import React from 'react';
import { withStyles, Button } from '@material-ui/core';

const StyledButton = withStyles(() => ({
  root: {
    height: '2rem',
    minWidth: 'unset',
    padding: '0 1.75rem',
    borderRadius: '0.375rem',
    borderWidth: 2,
    marginTop: '1.25rem',
    marginBottom: '2rem',
    '&.MuiButton-containedPrimary .MuiButton-label': {
      color: 'white',
    },
  },
}))(Button);
export const ActiveButton = ({ isActive, children, ...props }) => {
  return (
    <StyledButton variant={isActive ? 'contained' : 'outlined'} {...props}>
      {children}
    </StyledButton>
  );
};
