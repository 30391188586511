import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Typography,
  Divider,
  Grid,
  Button,
} from '@material-ui/core';
import to from 'await-to-js';
import Info from '@material-ui/icons/Info';
import { protectedPut } from 'services/http';
import NoteCard from './NoteCard';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  importantInfo: {
    backgroundColor: '#F9FAFC',
    borderRadius: 4,
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0.5,
    marginBottom: 14,
    padding: 10,
  },
  importantInfoComplement: {
    color: '#1E272E',
    display: 'block',
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: 0.46,
  },
}));

const NotesList = ({
  notesData,
  applicationNumber,
  setFetchedData,
  fetchedData,
  setAlertMessage,
  setErrorAlertMessage,
  user,
  hasUnreadMessages,
  setHasUnreadMessages,
}) => {
  const [readAllMsgs, setReadAllMsgs] = useState(false);
  const readAllMsg = async () => {
    const [err] = await to(
      protectedPut(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/mark_all_notes_read `,
        {
          application: {
            read_by: user.data.id,
          },
        },
      ),
    );
    if (err) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      // return;
    }
    setFetchedData(!fetchedData);
    setReadAllMsgs(true);
    setAlertMessage('All Message marked read successfully');

    // setHasUnreadMessages(false);
    // setAlertMessage('Note Marked succesfully');
    // console.log(e);
  };
  useEffect(() => {
    setReadAllMsgs(hasUnreadMessages);
  }, [hasUnreadMessages]);
  const classes = useStyles();
  return (
    <>
      <div data-testid="noteslist-container">
        {notesData?.length >= 1 && readAllMsgs && (
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <Button
                style={{
                  color: 'orange',
                  textTransform: 'none',
                  float: 'right',
                }}
                onClick={readAllMsg}
              >
                Mark All as Read
              </Button>
            </Grid>
          </Grid>
        )}
        {notesData &&
          notesData.map((data, index) => (
            <NoteCard
              key={index}
              data={data}
              applicationNumber={applicationNumber}
              fetchedData={fetchedData}
              setFetchedData={setFetchedData}
              setAlertMessage={setAlertMessage}
              setErrorAlertMessage={setErrorAlertMessage}
              user={user}
            />
          ))}
      </div>

      <Divider className={classes.divider} />
      <Typography className={classes.importantInfo}>
        <Info style={{ marginRight: 7, color: '#9198A0', fontSize: 13 }} />
        Send Messages
        <span className={classes.importantInfoComplement}>
          Communicate with the PowerPay team. Messages are Visible to other team
          members.
        </span>
      </Typography>
      <Typography className={classes.importantInfo}>
        <Info style={{ marginRight: 7, color: '#9198A0', fontSize: 13 }} />
        Read Messages
        <span className={classes.importantInfoComplement}>
          Acknowledge messages by marking them read.
        </span>
      </Typography>
    </>
  );
};

export default NotesList;
