import React from 'react';

export default function index({ height, width }) {
  return (
    <svg
      width={width || '16px'}
      height={height || '16px'}
      viewBox="0 0 20 20"
      version="1.1"
    >
      <defs>
        <path
          d="M3.72 11.22a.75.75 0 0 1 .53-.22h9a.75.75 0 0 0 .75-.75v-7.5a.75.75 0 0 0-.75-.75H2.75a.75.75 0 0 0-.75.75v10.19l1.72-1.72zm.84 1.28l-2.78 2.78a.75.75 0 0 1-1.28-.53v-12A2.25 2.25 0 0 1 2.75.5h10.5a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25H4.56z"
          id="a"
        />
      </defs>
      <use fillOpacity=".4" fill="#1E272E" xlinkHref="#a" fillRule="evenodd" />
    </svg>
  );
}
