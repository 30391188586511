import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { Formik, Form, yupToFormErrors } from 'formik';

import to from 'await-to-js';
import { protectedGet, protectedPatch } from 'services/http';

import { addIndex, map, mergeDeepWith } from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { Grid, Card, CardContent, makeStyles, Box } from '@material-ui/core';
import { AppMarketingCarousel } from 'components/AppMarketingCarousel';
import { buildFormData } from 'services/utils';
import { parseApplicationObjToFormJson } from 'services/utils/parseApplicationObjToFormJson';
import { useHistory, useLocation } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';
import { useSessionExpirerContext } from 'components/SessionExpirerContext';
import { StepHeader } from './StepHeader';
import { Step2DriverLicense } from './Step2DriverLicense';
import { Step3PersonalInfo } from './Step3PersonalInfo';
import { Step4AddressInfo } from './Step4AddressInfo';
import { Step5Employment } from './Step5Employment';
import { Step6LoanAmount } from './Step6LoanAmount';
import { Step7LegalTerms } from './Step7LegalTerms';
import { CoApplicantStep } from './CoApplicantStep';
import { SaveDraftButton } from './SaveDraftButton';
import { newApplication } from './helpers';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0.5rem',
    [theme.breakpoints.up('md')]: {
      padding: '4rem',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '4rem 8rem 4rem 4rem',
    },
  },
  card: {
    position: 'relative',
  },
  stepContainer: {
    overflow: 'hidden',
    padding: '1rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: '2rem',
      paddingRight: 0,
    },
  },
  stepper: {
    flexGrow: 1,
    background: 'transparent',
    padding: '8px 0',
  },
  stepForm: {
    minHeight: '32rem',
  },
  hide: { height: 0 },
  stepButton: {
    fontWeight: '600',
    fontSize: '0.975rem',
  },
}));

const applicationStateMerger = (state, res) => {
  const merger = (left, right) => {
    if (left === undefined || left === null) {
      return right;
    }

    if (Array.isArray(left)) {
      // financial Details
      return [
        mergeDeepWith(merger, left[0], right[0]),
        mergeDeepWith(merger, left[1], right[1]),
      ];
    }
    return left;
  };
  return mergeDeepWith(merger, res, state);
};

const setSteps = (values, { activeSteps, loaded, setActiveSteps }) => {
  if (loaded) {
    if (!activeSteps?.length) {
      const fixedSteps = [
        {
          component: (props) => <Step3PersonalInfo {...props} />,
        },
        {
          component: (props) => <Step4AddressInfo {...props} />,
        },
        {
          component: (props) => <Step5Employment {...props} />,
          title: 'Finances & Employment',
          subtitle: 'Provide employment information',
        },
        {
          component: (props) => <CoApplicantStep {...props} />,
          title: 'Co-Applicant',
          subtitle:
            'Applying for a loan with a co-applicant can help to improve the chances of loan approval',
        },
        {
          component: (props) => <Step6LoanAmount {...props} />,
          title: 'Loan Request',
          subtitle: 'Let us know  your financial expectations',
        },
        {
          component: (props) => <Step7LegalTerms {...props} />,
          title: 'Submit Application',
          subtitle: 'Read our Legal Terms and Conditions',
        },
      ];

      setActiveSteps(
        values.application.driverLicense?.url ||
          values.application.driverLicense?.uuid
          ? fixedSteps
          : [
              {
                component: (props) => (
                  <Step2DriverLicense
                    appId={values?.applicationId}
                    {...props}
                  />
                ),
              },
              ...fixedSteps,
            ],
      );
    }
  }
};

export const NewApplication = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { setSessionExpiredCallback } = useSessionExpirerContext();
  useEffect(() => {
    return () => {
      setSessionExpiredCallback(null);
    };
  }, []);

  //
  const [childCallbacks, setChildCallbacks] = useState({});
  const setChildCallbacksFn = (callbacks) =>
    setChildCallbacks(() => ({ ...callbacks }));

  // ///
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useUserContext();
  const isDealer = user.data.type === 'dealer';

  const { applicationId } = location.state || {};

  const [initialApplication, setInitialApplication] = useState(newApplication);

  const sendRequest = async (values, setSubmitting, submit = false) => {
    const formData = new FormData();
    const valuesNoFiles = {
      ...values,
      application: {
        ...values.application,
        addressAttributes: {
          ...values.application.addressAttributes,
          addressLine_2: values.application.addressAttributes.addressLine2,
        },
        driverLicense: null,
        coapplicantDriverLicense: null,
      },
      financialDetails: { ...values.financialDetails },
    };
    if (!valuesNoFiles.hasCoapplicant) {
      delete valuesNoFiles.financialDetails[1];
    }
    const decamelized = decamelizeKeys(valuesNoFiles);

    if (values.application.driverLicense instanceof File) {
      decamelized.application.driver_license = values.application.driverLicense;
    }
    if (values.application.coapplicantDriverLicense instanceof File) {
      decamelized.application.coapplicant_driver_license =
        values.application.coapplicantDriverLicense;
    }
    buildFormData(formData, decamelized);

    setSubmitting(true);
    const [err, r] = await to(
      protectedPatch(
        `/v1/applications/${applicationId}${submit ? '/submit' : ''}`,
        formData,
      ),
    );
    setSubmitting(false);
    if (err && err.response) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return false;
    }
    return r;
  };
  const onSubmit = async (values, { setSubmitting }) => {
    const sendValues = { ...values };
    if (user.data.type === 'homeowner') {
      sendValues.financialDetails[0].term = null;
    }
    const r = await sendRequest(values, setSubmitting, true);

    if (r) {
      const res = normalize(camelizeKeys(r.data)).get(['id', 'status']);
      setAlertMessage('Application submitted successfully');
      history.replace('/applications/confirmation', { ...res });
    }
  };
  const onSaveDraft = async (
    values,
    { setSubmitting },
    ignoreAlert = false,
  ) => {
    let lastStep = activeStep;
    const hasLicenseStep = activeSteps.length === 7;
    if (hasLicenseStep) {
      if (values.driverLicense?.uuid) {
        if (lastStep > 0) {
          lastStep--;
        }
      }
    }
    const r = await sendRequest(
      {
        ...values,
        application: { ...values.application, lastStep },
      },
      setSubmitting,
    );
    const app = parseApplicationObjToFormJson(camelizeKeys(r.data));
    setInitialApplication((state) => applicationStateMerger(state, app));
    if (!ignoreAlert) {
      if (r) {
        setAlertMessage('Your application draft has been saved');
      }
    }
    const test = childCallbacks?.onSaveDraft?.();
  };

  const [loaded, setLoaded] = useState(false);
  const [activeSteps, setActiveSteps] = useState([]);
  useEffect(() => {
    const fetch = async () => {
      const [err, application] = await to(
        protectedGet(`/v1/applications/${applicationId}`),
      );

      if (err) {
        return;
      }
      const res = parseApplicationObjToFormJson(camelizeKeys(application.data));
      setInitialApplication((state) => applicationStateMerger(state, res));
      setLoaded(true);
      setActiveStep(Math.max(res.lastStep || 0, 0));
    };
    if (applicationId) {
      fetch();
    } else {
      setErrorAlertMessage('Unauthorized Access');
      history.replace('/dashboard');
    }
  }, [applicationId]);
  return (
    <Grid container className={classes.container} spacing={3}>
      <Grid item xs={12}>
        <AppMarketingCarousel />
      </Grid>
      <Grid item xs={12}>
        <Card elevation={4} className={classes.card}>
          <CardContent>
            <Formik
              initialValues={initialApplication}
              validationSchema={null}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({
                values,
                setErrors,
                setSubmitting,
                handleSubmit,
                isSubmitting,
              }) => {
                setSteps(
                  { ...values, applicationId },
                  {
                    activeSteps,
                    loaded,
                    setActiveSteps,
                  },
                );
                setSessionExpiredCallback(() =>
                  onSaveDraft(values, { setSubmitting }),
                );
                const handleNext = async () => {
                  const onNext =
                    !childCallbacks.onNext || (await childCallbacks.onNext());
                  if (onNext) {
                    if (childCallbacks.validationSchema) {
                      const [validationErrors] = await to(
                        childCallbacks.validationSchema.validate(values, {
                          abortEarly: false,
                        }),
                      );
                      if (validationErrors) {
                        setErrors(yupToFormErrors(validationErrors));
                        return;
                      }
                    }
                    onSaveDraft(values, { setSubmitting }, true);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                };
                const handleBack = () => {
                  // if (!childCallbacks.onBack || childCallbacks.onBack()) {
                  onSaveDraft(values, { setSubmitting }, true);
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                  // }
                };
                return (
                  <>
                    <SaveDraftButton
                      onClick={() => {
                        onSaveDraft(values, { setSubmitting });
                      }}
                    />

                    <Form>
                      <Box className={clsx(classes.stepForm, 'form-step')}>
                        <SwipeableViews
                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                          index={activeStep}
                          disabled
                        >
                          {addIndex(map)(
                            (step, index) => (
                              <Box
                                className={clsx(classes.stepContainer, {
                                  [classes.hide]: index !== activeStep,
                                })}
                                key={index}
                              >
                                <StepHeader
                                  step={index + 1}
                                  title={step.title || childCallbacks.stepTitle}
                                  subtitle={
                                    step.subtitle || childCallbacks.stepSubtitle
                                  }
                                />
                                {step.component({
                                  isActive: index === activeStep,
                                  setChildCallbacks: setChildCallbacksFn,
                                  isFirst: index === 0,
                                  isDealer,
                                  onApplicationonSubmit: handleSubmit,
                                })}
                              </Box>
                            ),
                            activeSteps,
                          )}
                        </SwipeableViews>
                      </Box>
                      <MobileStepper
                        variant="progress"
                        steps={activeSteps.length}
                        position="static"
                        activeStep={activeStep}
                        className={classes.stepper}
                        nextButton={
                          <Button
                            className={classes.stepButton}
                            onClick={handleNext}
                            disabled={
                              isSubmitting ||
                              activeStep === activeSteps.length - 1
                            }
                            color="primary"
                          >
                            Next
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowLeft />
                            ) : (
                              <KeyboardArrowRight />
                            )}
                          </Button>
                        }
                        backButton={
                          <Button
                            className={classes.stepButton}
                            onClick={handleBack}
                            disabled={
                              isSubmitting ||
                              (childCallbacks.canBack
                                ? !childCallbacks.canBack()
                                : activeStep === 0)
                            }
                          >
                            {theme.direction === 'rtl' ? (
                              <KeyboardArrowRight />
                            ) : (
                              <KeyboardArrowLeft />
                            )}
                            Back
                          </Button>
                        }
                        LinearProgressProps={{ color: 'secondary' }}
                      />
                    </Form>
                  </>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
