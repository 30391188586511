import React, { useState } from 'react';
import IMask from 'imask';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PlaidLink from 'components/PlaidLink';
import { postPlaidPublicToken } from 'services/api/plaid';

import PlaidInfoCard from 'components/PlaidInfoCard';
import { Box, Hidden, FormControl, FormHelperText } from '@material-ui/core';
import { MaterialCarousel } from 'components/MaterialCarousel';
import { useAlertContext } from 'components/AlertContext';
import { useField } from 'formik';
import { MaskedField } from 'components/InputFields/SsnField';
import { ConfirmationDialog } from 'components/ConfirmationDialog';

// you can use any step to guide you on how to add stuff if you need it
const useStyles = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    marginTop: '1.25rem',
    [theme.breakpoints.up('md')]: {
      padding: '0',
      marginTop: '2rem',
    },
  },
  cardsContainer: {
    marginBottom: '0rem',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0.75rem',
    },
  },
}));

export const routingNumberPipe = IMask.createPipe({
  mask: '000000000',
});

export const accountNumberPipe = IMask.createPipe({
  mask:
    '0000000000000000000000000000000000000000000000000000000000000000000000',
});

const StepPlaid = ({ plaidCardsItems, onSaveDraft, displayVerify, ...props }) => {
  const [isResponseSuccesful, setIsResponseSuccesful] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const classes = useStyles();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const [field, meta, helpers] = useField({ name: 'isIncomeVerified' });

  const handlePlaidOnSuccess = async (token) => {
    const [error] = await postPlaidPublicToken(token);
    if (error && error.response) {
      // setErrorAlertMessage(error.response.data.message || 'Error');
      setShowConfirm(true);
      return;
    }
    // setAlertMessage('Income verified successfully');
    setShowConfirm(true);
    helpers.setValue(true);
  };

  const handlePlaidOnOpen = () => onSaveDraft();

  return (
    <>

      <ConfirmationDialog
        desc="The process is complete.  PowerPay will be contacting you in 3-5 business days with the status of your enrollment application!"
        dialogState={showConfirm}
        handleClose={() => { setShowConfirm(false) }}
        infoPopup
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.container}
      >
        <Hidden smDown>
          <Grid
            container
            spacing={8}
            justify="center"
            className={classes.cardsContainer}
          >
            {plaidCardsItems.map((item) => (
              <Grid key={item.title} item xs={12} md={4}>
                <PlaidInfoCard item={item} />
              </Grid>
            ))}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <MaterialCarousel
            darkIndicator
            items={plaidCardsItems.map((item) => (
              <PlaidInfoCard key={item.title} isCarousel item={item} />
            ))}
          />
        </Hidden>
        {!displayVerify &&
          <Grid
            container
            spacing={1}
            justify="center"
            className={classes.cardsContainer}
          >
            <Grid item xs={12} md={5}>
              <MaskedField
                label="Routing Number"
                name="companyDetails.bankRoutingNumber"
                pipeFn={routingNumberPipe}
                hideFn={(e) => e}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <MaskedField
                label="Account Number"
                name="companyDetails.bankAccountNumber"
                pipeFn={accountNumberPipe}
                hideFn={(e) => e}
              />
            </Grid>
          </Grid>
        }
        {
          displayVerify &&
          <FormControl error={meta.error}>
            <PlaidLink
              onSuccess={handlePlaidOnSuccess}
              onOpen={handlePlaidOnOpen} {...props} />
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        }
      </Box>
    </>
  );
};

export default StepPlaid;
