import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import SuccessMessage from 'components/SuccessMessage';
import Text from "components/TextFields/Text";
import Button from "@material-ui/core/Button";
import {useAlertContext} from "components/AlertContext";
import http from "services/http";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formTitle: {
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 56,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
}));

const Resubscribe = ({ location }) => {
  const classes = useStyles();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();
  const resubscribe = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('resubscribe_notification_token');
      await http.post(
        `${process.env.REACT_APP_BASE_URL}/v1/email_subscriptions/resubscribe_notifications?token=${token}`,
      );
      setAlertMessage('Successfully resubscribed to PowerPay messages.');
    } catch (err) {
      console.log(err);
      setErrorAlertMessage(
        err?.response?.data?.message ||
        'Error resubscribing, please try again later.',
      );
    }

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  }

  return (
    <div className={classes.contentWrapper}>
      <Header />
      <SuccessMessage text="You have successfully been unsubscribed from PowerPay messages" />
      <Button
        style={{
          width: 'auto',
        }}
        variant="contained"
        color="primary"
        className={classes.button}
        type="button"
        onClick={resubscribe}
      >
        <Text
          textSize="md"
          bold
          style={{ display: 'flex', alignItems: 'center' }}
          textColor="white"
        >
          Resubscribe
        </Text>
      </Button>
      <Footer />
    </div>
  );
};

export default Resubscribe;
