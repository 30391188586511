import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

import Text from 'components/TextFields/Text';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  sectionTitle: {
    marginBottom: 24,
  },
  subsection: {
    marginTop: 24,
    marginBottom: 24,
  },
  subsectionItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subsectionItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 16,
  },
  dataItem: {
    color: '#1E272E',
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.65,
    lineHeight: '18px',
  },
}));

const Loan = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.sectionTitle}>
        <Text
          textColor="#1E272E"
          textFontSize={16}
          textFontWeight={600}
          textLetterSpacing={0.8}
          textLineHeight="16px"
          textVariant="subtitle1"
          text="LOAN REQUEST"
        />
      </div>
      <div className={classes.subsection}>
        <Text
          textColor="#9198A0"
          textFontSize={10}
          textFontWeight={600}
          textLetterSpacing={0.46}
          textLineHeight="12px"
          textVariant="subtitle1"
          text="LOAN DETAILS"
        />
        <div className={classes.subsectionItems}>
          <Typography className={classes.subsectionItem}>
            Estimated Project Amount
            <NumberFormat
              className={classes.dataItem}
              value={data.amountRequested}
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default Loan;
