import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { makeStyles, Grid } from '@material-ui/core';
import { CancelAppDialog } from 'components/CancelAppDialog';
import { useAlertContext } from 'components/AlertContext';
import Details from './Details';
import Sidebar from './Sidebar';
import { protectedGet, protectedPatch } from '../../../services/http';
import Loader from '../../../components/Loaders';
import { parseApplication } from '../../../services/utils/parseApplication';
import { useUserContext } from '../../../services/hooks/useUser';
import { parseLoan } from '../../../services/utils/parseLoan';

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    height: '100%',
    padding: '36px 24px',
    width: '100%',
  },
});

const ApplicationDetail = ({ match, history }) => {
  const applicationNumber = match.params.id;
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const classes = useStyles();
  const { user, userType } = useUserContext();
  const [showLoader, setShowLoader] = useState(false);

  const [open, setOpen] = useState(false);
  const [applicationDetail, setApplicationDetail] = useState(null);
  const [loanDetails, setLoanDetails] = useState(null);
  const [detailTab, setDetailTab] = useState(
    loanDetails &&
      (loanDetails.status === 'approved' ||
        loanDetails.status === 'in_progress' ||
        loanDetails.status === 'completed')
      ? 1
      : 0,
  );
  const [hasLoan, setHasLoan] = useState(false);
  const [fetchedData, setFetchedData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSelectedDeal, setHasSelectedDeal] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = async () => {
    try {
      const res = await protectedPatch(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/cancel`,
      );
      handleClose();
      const [application, formattedApp] = parseApplication(res.data);
      setAlertMessage('Application Canceled Succesfully');
      setApplicationDetail(formattedApp);
    } catch (e) {
      console.error(e.message);
    }
  };

  useEffect(() => {
    async function fetch() {
      const [err, res] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}`,
        ),
      );
      if (err) {
        console.error(err);
        return;
      }
     
      const dealId = res?.data?.data?.relationships?.deal?.data?.id;
      if (userType !== 'homeowner') {
        if (!dealId) {
          history.push(
            `/applications/${res?.data?.data?.attributes?.id}/review`,
          );
        } else {
          history.push(
            `/applications/${res?.data?.data?.attributes?.id}/complete`,
          );
        }
      } else {
        const [application, formattedApp] = parseApplication(res?.data);
        setApplicationDetail(formattedApp);
        setHasSelectedDeal(formattedApp?.included?.some(item => item.type === 'deal'));

        if (application?.loan?.id) {
          const [err2, res2] = await to(
            protectedGet(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationNumber}/loans/${application.loan.id}`,
            ),
          );
          if (err2) {
            console.error(err2);
            return;
          }
          setHasLoan(true);
          const [loan, formattedLoan] = parseLoan(res2.data);
          setLoanDetails(formattedLoan);
        }
      }
      setShowLoader(false);
      setFetchedData(true);

    }

    if (!fetchedData) {
    
      fetch();
      setIsLoading(false);
    }
  }, [isLoading, fetchedData]);

  const handleRender = (appDetails, lDetails) => {
    if (appDetails) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Details
              applicationNumber={applicationNumber}
              applicationDetail={appDetails}
              handleClickOpen={handleClickOpen}
              history={history}
              loanDetails={lDetails}
              detailTab={detailTab}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Sidebar
              hasSelectedDeal={hasSelectedDeal}
              applicationNumber={applicationNumber}
              documents={appDetails.applicationData}
              draftStatus={appDetails?.headerData?.draftStatus}
              signatureMethod={lDetails?.signatureMethod}
              loanContractSignatureMethods={
                appDetails?.loanContractSignatureMethods
              }
              fetchedData={fetchedData}
              isLoading={isLoading}
              loanId={lDetails?.id}
              openStipulations={appDetails?.applicationData?.openStipulationsHomeowner}
              closedStipulations={
                appDetails?.applicationData?.closedStipulationsHomeowner
              }
              notices={appDetails?.applicationData?.notices}
              projectStatus={lDetails && lDetails.status}
              setFetchedData={setFetchedData}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              setIsLoading={setIsLoading}
              processingLoanDocs={false}
            />
          </Grid>
        </Grid>
      );
    }
    return <Loader />;
  };

  return (
    <div className={classes.container}>
      <CancelAppDialog
        dialogState={open}
        handleClose={handleClose}
        handleCancel={handleCancel}
      />
      {handleRender(applicationDetail, loanDetails)}
    </div>
  );
};

export default ApplicationDetail;
