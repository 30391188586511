import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    borderStyle: 'solid',
    borderRadius: 6,
    borderWidth: 0.5,
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.6,
    lineHeight: '12px',
    padding: 6,
    paddingLeft: 12,
    paddingRight: 12,
    [theme.breakpoints.up('md')]: {
      marginLeft: 24,
    },
  },
}));

const StatusButton = ({ status, draftStatus }) => {
  const classes = useStyles();

  const statusButton = () => {
    let style;
    let label;
    if (draftStatus === 'canceled') {
      style = {
        color: '#FF7F50',
        borderColor: '#FF7F50',
        backgroundColor: '#FF475733',
      };
      label = draftStatus.toUpperCase();
    } else {
      switch (status) {
        case 'approved':
          style = {
            color: '#1DD1A1',
            borderColor: '#1DD1A1',
            backgroundColor: '#68FFD233',
          };
          label = status.toUpperCase();
          break;
        case 'pending':
        case 'needs_review':
          style = {
            color: '#FF7F50',
            borderColor: '#FF7F50',
            backgroundColor: '#FFB07E33',
          };
          label = 'NEEDS REVIEW';
          break;
        case 'rejected':
          style = {
            color: '#FF7F50',
            borderColor: '#FF7F50',
            backgroundColor: '#FF475733',
          };
          label = 'DECLINED';
          break;
        case 'in_progress':
          style = {
            color: '#70A1FF',
            borderColor: '#70A1FF',
            backgroundColor: '#70A1FF33',
          };
          label = ' DOCS RECEIVED';
          break;
        case 'completed':
          style = {
            color: '#1DD1A1',
            borderColor: '#1DD1A1',
            backgroundColor: '#68FFD233',
          };
          label = status.toUpperCase();
          break;
        case 'conditional_approval':
          style = {
            color: '#FFB31A',
            borderColor: '#FFCE6C',
            backgroundColor: '#FFFBF3',
          };
          label = 'CONDITIONAL APPROVAL';
          break;
        case 'transferred':
          style = {
            color: '#393F47',
            borderColor: '#393F47',
            backgroundColor: '#393F4733',
          };
          label = status.toUpperCase();
          break;
        default:
          style = {
            color: '#393F47',
            borderColor: '#393F47',
            backgroundColor: '#393F4733',
          };
          label = status.toUpperCase();
          break;
      }
    }

    return (
      <Button className={classes.button} style={style}>
        {label}
      </Button>
    );
  };

  return <>{statusButton()}</>;
};

export default StatusButton;
