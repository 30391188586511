import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import to from 'await-to-js';
import { Formik, Form } from 'formik';
import { protectedPost } from 'services/http';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { mergeDeepWith } from 'ramda';

import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Text from 'components/TextFields/Text';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { StateSelectField } from 'components/InputFields/StateSelectField';
import { useAlertContext } from 'components/AlertContext';

import { merger } from 'services/utils';

const useStyles = makeStyles(() => {});

export const PrequalConfirmForm = ({
  token,
  prequalification,
  setPrequalification,
  setSelectedFlow,
}) => {
  const classes = useStyles();
  const { setErrorAlertMessage } = useAlertContext();

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const [err, r] = await to(
      protectedPost(
        `/v1/socure_proxies/confirm_soft_pull`,
        decamelizeKeys({ token, ...values }),
      ),
    );
    if (err && err.response) {
      setErrorAlertMessage(err.response.data.message || 'Error');
      return false;
    }
    setPrequalification(state => ({
      ...state,
      id: r.data.data.id,
    }));
    setSelectedFlow('waitingForPrequal');
  };

  const [initialValues, setInitialValues] = useState({
    name: '',
    lastName: '',
    houseNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    birthday: '',
    driverLicenseUid: '',
  });
  useEffect(() => {
    setInitialValues(state => {
      return mergeDeepWith(merger, prequalification, state);
    });
  }, [prequalification]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Text textSize="md" semibold>
                  Verify your information before submitting this
                  prequalification.
                </Text>
              </Grid>

              <Grid item xs={12} md={6}>
                <OutlinedTextField label="First Name" name="name" />
              </Grid>

              <Grid item xs={12} md={6}>
                <OutlinedTextField label="Last Name" name="lastName" />
              </Grid>

              <Grid item xs={12} md={6}>
                <OutlinedTextField
                  label="Address Street Number"
                  name="houseNumber"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <OutlinedTextField label="Address Street Name" name="address" />
              </Grid>

              <Grid item xs={12} md={6}>
                <OutlinedTextField label="City" name="city" />
              </Grid>

              <Grid item xs={12} md={6}>
                <StateSelectField label="State" name="state" />
              </Grid>

              <Grid item xs={12} md={6}>
                <OutlinedTextField label="Zip Code" name="zipCode" />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" marginTop="3rem">
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    <Text textSize="md" textColor="white" bold>
                      SUBMIT
                    </Text>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const validationSchema = Yup.object({
  name: Yup.string()
    .required('Field Required')
    .trim(),
  lastName: Yup.string()
    .required('Field Required')
    .trim(),
  houseNumber: Yup.string()
    .required('Field Required')
    .trim(),
  address: Yup.string()
    .required('Field Required')
    .trim(),
  city: Yup.string()
    .required('Field Required')
    .trim(),
  state: Yup.string()
    .required('Field Required')
    .trim(),
  zipCode: Yup.string()
    .required('Field Required')
    .trim(),
});
