import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import IMask from 'imask';

import { makeStyles } from '@material-ui/core/styles';
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { useField } from 'formik';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },

    '& .MuiFormLabel-root.MuiInputLabel-shrink': {
      padding: '0 0.25rem',
      background: 'white',
    },
  },
}));

export const phoneMaskPipe = IMask.createPipe({
  mask: [
    {
      mask: '(000)000-0000',
    },
    // {
    //   mask: '[+]0(000)000-00000',
    // },
  ],
});

export const PhoneNumberField = ({
  id,
  name,
  label,
  disabled,
  hideErrors = false,
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name, type: 'tel' });
  const [currentValue, setCurrentValue] = useState(field.value);
  useEffect(() => {
    if (field.value !== currentValue) {
      setCurrentValue(field.value);
    }
  }, [field.value]);

  if (!id) {
    console.warn('no id for input');
  }

  return (
    <FormControl
      className={clsx(classes.formControl)}
      variant="outlined"
      error={Boolean(meta.error)}
    >
      <InputLabel variant="outlined" htmlFor={id}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        type="tel"
        value={currentValue}
        disabled={disabled}
        onChange={ev => {
          /* setCurrentValue(phoneMaskPipe(ev.currentTarget.value)); */
          setCurrentValue(phoneMaskPipe(ev.currentTarget.value));
        }}
        inputProps={{
          onBlur: () => {
            helpers.setValue(currentValue);
          },
        }}
      />
      {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
