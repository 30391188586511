import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';

import SettingsSectionFormGroup from 'components/SettingsSection/SettingsSectionFormGroup';
import { SelectField } from 'components/InputFields/SelectField';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: '100%',
  },
  businessInformationContainer: {
    marginBottom: '2.75rem',
  },
}));

const BusinessInformation = ({ userData }) => {
  const classes = useStyles();

  return (
    <SettingsSectionFormGroup
      spacing={3}
      className={classes.businessInformationContainer}
      title="Business Information"
      description="If you want to update any field please contact PowerPay at 800-397-4485"
    >
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="Business Name"
          type="text"
          name="businessName"
          value={(userData && userData.business.businessName) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          select
          variant="outlined"
          className={classes.inputField}
          disabled
          label="Annual Revenue"
          name="annualRevenue"
          type="text"
          value={(userData && userData.business.annualRevenue) || ''}
        >
          <MenuItem value="under_2">Under 2 million</MenuItem>
          <MenuItem value="from_2_to_5">From 2 to 5 million</MenuItem>
          <MenuItem value="from_5_to_10">From 5 to 10 million</MenuItem>
          <MenuItem value="over_10">Over 10 million</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="Legal Business Name"
          type="text"
          name="legalBusinessName"
          value={(userData && userData.business.legalBusinessName) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          variant="outlined"
          disabled
          label="EIN / SSN"
          type="number"
          name="EINOrSSN"
          value={(userData && userData.business.einSssnn) || ''}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          className={classes.inputField}
          select
          variant="outlined"
          disabled
          label="Type of Company"
          name="companyType"
          type="text"
          value={(userData && userData.business.typeOfCompany) || ''}
        >
          <MenuItem value="private_business"> Private</MenuItem>
          <MenuItem value="public_business">Public</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <PhoneNumberField
          name="businessPhone"
          label="Business Phone"
          id="phoneNumber-input"
        />
      </Grid>
    </SettingsSectionFormGroup>
  );
};

export default BusinessInformation;
