import React from 'react';
import { Route } from 'react-router-dom';
import { PublicRouteLayout } from './PublicRouteLayout';

export default function PublicRoute({
  path,
  title,
  component: Component,
  exact,
  headerActions: HeaderActions,
  ...rest
}) {
  return (
    <Route
      path={path}
      exact={exact}
      strict
      render={props => (
        <PublicRouteLayout {...rest}>
          <Component {...props} />
        </PublicRouteLayout>
      )}
    />
  );
}
