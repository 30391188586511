import React from 'react';
import {
  PhoneNumberField,
  EmailField,
  TextField,
  SSNField,
  DateField,
  Select,
  CurrencyField,
  ZipCodeField,
  formatNumberToCurrencyString
} from './../CustomizedMUIInputs';
import {getSSNCustomError} from "pages/NewApplicationDealer/NewApplicationFormSections/ApplicantSection";

export const CoApplicantSection = ({
  applicant,
  coapplicant,
  setCoapplicant,
  removeCoApplicant,
  isLoading,
  showAllErrors,
  isValidIncomeAmount,
  minIncomeAmount,
  highlightEmail,
  reapplyId,
}) => {
  const highlightedEmailHelperText = highlightEmail
    ? `This email appears to be invalid. You can still submit the application if you'd like.`
    : ""

  return (
    <div className="form-coapplicants-information-section form-card">
      <h2>Coapplicant's Information</h2>
      <button
        className="new-application-form-remove-field"
        onClick={() => removeCoApplicant()}
        type="button"
      >
        Remove Coapplicant
      </button>
      <p className="form-coapplicants-information-warning">
        All Coapplicants must be <strong>US Citizens</strong>
      </p>
      <div className="form-coapplicants-information-section-grid">
        <TextField
          value={coapplicant.firstName}
          onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  firstName: newValue }))}
          label="First Name"
          id="coapplicant-first-name"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <TextField
          value={coapplicant.lastName}
          onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  lastName: newValue }))}
          label="Last Name"
          id="coapplicant-last-name"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <PhoneNumberField
          value={coapplicant.phoneNumber}
          onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  phoneNumber: newValue }))}
          label="Mobile Phone Number"
          id="coapplicant-phone-number"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        <EmailField
          value={coapplicant.email}
          onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  email: newValue }))}
          label="Email"
          id="coapplicant-email"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          highlight={highlightEmail}
          helperText={highlightedEmailHelperText}
          required
        />
        <SSNField
          value={coapplicant.ssn}
          onChange={(newValue) => {
            setCoapplicant(oldValue => ({...oldValue, ssn: newValue.ssn, unmaskedSSN: newValue.unmaskedSSN }));
          }}
          label="SSN"
          id="coapplicant-ssn"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
          customError={getSSNCustomError({applicant, coapplicant, reapplyId, isApplicant: false, isConfirmation: false})}
        />
        <DateField
          value={coapplicant.birthday}
          onChange={(newValue) => {
            setCoapplicant(oldValue => ({...oldValue,  birthday: newValue }));
          }}
          label="Date of Birth"
          id="coapplicant-birthday"
          keyboardButtonAriaLabel="Pick a Date of Birth"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          maximumDate={new Date()} // new Date() means today
          required
          customError={!reapplyId && {
            errorMessage: 'Date Fields do not match',
            validationFunction: (value) => {
              return value === coapplicant.birthdayConfirmation
            },
          }}
        />
        {!reapplyId && (<SSNField
          value={coapplicant.ssnConfirmation}
          onChange={(newValue) => {
            setCoapplicant(oldValue => ({
              ...oldValue,
              ssnConfirmation: newValue.ssn,
              unmaskedSSNConfirmation: newValue.unmaskedSSN
            }));
          }}
          label="SSN Confirmation"
          id="coapplicant-ssn-confirmation"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
          customError={getSSNCustomError({applicant, coapplicant, reapplyId, isApplicant: false, isConfirmation: true})}
        />)}
        {!reapplyId && (<DateField
          value={coapplicant.birthdayConfirmation}
          onChange={(newValue) => {
            setCoapplicant(oldValue => ({...oldValue, birthdayConfirmation: newValue}));
          }}
          label="Date of Birth Confirmation"
          id="coapplicant-birthday-confirmation"
          keyboardButtonAriaLabel="Pick a Date of Birth"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          maximumDate={new Date()} // new Date() means today
          required
          customError={{
            errorMessage: 'Date Fields do not match',
            validationFunction: (value) => {
              return value === coapplicant.birthday
            },
          }}
        />)}
        <p className="form-coapplicants-information-text-span">
          Add Coapplicant's employment and income information
        </p>
        <Select
          value={coapplicant.employmentType}
          label="Employment Type"
          onChange={(newValue) => {
            setCoapplicant(oldValue => ({...oldValue,  employmentType: newValue }));
          }}
          options={[
            {
              label: 'Employed',
              value: 'employed',
              id: 'employed-option-coaplicant',
            },
            {
              label: 'Self Employed',
              value: 'self_employed',
              id: 'self-employed-option-coaplicant',
            },
            {
              label: 'Retired',
              value: 'retired',
              id: 'retired-option-coaplicant',
            },
          ]}
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          required
        />
        {['employed', 'self_employed'].includes(coapplicant.employmentType) && (
          <TextField
            value={coapplicant.occupation}
            onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  occupation: newValue }))}
            label="Occupation"
            id="coapplicant-occupation"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        {['employed', 'self_employed'].includes(coapplicant.employmentType) && (
          <TextField
            value={coapplicant.employerName}
            onChange={(newValue) =>
              setCoapplicant(oldValue => ({...oldValue,  employerName: newValue }))
            }
            label="Employer Name"
            id="coapplicant-employer-name"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        {['employed', 'self_employed'].includes(coapplicant.employmentType) && (
          <ZipCodeField
            value={coapplicant.employerZipCode}
            onChange={(newValue) =>
              setCoapplicant(oldValue => ({...oldValue,  employerZipCode: newValue }))
            }
            label="Employer's Zip Code"
            id="coapplicant-employer-zip-code"
            disabled={isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
        )}
        <CurrencyField
          value={coapplicant.annualIncome}
          onChange={(newValue) => setCoapplicant(oldValue => ({...oldValue,  annualIncome: newValue }))}
          label="Coapplicant's Annual Income (USD)"
          id="coapplicant-annual-income"
          disabled={isLoading}
          showErrorsWhileClean={showAllErrors}
          customError={
            isValidIncomeAmount && {
              validationFunction: (value) => isValidIncomeAmount(value),
              errorMessage: `Total income between applicant and coapplicant must be above ${formatNumberToCurrencyString(
                minIncomeAmount,
              )}`,
            }
          }
          required={minIncomeAmount === 0}
        />
      </div>
    </div>
  );
};
