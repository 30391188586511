import React from 'react';
import clsx from 'clsx';

import { makeStyles, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  size: ({ textSize }) => getTextSize(textSize),
  fontWeight: ({ bold, semibold }) => {
    let fontWeight;
    if (bold) {
      fontWeight = 600;
    }
    if (semibold) {
      fontWeight = 500;
    }
    return { fontWeight };
  },
  overflow: {
    overflow: ({ overflow }) => (overflow ? 'hidden' : ''),
  },
  textOverflow: {
    textOverflow: ({ ellipsis }) => (ellipsis ? 'ellipsis' : 'clip'),
  },
}));

const getTextSize = size => {
  switch (size) {
    case 'mini':
      return {
        fontSize: '0.625rem',
        letterSpacing: '0.46px',
        lineHeight: '1.125rem',
      };
    case 'xs':
      return {
        fontSize: '0.75rem',
        letterSpacing: '0.6px',
        lineHeight: '1rem',
      };
    case 'xs+':
      return {
        fontSize: '0.8125rem',
        letterSpacing: '0.22px',
        lineHeight: '0.875rem',
      };
    case 'sm':
      return {
        fontSize: '0.875rem',
        letterSpacing: '0.65px',
        lineHeight: '1.125rem',
      };
    case 'sm+':
      return {
        fontSize: '0.9375rem',
        letterSpacing: '0.56px',
        lineHeight: '1.5rem',
      };
    case 'md':
      return {
        fontSize: '1rem',
        letterSpacing: '0.6px',
        lineHeight: '1.25rem',
      };
    case 'md+':
      return {
        fontSize: '1.125rem',
        letterSpacing: '0.65px',
        lineHeight: '1.625rem',
      };
    case 'lg':
      return {
        fontSize: '1.25rem',
        letterSpacing: '0.8px',
        lineHeight: '1.5rem',
      };
    case 'cardTitle':
      return {
        fontSize: '1.75rem',
        letterSpacing: '0.84px',
        lineHeight: '1.75rem',
      };
    case 'cardSubtitle':
      return {
        fontSize: '1.125rem',
        letterSpacing: '0.83px',
        lineHeight: '1.128rem',
      };
    default:
      return {};
  }
};

const Text = ({
  className,
  textVariant,
  textFontSize,
  textFontWeight,
  textLetterSpacing,
  textLineHeight,
  textColor = 'inherit',
  textAlign,
  text,
  textSize,
  style,
  bold = false,
  semibold = false,
  ellipsisOverflow = false,
  children,
}) => {
  let classesParams;
  if (ellipsisOverflow) {
    classesParams = {
      ellipsis: true,
      overflow: true,
    };
  }
  const classes = useStyles({ textSize, bold, semibold, ...classesParams });
  const theme = useTheme();

  const color = theme.palette.text[textColor] || textColor;

  return (
    <Typography
      variant={textVariant}
      className={clsx(
        classes.size,
        classes.fontWeight,
        classes.overflow,
        classes.textOverflow,
        className,
      )}
      style={{
        fontSize: textFontSize,
        color,
        fontWeight: textFontWeight,
        letterSpacing: textLetterSpacing,
        lineHeight: textLineHeight,
        textAlign,
        ...style,
      }}
    >
      {children || text}
    </Typography>
  );
};

export default Text;
