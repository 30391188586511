import React, { useState } from 'react';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core';

import http from 'services/http';
import SettingsSection from 'components/SettingsSection';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import BusinessInformationForm from './BusinessInformationForm';

const useStyles = makeStyles(theme => ({
  inputField: {
    width: 320,
    marginTop: 15,
    marginBottom: 15,
    [theme.breakpoints.up('md')]: {
      margin: 15,
      width: 340,
    },
  },
  inputLabel: {
    marginLeft: 10,
  },
  businessInformationContainer: {
    marginBottom: '2.75rem',
  },
}));

const validationSchema = yup.object({
  businessPhone: yup
    .string()
    .required('Business Phone is required')
    .nullable()
    .matches(/^(?:\+1)?\(\d{3}\)\d{3}-\d{4}$/gm, {
      excludeEmptyString: true,
      message: 'Invalid Phone Number',
    }),
});

const initialValues = {
  businessName: '',
  annualRevenue: '',
  legalBusinessName: '',
  EINorSSNN: null,
  companyType: '',
  businessPhone: null,
  businessAddress: {
    streetAddress: '',
    addressLineTwo: '',
    zipCode: '',
    state: '',
    city: '',
  },
};

const BusinessInformationGroup = ({ userData }) => {
  const classes = useStyles();

  const handleUpdateInformation = async values => {};

  return (
    <SettingsSection>
      <SettingsSectionForm
        initialValues={initialValues}
        onSubmit={handleUpdateInformation}
        validationSchema={validationSchema}
      >
        <BusinessInformationForm userData={userData} />
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default BusinessInformationGroup;
