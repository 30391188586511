import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { Formik, Form, yupToFormErrors, useFormikContext } from 'formik';
import to from 'await-to-js';
import { protectedGet, protectedPost, protectedPatch } from 'services/http';
import { mergeDeepWith, path } from 'ramda';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Text from 'components/TextFields/Text';
import { StepHeader } from 'pages/NewApplication/StepHeader';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItem from '@material-ui/core/MenuItem';
import { merger } from 'services/utils';
import { useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { SelectField } from 'components/InputFields/SelectField';
import { BusinessTypeSelect, BusinessProductSelect } from 'components/InputFields/BusinessTypeSelect';
import { StateSelectField } from 'components/InputFields/StateSelectField';
import {
  useNewItemStyles,
  NewItemWrapper,
  createEntity,
  addUserToEntity,
} from 'pages/NewOrganization';
import SignUp from 'pages/SignUp/Dealer';
import Icon from 'components/Icon';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';
import ToggleButtons from 'components/Buttons/ToggleButtons';
const initialValuesObj = (isEdit) => ({
  isEdit,
  dealer: { name: '', lastName: '', email: '' },
  dealerNetwork: {
    businessType: '',
  },
  business: {
    name: '',
    annualValue: '',
  },
  address: {
    streetAddress: '',
    addressLine_2: '',
    state: '',
    city: '',
    zipCode: '',
  },
});
export const NewDealership = ({ gridSize }) => {
  const classes = useNewItemStyles();
  const { palette } = useTheme();
  const path = window.location.pathname;
  const [ownerValue, setOwnerValue] = useState();
  const [valueFetched, setValueFetched] = useState(false);
  // const {setFieldValue} = useFormikContext();
  // const { values, setErrors, setFieldValue, handleSubmit } = useFormikContext();
  const history = useHistory();
  const [productTypes, setProductTypes] = useState();
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { dealershipId } = useParams();
  const [formSubmit, setFormSubmit] = useState(false);
  const isEdit = useRouteMatch('/dealerships/:dealershipId/edit');
  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    const [validationErrors] = await to(
      validationSchema.validate(
        { ...values },
        {
          abortEarly: false,
        },
      ),
    );
    if (validationErrors) {
      setErrors(yupToFormErrors(validationErrors));
      return;
    }
    const dealerRes = await createEntity({
      url: isEdit
        ? `/v1/dealer_networks/${dealershipId}`
        : `/v1/dealer_networks`,
      isEdit,
      values,
      setSubmitting,
      setErrorAlertMessage,
    });
    if (!isEdit && dealerRes) {
      const userRes = await addUserToEntity({
        url: `/v1/dealer_networks/${dealerRes.data.id}/members`,
        values,
        setSubmitting,
        setErrorAlertMessage,
        role: 'overseer',
      });
      if (userRes) {
        setAlertMessage('Dealership Created Successfully');
        history.replace('/dealerships');
      }
    } else if (isEdit) {
      if (dealerRes) {
        setAlertMessage('Dealership Updated Successfully');
        history.replace('/dealerships');
      }
    }
  };
  // const autoFillAddress = (name, value) => {
  //   setFieldValue(name, value);
  // };
  useEffect(async () => {
    if (!productTypes) {
      const [err, response] = await to(
        protectedGet(`${process.env.REACT_APP_BASE_URL}/v2/loan_products`),
      );
      if (response) {
        setProductTypes(response.data);
      }
    }
  }, []);
  // const onHandleSubmit = (e) => {
  //   e.preventDefault();
  //   setFormSubmit(true);
  //   const validate = async () => {
  //     const [validationErrors] = await to(
  //       validationSchema.validate(values 
  //          , {
  //         abortEarly: false,
  //       }),
  //     );

  //     if (validationErrors) {
  //       setErrors(yupToFormErrors(validationErrors));
  //       return false;
  //     }
  //     handleSubmit(e);
  //     return true;
  //   };

  //   validate();
  // };
  const [initialValues, setInitialValues] = useState(initialValuesObj(isEdit));
  useEffect(() => {
    async function fetch() {
      if (isEdit) {
        const [err, dealership] = await to(
          protectedGet(`/v1/dealer_networks/${dealershipId}`),
        );
        if (err) {
          return;
        }
        const res = normalize(camelizeKeys(dealership.data)).get([
          'businessType',
          'personalInformation',
          'dealerCode',
          'primaryProductName',
          'business.name',
          'business.legalName',
          'business.phoneNumber',
          'business.annualFinanceValue',
          'business.annualValue',
          'business.websiteUrl',
          'address.streetAddress',
          'address.addressLine2',
          'address.state',
          'address.city',
          'address.zipCode',
        ]);
        res.dealerNetwork = { businessType: res.businessType };
        // res.address.addressLine_2 = res.address.addressLine2;
        setInitialValues((state) => {
          return mergeDeepWith(merger, res, state);
        });
        setValueFetched(true);
      }
    }
    if (dealershipId) {
      fetch();
    }
  }, [dealershipId]);
  return (
    <NewItemWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={null}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              <Box className={clsx(classes.stepForm, 'form-step')}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="flex-start">
                      <IconButton
                        className={classes.backButton}
                        style={{ marginRight: '1rem' }}
                        onClick={() => history.goBack()}
                      >
                        <ChevronLeftIcon fontSize="large" />
                      </IconButton>
                      <StepHeader
                        title={isEdit ? `${'Merchant Details'}` : 'Add a New Dealer'}
                        subtitle={
                          isEdit
                            ? ''
                            : 'Fill out the information to create a new dealer'
                        }
                      />
                    </Box>
                  </Grid>
                  {/* <SignUp affiliateFlow /> */}
                  {!isEdit ? <SignUp affiliateFlow /> :
                    valueFetched && <>

                      <Grid
                        item
                        xs={12}
                        container
                        spacing={3}
                        className={classes.formContainer}
                      >
                        <Grid item xs={12}>
                          <Text
                            textVariant="body1"
                            textColor="#636A72"
                            textFontWeight={600}
                            textFontSize={12}
                            textLetterSpacing={0.6}
                            textLineHeight="16px"
                            text="BUSINESS INFORMATION (Merchant)"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="business.legalName"
                            label="Legal Business Name"
                            disabled={path.includes('edit')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="business.name"
                            label="DBA Business Name"
                            disabled={path.includes('edit')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="primaryProductName"
                            label="Primary Product"
                            disabled={path.includes('edit')}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="dealerCode"
                            label="DealerCode"
                            disabled={path.includes('edit')}
                          />
                        </Grid>
                        {/* <Grid item xs={12} md={6} >
                      {productTypes && (
                        <BusinessProductSelect
                          name="productType"
                          label="Product Type"
                          businessData={productTypes}
                        />
                      )}
                    </Grid>

                    {values.productType && (
                      <Grid item xs={12} md={6}>
                        <BusinessTypeSelect
                          name="businessType"
                          label="Business Type"
                          businessData={productTypes}
                        />
                      </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                      <BusinessTypeSelect
                        name="dealerNetwork.businessType"
                        label="Business Type"
                      />
                    </Grid> */}
                        <Grid item xs={12}>
                          <Text
                            textVariant="body1"
                            textColor="#636A72"
                            textFontWeight={600}
                            textFontSize={12}
                            textLetterSpacing={0.6}
                            textLineHeight="16px"
                            text="BUSINESS ADDRESS"
                            style={{
                              marginTop: '10px',
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="address.streetAddress"
                            label="Street Address"
                            disabled={path.includes('edit')}
                          // autoFillAddress={autoFillAddress}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField name="address.city" label="City"
                            disabled={path.includes('edit')}

                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <StateSelectField name="address.state" label="State"
                            disabled={path.includes('edit')}

                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="address.zipCode"
                            label="Zip Code"
                            disabled={path.includes('edit')}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="business.websiteUrl"
                            disabled
                            label="Website URL"
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <OutlinedTextField
                            name="business.phoneNumber"
                            label="Mobile Phone"
                            id="mobile-phoneNumber-input"
                            disabled={path.includes('edit')}
                          />
                        </Grid>

                      </Grid>
                      <>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={3}
                          className={classes.formContainer}
                          style={{
                            paddingTop: '32px',
                          }}
                        >
                          <Grid item xs={12}>
                            <Grid item xs={12}>
                              <Text
                                textVariant="body1"
                                textColor="#636A72"
                                textFontWeight={600}
                                textFontSize={12}
                                textLetterSpacing={0.6}
                                style={{
                                  marginTop: '10px',
                                }}
                                textLineHeight="16px"
                                text="PERSONAL INFORMATION (Overseer)"
                              />
                            </Grid>

                          </Grid>
                          <Grid item xs={12} md={6}>
                            <OutlinedTextField
                              label="First Name"
                              name="personalInformation.name"
                              disabled={path.includes('edit')}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <OutlinedTextField
                              label="Last Name"
                              name="personalInformation.lastName"
                              disabled={path.includes('edit')}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <OutlinedTextField
                              label="Email"
                              name="personalInformation.email"
                              disabled={path.includes('edit')}
                            />
                          </Grid>
                        </Grid>
                      </>


                    </>}
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </NewItemWrapper>
  );
};
const validationSchema = Yup.object({
  dealerNetwork: Yup.object({
    businessType: Yup.string().required('Field Required').trim(),
  }),
  business: Yup.object({
    name: Yup.string().required('Field Required').trim(),
    annualValue: Yup.string().required('Field Required').trim(),
  }),
  address: Yup.object({
    streetAddress: Yup.string().required('Field Required').trim(),
    addressLine_2: Yup.string().trim(),
    state: Yup.string().required('Field Required').trim(),
    city: Yup.string().required('Field Required').trim(),
    zipCode: Yup.string().required('Field Required').trim(),
  }),
  dealer: Yup.object().when('isEdit', {
    is: (value) => !value,
    then: Yup.object({
      name: Yup.string().required('Field Required').trim(),
      lastName: Yup.string().required('Field Required').trim(),
      email: Yup.string()
        .required('Field Required')
        .email('invalid Email')
        .trim(),
    }),
  }),
});