import React, { useState, useEffect } from 'react';
import {
  AutocompleteSelect,
  CurrencyField,
  formatNumberToCurrencyString,
  castFormattedCurrencyStringToNumber
} from './../CustomizedMUIInputs';
import { protectedGet } from 'services/http';
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Icon from 'components/Icon';

export const LoanSection = ({
  loanProduct,
  setLoanProduct,
  requestAmount,
  setRequestAmount,
  projectAmountTotal,
  downPayment, 
  setDownPayment,
  merchant,
  isLoading,
  showAllErrors,
  maxRequestAmount,
  userLevel,
  userId,
  onError,
  listOfUsersWithAccessToMerchantSelection,
  enableStageFunding
}) => {
  const [options, setOptions] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isReviewDetailsModalOpen, setIsReviewDetailsModalOpen] = useState(
    false,
  );

  useEffect(() => {
    /*
      This useEffect handles the fetching of the list of options, as well as automatically selecting a loan
      Keep in mind that even if the useEffect is bound to the variable [merchant], it will still get called on first load
    */

    const fetchLoanProducts = async () => {
      /*
        Add tests here:
        I would add a couple of tests to make sure that correct user levels lead to the desired endpoint being pinged, nothing crazy
        So, one test per user level
      */
      const merchantLoansUrl = listOfUsersWithAccessToMerchantSelection.includes(
        userLevel,
      )
        ? `/v2/loan_products/public_index?dealer_code=${merchant.dealer_code}`
        : `/v1/dealers/${userId}/loan_products`;

      
      const newListOfOptions = (await protectedGet(merchantLoansUrl)).data.data;

      let idToSelect;
      if (loanProduct?.id && !loanProduct.attributes) {
        // if there is a selected loan with an id, but with no attributes, it means that it's a resubmit loan
        idToSelect = loanProduct.id;
      } else {
        const endpointForPrimaryProductId = listOfUsersWithAccessToMerchantSelection.includes(userLevel) ? `/v1/dealers/by_code?dealer_code=${merchant.dealer_code}` : `/v1/dealers/${userId}`
        idToSelect = (await protectedGet(endpointForPrimaryProductId)).data.data.attributes.primary_product_id;
      }

      setOptions(newListOfOptions);

      const loanThatMatchesIdToSelect = newListOfOptions.find(
        (newLoan) => Number(idToSelect) === Number(newLoan.id), // Number casting just in case~
      );

      if (loanThatMatchesIdToSelect)
        setLoanProduct(loanThatMatchesIdToSelect);
      else {
        if (idToSelect !== null) {
          onError(
            'There was an error finding selected loan. Setting field empty',
          );
          console.error(
            `Loan with id ${idToSelect} not found in array of loans. Setting field to null`,
          );
        }
        setLoanProduct(null);
      }
    };

    //this is here and not in an else because we need it to "reset" if there's a change in the merchant
    setOptions([]);
    setIsFetching(true);

    /*
      A resubmmited merchant will have an id, but no dealer_code. We want to ignore it until it has both.
    */
    if (
      merchant?.dealer_code ||
      !listOfUsersWithAccessToMerchantSelection.includes(userLevel)
    ) {
      fetchLoanProducts().finally(() => {
        setIsFetching(false);
      });
    }
  }, [merchant]);

  /** @todo seems like a good idea to make this useMemo */
  const getText = () => {
    if (!merchant?.dealer_code && listOfUsersWithAccessToMerchantSelection.includes(userLevel)) {
      return <p>Please select a Merchant</p>;
    }
    if (!loanProduct?.attributes) {
      return <p>Please select a Loan Product</p>;
    }
    return (
      <p>
        Please select the loan product that best describes the purpose of your
        loan or <button className="form-loan-section-inline-button"
        onClick={() => setIsReviewDetailsModalOpen(!isReviewDetailsModalOpen)}>review the product details and elegibility</button>
      </p>
    );
  };

  return (
    <div
      className={
        'form-loan-section form-card ' +
        (isFetching ? 'form-section-fetching' : '')
      }
    >
      <h2>Loan Product</h2>
      {getText()}
      <Dialog
        open={isReviewDetailsModalOpen}
        onClose={() => setIsReviewDetailsModalOpen(false)}
        aria-labelledby="loan-product-details-review-name"
        aria-describedby="loan-product-details-review-description"
      >
        <DialogContent>
          <div className="form-loan-section-review-details-modal-content-grid">
            <Icon icon="errorIcon" />
            <h1 id="loan-product-details-review-name">{loanProduct?.attributes?.name || "ERROR: loanProduct.attributes.name not found"}</h1>
            <p id="loan-product-details-review-description">{loanProduct?.attributes?.description || "ERROR: loanProduct.attributes.description not found"}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <button className="form-loan-section-review-details-modal-close-button" onClick={() => setIsReviewDetailsModalOpen(false)}>Okay</button>
        </DialogActions>
      </Dialog>
      <AutocompleteSelect
        value={loanProduct}
        onChange={(newLoan) => {
          setLoanProduct(newLoan);
          if (newLoan?.attributes?.code === 'healthcare') {
            setDownPayment('');
          }
        }}
        options={options}
        getOptionLabel={(option) => option.attributes?.name || ''}
        loading={isLoading || (merchant?.dealer_code && isFetching)}
        inputId="loan-product-input"
        label="Select a Loan Product"
        className="full-width-input"
        disabled={isFetching || isLoading}
        showErrorsWhileClean={showAllErrors}
        required
      />
      {
        /* I decided to have a bit more code repetition to simplify later down the line if we want to remove the non-stage funding process */
        enableStageFunding ? <>
          <CurrencyField
            value={requestAmount}
            onChange={(newValue) => {
              setRequestAmount(newValue)
            }}
            label="Financed Amount (USD)"
            id="loan_financed-amount"
            className="right-column"
            customError={
              {
                validationFunction: (value) => {
                  return castFormattedCurrencyStringToNumber(value) <= castFormattedCurrencyStringToNumber(maxRequestAmount);
                },
                errorMessage: `Financed amount cannot exceed ${formatNumberToCurrencyString(
                  maxRequestAmount,
                )}`,
              }
            }
            disabled={isFetching || isLoading}
            showErrorsWhileClean={showAllErrors}
            required
          />
              {
                loanProduct?.attributes?.code !== "healthcare"
                    ? <>
                      <CurrencyField
                          value={downPayment}
                          onChange={(newValue) => {
                            setDownPayment(newValue);
                          }}
                          label="Down Payment (USD)"
                          id="loan_down-payment"
                          className="right-column"
                          disabled={isFetching || isLoading}
                          showErrorsWhileClean={showAllErrors}
                      />
                      <CurrencyField
                          value={projectAmountTotal}
                          onChange={() => {
                            /** noop, leaving it here just in case */
                          }}
                          label="Project Amount (USD)"
                          id="loan_project-amount"
                          className="right-column"
                          disabled={isFetching || isLoading}
                          readOnly
                      />
                    </>
                    : null
              }
        </> : 
        <CurrencyField
          value={requestAmount}
          onChange={(newValue) => {
            setRequestAmount(newValue);
          }}
          label="Request Amount (USD)"
          id="loan_request-amount"
          className="right-column"
          disabled={isFetching || isLoading}
          showErrorsWhileClean={showAllErrors}
          customError={
            maxRequestAmount && {
              validationFunction: (value) => {
                return castFormattedCurrencyStringToNumber(value) <= maxRequestAmount;
              },
              errorMessage: `Request amount cannot exceed ${formatNumberToCurrencyString(
                maxRequestAmount,
              )}`,
            }
          }
          required
        />
      }
    </div>
  );
};
