import React from 'react';
import { map } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Text from 'components/TextFields/Text';
import { DateInput } from 'components/InputFields/DateField';

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: 0,
  },
}));

export const DateRangeFilter = ({
  possibleValues,
  currentValues,
  setParams,
  parentKey = 'dateRange',
}) => {
  const classes = useStyles();

  if (!currentValues) {
    console.error('error, currentValues prop is missing');
  }

  const setValue = name => date => {
    setParams(params => ({
      ...params,
      [parentKey]: {
        ...params[parentKey],
        [name]: date,
      },
    }));
  };

  return (
    <Box display="flex" flexDirection="column" className="filter-section">
      <Text
        textSize="sm"
        textColor="secondary"
        style={{ marginBottom: '0.5rem' }}
        bold
      >
        FILTER BY DATE RANGE
      </Text>

      {map(({ value, name, label }) => {
        return (
          <Box display="flex" alignItems="center" key={name} padding="1rem 0">
            <DateInput
              label={label}
              name={name}
              currentValue={value}
              onChange={setValue(name)}
            />
          </Box>
        );
      })(possibleValues)}
    </Box>
  );
};
