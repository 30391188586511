import { useState, useRef, useEffect } from 'react';
import { addIndex, map, filter, reduce } from 'ramda';

import { useUserContext } from 'services/hooks/useUser';

export const isCellVisible = (
  params,
  activeColumns,
  { userType, dealerLevelRole },
) => (cell) => {
  if (activeColumns && !activeColumns[cell?.id]) {
    return false;
  }

  if (cell?.levelRoles?.length > 0) {
    if (userType === 'homeowner') {
      if (!cell?.levelRoles.includes('homeowner')) {
        return false;
      }
    } else if (!cell?.levelRoles?.includes(dealerLevelRole)) {
      return false;
    }
  }

  if (cell?.status) {
    if (!cell?.status?.includes(params.status)) {
      return false;
    }
  }
  return true;
};

export function useTableSetup({ headCells, rows, params }) {
  const userContext = useUserContext();
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [activeColumns, setActiveColumns] = useState(
    reduce(
      (obj, cell) => {
       if(params.status=== 'canceled'&& (cell.id === 'cancelApp'|| cell.id === 'startProject')){
         return '';
       }
        const objCopy = { ...obj };
        objCopy[cell.id] = true;
        return objCopy;
      },
      {},
      headCells,
    ),
  );
  const filteredHeadCells = filter(
    isCellVisible(params, activeColumns, userContext),
    headCells,
  );
  const filteredRows = map((row) => {
    if (params.status === 'canceled' && row.length > 15) {
      row.splice(row.length - 2, 2);
    }
    return addIndex(filter)(
      (cell, index) =>
        isCellVisible(params, activeColumns, userContext)(headCells[index]),
      row,
    );
  }, rows);
  const initialColumns = useRef(filteredHeadCells);

  return {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  };
}
