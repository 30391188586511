import React from 'react';
import Modal from '@material-ui/core/Modal';
import { useModalContext } from 'services/contexts/useModalContext';

export default function SimpleModal({ children }) {
  const { open, toggle } = useModalContext();
  return (
    <Modal
      disableEnforceFocus
      open={open}
      onClose={toggle}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {children}
    </Modal>
  );
}
