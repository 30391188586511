import { camelizeKeys } from 'humps';
import normalize from 'json-api-normalize';

export const parseLoan = (loan) => {
  const json = normalize(camelizeKeys(loan)).get([
    'id',
    'activationFee',
    'apr',
    'pacSignatureMethod',
    'baseAmount',
    'dealerNetFunding',
    'dealerConciergeCost',
    'stageFunding',
    'stageFundingReserve',
    'firstStage',
    'manufacturerAmount',
    'manufacturerTerm',
    'monitoringTerm',
    'monitoringAmount',
    'monthlyRate',
    'projectType',
    'secondStage',
    'serviceAgreeAmount',
    'serviceAgreeTerm',
    'status',
    'termYears',
    'thirdStage',
    'unitDescription',
    'payments.id',
    'payments.type',
    'payments.paidAt',
    'payments.requestedAt',
    'payments.scheduledAt',
    'payments.status',
    'payments.amount',
    'refunds.id',
    'refunds.reason',
    'refunds.refundAmountCents',
    'refunds.refundType',
    'refunds.requestedAt',
    'refunds.status',
    'dealStructId',
    'stipPay',
    'rebates',
    'buydown',
    'programCost',
    'equalPayCost',
    'stipPayCost',
    'totalHoldback',
    'holdbackPercentage',
    'buydownCost',
    'rebatesCost',
    'buydownPoints',
    'ohNo',
    'ohNoMonths',
    'ohNoCost',
    'equalPay',
    'equalPayMonths',
    'signatureMethod',
    'total',
    'dealStruct.name',
    'dealStruct.id',
    'availableTerms',
    'pop',
    'popIntroMonths'
  ]);
  const loanDetails = {
    activationFee: json.activationFee,
    apr: json.apr,
    pacSignatureMethod:json.pacSignatureMethod,
    baseAmount: json.baseAmount,
    dealerNetFunding: json.dealerNetFunding,
    buydown: json.buydown,
    buydownPoints: json.buydownPoints,
    dealStruct: json.dealStruct,
    stipPayCost:json.stipPayCost,
    dealerConciergeCost:json.dealerConciergeCost,
    stageFundingReserve:json.stageFundingReserve,
    equalPayCost:json.equalPayCost,
    holdbackPercentage:json.holdbackPercentage,
    totalHoldback:json.totalHoldback,
    rebatesCost:json.rebatesCost,
    buydownCost:json.buydownCost,
    dealStructId: json.dealStructId,
    programCost:json.programCost,
    signatureMethod: json.signatureMethod,
    equalPay: json.equalPay,
    equalPayMonths: json.equalPayMonths,
    firstStage: json.firstStage,
    id: json.id,
    manufacturerAmount: json.manufacturerAmount,
    manufacturerTerm: json.manufacturerTerm,
    monitoringAmount: json.monitoringAmount,
    monitoringTerm: json.monitoringTerm,
    monthlyRate: json.monthlyRate,
    ohNo: json.ohNo,
    ohNoMonths: json.ohNoMonths,
    payments: json.payments,
    refunds:json.refunds,
    projectType: json.projectType,
    rebates: json.rebates,
    ohNoCost:json.ohNoCost,
    secondStage: json.secondStage,
    serviceAgreeAmount: json.serviceAgreeAmount,
    serviceAgreeTerm: json.serviceAgreeTerm,
    stageFunding: json.stageFunding,
    status: json.status,
    stipPay: json.stipPay,
    term: json.termYears,
    thirdStage: json.thirdStage,
    total: json.total,
    unitDescription: json.unitDescription,
    pop: json.pop,
    popIntroMonths: json.popIntroMonths
  };
  return [json, loanDetails];
};
