import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Text from 'components/TextFields/Text';
import { StepHeader } from 'pages/NewApplication/StepHeader';
import { UploadDocForm } from './UploadDocForm';
import { SocureSdkForm } from './SocureSdkForm';
import { PrequalConfirmation } from './PrequalConfirmation';
import { PrequalConfirmForm } from './PrequalConfirmForm';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '0 auto',
    maxWidth: 1600,
    padding: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  card: {
    position: 'relative',
  },
  flowPickerBtn: {
    display: 'flex',
    width: '100%',
    borderRadius: 8,
    height: 240,
    border: `solid 2px ${theme.palette.primary.main}`,
  },
  hideable: {
    transition: theme.transitions.create(['height', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    opacity: 1,
  },
  hidden: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    pointerEvents: 'none',
  },
}));

const HideableBox = ({ visible, children }) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.hideable, {
        [classes.hidden]: !visible,
      })}
    >
      {children}
    </Box>
  );
};

const SocureFlowPicker = ({ selectedFlow, setSelectedFlow }) => {
  const classes = useStyles();
  return (
    <HideableBox visible={selectedFlow === 'selectFlow'}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Button
            className={classes.flowPickerBtn}
            onClick={() => setSelectedFlow('sdkFlow')}
          >
            <Text textSize="md" semibold>
              Scan your document
            </Text>
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            className={classes.flowPickerBtn}
            onClick={() => setSelectedFlow('uploadFlow')}
          >
            <Text textSize="md" semibold>
              Upload your Document
            </Text>
          </Button>
        </Grid>
      </Grid>
    </HideableBox>
  );
};

export const SocureValidationPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const { token } = useParams();
  const [selectedFlow, setSelectedFlow] = useState('selectFlow');
  const [prequalification, setPrequalification] = useState(null);

  return (
    <Box className={classes.container}>
      <Card elevation={4} className={classes.card}>
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start">
                <IconButton
                  className={classes.backButton}
                  style={{ margin: 0, padding: 0 }}
                  onClick={() => history.goBack()}
                >
                  <ChevronLeftIcon fontSize="large" />
                </IconButton>
                <StepHeader
                  title="Continue your Prequalification Process"
                  subtitle="Validate your identification details"
                />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <SocureFlowPicker
                selectedFlow={selectedFlow}
                setSelectedFlow={setSelectedFlow}
              />
            </Grid>

            <Grid item xs={12}>
              <HideableBox visible={selectedFlow === 'sdkFlow'}>
                <SocureSdkForm
                  token={token}
                  setPrequalification={setPrequalification}
                  selectedFlow={selectedFlow}
                  setSelectedFlow={setSelectedFlow}
                />
              </HideableBox>
            </Grid>

            <Grid item xs={12}>
              <HideableBox visible={selectedFlow === 'uploadFlow'}>
                <UploadDocForm
                  token={token}
                  setPrequalification={setPrequalification}
                  setSelectedFlow={setSelectedFlow}
                />
              </HideableBox>
            </Grid>

            <Grid item xs={12}>
              <HideableBox visible={selectedFlow === 'confirmPrequal'}>
                <PrequalConfirmForm
                  token={token}
                  prequalification={prequalification}
                  setPrequalification={setPrequalification}
                  setSelectedFlow={setSelectedFlow}
                />
              </HideableBox>
            </Grid>

            <Grid item xs={12}>
              <HideableBox visible={selectedFlow === 'waitingForPrequal'}>
                <PrequalConfirmation
                  token={token}
                  selectedFlow={selectedFlow}
                  prequalification={prequalification}
                  setPrequalification={setPrequalification}
                />
              </HideableBox>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
