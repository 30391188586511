import React, { useState, useEffect, useContext, useMemo } from 'react';
import to from 'await-to-js';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import { useUserContext } from 'services/hooks/useUser';

const AffiliateListContext = React.createContext();
export const { Consumer: AffiliateListConsumer, Provider } = AffiliateListContext;

export function AffiliateListProvider({ children }) {
  const [affiliateOptions, setAffiliateOptions] = useState(null);
  const { user, dealerLevelRole } = useUserContext();

  const rolesForGetAllDealers = ['affiliate', 'organization', 'super_admin'];
  useEffect(() => {
    if (!user) {
      setAffiliateOptions(null);
    }

    if (user?.data?.attributes?.role !== 'super_admin') return;

    (async () => {
      try {
        if (
          user &&
          affiliateOptions === null 
        ) {
          const [err, data] = await to(protectedGet('/v1/affiliates/all'));
          let dropDownFormatted = data?.data?.data?.map((affiliate) => ({
            label : affiliate?.attributes?.name,
            value : affiliate?.attributes?.id,
            dealerCode : affiliate?.attributes?.id
          }));
          dropDownFormatted = dropDownFormatted.sort((a, b) => a.label.localeCompare(b.label));
          setAffiliateOptions(dropDownFormatted);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [user]);
  useEffect(() => {
    if (!user && affiliateOptions) {
        setAffiliateOptions(null);
    }
  }, [affiliateOptions]);
  return (
    <Provider
      value={{
        affiliateOptions,
      }}
    >
      {children}
    </Provider>
  );
}

export const useAffiliateListContext = () => {
  const context = useContext(AffiliateListContext);
  return context;
};

export default AffiliateListContext;
