import * as React from 'react';

function SvgPrequalifyIcon({ width, height, color, ...props }) {
  return (
    <svg
      width={width || '24px'}
      height={height || '24px'}
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <path
          d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"
          id="prequalify-icon_svg__a"
        />
      </defs>
      <use
        fill={color || 'currentColor'}
        xlinkHref="#prequalify-icon_svg__a"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgPrequalifyIcon;
