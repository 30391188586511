import { createMuiTheme } from '@material-ui/core'; // will change to createTheme after packages get updated to a newer version
import defaultTheme from '@material-ui/core/styles/defaultTheme.js';

const materialTheme = createMuiTheme({
  palette: {
    divider: '#ECEFF2',
    primary: {
      main: '#f79d3c',
      light: '#ffce6c',
      dark: '#db8932',
    },
    secondary: {
      main: '#4366f7',
      dark: '#2845c0',
    },
    error: {
      main: '#ff4757',
      light: '#ff7d84',
    },
    warning: {
      main: '#ff7f50',
      light: '#ffb07e',
    },
    info: {
      main: '#70a1ff',
      light: '#a6d2ff',
    },
    success: {
      main: '#1dd1a1',
      light: '#68ffd2',
    },
    header: {
      app: '#70A1FF',
      project: '#F99C39',
      prequal: '#636A72',
    },
    text: {
      ...defaultTheme.text,
      primary: '#1E272E',
      darkGray: '#9198A0',
      lightGray: '#F9FAFC',
    },
    appState: {
      draft: 'rgba(30,30,30,0.1)',
      pending: 'rgba(30,39,46,0.2)',
      inactive: 'rgba(30,39,46,0.2)',
      active: '#1DD1A1',
      approved: '#1DD1A1',
      completed: '#1DD1A1',
      in_progress: '#1DD1A1',
      finished: '#1DD1A1',
      prequalified: '#1DD1A1',
      needs_review: '#ff7f50',
      canceled: '#ff7f50',
      not_prequalified: '#ff7f50',
      rejected: '#ff4757',
      declined: '#ff4757',
      docs_received: '#70A1FF',
      conditional_approval: '#FFB31A',
      change_requested: '#1DD1A1',
      transferred: 'rgba(30,39,46,0.2)',
      // doc status
      verified: '#1DD1A1',
      needed: '#ff7f50',
      sent: '#70A1FF',
      signed: '#70A1FF',
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    subtitle1: {
      fontSize: '0.75rem',
    },
  },
  overrides: {
    root: {
      backgroundColor: '#ffffff',
    },
    MuiPickersDay: {
      day: {
        fontSize: '0.75rem',
        letterSpacing: '0.55px',
        lineHeight: '12px',
      },
      daySelected: {
        color: 'white',
        fontWeight: '600',
        letterSpacing: '0.55px',
        lineHeight: '12px',
        backgroundColor: 'transparent',
        backgroundImage: 'linear-gradient(#fad156, #f79d3c)',
      },
    },
  },
});

export default materialTheme;
