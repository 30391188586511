import React from 'react';

export default function index({ width, height, color }) {
  return (
    <svg
      width={width || '14px'}
      height={height || '16px'}
      viewBox="0 0 14 16"
      version="1.1"
    >
      <defs>
        <path
          d="M13 10.667c.368 0 .667.298.667.666v2a2 2 0 0 1-2 2H2.333a2 2 0 0 1-2-2v-2a.667.667 0 0 1 1.334 0v2c0 .369.298.667.666.667h9.334a.667.667 0 0 0 .666-.667v-2c0-.368.299-.666.667-.666zM7.202.697l.014.006a.662.662 0 0 1 .255.159l-.06-.053a.67.67 0 0 1 .046.04l.014.013 2.667 2.667a.667.667 0 0 1-.943.942L7.666 2.943v7.724a.667.667 0 0 1-.567.66L7 11.332a.667.667 0 0 1-.667-.666V2.943L4.804 4.47a.667.667 0 0 1-.86.07l-.083-.07a.667.667 0 0 1 0-.942L6.529.862a.68.68 0 0 1 .314-.177.672.672 0 0 1 .35.01l.01.003z"
          id="a"
        />
      </defs>
      <use
        fill={color || '#F79D3C'}
        xlinkHref="#a"
        transform="translate(.25)"
        fillRule="evenodd"
      />
    </svg>
  );
}
