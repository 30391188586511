import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { getToken, setToken } from '../services/authentication';
import { protectedGet } from '../services/http';

const UserContext = React.createContext();
export const { Consumer: UserConsumer, Provider } = UserContext;

export function UserProvider({ children }) {
  const localState = getToken();
  const [user, setUser] = useState(localState || null);
  const [userStatus, setStatus] = useState(
    JSON.parse(user)?.data?.attributes?.status || null,
  );
  const tokenData = JSON.parse(user);
  const loginUser = (data) => setUser(data);
  const logOutUser = () => setUser(null);
  const resetUserStatus = () => setStatus(null);
  const userId = JSON.parse(user)?.data?.id;
  useEffect(() => {
    const fetch = async () => {
      if (userId) {
        const [err, res] = await to(protectedGet(`/v1/users/${userId}`));
        if (err) {
          return;
        }
        setStatus(res?.data?.data?.attributes?.status);
        setToken(
          JSON.stringify({ ...tokenData, data: { ...res?.data?.data } }),
        );
        if (tokenData) {
          setUser(getToken());
        }
      }
    };
    if (userStatus !== 'approved') {
      fetch();
    }
  }, [localState, userStatus]);
  return (
    <Provider
      value={{
        user,
        setUser: loginUser,
        clearUser: logOutUser,
        userStatus,
        clearUserStatus: resetUserStatus,
      }}
    >
      {children}
    </Provider>
  );
}

export default UserContext;
