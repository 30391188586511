import React from 'react';

export default function index({ width, height }) {
  return (
    <svg width="18" height="14" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path
          d="M.75 2.474a.75.75 0 0 1 0-.012V2.5C.75 1.26 1.76.25 3 .25h12c1.24 0 2.25 1.01 2.25 2.25v9c0 1.24-1.01 2.25-2.25 2.25H3c-1.24 0-2.25-1.01-2.25-2.25zm1.5 1.466v7.56c0 .41.34.75.75.75h12c.41 0 .75-.34.75-.75V3.941L9.43 8.364a.75.75 0 0 1-.76.06l-.1-.06L2.25 3.94zM15 1.75H3a.755.755 0 0 0-.67.416L9 6.835l6.67-4.669A.755.755 0 0 0 15 1.75z"
          id="a"
        />
      </defs>
      <use fillOpacity=".4" fill="#1E272E" xlinkHref="#a" fillRule="evenodd" />
    </svg>
  );
}
