import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Divider, Slider } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Text from 'components/TextFields/Text';
import { MoneyFormatField } from 'components/InputFields/MoneyFormatField';
import { ActiveButton } from 'components/Buttons/ActiveButton';
import { map } from 'ramda';
import { InfoItem } from './Step2DriverLicense';

const getTexts = (isDealer = false, dealerRole = 'dealership') => {
  const HOTexts = {
    stepTitle: '',
    stepSubtitle: '',
    title: '',
    subtitle: '',
  };
  const dealershipTexts = {
    stepTitle: '',
    stepSubtitle: '',
    title: '',
    subtitle: '',
  };
  if (isDealer) {
    switch (dealerRole) {
      case 'dealership':
      default:
        return dealershipTexts;
    }
  } else {
    return HOTexts;
  }
};

const useStyles = makeStyles((theme) => ({
  moneyInput: {
    marginTop: '1.25rem',
    maxWidth: '16rem',
    '& input': {
      padding: '0.375rem 0.5rem',
      fontSize: '2.5rem',
      color: '#1DD1A1',
      fontWeight: 700,
      lineHeight: '49px',
      letterSpacing: '1.25px',
    },
  },
  moneySlider: {
    marginTop: '1.5rem',
    maxWidth: '20rem',
    '& .MuiSlider-markLabel': {
      fontSize: '0.75rem',
      transform: 'unset',
      '&:nth-last-child(2)': {
        transform: 'translateX(-100%)',
      },
    },
  },
  leftBox: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '2rem',
    },
  },
  rightBox: {
    [theme.breakpoints.up('lg')]: {
      borderLeft: 'solid 2px',
      borderLeftColor: theme.palette.divider,
      paddingLeft: '3rem',
    },
  },
  loanBox: {
    background: '#F9FAFC',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    maxWidth: '30rem',
  },
}));
const PMT = (montlyRate, months, paymentValue) => {
  const pvif = (1 + montlyRate) ** months;
  return (montlyRate / (pvif - 1)) * -(paymentValue * pvif);
};

export const Step6LoanAmount = ({
  isActive,
  setChildCallbacks,
  isDealer,
  dealerRole,
}) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return TEXTS.stepTitle;
        },
        get stepSubtitle() {
          return TEXTS.stepSubtitle;
        },
      });
    }
    return () => {};
  }, [isActive]);

  const theme = useTheme();
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const requestAmount = values.financialDetails[0].requestAmount;
  const term = values.financialDetails[0].term;

  const setLoanAmount = (ev, val) => {
    setFieldValue('financialDetails[0].requestAmount', val);
  };
  const setLoanTimeTerms = (val) => () =>
    setFieldValue('financialDetails[0].term', val);

  const TEXTS = getTexts(isDealer, dealerRole);

  return (
    <Grid container>
      <Grid item xs={12} lg={5}>
        <Box className={classes.leftBox} display="flex" flexDirection="column">
          <Text textColor={theme.palette.text.primary} textSize="sm+" semibold>
            How much would you like to borrow?
          </Text>
          <Text
            id="loan-amount-label"
            textColor={theme.palette.text.secondary}
            textSize="xs+"
            semibold
          >
            Enter Project Cost (Min. $500)
          </Text>

          <MoneyFormatField
            min={0}
            max={100000}
            className={classes.moneyInput}
            name="financialDetails[0].requestAmount"
          />
          <Slider
            className={classes.moneySlider}
            aria-labelledby="loan-amount-label"
            marks={[
              { value: 0, label: '0' },
              { value: 10000 },
              { value: 20000 },
              { value: 30000 },
              { value: 40000 },
              { value: 50000 },
              { value: 60000 },
              { value: 70000 },
              { value: 80000 },
              { value: 90000 },
              { value: 100000, label: '$100,000' },
            ]}
            min={0}
            max={100000}
            step={10000}
            value={requestAmount || 0}
            onChange={setLoanAmount}
            valueLabelDisplay="off"
          />
        </Box>

        {isDealer ? (
          <Box>
            <Text
              textColor={theme.palette.text.primary}
              textSize="sm+"
              semibold
            >
              How would they like to pay the loan?
            </Text>
            <Text
              id="loan-amount-label"
              textColor={theme.palette.text.secondary}
              textSize="xs+"
              semibold
            >
              Pick Time Terms
            </Text>

            <Box display="flex" justifyContent="space-between" maxWidth="26rem">
              {map(
                (time) => (
                  <ActiveButton
                    key={time}
                    isActive={time === term}
                    color="primary"
                    onClick={setLoanTimeTerms(time)}
                  >
                    {time} Years
                  </ActiveButton>
                ),
                [5, 10, 15],
              )}
            </Box>
          </Box>
        ) : (
          <Box marginTop="2rem" maxWidth="24rem">
            <InfoItem
              title="This is an estimate"
              desc="The final price of your contract will be agreed with your contractor"
            />
          </Box>
        )}
      </Grid>
      <Grid item xs={12} lg={6}>
        {isDealer && (
          <Box className={classes.loanBox}>
            <Box display="flex" alignItems="center" marginBottom="0.5rem">
              <Text
                textColor={theme.palette.text.secondary}
                textSize="xs"
                style={{ flex: 1 }}
              >
                Total Loan Amount
              </Text>
              <Text textColor={theme.palette.text.primary} bold textSize="sm">
                <NumberFormat
                  value={requestAmount}
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Text>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="0.5rem">
              <Text
                textColor={theme.palette.text.secondary}
                textSize="xs"
                style={{ flex: 1 }}
              >
                Monthly Rate
              </Text>
              <Text textColor={theme.palette.text.primary} bold textSize="sm">
                <NumberFormat
                  value={
                    Math.round(
                      Math.abs(
                        PMT(8.99 / (12 * 100), term * 12, requestAmount),
                      ) * 100,
                    ) / 100
                  }
                  displayType="text"
                  prefix="$"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Text>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="0.5rem">
              <Text
                textColor={theme.palette.text.secondary}
                textSize="xs"
                style={{ flex: 1 }}
              >
                Interest Rate
              </Text>
              <Text textColor={theme.palette.text.primary} bold textSize="sm">
                8.99%
              </Text>
            </Box>
            <Box display="flex" alignItems="center" marginBottom="0.5rem">
              <Text
                textColor={theme.palette.text.secondary}
                textSize="xs"
                style={{ flex: 1 }}
              >
                Term in Months
              </Text>
              <Text textColor={theme.palette.text.primary} bold textSize="sm">
                {term * 12} Months
              </Text>
            </Box>
            <Divider style={{ margin: '1rem 0' }} />

            <InfoItem
              title="This is just a Loan Calculation"
              desc="These numbers do not represent the actual terms of the Loan. Those will be agreed between the Dealer and the Homeowner according to the requirements."
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
