import React from 'react';
import { map } from 'ramda';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Text from 'components/TextFields/Text';

const translateApplicationState = (state) => {
  switch (state) {
    case 'draft':
    case 'drafted':
      return 'Draft';
    case 'submitted':
    case 'active':
      return 'Active';
    case 'expiring':
      return 'Expiring';  
    case 'canceled':
      return 'Canceled';
    case 'transferred':
      return 'Transferred';
    case 'all':
      return 'All'; 
    
    default:
      return 'N/T';
  }
};

const useStyles = makeStyles((theme) => ({
  stateFilter: {
    '& .MuiTypography-root': {
      color: `${theme.palette.text.secondary} !important`,
    },
  },
  active: {
    '& .MuiTypography-root': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  statusFilter: {
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      padding: '0.5rem 0 0.25rem 0',
    },
  },
}));

export const StateFilter = ({
  possibleStatuses = [],
  status: currentStatus,
  setParams,
  setPage
}) => {
  const classes = useStyles();

  const onStatusClick = (status) => () => {
    setPage(0);
    if (currentStatus === status) {
      return;
    }
    setParams((params) => ({
      ...params,
      status,
    }));
  };

  return (
    <Box display="flex" className={classes.statusFilter}>
      {map(
        (st) => (
          <Button
            key={st}
            className={clsx(classes.stateFilter, {
              [classes.active]: currentStatus === st,
            })}
            onClick={onStatusClick(st)}
          >
            <Text textSize="sm+" bold>
              {translateApplicationState(st)}
            </Text>
          </Button>
        ),
        possibleStatuses,
      )}
    </Box>
  );
};
