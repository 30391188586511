import React from 'react';
import { protectedPut } from 'services/http';
import to from 'await-to-js';
import { decamelizeKeys } from 'humps';
import SettingsSection from 'components/SettingsSection';
import SettingsSectionForm from 'components/SettingsSection/SettingsSectionForm';
import { useAlertContext } from 'components/AlertContext';
import { useUserContext } from 'services/hooks/useUser';
import { getToken, setToken } from 'services/authentication';
import DefaultPageForm from './DefaultPageForm';

const DefaultPage = () => {
  const { setAlertMessage } = useAlertContext();
  const { user, setUser, userStatus } = useUserContext();
  const defaultUrl = user.data.attributes.defaultUrl || '';

  const onSubmit = (values) => {
    const update = async () => {
      const [err, userRes] = await to(
        protectedPut(`/v1/users/${user.data.attributes.id}`, {
          user: {
            default_url: values.defaultUrl,
          },
        }),
      );
      if (err) {
        return;
      }
      setAlertMessage('Operation Completed Successfully');
      user.data.attributes.defaultUrl = values.defaultUrl;
      setUser(user);
      //
      let userData = JSON.parse(getToken());
      userData = {
        ...userData,
        data: {
          ...userData.data,
          attributes: {
            ...userData.data.attributes,
            default_url: values.defaultUrl,
          },
        },
      };
      setToken(JSON.stringify(decamelizeKeys(userData)));
    };
    update();
  };
  if (userStatus !== 'approved') {
    return null;
  }
  return (
    <SettingsSection>
      <SettingsSectionForm onSubmit={onSubmit} initialValues={{ defaultUrl }}>
        <DefaultPageForm />
      </SettingsSectionForm>
    </SettingsSection>
  );
};

export default DefaultPage;
