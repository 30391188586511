import React, { useEffect, useRef, useState } from 'react';
import to from 'await-to-js';
import { protectedGet, protectedPost } from 'services/http';
import { camelizeKeys } from 'humps';
import { useHistory } from 'react-router-dom';
import { stringify } from 'querystring';

import { useAlertContext } from 'components/AlertContext';

import {
  createAppFromPrequalify,
  PrequalificationConfirmation,
} from 'pages/NewPrequalify/ConfirmPage';
import { useUserContext } from 'services/hooks/useUser';

export const PrequalConfirmation = ({
  token,
  selectedFlow,
  prequalification,
}) => {
  const timeout = useRef(null);
  const history = useHistory();
  const { setErrorAlertMessage } = useAlertContext();
  const { user } = useUserContext();

  const [status, setStatus] = useState('waitingForPrequal');
  const onCreateApp = () => {
    createAppFromPrequalify(prequalification, history, setErrorAlertMessage);
  };

  const onReviewPrequalification = async () => {
    if (user) {
      history.push(`/prequalify/${prequalification.id}/detail`);
    } else {
      const [err, res] = await to(
        protectedPost(`/v1/soft_pulls/continue_app`, {
          soft_pull_id: prequalification.id,
          token,
          emailed: false,
        }),
      );

      if (err && err.response) {
        setErrorAlertMessage(err.response.data.message || 'Error');
        return;
      }

      const params = {
        destination: 'prequal-detail',
        id: prequalification.id,
        uid: res.data.uid,
        'access-token': res.data['access-token'],
        client: res.data.client,
      };

      history.push(`/token-login?${stringify(params)}`);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const [err, r] = await to(
        protectedGet(`/v1/soft_pulls/${prequalification.id}/decision_status`),
      );
      if (err) {
        console.error({ ...err });
        return null;
      }
      const prequal = camelizeKeys(r.data);
      return prequal;
    };

    const loop = () => {
      timeout.current = setTimeout(async () => {
        const prequal = await fetch();
        if (prequal) {
          if (prequal.data.status === 'pending') {
            loop();
          } else {
            setStatus(prequal.data.status);
          }
        }
      }, 1000 * 10);
    };
    if (
      selectedFlow === 'waitingForPrequal' &&
      status === 'waitingForPrequal' &&
      prequalification
    ) {
      loop();
    }

    return () => {
      clearTimeout(timeout.current);
    };
  }, [selectedFlow, prequalification]);

  return (
    <PrequalificationConfirmation
      id={prequalification?.id}
      status={status}
      onCreateApp={onCreateApp}
      onReviewPrequalification={onReviewPrequalification}
      isOnPublicPage
    />
  );
};
