import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import normalize from 'json-api-normalize';
import { camelizeKeys } from 'humps';
import { protectedGet } from 'services/http';
import to from 'await-to-js';

import { AutocompleteInput } from 'components/InputFields/AutocompleteField';
import { useUserContext } from 'services/hooks/useUser';

export const DealerNetworkAutocomplete = ({ ...props }) => {
  const { dealerLevelId } = useUserContext();

  const fetchDealerNetworks = async () => {
    const [err, res] = await to(protectedGet('/v1/dealer_networks'));

    if (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(
      normalize(camelizeKeys(res.data)).get([
        'id',
        'businessType',
        'business.name',
        'dealerCode',
      ]),
    );
  };

  const { data: dealerNetworks = null } = useQuery(
    ['dealerNetworks', { dealerLevelId }],
    fetchDealerNetworks,
  );

  const dealerNetworkOptions = useMemo(
    () =>
      dealerNetworks?.map((dealerNetwork) => ({
        label: dealerNetwork?.business?.name,
        value: dealerNetwork?.dealerCode,
      })),
    [dealerNetworks],
  );

  return <AutocompleteInput options={dealerNetworkOptions} {...props} />;
};
