import SignIn from 'pages/SignIn';
import SignOut from 'pages/SignOut';
import HomeownerDashboard from 'pages/Dashboard/Homeowner';
import HomeownerContactUs from 'pages/Dashboard/Homeowner/ContactUs';
import DealerDashboard from 'pages/Dashboard/Dealer';
import { DashboardRedirect } from 'pages/Dashboard/DashboardRedirect';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import { Onboarding } from 'pages/NewApplication/Onboarding';
import { NewApplication } from 'pages/NewApplication';
import { ConfirmPage as ApplicationConfirmPage } from 'pages/NewApplication/ConfirmPage';
import ApplicationWrapper from 'pages/NewApplicationDealer/ApplicationWrapper';
import DealStatusPage from 'pages/NewApplicationDealer/dealStatusPage';
import Settings from 'pages/Settings';
import ResendLink from 'pages/ResendLink';
import SetPassword from 'pages/SetPassword';
import ConfirmInvite from 'pages/ConfirmInvite';
import HomeownerSignUp from 'pages/SignUp/HomeOwner';
import DealerSignUp from 'pages/SignUp/Dealer';
import Tutorials from 'pages/Tutorials';
import { SocureValidationPage } from 'pages/SocureValidation';
import ContactUs from 'pages/ContactUs';
import ApplicationQueue, {
  ApplicationQueueHeaderActions,
} from 'pages/ApplicationQueue';
import ProjectQueue from 'pages/ProjectQueue';
import {
  OrganizationList,
  OrganizationListHeaderActions,
} from 'pages/OrganizationList';
import {
  DealershipList,
  DealershipListHeaderActions,
} from 'pages/DealershipList';
import {
  TeamMemberList,
  TeamMemberListHeaderActions,
} from 'pages/TeamMemberList';
import ApplicationDetail from 'pages/ApplicationDetail/Homeowner';
import DealerApplicationDetail from 'pages/ApplicationDetail/Dealer';
import { NewOrganization } from 'pages/NewOrganization';
import { NewDealership } from 'pages/NewDealership';
import { NewTeamMember } from 'pages/NewTeamMember';
import { RedirectComponent } from 'components/RedirectComponent';
import NewApplicationForm from 'pages/NewApplicationDealer/NewApplicationForm';
import ConsumerForm from 'pages/ConsumerApplication/ConsumerForm';
import MerchantResources from 'pages/MerchantResources';
import HomeownerPaymentCenter from 'pages/Dashboard/Homeowner/PaymentCenter';
import Resubscribe from "pages/Resubscribe";

export const protectedRoutes = [
  {
    id: 'signIn',
    path: '/signin',
    component: SignIn,
    type: 'initialRoute',
  },
  {
    id: 'signOut',
    path: '/signout',
    component: SignOut,
    type: 'default',
  },
  {
    id: 'dashboard',
    path: '/dashboard',
    component: DashboardRedirect,
    type: 'protectedRoute',
  },
  {
    id: 'homeownerDashboard',
    title: 'Dashboard',
    path: '/homeowner/dashboard',
    component: HomeownerDashboard,
    type: 'protectedRoute',
  },
  {
    id: 'homeownerPaymentCenter',
    title: '',
    path: '/homeowner/payment-center',
    component: HomeownerPaymentCenter,
    type: 'protectedRoute',

  },
  {
    id: 'homeownerContactUs',
    title: 'Contact Us',
    path: '/homeowner/contact-us',
    component: HomeownerContactUs,
    type: 'protectedRoute',
  },
  {
    id: 'dealerDashboard',
    title: 'Dashboard',
    path: '/dealer/dashboard',
    component: DealerDashboard,
    type: 'protectedRoute',
  },
  {
    id: 'tutorials',
    title: 'Training',
    path: '/tutorials',
    component: Tutorials,
    type: 'protectedRoute',
  },
  {
    id: 'dealer-application-detail',
    title: 'Application',
    path: '/dealer/applications/:id',
    component: DealerApplicationDetail,
    type: 'protectedRoute',
  },
  {
    id: 'dealerApplicationQueue',
    title: 'Applications',
    path: '/applications',
    component: ApplicationQueue,
    type: 'protectedRoute',
    headerActions: ApplicationQueueHeaderActions,
  },
  {
    id: 'dealerProjectQueue',
    title: 'Loans',
    path: '/projects',
    component: ProjectQueue,
    type: 'protectedRoute',
  },
  {
    id: 'organization-edit-form',
    title: 'Edit Organization',
    path: '/organizations/:organizationId/edit',
    component: NewOrganization,
    type: 'protectedRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply/:dealerCode/:dealerMemberId',
    component: ConsumerForm,
    type: 'protectedRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply/:dealerCode',
    component: ConsumerForm,
    type: 'protectedRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply',
    component: ConsumerForm,
    type: 'protectedRoute',
  },
  {
    id: 'organization-form',
    title: 'New Organization',
    path: '/organizations/new',
    component: NewOrganization,
    type: 'protectedRoute',
  },
  {
    id: 'organizationList',
    title: 'Organization Management',
    path: '/organizations',
    component: OrganizationList,
    type: 'protectedRoute',
    headerActions: OrganizationListHeaderActions,
  },
  {
    id: 'dealership-edit-form',
    title: 'View Merchant',
    path: '/dealerships/:dealershipId/edit',
    component: NewDealership,
    type: 'protectedRoute',
  },
  {
    id: 'dealership-form',
    title: 'New Merchant',
    path: '/dealerships/new',
    component: NewDealership,
    type: 'protectedRoute',
  },
  {
    id: 'dealershipList',
    title: 'Merchants',
    path: '/dealerships',
    component: DealershipList,
    type: 'protectedRoute',
    headerActions: DealershipListHeaderActions,
  },
  {
    id: 'edit-team-member',
    title: 'Edit Team Member',
    path: '/team-members/:memberId/edit',
    component: NewTeamMember,
    type: 'protectedRoute',
  },
  {
    id: 'new-team-member',
    title: 'New Team Member',
    path: '/team-members/new',
    component: NewTeamMember,
    type: 'protectedRoute',
  },
  {
    id: 'TeamMemberList',
    title: 'User Management',
    path: '/team-members',
    component: TeamMemberList,
    type: 'protectedRoute',
    headerActions: TeamMemberListHeaderActions,
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/applications/start',
    component: NewApplicationForm,
    type: 'protectedRoute',
  },

  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/applications/:appId/complete',
    component: ApplicationWrapper,
    type: 'protectedRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/applications/:appId/incomplete',
    component: NewApplicationForm,
    type: 'protectedRoute',
  },

  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/applications/:appId/review',
    component: ApplicationWrapper,
    type: 'protectedRoute',
  },
  {
    id: 'reviewingApp',
    title: 'Reviewing',
    path: '/applications/:appId/reviewing',
    component: DealStatusPage,
    type: 'protectedRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'Invalid Dealer Configuration',
    path: '/errorPage',
    component: DealStatusPage,
    type: 'protectedRoute',
  },
  {
    id: 'declinedDeal',
    title: 'Deal Declined',
    path: '/applications/:appId/declined',
    component: DealStatusPage,
    type: 'protectedRoute',
  },
  {
    id: 'ineligibleDeal',
    title: 'Ineligible Deal',
    path: '/applications/:appId/ineligible',
    component: DealStatusPage,
    type: 'protectedRoute',
  },
  {
    id: 'ineligibleDeal',
    title: 'Exception Page',
    path: '/applications/:appId/exception',
    component: DealStatusPage,
    type: 'protectedRoute',
  },

  {
    id: 'socure-validation',
    title: 'Socure Validation',
    path: '/socure-validation/:token',
    component: SocureValidationPage,
    type: 'protectedRoute',
  },
  {
    id: 'application-onboarding',
    title: 'Dashboard',
    path: '/applications/onboarding',
    component: Onboarding,
    type: 'protectedRoute',
  },
  {
    id: 'application-form',
    title: 'New Application',
    path: '/applications/new',
    component: NewApplication,
    type: 'protectedRoute',
  },
  {
    id: 'application-confirmation',
    title: 'New Application',
    path: '/applications/confirmation',
    component: ApplicationConfirmPage,
    type: 'protectedRoute',
  },
  {
    id: 'settings',
    title: 'Settings',
    path: '/settings',
    component: Settings,
    type: 'protectedRoute',
  },
  {
    id: 'application',
    title: 'Application',
    path: '/applications/:id',
    component: ApplicationDetail,
    type: 'protectedRoute',
  },
  {
    id: 'token-login',
    title: 'New Application',
    path: '/token-login',
    component: RedirectComponent,
    type: 'publicRoute',
  },
  {
    id: 'merchant-resources',
    title: 'Merchant Resources',
    path: '/merchant-resources',
    component: MerchantResources,
    type: 'protectedRoute',
  },
  {
    id: 'contact-us',
    title: 'Contact Us',
    path: '/contact-us',
    component: ContactUs,
    type: 'protectedRoute',
  },
];

export const publicRoutes = [
  {
    id: 'signIn',
    path: '/signin',
    component: SignIn,
    type: 'initialRoute',
  },
  {
    id: 'signOut',
    path: '/signout',
    component: SignOut,
    type: 'default',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply/:dealerCode/:dealerMemberId',
    component: ConsumerForm,
    type: 'publicRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply/:dealerCode',
    component: ConsumerForm,
    type: 'publicRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/apply',
    exact: false,
    component: ConsumerForm,
    type: 'publicRoute',
  },

  {
    id: 'dealerPrequalifyQueue',
    title: 'New Application',
    path: '/confirmApplication/:appId',
    component: DealStatusPage,
    type: 'publicRoute',
  },
  {
    id: 'dealerPrequalifyQueue',
    title: 'Dealer Not Found',
    path: '/errorPage',
    component: DealStatusPage,
    type: 'publicRoute',
  },
  {
    id: 'declinedDeal',
    title: 'Deal Declined',
    path: '/applications/:appId/declined',
    component: DealStatusPage,
    type: 'publicRoute',
  },
  {
    id: 'ineligibleDeal',
    title: 'Ineligible Deal',
    path: '/applications/:appId/ineligible',
    component: DealStatusPage,
    type: 'publicRoute',
  },
  {
    id: 'ineligibleDeal',
    title: 'Exception Page',
    path: '/applications/:appId/exception',
    component: DealStatusPage,
    type: 'publicRoute',
  },
  {
    id: 'forgotPassword',
    path: '/forgot-password',
    component: ForgotPassword,
    type: 'initialRoute',
  },
  {
    id: 'confirm_invite',
    path: '/confirm_invite',
    component: ConfirmInvite,
    type: 'initialRoute',
  },
  {
    id: 'resetPassword',
    path: '/reset-password',
    component: ResetPassword,
    type: 'initialRoute',
  },
  {
    id: 'setPassword',
    path: '/new-password',
    component: SetPassword,
    type: 'initialRoute',
  },
  {
    id: 'signup',
    path: '/signup',
    component: HomeownerSignUp,
    type: 'initialRoute',
  },
  {
    id: 'signup',
    path: '/merchant/signup',
    component: DealerSignUp,
    type: 'initialRoute',
  },
  {
    id: 'signup',
    path: '/dealer/signup',
    component: DealerSignUp,
    type: 'initialRoute',
  },
  {
    id: 'signup',
    path: '/dealer/signup?:affiliate_id=affiliateId',
    component: DealerSignUp,
    type: 'initialRoute',
  },
  {
    id: 'resendLink',
    path: '/resend-link',
    component: ResendLink,
    type: 'initialRoute',
  },
  {
    id: 'socure-validation',
    title: 'Socure Validation',
    path: '/socure-validation/:token',
    component: SocureValidationPage,
    type: 'publicRoute',
  },
  {
    id: 'token-login',
    title: 'New Application',
    path: '/token-login',
    component: RedirectComponent,
    type: 'publicRoute',
  },
  {
    id: 'resubscribe',
    title: 'Resubscribe',
    path: '/resubscribe_notifications',
    component: Resubscribe,
    type: 'initialRoute',
  }
];
export const settingRoutes = [
  {
    id: 'settings',
    title: 'Settings',
    path: '/settings',
    component: Settings,
    type: 'protectedRoute',
  },
];
