import React, { useState } from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';

import Text from '../TextFields/Text';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: 16,
    marginBottom: 16,
    padding: '30px 24px 16px',
    boxShadow: '6px 8px 10px 0 rgba(116,125,140,0.1)',
  },
}));

const SettingsSection = ({ className, children }) => {
  const classes = useStyles();

  return <Paper className={clsx(classes.paper, className)}>{children}</Paper>;
};

export default SettingsSection;
