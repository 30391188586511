import React, { useEffect, useContext } from 'react';
import { parse } from 'querystring';
import { useLocation } from 'react-router-dom';

import { useSessionExpirerContext } from 'components/SessionExpirerContext';
import { removeToken } from '../../services/authentication';
import UserContext from '../../components/UserContext';

export default function SignOut({ history }) {
  const { clearUser, clearUserStatus } = useContext(UserContext);
  const { setOpenExpiredModal } = useSessionExpirerContext();
  const location = useLocation();
  const queryParams = parse(location.search.replace('?', ''));
  sessionStorage.removeItem('dealerSelected');
  sessionStorage.removeItem('passwordExpiry');
  useEffect(() => {
    if (queryParams.sessionExpired) {
      setOpenExpiredModal();
    }
    clearUser();
    removeToken();
    clearUserStatus();
    if (sessionStorage.getItem('applyPage')) {
      if (sessionStorage.getItem('dealerCode')) {
        const dealerCode = sessionStorage.getItem('dealerCode');
        history.replace(`/apply/${dealerCode}`);
        sessionStorage.removeItem('dealerCode');
      } else {
        history.replace(`/apply`);
      }
      sessionStorage.removeItem('applyPage');
    } else {
      history.replace('/signin');
    }
  });
  return <div />;
}
