import { useUserContext } from 'services/hooks/useUser';

const methodArray = [];

export const applicationFields = [
  {
    title: 'First Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'name',
  },
  {
    title: 'Last Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'last_name',
  },
  {
    title: 'Mobile Phone Number',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'phone_number',
  },
  {
    title: 'Email',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'email',
  },
  {
    title: 'SSN',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'ssn',
    // pad: true,
  },
  {
    title: 'Date Of Birth',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'birthday',
    // updateField: true,
    // pad: true,
  },
  {
    title: "Add applicant's employment and income information.",

    type: 'text',
    inputType: 'text',
    size: '12',
    fieldName: '',
  },
  {
    title: 'Employment Type',
    type: 'dropdown',
    inputType: 'dropdown',
    dropDownValues: ['Employed', 'Self Employed', 'Retired'],
    size: '12',
    fieldName: 'employment_type',
  },
  {
    title: 'Occupation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'occupation',
  },
  {
    title: 'Employer Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'employer_name',
  },
  {
    title: 'Employer Zip',
    type: 'number',
    inputType: 'string',
    size: '12',
    fieldName: 'employer_zip',
  },
  {
    title: 'Applicant Annual Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'anual_income',
  },
  {
    title: 'Other Household Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'other_household_income',
  },
];
export const coApplicantFields = [
  {
    title: 'First Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_name',
  },
  {
    title: 'Last Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_last_name',
  },
  {
    title: 'Mobile Phone Number',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_phone_number',
  },
  {
    title: 'Email',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_email',
  },
  {
    title: 'SSN',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_ssn',
    // pad: true,
  },
  {
    title: 'Date Of Birth',
    type: 'date',
    inputType: 'date',
    size: '12',
    fieldName: 'coapplicant_birthday',
    // updateField: true,
    // pad: true,
  },
  {
    title: "Add applicant's employment and income information.",
    type: 'text',
    inputType: 'text',
    size: '12',
    fieldName: '',
  },
  {
    title: 'Employment Type',
    type: 'dropdown',
    inputType: 'dropdown',
    dropDownValues: ['Employed', 'Self Employed', 'Retired'],
    size: '12',
    fieldName: 'coapplicant_employment_type',
    financialField: true,
  },
  {
    title: 'Occupation',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_occupation',
    financialField: true,
  },
  {
    title: 'Employer Name',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_employer_name',
    financialField: true,
  },
  {
    title: 'Employer Zip',
    type: 'string',
    inputType: 'string',
    size: '12',
    fieldName: 'coapplicant_employer_zip',
    financialField: true,
  },
  {
    title: 'Annual Income',
    type: 'currency',
    inputType: 'currency',
    size: '12',
    fieldName: 'coapplicant_anual_income',
    financialField: true,
  },
];
export const propertyFields = [
  {
    title: 'Address',
    type: 'address',
    inputType: 'address',
    sectionTitle: 'Property Information',
    size: '12',
    fieldName: 'street_address',
  },

  {
    title: 'City',
    type: 'string',
    inputType: 'string',
    size: '4',
    fieldName: 'city',
  },
  {
    title: 'State',
    type: 'autoComplete',
    inputType: 'autoComplete',
    // type: 'string',
    // inputType: 'string',
    size: '4',
    fieldName: 'state',
  },
  {
    title: 'Zip Code',
    inputType: 'string',
    type: 'number',
    size: '4',
    fieldName: 'zip_code',
  },
  {
    title: 'Own',
    type: 'dropdown',
    inputType: 'dropdown',
    defaultValue: 'Yes',
    dropDownValues: ['Yes','No'],
    size: '4',
    fieldName: 'owner',
  },
  {
    title: 'Project Amount',
    inputType: 'currency',
    type: 'currency',
    size: '6',
    fieldName: 'request_amount',
  },
];

export const finalFieldsSection = {
  "Applicant's Information": [
    {
      title: 'Full Name',
      type: 'string',
      size: '6',
      fieldName: 'fullName',
      editable: false,
    },
    {
      title: 'SSN',
      type: 'string',
      size: '6',
      fieldName: 'ssn',
      editable: false,
    },
    {
      title: 'Email',
      type: 'string',
      size: '6',
      fieldName: 'email',
      editable: false,
    },
    {
      title: 'DOB',
      type: 'string',
      size: '6',
      fieldName: 'dob',
      editable: false,
    },

    {
      title: 'US Citizen',
      type: 'dropdown',
      size: '6',
      dropDownValues: ['Yes'],
      defaultValue: 'Yes',
      fieldName: 'usCitizen',
      editable: false,
    },
    {
      title: 'Annual Income',
      type: 'string',
      size: '6',
      fieldName: 'anual_income',
      editable: false,
    },

    {
      title: 'Mobile Phone Number',
      type: 'string',
      inputType: 'tel',
      size: '6',
      fieldName: 'phone_number',
      editable: true,
    },
    {
      title: 'Active Military',
      type: 'dropdown',
      size: '6',
      dropDownValues: ['Yes', 'No'],
      defaultValue: '',
      fieldName: 'active_military',
      editable: true,
    },
    {
      title: 'Driving License State',
      type: 'autoComplete',
      size: '6',
      fieldName: 'driver_license_state',
      editable: true,
    },

    {
      title: 'Driving License Number',
      type: 'string',
      size: '6',
      fieldName: 'driver_license_number',
      editable: true,
    },

    {
      title: 'Occupation',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'occupation',
      editable: false,
    },
    {
      title: 'Employment Type',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'employment_type',
      editable: false,
    },
    {
      title: 'Employer Name',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'employer_name',
      editable: false,
    },
    {
      title: 'Employer Zip',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'employer_zip',
      editable: false,
    },
  ],

  "Co-Applicant's Information": [
    {
      title: 'Full Name',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_fullName',
      editable: false,
    },
    {
      title: 'SSN',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_ssn',
      editable: false,
    },
    {
      title: 'Email',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_email',
      editable: false,
    },
    {
      title: 'DOB',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_dob',
      editable: false,
    },
    {
      title: 'US Citizen',
      type: 'dropdown',
      size: '6',
      dropDownValues: ['Yes'],
      defaultValue: 'Yes',
      fieldName: 'usCitizen',
      editable: false,
    },
    {
      title: 'Annual Income',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_anual_income',
      editable: false,
    },
    {
      title: 'Mobile Phone Number',
      type: 'string',
      inputType: 'tel',
      size: '6',
      fieldName: 'coapplicant_phone_number',
      editable: true,
    },
    {
      title: 'Active Military',
      type: 'dropdown',
      size: '6',
      dropDownValues: ['Yes', 'No'],
      defaultValue: '',
      fieldName: 'coapplicant_active_military',
      editable: true,
    },
    {
      title: 'Driving License State',
      type: 'stateDropDown',
      size: '6',
      fieldName: 'coapplicant_driver_license_state',
      editable: true,
    },

    {
      title: 'Driving License Number',
      type: 'string',
      size: '6',
      fieldName: 'coapplicant_driver_license_number',
      editable: true,
    },
    {
      title: 'Occupation',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'coapplicant_occupation',
      editable: false,
    },
    {
      title: 'Employment Type',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'coapplicant_employment_type',
      editable: false,
    },
    {
      title: 'Employer Name',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'coapplicant_employer_name',
      editable: false,
    },
    {
      title: 'Employer Zip',
      type: 'string',
      inputType: 'string',
      size: '6',
      fieldName: 'coapplicant_employer_zip',
      editable: false,
    },
  ],
  'Property Information': [
    {
      title: 'Address',
      type: 'string',
      size: '12',
      fieldName: 'address',
      editable: false,
    },
    {
      title: 'Requested Amount',
      type: 'string',
      size: '6',
      fieldName: 'request_amount',
      editable: false,
    },
  ],
  'Treatment Information': [
    {
      title: 'Treatment Date',
      type: 'date_picker',
      size: '6',
      fieldName: 'service_date',
      editable: true,
    },
  ],
  'Update Information' :
  [
    {
      title: 'Update',
      class: 'updateButton',
      type: 'updateButton',
      size: '12',
      fieldName: 'updateFields',
    },
    {
      title: 'Cancel',
      class: 'updateButton',
      type: 'cancelButton',
      size: '3',
      fieldName: 'updateFields',
    },
    {
      title: 'Why are only some fields editable?',
      class: 'inviteButton',
      type: 'buttonLink',
      size: '3',
      fieldName: 'updateFields',
    },
  ],
  'User Account': [
    {
      title: 'Email',
      type: 'string',
      size: '6',
      fieldName: 'email',
    },
    {
      title: 'Re-send Invite',
      type: 'button',
      size: '6',
      class: 'inviteButton',
      fieldName: 'confirmEmailButt',
    },
    {
      title: 'Co-Applicant Email',
      type: 'coapplicant_email',
      size: '6',
      fieldName: 'coapplicant_email',
      coAppField: true,
    },
    {
      title: 'Update Email',
      type: 'coAppButton',
      size: '6',
      class: 'inviteButton',
      fieldName: 'updateEmailButt',
      coAppField: true,
    },
  ],
  Documentation: [
    {
      title: 'Install Loan Contract',
      type: 'uploadInput',
      size: '12',
      fieldName: 'installLoan',
    },
  ],
  'Loan Contract': [
    {
      title: 'Loan Purpose',
      type: 'dropdown',
      size: '6',
      fieldName: 'project_type',
      editable: true,
      // dropDownValues: "businessTypes",
    },
    {
      title: 'Loan Purpose Description',
      type: 'string',
      size: '6',
      fieldName: 'unit_description',
      editable: true,
    },

    {
      title: 'Sign Contract Through',
      type: 'dropdown',
      size: '6',
      // dropDownValues: getDropDownValues(),
      defaultValue: 'Email',
      fieldName: 'signatureMethod',
    },
    {
      title: 'Send Contract',
      class: 'inviteButton',
      type: 'button',
      size: '6',
      fieldName: 'contract',
    },
  ],
};
