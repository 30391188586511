import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import { MaterialCarousel } from 'components/MaterialCarousel';

import DashboardImg from 'assets/images/5DashboardBanner-Mobile.jpg';
import DashboardImg2 from 'assets/images/dashboard-md.jpg';
import DashboardImg3 from 'assets/images/5DashboardBanner-Desktop.jpg';

const useStyles = makeStyles(theme => ({
  img: {
    display: 'block',
    overflow: 'hidden',
    width: '100%',
    borderRadius: 4,
  },
}));

const ResponsiveItem = ({ classes, mobile, mid, lg }) => {
  return (
    <>
      <Hidden smUp>
        <img className={classes.img} src={mobile} alt="" />
      </Hidden>
      <Hidden xsDown mdUp>
        <img className={classes.img} src={mid} alt="" />
      </Hidden>
      <Hidden mdDown>
        <img className={classes.img} src={lg} alt="" />
      </Hidden>
    </>
  );
};

const items = classes => {
  return [
    <ResponsiveItem
      classes={classes}
      mobile={DashboardImg3}
      mid={DashboardImg3}
      lg={DashboardImg3}
    />,
    /* <img className={classes.img} src={DashboardImg3} alt="" />, */
  ];
};

export const CarouselCard = () => {
  const classes = useStyles();
  return <MaterialCarousel items={items(classes)} />;
};
