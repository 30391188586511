import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';

const textColorStyle = ({ value, disabled }) => {
  if (disabled) {
    return 'rgba(30,39,46,0.4)';
  }
  return value ? '#1E272E' : 'rgba(30,39,46,0.4)';
};

const useStyles = makeStyles(() => ({
  informationItemWrapper: {
    backgroundColor: '#F9FAFC',
    marginBottom: 18,
    paddingBottom: 25,
    paddingLeft: 22,
    paddingTop: 18,
    '& .informationItem': {
      marginBottom: 12,
    },
  },
  informationItemTitle: {
    color: '#1E272E',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.7,
    lineHeight: '18px',
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 22,
  },
  informationItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 22,
    width: '100%',
  },
  informationItemDescription: {
    color: textColorStyle,
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  informationItemValue: {
    color: textColorStyle,
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '12px',
  },
  // Project Total Amount
  debtInformation: {
    backgroundColor: '#C1C9D133',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 32,
  },
  totalAmount: {
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,

    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  totalAmountValue: {
    color: '#1C272F',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
  monthlyPayment: {
    color: '#1DD1A1',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  monthlyPaymentValue: {
    color: '#1DD1A1',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
}));

export const InformationItem = ({
  title,
  value,
  numberFormat = false,
  disabled,
  align,
  padding,
}) => {
  const classes = useStyles({ value, disabled });
  return (
    <div
      className={clsx('informationItem', classes.informationItem)}
      style={{ padding: typeof padding === 'number' ? padding : undefined }}
    >
      {title && (
        <Typography className={classes.informationItemDescription}>
          {title}
        </Typography>
      )}
      <Typography
        className={
          title
            ? classes.informationItemValue
            : classes.informationItemDescription
        }
        style={{
          margin: (() => {
            switch (align) {
              case 'left':
                return '0 auto 0 0';
              case 'right':
                return '0 0 0 auto';
              case 'center':
                return '0 auto';
              default:
                return 0;
            }
          })(),
        }}
      >
        {value ? (
          numberFormat ? (
            <NumberFormat
              value={value}
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          ) : (
            value
          )
        ) : (
          '--'
        )}
      </Typography>
    </div>
  );
};
