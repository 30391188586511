import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, makeStyles, TextField } from '@material-ui/core';
import * as yup from 'yup';
import { useAlertContext } from 'components/AlertContext';
import { protectedPost } from 'services/http';
// Validations Schemas
const homeownerSchema = yup.object({
  email: yup
    .string()
    .email('Wrong homeowner email address')
    .required('Homeowner email is required'),
});
const coapplicantSchema = yup.object({
  email: yup.string().email('Wrong coapplicant email address').nullable(),
});

// Styles
const useStyles = makeStyles((theme) => {
  return {
    container: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: 24,
      },
    },
    editSection: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '2rem',
    },
    editSection__label: {
      color: theme.palette.primary.main,
      marginBottom: '0.5rem',
    },

    inputRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'start',

      '& > *': {
        maxWidth: '50%',
        '&:first-child': {
          marginRight: '1rem',
        },
        '&:last-child': {
          marginLeft: '1rem',
        },
      },
    },
    outlinedTextInput: {
      width: '100%',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1E272E',
      },
    },
    saveButtonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    saveButton: {
      backgroundColor: `${theme.palette.primary.main}`,
      color: 'white',
      fontWeight: 'bolder',
      padding: '0.5rem 2rem',
    },
  };
});

// Edit Section wrapper
const EditSection = ({ title, classes, children }) => (
  <Box className={classes.editSection}>
    <Box className={classes.editSection__label}>{title}</Box>
    <Box className={classes.inputRow}>{children}</Box>
  </Box>
);

const ContactTab = ({ application }) => {
  const classes = useStyles();
  const [iCoapplicantData, setICoapplicantData] = useState(null);
  const [iHomeownerData, setIHomeownerData] = useState(null);

  const [coapplicantData, setCoapplicantData] = useState(null);
  const [homeownerData, setHomeownerData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const [canSave, setCanSave] = useState(false);
  useEffect(() => {
    const { coapplicantData, homeownerData } = application || {
      coapplicantData: null,
      homeownerData: null,
    };
    // Saving initial data
    setIHomeownerData(homeownerData);
    // Saving copy for edit
    setHomeownerData(homeownerData);
    setCoapplicantData(coapplicantData);
    setICoapplicantData(coapplicantData);
  }, []);
  const { setErrorAlertMessage } = useAlertContext();

  useEffect(() => {
    const homeownerFields = ['email'];
    const coApplicantFields = ['email'];
    let hasChanges = false;
    if (homeownerData) {
      homeownerFields.forEach((field) => {
        if (homeownerData[field] !== iHomeownerData[field]) {
          hasChanges = true;
        }
        if (hasChanges) {
          return null;
        }
      });
    }
    if (coapplicantData) {
      coApplicantFields.forEach((field) => {
        if (coapplicantData[field] !== iCoapplicantData[field]) {
          hasChanges = true;
        }
        if (hasChanges) {
          return null;
        }
      });
    }
    setCanSave(hasChanges);
  }, [coapplicantData, homeownerData]);

  const handleChangeContactInfo = useCallback(
    async (payload) => {
      const applicationId = application.applicationData.id;
      const url = `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationId}/applicant_email_change_order`;
      try {
        await protectedPost(url, payload);
        window.location.reload();
      } catch (e) {
        setErrorAlertMessage(
          e?.response?.data?.message || 'There was an error updating your info',
        );
        setIsSaving(false);
      }
    },

    [application],
  );

  return homeownerData ? (
    <div className={classes.container}>
      <EditSection title="HOMEOWNER" classes={classes}>
        <TextField
          className={classes.outlinedTextInput}
          label="Email"
          name="email"
          type="email"
          variant="outlined"
          value={homeownerData.email}
          onChange={(e) =>
            setHomeownerData((old) => ({ ...old, email: e.target.value }))
          }
        />
      </EditSection>

      {application.meta.hasCoapplicant ? (
        <EditSection title="COAPPLICANT" classes={classes}>
          <TextField
            className={classes.outlinedTextInput}
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            value={coapplicantData.email || ''}
            onChange={(e) =>
              setCoapplicantData((old) => ({
                ...old,
                email: e.target.value,
              }))
            }
          />
        </EditSection>
      ) : null}
      <Box className={classes.saveButtonWrapper}>
        <Button
          variant="contained"
          color="primary"
          className={classes.saveButton}
          disabled={!canSave || isSaving}
          onClick={async () => {
            try {
              await homeownerSchema.validate(homeownerData);
              await coapplicantSchema.validate(coapplicantData);
              handleChangeContactInfo({
                applicant_email: homeownerData.email,
                coapplicant_email: coapplicantData.email || null,
              });
              setIsSaving(true);
            } catch (e) {
              if (e && typeof e?.message === 'string') {
                setErrorAlertMessage(e.message);
              } else {
                setErrorAlertMessage('Unknown error');
              }
            }
          }}
        >
          Save Changes
        </Button>
      </Box>
    </div>
  ) : (
    <Box className={classes.inputRow}>Loading...</Box>
  );
};

export { ContactTab };
