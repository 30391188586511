import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { makeStyles, Grid } from '@material-ui/core';

import Resume from './Resume';

const useStyles = makeStyles(() => ({
  container: {
    paddingBottom: 22,
    paddingLeft: 22,
  }
}));

const LoanTab = ({
  setTerm,
  applicationDetail,
  values,
  loanDetails,
  originalLoanDetails,
  rawApplicationsEndpointResponse
}) => {
  const { setFieldValue } = useFormikContext();
  useEffect(() => {
    if (firstTime) {
      return;
    }
    setTerm(15);
    setFieldValue('ohNo', false);
    setFieldValue('ohNoMonths', '');
    setFieldValue('equalPay', false);
    setFieldValue('equalPayMonths', '');
    setFieldValue('buydown', false);
    setFieldValue('buydownPoints', '');
  }, [values.dealStructId]);

  const [firstTime, setFirstTime] = useState(true);
  useEffect(() => {
    if (firstTime) {
      setFirstTime(false);
      return;
    }

    if (values.ohNo) {
      setTerm(15);
      setFieldValue('equalPay', false);
      setFieldValue('equalPayMonths', '');
      setFieldValue('buydown', false);
      setFieldValue('buydownPoints', '');
    }
    if (values.equalPay) {
      setFieldValue('ohNo', false);
      setFieldValue('ohNoMonths', '');
      setFieldValue('buydown', false);
      setFieldValue('buydownPoints', '');
      setTerm(values.equalPayMonths / 12);
    }
    if (values.buydown) {
      setFieldValue('equalPay', false);
      setFieldValue('equalPayMonths', '');
      setFieldValue('ohNo', false);
      setFieldValue('ohNoMonths', '');
    }
  }, [values.ohNo, values.equalPay, values.equalPayMonths, values.buydown]);
  const classes = useStyles();
  return (
    <Grid container className={classes.container} spacing={1}>
        <Resume
          loanDetails={loanDetails}
          applicationDetail={applicationDetail}
          originalLoanDetails={originalLoanDetails}
          rawApplicationsEndpointResponse={rawApplicationsEndpointResponse}
        />
      
    </Grid>
  );
};

export default LoanTab;
