import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { OutlinedTextField } from 'components/InputFields/InputField';
import PublicCompanyActive from 'assets/icons/public-company-active.png';
import PublicCompanyInactive from 'assets/icons/public-company-inactive.png';
import PrivateCompanyActive from 'assets/icons/private-company-active.png';
import PrivateCompanyInactive from 'assets/icons/private-company-inactive.png';
import StepDescription from 'components/StepDescription';
import { SsnField } from 'components/InputFields/SsnField';
import { EinField } from 'components/InputFields/EinField';

import CompanyTypeOption from './CompanyTypeOption';

const validationSchemaFn = () => {
  const schema = Yup.object({
    companyType: Yup.mixed().oneOf(['private_business', 'public_business']),
    legalBusinessName: Yup.string().required('Legal business name is required'),
    EIN: Yup.string()
      .trim()
      .required('Field Required')
      .when('companyType', {
        is: (value) => value === 'public_business',
        then: Yup.string().matches(/^(\d{2})-(\d{7})$/gi, {
          excludeEmptyString: true,
          message: 'Invalid Ein',
        }),
        otherwise:
          Yup.string().matches(/^((\d{3})-(\d{2})-(\d{4}))|((\d{2})-(\d{7}))$/gi, {
            excludeEmptyString: true,
            message: 'Invalid Ein/Ssn',
          }) ,
      }),
  }).nullable();
  return Yup.object({
    companyDetails: schema,
  });
};

const useStyles = makeStyles((theme) => ({
  companyOptionContainer: {
    justifyContent: 'space-around',
    padding: '3rem 0.75rem',
    [theme.breakpoints.up('md')]: {
      padding: '3rem',
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      padding: '0 0.75rem',
    },
  },
}));

const SpacedGrid = withStyles(() => ({
  root: {
    padding: '0.75rem',
  },
}))(Grid);

const CompanyDetails = ({ isActive, setChildCallbacks }) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get validationSchema() {
          return validationSchemaFn();
        },
      });
    }
    return () => {};
  }, [isActive]);

  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();
  const companyTypeSelected = values.companyDetails.companyType;

  const handleCompanyOptionClick = (companyType) => () => {
    setFieldValue('companyDetails.companyType', companyType);
    setFieldValue('companyDetails.EIN', '');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <StepDescription
          title="Type of Company"
          subtitle="Please select your company type and complete the information below."
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" className={classes.companyOptionContainer}>
          <CompanyTypeOption
            companyType="public company"
            imageActive={PublicCompanyActive}
            imageInactive={PublicCompanyInactive}
            isSelected={companyTypeSelected === 'public_business' ? 1 : 0}
            onClick={handleCompanyOptionClick('public_business')}
          />
          <CompanyTypeOption
            companyType="private company"
            imageActive={PrivateCompanyActive}
            imageInactive={PrivateCompanyInactive}
            isSelected={companyTypeSelected === 'private_business' ? 1 : 0}
            onClick={handleCompanyOptionClick('private_business')}
          />
        </Box>
      </Grid>
      <SpacedGrid item xs={12} md={6}>
        <OutlinedTextField
          label="Legal Business Name"
          name="companyDetails.legalBusinessName"
        />
      </SpacedGrid>
      <SpacedGrid item xs={12} md={6}>
        {companyTypeSelected === 'private_business' ? (
          <SsnField label="SSN" name="companyDetails.EIN" ssnEin />
        ) : (
          <EinField label="EIN" name="companyDetails.EIN" />
        )}
      </SpacedGrid>
    </Grid>
  );
};

export default CompanyDetails;
