import React, { useState, useEffect } from 'react';
import { map } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import { protectedGet } from 'services/http';
import to from 'await-to-js';
import { useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useRedirectOnDealerStatus } from 'services/hooks/useRedirectOnDealerStatus';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import { PageTable } from 'components/PageTable';
import {
  PageTableHeader,
  QueryFilter,
  ColumnToggler,
  StateFilter,
} from 'components/PageTable/Header';
import { TableActionButton } from 'components/Buttons/NewApplicationButton';

import { PageTableWrapper, tableStyles } from 'pages/ApplicationQueue';
import { useTableSetup } from 'services/hooks/useTableSetup';
import usePageTableParameters from 'services/hooks/usePageTableParameters';
import { useErrorAlertMessage } from 'components/AlertContext';
import { notifyErrorWithRedirect } from 'services/utils/notifyErrorWithRedirect';
import { useUserContext } from 'services/hooks/useUser';
import { useDealerListContext } from 'services/contexts/useDealerList';
import { AutocompleteField } from 'components/InputFields/AutocompleteField';

const headCells = [
  {
    id: 'id',
    label: 'Merchant Code',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'businessType',
    label: 'Business Type',
  },
];
function useTableData() {
  const [rows, setRows] = useState([]);
  return [
    map((item) => {
      const rowCells = [];

      rowCells.push({
        type: 'link',
        value: `/dealerships/${item.id}/edit`,
        subValue: `${item.dealerCode}`.padStart(4, '0'),
        state: { dealershipId: item.id },
      });

      rowCells.push({
        value: item?.business?.name,
      });

      rowCells.push({
        value: item?.businessType || '',
      });

      return rowCells;
    }, rows),
    setRows,
  ];
}

export const DealershipList = () => {
  useRedirectOnDealerStatus();
  const classes = tableStyles();
  const history = useHistory();

  const [params, setParams] = useState({
    status: 'submitted',
  });
  const [rows, setRows] = useTableData();
  const { ...tableMethods } = usePageTableParameters({
    initialOrder: 'id',
  });
  const { page, perPage, order, sort } = tableMethods;
  // const {
  //   isOpen: isBulkAssigning,
  //   onOpen: onOpenBulkAssign,
  //   onClose: onCloseBulkAssign,
  // } = useDisclosure(false);

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  const errorAlertMessage = useErrorAlertMessage();
  const { dealerLevelRole } = useUserContext();
  const { dealerNetworkOptions } = useDealerListContext();
  const [currentValue, getCurrentValue] = useState('');
  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      const [err, dealerships] = await to(
        protectedGet('/v1/dealer_networks', {
          params: decamelizeKeys({
            ...params,
            page: page + 1,
            perPage,
            sort,
            order: decamelize(order),
            dealerNetworkId: currentValue ? currentValue : null,
          }),
        }),
      );
      setIsLoading(false);
      if (err) {
        notifyErrorWithRedirect(err, errorAlertMessage, history, '/dashboard');
        return;
      }
      const res = normalize(camelizeKeys(dealerships.data)).get([
        'id',
        'name',
        'businessType',
        'business.name',
        'dealerCode',
      ]);
      if (res.name) {
        res.homeowner = { name: res.name, lastName: res.lastName };
      }
      setTotalItems(Number(dealerships.headers.total));
      setRows(res);
    }
    fetch();
    tableMethods.setSelected({});
  }, [params, page, perPage, sort, order, currentValue]);
  const {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  } = useTableSetup({ rows, params, headCells });
  const [initialValues, setInitialValues] = useState({
    dealerList: '',
  });

  return (
    <>
      <PageTableWrapper isLoading={isLoading}>
        <PageTableHeader>
          <StateFilter
            possibleStatuses={['submitted']}
            status={params.status}
            setParams={setParams}
          />
          <Box className={classes.dealerRightFilterWrapper}>
            {dealerLevelRole === 'super_admin' && (
              <Grid item xs={12} md={6}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={null}
                  enableReinitialize
                  className={classes.filter}
                >
                  {({ values }) => {
                    return (
                      <>
                        <Form>
                          <AutocompleteField
                            style={{ width: '200px' }}
                            placeholder="Filter by dealer"
                            name="dealerList"
                            options={dealerNetworkOptions}
                            disabled={false}
                            getCurrentValue={getCurrentValue}
                            type="dealerFilter"
                          />
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              </Grid>
            )}
            {/* <QueryFilter query={params.query} setParams={setParams} /> */}
            <Hidden mdDown>
              <ColumnToggler
                columns={initialColumns.current}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
              />
            </Hidden>
          </Box>
        </PageTableHeader>
        <PageTable
          totalItems={totalItems}
          rows={filteredRows}
          headCells={filteredHeadCells}
          isLoading={isLoading}
          page={params.page}
          perPage={params.perPage}
          setParams={setParams}
          emptyStateTitle="No Dealer, yet."
          emptyStateDesc="Create a New Dealer using the upper-right button"
          itemType={`${totalItems > 1 ? 'Dealers' : 'Dealer'}`}
          onRowClick={onRowClick}
          {...tableMethods}
          hideCheckboxes
        />
      </PageTableWrapper>
      {/* <SelectedMenu selected={selected}>
        <SelectedMenuActionButton
          icon="people"
          label="Bulk Re-Assignment"
          onClick={onOpenBulkAssign}
        />
        <SelectedMenuActionButton icon="archive" label="Archive Dealership" />
        <SelectedMenuActionButton icon="cancel" label="Cancel Dealership" />
      </SelectedMenu>
      <ActionModal isOpen={isBulkAssigning} onClose={onCloseBulkAssign} /> */}
    </>
  );
};

export const DealershipListHeaderActions = () => {
  const history = useHistory();
  return (
    <Box>
      <TableActionButton
        text="New Merchant"
        onClick={() => history.push('/dealerships/new')}
      />
    </Box>
  );
};
