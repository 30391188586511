import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
}));

const LinkText = ({
  link,
  variant,
  textColor,
  textFontSize,
  textLineHeight,
  textLetterSpacing,
  textFontWeight,
  text,
  newWindow = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Typography
      className={classes.link}
      component="span"
      variant={variant}
      style={{
        color: textColor || theme.palette.primary.main,
        display: 'inline',
        fontSize: textFontSize,
        fontWeight: textFontWeight,
        lineHeight: textLineHeight,
        letterSpacing: textLetterSpacing,
      }}
    >
      <a
        style={{ color: textColor || theme.palette.primary.main }}
        href={link}
        target={newWindow ? '_blank' : undefined}
        rel="noreferrer"
      >
        {text}
      </a>
    </Typography>
  );
};

export default React.memo(
  LinkText,
  (oldProps, newProps) =>
    oldProps.link === newProps.link || oldProps.text === newProps.text,
);
