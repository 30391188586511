import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';

import ContainedButton from 'components/Buttons/ContainedButton';
import { useFormikContext } from 'formik';

const useStyles = makeStyles(() => ({
  button: {
    marginTop: '1rem',
  },
}));

function SettingsSectionAction({ children, ...props }) {
  const { values, errors } = useFormikContext();

  const classes = useStyles();
  return (
    <Grid item xs={12} className={classes.button}>
      <ContainedButton
        variant="contained"
        color="primary"
        type="submit"
        errors={errors}
        disabledOptions={values}
        {...props}
      >
        {children}
      </ContainedButton>
    </Grid>
  );
}

export default SettingsSectionAction;
