import React from 'react';
import { makeStyles, IconButton, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Info from '@material-ui/icons/Info';
import StatusButton from 'components/Buttons/StatusButton';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  applicationTitleWrapper: {
    alignItems: 'center',
    display: 'flex',
  },
  cancelApplication: {
    marginLeft: 'auto',
  },
  applicationAdvice: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
    letterSpacing: 0.26,
    color: theme.palette.text.darkGray,
    [theme.breakpoints.up('md')]: {
      marginLeft: '2.625rem',
    },
  },
  applicationAdvice__Icon: {
    marginRight: '0.5rem',
    color: theme.palette.text.darkGray,
    fontSize: '0.875rem',
  },
  detailsWrapper: {
    display: 'flex',
    marginTop: '1rem',
    [theme.breakpoints.up('md')]: {
      marginLeft: '2.625rem',
    },
  },
  detailItem: {
    color: theme.palette.text.darkGray,
    fontSize: '0.625rem',
    fontWeight: 600,
    lineHeight: '1rem',
    letterSpacing: 0.5,
    marginRight: '0.75rem',
  },
  dataItem: {
    color: theme.palette.text.primary,
    display: 'block',
    fontSize: '0.875rem',
    fontWeight: 400,
    letterSpacing: 0.65,
    lineHeight: '1.125rem',
  },
  subtitleItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 4,
    marginLeft: 4,
  },
  cancelApp: {
    color: theme.palette.text.darkGray,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: 0.55,
    fontWeight: 600,
    [`&:hover`]: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
}));

const ApplicationDescriptionHeader = ({
  onGoBack,
  applicationNumber,
  defiApplicationNumber,
  handleClickOpen,
  headerData,
  isProject,
}) => {
  const classes = useStyles();
  return headerData ? (
    <>
      <div className={classes.applicationTitleWrapper}>
        <IconButton aria-label="go back to dashboard" onClick={onGoBack}>
          <ChevronLeftIcon />
        </IconButton>
        <p>
          Application {applicationNumber}
          <span className={classes.subtitleItem}>
            {defiApplicationNumber != null ? `D${defiApplicationNumber}` : ''}
          </span>
        </p>
        <StatusButton
          status={headerData.status}
          draftStatus={headerData.draftStatus}
        />
        {(!isProject ||
          ![
            'in_progress',
            'approved',
            'completed',
            'finished',
            'change_requested',
            'canceled',
          ].includes(headerData.status)) &&
          headerData.status !== 'rejected' &&
          headerData.draftStatus !== 'canceled' &&
          headerData.status !== 'expired' && (
            <div className={classes.cancelApplication}>
              <Typography
                className={classes.cancelApp}
                onClick={handleClickOpen}
              >
                CANCEL APPLICATION
              </Typography>
            </div>
          )}
      </div>
      <Typography className={classes.applicationAdvice}>
        <Info className={classes.applicationAdvice__Icon} />
        If there are any changes in your application, please contact PowerPay at
        800-397-4485
      </Typography>
      <div className={classes.detailsWrapper}>
        <Typography className={classes.detailItem}>
          APPLICANT
          <span className={classes.dataItem}>{headerData.homeowner}</span>
        </Typography>
        <Typography className={classes.detailItem}>
          ZIP CODE
          <span className={classes.dataItem}>{headerData.zipCode}</span>
        </Typography>
        <Typography className={classes.detailItem}>
          DATE CREATED
          <span className={classes.dataItem}>
            {format(new Date(headerData.dateCreated), 'MM/dd/yyyy')}
          </span>
        </Typography>
      </div>
    </>
  ) : null;
};

// const ApplicationDescriptionHeaderMemo = React.memo(
//   ApplicationDescriptionHeader,
//   (old, curr) => {
//     // Checking app number
//     if (old.applicationNumber !== curr.applicationNumber) return true;

//     // Checking headerData properties
//     if (old.headerData && curr.headerData) {
//       const keys = Object.keys(old.headerData);
//       for (let i = 0; i < keys.length; i++) {
//         if (
//           typeof old.headerData[keys[i]] !== 'object' &&
//           old.headerData[keys[i]] !== curr.headerData[keys[i]]
//         )
//           return true;
//       }
//     }

//     return true;
//   },
// );
export { ApplicationDescriptionHeader };
// export { ApplicationDescriptionHeaderMemo as ApplicationDescriptionHeader };
