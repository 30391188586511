import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OutlinedTextField } from 'components/InputFields/InputField';
import { DateField } from 'components/InputFields/DateField';
import { PhoneNumberField } from 'components/InputFields/PhoneNumberField';
import { SsnField } from 'components/InputFields/SsnField';
import { SelectField } from 'components/InputFields/SelectField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Text from 'components/TextFields/Text';
import { isValidDate } from 'services/utils';

const getTexts = (isDealer = false, dealerRole = 'dealership') => {
  const HOTexts = {
    stepTitle: 'Personal Information',
    stepSubtitle: 'Let us know more about you',
    stepSubtitleCO: 'This is the information related to your Co-Applicant',
    title: '',
    subtitle: '',
  };
  const dealershipTexts = {
    stepTitle: 'Personal Information',
    stepSubtitle: 'Let us know more about your client',
    stepSubtitleCO: 'This is the information related to the Co-Applicant',
    title: '',
    subtitle: '',
  };
  if (isDealer) {
    switch (dealerRole) {
      case 'dealership':
      default:
        return dealershipTexts;
    }
  } else {
    return HOTexts;
  }
};

const validationSchemaFn = (coapplicant) => {
  const schema = Yup.object({
    name: Yup.string().required('Field Required').trim(),
    lastName: Yup.string().required('Field Required').trim(),
    birthday: Yup.mixed()
      .required('Field Required')
      .test('isDate', 'invalid Date', (val) => val && isValidDate(val)),
    email: Yup.string()
      .required('Field Required')
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/gm, {
        excludeEmptyString: true,
        message: 'invalid Email',
      })
      .email('invalid Email')
      .trim(),
    ssn: Yup.string()
      .required('Field Required')
      .matches(/^(\d{3})-(\d{2})-(\d{4})$/gi, {
        excludeEmptyString: true,
        message: 'Invalid Ssn',
      })
      .trim(),
    activeMilitary: Yup.mixed()
      .oneOf([true, false], 'Field Required')
      .required('Field Required'),
    usCitizen: Yup.mixed()
      .oneOf([true, false], 'Field Required')
      .required('Field Required'),
    phoneNumber: Yup.string()
      .matches(/^(?:\+1)?\(\d{3}\)\d{3}-\d{4}$/gm, {
        excludeEmptyString: true,
        message: 'Invalid Phone Number',
      })
      .trim()
      .nullable(),
  });
  return coapplicant
    ? Yup.object({
        coapplicant: schema,
      })
    : Yup.object({
        homeowner: schema,
      });
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '2rem',
    },
    [theme.breakpoints.up('xl')]: {
      paddingRight: '3rem',
    },
  },
  textField: {
    width: '100%',
  },
  notchedOutline: {
    borderColor: '#1E272E',
  },
}));

export const Step3PersonalInfo = ({
  isActive,
  setChildCallbacks,
  coapplicant,
  isDealer,
  dealerRole,
}) => {
  useEffect(() => {
    if (isActive) {
      setChildCallbacks({
        get stepTitle() {
          return TEXTS.stepTitle;
        },
        get stepSubtitle() {
          return TEXTS.stepSubtitle;
        },

        get validationSchema() {
          return validationSchemaFn(coapplicant);
        },
      });
    }
    return () => {};
  }, [isActive]);

  const classes = useStyles();
  const theme = useTheme();

  const prefix = coapplicant ? 'coapplicant.' : 'homeowner.';

  const TEXTS = getTexts(isDealer, dealerRole);
  return (
    <Grid container spacing={3} className={classes.container}>
      {coapplicant && (
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column">
            <Text
              textColor={theme.palette.text.primary}
              textSize="sm+"
              semibold
            >
              Basic Information
            </Text>
            <Text
              textColor={theme.palette.text.secondary}
              textSize="xs+"
              semibold
            >
              This is the information related to your Co-Applicant
            </Text>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <OutlinedTextField
          label="First Name"
          name={`${prefix}name`}
          type="text"
          disabled={!coapplicant && !isDealer}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedTextField
          label="Last Name"
          name={`${prefix}lastName`}
          type="text"
          disabled={!coapplicant && !isDealer}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DateField label="Date of Birth" name={`${prefix}birthday`} />
      </Grid>
      <Grid item xs={12} md={6}>
        <PhoneNumberField
          id={
            coapplicant ? 'coapplicant-phoneNumber-input' : 'phoneNumber-input'
          }
          label="Phone"
          name={`${prefix}phoneNumber`}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <OutlinedTextField label="Email" name={`${prefix}email`} type="text" />
      </Grid>
      <Grid item xs={12} md={6}>
        <SsnField
          id="homeownerSsn"
          label="Social Security Number"
          name={`${prefix}ssn`}
          type="text"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          label="Active Military Service?"
          name={`${prefix}activeMilitary`}
          type="text"
        >
          <MenuItem value>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          label="US Citizen?"
          name={`${prefix}usCitizen`}
          type="text"
        >
          <MenuItem value>Yes</MenuItem>
          <MenuItem value={false}>No</MenuItem>
        </SelectField>
      </Grid>
    </Grid>
  );
};
