import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { parse } from 'date-fns';
import { path } from 'ramda';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { isValidDate, pathSplit } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  error: {
    '& .MuiFormLabel-root': {
      color: theme.palette.error.main,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '14px',
      marginRight: '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.error.main,
    },
  },
}));

export const DateField = ({
  name,
  label,
  hideErrors,
  disabled,
  minDate,
  maxDate,
  minDateMessage,
  maxDateMessage,
  value,
  priorityValue,
}) => {
  const classes = useStyles();
  const { values, setFieldValue, errors } = useFormikContext();
  const fieldValue = pathSplit(values, name);

  const [date, setDate] = useState(
    (value && priorityValue ? value : false) || fieldValue,
  );

  useEffect(() => {
    if (!priorityValue) {
      if (typeof fieldValue === 'string') {
        setFieldValue(name, parse(fieldValue, 'yyyy-MM-dd', new Date()));
      } else if (fieldValue !== date) {
        setDate(
          typeof fieldValue === 'string'
            ? parse(fieldValue, 'yyyy-MM-dd', new Date())
            : fieldValue,
        );
      }
    }
  }, [fieldValue]);

  const onChange = (date, text) => {
    setDate(text);
    if (date && isValidDate(date)) {
      setFieldValue(name, date);
    } else {
      setFieldValue(name, null);
    }
  };
  const error = path(name.split('.'), errors);
  return (
    <FormControl
      className={clsx(classes.formControl, {
        [classes.error]: Boolean(error),
      })}
      error={Boolean(error)}
    >
      <KeyboardDatePicker
        label={label}
        name={name}
        format="MM/dd/yyyy"
        value={date}
        onChange={onChange}
        inputVariant="outlined"
        disabled={disabled || false}
        minDate={minDate}
        maxDate={maxDate}
        minDateMessage={minDateMessage}
        maxDateMessage={maxDateMessage}
      />
      {!hideErrors && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const DateInput = ({
  name,
  label,
  currentValue,
  onChange: onChangeCb,
  disabled,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState();

  useEffect(() => {
    const parsedDate = parse(currentValue, 'yyyy-MM-dd', new Date());
    if (typeof currentValue === 'string') {
      onChange(parsedDate);
    } else if (currentValue !== value) {
      setValue(typeof currentValue === 'string' ? parsedDate : currentValue);
    }
  }, [currentValue]);

  const onChange = (date, text) => {
    setValue(text);
    if (date && isValidDate(date)) {
      onChangeCb(date);
    } else {
      onChangeCb(null);
    }
  };

  return (
    <FormControl className={clsx(classes.formControl)}>
      <KeyboardDatePicker
        label={label}
        name={name}
        format="MM/dd/yyyy"
        value={value}
        onChange={onChange}
        inputVariant="outlined"
        disabled={disabled || false}
      />
    </FormControl>
  );
};
