import { camelizeKeys } from 'humps';
import normalize from 'json-api-normalize';
import { sort } from 'ramda';

export const parseApplication = (application) => {
  const json = normalize(camelizeKeys(application)).get([
    'ppId',
    'id',
    'draftStatus',
    'status',
    'loanProductCode',
    'driverLicenseUrl',
    'hasDriverLicenses',
    'hasTicketsForApplicant',
    'coapplicantDriverLicenseUrl',
    'name',
    'lastName',
    'hasUnreadMessages',
    'address.zipCode',
    'createdAt',
    'creditLimit',
    'birthday',
    'ssn',
    'activeMilitary',
    'phoneNumber',
    'mobilePhone',
    'email',
    'usCitizen',
    'owner',
    'address.streetAddress',
    'address.addressLine2',
    'address.state',
    'address.city',
    'financialDetails.requestAmount',
    'financialDetails.term',
    'financialDetails.employmentType',
    'financialDetails.employerName',
    'financialDetails.occupation',
    'financialDetails.employerZip',
    'financialDetails.employerTime',
    'financialDetails.userType',
    'coapplicantName',
    'coapplicantLastName',
    'coapplicantBirthday',
    'coapplicantPhoneNumber',
    'coapplicantEmail',
    'coapplicantSsn',
    'coapplicantActiveMilitary',
    'coapplicantUsCitizen',
    'coapplicantDriverLicenseNumber',
    'coapplicantDriverLicenseState',
    'loan.id',
    'documents',
    'inactiveDocuments',
    'enableInactiveDocsSection',
    'deal.teaserAvailable',
    'deal.teaserMonthlyPayment',
    'openStipulations',
    'openStipulationsHomeowner',
    'closedStipulationsHomeowner',
    'closedStipulations',
    'requiredDocs',
    'signedCoapplicant',
    'signedApplicant',
    'allowPhysicalPacs',
    'loanContractSignatureMethods',
    'loanPacSignatureMethods',
    'driverLicenseNumber',
    'driverLicenseState',
    'defiApplicationNumber',
    'programRequiresPac',
    'notices'
  ]);
  json.financialDetails = sort((a) => {
    if (a.userType === 'homeowner') return -1;
    return 1;
  }, json.financialDetails);
  json.meta = camelizeKeys(application?.data?.meta);
  json.included = camelizeKeys(application?.included);
  const applicationDetails = {
    meta: { ...json.meta },
    included: json.included,
    headerData: {
      status: json.status,
      loanProductCode : json.loanProductCode,
      draftStatus: json.draftStatus,
      homeowner: `${json.name} ${json.lastName}`,
      zipCode: json.address.zipCode,
      dateCreated: json.createdAt,
    },
    dealData: {
      teaserAvailable: json.deal?.teaserAvailable,
      monthlyPayment: json.deal?.teaserMonthlyPayment,
    },
    loanRequestData: {
      amountRequested: json.financialDetails[0].requestAmount,
    },
    homeownerData: {
      firstname: json.name,
      lastname: json.lastName,
      birthday: json.birthday,
      ssn: json.ssn,
      creditLimit: json.creditLimit,
      activeMilitary: json.activeMilitary,
      phone: json.mobilePhone || json.phoneNumber,
      email: json.email,
      usCitizen: json.usCitizen,
      owner: json.owner,
      addressName: json.address.streetAddress,
      addressName2: json.address.addressLine2,
      state: json.address.state,
      city: json.address.city,
      driverLicenseNumber: json.driverLicenseNumber,
      driverLicenseState: json.driverLicenseState,
    },
    homeownerFinancesData: {
      employmentType: json.financialDetails[0].employmentType,
      employerName: json.financialDetails[0].employerName,
      occupation: json.financialDetails[0].occupation,
      employerZip: json.financialDetails[0].employerZip,
      employerTime: json.financialDetails[0].employerTime,
    },
    coapplicantData: {
      firstname: json.coapplicantName,
      lastname: json.coapplicantLastName,
      birthday: json.coapplicantBirthday,
      ssn: json.coapplicantSsn,
      activeMilitary: json.coapplicantActiveMilitary,
      phone: json.coapplicantPhoneNumber,
      email: json.coapplicantEmail,
      usCitizen: json.coapplicantUsCitizen,
      owner: json.owner,
      addressName: json.address.streetAddress,
      addressName2: json.address.addressLine2,
      state: json.address.state,
      city: json.address.city,
      driverLicenseNumber: json.coapplicantDriverLicenseNumber,
      driverLicenseState: json.coapplicantDriverLicenseState,
    },
    coapplicantFinancesData: json.financialDetails[1]
      ? {
          employmentType: json.financialDetails[1].employmentType,
          employerName: json.financialDetails[1].employerName,
          occupation: json.financialDetails[1].occupation,
          employerZip: json.financialDetails[1].employerZip,
          employerTime: json.financialDetails[1].employerTime,
        }
      : null,
    applicationData: {
      id: json.id,
      ppId: json.ppId,
      driverLicense: json.driverLicenseUrl,
      coapplicantDriverLicense: json.coapplicantDriverLicenseUrl,
      hasDriverLicenses: json.hasDriverLicenses,
      loanProductCode:json.loanProductCode,
      hasTicketsForApplicant:json.hasTicketsForApplicant,
      documents: [...json.documents],
      inactiveDocuments: [...json.inactiveDocuments],
      enableInactiveDocsSection: json.enableInactiveDocsSection,
      openStipulations: [...json.openStipulations],
      openStipulationsHomeowner: [...json.openStipulationsHomeowner],
      closedStipulationsHomeowner: [...json.closedStipulationsHomeowner],
      closedStipulations: [...json.closedStipulations],
      notices: [...json.notices],
      requestedDocs: json.requiredDocs,
      loan: json.loan ? json.loan.id : null,
      signedApplicant: json.signedApplicant,
      signedCoapplicant: json.meta.hasCoapplicant
        ? Boolean(json.signedCoapplicant)
        : null,
      allowPhysicalPacs: json.allowPhysicalPacs,
      loanContractSignatureMethods: json.loanContractSignatureMethods,
      loanPacSignatureMethods: json.loanPacSignatureMethods,
      hasUnreadMessages: json.hasUnreadMessages,
      defiApplicationNumber: json.defiApplicationNumber,
      programRequiresPac: json.programRequiresPac
    },
  };
  return [json, applicationDetails];
};
