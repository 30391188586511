import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import Text from 'components/TextFields/Text';

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: 16,
    [theme.breakpoints.up('md')]: {
      marginLeft: 32,
    },
  },
  sectionTitle: {
    marginBottom: 24,
  },
  subsection: {
    marginTop: 24,
    marginBottom: 24,
  },
  subsectionItems: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  subsectionItem: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    letterSpacing: 0.55,
    lineHeight: '12px',
    marginRight: 16,
  },
  dataItem: {
    color: '#1E272E',
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.65,
    lineHeight: '18px',
  },
}));

const HomeOwnerFinance = ({ data }) => {
  const classes = useStyles();
  if (data.employmentType === 'employed') {
    return (
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <Text
            textColor="#1E272E"
            textFontSize={16}
            textFontWeight={600}
            textLetterSpacing={0.8}
            textLineHeight="16px"
            textVariant="subtitle1"
            text="HOMEOWNER FINANCES & EMPLOYMENT"
          />
        </div>
        <div className={classes.subsection}>
          <Text
            textColor="#9198A0"
            textFontSize={10}
            textFontWeight={600}
            textLetterSpacing={0.46}
            textLineHeight="12px"
            textVariant="subtitle1"
            text="EMPLOYMENT"
          />
          <div className={classes.subsectionItems}>
            <Typography className={classes.subsectionItem}>
              Employment
              <span className={classes.dataItem}>Employed</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Employer Name
              <span className={classes.dataItem}>{data.employerName}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Employer Zip Code
              <span className={classes.dataItem}>{data.employerZip}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Occupation
              <span className={classes.dataItem}>{data.occupation}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Years of Employment
              <span className={classes.dataItem}>{data.employerTime}</span>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  if (data.employmentType === 'self_employed') {
    return (
      <div className={classes.section}>
        <div className={classes.sectionTitle}>
          <Text
            textColor="#1E272E"
            textFontSize={16}
            textFontWeight={600}
            textLetterSpacing={0.8}
            textLineHeight="16px"
            textVariant="subtitle1"
            text="HOMEOWNER FINANCES & EMPLOYMENT"
          />
        </div>
        <div className={classes.subsection}>
          <Text
            textColor="#9198A0"
            textFontSize={10}
            textFontWeight={600}
            textLetterSpacing={0.46}
            textLineHeight="12px"
            textVariant="subtitle1"
            text="EMPLOYMENT"
          />

          <div className={classes.subsectionItems}>
            <Typography className={classes.subsectionItem}>
              Employment
              <span className={classes.dataItem}>Self Employed</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Title
              <span className={classes.dataItem}>{data.occupation}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Company Name
              <span className={classes.dataItem}>{data.employerName}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Company Zip Code
              <span className={classes.dataItem}>{data.employerZip}</span>
            </Typography>
            <Typography className={classes.subsectionItem}>
              Year of Employment
              <span className={classes.dataItem}>{data.employerTime}</span>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
  return <div />;
};

export default HomeOwnerFinance;
