import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Info from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  additionalInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1rem',
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.6,
    lineHeight: '22px',
    marginTop: '2.25rem',
  },
  additionalInfoTitle: {
    alignItems: 'center',
    color: '#1E272E',
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: 600,
    letterSpacing: 0.65,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
  },
  additionalInfoText: {
    color: '#1E272E',
    fontSize: '0.875rem',
    letterSpacing: 0.65,
    lineHeight: '18px',
    marginLeft: '0.125rem',
    maxWidth: 340,
  },
}));

const AdditionalInfo = ({ title, text, textComponent: TextComponent }) => {
  const classes = useStyles();

  return (
    <div className={classes.additionalInfo}>
      <Typography className={classes.additionalInfoTitle}>
        <Info style={{ marginRight: 7, color: '#9198A0' }} />
        {title}
      </Typography>
      {TextComponent ? (
        TextComponent
      ) : (
        <Typography className={classes.additionalInfoText}>{text}</Typography>
      )}
    </div>
  );
};

export default AdditionalInfo;
