import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import { useField } from 'formik';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
}));

export const SelectField = ({
  name,
  label,
  hideErrors = false,
  children,
  disabled,
  value,
  onChange = () => {},
}) => {
  const classes = useStyles();
  const [field, meta, helpers] = useField({ name });
  field.onChange = (newVal) => {
    if (typeof newVal?.target?.value !== 'undefined') {
      helpers.setValue(newVal.target.value);
      onChange(newVal.target.value);
    }
  };
  useEffect(() => {
    if (value) {
      helpers.setValue(value);
    }
  }, []);
  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      className={classes.formControl}
      error={Boolean(meta.error)}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select labelId={`${name}-label`} label={label} {...field}>
        {children}
      </Select>
      {!hideErrors && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  );
};
