import React, { useState, useEffect } from 'react';
import { map, props } from 'ramda';
import normalize from 'json-api-normalize';
import { camelizeKeys, decamelizeKeys, decamelize } from 'humps';
import {
  protectedGet,
  protectedPost,
  protectedPatch,
  protectedPut,
} from 'services/http';
import to from 'await-to-js';
import { Formik, Form } from 'formik';
import { useDealerListContext } from 'services/contexts/useDealerList';
import { useLoanProductListContext } from 'services/contexts/useLoanProductList';
import { useOrganizationListContext } from 'services/contexts/useOrganizationList';
import { useAffiliateListContext } from 'services/contexts/useAffiliateList';
import { fullName } from 'services/utils';
import { useHistory } from 'react-router-dom';
import { useAlertContext } from 'components/AlertContext';
import { useDisclosure } from 'services/hooks/useDisclosure';
import { useRedirectOnDealerStatus } from 'services/hooks/useRedirectOnDealerStatus';
import { useTableSetup } from 'services/hooks/useTableSetup';
import usePageTableParameters from 'services/hooks/usePageTableParameters';
import { useGlobalTableMethods } from 'services/hooks/useGlobalTableMethods';
import { useDebounce } from 'services/hooks/useDebounce';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Icon from 'components/Icon';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Button, Modal } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import CardContent from '@material-ui/core/CardContent';
import { PageTable } from 'components/PageTable';
import { connect } from 'react-redux';
import { updateData } from 'redux/actions';
import { AutocompleteFieldDropDown } from 'components/InputFields/AutocompleteFieldDropDown';
import {
  PageTableHeader,
  StateFilter,
  QueryFilter,
  ColumnToggler,
} from 'components/PageTable/Header';
import { SelectedMenu } from 'components/PageTable/SelectedMenu';
import { CancelAppDialog } from 'components/CancelAppDialog';
import { SelectedMenuActionButton } from 'components/PageTable/SelectedMenu/ActionButton';
import { AssignmentActionModal } from 'components/PageTable/SelectedMenu/AssignmentActionModal';
import { useUserContext } from 'services/hooks/useUser';
import { format } from 'date-fns';
import { setRedirectPage } from 'services/authentication';
import clsx from "clsx";
import { NewApplicationButton } from '../../components/Buttons/NewApplicationButton';
const appFields = [
  'ppId',
  'id',
  'status',
  'draftStatus',
  'hasUnreadMessages',
  'dealer.id',
  'dealer.name',
  'dealer.role',
  'dealer.lastName',
  'dealer.businessName',
  'name',
  'deal',
  'deal.promoDescription',
  'deal.buydownApplied',
  'deal.promoCode',
  'deal.interestRate',
  'deal.buydownPointsApplied',
  'lastName',
  'homeowner.name',
  'homeowner.lastName',
  'coapplicantName',
  'coapplicantLastName',
  'concierge',
  'address.streetAddress',
  'address.addressLine2',
  'hasCoapplicant',
  'address.state',
  'address.city',
  'address.zipCode',
  'createdAt',
  'canStartProject',
  'financialDetails.requestAmount',
  'financialDetails.userType',
  'signedApplicant',
  'signedCoapplicant',
  'driverLicenseUploaded',
  'coapplicantDriverLicenseUploaded',
  'installContractUploaded',
  'stipPayUploaded',
  'ticketNumber',
  'loan.status',
  'creditLimit',
  'stipPayAccepted',
  'defaultApr',
  'promos',
  'dealerNetworkName',
  'dealerNetworkCode',
  'defiApplicationNumber',
  'expirationDate',
  'merchantRefId',
];
export const clearFilters = () => {
  localStorage.removeItem('affiliateList');
  localStorage.removeItem('organizationList');
  localStorage.removeItem('loanProductList');
  localStorage.removeItem('dealerList');
}
const highLevelRoles = ['organization', 'affiliate', 'super_admin'];
export const tableStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    padding: '2rem 1rem 4rem 1rem',
    [theme.breakpoints.up('md')]: { padding: '2rem 1.25rem 4rem 1.25rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  cardWithoutMin: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 0,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    // minHeight: '32rem',
    // [theme.breakpoints.up('lg')]: {
    //   minHeight: '40rem',
    // },
  },
  card: {
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 0,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    minHeight: '32rem',
    [theme.breakpoints.up('lg')]: {
      minHeight: '40rem',
    },
  },
  cardContent: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: '4px',
    },
    '& .Cell-dealership': {
      minWidth: '200px',
    },
  },
  rightFilterWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dealerRightFilterWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'black',
    border: '2px solid #000',
    boxShadow: 50,
    backgroundColor: 'white',

  },
  clearButton: {
      color: theme.palette.primary.main,
      fontSize: '0.75rem',
      marginLeft: '0.30rem',
      marginBottom: '0.55rem',
  },
}));
export const PageTableWrapper = ({ children, isLoading }) => {
  const classes = tableStyles();
  return (
    <Grid
      container
      className={classes.container}
      style={{
        height: '100%',
      }}
    >
      <Grid item xs={12}>
        <Card
          elevation={4}
          className={isLoading ? classes.card : classes.cardWithoutMin}
        >
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

const handleSubmit = async ({
  app,
  cellKey,
  payload,
  updateRow,
  setAlertMessage,
  setErrorAlertMessage,
}) => {
  const [err] = await to(
    protectedPost(
      `${process.env.REACT_APP_BASE_URL}/v1/applications/${app.id}/documents`,
      payload,
    ),
  );
  if (err) {
    console.error(err);
    setErrorAlertMessage(err?.response?.data?.message || 'Error');
    return;
  }

  setAlertMessage('File was Uploaded Succesfully');
  updateRow({ ...app, [cellKey]: false });
};

const localHeadCells = [
  {
    id: 'ppId',
    label: 'Number',
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Application Date',
    sortable: true


  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    sortable: true

  },
  {
    id: 'merchantRefId',
    label: 'Merchant Reference ID',
    sortable: false

  },
  // {
  //   id: 'dealership',
  //   label: 'Merchant',
  //   levelRoles: ['affiliate', 'organization'],
  // },
  {
    id: 'merchantMember',
    label: 'Merchant Member',
    subLabel: 'Role',
    sortable: true

  },
  {
    id: 'client',
    label: 'Applicant',
    subLabel: 'CoApplicant',
    sortable: false

  },
  {
    id: 'requestAmount',
    label: 'Financed Amount',
    subLabel: 'Credit Limit',
    sortable: false

  },
  {
    id: 'defaultApr',
    label: 'RATE',
    subLabel: 'Promo',
    sortable: false

  },
  {
    id: 'status',
    label: 'Status',
    status: ['submitted', 'canceled', 'all', 'drafted', 'expired', 'expiring'],
    sortable: false

  },
  {
    id: 'unReadMsg',
    label: 'Unread Messages',
    sortable: false,
  },
  {
    id: 'dealSelected',
    label: 'Deal Selected',
    sortable: false,
  },
  {
    id: 'signedByPB',
    label: 'Signed by PB',
    sortable: false,
  },
  {
    id: 'signedByCB',
    label: 'Signed by CB',
    sortable: false,
  },
  {
    id: 'installContract',
    label: 'Install Contract',
    sortable: false,
  },
  {
    id: 'driverLicense',
    label: "PB Driver's  License",
    sortable: false,
  },
  {
    id: 'coapplicantDriverLicense',
    label: "CB Driver's  License",
    sortable: false,
  },
];

const highLevelHeadCells = [
  {
    id: 'ppId',
    label: 'Number',
    sortable: true
  },
  {
    id: 'createdAt',
    label: 'Application Date',
    sortable: true
  },
  {
    id: 'expirationDate',
    label: 'Expiration Date',
    sortable: true

  },
  {
    id: 'merchantRefId',
    label: 'Merchant Reference ID',
    sortable: false


  },
  {
    id: 'dealerNetworkName',
    label: 'Merchant Name',
    subLabel: 'Code',
    sortable: false

  },
  {
    id: 'merchantMember',
    label: 'Merchant Member',
    subLabel: 'Role',
    sortable: true

  },
  {
    id: 'client',
    label: 'Applicant',
    subLabel: 'CoApplicant',
    sortable: false

  },

  {
    id: 'requestAmount',
    label: 'Financed Amount',
    subLabel: 'Credit Limit',
    sortable: false

  },
  {
    id: 'defaultApr',
    label: 'RATE',
    subLabel: 'Promo',
    sortable: false

  },
  {
    id: 'status',
    label: 'Status',
    status: ['submitted', 'canceled', 'all', 'drafted', 'expired', 'expiring'],
    sortable: false
  },

  {
    id: 'unReadMsg',
    label: 'Unread Messages',
    sortable: false,
  },
  {
    id: 'dealSelected',
    label: 'Deal Selected',
    sortable: false,
  },
  {
    id: 'signedByPB',
    label: 'Signed by PB',
    sortable: false,
  },
  {
    id: 'signedByCB',
    label: 'Signed by CB',
    sortable: false,
  },
  {
    id: 'installContract',
    label: 'Install Contract',
    sortable: false,
  },
  {
    id: 'driverLicense',
    label: "PB Driver's  License",
    sortable: false,
  },
  {
    id: 'coapplicantDriverLicense',
    label: "CB Driver's  License",
    sortable: false,
  },
];
const conditionalCells = [
  {
    id: 'startProject',
    label: 'Start',
    sortable: false,
  },
  {
    id: 'cancelApp',
    label: 'Cancel',
    sortable: false,
  },
];
function useLocalTableMethods(setParams) {
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();

  const {
    isOpen: isCancelDialogOpen,
    onOpen: onOpenCancelDialog,
    onClose: onCloseCancelDialog,
  } = useDisclosure(false);
  const [selectedCancelAppId, setSelectedCancelAppId] = useState(null);

  const cancelApp = async () => {
    const [err] = await to(
      protectedPatch(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${selectedCancelAppId}/cancel`,
      ),
    );
    onCloseCancelDialog();
    if (err) {
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }
    setAlertMessage('Application Canceled Succesfully');
    setParams((state) => ({ ...state, refresh: Date.now }));
  };

  return {
    // cancel dialog
    isCancelDialogOpen,
    onOpenCancelDialog,
    onCloseCancelDialog,
    setSelectedCancelAppId,
    cancelApp,
  };
}

export function useTableData(
  setParams,
  canEdit,
  dealerLevelRole,
  updateRefId,
  merchantRefId,
) {
  const [rows, setRows] = useState([]);
  const { setAlertMessage, setErrorAlertMessage } = useAlertContext();
  const { user } = useUserContext();
  const { updateRow } = useGlobalTableMethods(rows, setRows);
  const {
    isCancelDialogOpen,
    onOpenCancelDialog,
    onCloseCancelDialog,
    setSelectedCancelAppId,
    cancelApp,
  } = useLocalTableMethods(setParams);
  return [
    map((app) => {
      const rowCells = [];
      const message = app.message;

      const hasStarted = [
        'in_progress',
        'completed',
        'refunded',
        'finished',
        'refunded',
        'change_requested',
      ].includes(app?.loan?.status);

      const draft = app.status === 'pending' && app.draftStatus === 'drafted';
      let urlValue = draft
        ? `/applications/new`
        : `/dealer/applications/${app.id}`;

      if (
        app.status === 'rejected' ||
        app.draftStatus === 'canceled' ||
        app.status === 'expired'
      ) {
        urlValue = `/dealer/applications/${app.id}`;
        // no-op - use old routes
      } else if (
        sessionStorage.getItem('flow') === 'new' &&
        !hasStarted &&
        draft
      ) {
        urlValue = `/applications/${app.id}/incomplete`;
      }
      //  else if (
      //   sessionStorage.getItem('flow') === 'new' &&
      //   !hasStarted &&
      //   app.status === 'rejected'
      // ) {
      //   urlValue = `/applications/${app.id}/declined`;
      // }
      else if (sessionStorage.getItem('flow') === 'new' && !hasStarted) {
        urlValue = app.dealSelected
          ? `/applications/${app.id}/complete`
          : `/applications/${app.id}/review`;
      } else {
        // no-op
      }

      const uploadFileCb = (file, fileType, cellKey) => {
        const data = new FormData();
        data.append('application_id', Number(app.id));
        data.append('document', file, fileType);
        data.append('file_type', fileType);
        handleSubmit({
          app,
          payload: data,
          cellKey,
          updateRow,
          setAlertMessage,
          setErrorAlertMessage,
        });
      };




      // Application Number
      rowCells.push({
        type: 'link',
        value: urlValue,

        subValue: `${app.ppId}`,
        state: { applicationId: app.id },
        subValue2: `${app.defiApplicationNumber != null
          ? `D${app.defiApplicationNumber}`
          : ''
          }`,
      });

      // Application Date
      rowCells.push({
        value: format(new Date(app.createdAt), 'MM/dd/yyyy'),
        subValue: format(new Date(app.createdAt), 'hh:mm aaa'),
      });

      // Expiration Date
      rowCells.push({
        value: format(new Date(app.expirationDate), 'MM/dd/yyyy'),
        subValue: format(new Date(app.expirationDate), 'hh:mm aaa'),
      });

      // Merchant Reference ID
      rowCells.push({
        type: 'merchantEditor',
        value: app.merchantRefId || '',
        appId: app.id,

        callbackFn: async (merchantRefId) => {
          if (merchantRefId === null) {
            return '';
          }
          const formData = {
            application: {
              merchant_ref_id: merchantRefId,
            },
          };
          const [err] = await to(
            protectedPut(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${app.id}/update_submitted_app`,
              formData,
            ),
          );
          if (err) {
            console.error(err);
            return;
          }
          setAlertMessage('Merchant Reference Id Updated Succesfully');
          setParams((state) => ({ ...state, refresh: Date.now }));
        },
      });

      // Dealer
      if (highLevelRoles.includes(dealerLevelRole)) {
        rowCells.push({
          value: app.dealerNetworkName || 'N/A',
          code: app.dealerNetworkCode || 'N/A',
          type: 'dealerNetwork',
        });
      }
      rowCells.push({
        type: 'dealerSelect',
        value: {
          name: app?.dealer?.name,
          lastName: app?.dealer?.lastName,
          id: app?.dealer?.id,
          role: app?.dealer?.role,
          appId: app?.id,
        },
        callbackFn: async (dealer) => {
          const params = {
            dealer_id: dealer.id,
          };
          const [err] = await to(
            protectedPut(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${app.id}/set_dealer_member_for_app`,
              null,
              {
                params,
              },
            ),
          );
          if (err) {
            console.error(err);
            return;
          }
          setAlertMessage('Merchant user updated successfully');
          setParams((state) => ({ ...state, refresh: Date.now }));
        },
      });

      // Applicant(s)
      rowCells.push({
        value: fullName({ name: app.name, lastName: app.lastName }),
        subValue: app.meta.hasCoapplicant
          ? fullName({
            name: app.coapplicantName,
            lastName: app.coapplicantLastName,
          })
          : null,
      });

      // Project Amount / Credit Limit
      rowCells.push({
        value: [
          app.financialDetails?.find(
            (financialDetails) => financialDetails.userType === 'homeowner',
          )?.requestAmount,
          app.creditLimit,
        ],
        type: 'multi-money',
      });

      // Apr / Promo

      /*
        Ideally this whole page will later get reworked. For now, we'll do a botch solution, here's the explanation:
        The old code showed the default apr always. Now we need to change the displayed percentage based on the following criteria:

        -if there is a deal selected, and the boolean show_promo_apr === true, then show promo_composite_apr

        -if there's a deal selected, but show_promo_apr === false, then show the interest rate of the deal

        -if there is no deal selected, show the default apr. If there's no default apr, show nothing

        a "deal selected" is NOT the user selecting the deal on the ui. It basically means that relationships.deal.data !== null for the application
      */
      let percentage;
      if (app.rawApplication.relationships.deal.data) {
        /*
            To find the interest rate or the apr, we first need access to the deal info. For that, we need to do this:
            1- get the deal's id 
              - you can get the id by looking at the relationships object in the application response
            2- using the id, find the deal in the "included" array (for some reason we return ALL of the included elements in an array separate from ALL the applications)
          */
        const auxDeal = app.rawApplicationsArrayOfIncluded.find(includedElement => 
          includedElement.type === "deal" && includedElement.id === app.rawApplication.relationships.deal.data.id)
          .attributes 

        if (auxDeal.show_promo_apr) {
          percentage = auxDeal.promo_composite_apr;
        } else {
          percentage = auxDeal.interest_rate
        }
      } else {
        // if deal does not exist, that means there's no selected deal in the application
        percentage = app.rawApplication.attributes.default_apr
      }

      rowCells.push({
          value: {
            promos: app.promos,
            percentage,
          },
        type: 'aprPromos',
      });
        
      // App Status
      rowCells.push({
        value: app.status,
        subValue: app.draftStatus,
        type: 'status',
      });

      // App Messages
      rowCells.push({
        value: {
          value: app.hasUnreadMessages,
          disabled: app.hasUnreadMessages === false,
        },
        type: 'message',
      });

      // App Concierge
      if (user.data.attributes.hierarchyLevelRole === 'super_admin') {
        rowCells.push({
          value: {
            value: app.concierge,
            disabled: !app.concierge,
          },
          type: 'concierge',
        });
      }
      // Deal Selected
      const dealDetailsWithPromoCompositeApr = app.deal;
      if (app.rawApplication.relationships.deal.data) {
        /*
            To find the interest rate or the apr, we first need access to the deal info. For that, we need to do this:
            1- get the deal's id 
              - you can get the id by looking at the relationships object in the application response
            2- using the id, find the deal in the "included" array (for some reason we return ALL of the included elements in an array separate from ALL the applications)
          */
        const auxDeal = app.rawApplicationsArrayOfIncluded.find(includedElement => 
          includedElement.type === "deal" && includedElement.id === app.rawApplication.relationships.deal.data.id)
          .attributes 

        if (auxDeal.show_promo_apr) {
          dealDetailsWithPromoCompositeApr.promo_composite_apr = auxDeal.promo_composite_apr;
        }
      }
      if (sessionStorage.getItem('flow') === 'new') {
        rowCells.push({
          value: {
            value: app.dealSelected,
            dealDetails: app.deal,
            disabled: !app.dealSelected,
          },
          type: 'checkboxDeal',
        });
      }

      // Applicant Signed Contract
      rowCells.push({
        value: {
          value: app.signedApplicant,
          disabled: app.signedApplicant === null,
        },
        type: 'checkbox',
      });

      // Coapplicant Signed Contract
      rowCells.push({
        value: {
          value: app.signedCoapplicant,
          disabled: app.signedCoapplicant === null,
        },
        type: 'checkbox',
      });

      rowCells.push({
        value: {
          disabled:
            !canEdit && typeof app.installContractUploaded !== 'boolean',
          value: app.installContractUploaded,
          callbackFn: (event) =>
            uploadFileCb(
              event.target.files[0],
              'install_contract',
              'installContractUploaded',
            ),
        },
        type: canEdit ? 'checkboxUpload' : 'checkbox',
      });

      rowCells.push({
        value: {
          disabled: !canEdit && typeof app.driverLicenseUploaded !== 'boolean',
          value: app.driverLicenseUploaded,
          callbackFn: (event) =>
            uploadFileCb(
              event.target.files[0],
              'driver_license',
              'driverLicenseUploaded',
            ),
        },
        type: canEdit ? 'checkboxUpload' : 'checkbox',
      });

      // CB Driver's Licencse
      rowCells.push({
        value: {
          disabled: !canEdit && typeof app.coapplicantDriverLicenseUploaded !== 'boolean' || app.hasCoapplicant === false,
          value: app.coapplicantDriverLicenseUploaded,
          callbackFn: (event) =>
            uploadFileCb(
              event.target.files[0],
              'coapplicant_driver_license',
              'coapplicantDriverLicenseUploaded',
            ),
        },
        type: canEdit ? 'checkboxUpload' : 'checkbox',
      });

      rowCells.push({
        disabled: !app.canStartProject,
        subValue: app.canStartProject ? 
          (["solar_esg", "home_improvement", "esg_hi"].includes(app.loanStatusCode)? "Start Project" : "Initiate Funding") 
          : '',
        state: { applicationId: app.id, loanId: app.loanId },
        type: 'startProject',
      });
      if (
        canEdit &&
        (!app.loan ||
          ![
            'in_progress',
            'approved',
            'completed',
            'finished',
            'change_requested',
          ].includes(app.loan.status)) &&
        (app.status !== 'rejected' || app.status !== 'expired')
      ) {
        rowCells.push({
          value: {
            setSelectedCancelApp: () => setSelectedCancelAppId(app.id),
            onOpenCancelDialog,
          },
          type: 'cancel',
        });
      } else {
        rowCells.push({
          disabled: !app.canStartProject,
          subValue: '',
          state: '',
          type: 'empty',
        });
      }

      return rowCells;


    }, rows),
    setRows,
    {
      updateRow,
      isCancelDialogOpen,
      onOpenCancelDialog,
      onCloseCancelDialog,
      setSelectedCancelAppId,
      cancelApp,
    },
  ];
}
const showMessage = (message) => {
  props.updateData({
    name: 'message',
    value: message,
  });
};

const ApplicationQueue = (props) => {
  const fieldsToReset = [
    'name',
    'productId',
    'coapplicant_name',
    'invalidServiceDate',
    'serviceDate',
    'promoCode',
    'loanDetails',
    'coapplicant_driver_license_number',
    'driver_license_number',
    'coapplicant_active_military',
    'active_military',
    'last_name',
    'coapplicant_last_name',
    'email',
    'coapplicant_email',
    'coapplicant_ssn',
    'coapplicant_anual_income',
    'birthday',
    'ssn',
    'anual_income',
    'request_amount',
    'city',
    'state',
    'employer_name',
    'employer_zip',
    'zip_code',
    'street_address',
    'signatureMethod',
    'occupation',
    'employment_type',
    "stagedFunding",
    'coapplicant_employer_name',
    'coapplicant_employer_zip',
    'phone_number',
    'coapplicant_phone_number',
    'coapplicant_birthday',
    'coapplicant_occupation',
    'coapplicant_employment_type',
    'dealer_code',
    'ith_dealer_email',
    'ith_dealer_phone',
    'years_employment',
    'coapplicant_years_employment',
    'applicant_notifications_disabled',
    'merchant_ref_id',
  ];

  const [enableStagedFunding, setEnableStageFunding] = useState(false)

  useEffect(async () => {
    // This state setting is used to determine the type of label we should use in some header cells
    // @todo this kind of solutions should be avoided and we should move this logic to context/reducer solution

    const [_settingsError, settingsResponse] = await to(protectedGet(`/v1/settings`));
    const response = Boolean(
        settingsResponse?.data?.data?.find((elem) => elem.attributes.key === 'feature.down_payment_stage_funding_enable')?.attributes?.value
    );

    setEnableStageFunding(response);
  }, [])

  const [openBulkCancel, setOpenBulkCancel] = useState();

  const {
    isOpen: isCancelDialogOpenBulk,
    onOpen: onOpenCancelDialog,
    onClose: onCloseCancelDialogBulk,
  } = useDisclosure(false);

  useEffect(() => {
    clearFilters();
  }, []);

  useEffect(() => {
    fieldsToReset.map((item) => {
      props.updateData({
        name: item,
        value: '',
      });
      return '';
    });
  }, []);

  const { user, dealerLevelRole } = useUserContext();
  const { setErrorAlertMessage, setAlertMessage } = useAlertContext();

  useRedirectOnDealerStatus();

  const classes = tableStyles();
  const { dealStruct, merchantRefId } = props;
  const history = useHistory();

  useEffect(() => {
    props.updateData({
      name: 'dealStruct',
      value: '',
    });
    props.updateData({
      name: 'appData',
      value: '',
    });
    props.updateData({
      name: 'loanDetails',
      value: '',
    });
  }, []);

  const [params, setParams] = useState({
    status: 'submitted',
  });

  const updateRefId = (id) => {
    props.updateData({
      name: 'merchant_ref_id',
      value: id,
    });
  };
  const [queryParams, setQueryParams] = useState({
    searchText: '',
  });
  const debouncedQueryParams = useDebounce(queryParams, 144);

  const [
    rows,
    setRows,
    { isCancelDialogOpen, onCloseCancelDialog, cancelApp },
  ] = useTableData(
    setParams,
    user.data.meta.canManageResources,
    dealerLevelRole,
    updateRefId,
    merchantRefId,
  );

  const { ...tableMethods } = usePageTableParameters({
    initialOrder: 'ppId',
    rows,
  });

  const { selected, selectedIds, page, perPage, order, sort } = tableMethods;

  const {
    isOpen: isBulkAssigning,
    onOpen: onOpenBulkAssign,
    onClose: onCloseBulkAssign,
  } = useDisclosure(false);

  const onBulkAssign = async (selectedDealerNetworkId) => {
    const reqParams = {
      dealer_id: selectedDealerNetworkId,
      application_ids: selectedIds.map((row) => row.id),
    };
    const [err] = await to(
      protectedPatch(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/${selectedIds[0]}/assign`,
        reqParams,
      ),
    );
    if (err) {
      console.error(err);
      return;
    }
    setParams((state) => ({ ...state, refresh: Date.now }));
    onCloseBulkAssign();
  };

  const onRowClick = (row) => {
    history.push(row[0].value, row[0].state);
  };
  const [candBulkDelete, setCanBulkDelete] = useState(false);
  const [canReapply, setCanReapply] = useState(false);
  const { dealerNetworkOptions} = useDealerListContext();
  const { loanProductOptions} = useLoanProductListContext();
  const { organizationOptions} = useOrganizationListContext();
  const { affiliateOptions } = useAffiliateListContext();
  const [currentValue, getCurrentValue] = useState('');
  const [currentLoanProduct, getCurrentLoanProduct] = useState('');
  const [currentOrganization, getCurrentOrganization] = useState('');
  const [currentAffiliate, getCurrentAffiliate] = useState('');

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      if (debouncedQueryParams.searchText !== '') {
        if (
          localStorage.getItem('searchText') ===
          debouncedQueryParams.searchText &&
          localStorage.getItem('status') === params.status
        ) {
          params.page = page + 1;
        } else {
          params.page = 1;
        }
      } else {
        params.page = page + 1;
      }
      localStorage.setItem('searchText', debouncedQueryParams.searchText);
      localStorage.setItem('status', params.status);
      const [err, applications] = await to(
        protectedGet('/v1/applications', {
          params: decamelizeKeys({
            ...params,
            ...debouncedQueryParams,
            page: params.page,
            perPage,
            sort,
            order: decamelize(order),
            dealerNetworkId: currentValue ? currentValue : null,
            loanProductId: currentLoanProduct ? currentLoanProduct : null,
            organizationId: currentOrganization ? currentOrganization : null,
            affiliateId: currentAffiliate ? currentAffiliate : null,
          }),
        }),
      );
      setIsLoading(false);
      if (err) {
        console.error(err);
        return;
      }
      /*
        For future point of reference: most of the applications information is stored in the applications object, gotten from a fetch.

        then it gets "normalized", whatever that means, and in the process a lot of information gets lost. 

        For example, as i'm writing this, i'm adding the loan_product_code as loanStatusCode from the applications object back to the app object
      */
      const res = normalize(camelizeKeys(applications.data))
        .get(appFields)
        .map((app, i) => ({
          ...app,
          meta: camelizeKeys(applications.data.data[i].meta),
          rawApplication: applications.data.data[i],                          // i still don't understand why we need to do this map.
          rawApplicationsArrayOfIncluded: applications.data.included,         // We keep adding requirements that need more and more info from the response
          rawApplications: applications.data,                                 // So i'm just gonna add them all here. This page needs a serious rework anyways, its a complete 🍝.
          loanId: applications.data.data[i]?.relationships?.loan?.data?.id,
          loanStatusCode: applications.data.data[i]?.attributes.loan_product_code,
          dealSelected: applications.data.data[i]?.relationships?.deal?.data?.id
            ? true
            : false,
          message: applications.data.data[i].attributes.status_message,
        }));
      if (res.name) {
        res.homeowner = { name: res.name, lastName: res.lastName };
      }
      setTotalItems(Number(applications.headers.total));
      let modifiedArr = [];
      const permissableLoans = [
        'in_progress',
        'completed',
        'refunded',
        'finished'
      ];
      if (params?.status !== 'all')
        res.map((app) => {
          if (app?.loan && permissableLoans.includes(app?.loan?.status)) {
            return '';
          }
          modifiedArr.push(app);
          return modifiedArr;
        })
      else {
        modifiedArr = res;
      }
      setRows(modifiedArr);
    }
    fetch();
    tableMethods.setSelected({});
  }, [params, debouncedQueryParams, page, perPage, sort, order, currentValue,
      currentLoanProduct, currentOrganization, currentAffiliate]);

  const cancelMultipleApp = async () => {
    setOpenBulkCancel(false);
    const appIds = [];
    selectedIds.map((appId) => {
      appIds.push(appId.id);
      return appIds;
    });
    const [err] = await to(
      protectedPost(
        `${process.env.REACT_APP_BASE_URL}/v1/applications/actions`,
        {
          ids: appIds,
          type: 'cancel',
        },
      ),
    );
    if (err) {
      console.error(err);
      setErrorAlertMessage(err?.response?.data?.message || 'Error');
      return;
    }

    setAlertMessage('Application(s) Canceled Succesfully');
    setParams((state) => ({ ...state, refresh: Date.now }));
  };
  useEffect(() => {
    if (selectedIds.length === 1) {
      setCanReapply(true);
    } else {
      setCanReapply(false);
    }
    const rowsCopy = [...rows];
    let canDeleteBulk = true;
    const appWithProject = selectedIds.find((rowIds) => {
      const selectedRowIndex = rowsCopy.findIndex(
        (row) => row[0].subValue === rowIds.subValue,
      );
      const selectedRow = rowsCopy[selectedRowIndex];
      rowsCopy.splice(selectedRowIndex, 1);
      if (selectedRow[selectedRow.length - 1].type === 'empty') {
        canDeleteBulk = false;
      }
      return !selectedRow[13];
    });
    setCanBulkDelete(canDeleteBulk);
  }, [selectedIds]);

  const parseProjectAmountHeader = (enableStagedFunding, headers) => {
    headers.forEach((header) => {
      if (header.id === 'requestAmount') {
        header.label = enableStagedFunding ? 'Financed Amount' : 'Project Amount';
      }
    });

    return headers;
  };

  if (
    user.data.attributes.hierarchyLevelRole === 'super_admin' &&
    localHeadCells[10].label !== 'Concierge' &&
    highLevelHeadCells[11].label !== 'Concierge'
  ) {
    localHeadCells.splice(10, 0, {
      id: 'concierge',
      label: 'Concierge',
    });
    highLevelHeadCells.splice(11, 0, {
      id: 'concierge',
      label: 'Concierge',
    });
  } else if (
    user.data.attributes.hierarchyLevelRole !== 'super_admin' &&
    localHeadCells[10].label === 'Concierge' &&
    highLevelHeadCells[11].label === 'Concierge'
  ) {
    localHeadCells.splice(10, 1);
    highLevelHeadCells.splice(11, 1);
  }
  if (
    params.status !== 'canceled' &&
    (highLevelHeadCells[highLevelHeadCells.length - 1].id !== 'cancelApp' ||
      highLevelHeadCells[highLevelHeadCells.length - 1].id !== 'cancelApp')
  ) {
    conditionalCells.map((item) => {
      highLevelHeadCells.push(item);
      localHeadCells.push(item);
      return '';
    });
  } else if (
    params.status === 'canceled' &&
    (highLevelHeadCells[highLevelHeadCells.length - 1].id === 'cancelApp' ||
      localHeadCells[localHeadCells.length - 1].id === 'cancelApp')
  ) {
    highLevelHeadCells.splice(highLevelHeadCells.length - 2, 2);
    localHeadCells.splice(localHeadCells.length - 2, 2);
  }
  const {
    activeColumns,
    setActiveColumns,
    filteredHeadCells,
    filteredRows,
    initialColumns,
    totalItems,
    setTotalItems,
    isLoading,
    setIsLoading,
  } = useTableSetup({
    rows,
    params,
    headCells: highLevelRoles.includes(dealerLevelRole)
      ? parseProjectAmountHeader(enableStagedFunding, highLevelHeadCells)
      : parseProjectAmountHeader(enableStagedFunding, localHeadCells),
  });
  const { webUiPreference } = useUserContext();

  const [initialValues, setInitialValues] = useState({
    dealerList: '',
  });
  const [loanProductInitialValues, setLoanProductInitialValues]  = useState({
    loanProductList: '',
  });
  const [organizationInitialValues, setOrganizationInitialValues] = useState({
    organizationList: '',
  });
  const [affiliateInitialValues, setAffiliateInitialValues  ] = useState({
    affiliateList: '',
  });
   const onReapply = () => {
    props.updateData({
      name: 'reApplyId',
      value: selectedIds[0].id,
    });
    history.push(`/applications/start`);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <PageTableWrapper isLoading={isLoading}>
        <PageTableHeader>
          <StateFilter
            possibleStatuses={
              user.data.meta.canManageResources
                ? ['submitted', 'expiring', 'drafted', 'canceled', 'all']
                : ['submitted', 'canceled']
            }
            status={params.status}
            setParams={setParams}
            {...tableMethods}
          />
          <Box className={classes.rightFilterWrapper}>
            {dealerLevelRole === 'super_admin' && (
              <>
                <Button
                  style={{
                    minWidth: '35px',
                    padding: '0px',
                  }}
                  onClick={handleOpen}
                >
                  <Icon icon="table-filters" />
                </Button>
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >

                  <Box className={classes.modalContent}>
                  <Button
                    style={{
                      minWidth: '35px',
                      padding: '0px',
                      marginTop: '10px',
                      marginLeft: '450px',
                    }}
                    onClick={handleClose}
                  >
                     {/* <Icon icon="close"></Icon> */}
                     <HighlightOffIcon style={{fontSize: '35px'}} />

                  </Button>
                    <br></br>
                    <Grid item xs={12} md={6} >
                      <Formik
                        initialValues={initialValues}
                        validationSchema={null}
                      >
                        {({ values }) => {
                          return (
                            <>
                              <Form
                                style={{ padding: '0 1rem 0 1rem', width: '330px'}}>
                                <AutocompleteFieldDropDown
                                  placeholder="Filter by dealer"
                                  name="dealerList"
                                  options={dealerNetworkOptions}
                                  disabled={isLoading}
                                  getCurrentValue={getCurrentValue}
                                  type="dealerFilter"
                                />
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} md={6} >
                      <Formik
                        initialValues={loanProductInitialValues}
                        validationSchema={null}
                        enableReinitialize
                      >
                        {({ values }) => {
                          return (
                            <>
                              <Form
                                style={{ padding: '0 1rem 0 1rem', width: '330px' }}>
                                <AutocompleteFieldDropDown
                                  placeholder="Filter by Loan Product"
                                  name="loanProductList"
                                  options={loanProductOptions}
                                  disabled={isLoading}
                                  getCurrentValue={getCurrentLoanProduct}
                                  type="dealerFilter"
                                />
                              </Form>
                            </>
                          );
                        }}
                      </Formik>
                    </Grid>
                    <br></br>
                    <Grid item xs={12} md={6} >
                      <Formik
                        initialValues={organizationInitialValues}
                        validationSchema={null}
                        enableReinitialize
                      >
                        {({ values }) => {
                          return (
                            <>
                              <Form
                                style={{ padding: '0 1rem 0 1rem', width: '330px' }}>
                                <AutocompleteFieldDropDown
                                  placeholder="Filter by Organization"
                                  name="organizationList"
                                  options={organizationOptions}
                                  disabled={isLoading}
                                  getCurrentValue={getCurrentOrganization}
                                  type="dealerFilter"
                                />
                              </Form>
                              <br></br>
                            </>
                          );
                        }}
                      </Formik>
                    </Grid>
                    <Grid item xs={12} md={6} >
                    <Formik
                      initialValues={affiliateInitialValues}
                      validationSchema={null}
                      enableReinitialize
                    >
                      {({ values }) => {
                        return (
                          <>
                            <Form
                            style={{padding: '0 1rem 0 1rem', width: '330px'}}>
                              <AutocompleteFieldDropDown
                                placeholder="Filter by Affiliate"
                                name="affiliateList"
                                options={affiliateOptions}
                                disabled={isLoading}
                                getCurrentValue={getCurrentAffiliate}
                                type="dealerFilter"

                              />
                            </Form>
                            <br></br>
                          </>
                        );
                      }}
                    </Formik>
                  </Grid>
                    <QueryFilter setParams={setQueryParams} disableWindowReload />
                    <br></br>
                    <Button
                        className={clsx(classes.clearButton )}
                        onClick={() => {
                          clearFilters();
                          window.location.reload();
                        }}
                    >
                      Clear All
                    </Button>
                    <br></br>
                  </Box>

                </Modal>
              </>
            )}
            {dealerLevelRole !== 'super_admin' &&
              <QueryFilter setParams={setQueryParams} />
            }
            <Hidden mdDown>
              <ColumnToggler
                columns={initialColumns.current}
                hiddenColumns={webUiPreference?.applications?.hiddenColumns || []}
                activeColumns={activeColumns}
                setActiveColumns={setActiveColumns}
                showSave
              />
            </Hidden>
            {/* <ExtendedFilters>
              <CheckboxesFilter
                possibleValues={possibleStatuses}
                currentValues={params.statusFilters}
                setParams={setParams}
              />
             
            </ExtendedFilters> */}
          </Box>
        </PageTableHeader>
        <PageTable
          totalItems={totalItems}
          rows={filteredRows}
          headCells={filteredHeadCells}
          isLoading={isLoading}
          page={params.page}
          perPage={params.perPage}
          setParams={setParams}
          emptyStateTitle="No Applications, yet."
          emptyStateDesc="Create a new Application to start the process!"
          itemType={`${totalItems > 1 ? 'Applications' : 'Application'}`}
          onRowClick={onRowClick}
          hideCheckboxes={!user.data.meta.canManageResources}
          {...tableMethods}
        />
      </PageTableWrapper>
      <SelectedMenu selected={selected}>
        {(user.data.attributes.hierarchyLevelRole === 'organization' ||
          user.data.attributes.hierarchyLevelRole === 'affiliate') && (
            <SelectedMenuActionButton
              icon="people"
              label="Bulk Re-Assignment"
              onClick={onOpenBulkAssign}
            />
          )}
        {canReapply && (
          <SelectedMenuActionButton
            icon="prequalify"
            label="ReSubmit"
            onClick={onReapply}
          />
        )}
        {/* <SelectedMenuActionButton icon="archive" label="Archive Application" /> */}
        {candBulkDelete && (
          <SelectedMenuActionButton
            icon="cancel"
            onClick={() => {
              setOpenBulkCancel(true);
            }}
            label="Cancel Application"
          />
        )}
      </SelectedMenu>
      <CancelAppDialog
        dialogState={openBulkCancel}
        handleClose={() => {
          setOpenBulkCancel(false);
        }}
        handleCancel={cancelMultipleApp}
      />
      <AssignmentActionModal
        selectedIds={selectedIds}
        isOpen={isBulkAssigning}
        onClose={onCloseBulkAssign}
        onBulkAssign={onBulkAssign}
      />
      <CancelAppDialog
        dialogState={isCancelDialogOpen}
        handleClose={onCloseCancelDialog}
        handleCancel={cancelApp}
      />
    </>
  );
};

export const ApplicationQueueHeaderActions = () => {
  return (
    <Box>
      {sessionStorage.getItem('flow') === 'new' ? <NewApplicationButton /> : ''}{' '}
    </Box>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    merchantRefId: state.appReducer.merchant_ref_id,
    dealStruct: state.appReducer?.dealStruct,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(ApplicationQueue);
