import React from 'react';

export default function index({ width, height }) {
  return (
    <svg
      width={width || '18px'}
      height={height || '20px'}
      viewBox="0 0 18 20"
      version="1.1"
    >
      <defs>
        <path
          d="M12.333.833c.46 0 .834.373.834.834l-.001.832 1.667.001a2.5 2.5 0 0 1 2.5 2.5v11.667a2.5 2.5 0 0 1-2.5 2.5H3.167a2.5 2.5 0 0 1-2.5-2.5V5a2.5 2.5 0 0 1 2.5-2.5h1.666v-.833c0-.425.318-.776.73-.827l.104-.007c.46 0 .833.373.833.834v.832h5v-.832c0-.425.318-.776.729-.827zm3.333 8.333H2.333v7.5c0 .46.373.834.834.834h11.666c.46 0 .834-.373.834-.833l-.001-7.5zm-10.833-5H3.167A.833.833 0 0 0 2.333 5v2.5h13.333V5a.833.833 0 0 0-.833-.833h-1.667V5a.833.833 0 0 1-.728.827l-.105.006A.833.833 0 0 1 11.5 5v-.834h-5V5a.833.833 0 0 1-.729.827l-.104.006A.833.833 0 0 1 4.833 5v-.834z"
          id="a"
        />
      </defs>
      <use
        fillOpacity=".4"
        fill="#1E272E"
        xlinkHref="#a"
        fillRule="evenodd"
        opacity=".46"
      />
    </svg>
  );
}
