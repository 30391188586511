import normalize from 'json-api-normalize';
import { forEach, sort } from 'ramda';
import { ssnPipe } from 'components/InputFields/SsnField';

export const parseApplicationObjToFormJson = application => {
  const homeownerProps = [
    'name',
    'lastName',
    'birthday',
    'phoneNumber',
    'email',
    'ssn',
    'activeMilitary',
    'usCitizen',
  ];
  const coapplicantProps = [
    'coapplicantName',
    'coapplicantLastName',
    'coapplicantBirthday',
    'coapplicantPhoneNumber',
    'coapplicantEmail',
    'coapplicantSsn',
    'coapplicantActiveMilitary',
    'coapplicantUsCitizen',
  ];
  const json = normalize(application).get([
    'dealerCode',
    'dealerName',
    'dealer.dealerCode',
    'dealer.name',
    'dealer.lastName',
    'dealer.phoneNumber',
    'dealer.email',
    'dealer.business.name',
    'dealer.address.city',
    'dealer.address.state',
    'address.streetAddress',
    'address.addressLine2',
    'address.zipCode',
    'address.city',
    'address.state',
    'owner',
    'financialDetails.anualIncome',
    'financialDetails.occupation',
    'financialDetails.employerName',
    'financialDetails.employerZip',
    'financialDetails.employerTime',
    'financialDetails.employmentType',
    'financialDetails.requestAmount',
    'financialDetails.userType',
    'financialDetails.term',
    // spread homeowner Object
    ...homeownerProps,
    'driverLicenseUrl',
    'driverLicenseUid',
    // spread coapplicat Object
    ...coapplicantProps,
    'coapplicantDriverLicenseUrl',
    //
    'lastStep',
    'defiApplicationNumber'
  ]);

  json.dealer = {
    ...json.dealer,
    dealerCode: json.dealerCode,
    dealerName: json.dealerName,
  };

  json.homeowner = {};
  forEach(p => {
    json.homeowner[p] = json[p];
    delete json[p];
  }, homeownerProps);

  json.coapplicant = {};
  forEach(p => {
    let key = p.replace('coapplicant', '');
    key = key.charAt(0).toLowerCase() + key.slice(1);
    json.coapplicant[key] = json[p];
    delete json[p];
  }, coapplicantProps);

  json.application = {
    addressAttributes: {
      ...json.address,
    },
    owner: json.owner,
  };

  if (json.driverLicenseUrl) {
    json.application.driverLicense = {
      url: json.driverLicenseUrl,
      uuid: json.driverLicenseUid,
    };
    delete json.driverLicenseUrl;
    delete json.driverLicenseUid;
  }
  if (json.coapplicantDriverLicenseUrl) {
    json.application.coapplicantDriverLicense = {
      url: json.coapplicantDriverLicenseUrl,
    };
    delete json.coapplicantDriverLicenseUrl;
  }

  delete json.address;
  json.hasDealer = Boolean(json.dealer);
  json.hasCoapplicant =
    Boolean(json.coapplicant.name) ||
    Boolean(json.application.coapplicantDriverLicense);

  // ensure financialDetails is ordered
  if (!json.financialDetails) {
    json.financialDetails = [];
  }
  json.financialDetails = sort((a, b) => {
    if (a.userType === 'homeowner') return -1;
    return 1;
  }, json.financialDetails);

  // add hyphens to ssn
  json.homeowner.ssn = ssnPipe(json.homeowner.ssn || '');
  if (json.coapplicant) {
    json.coapplicant.ssn = ssnPipe(json.coapplicant.ssn || '');
  }

  return json;
};
