import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useUserContext } from 'services/hooks/useUser';
import { connect } from 'react-redux';
import to from 'await-to-js';
import { updateData } from 'redux/actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { useAlertContext } from 'components/AlertContext';
import ContractFormLayout from './ContractFormLayout';
import DealStructCards from './DealStructCards';
import NewApplicationForm from './NewApplicationForm';
import StartProject from './StartProject';
import {
  protectedGet,
  protectedPut,
} from '../../services/http/index';
import SideTab from './SideTab';

const useStyles = makeStyles((theme) => ({
  lineItems: {
    width: '4em',
    height: '4em',
    textAlign: 'center',
    lineHeight: '4em',
    borderRadius: '2em',
    color: 'black',
    background: 'white',
    margin: '0 7em',
    display: 'inline-block',
    position: 'relative',
    border: '2px solid',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '1.7em',
      left: '-14.3em',
      width: '14.2em',
      height: '.2em',
      background: 'black',
      zIndex: '0',
    },
    'first-child::before': {
      display: 'none',
    },
  },

  lineItemsVisited: {
    width: '4em',
    height: '4em',
    textAlign: 'center',
    lineHeight: '4em',
    borderRadius: '2em',
    color: 'black',
    background: 'orange',
    margin: '0 7em',
    display: 'inline-block',
    position: 'relative',
    border: '2px solid',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '1.7em',
      left: '-14.3em',
      width: '14.2em',
      height: '.2em',
      background: 'black',
      zIndex: '0',
    },
    '&:first-child::before': {
      display: 'none',
    },
  },
  paragraph: {
    color: 'black',
    width: '185px',
    marginLeft: '-60px',
  },
  container: {
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  section: {
    backgroundColor: 'rgba(145,152,160,0.05)',
    padding: '1rem',
    borderRadius: '0.25rem',
  },
  buttonStyle: {
    backgroundColor: 'white',
  },
  formControl: {
    minWidth: '100%',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#1E272E',
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ApplicationWrapper = (props) => {
  const { appId } = useParams();
  const classes = useStyles();
  const [fetchedData, setFetchedData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { setErrorAlertMessage } = useAlertContext();
  const { user, dealerLevelRole } = useUserContext();
  const history = useHistory();
  const [hasUnreadMessages, setHasUnreadMessages] = useState();
  const [fetched, setFetched] = useState(false);
  const [advancedFundingState, setAdvancedFundingState] = useState(false);
  const { dealStruct, dealsData, loanDetails, reApplyId } = props;

  const [enableStagedFunding, setEnableStageFunding] = useState(false)

  useEffect(async () => {
    /* since appId is undefined, this whole bit of code is ignored when resubmitting an application from the application queue */
    if (appId && (!dealStruct || dealStruct === '')) {
      props.updateData({
        name: 'appId',
        value: appId,
      });
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
        ),
      );

      if (rawApplication) {
        props.updateData({
          name: 'appData',
          value: rawApplication.data,
        });
        setHasUnreadMessages(
          rawApplication?.data?.data?.attributes.has_unread_messages,
        );
        const dealSetId =
          rawApplication?.data?.data?.relationships?.deal_set?.data?.id;
        if (
          rawApplication?.data?.data?.attributes?.draft_status === 'canceled' ||
          rawApplication?.data?.data?.attributes?.status === 'rejected' ||
          rawApplication?.data?.data?.attributes?.status === 'expired'
        ) {
          history.push(`/dealer/applications/${appId}`);
          return;
        }
        setFetched(true);
        const detail = rawApplication?.data?.included.find(
          (item) =>
            item.type === 'financial_detail' &&
            item?.attributes.user_type === 'homeowner',
        );
        const projectAmount = detail ? detail.attributes.request_amount : '';

        const stageFunding = user?.data?.attributes?.defaultStageFunding ? true : false;
        
        if (stageFunding) setAdvancedFundingState(stageFunding);

        const downPaymentAmount = dealsData?.appData?.attributes?.loan_product_code !== 'healthcare' && rawApplication?.data?.data?.attributes?.down_payment_amount || 0;

        const [settingsError, settingsResponse] = await to(protectedGet(`/v1/settings`));

        const featureEnableStageFunding = Boolean(settingsResponse?.data?.data?.find(elem => elem.attributes.key === "feature.down_payment_stage_funding_enable")?.attributes?.value)

        setEnableStageFunding(featureEnableStageFunding);

        const [err, res] = await to(
          protectedPut(
            `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/${dealSetId}?application_id=${appId}&project_amount=${projectAmount}&stage_funded=${stageFunding}&stip_pay=false&buydown_points=0${featureEnableStageFunding ? `&down_payment_amount=${downPaymentAmount}` : ""}`,
          ),
        );

        if (res) {
          if (res?.data?.data?.attributes?.declined) {
            history.push(`/applications/${appId}/declined`);
            return;
          }
          if (res?.data?.data?.attributes?.reviewing) {
            history.push(`/applications/${appId}/reviewing`);
            return;
          }
          props.updateData({
            name: 'dealStruct',
            value: res.data,
          });
          props.updateData({
            name: 'request_amount_contract',
            value: projectAmount,
          });
        }
        const loanGenerated =
          rawApplication?.data?.data?.relationships?.loan?.data?.id;
        if (loanGenerated) {
          const [err, loanResponse] = await to(
            protectedGet(
              `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}/loans/${loanGenerated}`,
            ),
          );

          const filteredPaymentsArray = loanResponse.data.included.filter(
            (item) => {
              return item.type === 'payment';
            },
          );
          let sentToReview = true;
          filteredPaymentsArray.map((payment) => {
            if (
              payment.attributes.status === 'paid' ||
              payment.attributes.status === 'released'
            ) {
              sentToReview = false;
            }
            return sentToReview;
          });

          if (
            loanResponse?.data?.data?.attributes.status ===
            'change_requested' ||
            loanResponse.data.data.attributes.status === 'completed' ||
            loanResponse.data.data.attributes.status === 'finished'
          ) {
            sentToReview = false;
          }
          if (!sentToReview) {
            history.push(`/dealer/applications/${appId}`);
          }
        }
      }

      if (err) {
        setErrorAlertMessage(
          err?.response?.data?.message ||
          'Error while fetching application details.',
        );
      }
    }
  }, []);
  useEffect(async () => {
    const applicationId = appId || reApplyId;
    if (fetchedData || reApplyId) {
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${applicationId}`,
        ),
      );
      if (!err) {
        props.updateData({
          name: 'appData',
          value: rawApplication.data,
        });
        props.updateData({
          name: 'reApplyId',
          value: '',
        });
        props.updateData({
          name: 'productId',
          value: rawApplication.data.data.attributes.loan_product_id,
        });
      }

      if (err) {
        setErrorAlertMessage(
          err?.response?.data?.message ||
          'Error while fetching application details.',
        );
      }
      setFetchedData(false);
      setShowLoader(false);
    }
  }, [fetchedData, reApplyId]);

  return (
    <div>
      {appId && !window.location.href.includes('complete') ? (
        <>
          {dealStruct && (
            <>
              {dealStruct.data.attributes.exception === true &&
                dealStruct.data.attributes.flagged === true ? (
                history.push(`/applications/${appId}/exception`)
              ) : (
                <Grid container spacing={3} className={classes.container}>
                  {/* Main */}
                  <Grid item xs={12} lg={8} spacing={1}>
                    <Card className={classes.card}>
                      <CardContent>
                        <Grid container spacing={3}>
                          <Grid item xs={6} md={4} className={classes.section}>
                            <ContractFormLayout setAdvancedFundingState={setAdvancedFundingState}/>
                          </Grid>
                          <Grid item xs={6} md={8}>
                            <DealStructCards
                              enableStagedFunding={enableStagedFunding}
                              advancedFundingState={advancedFundingState}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>

                  {/* Sidebar */}
                  <Grid item xs={12} lg={4}>
                    <SideTab
                      appId={appId}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      fetchedData={fetchedData}
                      loanDetails={loanDetails}
                      signatureMethod={dealsData?.signatureMethod}
                      setFetchedData={setFetchedData}
                      applicationDetails={dealsData.appData}
                      hasUnreadMessages={hasUnreadMessages}
                      setHasUnreadMessages={setHasUnreadMessages}
                    ></SideTab>
                  </Grid>
                </Grid>
              )}
            </>
          )}{' '}
        </>
      ) : window.location.href.includes('complete') ? (
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} lg={8} spacing={1}>
            <Card className={classes.card}>
              <CardContent>
                <Box p={3}>
                  <StartProject enableStagedFunding={enableStagedFunding} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <SideTab
              appId={appId}
              showLoader={showLoader}
              setShowLoader={setShowLoader}
              fetchedData={fetchedData}
              loanDetails={loanDetails}
              setFetchedData={setFetchedData}
              applicationDetails={dealsData.appData}
              hasUnreadMessages={hasUnreadMessages}
            ></SideTab>
          </Grid>
        </Grid>
      ) : (
        <NewApplicationForm></NewApplicationForm>
      )}
    </div>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
    reApplyId: state.appReducer?.reApplyId,
    dealStruct: state.appReducer?.dealStruct,
    loanDetails: state.appReducer?.loanDetails,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(ApplicationWrapper);
