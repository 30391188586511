import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useDisclosure } from 'services/hooks/useDisclosure';
import Fade from '@material-ui/core/Fade';
import to from 'await-to-js';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { Alert, AlertTitle } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { useUserContext } from 'services/hooks/useUser';

const SessionExpirerContext = React.createContext();

export const {
  Consumer: SessionExpirerConsumer,
  Provider,
} = SessionExpirerContext;

export function SessionExpirerProvider({ children }) {
  const alertRef = useRef(null);
  const callbackRef = useRef(null);
  const setSessionExpiredCallback = (cb) => {
    callbackRef.current = cb;
  };
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure(false);
  const [expired, setExpired] = useState(false);

  const expire = async () => {
    if (callbackRef.current) await to(callbackRef.current());
    history.go(-history.length);
    history.replace('/signout');
    setExpired(true);
    // onClose();
  };

  const closeFn = () => {
    onClose();
    setTimeout(() => {
      setExpired(false);
    }, 320);
  };

  const handleClick = (ev) => {
    if (!alertRef.current?.contains(ev.target)) {
      if (!isOpen) {
        clearTimeout(alertTimeoutRef.current);
        setAlertTimeout(null);
        clearTimeout(expireTimeoutRef.current);
        setExpireTimeout(null);
      }
      closeFn();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false);
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    };
  }, [isOpen]);

  const { user } = useUserContext();
  const [alertTimeout, setAlertTimeout] = useState(null);
  const alertTimeoutRef = useRef(null);
  useEffect(() => {
    alertTimeoutRef.current = alertTimeout;
  }, [alertTimeout]);
  useEffect(() => {
    if (!user) {
      clearTimeout(alertTimeout);
      setAlertTimeout(null);
      return;
    }
    if (!isOpen) {
      if (!alertTimeout) {
        setAlertTimeout(() => {
          return setTimeout(() => {
            if (!isOpen) {
              onOpen();
            }
            setAlertTimeout(null);
          }, 1000 * 60 * 15);
        });
      }
    } else {
      clearTimeout(alertTimeout);
      setAlertTimeout(null);
    }
  }, [alertTimeout, user, isOpen]);

  const [expireTimeout, setExpireTimeout] = useState(null);
  const expireTimeoutRef = useRef(null);
  useEffect(() => {
    expireTimeoutRef.current = expireTimeout;
  }, [expireTimeout]);
  useEffect(() => {
    if (!user) {
      clearTimeout(expireTimeout);
      setExpireTimeout(null);
      return;
    }
    if (isOpen) {
      if (!expireTimeout) {
        setExpireTimeout(() => {
          return setTimeout(() => {
            expire();
          }, 1000 * 60 * 5);
        });
      }
    } else {
      clearTimeout(expireTimeout);
      setExpireTimeout(null);
    }
  }, [isOpen, user]);

  const setOpenExpiredModal = () => {
    onOpen();
    setExpired(true);
  };

  return useMemo(
    () => (
      <Provider
        value={{
          setSessionExpiredCallback,
          setOpenExpiredModal,
        }}
      >
        <Fade in={isOpen}>
          <Snackbar
            severity="warning"
            open={isOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            style={{ background: 'white' }}
          >
            <Alert
              ref={alertRef}
              variant="outlined"
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={closeFn}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {expired ? (
                <>
                  <AlertTitle>Session expired</AlertTitle>
                  Your session has expired
                </>
              ) : (
                <>
                  <AlertTitle>Attention</AlertTitle>
                  Your session is about to expire
                  <Button
                    color="primary"
                    onClick={onClose}
                    style={{ marginTop: '0.5rem' }}
                  >
                    Refresh session
                  </Button>
                </>
              )}
            </Alert>
          </Snackbar>
        </Fade>
        {children}
      </Provider>
    ),
    [isOpen, expired],
  );
}

export const useSessionExpirerContext = () => {
  const context = useContext(SessionExpirerContext);
  return context;
};

export default SessionExpirerContext;
