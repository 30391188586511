import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import { useHistory } from 'react-router-dom';
import { Onboarding } from 'pages/NewApplication/Onboarding';
import { CarouselCard } from './CarouselCard';
import { ApplicationCard } from './ApplicationCard';
import { ProjectCard } from './ProjectCard';
import { HelpCard } from './HelpCard';

const useStyles = makeStyles({
  container: {
    padding: '24px',
  },
});

const Dashboard = () => {
  const classes = useStyles();
  const { user } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  if (user ?.data ?.type !== 'homeowner') {
    setErrorAlertMessage('Unauthorized Access');
    history.replace('/dashboard');
  }
  if (user ?.data ?.meta.applicationsCount === 0) {
    if (!sessionStorage.getItem('isCreatingApplication')) {
      history.replace('/applications/onboarding');
    }
  }
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={12}>
        <HelpCard
          title="Need help?"
          subheader="We're here to assist."
        />
      </Grid>
      <Grid item xs={12}>
        <CarouselCard />
      </Grid>
      <Grid item xs={12}>
        <ProjectCard />
      </Grid>
      <Grid item xs={12}>
        <ApplicationCard />
      </Grid>
      {/* <Grid item xs={12}>
        <Onboarding hasApplications />
      </Grid> */}
    </Grid>
  );
};

export default Dashboard;
