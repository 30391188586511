import React from 'react';

import { withStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import Text from 'components/TextFields/Text';

const Menu = withStyles(theme => ({
  root: {
    zIndex: 9999,
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    background: theme.palette.background.paper,
    boxShadow: '0 -5px 22px 0 #EEF0F3',
    left: 0,
    right: 0,
    bottom: 0,
    height: 68,
    padding: '0 8px',
    [theme.breakpoints.up('md')]: {
      padding: '0 24px',
    },
  },
}))(Box);

const ActionSection = withStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
  },
}))(Box);

export const SelectedMenu = ({ selected, children }) => {
  const currentSelectedItems = Object.values(selected).filter(Boolean);
  if (currentSelectedItems.length === 0) {
    return null;
  }
  return (
    <Menu>
      <Text textSize="sm" textColor="primary">
        {currentSelectedItems.length} selected
      </Text>

      <ActionSection>{children}</ActionSection>
    </Menu>
  );
};
