import React, { useState, useEffect } from 'react';
import to from 'await-to-js';
import { useDealerListContext } from 'services/contexts/useDealerList';
import Text from 'components/TextFields/Text';
import {
  Grid,
  Card,
  CardHeader,
  makeStyles,
  Box,
  useTheme,
} from '@material-ui/core';
import { updateData } from 'redux/actions';
import { connect } from 'react-redux';
import { useUserContext } from 'services/hooks/useUser';
import { useAlertContext } from 'components/AlertContext';
import { useHistory } from 'react-router-dom';
import DealerSection from 'pages/NewApplicationDealer/DealerSection';
import { protectedGet } from 'services/http';
import PulseLoader from 'react-spinners/PulseLoader';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingRight: '4rem',
    },
    padding: '1.5rem',
  },
}));

const MerchantResources = (props) => {
  const { dealerNetworkOptions } = useDealerListContext();
  const theme = useTheme();

  const { dealerCode } = props;
  const { dealerLevelRole } = useUserContext();
  const [gotMerchantData, setGotMerchantData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [primaryProductName, setPrimaryProductName] = useState('');
  const classes = useStyles();
  const { user } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const history = useHistory();
  useEffect(async () => {
    let url = '';
    let primaryProductId = user?.data?.attributes?.primaryProductId;
    if (
      ['affiliate', 'organization', 'super_admin'].includes(dealerLevelRole)
    ) {
      if (!dealerCode) {
        return;
      }

      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/dealers/by_code?dealer_code=${dealerCode}`,
        ),
      );
      if (rawApplication) {
        primaryProductId =
          rawApplication?.data?.data?.attributes?.primary_product_id;
        url = `/v1/dealers/${rawApplication?.data?.data?.id}/loan_products`;
      } else if (err) {
        setErrorAlertMessage('The selected merchant is still being approved and can not be selected.');
        props.updateData({
          name: 'dealer_code',
          value: '',
        });
        setPrimaryProductName('');
        setShowLoader(false);
        return;
      }
    } else {
      url = `/v1/dealers/${user?.data?.id}/loan_products`;
    }
    const [err, loanProducts] = await to(protectedGet(url));
    if (loanProducts) {
      loanProducts.data.data.map((loanProduct) => {
        if (loanProduct?.id === primaryProductId?.toString()) {
          setPrimaryProductName(
            loanProduct?.attributes?.code,
          );
        }
        return primaryProductName;
      });
      if (showLoader) {
        setTimeout(() => {
          setShowLoader(false);
        }, 500);
      }
    }
  }, [dealerCode, user]);

  const updateMerchantData = () => {
    setGotMerchantData(true);
  };
  if (user?.data?.type !== 'dealer') {
    setErrorAlertMessage('Unauthorized Access');
    history.replace('/dashboard');
  }

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid xs={12}>
        {['affiliate', 'organization', 'super_admin'].includes(
          dealerLevelRole,
        ) && (
          <Card style={{ padding: '24px' }}>
            <DealerSection
              dealerNetworkOptions={dealerNetworkOptions}
              updateMerchantData={updateMerchantData}
              noHeader
              setShowLoader={setShowLoader}
            />
          </Card>
        )}
        {dealerCode && primaryProductName && (
          <>
            {showLoader && (
              <Box
                p={6}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <PulseLoader color={theme.palette.primary.light} size="1rem" />
                <Text textSize="sm+" semibold textColor="secondary">
                  Please wait...
                </Text>
              </Box>
            )}

            {!showLoader && (
              <Card style={{ marginTop: '24px' }}>
                <iframe
                  title="frame1"
                  width="100%"
                  id="gpp-calc"
                  height="844"
                  frameBorder={0}
                  src={`https://getpowerpay.com/merchant-resources/${primaryProductName}/?d=${dealerCode}&emb=1`}
                ></iframe>
              </Card>
            )}
          </>
        )}

        {!['affiliate', 'organization', 'super_admin'].includes(
          dealerLevelRole,
        ) &&
          primaryProductName && (
            <Card style={{ marginTop: '24px' }}>
              <iframe
                title="frame2"
                width="100%"
                id="gpp-calc"
                height="844"
                frameBorder={0}
                src={`https://getpowerpay.com/merchant-resources/${primaryProductName}/?d=${user?.data?.attributes?.dealerCode}&emb=1`}
              ></iframe>
            </Card>
          )}
      </Grid>
    </Grid>
  );
};

const mapStatesToProps = (state) => {
  return { dealerCode: state.appReducer.dealer_code };
};

export default connect(mapStatesToProps, {
  updateData,
})(MerchantResources);
