import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loaders';
import Text from 'components/TextFields/Text';
import LinkText from 'components/TextFields/LinkText';
import SuccessMessage from 'components/SuccessMessage';
import http, { protectedGet } from 'services/http';
import to from 'await-to-js';
import SecondPage from './ThirdPage';
import ThirdPage from './SecondPage';
import FirstPage from './FirstPage';

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const nameRegExp = /\p{L}+/u;

const validationSchema = yup.object({
  firstname: yup
    .string()
    .required('Firstname is required')
    .matches(nameRegExp, 'Invalid name'),
  lastname: yup
    .string()
    .required('Lastname is required')
    .matches(nameRegExp, 'Invalid name'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('email is required'),
  phone: yup.string().matches(/^(?:\+1)?\(\d{3}\)\d{3}-\d{4}$/gm, {
    excludeEmptyString: true,
    message: 'Invalid Phone Number',
  }),
  dealerCode: yup.string(),
});

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    justifyContent: 'space-around',
    margin: '0 auto',
    width: '100%',
  },
  form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    marginBottom: 20,
  },
  formTitle: {
    maxWidth: 345,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginBottom: 56,
    },
  },
  formText: {
    color: '#636A72',
    fontSize: '0.75rem',
    letterSpacing: 0.45,
    lineHeight: '22px',
  },
}));

const SignUp = ({ location }) => {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState('firstPage');
  const [accountCreated, setAccountCreated] = useState(false);
  const [dealerCode, setDealerCode] = useState(
    location.search.split('=')[1] || null,
  );
  const [fetchedDealer, setFetchedDealer] = useState({});

  const handleSignUp = async (values, setSubmitting, setErrors) => {
    const payload = {
      homeowner: {
        name: values.firstname,
        last_name: values.lastname,
        email: values.email,
        phone_number: values.phone,
        dealer_code: dealerCode,
      },
    };
    try {
      await http.post(
        `${process.env.REACT_APP_BASE_URL}/v1/homeowners`,
        payload,
      );
      setSubmitting(false);
      setAccountCreated(true);
    } catch (e) {
      setCurrentPage('firstPage');
      setSubmitting(false);
      setErrors({ email: 'Email has already been taken' });
    }
  };
  useEffect(() => {
    const fetch = async () => {
      if (dealerCode) {
        const [err, response] = await to(
          protectedGet('/v1/dealers/by_code', {
            params: { dealer_code: dealerCode },
          }),
        );
        if (err) {
          setFetchedDealer({});
          return;
        }
        const dealerData = response ? response.data.data : null;
        const addressData = response ? response.data.included[0] : null;
        const businessData = response ? response.data.included[1] : null;
        setFetchedDealer({
          dealerData,
          addressData,
          businessData,
        });
        return;
      }
      setFetchedDealer({});
    };
    fetch();
  }, [dealerCode]);

  return (
    <div className={classes.contentWrapper}>
      <Header />
      {accountCreated ? (
        <SuccessMessage text="Check your inbox to confirm your account and continue with the proccess" />
      ) : (
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            handleSignUp(values, setSubmitting, setErrors);
          }}
        >
          {({ isSubmitting, errors, values, touched, setFieldValue }) =>
            isSubmitting ? (
              <Loader loadingStatus={isSubmitting} height="75vh" width="50vw" />
            ) : (
              <Form className={classes.form}>
                <div className={classes.formTitle}>
                  <Text
                    textVariant="h5"
                    textColor="#000000"
                    textFontWeight={600}
                    textFontSize={22}
                    textLetterSpacing={0.5}
                    textLineHeight="28px"
                    text="Apply now - it's easy!"
                  />
                  <div className={classes.formText}>
                    Already have an account?{' '}
                    <LinkText
                      variant="subtitle1"
                      link="/signin"
                      text="Log in"
                      textFontSize="12px"
                      textFontWeight={600}
                      textLineHeight="16px"
                      textLetterSpacing={0.6}
                    />
                  </div>
                  {currentPage === 'firstPage' && (
                    <FirstPage
                      setCurrentPage={setCurrentPage}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                    />
                  )}
                  {currentPage === 'secondPage' && (
                    <SecondPage
                      setCurrentPage={setCurrentPage}
                      values={values}
                      errors={errors}
                    />
                  )}
                  {currentPage === 'thirdPage' && (
                    <ThirdPage
                      setCurrentPage={setCurrentPage}
                      dealerCode={dealerCode}
                      setDealerCode={setDealerCode}
                      dealerData={fetchedDealer.dealerData}
                      addressData={fetchedDealer.addressData}
                      businessData={fetchedDealer.businessData}
                    />
                  )}
                </div>
              </Form>
            )
          }
        </Formik>
      )}

      <Footer />
    </div>
  );
};

export default SignUp;
