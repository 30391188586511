import React from 'react';

export default function DocumentDOC({ width, height }) {
  return (
    <svg
      width={width || '32px'}
      height={height || '42px'}
      viewBox="0 0 32 42"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / Files / Unknown@1x</title>
      <desc>Created with Sketch.</desc>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="Icon-/-Files-/-General"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M27.6956522,42 L4.30434783,42 C1.92712216,42 7.81597009e-14,40.0547544 7.81597009e-14,37.6551724 L7.81597009e-14,4.34482759 C7.81597009e-14,1.94524557 1.92712216,0 4.30434783,0 L23.3913043,0 L32,8.68965517 L32,37.6551724 C32,40.0547544 30.0728778,42 27.6956522,42 Z"
          id="Path"
          fill="#636A72"
          fillRule="nonzero"
        />
        <path
          d="M32,8.68965517 L27.2557373,8.68965517 C24.7704559,8.68965517 23.3913043,7.03154602 23.3913043,4.54626465 L23.3913043,5.06261699e-14 L32,8.68965517 Z"
          id="Path"
          fill="#393F47"
          fillRule="nonzero"
        />
        <g
          id="003-doc"
          opacity="0.100000001"
          transform="translate(5.000000, 20.000000)"
        />
        <g
          id="Group-2"
          opacity="0.340000004"
          transform="translate(6.000000, 21.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M12.0289171,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 -1.77635684e-15,1.12314614 -1.77635684e-15,0.723543888 C-1.77635684e-15,0.323941633 0.283448929,-4.4408921e-16 0.633100902,-4.4408921e-16 L12.0289171,-4.4408921e-16 C12.3785691,-4.4408921e-16 12.662018,0.323941633 12.662018,0.723543888 C12.662018,1.12314614 12.3785691,1.44708778 12.0289171,1.44708778 Z"
            id="Path"
          />
          <path
            d="M19.626128,1.44708778 L14.5613208,1.44708778 C14.2116688,1.44708778 13.9282199,1.12314614 13.9282199,0.723543888 C13.9282199,0.323941633 14.2116688,-4.4408921e-16 14.5613208,-4.4408921e-16 L19.626128,-4.4408921e-16 C19.9757799,-4.4408921e-16 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
        </g>
        <g
          id="Group-3"
          opacity="0.340000004"
          transform="translate(6.000000, 25.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M19.626128,1.44708778 L9.49651354,1.44708778 C9.14686156,1.44708778 8.86341263,1.12314614 8.86341263,0.723543888 C8.86341263,0.323941633 9.14686156,-4.4408921e-16 9.49651354,-4.4408921e-16 L19.626128,-4.4408921e-16 C19.9757799,-4.4408921e-16 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
          <path
            d="M6.96410993,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 -1.77635684e-15,1.12314614 -1.77635684e-15,0.723543888 C-1.77635684e-15,0.323941633 0.283448929,-4.4408921e-16 0.633100902,-4.4408921e-16 L6.96410993,-4.4408921e-16 C7.3137619,-4.4408921e-16 7.59721083,0.323941633 7.59721083,0.723543888 C7.59721083,1.12314614 7.3137619,1.44708778 6.96410993,1.44708778 Z"
            id="Path"
          />
        </g>
        <g
          id="Group-3-Copy"
          opacity="0.340000004"
          transform="translate(6.000000, 13.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M19.626128,1.44708778 L9.49651354,1.44708778 C9.14686156,1.44708778 8.86341263,1.12314614 8.86341263,0.723543888 C8.86341263,0.323941633 9.14686156,-4.4408921e-16 9.49651354,-4.4408921e-16 L19.626128,-4.4408921e-16 C19.9757799,-4.4408921e-16 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
          <path
            d="M6.96410993,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 -1.77635684e-15,1.12314614 -1.77635684e-15,0.723543888 C-1.77635684e-15,0.323941633 0.283448929,-4.4408921e-16 0.633100902,-4.4408921e-16 L6.96410993,-4.4408921e-16 C7.3137619,-4.4408921e-16 7.59721083,0.323941633 7.59721083,0.723543888 C7.59721083,1.12314614 7.3137619,1.44708778 6.96410993,1.44708778 Z"
            id="Path"
          />
        </g>
        <g
          id="Group-4"
          opacity="0.340000004"
          transform="translate(6.000000, 29.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M15.1944217,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 2.39808173e-14,1.12314614 2.39808173e-14,0.723543888 C2.39808173e-14,0.323941633 0.283448929,-4.4408921e-16 0.633100902,-4.4408921e-16 L15.1944217,-4.4408921e-16 C15.5440736,-4.4408921e-16 15.8275226,0.323941633 15.8275226,0.723543888 C15.8275226,1.12314614 15.5440736,1.44708778 15.1944217,1.44708778 Z"
            id="Path"
          />
          <path
            d="M19.626128,1.44708778 L17.7268253,1.44708778 C17.3771733,1.44708778 17.0937244,1.12314614 17.0937244,0.723543888 C17.0937244,0.323941633 17.3771733,-4.4408921e-16 17.7268253,-4.4408921e-16 L19.626128,-4.4408921e-16 C19.9757799,-4.4408921e-16 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
        </g>
        <g
          id="Group-5"
          opacity="0.340000004"
          transform="translate(6.000000, 33.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M4.43170632,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 -8.8817842e-16,1.12314614 -8.8817842e-16,0.723543888 C-8.8817842e-16,0.323941633 0.283448929,0 0.633100902,0 L4.43170632,0 C4.78135829,0 5.06480722,0.323941633 5.06480722,0.723543888 C5.06480722,1.12314614 4.78135829,1.44708778 4.43170632,1.44708778 Z"
            id="Path"
          />
          <path
            d="M13.2951189,1.44708778 L6.96410993,1.44708778 C6.61445795,1.44708778 6.33100902,1.12314614 6.33100902,0.723543888 C6.33100902,0.323941633 6.61445795,0 6.96410993,0 L13.2951189,0 C13.6447709,0 13.9282199,0.323941633 13.9282199,0.723543888 C13.9282199,1.12314614 13.6447709,1.44708778 13.2951189,1.44708778 Z"
            id="Path"
          />
          <path
            d="M19.626128,1.44708778 L15.8275226,1.44708778 C15.4778706,1.44708778 15.1944217,1.12314614 15.1944217,0.723543888 C15.1944217,0.323941633 15.4778706,0 15.8275226,0 L19.626128,0 C19.9757799,0 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
        </g>
        <g
          id="Group-5-Copy"
          opacity="0.340000004"
          transform="translate(6.000000, 17.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <path
            d="M4.43170632,1.44708778 L0.633100902,1.44708778 C0.283448929,1.44708778 -8.8817842e-16,1.12314614 -8.8817842e-16,0.723543888 C-8.8817842e-16,0.323941633 0.283448929,0 0.633100902,0 L4.43170632,0 C4.78135829,0 5.06480722,0.323941633 5.06480722,0.723543888 C5.06480722,1.12314614 4.78135829,1.44708778 4.43170632,1.44708778 Z"
            id="Path"
          />
          <path
            d="M13.2951189,1.44708778 L6.96410993,1.44708778 C6.61445795,1.44708778 6.33100902,1.12314614 6.33100902,0.723543888 C6.33100902,0.323941633 6.61445795,0 6.96410993,0 L13.2951189,0 C13.6447709,0 13.9282199,0.323941633 13.9282199,0.723543888 C13.9282199,1.12314614 13.6447709,1.44708778 13.2951189,1.44708778 Z"
            id="Path"
          />
          <path
            d="M19.626128,1.44708778 L15.8275226,1.44708778 C15.4778706,1.44708778 15.1944217,1.12314614 15.1944217,0.723543888 C15.1944217,0.323941633 15.4778706,0 15.8275226,0 L19.626128,0 C19.9757799,0 20.2592289,0.323941633 20.2592289,0.723543888 C20.2592289,1.12314614 19.9757799,1.44708778 19.626128,1.44708778 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  );
}
