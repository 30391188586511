import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  background: {
    backgroundImage: ({ type }) => {
      switch (type) {
        case 'forgotPassword':
        case 'setPassword':
        case 'resetPassword':
          return 'url(/images/auth/reset-password.jpg)';
        case 'signIn':
        case 'resendLink':
          return 'url(/images/auth/signin.jpg)';
        case 'signup':
        default:
          return 'url(/images/auth/signup.jpg)';
      }
    },
    backgroundPositionX: 0,
    backgroundPositionY: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '50vw',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  // powerPayLogo: {
  //   position: 'absolute',
  //   top: '3%',
  //   left: '2.5%',
  //   [theme.breakpoints.down('sm')]: {
  //     display: 'none',
  //   },
  //   display: 'none',
  // },
  contentRight: {
    height: '100%',
    width: '50vw',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      padding: '0 0.25rem',
    },
  },
}));

const InitialPage = ({ component: Component, type, ...props }) => {
  const classes = useStyles({ type });
  return (
    <div className={classes.content}>
      {/*
      POWERPLAY SVG LOGO - The images already have the logo.
      <div className={classes.powerPayLogo}>
        <Icon icon="logo" color="white" height="46" width="225" />
      </div> */}
      <div className={classes.background} />
      <div className={classes.contentRight}>
        <Component {...props} />
      </div>
    </div>
  );
};

export default InitialPage;
