import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    borderWidth: 1.5,
    borderColor: theme.palette.primary.main,
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: 0.6,
    minHeight: 32,
    minWidth: 80,
    marginRight: 24,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: 'max-content',
    },
    '&:hover': {
      borderWidth: 1.5,
    },
  },
}));

const ToggleButtons = ({
  values,
  setFieldValue,
  hasValue,
  disabled,
  valueState,
  labelValues,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  const initialValue = valueState ?? hasValue;
  const [activeButton, setActiveButton] = useState(
    values.indexOf(initialValue),
  );

  useEffect(() => {
    setActiveButton(values.indexOf(valueState));
  }, [valueState]);

  const handleChange = (index, value) => {
    setActiveButton(index);
    setFieldValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  const children = [
    values.map((value, index) => (
      <Button
        disabled={disabled}
        key={index}
        className={classes.button}
        variant={activeButton === index ? 'contained' : 'outlined'}
        color="primary"
        value={value}
        style={{
          color: activeButton === index ? 'white' : null,
          fontWeight: activeButton === index ? 600 : 500,
        }}
        onClick={() => handleChange(index, value)}
        {...props}
      >
        {labelValues ? labelValues[index] : value}
      </Button>
    )),
  ];
  return <div>{children}</div>;
};

export default ToggleButtons;
