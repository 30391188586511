import axios from 'axios';
import { getToken } from '../authentication';

const token = JSON.parse(getToken()) || {};
const tokenFn = () => {
  return JSON.parse(getToken()) || {};
};

const http = {
  get(url) {
    return axios.get(url);
  },
  protectedGet(
    url,
    config = {
      headers: {
        'access-token': token['access-token'],
        client: token.client,
        uid: token.uid,
      },
    },
  ) {
    return axios.get(url, config);
  },
  post(url, payload) {
    return axios.post(url, payload);
  },
  protectedPost(
    url,
    payload,
    config = {
      headers: {
        'access-token': token['access-token'],
        client: token.client,
        uid: token.uid,
      },
    },
  ) {
    return axios.post(url, payload, config);
  },
  put(url, payload) {
    return axios.put(url, payload);
  },
  protectedPut(
    url,
    payload,
    config = {
      headers: {
        'access-token': token['access-token'],
        client: token.client,
        uid: token.uid,
      },
    },
  ) {
    return axios.put(url, payload, config);
  },
  delete(url) {
    return axios.delete(url);
  },
  patch(url) {
    return axios.patch(url);
  },
  protectedPatch(
    url,
    config = {
      headers: {
        'access-token': token['access-token'],
        client: token.client,
        uid: token.uid,
      },
    },
  ) {
    return axios.patch(url, {}, config);
  },
};

const baseUrlAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

baseUrlAxios.interceptors.request.use((config) => {
  const t = tokenFn();
  return {
    ...config,
    headers: {
      ...config.headers,
      'access-token': t['access-token'],
      client: t.client,
      uid: t.uid,
    },
  };
});

baseUrlAxios.interceptors.response.use(null, (err) => {

  if (err.response.status === 401) {
    setTimeout(() => {
      window.location.replace('/signout');
    }, 2000);
  }
  return Promise.reject(err);
});

export const protectedGet = (url, config = {}) => baseUrlAxios.get(url, config);
export const protectedPost = (url, data = {}, config = {}) =>
  baseUrlAxios.post(url, data, config);
export const protectedPut = (url, data = {}, config = {}) =>
  baseUrlAxios.put(url, data, config);
export const protectedPatch = (url, data = {}, config = {}) =>
  baseUrlAxios.patch(url, data, config);
export const protectedDelete = (url, config = {}) =>
  baseUrlAxios.delete(url, config);
export default http;
