import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { InformationItem } from 'pages/ApplicationDetail/Dealer/Details/LoanTab/InformationItem';
import { format } from 'date-fns';
import { updateData } from 'redux/actions';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useUserContext } from 'services/hooks/useUser';

const useStyles = makeStyles(() => ({
  informationItemWrapper: {
    backgroundColor: '#F9FAFC',
    marginBottom: 18,
    paddingBottom: 25,
    paddingLeft: 22,
    paddingTop: 18,
    '& .informationItem': {
      marginBottom: 12,
    },
  },
  transparentButtonInvite: {
    background: 'none',
    border: 'none',
    margin: 0,
    padding: 0,
  },
  informationItemTitle: {
    color: '#1E272E',
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0.7,
    lineHeight: '18px',
    marginBottom: 10,
    paddingLeft: 16,
    paddingRight: 22,
  },
  changeContractText: {
    fontSize: '0.8rem',
    marginTop: '0.5rem',
    color: '#f79d3c',
    fontWeight: 500,
    paddingLeft: '16px',
    cursor: 'pointer',
  },
  informationItem: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
    paddingLeft: 16,
    paddingRight: 22,
    width: '100%',
  },
  informationItemDescription: {
    color: 'rgba(30,39,46,0.4)',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  informationItemValue: {
    color: '#1E272E',
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.65,
    lineHeight: '12px',
  },
  // Project Total Amount
  debtInformation: {
    backgroundColor: '#C1C9D133',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 32,
  },
  totalAmount: {
    color: '#1E272E',
    fontSize: 12,
    fontWeight: 500,

    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  totalAmountValue: {
    color: '#1C272F',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
  monthlyPayment: {
    color: '#1DD1A1',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.55,
    lineHeight: '12px',
  },
  monthlyPaymentValue: {
    color: '#1DD1A1',
    display: 'block',
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 1.2,
    marginTop: 12,
  },
}));

const getLoanType = (loanDetails) => {
  if (loanDetails.ohNo) {
    return `ON`;
  }
  if (loanDetails.equalPay) {
    return `BP`;
  }
  if (loanDetails.pop) {
    return 'POP';
  }
  return `IL`;
};

const Resume = ({
  loanDetails,
  applicationDetail,
  originalLoanDetails,
  updateData,
  rawApplicationsEndpointResponse
}) => {
  const { id } = useParams();
  const history = useHistory();
  const [hideUpdateLoan, setHideUpdateLoan] = useState(false);
  const { user } = useUserContext();
  useEffect(() => {
    if (
      loanDetails.status === 'completed' ||
      loanDetails.status === 'finished' ||
      loanDetails.status === 'change_requested'
    ) {
      setHideUpdateLoan(true);
      return '';
    }
    loanDetails.payments.map((payment) => {
      if (payment.status === 'paid' || payment.status === 'released') {
        setHideUpdateLoan(true);
      }
      return '';
    });
  }, [loanDetails.payments]);
  const classes = useStyles();

  // botch fix, originalLoanDetails is literally the raw response form the endpoint /api/v1/applications/${appId}/loans/${loanId}
  // the regular loanDetails at some point looses the info for show_promo_apr, and I couldn't find where, so voilá
  const promoAprInformation = originalLoanDetails?.data.data.attributes
    .show_promo_apr
    ? {
        promo_composite_apr:
          originalLoanDetails.data.data.attributes.promo_composite_apr,
        show_promo_apr: originalLoanDetails.data.data.attributes.show_promo_apr,
      }
    : null;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.informationItemWrapper}>
        <InformationItem
          title="Application Request Amount"
          value={loanDetails.baseAmount || '--'}
          numberFormat
        />
        {!hideUpdateLoan && (
          <button
            onClick={() => {
              updateData({
                name: 'dealStruct',
                value: '',
              });
              updateData({
                name: 'stagedFunding',
                value: '',
              });
              updateData({
                name: 'appData',
                value: '',
              });
              updateData({
                name: 'loanDetails',
                value: '',
              });
              history.push(`/applications/${id}/review`);
            }}
            type="button"
            className={classes.transparentButtonInvite}
          >
            <span className={classes.changeContractText}>
              Need to update Loan?
            </span>
          </button>
        )}
      </Grid>

      <Grid item xs={12} className={classes.informationItemWrapper}>
        <Typography className={classes.informationItemTitle}>
          Credit Terms
        </Typography>
        <InformationItem
          title="Loan Type"
          value={`${getLoanType(loanDetails)}${
            loanDetails.ohNo
              ? loanDetails.ohNoMonths
              : loanDetails.pop
              ? loanDetails.popIntroMonths
              : loanDetails.term * 12
          }`}
        />

        {loanDetails?.activationFee ? (
          <InformationItem
            title="Activation Fee"
            value={loanDetails?.activationFee}
            numberFormat
          />
        ) : null}

        {loanDetails?.dealerConciergeCost?.cents ? (
          <InformationItem
            title={`Dealer Concierge Cost (${(
              (loanDetails?.dealerConciergeCost?.cents /
                100 /
                loanDetails.baseAmount) *
              100
            ).toFixed(2)}%)`}
            value={loanDetails?.dealerConciergeCost?.cents / 100}
            numberFormat
          />
        ) : null}

        {loanDetails?.apr || promoAprInformation ? (
          <InformationItem
            title={promoAprInformation ? 'APR' : 'Interest Rate'}
            value={
              promoAprInformation
                ? `${promoAprInformation.promo_composite_apr}%`
                : `${Math.round(loanDetails?.apr * 100) / 100}%`
            }
          />
        ) : null}

        {loanDetails?.term ? (
          <InformationItem
            title="Term in Months"
            value={`${loanDetails?.term * 12} months`}
          />
        ) : null}

        {applicationDetail?.homeownerData?.creditLimit ? (
          <InformationItem
            title="Credit Limit"
            value={applicationDetail?.homeownerData?.creditLimit}
            numberFormat
          />
        ) : null}

        {user?.data.attributes.showNetFundingAndCost ? (
          <>
            {loanDetails?.ohNoCost ? (
              <InformationItem
                title={`OH-NO Discount (${(
                  (loanDetails?.ohNoCost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.ohNoCost}
                numberFormat
              />
            ) : null}

            {loanDetails?.equalPayCost ? (
              <InformationItem
                title={`Balance Pay Discount (${(
                  (loanDetails?.equalPayCost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.equalPayCost}
                numberFormat
              />
            ) : null}

            {loanDetails?.buydownCost ? (
              <InformationItem
                title={`Buydown Cost (${(
                  (loanDetails?.buydownCost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.buydownCost}
                numberFormat
              />
            ) : null}

            {loanDetails?.rebatesCost ? (
              <InformationItem
                title={`Rebate Cost (${(
                  (loanDetails?.rebatesCost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.rebatesCost}
                numberFormat
              />
            ) : null}
            {loanDetails?.programCost?.cents ? (
              <InformationItem
                title={`Program Cost (${(
                  (loanDetails?.programCost?.cents /
                    100 /
                    loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.programCost?.cents / 100}
                numberFormat
              />
            ) : null}
            {loanDetails?.stageFundingReserve ? (
              <InformationItem
                title={`Advanced Funding Discount (${(
                  (loanDetails?.stageFundingReserve / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.stageFundingReserve}
                numberFormat
              />
            ) : null}

            {loanDetails?.stipPayCost ? (
              <InformationItem
                title={`Stip Pay Cost (${(
                  (loanDetails?.stipPayCost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.stipPayCost}
                numberFormat
              />
            ) : null}
            {loanDetails?.totalHoldback ? (
              <InformationItem
                title={`HoldBack Amount (${(
                  (loanDetails?.totalHoldback / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={loanDetails?.totalHoldback}
                numberFormat
              />
            ) : null}
            {
              Boolean(rawApplicationsEndpointResponse.data?.included?.find(item => item.type === "loan")?.attributes?.installment_cost) ? (
                <InformationItem
                title={`Installment Cost (${(
                  (rawApplicationsEndpointResponse.data?.included?.find(item => item.type === "loan")?.attributes?.installment_cost / loanDetails.baseAmount) *
                  100
                ).toFixed(2)}%)`}
                value={rawApplicationsEndpointResponse.data?.included?.find(item => item.type === "loan")?.attributes?.installment_cost}
                numberFormat
              />
              ) : null
            }
          </>
        ) : null}
      </Grid>

      {loanDetails?.firstStage && loanDetails?.stageFunding ? (
        <Grid item xs={12} className={classes.informationItemWrapper}>
          <Typography className={classes.informationItemTitle}>
            Advanced Funding
          </Typography>
          <div className={classes.informationItem}>
            <Typography className={classes.informationItemDescription}>
              First Stage
            </Typography>
            <Typography className={classes.informationItemValue}>
              {format(new Date(loanDetails.firstStage), 'MM/dd/yyyy') || '--'}
            </Typography>
          </div>
          <div className={classes.informationItem}>
            <Typography className={classes.informationItemDescription}>
              {loanDetails.payments.length > 2
                ? 'Second Stage'
                : 'Final Payment'}
            </Typography>
            <Typography className={classes.informationItemValue}>
              {loanDetails.secondStage
                ? format(new Date(loanDetails.secondStage), 'MM/dd/yyyy') ||
                  '--'
                : '--'}
            </Typography>
          </div>
          {loanDetails.payments.length > 2 && (
            <div className={classes.informationItem}>
              <Typography className={classes.informationItemDescription}>
                Final Payment
              </Typography>
              <Typography className={classes.informationItemValue}>
                {loanDetails.thirdStage
                  ? format(new Date(loanDetails.thirdStage), 'MM/dd/yyyy') ||
                    '--'
                  : '--'}
              </Typography>
            </div>
          )}
        </Grid>
      ) : null}

      <Grid item xs={12} className={classes.informationItemWrapper}>
        <Typography className={classes.informationItemTitle}>
          Project Type
        </Typography>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Project Type
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.projectType || '--'}
          </Typography>
        </div>
        <div className={classes.informationItem}>
          <Typography className={classes.informationItemDescription}>
            Project Description
          </Typography>
          <Typography className={classes.informationItemValue}>
            {loanDetails.unitDescription || '--'}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} lg={6} />
      <Grid item xs={12} lg={6} className={classes.debtInformation}>
        <Typography className={classes.totalAmount}>
          TOTAL LOAN AMOUNT
          <span className={classes.totalAmountValue}>
            <NumberFormat
              value={loanDetails.total}
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </span>
        </Typography>
        <Typography className={classes.monthlyPayment}>
          MONTHLY PAYMENT
          <span className={classes.monthlyPaymentValue}>
            <NumberFormat
              value={
                applicationDetail?.dealData?.teaserAvailable
                  ? applicationDetail?.dealData?.monthlyPayment?.cents / 100
                  : loanDetails.monthlyRate
              }
              displayType="text"
              prefix="$"
              thousandSeparator
              decimalScale={2}
              fixedDecimalScale
            />
          </span>
        </Typography>
      </Grid>
    </Grid>
  );
};
const mapStatesToProps = (state) => {
  return {
    dealsData: state.appReducer,
  };
};
export default connect(mapStatesToProps, {
  updateData,
})(Resume);
