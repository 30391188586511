import React, { useEffect, useState } from 'react';
import RejectedIcon from 'assets/icons/submit-rejected.png';
import AcceptedIcon from 'assets/icons/submit-accept.png';
import to from 'await-to-js';
import { useUserContext } from 'services/hooks/useUser';
import { useHistory, useParams } from 'react-router-dom';
import { updateData } from 'redux/actions';
import { connect } from 'react-redux';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import StatusButton from 'components/Buttons/StatusButton';

import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import { protectedGet } from 'services/http';
import { useAlertContext } from 'components/AlertContext';
import { DynamicConfirm } from '../NewPrequalify/ConfirmPage';
import SideTab from './SideTab';
import './dealStatusPage.css';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.up('md')]: { padding: '2rem 2rem 4rem 2rem' },
    [theme.breakpoints.up('lg')]: {},
  },
  card: {
    flexGrow: 1,
    // padding: '2rem 1rem',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      padding: '24px',
      paddingBottom: '2rem',
    },
  },
  image: {
    height: 120,
    marginBottom: '0.25rem',
  },
}));

const ApplicantInformationItem = ({
  title,
  text,
  className,
  classNamePrefix,
}) => {
  return (
    <div className={className}>
      <h4 className={`${classNamePrefix}_title`}>{title}</h4>
      <p className={`${classNamePrefix}_text`}>{text}</p>
    </div>
  );
};

const ApplicantInformationSubsection = ({
  title,
  listOfApplicantInformation,
  className,
  classNamePrefix,
}) => {
  /*
    listOfApplicantInformation format: [{title: "title", text: "text"}]
  */
  return (
    <div className={className}>
      <h3 className={`${classNamePrefix}_title`}>{title}</h3>
      <div className={`${classNamePrefix}_flex-container`}>
        {listOfApplicantInformation.map((item) => (
          <ApplicantInformationItem
            title={item.title}
            text={item.text}
            id={classNamePrefix + item.title}
            className={`${classNamePrefix}_information-item`}
            classNamePrefix={`${classNamePrefix}_information-item`}
          />
        ))}
      </div>
    </div>
  );
};

const DealStatusPage = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [fetchedData, setFetchedData] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState();
  const [dealsMsg, setDealMsg] = useState('');

  const { user } = useUserContext();
  const { setErrorAlertMessage } = useAlertContext();
  const { message, dealsData, invalidDealerConfig } = props;
  const { appId } = useParams();

  useEffect(async () => {
    if (!message) {
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
        ),
      );

      if (rawApplication) {
        props.updateData({
          name: 'appData',
          value: rawApplication.data,
        });
        setHasUnreadMessages(
          rawApplication?.data?.data?.attributes.has_unread_messages,
        );
        const dealSetId =
          rawApplication.data.data.relationships.deal_set.data.id;
        const [err, dealsData] = await to(
          protectedGet(
            `${process.env.REACT_APP_BASE_URL}/v2/deal_sets/${dealSetId}?application_id=${appId}`,
          ),
        );
        if (dealsData) {
          if (
            !dealsData.data.data.attributes.reviewing &&
            window.location.href.includes('reviewing')
          ) {
            history.push(`/applications/${appId}/review`);
            return;
          }

          setDealMsg(dealsData.data.data.attributes.message);
        }
      }
    }
  }, []);
  useEffect(async () => {
    if (fetchedData) {
      const [err, rawApplication] = await to(
        protectedGet(
          `${process.env.REACT_APP_BASE_URL}/v1/applications/${appId}`,
        ),
      );
      if (!err) {
        props.updateData({
          name: 'appData',
          value: rawApplication.data,
        });
      }

      if (err) {
        setErrorAlertMessage(
          err?.response?.data?.message ||
            'Error while fetching application details.',
        );
      }
      setFetchedData(false);
      setShowLoader(false);
    }
  }, [fetchedData]);

  const declinedDealObject = {
    icon: RejectedIcon,
    subtitle: 'We appreciate you considering PowerPay for your needs',
    desc: `${message || dealsMsg}`,
  };
  const ineligibleDealObject = {
    icon: RejectedIcon,
    subtitle:
      'We appreciate you considering PowerPay for your home improvement needs',
    desc: `${message || dealsMsg}`,
  };
  const exceptionDealObject = {
    icon: RejectedIcon,
    title: (dealsData?.data?.attributes?.credit_freeze
        ? 'There is a credit freeze on the application'
        : 'An issue occurred while processing...'),
    subtitle: 'We appreciate you considering PowerPay for your needs.',
    desc: `${message || dealsMsg}`,
  };
  const canceledDealObject = {
    icon: RejectedIcon,
    title: 'This Application is Canceled...',
    subtitle: 'Please contact Power Pay at 800-397-4485 for assistance.',
  };
  const applicationConfirmObject = {
    icon: AcceptedIcon,
    title: 'Application Received',
    subtitle:
      'We are reviewing your application- please check with your contractor regarding application status.',
    desc:
      'Please follow the instructions, it may take up to 30 seconds to receive the email.',
  };
  const dealerNotFoundObject = {
    icon: RejectedIcon,
    title: 'Invalid Dealer/Member Code',
    subtitle: 'Please contact Power Pay at 800-397-4485 for assistance.',
  };
  const invalidDealerConfigObject = {
    icon: RejectedIcon,
    title: 'Invalid Dealer Configuration',
    subtitle: 'Please contact Power Pay at 800-397-4485 for assistance.',
  };
  const appUnderReview = {
    icon: AcceptedIcon,
    title: 'Application Under Review',
    subtitle:
      'It will take a few minutes to review the application. Keep an eye out for an email with more details or refresh the application in a few minutes for your offer.',
    // desc: `${message || dealsMsg}`,
  };

  let dealObject = window.location.href.includes('declined')
    ? { ...declinedDealObject }
    : window.location.href.includes('ineligible')
    ? { ...ineligibleDealObject }
    : window.location.href.includes('confirmApplication')
    ? { ...applicationConfirmObject }
    : { ...exceptionDealObject };
  if (window.location.href.includes('errorPage')) {
    dealObject = invalidDealerConfig
      ? { ...invalidDealerConfigObject }
      : { ...dealerNotFoundObject };
  } else if (window.location.href.includes('reviewing')) {
    dealObject = { ...appUnderReview };
  } else if (window.location.href.includes('canceled')) {
    dealObject = { ...canceledDealObject };
  }
  const goBack = () => {
    history.push(`/applications`);
  };
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid
        item
        xs={12}
        lg={!window.location.href.includes('errorPage') ? (user ? 7 : 12) : 12}
        spacing={1}
      >
        <Card className={classes.card}>
          {dealsData && (
            <Box>
              <p>
                <IconButton
                  style={{ margin: 0, padding: 0 }}
                  onClick={() => {
                    goBack();
                  }}
                >
                  <ChevronLeftIcon fontSize="large" />
                </IconButton>
                <span>Application {dealsData?.data?.attributes?.pp_id}</span>
                <StatusButton
                  status={dealsData?.data?.attributes?.status}
                  draftStatus={dealsData?.data?.attributes?.draft_status}
                />
              </p>
            </Box>
          )}
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              maxWidth="48rem"
              margin="0 auto"
            >
              <DynamicConfirm
                status="declined"
                {...dealObject}
              ></DynamicConfirm>
            </Box>
          </CardContent>
        </Card>
        {(window.location.href.includes('reviewing') || window.location.href.includes('exception')) && dealsData && (
          <Card className={classes.card} style={{ marginTop: '1rem' }}>
            <h2 className="dealStatusPage_applicants-information-title">
              Applicant's Information
            </h2>
            <ApplicantInformationSubsection
              title="Basic Information"
              listOfApplicantInformation={[
                {
                  title: 'First Name',
                  text: dealsData?.data?.attributes?.name || '-',
                },
                {
                  title: 'Last Name',
                  text: dealsData?.data?.attributes?.last_name || '-',
                },
              ]}
              className="dealStatusPage_applicants-information-subsection"
              classNamePrefix="dealStatusPage_applicants-information-subsection"
            />
            <ApplicantInformationSubsection
              title="Contact Information"
              listOfApplicantInformation={[
                {
                  title: 'Email',
                  text: dealsData?.data?.attributes?.email || '-',
                },
                {
                  title: 'Phone Number',
                  text: dealsData?.data?.attributes?.phone_number || '-',
                },
              ]}
              className="dealStatusPage_applicants-information-subsection"
              classNamePrefix="dealStatusPage_applicants-information-subsection"
            />
            <ApplicantInformationSubsection
              title="Address"
              listOfApplicantInformation={[
                {
                  title: 'Address',
                  text:
                    dealsData?.included?.find((elem) => elem.type === 'address')
                      ?.attributes.street_address || '-',
                },
                {
                  title: 'State',
                  text:
                    dealsData?.included?.find((elem) => elem.type === 'address')
                      ?.attributes.state || '-',
                },
                {
                  title: 'City',
                  text:
                    dealsData?.included?.find((elem) => elem.type === 'address')
                      ?.attributes.city || '-',
                },
              ]}
              className="dealStatusPage_applicants-information-subsection"
              classNamePrefix="dealStatusPage_applicants-information-subsection"
            />
          </Card>
        )}
      </Grid>
      <Grid item xs={12} lg={5}>
        {!window.location.href.includes('errorPage') && (
          <SideTab
            appId={appId}
            setHasUnreadMessages={setHasUnreadMessages}
            applicationDetails={dealsData}
            hasUnreadMessages={hasUnreadMessages}
            showLoader={showLoader}
            setShowLoader={setShowLoader}
            fetchedData={fetchedData}
            setFetchedData={setFetchedData}
          ></SideTab>
        )}
      </Grid>
    </Grid>
  );
};
const mapStatesToProps = (state) => {
  return {
    message: state.appReducer.message,
    dealsData: state.appReducer.appData,
    invalidDealerConfig: state.appReducer.invalidDealerConfig,
  };
};
export default connect(mapStatesToProps, { updateData })(DealStatusPage);
